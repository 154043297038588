import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { ActionCreators as UndoActionCreators } from "redux-undo";
import Emitter from "_actions/emitter";
import i18n from "i18n";
import { ResponseStatusCode } from "_helpers";
import { addNewPage, removeComponent } from "_actions";
import { DashboardService } from "_services";
import { Button, Alert, SelectPicker, Loader, Input } from "rsuite";

import { Modal, Container, Row, Col } from "reactstrap";

class WorkspaceEndpoints extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      endpoints: null,
      search: "",
    };
  }

  resetContent = () => {
    this.getEndpoints();
    this.setState({
      isLoading: false,
      search: "",
    });
  };

  getEndpoints = () => {
    const { workspace } = this.props;
    const that = this;
    that.setState({ isLoading: true });
    DashboardService.FEED_MENU(workspace.id, "62fd5f37764fe1b7b6e6a9fd", "0")
      .then((data) => {
        that.setState({ isLoading: false });
        if (data.operationResult) {
          this.setState({ endpoints: data.dynamicValue });
        }
      })
      .catch((err) => {
        Alert.warning(ResponseStatusCode(err.statusCode));
        that.setState({ isLoading: false });
        debugger;
        //  let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
        //   this.warningAlert(data);
      });
  };

  componentDidUpdate(prevProps) {
    if (this.props.isActive)
      if (prevProps.isActive != this.props.isActive && this.props.isActive) {
        this.resetContent();
      }
  }

  render() {
    const { isActive, handleToggle ,workspace} = this.props;
    const { isLoading, endpoints, search  } = this.state;
 
    return (
      <Translation>
        {(t) => (
          <>
            <Modal
              className="modal-dialog-centered"
              isOpen={isActive}
              //size="lg"
              scrollable={true}
            >
              <div className="modal-header">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <h5 className="modal-title" id="CheckProjectModal">
                    {t("SERVICES_MENU_ITEM_ENDPOINTS")}
                  </h5>
                  <Input
                    size="xs"
                    style={{ marginLeft: "6px" }}
                    placeholder={t("PLACEHOLDER_SEARCH")}
                    onChange={(value) => {
                      this.setState({
                        search: value.trim(),
                      });
                    }}
                  />
                </div>

                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  disabled={this.state.isLoading}
                  onClick={() => handleToggle()}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div
                className="modal-body endpoint-modal-body"
                style={{ paddingTop: 0, paddingBottom: 0 }}
              >
                {endpoints && (
                  <>
                    <div>
                      {t("DEV")}
                      <p>{endpoints.baseURL}</p>
                    </div>
                    {workspace && (
                      <>
                        <div>
                          {t("QA")}
                          <p>{`https://${workspace.qA_URL}/`}</p>
                        </div>
                        <div>
                          {t("PROD")}
                          <p>{`https://${workspace.prod_URL}/`}</p>
                        </div>
                      </>
                    )}

                    <div>
                      {t("TITLE_ENDPOINTS_WORKSPACE_ID")}
                      <p>{this.props.workspace?.id}</p>
                    </div>
                    {[
                      ...endpoints.groupByDashBoardEndpoints.filter((x) =>
                        `${x.parentName}${JSON.stringify(
                          x.dashBoardEndpoints.map(
                            (c) => c.functionName + c.transactionShortName
                          )
                        )}`
                          .toLocaleLowerCase()
                          .includes(search ? search.toLocaleLowerCase() : "")
                      ),
                    ].map((item, key) => (
                      <div
                        className="text-primary"
                        style={{ padding: "8px" }}
                        key={key}
                      >
                        {item.parentName}
                        <div className="modal-title" style={{ padding: "8px" }}>
                          {item.dashBoardEndpoints.map((i, k) => (
                            <div key={k}>
                              <div style={{ fontWeight: "600" }}>
                                {`${i.functionName} ${
                                  i.transactionShortName
                                    ? "- (" + i.transactionShortName + ")"
                                    : ""
                                }`}
                              </div>
                              <p>{i.path}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </>
                )}
                {isLoading && (
                  <div className={"fade-in"}>
                    <Loader backdrop vertical />
                  </div>
                )}
              </div>
              <div className="modal-footer">
                <Button
                  size={"sm"}
                  data-dismiss="modal"
                  disabled={this.state.isLoading}
                  type="button"
                  onClick={() => handleToggle()}
                >
                  {t("BUTTON_CANCEL")}
                </Button>
              </div>
              {this.state.alert}
            </Modal>
          </>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer } = state;
  const { workspace, workspaces, selectedService } = workspaceReducer;

  return {
    workspace,
    workspaces,
    selectedService,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceEndpoints);
