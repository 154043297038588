import {
    //ServiceURLs,
    CategoryEndPoint,
    GetBaseERPURL,
    MakeReqestAndGetResponse,
    GetUserLang,
  } from "./index";
  import { HTTPOperationType } from "./httpOperations";
  
  export const CategoryService = {
    GET_W_LANG

  };

 

  function GET_W_LANG(Lang) {

    return MakeReqestAndGetResponse(
      GetBaseERPURL() + CategoryEndPoint.GET_W_LANG+Lang,
      HTTPOperationType.get
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }
   
 
 