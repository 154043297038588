import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Dropdown } from 'rsuite';
import { Translation } from "react-i18next";
import i18n from 'i18n';
import Emitter from "_actions/emitter";
import { Loader, Alert, Icon } from 'rsuite';
import {
    setProjectId
} from "_actions";
import { Tooltip, Whisper } from 'rsuite';

class ProjectCard extends Component {

    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            img: null,
            isCloneModal: false,
        }
        this.myRef = React.createRef();
    }

    handleOpenProject = () => {
        const { setProjectId, data, handleClose, isBussy } = this.props;
        if (!isBussy) {
            setProjectId(data.id);
            Emitter.emit("UPDATE_PROJECT_ID", null);
            handleClose();
        }


    }

    handleOpenSettings = () => {
        const { data } = this.props;
        this.props.customHistory.push("project-settings", data)
        // this.props.customHistory.push("project-settings")

    }
    handleOpenAnalyzer = () => {
        Alert.warning(i18n.t("ERROR_ITS_NOT_WORKING"))

    }


    handleCloneModal = () => {
        this.setState({
            isCloneModal: !this.state.isCloneModal
        })
    }
    handleDelete = () => {
        Alert.warning(i18n.t("ERROR_ITS_NOT_WORKING"))

    }

    componentDidMount = () => {
        this.getMyIdealImage();
    }

    getMyIdealImage = () => {
        const { data } = this.props;
        try {
            const renderedImages = data.setting.icons;
            let current = this.myRef.current;
            this.setState({ loading: true, })
            if (!renderedImages) {
                this.setState({ loading: false, })
                return;
            }
            if (renderedImages == null) {
                this.setState({ img: null, loading: false, })
                return
            }
            else if (renderedImages.length == 0) {
                this.setState({ img: null, loading: false, })
                return
            }

            if (current != null) {
                let w = this.myRef.current.offsetWidth;
                let h = this.myRef.current.offsetHeight;
                if (renderedImages == null || renderedImages.length < 1) {
                    this.setState({ loading: false, })
                    return;
                }

                let closest = null;
                try {
                    if (w > h) {
                        closest = renderedImages.reduce(function (prev, curr) {
                            let _x = curr.width;
                            let _y = curr.height;
                            let _px = prev.width;
                            let _py = prev.height;
                            return (Math.abs(_x - w) < Math.abs(_px - w) ? curr : prev);
                        });
                    } else {
                        closest = renderedImages.reduce(function (prev, curr) {
                            let _x = curr.width;
                            let _y = curr.height;
                            let _px = prev.width;
                            let _py = prev.height;
                            return (Math.abs(_y - h) < Math.abs(_py - h) ? curr : prev);
                        });
                    }
                } catch (error) {
                    //console.log(error);
                }
                if (closest != null) { this.setState({ img: closest.url, loading: false, }) }

            }
        } catch (error) {
            this.setState({ img: null, loading: false, })
        }
    }

    render() {
        const { data, isBussy } = this.props;
        const { img, loading } = this.state;
        const tooltip = (
            <Tooltip>
                {data.projectName}
            </Tooltip>
        );
        return (
            <Translation>
                {t => (
                    <>
                        <Whisper placement="bottom" trigger="hover" speaker={tooltip}>

                            <div aria-label={data.projectName} className={"project-container custom-fade-in"} >
                                {
                                    !img ?
                                        <div ref={this.myRef} className={"project-null-img"} onClick={() => { this.handleOpenProject() }}>
                                            +
                                    {
                                                loading &&
                                                <Loader backdrop vertical />
                                            }
                                        </div>
                                        :
                                        <div ref={this.myRef} className={"project-img"}
                                            onClick={() => { this.handleOpenProject() }}
                                            style={{
                                                backgroundImage: "url(" + img + ")",
                                            }}>
                                            {
                                                loading &&
                                                <Loader backdrop vertical />
                                            }
                                        </div>
                                }

                                <div className={"mt-2 project-title"}>
                                    {data.projectName}
                                </div>
                            </div>

                        </Whisper>

                    </>

                )}
            </Translation>
        )
    }
}

const mapStateToProps = (state) => {
    const { currentstatesReducer } = state;
    const { customHistory } = currentstatesReducer;

    return {
        customHistory,
    };

}

const mapDispatchToProps = (dispatch) => {
    return {
        setProjectId: (_id) => dispatch(setProjectId(_id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectCard)
