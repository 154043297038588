import {
    //ServiceURLs,
    DevelopingLangsEndPoint,
    GetBaseERPURL,
    MakeReqestAndGetResponse,
    GetUserLang,
  } from "./index";
  import { HTTPOperationType } from "./httpOperations";
  
  export const DevLangService = {
    GET_DEV_LANGS
  };
  
   
 

  function GET_DEV_LANGS() {
  
    return MakeReqestAndGetResponse(
      GetBaseERPURL() + DevelopingLangsEndPoint.GET_DEV_LANGS,
      HTTPOperationType.get
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }
 
 