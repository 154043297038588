import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import moment from "moment";
import Emitter from "_actions/emitter";
import i18n from "i18n";
import { ResponseStatusCode } from "_helpers";
import {
  updateStore,
  updateStoreCategories,
  updateGeneratedTransactions,
  updateGeneratedModules,
  updateGeneratedInterfaces,
} from "_actions";
import {
  ErpEngineService,
  DashBoardURLSService,
  WorkspaceService,
  GITService,
} from "_services";
import {
  InputGroup,
  Input,
  Icon,
  Alert,
  SelectPicker,
  ButtonToolbar,
  IconButton,
  Tooltip,
  Whisper,
} from "rsuite";
import { Loader, Container, Placeholder, Button, FlexboxGrid } from "rsuite";
import { Table, ColumnGroup, Column, HeaderCell, Cell } from "rsuite-table";

import { Modal } from "reactstrap";

import CreateURL from "./CreateURL";
import RunAnotherInstanceModal from "./RunAnotherInstanceModal";
import TransferModeDataModal from "./TransferModeDataModal";
import CreateUpdatePlatformModal from "./CreateUpdatePlatformModal";

const { Paragraph } = Placeholder;
let LANG = i18n.t("LANG");
const WAIT_INTERVAL = 500;
const ENTER_KEY = 13;

class PlatformsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      search: "",
      platforms: [],
      urls: [],
      isCreateUpdate: false,
      data: null,
    };
  }

  handleUpdate = (data) => {
    this.setState({ data: data, isCreateUpdate: true });
  };
  handleRun = (data) => {
    debugger;
    const that = this;
    const { workspace, selectedService, handleToggle, handleUpdate } =
      this.props;

    that.setState({ isLoading: true });
    DashBoardURLSService.RUN_UNDASHBOARDURL(
      data.id,
      workspace.id,
      data.relFrontEndPlatform,
      data.worK_PLACE
    )
      .then((data) => {
        //debugger;
        if (data.operationResult) {
          debugger;
          Alert.success(i18n.t("Success"));
        }
      })
      .catch((err) => {
        that.setState({ isLoading: false, isSetSettingsLoading: false });
        let data = {
          title: i18n.t("warning"),
          desc: ResponseStatusCode(err.statusCode),
        };
        debugger;
        Alert.error(data.desc);
      });
  };

  handleCreateUpdate = () => {
    const value = !this.state.isCreateUpdate;
    this.setState({ isCreateUpdate: value });
    if (!value) {
      this.setState({ isCreateUpdate: value, data: null });
    } else {
      this.setState({ isCreateUpdate: value });
    }
  };

  resetContent = () => {
    this.setState({
      isLoading: false,
      search: "",

      urls: [],
      isCreateUpdate: false,
      data: null,
    });
    this.handleGetPlatforms();
    this.handleCheckUrls();
  };
  componentDidUpdate(prevProps) {
    if (this.props.isActive)
      if (prevProps.isActive != this.props.isActive && this.props.isActive) {
        this.resetContent();
      }
  }

  componentWillMount() {
    this.timer = null;
  }

  handleSetSettings = () => {
    const { workspace, selectedService } = this.props;
    const that = this;
    const URL_MODE = "dev_URL";

    this.setState({ isLoading: true, isSetSettingsLoading: true });
    ErpEngineService.SET_SETTING_BACKEND(
      workspace[URL_MODE],
      workspace.id,
      "dev",
      "0.1" // -->> VERSION
    )
      .then((data) => {
        debugger;
        if (data.operationResult) {
          Alert.success(
            i18n.t("SET_SETTING_BACKEND") +
              i18n.t("MESSAGE_ENGINE_OPERATION_SUCCESS")
          );
        }
        that.setState({ isLoading: false, isSetSettingsLoading: false });
      })
      .catch((err) => {
        that.setState({ isLoading: false, isSetSettingsLoading: false });
        Alert.error(i18n.t("MESSAGE_ENGINE_OPERATION_UNSUCCESS"));

        // let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
        debugger;
      });
  };

  handleGetPlatforms = () => {
    const that = this;
    const { workspace, selectedService } = this.props;
    that.setState({ isLoading: true });
    DashBoardURLSService.GET_PLATFORMS()
      .then((data) => {
        //debugger;
        if (data.operationResult) {
          debugger;
          that.setState({ platforms: data.dynamicValue, isLoading: false });
        } else {
          that.setState({ isLoading: false });
        }
      })
      .catch((err) => {
        that.setState({ isLoading: false, isSetSettingsLoading: false });
        let data = {
          title: i18n.t("warning"),
          desc: ResponseStatusCode(err.statusCode),
        };
        debugger;
        Alert.error(data.desc);
      });
  };

  handleCheckUrls = () => {
    const that = this;
    const { workspace, selectedService } = this.props;
    that.setState({ isLoading: true });
    DashBoardURLSService.CHECK_URL(workspace.id)
      .then((data) => {
        //debugger;
        if (data.operationResult) {
          debugger;
          that.setState({ urls: data.dynamicValue, isLoading: false });
        } else {
          that.setState({ isLoading: false });
        }
      })
      .catch((err) => {
        that.setState({ isLoading: false, isSetSettingsLoading: false });
        let data = {
          title: i18n.t("warning"),
          desc: ResponseStatusCode(err.statusCode),
        };
        debugger;
        Alert.error(data.desc);
      });
  };

  render() {
    const {
      isActive,
      handleToggle,
      store,
      storeCategories,
      user,
      workspace,
      EngineStatus,
      gitStatus,
    } = this.props;

    const { isLoading, mystore, active } = this.state;
    return (
      <Translation>
        {(t) => (
          <>
            <Modal
              className="modal-dialog-centered"
              isOpen={isActive}
              size="lg"
              scrollable={true}
            >
              <div>
                <div className="modal-header store-model-header">
                  <h5 className="modal-title">
                    {t("MODAL_PLATFORM_MODAL_TITLE")}
                  </h5>
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    disabled={this.state.isLoading}
                    onClick={() => handleToggle()}
                  >
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
              </div>
              <div className="modal-body store-model-body">
                <Container>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      marginBottom: "10px",
                    }}
                  >
                    <Button
                      size="xs"
                      //  icon={<Icon icon="plus" />}
                      appearance="default"
                      onClick={this.handleCreateUpdate}
                    >
                      {t("BUTTON_CREATE_NEW")}
                    </Button>
                  </div>
                  <div style={{ paddingBottom: "1em" }}>
                    <Table
                      height={400}
                      fillHeight={true}
                      data={this.state.urls}
                      onRowClick={(data) => {
                        console.log(data);
                      }}
                    >
                      <Column width={300} align="center" fullText flexGrow={1}>
                        <HeaderCell>Url</HeaderCell>
                        <BaseCell dataKey="url" path="url" />
                      </Column>

                      <Column width={90}>
                        <HeaderCell>Mode</HeaderCell>
                        <BaseCell dataKey="worK_PLACE" path="worK_PLACE" />
                      </Column>

                      <Column width={130}>
                        <HeaderCell>Platform</HeaderCell>
                        <BaseCell
                          path={"relFrontEndPlatformObj.name"}
                          dataKey="relFrontEndPlatformObj"
                        />
                      </Column>

                      <Column width={70}>
                        <HeaderCell>Status</HeaderCell>
                        <BaseCell dataKey="isActive" path="isActive" />
                      </Column>

                      <Column width={75} fixed="right">
                        <HeaderCell>Action</HeaderCell>
                        <ActionCell
                          dataKey="id"
                          handleUpdate={this.handleUpdate}
                          handleRun={this.handleRun}
                        />
                      </Column>
                    </Table>
                  </div>
                  {isLoading && (
                    <div className={"fade-in"}>
                      <Loader backdrop vertical />
                    </div>
                  )}
                </Container>
              </div>

              {this.state.alert}
            </Modal>
            <CreateURL
              isActive={this.state.isCreateUrl}
              handleToggle={this.handleCreateUrl}
            />

            {this.state.isRunInstance && (
              <RunAnotherInstanceModal
                isActive={this.state.isRunInstance}
                handleToggle={this.handleRunInstance}
              />
            )}
            {this.state.isCreateUpdate && (
              <CreateUpdatePlatformModal
                isActive={this.state.isCreateUpdate}
                handleToggle={this.handleCreateUpdate}
                data={this.state.data}
                handleUpdate={this.handleCheckUrls}
                platforms={this.state.platforms}
              />
            )}
          </>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer, authentication } = state;
  const { loggedIn, LoginFailed, user } = authentication;

  const {
    workspace,
    workspaces,
    selectedService,
    store,
    storeCategories,
    EngineStatus,
    gitStatus,
    TerminalList,
    generatedTransactions,
    generatedModels,
    generatedInterfaces,
  } = workspaceReducer;

  return {
    workspace,
    workspaces,
    selectedService,
    store,
    storeCategories,
    user,
    EngineStatus,
    TerminalList,
    gitStatus,
    generatedTransactions,
    generatedModels,
    generatedInterfaces,
  };
};

const mapDispatchToProps = {
  updateStore,
  updateStoreCategories,
  updateGeneratedTransactions,
  updateGeneratedModules,
  updateGeneratedInterfaces,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlatformsModal);

const BaseCell = React.forwardRef((props, ref) => {
  const { children, rowData, dataKey, path, ...rest } = props;
  try {
    if (path) {
      const _path = path.split(".");
      let value = Object.assign({}, rowData);

      _path.forEach((el) => {
        value = value[el];
      });

      return (
        <Cell
          ref={ref}
          rowData={rowData}
          onDoubleClick={() => {
            console.log(rowData);
          }}
          {...rest}
          style={{ textAlign: "center" }}
        >
          <p style={{ margin: 0, fontSize: "12px", textAlign: "center" }}>
            {dataKey == "worK_PLACE" ? (
              value == 0 ? (
                i18n.t("INSTANCE_MODE_DEV")
              ) : value == 1 ? (
                i18n.t("INSTANCE_MODE_QA")
              ) : value == 2 ? (
                i18n.t("INSTANCE_MODE_PROD")
              ) : (
                value
              )
            ) : dataKey == "isActive" ? (
              <div className="engine-status-box">
                <div
                  className={
                    "engine-status-color " +
                    (value ? " engine-status-on" : " engine-status-off")
                  }
                />
              </div>
            ) : (
              value
            )}
          </p>
        </Cell>
      );
    } else {
      return (
        <Cell ref={ref} rowData={rowData} {...rest}>
          {children}
        </Cell>
      );
    }
  } catch (error) {
    return (
      <Cell ref={ref} rowData={rowData} {...rest}>
        {"-"}
      </Cell>
    );
  }
});

const ActionCell = ({ rowData, dataKey, ...props }) => {
  const { handleUpdate, handleRun } = props;
  /*
  handleAction = () => {
    alert(`id:${rowData[dataKey]}`);
    console.log(rowData, dataKey);
  };
*/
  return (
    <BaseCell
      {...props}
      style={{ margin: 0, fontSize: "12px", textAlign: "center" }}
    >
      <ButtonToolbar>
        {/*
        <Whisper
          placement="bottom"
          trigger="hover"
          speaker={<Tooltip>{i18n.t("BUTTON_EDIT")}</Tooltip>}
        >
          <IconButton
            size="xs"
            icon={<Icon icon="edit" />}
            appearance="default"
            onClick={() => {
              handleUpdate(rowData);
            }}
          />
        </Whisper>
       */}
        <Whisper
          placement="bottom"
          trigger="hover"
          speaker={<Tooltip>{i18n.t("BUTTON_RUN")}</Tooltip>}
        >
          <IconButton
            size="xs"
            icon={<Icon icon="play2" />}
            appearance="primary"
            onClick={() => {
              handleRun(rowData);
            }}
          />
        </Whisper>
      </ButtonToolbar>
    </BaseCell>
  );
};
