import {
    //ServiceURLs,
    ModulesEndpoint,
    GetBaseERPURL,
    MakeReqestAndGetResponse,
    GetUserLang,
  } from "./index";
  import { HTTPOperationType } from "./httpOperations";
  
  export const ModulesService = {
    CREATE_MODULE,
    STEPBYLOAD,
    EDIT_MODULE,
    UPDATE_NPM_PACKAGE,
    GET_FROM_STORE,
    UPDATE_FROM_STORE 
  };

  function GET_FROM_STORE(id,RelWorkSpaceId,Version) {
    var js = {
      id:id,
      Version:Version,
      RelWorkSpaceId:RelWorkSpaceId
    };
    return MakeReqestAndGetResponse(
      GetBaseERPURL() + ModulesEndpoint.GET_FROM_STORE,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }
 
  function UPDATE_FROM_STORE(id,RelWorkSpaceId,Version) {
    var js = {
      id:id,
      Version:Version,
      RelWorkSpaceId:RelWorkSpaceId
    };
    return MakeReqestAndGetResponse(
      GetBaseERPURL() + ModulesEndpoint.UPDATE_FROM_STORE,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }
 


  function STEPBYLOAD(IsOrderByDesc,LastIndexId,Count,SearchTerm,DynamicObj) {
    var js = {
      SearchTerm: SearchTerm,
      Count : Count,
      LastIndexId : LastIndexId,
      IsOrderByDesc:IsOrderByDesc,
      DynamicObj:DynamicObj
    };
  
    return MakeReqestAndGetResponse(
      GetBaseERPURL() + ModulesEndpoint.STEPBYLOAD,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }
   
 

  function CREATE_MODULE(Name,Description,RelDevelopLangId,NPMPackage,Version,RelWorkSpaceId) {
    var js = {
      Name: Name,
      Description:Description,
      RelDevelopLangId:RelDevelopLangId,
      NPMPackage:NPMPackage,
      Version:Version,
      RelWorkSpaceId:RelWorkSpaceId
    };
    return MakeReqestAndGetResponse(
      GetBaseERPURL() + ModulesEndpoint.CREATE_MODULE,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }
 


  function EDIT_MODULE(Name,Description,RelDevelopLangId,NPMPackage,Version,RelWorkSpaceId,id) {
    var js = {
      Name: Name,
      Description:Description,
      RelDevelopLangId:RelDevelopLangId,
      NPMPackage:NPMPackage,
      Version:Version,
      RelWorkSpaceId:RelWorkSpaceId,
      id:id
    };
    return MakeReqestAndGetResponse(
      GetBaseERPURL() + ModulesEndpoint.EDIT_MODULE,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }
 



  function UPDATE_NPM_PACKAGE(NPMPackage,RelWorkSpaceId,id) {
    var js = {
      NPMPackage:NPMPackage,
      RelWorkSpaceId:RelWorkSpaceId,
      id:id
    };
    return MakeReqestAndGetResponse(
      GetBaseERPURL() + ModulesEndpoint.UPDATE_NPM_PACKAGE,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }
 