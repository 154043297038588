import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Translation } from "react-i18next";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { ActionCreators as UndoActionCreators } from 'redux-undo'
import Emitter from "_actions/emitter";
import i18n from "i18n";
import { ResponseStatusCode } from "_helpers";
import { WorkspaceService, ModulesService, TransactionService } from "_services";
import { Button, Alert } from 'rsuite';
import { updateSelectedService, updatedModels, updatedTransactions, updateSelectedModel } from '_actions';
import { AutoComplete, SelectPicker, List, IconButton, Icon } from 'rsuite';

import {

    Input,
    InputGroup,
    InputGroupAddon,
    Modal,
    Container,
    Row, Col
} from "reactstrap";
const axios = require('axios');


const WAIT_INTERVAL = 500;
const ENTER_KEY = 13;


class EditNPMPackages extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: false,
            checkLoading: false,
            search: "",
            myList: [],
            data: []
        }


    }

    handleChange = (event) => {
        const { target } = event;
        var theEvent = event || window.event;
        clearTimeout(this.timer);

        this.setState({
            search: event.target.value.toLowerCase(),
        });

        //this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL);
    }


    handleChangeAuto = (value, event) => {
        clearTimeout(this.timer);
        this.setState({
            search: value.toLowerCase(),
        });
        // this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL);

    }


    triggerChange = () => {
        this.checkNPM()
    }
    onKeyDown = (event) => {
      //  debugger
        if (event.key === 'Enter') {
            this.triggerChange();
            event.preventDefault()
        }
    }


    checkNPM = () => {
        // https://api.npms.io/v2/package/
        const that = this;
       
        if (this.state.search.length > 2) {
            that.setState({
                checkLoading: true,
            })
            axios.get('https://api.npms.io/v2/package/' + encodeURIComponent(this.state.search))
                .then(function (response) {
                    let _list = [...that.state.myList];
                    //debugger
                    if (_list.find(x => x.packageName == response.data.collected.metadata.name)) {
                        Alert.warning(i18n.t("MESSAGE_X_PACKAGE_ALREADY_IN"))
                    } else {
                        _list.push({
                            "packageName": response.data.collected.metadata.name
                        })
                        Alert.success(i18n.t("MESSAGE_X_PACKAGE_ADDED"))

                        that.setState({
                            search: "",
                            myList: _list,
                        })
                    }
                    that.setState({ checkLoading: false })
                })
                .catch(function (error) {
                    //  debugger
                    that.setState({ checkLoading: false })
                    Alert.error(that.state.search + " "+i18n.t("MESSAGE_X_PACKAGE_NOT_FOUND"))
                    // handle error
                    // console.log(error);
                })
                .then(function () {
                    that.setState({ checkLoading: false })
                    // always executed
                });
        }
    }

    searchNPM = () => {
        const that = this;
        if (this.state.search.length > 2) {
            axios.get('https://api.npms.io/v2/search?q=' + this.state.search)
                .then(async function (response) {
                    let singleList = [];
                    await response.data.results.forEach(element => {
                        // debugger
                        singleList.push({
                            label: element.package.name,
                            value: element.package.name//element.package
                        })
                    });

                    that.setState({
                        data: singleList,
                        dataAll: response.data.results
                    })
                    //  debugger;
                })
                .catch(function (error) {
                    // handle error
                    // console.log(error);
                })
                .then(function () {
                    // always executed
                });
        }

    }


    handleUpdateNPM = () => {
        const { module, workspace, clearHistory, onRefresh, handleToggle, } = this.props;
        const that = this;
        that.setState({ isLoading: true })
        ModulesService.UPDATE_NPM_PACKAGE(
            this.state.myList,
            workspace.id,
            module.id
        ).then(data => {
            debugger
            that.props.onRefresh();
            Alert.success(i18n.t("MESSAGE_NPM_SUCCESS_SAVED"))

            handleToggle();
        }).catch(err => {
            Alert.warning(ResponseStatusCode(err.statusCode))
            that.setState({ isLoading: false })
        });
    }

    resetContent = () => {
        this.setState({
            isLoading: false,
            search: "",
            checkLoading: false,
            myList: [...(this.props.module ? this.props.module.npmPackage : [])],
            data: []

        })

    }



    componentDidUpdate(prevProps) {
        if (this.props.isActive)
            if (prevProps.isActive != this.props.isActive && this.props.isActive) {
                this.resetContent();
            }
    }






    render() {
        const { isActive, handleToggle, module } = this.props;
        const { isLoading, checkLoading, myList } = this.state;
        return (
            <Translation>
                {t => (
                    <>
                        <Modal
                            className="modal-dialog-centered"
                            isOpen={isActive}
                            //size="lg"
                            scrollable={true}
                        >
                            <div>
                                <div className="modal-header store-model-header">
                                    <h5 className="modal-title" id="CheckProjectModal">
                                        {t("MODAL_NPM_PACKAGES")}
                                    </h5>
                                    <button
                                        aria-label="Close"
                                        className="close"
                                        data-dismiss="modal"
                                        type="button"
                                        disabled={this.state.isLoading}
                                        onClick={() => handleToggle()}
                                    >
                                        <span aria-hidden={true}>×</span>
                                    </button>
                                </div>
                                <div className={"store-header"}>
                                    <InputGroup >
                                        <Input
                                            innerRef={(input) => { setTimeout(() => { try { input.focus() } catch (error) { } }, 300); }}
                                            autoFocus
                                            name={"search"}
                                            value={this.state.search}
                                            onBlur={() => { }}
                                            disabled={checkLoading}
                                            onKeyDown={this.onKeyDown.bind(this)}
                                            //  onBlur={() => { this.onBlur() }}
                                            onInput={this.handleChange.bind(this)}
                                            className={"form-control-sm property-dropdown-input " + ((this.state.type !== "full") ? "" : "property-dropdown-input-display")}
                                            placeholder="" type="text" />
                                        <InputGroupAddon addonType="prepend">
                                            <Button appearance={"ghost"} loading={checkLoading} onClick={this.triggerChange} style={{
                                                padding: "0 1em",
                                                borderRadius: "0.25rem",
                                                borderTopLeftRadius: "0px",
                                                borderBottomLeftRadius: "0px"
                                            }} className={"form-control-sm"} >{t("BUTTON_ADD")}</Button>
                                        </InputGroupAddon>
                                    </InputGroup>

                                </div>
                            </div>
                            <div style={{ paddingBottom: "5px" }} className="modal-body store-model-body">
                                {
                                    this.state.myList.length > 0 ?
                                        <div >
                                            <h5 style={{
                                                margin: 0,
                                                paddingBottom: "10px",
                                                color: "#32b1c1"
                                                // borderBottom: "1px solid red",

                                            }}>
                                                {t("NPM_LIST_TITLE")}
                                                <span style={{
                                                    color: "#9d9d9f",
                                                    fontSize: ".8em"
                                                }}>
                                                    {" (" + this.state.myList.length + ")"}</span>
                                            </h5>
                                            <List hover bordered size={"sm"} style={{ borderRadius: "4px" }}>
                                                {
                                                    this.state.myList.map((item, key) => {
                                                        return (
                                                            <List.Item
                                                                style={{
                                                                    boxShadow: "0 -1px 0 #e5e5ea61, 0 1px 0 #e5e5ea66"
                                                                }}
                                                                key={key} index={key}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    justifyContent: "space-between",
                                                                    alignItems: "center"
                                                                }}>
                                                                    <span >{item.packageName}</span>
                                                                    <IconButton onClick={() => {
                                                                        let _list = [...this.state.myList];
                                                                        _list.splice(key, 1);
                                                                        this.setState({
                                                                            myList: _list
                                                                        })
                                                                    }} appearance={"subtle"} size="sm" color={"red"} icon={<Icon icon="trash-o" />} />
                                                                </div>
                                                            </List.Item>
                                                        )
                                                    })
                                                }

                                            </List>
                                        </div>
                                        :
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            flexDirection: "column",
                                            fontSize: "14px",
                                            color: "#bfbfbf",
                                        }}>
                                            <Icon style={{
                                                color: "#b0bbc5c2",
                                                fontSize: "3em",
                                                marginTop: "1rem",
                                                marginBottom: "10px",
                                            }} icon={"cubes"} />
                                           {t("NPM_LIST_EMPTY")}
                                        </div>
                                }


                            </div>
                            <div className="modal-footer">

                                <Button
                                    size={"sm"}
                                    data-dismiss="modal"
                                    disabled={this.state.isLoading || checkLoading}
                                    type="button"
                                    onClick={() => handleToggle()}
                                >
                                    {t("BUTTON_CANCEL")}
                                </Button>
                                {
                                    module &&
                                    <Button disabled={checkLoading || (JSON.stringify(module.npmPackage) == JSON.stringify(myList))} loading={this.state.isLoading} appearance="primary" size={"sm"} type="button" onClick={() => { this.handleUpdateNPM() }}>
                                        {t("BUTTON_SAVE")}
                                    </Button>
                                }



                            </div>
                            {this.state.alert}
                        </Modal>

                    </>

                )}
            </Translation>
        )
    }
}

const mapStateToProps = (state, props) => {
    const { workspaceReducer } = state;
    const { models, workspaces, workspace, selectedService, services, modules, module } = workspaceReducer;

    return {
        workspaces,
        workspace,
        modules,
        module,
        selectedService,
        services,
        models
    };
}


const mapDispatchToProps = {
    updateSelectedService,
    updatedModels,
    updateSelectedModel,
    updatedTransactions
}

export default connect(mapStateToProps, mapDispatchToProps)(EditNPMPackages)
