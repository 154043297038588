import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import Emitter from "_actions/emitter";
import i18n from "i18n";
import { ResponseStatusCode } from "_helpers";
import { ModelsService } from "_services";

import { Button, Alert, SelectPicker, CheckTreePicker, Icon } from "rsuite";

import { Input, Modal, Container, Row, Col } from "reactstrap";

import {
  updateServices,
  updateSelectedService,
  updatedModels,
  updateSelectedModel,
} from "_actions";

class CreateEditRelationTab extends Component {
  constructor(props) {
    super(props);
    const that = this;
    const _fp =
      props.refData && props.refData.foreignProperty
        ? props.allServices.find(
            (x) => x.id == props.refData.foreignTransaction
          )
        : null;

    this.state = {
      customStyles: {
        TabName: props.refData ? props.refData.tabName[0].textValue : "",
        CurrentProperty: props.refData ? props.refData.currentProperty : null,
        Type: props.refData ? props.refData.type : "=",
        ForeignTransaction: props.refData
          ? props.refData.foreignTransaction
          : null,
        ForeignProperty: props.refData
          ? props.refData.foreignProperty.name
          : null,
      },
      baseForeign: props.refData ? (_fp ? _fp : null) : null,
      // baseForeignFields: null,
      loading: false,
      counter: 0,
    };
    debugger;
    if (_fp) {
      this.generateModelObject(that);
    }
  }
  handleCloseModal = () => {
    const { handleToggle } = this.props;
    handleToggle();
  };
  componentDidMount() {
    if (this.props.refData) {
      this.generateModelObject(this);
    }
  }

  getObjectFields = (data, ref) => {
    const { allTransactions } = this.props;
    const that = this;
    let tableModel = [];
    let refService = null;
    switch (data.contentType) {
      case 5:
        refService = allTransactions.find(
          (x) => x.model.id === data.relationModel.relModel.id
        )?.model;
        tableModel = refService ? [...refService.dataTypes] : [];

        break;
      case 10:
        refService = allTransactions.find(
          (x) => x.model.id === data.refModel.id
        )?.model; //allServices.find((x) => x.relBaseModelId === data.refModel.id);
        tableModel = refService ? [...refService.dataTypes] : [];

        break;

      default:
        break;
    }

    let allFields = [];
    tableModel.forEach((e) => {
      let _obj = {
        label: (ref ? ref.name : data.name) + "." + e.name,
        value: (ref ? ref.name : data.name) + "." + e.name,
        name: (ref ? ref.name : data.name) + "." + e.name,
        refData: e,
      };
      switch (e.contentType) {
        case 1:
          _obj["inputType"] = "text";
          allFields.push(_obj);
          break;
        case 2:
          _obj["inputType"] = "textarea";
          allFields.push(_obj);
          break;
        case 3:
          _obj["inputType"] = "number";
          allFields.push(_obj);
          break;
        case 4:
          _obj["inputType"] = "date";
          allFields.push(_obj);
          break;
        case 7:
          _obj["valueEditorType"] = "checkbox";
          allFields.push(_obj);
          break;
        case 5:
          _obj["inputType"] = "text";
          // _obj.label = ref.name + "." + e.name;
          //  _obj.value = ref.name + "." + e.name;
          // _obj.name = ref.name + "." + e.name;

          allFields.push(_obj);
          const c5 = this.getObjectFields(e, _obj);

          allFields = [...allFields, ...c5];
          break;
        case 10:
          _obj["inputType"] = "text";
          // _obj.label = ref.name + "." + e.name;
          //  _obj.value = ref.name + "." + e.name;
          // _obj.name = ref.name + "." + e.name;

          allFields.push(_obj);
          const c10 = this.getObjectFields(e, _obj);

          allFields = [...allFields, ...c10];
          break;
        case 14:
          _obj = null;
          break;
        default:
          _obj = {
            label: (ref ? ref.name : data.name) + "." + e.name,
            value: (ref ? ref.name : data.name) + "." + e.name,
            name: (ref ? ref.name : data.name) + "." + e.name,
            refData: e,
          };
          allFields.push(_obj);
          break;
      }
    });

    return allFields;
  };

  generateModelObject = (that) => {
    debugger;
    const model =
      this.state.baseForeign.responseModelType == 1
        ? that.props.model.find(
            (x) => x.id == this.state.baseForeign.relResponseModelId
          )
        : that.props.model.find(
            (x) => x.id == this.state.baseForeign.reqModelId
          ); //this.state.baseForeign.model;
    const tableModel = model ? model.dataTypes : this.state.baseForeign.model;

    if (tableModel) {
      let allFields = [];
      tableModel.forEach((e) => {
        let _obj = {
          label: e.displayName,
          value: e.name,
          name: e.name,
          refData: e,
        };

        switch (e.contentType) {
          case 1:
            _obj["inputType"] = "text";
            allFields.push(_obj);
            break;
          case 2:
            _obj["inputType"] = "textarea";
            allFields.push(_obj);
            break;
          case 3:
            _obj["inputType"] = "number";
            allFields.push(_obj);
            break;
          case 4:
            _obj["inputType"] = "date";
            allFields.push(_obj);
            break;
          case 7:
            _obj["valueEditorType"] = "checkbox";
            allFields.push(_obj);
            break;
          case 5:
            _obj["inputType"] = "text";
            _obj.label = e.name;
            allFields.push(_obj);

            const c5 = this.getObjectFields(e, _obj);

            allFields = [...allFields, ...c5];

            break;
          case 10:
            _obj["inputType"] = "text";
            _obj.label = e.name;
            allFields.push(_obj);
            const c10 = this.getObjectFields(e, _obj);

            allFields = [...allFields, ...c10];

            break;
          case 14:
            _obj = null;
            break;
          default:
            _obj = {
              label: e.displayName,
              value: e.name,
              name: e.name,
              refData: e,
            };
            allFields.push(_obj);
            break;
        }
      });
      debugger;
      that.setState({
        baseForeignFields: allFields,
      });
    }
  };

  handleSubmit = (e) => {
    const that = this;
    const { baseForeign } = this.state;
    const _cData = this.state.customStyles;
    const { refData, selectedService, allServices, allTransactions } =
      this.props;

    let nData = {
      TabName: [
        {
          lang: "en",
          textValue: _cData.TabName,
        },
        {
          lang: "de",
          textValue: _cData.TabName,
        },
        {
          lang: "tr",
          textValue: _cData.TabName,
        },
      ],
      CurrentProperty: _cData.CurrentProperty,
      Type: _cData.Type,
      ForeignTransaction: _cData.ForeignTransaction,
      ForeignProperty: _cData.ForeignProperty,
    };
    const _eb = allTransactions.find(
      (x) => x.model.id == this.props.selectedService.reqModelId
    );
    debugger;
    let _myExtras = _eb.model.hasOwnProperty("relationTabs")
      ? [..._eb.model.relationTabs]
      : [];

    if (refData) {
      _myExtras[refData.key] = nData;
    } else {
      _myExtras.push(nData);
    }
    debugger;
    this.handleSaveModel(_myExtras);

    // const { formValue, formError } = this.state;

    //this.props.handleUpdateRow(this.state.formValue);
    //this.handleCloseModal();
  };

  handleSaveModel = (_extras) => {
    const { selectedService, isRefModel, handleToggleAll, mSelected } =
      this.props;

    const that = this;

    const relationsTabs = [
      ...(mSelected.relationsTabs ? mSelected.relationsTabs : []),
      ..._extras,
    ];

    that.setState({
      loading: true,
    });
    debugger;
    ModelsService.UpdateRelationTabs(
      selectedService[isRefModel ? "id" : "relBaseModelId"],
      relationsTabs
    )
      .then((data) => {
        that.setState({ loading: false });
        if (data.operationResult) {
          //      that.saveTrans();
          Emitter.emit("UPDATE_ALL_TRANS", null);
          handleToggleAll();
        }
      })
      .catch((err) => {
        Alert.error("Error code : " + err.statusCode);
        that.setState({ loading: false });
      });
  };

  customStylesForm = (e, stateName) => {
    let newState = this.state.customStyles;
    newState[stateName] = e.target.value;

    if (stateName === "checkbox") {
      if (e.target.value) {
        newState.checkboxState = "valid";
      } else {
        newState.checkboxState = "invalid";
      }
    } else {
      if (e.target.value === "") {
        newState[stateName + "State"] = "invalid";
      } else {
        newState[stateName + "State"] = "valid";
      }
    }
    this.setState({
      customStyles: newState,
    });
  };

  onKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      this.validateCustomStylesForm();
    }
  };

  validateCustomStylesForm = () => {
    let newState = this.state.customStyles;
    newState.TabName = newState.TabName.trim();

    var regex = /^.{1,50}$/;

    if (newState.TabName === "") {
      newState.TabNameState = "invalid";
    } else if (!regex.test(newState.TabName)) {
      newState.TabNameState = "invalid";
    } else {
      newState.TabNameState = "valid";
    }
    debugger;

    if (!newState.CurrentProperty) {
      newState.CurrentPropertyState = "invalid";
      Alert.error(i18n.t("TITLE_MODAL_CREATE_EXTRABUTTON_TRANS_INVALIDE"));
    } else {
      newState.CurrentPropertyState = "valid";
    }

    if (!newState.Type) {
      newState.TypeState = "invalid";
      Alert.error(i18n.t("TITLE_MODAL_CREATE_EXTRABUTTON_TRANS_INVALIDE"));
    } else if (!regex.test(newState.Type)) {
      newState.TypeState = "invalid";
    } else {
      newState.TypeState = "valid";
    }

    if (!newState.ForeignTransaction) {
      newState.ForeignTransactionState = "invalid";
      Alert.error(i18n.t("TITLE_MODAL_CREATE_EXTRABUTTON_TRANS_INVALIDE"));
    } else {
      newState.ForeignTransactionState = "valid";
    }

    if (!newState.ForeignProperty) {
      newState.ForeignPropertyState = "invalid";
      Alert.error(i18n.t("TITLE_MODAL_CREATE_EXTRABUTTON_TRANS_INVALIDE"));
    } else {
      newState.ForeignPropertyState = "valid";
    }

    this.setState(
      {
        customStyles: newState,
      },
      () => {
        if (
          newState.TabNameState == "valid" &&
          newState.CurrentPropertyState == "valid" &&
          newState.TypeState == "valid" &&
          newState.ForeignTransactionState == "valid" &&
          newState.ForeignPropertyState == "valid"
        ) {
          this.handleSubmit();
        }
      }
    );
  };

  handleDelete = () => {
    const { allTransactions, refData } = this.props;
    const _eb = allTransactions.find(
      (x) => x.model.id == this.props.selectedService.reqModelId
    );
    debugger;

    let _myExtras = _eb.model.hasOwnProperty("relationTabs")
      ? [..._eb.model.relationTabs]
      : [];

    if (refData) {
      _myExtras.splice(refData.key, 1);
      this.handleSaveModel(_myExtras);
    }
  };

  render() {
    const { isActive, handleToggle, selectedService, allServices, refData } =
      this.props;
    const { loading } = this.state;
    const that = this;
    return (
      <Translation>
        {(t) => (
          <>
            <Modal
              className="modal-dialog-centered"
              isOpen={isActive}
              //size="lg"
              //toggle={this.handleCloseModal}
              scrollable={true}
            >
              <div className="modal-body" style={{ padding: 0 }}>
                <div className="modal-header">
                  <h5 className="modal-title" id="ShowModelExtra">
                    {t(
                      refData
                        ? "TITLE_MODAL_EDIT_RELATION_TAB"
                        : "TITLE_MODAL_CREATE_RELATION_TAB"
                    )}
                  </h5>
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => this.handleCloseModal()}
                  >
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
              </div>
              <div className="modal-body">
                <Container>
                  <Row>
                    <label
                      className="form-control-label-name"
                      htmlFor="validationCustomButtonCaptions"
                    >
                      {t("TITLE_MODAL_CREATE_RELATIONTAB_NAME")}
                    </label>
                    <Input
                      //innerRef={(input) => { setTimeout(() => { try { input.focus() } catch (error) { } }, 300); }}
                      autoFocus
                      aria-describedby="inputGroupPrepend"
                      placeholder={t("TITLE_MODAL_CREATE_RELATIONTAB_NAME")}
                      type="text"
                      defaultValue={this.state.customStyles.TabName}
                      id="validationCustomButtonCaptions"
                      onKeyDown={this.onKeyDown.bind(this)}
                      valid={this.state.customStyles.TabNameState === "valid"}
                      invalid={
                        this.state.customStyles.TabNameState === "invalid"
                      }
                      disabled={loading}
                      onChange={(e) => this.customStylesForm(e, "TabName")}
                    />

                    <div className="invalid-feedback">
                      {t("TITLE_MODAL_CREATE_RELATIONTAB_NAME")}
                    </div>
                  </Row>

                  <Row style={{ marginTop: "10px", flexDirection: "column" }}>
                    <label
                      className="form-control-label"
                      htmlFor="validationCustomTrans"
                    >
                      {t("TITLE_MODAL_CREATE_RELATIONTAB_C_FIELD")}
                    </label>
                    <SelectPicker
                      onChange={(value) => {
                        let _cs = this.state.customStyles;
                        debugger;
                        if (value == "_id") {
                          _cs.CurrentProperty = {
                            name: "_id",
                          };
                        } else {
                          const _m = selectedService.model.find(
                            (x) => x.name == value
                          );

                          _cs.CurrentProperty = _m;
                        }

                        _cs.CurrentPropertyState = "valid";
                        this.setState({ customStyles: _cs });
                      }}
                      //  inline
                      placement="autoVerticalStart"
                      placeholder={t("TITLE_MODAL_CREATE_RELATIONTAB_C_FIELD")}
                      block
                      data={[
                        {
                          value: "_id",
                          label: "_id",
                        },
                        ...selectedService.model.map((item, key) => {
                          return {
                            value: item.name,
                            label: item.name,
                          };
                        }),
                      ]}
                      cleanable={false}
                      defaultValue={
                        this.state.customStyles.CurrentProperty
                          ? this.state.customStyles.CurrentProperty.name
                          : null
                      }
                    />
                  </Row>
                  <Row style={{ marginTop: "10px", flexDirection: "column" }}>
                    <label
                      className="form-control-label"
                      htmlFor="validationCustomTrans"
                    >
                      {t("TITLE_MODAL_CREATE_RELATIONTAB_TYPE")}
                    </label>
                    <SelectPicker
                      onChange={(value) => {
                        let _cs = this.state.customStyles;
                        _cs.Type = value;
                        _cs.TypeState = "valid";
                        this.setState({ customStyles: _cs });
                      }}
                      //  inline
                      defaultValue={
                        this.props.refData ? this.props.refData.type : "="
                      }
                      placement="autoVerticalStart"
                      placeholder={t("TITLE_MODAL_CREATE_RELATIONTAB_TYPE")}
                      block
                      data={[{ value: "=", label: "=" }]}
                      cleanable={false}
                    />
                  </Row>
                  <Row style={{ marginTop: "10px", flexDirection: "column" }}>
                    <label
                      className="form-control-label"
                      htmlFor="validationCustomTrans"
                    >
                      {t("TITLE_MODAL_CREATE_RELATIONTAB_T_TRANSACTION")}
                    </label>
                    <SelectPicker
                      onChange={(value) => {
                        const _fd = allServices.find(
                          (item) => item.id == value
                        );

                        debugger;
                        let _cs = this.state.customStyles;
                        _cs.ForeignTransaction = value;
                        _cs.ForeignTransactionState = "valid";
                        this.setState(
                          {
                            customStyles: _cs,
                            baseForeign: _fd,
                            baseForeignFields: null,
                          },
                          () => {
                            this.generateModelObject(that);
                          }
                        );
                      }}
                      //  inline
                      placement="autoVerticalStart"
                      placeholder={t(
                        "TITLE_MODAL_CREATE_RELATIONTAB_T_TRANSACTION"
                      )}
                      block
                      data={[
                        ...allServices.map((item, key) => {
                          return {
                            value: item.id,
                            label:
                              item.shortName.length > 0
                                ? "(" + item.shortName + ") - " + item.name
                                : item.name,
                          };
                        }),
                      ]}
                      cleanable={false}
                      defaultValue={this.state.customStyles.ForeignTransaction}
                    />
                  </Row>

                  {this.state.baseForeign && this.state.baseForeignFields && (
                    <Row style={{ marginTop: "10px", flexDirection: "column" }}>
                      <label
                        className="form-control-label"
                        htmlFor="validationCustomTrans"
                      >
                        {t("TITLE_MODAL_CREATE_RELATIONTAB_T_FIELD")}
                      </label>
                      <SelectPicker
                        onChange={(value) => {
                          let _cs = this.state.customStyles;

                          const _data = this.state.baseForeignFields.find(
                            (x) => x.name == value
                          );
                          _cs.ForeignProperty = _data.refData;
                          _cs.ForeignProperty.name = _data.name;

                          _cs.ForeignPropertyState = "valid";
                          this.setState({ customStyles: _cs });
                        }}
                        //  inline
                        placement="autoVerticalStart"
                        placeholder={t(
                          "TITLE_MODAL_CREATE_RELATIONTAB_T_FIELD"
                        )}
                        block
                        data={this.state.baseForeignFields}
                        cleanable={false}
                        defaultValue={
                          this.state.customStyles.ForeignProperty
                            ? this.state.customStyles.ForeignProperty
                            : null
                        }
                      />
                    </Row>
                  )}
                </Container>
              </div>
              <div className="modal-footer">
                <Button
                  size={"sm"}
                  data-dismiss="modal"
                  disabled={loading}
                  type="button"
                  onClick={() => {
                    const that = this;
                    debugger;
                  }}
                >
                  {t("TEST")}
                </Button>

                <Button
                  size={"sm"}
                  data-dismiss="modal"
                  disabled={loading}
                  type="button"
                  onClick={() => handleToggle()}
                >
                  {t("BUTTON_CANCEL")}
                </Button>

                {refData && (
                  <Button
                    loading={loading}
                    appearance="ghost"
                    color="red"
                    size={"sm"}
                    type="button"
                    onClick={() => this.handleDelete()}
                  >
                    {t("BUTTON_DELETE")}
                  </Button>
                )}

                <Button
                  loading={loading}
                  appearance="primary"
                  size={"sm"}
                  type="button"
                  onClick={() => this.validateCustomStylesForm()}
                >
                  {t(refData ? "BUTTON_EDIT" : "BUTTON_CREATE")}
                </Button>
              </div>
            </Modal>
          </>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer } = state;
  const {
    workspace,
    selectedService,
    dataModels,
    modules,
    module,
    models,
    model,
    allTransactions,
    allServices,
  } = workspaceReducer;

  return {
    workspace,
    selectedService,
    dataModels,
    modules,
    module,
    models,
    model,
    allTransactions,
    allServices,
  };
};
const mapDispatchToProps = {
  updateServices,
  updateSelectedService,
  updatedModels,
  updateSelectedModel,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateEditRelationTab);
