import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import moment from "moment";
import Emitter from "_actions/emitter";
import i18n from "i18n";
import { ResponseStatusCode } from "_helpers";
import { updateStore, updateStoreCategories } from "_actions";
import {
  WorkspaceService,
  ModulesService,
  CategoryService,
  StoreService,
} from "_services";
import {
  InputGroup,
  Input,
  Icon,
  Alert,
  SelectPicker,
  ButtonToolbar,
} from "rsuite";
import { Nav, Dropdown } from "rsuite";
import {
  Loader,
  Avatar,
  Container,
  Row,
  Col,
  Panel,
  Placeholder,
  Badge,
  Button,
} from "rsuite";
import { NavLink } from "react-router-dom";
import CreateStorePlugin from "./CreateStorePlugin";
import UpdateVersionStorePlugin from "./UpdateVersionStorePlugin";
import { Modal } from "reactstrap";
import AvatarCard from "components/Custom/AvatarCard";
import Lightbox from "components/CoolLightbox";

const { Paragraph } = Placeholder;
let LANG = i18n.t("LANG");
const WAIT_INTERVAL = 500;
const ENTER_KEY = 13;

class ShowStoreList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      search: "",
      category: "-1",
      active: "plugins",
      isCreate: false,
      isClone: false,
      mystore: null,
      selectedPlugin: null,
      updatedTime: new Date() + "",
    };
  }

  resetContent = () => {
    this.getStore();
    this.getCategories();
    this.setState({
      isLoading: true,
      search: "",
      category: "-1",
      active: "plugins",
      isCreate: false,
      isClone: false,
      mystore: null,
      selectedPlugin: null,
      updatedTime: new Date() + "",
    });
  };
  componentDidUpdate(prevProps) {
    if (this.props.isActive)
      if (prevProps.isActive != this.props.isActive && this.props.isActive) {
        this.resetContent();
      }
  }
  handleCreatePlugin = () => {
    this.setState({ isCreate: !this.state.isCreate });
  };

  handleClonePlugin = (data = null) => {
    this.setState({ isClone: !this.state.isClone, selectedPlugin: data });
  };

  triggerChange = () => {
    this.getStore(this.state.search, this.state.category);
  };

  componentWillMount() {
    this.timer = null;
  }

  handleChange = (event) => {
    const { target } = event;
    var theEvent = event || window.event;
    clearTimeout(this.timer);

    this.setState({
      search: event.target.value,
    });

    this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL);
  };

  handleChangeCategory = (value) => {
    this.setState(
      {
        category: value,
      },
      this.triggerChange()
    );
  };

  getCategories = () => {
    const { updateModules, workspace, module, updateStoreCategories } =
      this.props;
    const that = this;
    that.setState({ isLoading: true });
    CategoryService.GET_W_LANG(LANG)
      .then((data) => {
        if (data.operationResult) {
          updateStoreCategories(data.dynamicValue);
        }
        that.setState({ isLoading: false });
      })
      .catch((err) => {
        that.setState({ isLoading: false });
        debugger;
      });
  };

  getStore = (_search = "", id = "-1") => {
    const { updateModules, workspace, module, updateStore, onRefresh } =
      this.props;
    const that = this;
    that.setState({ isLoading: true });
    this.getMyStore(_search, id);
    StoreService.STEPBYLOAD(true, "-1", 9999, _search, workspace.id, id)
      .then((data) => {
        if (data.operationResult) {
          updateStore(data.dynamicValue);
        }
        that.setState({ isLoading: false });
      })
      .catch((err) => {
        that.setState({ isLoading: false });
        debugger;
      });
  };
  getMyStore = (_search = "", id = "-1") => {
    const { updateModules, workspace, module, updateStore } = this.props;
    const that = this;
    that.setState({ isLoading: true });

    StoreService.STEPBYLOAD_OWN(true, "-1", 9999, _search, workspace.id, id)
      .then((data) => {
        if (data.operationResult) {
          that.setState({ mystore: data.dynamicValue });
        }
        that.setState({ isLoading: false });
      })
      .catch((err) => {
        that.setState({ isLoading: false });
        debugger;
      });
  };

  AddToWorkspace = (_plugin) => {
    const { updateModules, workspace, module, updateStore, onRefresh } =
      this.props;
    const that = this;
    that.setState({ isLoading: true });
    debugger;
    ModulesService.GET_FROM_STORE(_plugin.id, workspace.id, _plugin.version)
      .then((data) => {
        debugger;
        if (data.operationResult) {
          Alert.success(i18n.t("Success"));
          onRefresh("", workspace.id, true);
          that.props.handleToggle();
        }
        that.setState({ isLoading: false });
      })
      .catch((err) => {
        that.setState({ isLoading: false });
        debugger;
      });
  };

  UpdateVersion = (_plugin) => {
    const { updateModules, workspace, module, updateStore, onRefresh } =
      this.props;
    const that = this;
    that.setState({ isLoading: true });
    debugger;
    ModulesService.UPDATE_FROM_STORE(_plugin.id, workspace.id, _plugin.version)
      .then((data) => {
        debugger;
        if (data.operationResult) {
          Alert.success(i18n.t("Success"));
          that.resetContent();
          onRefresh("", workspace.id, true);
          //that.props.handleToggle();
        }
        that.setState({ isLoading: false });
      })
      .catch((err) => {
        that.setState({ isLoading: false });
        debugger;
      });
  };

  handleSelect = (activeKey) => {
    this.setState({ active: activeKey });
  };
  render() {
    const { isActive, handleToggle, store, storeCategories } = this.props;
    const { isLoading, mystore, active } = this.state;
    return (
      <Translation>
        {(t) => (
          <>
            <Modal
              className="modal-dialog-centered"
              isOpen={isActive}
              size="lg"
              scrollable={true}
            >
              <div>
                <div className="modal-header store-model-header">
                  <h5 className="modal-title" id="CheckProjectModal">
                    {t("TITLE_MODAL_STORE")}
                  </h5>
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    disabled={this.state.isLoading}
                    onClick={() => handleToggle()}
                  >
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
                <div className={"store-header"}>
                  <Nav
                    appearance="subtle"
                    activeKey={this.state.active}
                    onSelect={this.handleSelect}
                    style={{}}
                  >
                    <Nav.Item eventKey="plugins">
                      {t("TITLE_MODAL_STORE_SUB")}
                    </Nav.Item>
                    <Nav.Item eventKey="myplugins">
                      {t("MODAL_STORE_PUBLISH_TAB")}
                    </Nav.Item>
                  </Nav>
                  <div className={"store-header-sub"}>
                    <Button
                      onClick={this.handleCreatePlugin}
                      appearance="primary"
                      style={{ marginRight: "10px" }}
                      size="sm"
                    >
                      {t("BUTTON_CREATE")}
                    </Button>
                    <SelectPicker
                      className={"store-header-input"}
                      cleanable={false}
                      defaultValue={"-1"}
                      style={{ marginRight: "10px" }}
                      size="sm"
                      onChange={(value) => {
                        this.handleChangeCategory(value);
                      }}
                      placeholder={t("STORE_CATEGORIES")}
                      data={[
                        {
                          value: "-1",
                          label: t("OPTION_ALL"),
                        },
                        ...storeCategories.map((item, key) => {
                          return {
                            value: item.id,
                            label: item.shortName,
                          };
                        }),
                      ]}
                    />
                    <InputGroup size="sm" inside style={{ width: "150px" }}>
                      <Input
                        value={this.state.search}
                        onBlur={() => {}}
                        // onKeyDown={this.onKeyDown.bind(this)}
                        onInput={(value) => {
                          this.handleChange(value);
                        }}
                        placeholder={t("PLACEHOLDER_SEARCH")}
                      />
                      <InputGroup.Button>
                        <Icon icon={"search"} />
                      </InputGroup.Button>
                    </InputGroup>
                  </div>
                </div>
              </div>

              <div className="modal-body store-model-body">
                <Container>
                  {active === "plugins" ? (
                    <Row>
                      {store &&
                        store.dynamicValue.map((item, key) => (
                          <Col
                            key={key + "-" + this.state.updatedTime}
                            style={{
                              paddingTop: "5px",
                              paddingBottom: "5px",
                            }}
                            sm={24}
                          >
                            <Card
                              handleSelect={() => {
                                this.AddToWorkspace(item);
                              }}
                              handleSelectAndUpdate={() => {
                                this.UpdateVersion(item);
                              }}
                              handleClonePlugin={() => {
                                this.handleClonePlugin(item);
                              }}
                              cID={item.id}
                              demoBadge={false}
                              iData={item}
                              header={item.title}
                              fromThisWorkspace={
                                this.props.workspace.id == item.relWorkspaceId
                              }
                            />
                          </Col>
                        ))}
                    </Row>
                  ) : (
                    <Row>
                      {mystore &&
                        mystore.dynamicValue.map((item, key) => (
                          <Col
                            key={key}
                            style={{
                              paddingTop: "5px",
                              paddingBottom: "5px",
                            }}
                            sm={24}
                          >
                            <Card
                              handleSelect={() => {
                                this.AddToWorkspace(item);
                              }}
                              handleSelectAndUpdate={() => {
                                this.UpdateVersion(item);
                              }}
                              handleClonePlugin={() => {
                                this.handleClonePlugin(item);
                              }}
                              cID={item.id}
                              demoBadge={false}
                              iData={item}
                              header={item.title}
                              fromThisWorkspace={
                                this.props.workspace.id == item.relWorkspaceId
                              }
                            />
                          </Col>
                        ))}
                    </Row>
                  )}

                  {(active === "plugins" ? store : mystore) &&
                    (active === "plugins" ? store : mystore).dynamicValue
                      .length < 1 && (
                      <div className={"store-not-found"}>
                        <Icon icon={"exclamation-triangle"} />
                        {t("STORE_NO_RESULT")}
                      </div>
                    )}
                  {isLoading && (
                    <div className={"fade-in"}>
                      <Loader backdrop vertical />
                    </div>
                  )}
                </Container>
              </div>

              {this.state.alert}
            </Modal>
            <CreateStorePlugin
              onRefresh={this.resetContent}
              isActive={this.state.isCreate}
              handleToggle={this.handleCreatePlugin}
            />

            <UpdateVersionStorePlugin
              data={this.state.selectedPlugin}
              onRefresh={this.resetContent}
              isActive={this.state.isClone}
              handleToggle={this.handleClonePlugin}
            />
          </>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer } = state;
  const { workspace, workspaces, selectedService, store, storeCategories } =
    workspaceReducer;

  return {
    workspace,
    workspaces,
    selectedService,
    store,
    storeCategories,
  };
};

const mapDispatchToProps = {
  updateStore,
  updateStoreCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowStoreList);

const Card = (props) => {
  let pFile = props.iData.presentFiles;
  let pFilePhotos = pFile.filter((p) => p.type === 1);
  let pFilePhotosGallery = [];
  if (pFilePhotos) {
    pFilePhotosGallery = pFilePhotos.map((item) => {
      if (item.convertedImages.length > 0) {
        return {
          ...item.convertedImages[0],
          src: item.convertedImages[0].url,
          alt: "thexapp",
          caption: "caption",
        };
      } else {
        pFilePhotos = [];
        pFilePhotosGallery = [];
      }
    });
  }

  if (pFilePhotos.length < 1) {
    pFilePhotosGallery = [];
  }

  const [isOpen, setOpen] = useState(false);
  const [currentImageIndex, setCurrentIndex] = useState(0);
  return (
    <>
      <Badge content={props.demoBadge} style={{ width: "100%" }}>
        <Panel
          className={"store-item"}
          header={
            <div>
              <AvatarCard
                containerStyle={{
                  padding: 0,
                }}
                style={{
                  marginRight: "10px",
                  width: "50px",
                  height: "50px",
                  borderRadius: "10px",
                }}
                nullStyle={{
                  marginRight: "10px",
                  width: "50px",
                  height: "50px",
                  borderRadius: "10px",
                  color: "transparent",
                  background: " #2baebf3d",
                  borderStyle: "solid",
                  borderWidth: "1px",
                }}
                renderedImages={
                  props.iData.appIcons ? props.iData.appIcons : null
                }
                isEditable={false}
                isSquare={true}
              />
              <div className={"store-item-title"}>
                <div className={"store-header-title"}>
                  {props.header}
                  <span>v{props.iData.version + " "}</span>
                  <span style={{ fontSize: "0.6em" }}>
                    {moment(new Date(props.iData.updatedTime))
                      .endOf("day")
                      .fromNow()}
                  </span>
                </div>
                <ButtonToolbar>
                  {!props.iData.alreadyInstalled && (
                    <Button
                      onClick={() => props.handleSelect()}
                      disabled={props.iData.alreadyInstalled}
                      size="sm"
                      className={"store-add-button"}
                      appearance="ghost"
                    >
                      {i18n.t("BUTTON_ADD")}
                    </Button>
                  )}

                  {props.iData.alreadyInstalled && (
                    <Button
                      onClick={() => props.handleSelectAndUpdate()}
                      disabled={!props.iData.updateAvailable}
                      size="sm"
                      className={"store-add-button custom-fade-in"}
                      appearance="ghost"
                    >
                      {i18n.t("BUTTON_UPDATE")}
                    </Button>
                  )}
                </ButtonToolbar>
              </div>
              {props.fromThisWorkspace && (
                <Button
                  appearance="primary"
                  onClick={props.handleClonePlugin}
                  className="store-item-badge"
                >
                  {i18n.t("CREATE_NEW_VERSION")}
                </Button>
              )}
            </div>
          }
          bordered
          shaded
        >
          <span>
            {props.iData.description.length > 0 ? (
              props.iData.description.find((x) => x.lang === "en").textValue
            ) : (
              <span style={{ fontStyle: "italic", color: "#929292" }}>
                {"- " + i18n.t("STORE_NO_DESCRIPTION")}
              </span>
            )}
          </span>
          <div>
            {pFilePhotos.map((item, key) => {
              return (
                <Button
                  key={key}
                  appearance="link"
                  style={{
                    padding: "5px",
                  }}
                  onClick={() => setOpen(true)}
                >
                  <AvatarCard
                    containerStyle={{
                      padding: 0,
                    }}
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "10px",
                    }}
                    nullStyle={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "10px",
                      color: "transparent",
                      background: " #2baebf3d",
                      borderStyle: "solid",
                    }}
                    renderedImages={item.convertedImages}
                    isEditable={false}
                    isSquare={true}
                  />
                </Button>
              );
            })}
          </div>
        </Panel>
      </Badge>
      {pFilePhotosGallery.length > 0 && (
        <Lightbox
          currentImageIndex={currentImageIndex}
          setCurrentIndex={setCurrentIndex}
          isOpen={isOpen}
          onClose={() => setOpen(false)}
          images={pFilePhotosGallery}
        />
      )}
    </>
  );
};
