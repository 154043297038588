import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { authenticationAction } from "_actions";
import { userService, ProjectService, Get_FRONTEND_CMS_URL,GetCreatorFrontEnd, GetConsoleFrontEnd } from "_services";
import Identicon from 'react-hooks-identicons';
import routes from "routes.js";

import { Alert } from 'rsuite';
import CImage from "components/UI/CImage";

// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Breadcrumb,
  BreadcrumbItem,
  ListGroupItem,
  ListGroup,
  Media,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col
} from "reactstrap";

class MainNavbar extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      mainName: null,
      childName: null,
    }
  }


  componentDidMount = (prevProps) => {
    if (prevProps) {
      if ((prevProps.location.pathname != this.props.location.pathname)) {
        this.findCurrentPath();
      }
    } else {
      this.findCurrentPath();
    }

  }
  componentDidUpdate = (prevProps) => {
    if (prevProps) {
      if ((prevProps.location.pathname != this.props.location.pathname)) {
        this.findCurrentPath();
      }
    } else {
      this.findCurrentPath();
    }
  }

  handleChangePlatform = (e) => {
    const { name } = e.currentTarget;
    /*
      - creator
      - console
      - cms
    */
   //return

    const { currentProjectId } = this.props;
    switch (name) {
      case "console":
        this.GotoConsole(currentProjectId);
        break;
      case "creator":
       this.GotoCreator(currentProjectId)
        break;
      case "cms":
        this.GotoCMS(currentProjectId);
        break;

      default:
        break;
    }

 
  }
  GotoConsole = (ProjectID) => {
    if (ProjectID !== undefined) {
      ProjectService.SetProjectDetail(ProjectID).then(async (res) => {
        if (res.operationResult === true) {
          let prapareLink = GetConsoleFrontEnd() + "auth/" + res.dynamicValue;
          //  window.open(prapareLink,"_blank");
          window.location.href = prapareLink;

        }
      }).catch((err) => {
        console.log(err);
      });
    }
  }
  GotoCMS = (ProjectID) => {
    if (ProjectID !== undefined) {
      ProjectService.SetProjectDetail(ProjectID).then(async (res) => {
        if (res.operationResult === true) {
          let prapareLink = Get_FRONTEND_CMS_URL() + "auth/" + res.dynamicValue;
          //  window.open(prapareLink,"_blank");
          window.location.href = prapareLink;

        }
      }).catch((err) => {
        console.log(err);
      });
    }
  }
  GotoCreator = (ProjectID) => {
    if (ProjectID !== undefined) {
      ProjectService.SetProjectDetail(ProjectID).then(async (res) => {
        if (res.operationResult === true) {
          let prapareLink = GetCreatorFrontEnd() + "auth/" + res.dynamicValue;
          //  window.open(prapareLink,"_blank");
          window.location.href = prapareLink;
        }
      }).catch((err) => {
        console.log(err);
      });
    }
  }

  

  findCurrentPath = () => {
    const that = this;
    const path = this.props.location.pathname.substring(1);
    // debugger
    routes.map((x, key) => {
      if (x.path == path) {
        that.setState({
          mainName: x.name,
          childName: null,
        })
        return;
      }
      if (x.hasOwnProperty("views")) {
        x.views.map((xx) => {
          //  debugger
          if (xx.path == path) {

            that.setState({
              mainName: x.name,
              childName: xx.name,
            })
            return;
          }

        })
      }
    })



  }

  logoutClicked = () => {
    const { loginSuccess, loginFailure, logout } = this.props;
    userService.logout().then(data => {
      //ok olmussa
      logout();
      window.location.reload();

    }).catch(err => { });
  }



  render() {
    const { mainName, childName } = this.state;


    return (
      <>
        <Navbar
          className={classnames(
            "navbar-top navbar-expand border-bottom",
            { "navbar-dark bg-info": this.props.theme === "dark" },
            { "navbar-light bg-white": this.props.theme === "light" }
          )}
        >
          <Container fluid>
            <Collapse navbar isOpen={true}>
              {

                <Form
                  className={classnames(
                    "navbar-search form-inline mr-sm-3",
                    { "navbar-search-light": this.props.theme === "dark" },
                    { "navbar-search-dark": this.props.theme === "light" }
                  )}
                >
                  <FormGroup className="mb-0">
                    {
                      mainName &&
                      <>
                        <h6 className="custom-fade-in h2 d-inline-block mb-0 text-primary">{mainName}</h6>{" "}

                      </>

                    }
                    {
                      childName &&
                      <>
                        <Breadcrumb
                          className="custom-fade-in d-none d-md-inline-block ml-md-1"
                          listClassName="breadcrumb-links" >
                          <BreadcrumbItem aria-current="page" className="active">
                          </BreadcrumbItem>
                          <BreadcrumbItem aria-current="page" className="active">
                            {childName}
                          </BreadcrumbItem>
                        </Breadcrumb>
                      </>
                    }



                  </FormGroup>
                </Form>

              }


              <Nav className="align-items-center ml-md-auto" navbar>
                <NavItem className="d-xl-none">
                  <div
                    className={classnames(
                      "pr-3 sidenav-toggler",
                      { active: !this.props.sidenavOpen },
                      { "sidenav-toggler-dark": this.props.theme === "dark" }
                    )}
                    onClick={this.props.toggleSidenav}
                  >
                    <div className="sidenav-toggler-inner">
                      <i className="sidenav-toggler-line" />
                      <i className="sidenav-toggler-line" />
                      <i className="sidenav-toggler-line" />
                    </div>
                  </div>
                </NavItem>

                <UncontrolledDropdown nav>
                  <DropdownToggle className="nav-link" color="" tag="a">
                    <i className="ni ni-bell-55" />
                  </DropdownToggle>
                  <DropdownMenu
                    className="dropdown-menu-xl py-0 overflow-hidden"
                    right
                  >
                    <div className="px-3 py-3">
                      <h6 className="text-sm text-muted m-0">
                        You have <strong className="text-info">13</strong>{" "}
                        notifications.
                      </h6>
                    </div>

                    <ListGroup flush>
                      <ListGroupItem
                        className="list-group-item-action"
                        href="#thexapp"
                        onClick={e => e.preventDefault()}
                        tag="a"
                      >
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              alt="..."
                              className="avatar rounded-circle"
                              src={require("assets/img/theme/team-1.jpg")}
                            />
                          </Col>
                          <div className="col ml--2">
                            <div className="d-flex justify-content-between align-items-center">
                              <div>
                                <h4 className="mb-0 text-sm">John Snow</h4>
                              </div>
                              <div className="text-right text-muted">
                                <small>2 hrs ago</small>
                              </div>
                            </div>
                            <p className="text-sm mb-0">
                              Let's meet at Starbucks at 11:30. Wdyt?
                            </p>
                          </div>
                        </Row>
                      </ListGroupItem>
                      <ListGroupItem
                        className="list-group-item-action"
                        href="#thexapp"
                        onClick={e => e.preventDefault()}
                        tag="a"
                      >
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              alt="..."
                              className="avatar rounded-circle"
                              src={require("assets/img/theme/team-2.jpg")}
                            />
                          </Col>
                          <div className="col ml--2">
                            <div className="d-flex justify-content-between align-items-center">
                              <div>
                                <h4 className="mb-0 text-sm">John Snow</h4>
                              </div>
                              <div className="text-right text-muted">
                                <small>3 hrs ago</small>
                              </div>
                            </div>
                            <p className="text-sm mb-0">
                              A new issue has been reported for Argon.
                            </p>
                          </div>
                        </Row>
                      </ListGroupItem>
                      <ListGroupItem
                        className="list-group-item-action"
                        href="#thexapp"
                        onClick={e => e.preventDefault()}
                        tag="a"
                      >
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              alt="..."
                              className="avatar rounded-circle"
                              src={require("assets/img/theme/team-3.jpg")}
                            />
                          </Col>
                          <div className="col ml--2">
                            <div className="d-flex justify-content-between align-items-center">
                              <div>
                                <h4 className="mb-0 text-sm">John Snow</h4>
                              </div>
                              <div className="text-right text-muted">
                                <small>5 hrs ago</small>
                              </div>
                            </div>
                            <p className="text-sm mb-0">
                              Your posts have been liked a lot.
                            </p>
                          </div>
                        </Row>
                      </ListGroupItem>
                      <ListGroupItem
                        className="list-group-item-action"
                        href="#thexapp"
                        onClick={e => e.preventDefault()}
                        tag="a"
                      >
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              alt="..."
                              className="avatar rounded-circle"
                              src={require("assets/img/theme/team-4.jpg")}
                            />
                          </Col>
                          <div className="col ml--2">
                            <div className="d-flex justify-content-between align-items-center">
                              <div>
                                <h4 className="mb-0 text-sm">John Snow</h4>
                              </div>
                              <div className="text-right text-muted">
                                <small>2 hrs ago</small>
                              </div>
                            </div>
                            <p className="text-sm mb-0">
                              Let's meet at Starbucks at 11:30. Wdyt?
                            </p>
                          </div>
                        </Row>
                      </ListGroupItem>
                      <ListGroupItem
                        className="list-group-item-action"
                        href="#thexapp"
                        onClick={e => e.preventDefault()}
                        tag="a"
                      >
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              alt="..."
                              className="avatar rounded-circle"
                              src={require("assets/img/theme/team-5.jpg")}
                            />
                          </Col>
                          <div className="col ml--2">
                            <div className="d-flex justify-content-between align-items-center">
                              <div>
                                <h4 className="mb-0 text-sm">John Snow</h4>
                              </div>
                              <div className="text-right text-muted">
                                <small>3 hrs ago</small>
                              </div>
                            </div>
                            <p className="text-sm mb-0">
                              A new issue has been reported for Argon.
                            </p>
                          </div>
                        </Row>
                      </ListGroupItem>
                    </ListGroup>

                    <DropdownItem
                      className="text-center text-info font-weight-bold py-3"
                      href="#thexapp"
                      onClick={e => e.preventDefault()}
                    >
                      View all
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                
              </Nav>
              <Nav className="align-items-center ml-auto ml-md-0" navbar>
                <UncontrolledDropdown nav>
                  <DropdownToggle className="nav-link pr-0" color="" tag="a">
                    <Media className="align-items-center">
                      <span className="avatar avatar-sm rounded-circle">
                        <CImage images={this.props.user && this.props.user.profilePhotoImages} single={false} variant={"circle"} />
                      </span>
                      <Media className="ml-2 d-none d-lg-block">
                        <span className="mb-0 text-sm font-weight-bold">
                          {this.props.user && this.props.user.name}
                        </span>
                      </Media>
                    </Media>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem className="noti-title" header tag="div">
                      <h6 className="text-overflow m-0">Welcome!</h6>
                    </DropdownItem>
                    <DropdownItem
                      href="#thexapp"
                      onClick={e => e.preventDefault()}
                    >
                      <i className="ni ni-single-02" />
                      <span>My profile</span>
                    </DropdownItem>
                    <DropdownItem
                      href="#thexapp"
                      onClick={e => e.preventDefault()}
                    >
                      <i className="ni ni-settings-gear-65" />
                      <span>Settings</span>
                    </DropdownItem>
                    <DropdownItem
                      href="#thexapp"
                      onClick={e => e.preventDefault()}
                    >
                      <i className="ni ni-calendar-grid-58" />
                      <span>Activity</span>
                    </DropdownItem>
                    <DropdownItem
                      href="#thexapp"
                      onClick={e => e.preventDefault()}
                    >
                      <i className="ni ni-support-16" />
                      <span>Support</span>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem
                      // href="#thexapp"
                      onClick={e => {
                        this.logoutClicked();
                        e.preventDefault();
                      }}
                    >
                      <i className="ni ni-user-run" />
                      <span>Logout</span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}
MainNavbar.defaultProps = {
  toggleSidenav: () => { },
  sidenavOpen: false,
  theme: "dark"
};
MainNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"])
};

//export default MainNavbar;

const mapStateToProps = (state, props) => {
  const { authentication, currentstatesReducer } = state;
//const { currentProjectId } = currentstatesReducer;

  const { loggedIn, LoginFailed, user } = authentication;

  return {
    loggedIn,
    LoginFailed,
    user,
    //currentProjectId
  };
}
/*
const mapDispatchToProps = {
  // onUpdateConponent: updateComponent,
}*/
const mapDispatchToProps = (dispatch) => {

  return {

    logout: () => dispatch(authenticationAction.logout()),
  }

}
export default connect(mapStateToProps, mapDispatchToProps)(MainNavbar)

