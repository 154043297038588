import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Modal,
  Button,
  Loader,
  Alert,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock,
  ButtonToolbar,
  Input,
  Nav,
  ButtonGroup,
  SelectPicker,
  Container,
  Content,
  IconButton,
  InputGroup,
  Icon,
} from "rsuite";

import { Translation } from "react-i18next";
import { erpLangService } from "_services";
import {
  updateWorkspaceLangs,
  updateSupportedLangs,
  updateWorkspaceTextAndValues,
  updateWorkspaceDupTextAndValues,
  updateWorkspaceCurrentLang,
} from "_actions";
import SwipeableViews from "react-swipeable-views";
import { Cell, Column, HeaderCell, Table } from "rsuite-table";
import i18n from "i18n";
import LangOptions from "./LangOptions";

const WAIT_INTERVAL = 300;

class LangSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      step: 0,
      selectedCollection: null,
      formValues: [],
      activeNav: "all",
      search: "",
    };
    this.handleSortColumn = this.handleSortColumn.bind(this);
  }

  handleReset = () => {
    this.setState({
      loading: false,
      step: 0,
      selectedCollection: null,
      formValues: [],
      activeNav: "all",
      isLangOptions: false,
      search: "",
    });
  };

  componentDidMount() {
    this.GET_LANGS();
    this.GET_SUPPORTEDLANGS();
  }

  componentDidUpdate(prevProps, prevState) {
    const { isActive, handleToggle, workspaceAllTextAndValues } = this.props;
    const that = this;
    if (prevProps.isActive !== isActive && !isActive) {
      setTimeout(() => {
        that.handleReset();
      }, 1000);
    }
    if (prevProps.isActive !== isActive && isActive) {
      if (workspaceAllTextAndValues.length == 0) {
        this.GET_WORKSPACE_LANGS();
      }
    }
  }

  handleSortColumn(sortColumn, sortType) {
    this.setState({
      loading: true,
    });

    setTimeout(() => {
      this.setState({
        sortColumn,
        sortType,
        loading: false,
      });
    }, 500);
  }

  GET_LANGS = () => {
    const that = this;
    const { workspace, updateWorkspaceLangs } = this.props;
    that.setState({ loading: true });
    erpLangService
      .GET_LANGS()
      .then((data) => {
        if (data.operationResult) {
          updateWorkspaceLangs(data.dynamicValue);
        }
        that.setState({ loading: false });
      })
      .catch((err) => {
        that.setState({ loading: false });
        debugger;
      });
  };

  GET_SUPPORTEDLANGS = () => {
    const that = this;
    const { workspace, updateSupportedLangs } = this.props;
    that.setState({ loading: true });
    erpLangService
      .GET_SUPPORTEDLANGS(workspace.relWorkspaceId)
      .then((data) => {
        if (data.operationResult) {
          updateSupportedLangs(data.dynamicValue);
        }
        that.setState({ loading: false });
      })
      .catch((err) => {
        that.setState({ loading: false });
        debugger;
      });
  };

  GET_WORKSPACE_LANGS = () => {
    const that = this;
    const { workspace, updateWorkspaceTextAndValues } = this.props;
    that.setState({ loading: true });
    erpLangService
      .GET_WORKSPACE_LANGS(workspace.relWorkspaceId)
      .then((data) => {
        if (data.operationResult) {
          debugger;
          updateWorkspaceTextAndValues(data.dynamicValue);
        }
        that.setState({ loading: false });
      })
      .catch((err) => {
        that.setState({ loading: false });
        debugger;
      });
  };

  GetAll = () => {
    this.GET_WORKSPACE_LANGS();
    this.GET_WORKSPACE_DUPLICATED_LANGS();
  };

  ADD_NEW_LANGS = (Lang_id) => {
    const that = this;
    const { workspace, updateSupportedLangs } = this.props;
    that.setState({ loading: true });
    erpLangService
      .ADD_NEW_LANGS(workspace.relWorkspaceId, Lang_id)
      .then((data) => {
        if (data.operationResult) {
          that.GET_SUPPORTEDLANGS();
        }
        that.setState({ loading: false });
      })
      .catch((err) => {
        that.setState({ loading: false });
        debugger;
      });
  };
  REMOVE_LANGS_SUPPORT = (Lang_id) => {
    const that = this;
    const { workspace, updateSupportedLangs } = this.props;
    that.setState({ loading: true });
    erpLangService
      .REMOVE_LANGS_SUPPORT(workspace.relWorkspaceId, Lang_id)
      .then((data) => {
        if (data.operationResult) {
          that.GET_SUPPORTEDLANGS();
        }
        that.setState({ loading: false });
      })
      .catch((err) => {
        that.setState({ loading: false });
        debugger;
      });
  };

  GET_WORKSPACE_DUPLICATED_LANGS = () => {
    const that = this;
    const { workspace, updateWorkspaceDupTextAndValues } = this.props;
    that.setState({ loading: true });
    erpLangService
      .GET_WORKSPACE_DUPLICATED_LANGS(workspace.relWorkspaceId)
      .then((data) => {
        if (data.operationResult) {
          updateWorkspaceDupTextAndValues(data.dynamicValue);
        }
        that.setState({ loading: false });
      })
      .catch((err) => {
        that.setState({ loading: false });
        debugger;
      });
  };

  UPDATE_WORKSPACE_DUPLICATED_LANGS = () => {
    const that = this;
    const { workspace } = this.props;

    const { selectedCollection, formValues } = this.state;
    //let _relObject = JSON.parse(JSON.stringify(selectedCollection.ref));
    //_relObject.langsValues = formValues;
    debugger;
    that.setState({ loading: true });
    erpLangService
      .UPDATE_WORKSPACE_DUPLICATED_LANGS(workspace.relWorkspaceId, formValues)
      .then((data) => {
        if (data.operationResult) {
          debugger;
          Alert.success(i18n.t("SUCCESS_UPDATE"));
          that.GetAll();
          that.handleSelected(null);
          //updateWorkspaceDupTextAndValues(data.dynamicValue);
        } else {
          Alert.error(i18n.t("ERROR_UPDATE"));
        }
        that.setState({ loading: false });
      })
      .catch((err) => {
        Alert.error(i18n.t("ERROR_UPDATE"));
        that.setState({ loading: false });
        debugger;
      });
  };

  UPDATE_WORKSPACE_ONE_LANGS = () => {
    const that = this;
    const { workspace } = this.props;
    const { selectedCollection, formValues } = this.state;
    let _relObject = JSON.parse(JSON.stringify(selectedCollection.ref));
    _relObject.langsValues = formValues;
    debugger;
    that.setState({ loading: true });
    erpLangService
      .UPDATE_WORKSPACE_ONE_LANGS(workspace.relWorkspaceId, _relObject)
      .then((data) => {
        if (data.operationResult) {
          debugger;
          Alert.success(i18n.t("SUCCESS_UPDATE"));
          that.GetAll();
          that.handleSelected(null);
          //updateWorkspaceDupTextAndValues(data.dynamicValue);
        } else {
          Alert.error(i18n.t("ERROR_UPDATE"));
        }
        that.setState({ loading: false });
      })
      .catch((err) => {
        Alert.error(i18n.t("ERROR_UPDATE"));
        that.setState({ loading: false });
        debugger;
      });
  };

  handleSelected = (_data) => {
    debugger;
    this.setState({
      selectedCollection: _data,
      step: _data ? 1 : 0,
      formValues: _data ? _data.ref.langsValues : [],
    });
  };

  handleSave = () => {
    const { selectedCollection, formValues, activeNav } = this.state;
    const that = this;
    debugger;
    if (activeNav == "all") {
      that.UPDATE_WORKSPACE_ONE_LANGS();
    } else {
      that.UPDATE_WORKSPACE_DUPLICATED_LANGS();
    }
  };

  handleChangeNav = (eventKey) => {
    const that = this;
    this.setState(
      {
        activeNav: eventKey,
      },
      () => {
        if (eventKey != "all") that.GET_WORKSPACE_DUPLICATED_LANGS();
      }
    );
  };

  getData = () => {
    const { activeNav } = this.state;
    const { workspaceAllTextAndValues, workspaceDuplicatedTextAndValues } =
      this.props;
    const data = (
      activeNav != "all"
        ? [...workspaceDuplicatedTextAndValues]
        : [...workspaceAllTextAndValues]
    ).map((item, k) => {
      let _obj = {
        ...item,
        id:
          item.type == 1
            ? item.relTransactionId
            : item.type == 0
            ? item.relModelId
            : "-",
        type: item.type ? i18n.t("TXT_TRANSACTION") : i18n.t("TXT_MODULE"),
        label:
          item.type == 1
            ? item.relTransactionId
            : item.type == 0
            ? item.relModelDataTypeName
            : "-",
        ref: item,
      };
      _obj["rowKey"] = k;
      _obj["fulltext"] = "";
      item.langsValues.forEach((element) => {
        _obj[element.lang] = element.textValue;
        _obj["fulltext"] = _obj["fulltext"] + " " + element.textValue;
      });
      return _obj;
    });

    if (!data) {
      return [];
    }
    const { sortColumn, sortType } = this.state;
    if (sortColumn && sortType) {
      return data.sort((a, b) => {
        let x = a[sortColumn];
        let y = b[sortColumn];
        if (typeof x === "string") {
          x = x.charCodeAt();
        }
        if (typeof y === "string") {
          y = y.charCodeAt();
        }
        if (sortType === "asc") {
          return x - y;
        } else {
          return y - x;
        }
      });
    }
    return data;
  };

  handleShowOptions = () => {
    this.setState({
      isLangOptions: !this.state.isLangOptions,
    });
  };

  handleUpdateInput = (value, item) => {
    let formValues = JSON.parse(JSON.stringify(this.state.formValues));
    let findIndex = formValues.findIndex((x) => x.lang == item.langShort);
    formValues[findIndex].textValue = value;
    this.setState({
      formValues: formValues,
    });
  };

  handleChangeInput = (value, item) => {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.handleUpdateInput(value, item);
    }, WAIT_INTERVAL);
  };

  handleChangeSearchInput = (value) => {
    clearTimeout(this.timer);
    const that = this;
    this.timer = setTimeout(() => {
      that.setState({ search: value });
    }, WAIT_INTERVAL);
  };

  componentWillMount() {
    this.timer = null;
  }

  render() {
    const { isActive, handleToggle } = this.props;
    const { step, loading, selectedCollection } = this.state;
    return (
      <Translation>
        {(t) => (
          <>
            <Modal
              className="lang-setting-modal"
              full={step ? false : true}
              overflow={true}
              show={isActive}
              onHide={handleToggle}
              backdrop={!loading}
            >
              <Modal.Header
                style={{
                  display: "flex",
                  gap: 10,
                  justifyContent: "flex-start",
                }}
              >
                <Modal.Title style={{ width: "auto" }}>
                  {t("TITLE_MODAL_LANGS_SETTINGS")}
                </Modal.Title>
                {!step && (
                  <Input
                    className="custom-fade-in"
                    defaultValue={this.state.search}
                    size="xs"
                    placeholder={t("PLACEHOLDER_SEARCH")}
                    style={{ width: "160px" }}
                    onChange={(value) => {
                      this.handleChangeSearchInput(value);
                    }}
                  />
                )}
              </Modal.Header>
              <Modal.Body>
                <div className="modal-body store-model-body">
                  <Container>
                    <div
                      style={{
                        height: "50vh",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <SwipeableViews
                        style={{ height: "50vh" }}
                        index={step}
                        ref={(el) => (this.reactSwipeEl = el)}
                      >
                        <div className={"global-script-view"}>
                          <div className="langs-headers">
                            <Nav
                              activeKey={this.state.activeNav}
                              onSelect={this.handleChangeNav}
                              appearance="subtle"
                            >
                              <Nav.Item eventKey={"all"}>
                                {t("NAV_ALL_LANGS")}
                              </Nav.Item>
                              <Nav.Item eventKey={"duplicated"}>
                                {t("NAV_DUPLICATED_LANGS")}
                              </Nav.Item>
                            </Nav>
                            <div className="lang-button-tools">
                              <SelectPicker
                                cleanable={false}
                                searchable={false}
                                size="xs"
                                defaultValue={this.props.currentLang}
                                onChange={(value) => {
                                  this.props.updateWorkspaceCurrentLang(value);
                                }}
                                data={this.props.supportedLangs.map((x) => ({
                                  label: x.langLong,
                                  value: x.langShort,
                                }))}
                              />
                              <ButtonGroup>
                                <Button
                                  size="xs"
                                  onClick={this.handleShowOptions}
                                >
                                  {t("BTN_LANG_OPTIONS_SETTINGS")}
                                </Button>
                              </ButtonGroup>
                            </div>
                          </div>

                          {this.state.activeNav == "all" ? (
                            <Table
                              className={"table-div"}
                              style={{ padding: 0 }}
                              fillHeight
                              data={this.getData().filter((x) =>
                                x.fulltext
                                  .toLocaleLowerCase()
                                  .includes(
                                    this.state.search.toLocaleLowerCase()
                                  )
                              )}
                              sortColumn={this.state.sortColumn}
                              sortType={this.state.sortType}
                              onSortColumn={this.handleSortColumn}
                              loading={this.state.loading}
                              cellBordered
                              rowKey="rowKey"
                              rowHeight={34}
                              // virtualized
                              onRowClick={(data, event) => {
                                event.stopPropagation();
                                this.handleSelected(data);
                              }}
                            >
                              <Column sortable width={180} fullText resizable>
                                <HeaderCell>
                                  {t("MODULE_HEADER_NAME_OR_TRANS")}
                                </HeaderCell>
                                <Cell className="lang-label" dataKey="label" />
                              </Column>
                              <Column sortable width={120} resizable>
                                <HeaderCell>
                                  {t("MODULE_HEADER_LANG_TYPE")}
                                </HeaderCell>
                                <Cell
                                  className="lang-label lang-type-cell"
                                  dataKey="type"
                                />
                              </Column>
                              type
                              {this.props.supportedLangs.map((item, k) => (
                                <Column
                                  sortable
                                  width={180}
                                  resizable
                                  key={item.id + "-" + k}
                                >
                                  <HeaderCell>{item.langLong}</HeaderCell>
                                  <Cell
                                    className="lang-label"
                                    dataKey={item.langShort}
                                  />
                                </Column>
                              ))}
                            </Table>
                          ) : (
                            <Table
                              className={"table-div"}
                              style={{ padding: 0 }}
                              fillHeight
                              data={this.getData()}
                              sortColumn={this.state.sortColumn}
                              sortType={this.state.sortType}
                              onSortColumn={this.handleSortColumn}
                              loading={this.state.loading}
                              cellBordered
                              rowKey="rowKey"
                              rowHeight={34}
                              // virtualized
                              onRowClick={(data, event) => {
                                event.stopPropagation();
                                this.handleSelected(data);
                              }}
                            >
                              <Column sortable width={180} fullText resizable>
                                <HeaderCell>
                                  {t("MODULE_HEADER_LANG_VALUE")}
                                </HeaderCell>
                                <Cell className="lang-label" dataKey="val" />
                              </Column>
                              <Column sortable width={70} resizable>
                                <HeaderCell>
                                  {t("MODULE_HEADER_LANG_COUNT")}
                                </HeaderCell>
                                <Cell
                                  className="lang-label lang-type-cell"
                                  dataKey="count"
                                />
                              </Column>
                              type
                              {this.props.supportedLangs.map((item, k) => (
                                <Column
                                  sortable
                                  width={180}
                                  resizable
                                  key={item.id + "-" + k}
                                >
                                  <HeaderCell>{item.langLong}</HeaderCell>
                                  <Cell
                                    className="lang-label"
                                    dataKey={item.langShort}
                                  />
                                </Column>
                              ))}
                            </Table>
                          )}
                        </div>
                        <div className={"global-script-view"}>
                          {/*
                          
                          <Header>
                            <IconButton
                              onClick={() => {
                                this.handleSelected(null);
                              }}
                              icon={<Icon icon="back-arrow" />}
                              circle
                              size="md"
                            />
                          </Header>
                          */}
                          <Content style={{ padding: "4px" }}>
                            {selectedCollection && (
                              <div className="custom-fade-in">
                                <p style={{ fontSize: ".7em" }}>
                                  {selectedCollection.val
                                    ? selectedCollection.val
                                    : selectedCollection.label}
                                  <span
                                    style={{
                                      marginLeft: "4px",
                                    }}
                                  >{`(${
                                    selectedCollection.val
                                      ? selectedCollection.count
                                      : selectedCollection.type
                                  })`}</span>
                                </p>
                                <Form fluid>
                                  {this.props.supportedLangs.map((item, k) => (
                                    <FormGroup key={k}>
                                      <ControlLabel>
                                        {item.langLong}
                                      </ControlLabel>
                                      {
                                        <Input
                                          //name={item.langShort}
                                          className="custom-fade-in"
                                          defaultValue={
                                            this.state.formValues[
                                              this.state.formValues.findIndex(
                                                (x) => x.lang == item.langShort
                                              )
                                            ].textValue
                                          }
                                          onChange={(value) => {
                                            this.handleChangeInput(value, item);
                                          }}
                                        />
                                      }
                                    </FormGroup>
                                  ))}
                                </Form>
                              </div>
                            )}
                          </Content>
                          <ButtonToolbar
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              onClick={() => {
                                this.handleSelected(null);
                              }}
                              appearance="subtle"
                            >
                              {t("BUTTON_BACK")}
                            </Button>
                            <Button
                              appearance="primary"
                              onClick={() => {
                                this.handleSave();
                              }}
                            >
                              {t("BUTTON_SAVE")}
                            </Button>
                          </ButtonToolbar>
                        </div>
                      </SwipeableViews>
                    </div>
                    {loading && (
                      <div className={"fade-in"}>
                        <Loader backdrop vertical />
                      </div>
                    )}
                  </Container>
                </div>
              </Modal.Body>
            </Modal>
            <LangOptions
              isActive={this.state.isLangOptions}
              handleToggle={this.handleShowOptions}
            />
          </>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer } = state;
  const {
    model,
    models,
    transaction,
    transactions,
    workspaces,
    workspace,
    dataModels,
    selectedService,
    services,
    allServices,
    allTransactions,
    workspaceAllTextAndValues,
    workspaceDuplicatedTextAndValues,
    workspaceLangs,
    supportedLangs,
    currentLang,
  } = workspaceReducer;

  return {
    workspace,
    transaction,
    transactions,
    workspaces,
    dataModels,
    models,
    model,
    selectedService,
    services,
    allServices,
    allTransactions,

    workspaceAllTextAndValues,
    workspaceDuplicatedTextAndValues,
    workspaceLangs,
    supportedLangs,
    currentLang,
  };
};

const mapDispatchToProps = {
  updateWorkspaceLangs,
  updateSupportedLangs,
  updateWorkspaceTextAndValues,
  updateWorkspaceDupTextAndValues,
  updateWorkspaceCurrentLang,
};

export default connect(mapStateToProps, mapDispatchToProps)(LangSettings);
