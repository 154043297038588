import {
  //ServiceURLs,
  StoreEndPoint,
  GetBaseERPURL,
  MakeReqestAndGetResponse,
  GetUserLang,
} from "./index";
import { HTTPOperationType } from "./httpOperations";

export const StoreService = {
  ADD_STORE,
  STORE_ADD_NEW_VERSION,
  STEPBYLOAD,
  STEPBYLOAD_OWN,
  PRAPARE_MODULE_FOR_STORE,
};

//Platform
/*
  public enum ERP_WORK_PLACE
    {
        DEV =0,
        QA =1,
        PROD =2
    }

*/

function PRAPARE_MODULE_FOR_STORE(Platform, ModuleIds, RelWorkspaceId) {
  var js = {
    Platform: Platform,
    ModuleIds: ModuleIds,
    RelWorkspaceId: RelWorkspaceId,
  };

  return MakeReqestAndGetResponse(
    GetBaseERPURL() + StoreEndPoint.PRAPARE_MODULE_FOR_STORE,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

// DYNAMIC OBJ CATEGORY ID -1 HEPSI.

function STEPBYLOAD(
  IsOrderByDesc,
  LastIndexId,
  Count,
  SearchTerm,
  RelWorkSpaceId,
  RelCategoryId
) {
  var js = {
    SearchTerm: SearchTerm,
    Count: Count,
    LastIndexId: LastIndexId,
    IsOrderByDesc: IsOrderByDesc,
    DynamicObj: {
      RelWorkSpaceId: RelWorkSpaceId,
      RelCategoryId: RelCategoryId,
    },
  };

  return MakeReqestAndGetResponse(
    GetBaseERPURL() + StoreEndPoint.STEPBYLOAD,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

function STEPBYLOAD_OWN(
  IsOrderByDesc,
  LastIndexId,
  Count,
  SearchTerm,
  RelWorkSpaceId,
  RelCategoryId
) {
  var js = {
    SearchTerm: SearchTerm,
    Count: Count,
    LastIndexId: LastIndexId,
    IsOrderByDesc: IsOrderByDesc,
    DynamicObj: {
      RelWorkSpaceId: RelWorkSpaceId,
      RelCategoryId: RelCategoryId,
    },
  };

  return MakeReqestAndGetResponse(
    GetBaseERPURL() + StoreEndPoint.STEPBYLOAD_OWN,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

function ADD_STORE(
  Title,
  RelCategoryId,
  ModuleRef,
  IsActive,
  Version,
  PresentFiles,
  Description,
  AppIcons
) {
  var js = {
    Title: Title,
    RelCategoryId: RelCategoryId,
    ModuleRef: ModuleRef,
    IsActive: IsActive,
    Version: Version,
    PresentFiles: PresentFiles,
    Description: Description,
    AppIcons: AppIcons,
  };
  return MakeReqestAndGetResponse(
    GetBaseERPURL() + StoreEndPoint.ADD_STORE,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

function STORE_ADD_NEW_VERSION(
  Id,
  Title,
  RelCategoryId,
  ModuleIds,
  IsActive,
  Version,
  PresentFiles,
  Description,
  AppIcons,
  ModuleRef
) {
  var js = {
    Id: Id,
     Title: Title,
    RelCategoryId: RelCategoryId,
    //ModuleIds: ModuleIds,
    IsActive: IsActive,
    Version: Version,
     PresentFiles: PresentFiles,
     Description: Description,
    AppIcons: AppIcons,
    ModuleRef: ModuleRef,
  };
  return MakeReqestAndGetResponse(
    GetBaseERPURL() + StoreEndPoint.STORE_ADD_NEW_VERSION,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}
