import {
    //ServiceURLs,
    ServiceRelationsEndpoints,
    GetBaseERPURL,
    MakeReqestAndGetResponse,
    GetUserLang,
  } from "./index";
  import { HTTPOperationType } from "./httpOperations";
  
  export const ServiceRelationsService = {
    GET_ALL_MODELS,
    GET_RELATIONS,
    CREATE,
    REMOVE
 
  };
 

  function GET_ALL_MODELS(RelWorkspaceId) {
    var js = {
        RelWorkspaceId:RelWorkspaceId
    };

    return MakeReqestAndGetResponse(
      GetBaseERPURL() + ServiceRelationsEndpoints.GET_ALL_MODELS,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }
 

  function GET_RELATIONS(RelWorkspaceId,PrimaryModelId) {
    var js = {
      RelWorkspaceId:RelWorkspaceId,
      PrimaryModelId:PrimaryModelId,
 
    };

    return MakeReqestAndGetResponse(
      GetBaseERPURL() + ServiceRelationsEndpoints.GET_RELATIONS,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }

  function CREATE(RelWorkspaceId,PrimaryModulelId,PrimaryModelId,ForeignModulelId,ForeignModelId) {
    var js = {
      RelWorkspaceId:RelWorkspaceId,
      PrimaryModulelId:PrimaryModulelId,
      PrimaryModelId:PrimaryModelId,
      ForeignModulelId:ForeignModulelId,
      ForeignModelId:ForeignModelId
    };

    return MakeReqestAndGetResponse(
      GetBaseERPURL() + ServiceRelationsEndpoints.CREATE,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }

  function REMOVE(id,RelWorkspaceId,PrimaryModulelId,PrimaryModelId,ForeignModulelId,ForeignModelId) {
    var js = {
      id:id,
      RelWorkspaceId:RelWorkspaceId,
      PrimaryModulelId:PrimaryModulelId,
      PrimaryModelId:PrimaryModelId,
      ForeignModulelId:ForeignModulelId,
      ForeignModelId:ForeignModelId
    };

    return MakeReqestAndGetResponse(
      GetBaseERPURL() + ServiceRelationsEndpoints.REMOVE,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }