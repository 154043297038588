import {
  //ServiceURLs,
  GITEndPoints,
  GetBaseConsoleURL,
  MakeReqestAndGetResponse,
  GetUserLang,
} from "./index";
import { HTTPOperationType } from "./httpOperations";

export const GITService = {
  START_GIT,
  STOP_GIT,
  GET_GIT_URL,
};

const forceLocal = false;

function GetErpURL(url) {
  if (forceLocal == true) return `http://localhost:7000`;
  else return `https://${url}`;
}

function START_GIT(WorkspaceId, ERP_URL) {
  var js = {
    WorkspaceId: WorkspaceId,
  };
  return MakeReqestAndGetResponse(
    GetErpURL(ERP_URL) + GITEndPoints.START_GIT,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

function STOP_GIT(WorkspaceId, ERP_URL) {
  var js = {
    WorkspaceId: WorkspaceId,
  };
  return MakeReqestAndGetResponse(
    GetErpURL(ERP_URL) + GITEndPoints.STOP_GIT,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

function GET_GIT_URL(WorkspaceId, ERP_URL) {
  var js = {
    WorkspaceId: WorkspaceId,
  };
  return MakeReqestAndGetResponse(
    GetErpURL(ERP_URL) + GITEndPoints.GET_GIT_URL,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}
