import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import { ErpEngineService, TransactionService } from "_services";

import i18n from "i18n";

import DynamicTable from "../DynamicTable";

import {
  Modal,
  Icon,
  Tooltip,
  Popover,
  Whisper,
  Loading,
  Button,
  Alert,
  Animation,
} from "rsuite";

class SearchObjectModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      listdata: [],
      loading: false,

      filter: "",
      countPerPage: 10,
      currentPage: 1,
      short: "",
      FilterModel: {
        CurrentPage: 1,
        CountPerPage: 10,
        TotalDocument: 0,
        TotalPage: 0,
      },
    };
  }
  componentDidMount() {
    const _extra = this.setAdvancedDefaults();
    if (_extra.advanced["GetFirstValue"]) {
      this.findTrans();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { isActive } = this.props;
    if (isActive && isActive !== prevProps.isActive) {
      this.findTrans();
    }
  }

  GetTransactions = () => {
    const { workspace, selectedService, transactions, allServices } =
      this.props;
    const _cData = this.props.data;
    const _cDataRefRel =
      _cData.contentType == "10"
        ? _cData.refModel
        : _cData.relationModel.relModel;

    //   const _trans = transactions.dynamicValue.find(x=>x.relBaseModelId === )
    const URL_MODE = "dev_URL";
    const that = this;

    this.setState({ loading: true });
    TransactionService.STEPBYLOAD(
      false,
      "-1",
      "99999",
      _cDataRefRel.modelName,
      _cDataRefRel.relWorkSpaceId,
      _cDataRefRel.relModuleId
    )
      .then((data) => {
        if (data.operationResult) {
          const _finded = allServices.find(
            (x) => x.relBaseModelId === _cDataRefRel.id
          );
          //const _finded = allServices.find((x) => x.reqModelId === _cDataRefRel.id);
          if (_finded) {
            this.handleReadData(_finded.executableTransactionServiceName);
            return;
          }
        }
        that.setState({ loading: false });
      })
      .catch((err) => {
        that.setState({ loading: false });
        Alert.error("BAŞARISIZ");

        // let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
        debugger;
      });
  };

  findTrans = () => {
    const { workspace, selectedService, transactions, allServices } =
      this.props;
    const _cData = this.props.data;
    const _cDataRefRel =
      _cData.contentType == "10"
        ? _cData.refModel
        : _cData.relationModel.relModel;
    debugger;
    const _finded = allServices.find(
      (x) => x.relBaseModelId === _cDataRefRel.id
    );
    //const _finded = allServices.find((x) => x.reqModelId === _cDataRefRel.id);

    if (_finded) {
      debugger;
      this.handleReadData(_finded.executableTransactionServiceName);
    } else {
      debugger;
      Alert.warning("allServices bulunamadı.");
      //this.GetTransactions();
    }
  };

  updateExtraPath = (key, value) => {
    const that = this;
    this.setState(
      {
        [key]: value,
      },
      () => {
        that.findTrans();
      }
    );
    /*
    filter:"",
      countPerPage: 10,
      currentPage: 1,
      short: "",
    */
  };
  handleFilterAndGetData = (filter) => {
    const { workspace, selectedService, transactions, allServices } =
      this.props;
    const _cData = this.props.data;
    const _cDataRefRel =
      _cData.contentType == "10"
        ? _cData.refModel
        : _cData.relationModel.relModel;

    //const _finded = allServices.find((x) => x.reqModelId === _cDataRefRel.id);
    const _finded = allServices.find(
      (x) => x.relBaseModelId === _cDataRefRel.id
    );

    const that = this;
    this.setState(
      {
        filter: filter,
      },
      () => {
        if (_finded) {
          that.handleReadData(_finded.executableTransactionServiceName);
        }
      }
    );
  };
  setAdvancedDefaults = () => {
    const item = this.props.data;

    let _inputs = {};
    item.advanced.forEach((x) => {
      try {
        let _v = null;
        switch (x.currentContentType) {
          case 3:
            _v = parseFloat(x.defaultValue);
            break;

          case 7:
            _v = x.defaultValue.toLowerCase() == "true";
            break;

          default:
            break;
        }
        _inputs[x.name] = _v;
      } catch (error) {}
    });

    let _events = {};
    item.events.forEach((x) => {
      //debugger;
      if (x.defaultValue) {
        _events[x.name] = JSON.parse(x.defaultValue);
      }
    });
    /*
    this.setState({
      cInputs: _inputs,
      advanceFormValue: _inputs,
      cEvents: _events,
    });
    */
    return {
      advanced: _inputs,
      events: _events,
    };
  };
  handleReadData = (executableTransactionServiceName) => {
    this.setState({
      sName: executableTransactionServiceName,
    });

    const { defaultValue, handleSelect, data } = this.props;
    const _extra = this.setAdvancedDefaults();
    const { filter, countPerPage, currentPage, short } = this.state;

    const { workspace, selectedService, transactions, allTransactions } =
      this.props;
    const _cData = this.props.data;
    const _cDataRefRel =
      _cData.contentType == "10"
        ? _cData.refModel
        : _cData.relationModel.relModel;

    //   const _trans = transactions.dynamicValue.find(x=>x.relBaseModelId === )

    const URL_MODE = "dev_URL";
    const that = this;

    this.setState({ loading: true });

    const FilterModel = {
      Filter: filter ? filter : "{}",
      CurrentPage: currentPage ? currentPage : undefined,
      CountPerPage: countPerPage ? countPerPage : undefined,
      Sorts: short ? short : undefined,
    };

    const ect =
      data.contentType == "10"
        ? data.refModel.executeCustomTransaction
        : data.relationModel.executeCustomTransaction;

    const _trans = _cData.relationModel?.listAnyRead
      ? allTransactions
          .find((sModel) => sModel.model.id === _cDataRefRel.id)
          .transActions.find((x) => x.id == _cData.relationModel.listAnyRead)
      : null;

    let funcName = data.sendCurrentModel
      ? allTransactions
          .find((sModel) => sModel.model.id === _cDataRefRel.id)
          .transActions.find(
            (x) => x.id == (ect ? ect : _cData.relationModel.listAnyRead)
          )?.functionName
      : _trans
      ? `${_trans.functionName == "read" ? "_" : ""}${_trans.functionName}`
      : "_read";



    debugger;
    ErpEngineService.EXECUTE_TRANSACTION_DIRECTLY(
      workspace[URL_MODE],
      funcName,
      executableTransactionServiceName,
      data.sendCurrentModel ? JSON.stringify(this.props.formValue) : "{}",
      FilterModel
    )
      .then((data) => {
        if (data.operationResult) {
          this.setState({
            listdata: data.dynamicValue,
            data: data.dynamicValue,
            FilterModel: data.FilterModel,
          });

          if (_extra && _extra.advanced["GetFirstValue"] && !defaultValue) {
            handleSelect(data.dynamicValue[0]);
          }
        }
        that.setState({ loading: false });
      })
      .catch((err) => {
        that.setState({ loading: false });
        Alert.error("BAŞARISIZ");

        // let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
        debugger;
      });
  };

  handleCreateData = (rowData) => {
    const { workspace, selectedService, transactions } = this.props;
    const _cData = this.props.data;
    const _cDataRefRel =
      _cData.contentType == "10"
        ? _cData.refModel
        : _cData.relationModel.relModel;

    //   const _trans = transactions.dynamicValue.find(x=>x.relBaseModelId === )

    const URL_MODE = "dev_URL";
    const that = this;

    this.setState({ loading: true });

    ErpEngineService.EXECUTE_TRANSACTION_DIRECTLY(
      workspace[URL_MODE],
      "create",
      that.state.sName,
      JSON.stringify(rowData)
    )
      .then((data) => {
        if (data.operationResult) {
          that.handleReadData(that.state.sName);
        }
        that.setState({ loading: false });
      })
      .catch((err) => {
        that.setState({ loading: false });
        Alert.error("BAŞARISIZ");

        // let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
        debugger;
      });
  };

  render() {
    const {
      isActive,
      handleToggle,
      search,
      data,
      model,
      models,
      handleSelect,
      isObject,
      selectedService,
      allServices,
      allTransactions,
    } = this.props;

    const that = this;
    const { listdata } = this.state;
    const _cData = this.props.data;
    const _cDataRefRel =
      _cData.contentType == "10"
        ? _cData.refModel
        : _cData.contentType == "5"
        ? _cData.relationModel.relModel
        : null;
    const _finded =
      allTransactions &&
      allTransactions.find((x) => x.model.id === _cDataRefRel.id);
    if (isActive) {
      debugger;
    }
    return (
      <Translation>
        {(t) => (
          <>
            <Modal full size={"md"} show={isActive} onHide={handleToggle}>
              <Modal.Header>
                <Modal.Title>
                  {isActive && _cDataRefRel && _cDataRefRel.modelName}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {
                  <DynamicTable
                    fromRelation={true}
                    isCreatable={true}
                    isSearchable={true}
                    addRowData={(rowData) => {
                      that.handleCreateData(rowData);
                    }}
                    refModel={
                      isObject
                        ? data.refModel
                        : model.find(
                            (x) => x.id === data.relationModel.relModel.id
                          )
                    }
                    className={"view-table"}
                    handleSelect={(_data) => {
                      const thaxt = that;

                      debugger;

                      handleSelect(_data);
                      handleToggle();
                    }}
                    data={listdata}
                    dataModel={
                      isObject
                        ? data.refModel.dataTypes
                        : model.find(
                            (x) => x.id === data.relationModel.relModel.id
                          ).dataTypes
                    }
                    searchObjectModalRef={_finded?.model}
                    isStandartList={true}
                    updateExtraPath={this.updateExtraPath}
                    filter={this.state.filter}
                    countPerPage={this.state.countPerPage}
                    currentPage={this.state.currentPage}
                    short={this.state.short}
                    FilterModel={this.state.FilterModel}
                    handleFilterAndGetData={this.handleFilterAndGetData}
                  />
                }
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={handleToggle} appearance="primary">
                  {t("BUTTON_OK")}
                </Button>
                <Button onClick={handleToggle} appearance="subtle">
                  {t("BUTTON_CANCEL")}
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer } = state;
  const {
    model,
    models,
    transaction,
    transactions,
    workspace,
    dataModels,
    selectedService,
    allServices,
    allTransactions,
  } = workspaceReducer;

  return {
    workspace,
    transaction,
    transactions,
    dataModels,
    models,
    model,
    selectedService,
    allServices,
    allTransactions,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SearchObjectModal);
