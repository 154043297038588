import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import i18n from "i18n";

import { Icon } from "rsuite";
import {
  FormControl,
  Input,
  InputGroup,
  Panel,
  IconButton,
  Alert,
} from "rsuite";

import DynamicTable from "./DynamicTable";
import FormGenerator from "./FormGenerator";
import OperationRowDataDT from "./Modals/OperationRowDataDT";
import SearchObjectModal from "./Modals/SearchObjectModal";

import { DynamicService } from "_services";
import RunTransactionModal from "components/Transaction/Modals/RunTransactionModal";
import { ErpEngineService, ModelsService } from "_services";
import EasyInvoiceViewer from "components/Modals/EasyInvoiceViewer";
import ConfirmModal from "components/Modals/ConfirmModal";
import { enqueueSnackbar } from "notistack";

class FormRefModelElement extends Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
    const { formValueOrj, data } = this.props;
    const formValue = formValueOrj ? formValueOrj : this.props.formValue;
    let _cData = null;
    if (formValue && formValue.hasOwnProperty(data.name)) {
      _cData = formValue[data.name];
    }
    this.state = {
      formValue: _cData,
      dynamicDataList: _cData ? _cData : [],
      selectedItem: null,
      isDetailOperations: false,
      countSelector: 0,
      isSearch: false,
      isTransModal: false,
      refTransaction: null,
      refFormValue: null,
      formValueUpdateTime: new Date(),
      isInvoiceViewer: false,

      isConfirmModal: false,
      confirmTitle: "",
    };
  }
  handleInvoiceViewerModal = () => {
    this.setState({
      isInvoiceViewer: !this.state.isInvoiceViewer,
    });
  };
  handleConfirmModal = (title = "") => {
    this.setState({
      confirmTitle: title,
      isConfirmModal: !this.state.isConfirmModal,
    });
  };

  componentDidMount() {
    const { _extra, runTransaction, allServices } = this.props;
    if (_extra.events.hasOwnProperty("onLoad")) {
      const _cDetail = _extra.events.onLoad;
      const _sTrans = allServices.find((x) => x.id == _cDetail.transId);
      // debugger;
      if (_sTrans) {
        switch (_cDetail.runType) {
          case "modal":
            this.handleRunTransaction(_sTrans, {}, "onLoad");
            break;
          case "current":
            runTransaction(_sTrans, {});
            break;
          default:
            runTransaction(_sTrans, {});
            break;
        }
      }
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.formValue) !==
      JSON.stringify(this.props.formValue)
    ) {
      const { formValueOrj, data } = this.props;
      const formValue = formValueOrj ? formValueOrj : this.props.formValue;
      let _cData = null;
      if (formValue && formValue.hasOwnProperty(data.name)) {
        _cData = formValue[data.name];
      }
      if (JSON.stringify(_cData) !== JSON.stringify(this.state.formValue)) {
        // debugger;
        this.setState({
          formValue: _cData,
          dynamicDataList: _cData ? _cData : [],

          // countSelector: this.state.countSelector + 1,
        });
      }
    }
  }
  handleRunTransaction = (refTransaction, _formValue, refEvent) => {
    const { workspace, item } = this.props;
    const that = this;
    let selectedService = refTransaction;
    const { formValue, formError, form } = this.state;
    const URL_MODE = "dev_URL";
    let _body = {};

    if (refEvent.modelKey) {
      _body = {
        [refEvent.modelKey]: _formValue,
      };
    } else {
      _body = _formValue;
    }

    /*
    const _body = {
      [refEvent.modelKey]: _formValue.hasOwnProperty(item.name)
        ? _formValue[item.name]
        : "",
    };
*/
    debugger;
    this.setState({ loading: true });
    ErpEngineService.EXECUTE_TRANSACTION_DIRECTLY(
      workspace[URL_MODE],
      this.props.isListDetail
        ? this.props.itemOperation
        : selectedService.functionName,
      selectedService.executableTransactionServiceName,
      JSON.stringify(this.preTransactionFunc(_body, refTransaction, _body))
    )
      .then((data) => {
        if (data.operationResult) {
          that.postTransactionFunc(
            data.operationResult,
            data.dynamicValue,
            refEvent,
            refTransaction
          );
          that.handleTransModal(
            refTransaction,
            data.dynamicValue,
            undefined,
            refEvent
          );
          //debugger;
        }
        that.setState({ loading: false });
      })
      .catch((err) => {
        that.setState({ loading: false });
        try {
          Alert.error(
            Array.isArray(err.failText) ? err.failText.join(". ") : err.failText
          );
        } catch (error) {
          Alert.error(i18n.t("MESSAGE_TRANSACTION_SUBMIT_UNSUCCESS"));
        }
        // let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
        debugger;
      });
  };
  runTransaction = (refTransaction, _formValue, _event) => {
    const { workspace, item } = this.props;
    const that = this;
    let selectedService = refTransaction;
    const { formValue, formError, form } = this.state;
    const URL_MODE = "dev_URL";

    const _body = _formValue;
    this.setState({ loading: true });
    ErpEngineService.EXECUTE_TRANSACTION_DIRECTLY(
      workspace[URL_MODE],
      this.props.isListDetail
        ? this.props.itemOperation
        : selectedService.functionName,
      selectedService.executableTransactionServiceName,
      JSON.stringify(this.preTransactionFunc(_body, refTransaction, _body))
    )
      .then((data) => {
        if (data.operationResult) {
          that.postTransactionFunc(
            data.operationResult,
            data.dynamicValue,
            "refEvent",
            refTransaction
          );
          if (_event == "onLoad") {
            this.handleRunDidMountTransaction(data.dynamicValue);
          }
          //debugger;
        }
        that.setState({ loading: false });
      })
      .catch((err) => {
        that.setState({ loading: false });
        try {
          Alert.error(
            Array.isArray(err.failText) ? err.failText.join(". ") : err.failText
          );
        } catch (error) {
          Alert.error(i18n.t("MESSAGE_TRANSACTION_SUBMIT_UNSUCCESS"));
        }
        // let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
        debugger;
      });
  };
  handleTransModal = (
    refTransaction = null,
    formValue,
    status,
    refEvent = null
  ) => {
    this.setState({
      isTransModal: status == undefined ? !this.state.isTransModal : status,
      refTransaction: refTransaction,
      refFormValue: formValue,
      refEvent: refEvent,
    });
  };
  preTransactionFunc = (data, _selectedService, _formValue) => {
    const { isRunTransModal, runTransModalClose, updateBaseForm, baseData } =
      this.props;
    const _dynamicSchemaModel = this.prepareGenerateSchema();

    const that = this;

    const runTransaction = that.runTransaction;
    const transactions = this.props.allTransList;
    const Toast = this.ToastMessage;
    const SnackToast = this.SnackMessage;
    const easyinvoice = require("easyinvoice").default; //easyinvoice.download('myInvoice.pdf', data.InvoiceBinary);
    const showInvoiceModal = this.handleInvoiceViewerModal;
    const showConfirmModal = this.handleConfirmModal;
    const runPreTransaction = () => {
      if (that.props.isRunTransModal && that.props.handleRunPreTransaction) {
        that.props.handleRunPreTransaction();
      }
    };
    const updatePreForm = (formValue) => {
      if (isRunTransModal) {
        //  debugger;
        updateBaseForm(formValue);
        //  debugger;
        runTransModalClose();
      } else {
        this.setState({ formValue });
        if (this.props.isRefModelForm) {
          // handleUpdateRefData(formValue);
          that.handleUpdateRefData(formValue, this.form, _dynamicSchemaModel);
        }
      }
    };

    const closeModal = () => {
      if (isRunTransModal) {
        runTransModalClose();
      } else {
        that.setState({
          isTransModal: false,
        });
      }
    };

    let { formData, model } = this.transactionPrepareFunc();
    const updateData = that.UpdateFormValue;

    let result = null;
    if (
      _selectedService.hasOwnProperty("preScript") &&
      _selectedService.preScript
    ) {
      try {
        if (_selectedService.preScript.includes("await ")) {
          result = eval(
            "(async function() => { " + _selectedService.preScript + "})()"
          );
        } else {
          result = eval("(function() {" + _selectedService.preScript + "}())");
        }
      } catch (error) {
        debugger;
      }
    }
    /*
    preScript(pin): "alert("pre")"
postScript(pin): "alert("post")"
    */

    try {
      //  debugger;
      if (result) {
        return result;
      }
      return data;
    } catch (error) {
      return data;
    }
  };

  prepareGenerateSchema = () => {
    return null;
  };
  ToastMessage = (msg, type) => {
    try {
      switch (type) {
        case "success":
          Alert.success(msg);
          break;
        case "error":
          Alert.error(msg);
          break;
        case "info":
          Alert.info(msg);
          break;
        case "warning":
          Alert.warning(msg);
          break;
        default:
          Alert.info(msg);
          break;
      }
    } catch (error) {
      Alert.info(msg);
    }
  };
  SnackMessage = (msg, type) => {
    try {
      enqueueSnackbar(msg, { variant: type });
    } catch (error) {
      enqueueSnackbar(msg);
    }
  };
  postTransactionFunc = (
    status,
    response,
    responseModel,
    _selectedService,
    _sModels
  ) => {
    const { isRunTransModal, runTransModalClose, updateBaseForm, baseData } =
      this.props;

    const _dynamicSchemaModel = this.prepareGenerateSchema();

    const that = this;
    const runTransaction = that.runTransaction;
    const transactions = this.props.allTransList;
    const Toast = this.ToastMessage;
    const SnackToast = this.SnackMessage;
    const runPreTransaction = () => {
      if (that.props.isRunTransModal && that.props.handleRunPreTransaction) {
        that.props.handleRunPreTransaction();
      }
    };
    const updatePreForm = (formValue) => {
      if (isRunTransModal) {
        //  debugger;
        updateBaseForm(formValue);
      } else {
        this.setState({ formValue });
        if (this.props.isRefModelForm) {
          // handleUpdateRefData(formValue);
          that.handleUpdateRefData(formValue, this.form, _dynamicSchemaModel);
        }
      }
    };
    const closeModal = () => {
      if (isRunTransModal) {
        runTransModalClose();
      } else {
        that.setState({
          isTransModal: false,
        });
      }
    };

    const easyinvoice = require("easyinvoice").default; //easyinvoice.download('myInvoice.pdf', data.InvoiceBinary);
    const showInvoiceModal = this.handleInvoiceViewerModal;
    const showConfirmModal = this.handleConfirmModal;
    let { formData, model } = this.transactionPrepareFunc();
    const updateData = that.UpdateFormValue;
    // debugger;
    if (
      _selectedService.hasOwnProperty("postScript") &&
      _selectedService.postScript
    ) {
      try {
        let result = null;

        if (_selectedService.postScript.includes("await ")) {
          result = eval(
            "(async function() => { " + _selectedService.postScript + "})()"
          );
        } else {
          result = eval("(function() {" + _selectedService.postScript + "}())");
        }

        if (
          (!_selectedService.postScript ||
            _selectedService.postScript.trim().length == 0) &&
          status
        ) {
          if (_selectedService.responseModelType === 0) {
            let _myValues = {};

            _sModels.map((x) => {
              if (response.hasOwnProperty(x.name)) {
                _myValues[x.name] = response[x.name];
              } else if (formData.hasOwnProperty(x.name)) {
                _myValues[x.name] = formData[x.name];
              } else {
              }
            });

            that.setState({
              formValue: _myValues,
              formValueUpdateTime: new Date(),
            });
          }
        }
        if (result) {
          //    return result;
        }
      } catch (error) {}
    } else {
      if (_selectedService.responseModelType === 0) {
        let _myValues = {};
        const { isRefModelForm, refModel, allTransactions } = this.props;
        const _sModels = model
          ? allTransactions.find(
              (x) =>
                x.model.id ===
                (isRefModelForm ? refModel.id : (_selectedService.responseModelType
                  ? _selectedService.relResponseModelId
                  : _selectedService.reqModelId))
            ).model.dataTypes
          : (isRefModelForm ? refModel : _selectedService)
          ? isRefModelForm
            ? refModel.dataTypes
            : _selectedService.model
          : [];
        _sModels.map((x) => {
          if (response.hasOwnProperty(x.name)) {
            _myValues[x.name] = response[x.name];
          } else if (formData.hasOwnProperty(x.name)) {
            _myValues[x.name] = formData[x.name];
          } else {
          }
        });

        that.setState({
          formValue: _myValues,
          formValueUpdateTime: new Date(),
        });
      }
    }
  };
  transactionPrepareFunc = () => {
    const { formValue } = this.state;
    const { selectedService, model, isRefModelForm, refModel } = this.props;
    const _sModels = model
      ? model.find(
          (x) =>
            x.id === (isRefModelForm ? refModel.id : (selectedService.responseModelType
              ? selectedService.relResponseModelId
              : selectedService.reqModelId))
        ).dataTypes
      : (isRefModelForm ? refModel : selectedService)
      ? isRefModelForm
        ? refModel.dataTypes
        : selectedService.model
      : [];
    return {
      formData: JSON.parse(JSON.stringify(formValue)),
      model: _sModels,
    };
  };
  handleSelect = (_value) => {
    const { data, loading, isObject, selectedService } = this.props;
    let _cValue = Object.assign({}, _value);

    this.setState({ selectedItem: _cValue, isDetailOperations: true });
  };
  handleIsDetailOperations = () => {
    this.setState({ isDetailOperations: false, selectedItem: null });
  };
  handleEditIndex = (sData, refForm, refSchemaModel, funcModal) => {
    const { data, editData } = this.props;
    const { dynamicDataList, formValue, selectedIndex } = this.state;
    const that = this;

    if (!refForm.check()) {
      Alert.error("Form not validated");
      return;
    }

    funcModal();
  };
  onKeyDown = (event) => {
    if (event.key === "Enter") {
      this.handleOpenSearch();
      event.preventDefault();
    }
  };
  handleOpenSearch = () => {
    this.setState({
      isSearch: !this.state.isSearch,
    });
  };
  handleUpdateData = (_value) => {
    const aa = this.textInput.current;
    const { data, isObject } = this.props;

    const { formRef, formValue, formOnChange, dynamicDataList } = this.props;
    const _key = data.name;
    let _obj = Object.assign({}, formValue);

    const _cData = this.props.data;
    const _cDataRefRel =
      _cData.contentType == "10"
        ? _cData.refModel
        : _cData.relationModel.relModel;

    const _cValue = _value.hasOwnProperty(_cDataRefRel.listColumn)
      ? _value[_cDataRefRel.listColumn]
      : _value._id;
    const _cValueIsObj = isObject ? _value : _cValue;

    _obj[_key] = [];
    _obj[_key].push(_cValueIsObj);
    //   debugger;
    formOnChange(_obj);

    /* setTimeout(() => {
            formRef().check()
        }, 200);*/
    //debugger
    //Burası değişecek büyük ihtimal görünür field seçilebilir.
    this.setState({ text: _cValue });
  };
  handleAddRow = (_data) => {
    const that = this;
    const { data, isObject, formOnChange, formValue } = this.props;
    const { dynamicDataList } = this.state;
    let _obj = Object.assign({}, formValue);

    let _cData = [...dynamicDataList];
    _cData.push(_data);
    const _key = data.name;
    _obj[_key] = _cData;
    formOnChange(_obj);

    this.setState({ dynamicDataList: _cData });

    that.handleRunEvent("onCreateRow", _cData);
  };
  handleRemoveIndex = (sData, index) => {
    const { data, formOnChange } = this.props;
    const { dynamicDataList, formValue } = this.state;
    let _cData = [...dynamicDataList];
    _cData.splice(index, 1);
    const _key = data.name;
    let _obj = Object.assign({}, formValue);
    _obj[_key] = _cData;
    formOnChange(_obj);
    this.setState({ dynamicDataList: _cData });

    const that = this;
    that.handleRunEvent("onDeleteRow", _cData);
  };
  handleEditAll = (_cData) => {
    const { data, formOnChange, formValue } = this.props;
    const { dynamicDataList } = this.state;
    const _key = data.name;

    let _obj = Object.assign({}, formValue);
    _obj[_key] = _cData;
    formOnChange(_obj);
    this.setState({ dynamicDataList: _cData });
    const that = this;
    that.handleRunEvent("onEditRow", _cData);
  };
  handleRunEvent = (funcName, data) => {
    const { _extra, allServices, runTransaction } = this.props;
    if (_extra.events.hasOwnProperty(funcName)) {
      const _cDetail = _extra.events[funcName];
      const _sTrans = allServices.find((x) => x.id == _cDetail.transId);
      // debugger;
      if (_sTrans) {
        switch (_cDetail.runType) {
          case "modal":
            this.handleRunTransaction(_sTrans, data, _cDetail);
            break;
          case "current":
            runTransaction(_sTrans, data);
            break;
          default:
            runTransaction(_sTrans, data);
            break;
        }
      }
    }
  };

  handleRunDidMountTransaction = (_cData) => {
    this.setState({ dynamicDataList: _cData, formValue: _cData });
  };

  render() {
    const {
      transaction,
      data,
      models,
      model,
      readOnly,
      loading,
      isObject,
      selectedService,
      allServices,
      _extra,
      runTransaction,
    } = this.props;
    const that = this;
    const { formValue } = this.state;
    if (this.props.isShowTransaction) {
      //debugger;
    }
    const _DbModel = model.find((x) => x.modelName == data.refModel.modelName);
    const _extraSelectRef = _extra.advanced["HideSelectReference"];
    const _extraEditable = _extra.advanced["HideEditableTable"];
    const _extraCreatable = _extra.advanced["HideCreateRowTable"];
    const _extraSearchable = _extra.advanced["HideSearchPanel"];
    debugger
    const canEditDelete = (readOnly ? !readOnly : true)
      ? !_extraEditable
      : false;
    const canCreate = (readOnly ? !readOnly : true) ? !_extraCreatable : false;

    const _DataTypes = model
      .find((x) => x.id === data.refModel.id)
      ?.dataTypes?.filter((x) => !x.privateField);

    if (!_DataTypes) {
      return (
        <Translation>
          {(t) => <span>{t("ERR_NOT_FOUND_MODEL")}</span>}
        </Translation>
      );
    }
//debugger
    return (
      <Translation>
        {(t) => (
          <div
            key={
              "rme-detail-" +
              this.state.countSelector +
              "-" +
              this.state.formValueUpdateTime
            }
          >
            {!readOnly &&
              data &&
              _DbModel &&
              _DbModel.isDatabaseObject &&
              !_extraSelectRef && (
                <div className={"refModelSearch"}>
                  <IconButton
                    appearance="primary"
                    size="md"
                    icon={<Icon icon="search" />}
                    onClick={this.handleOpenSearch}
                  />
                </div>
              )}
            {data && (
              <Panel
                style={{ paddingBottom: "6px" }}
                bordered
                className={"refFormControl"}
              >
                {
                  //data.relationModel.relModel.id
                  data.refModel && data.refModel.relationType === 1 ? (
                    <DynamicTable
                      mantar={data}
                      isShowTransaction={this.props.isShowTransaction}
                      readOnly={readOnly}
                      data={this.state.dynamicDataList}
                      isCreatable={canCreate}
                      isSearchable={!_extraSearchable}
                      isAction={canEditDelete}
                      isDeletable={true}
                      isRefModelForm={true}
                      refModel={data.refModel}
                      addRowData={(data) => this.handleAddRow(data)}
                      handleUpdateRefData={this.handleUpdateData}
                      refTransaction={this.props.refTransaction}
                      handleSelect={(data) => {
                        debugger;
                        if (_extra.events.hasOwnProperty("onClickRow")) {
                          const _cDetail = _extra.events.onClickRow;
                          const _sTrans = allServices.find(
                            (x) => x.id == _cDetail.transId
                          );
                          debugger;
                          if (_sTrans) {
                            switch (_cDetail.runType) {
                              case "modal":
                                this.handleRunTransaction(
                                  _sTrans,
                                  data,
                                  _cDetail
                                );
                                break;
                              case "current":
                                runTransaction(_sTrans, data);
                                break;
                              default:
                                runTransaction(_sTrans, data);
                                break;
                            }
                          }
                        } else {
                          if (canEditDelete) {
                            this.handleSelect(data);
                          }
                        }
                      }}
                      editData={(allData) => {
                        this.handleEditAll(allData);
                      }}
                      deleteData={(rowData, index) => {
                        this.handleRemoveIndex(rowData, index);
                      }}
                      dataModelOrj={
                        model.find((x) => x.id === data.refModel.id).dataTypes
                      }
                      dataModel={model
                        .find((x) => x.id === data.refModel.id)
                        ?.dataTypes?.filter((x) => !x.privateField)}
                    />
                  ) : (
                    <div className={"refFormControl"}>
                      <FormGenerator
                        isShowTransaction={this.props.isShowTransaction}
                        readOnly={readOnly}
                        isListDetail={true}
                        currentValues={
                          formValue
                            ? formValue.hasOwnProperty("0")
                              ? formValue[0]
                              : formValue
                            : formValue
                        }
                        currentValuesOrj={formValue}
                        isRefModelForm={true}
                        refModel={data.refModel}
                        refTransaction={this.props.refTransaction}
                        handleUpdateRefData={this.handleUpdateData}
                      />
                    </div>
                  )
                }
              </Panel>
            )}

            {this.state.selectedItem && this.state.isDetailOperations && (
              <OperationRowDataDT
                title={t("TITLE_MODAL_DETAIL_ROW")}
                operationName={t("ok")}
                operationColor={"cyan"}
                collection={this.state.dynamicDataList}
                isDynamic={true}
                readOnly={true}
                refModel={data.refModel}
                currentValues={this.state.selectedItem}
                dataModel={
                  model.find((x) => x.id === data.refModel.id).dataTypes
                }
                handleCreate={this.handleEditIndex}
                isActive={this.state.isDetailOperations}
                //handleUpdateData={this.handleUpdateRefData}
                handleToggle={this.handleIsDetailOperations}
              />
            )}
            {
              <SearchObjectModal
                isObject={isObject}
                handleSelect={(selectedData) => {
                  that.setState({
                    formValue: selectedData,
                    countSelector: that.state.countSelector + 1,
                    formValueUpdateTime: new Date(),
                  });
                }}
                search={""}
                data={data}
                formValue={this.props.formValue}
                isActive={this.state.isSearch}
                handleToggle={this.handleOpenSearch}
              />
            }
            {this.state.isTransModal && (
              <RunTransactionModal
                baseData={this.props.formValue}
                refTransaction={this.state.refTransaction}
                preRefTransaction={this.props.refTransaction}
                isActive={this.state.isTransModal}
                handleToggle={() => {
                  this.setState({ isTransModal: false });
                }}
                refFormValue={this.state.refFormValue}
                updateBaseForm={this.props.formOnChange}
              />
            )}

            {this.state.isInvoiceViewer && (
              <EasyInvoiceViewer
                isActive={this.state.isInvoiceViewer}
                handleToggle={this.handleInvoiceViewerModal}
              />
            )}

            {this.state.isConfirmModal && (
              <ConfirmModal
                isActive={this.state.isConfirmModal}
                handleToggle={this.handleConfirmModal}
                title={this.state.confirmTitle}
              />
            )}
          </div>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer } = state;
  const {
    models,
    model,
    transaction,
    workspace,
    dataModels,
    selectedService,
    allServices,
    allTransactions,
  } = workspaceReducer;

  return {
    workspace,
    transaction,
    dataModels,
    models,
    model,
    selectedService,
    allServices,
    allTransactions,
  };
};

const mapDispatchToProps = {};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormRefModelElement);
