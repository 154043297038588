import { StorageEndPoints } from "_services";
import { GetBaseStorageURL } from "_services";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Uploader, Alert } from "rsuite";
import { Translation } from "react-i18next";

import {
  updateServices,
  updateSelectedService,
  updatedModels,
  updateSelectedModel,
} from "_actions";
import { ResponseStatusCode } from "_helpers";

export class FormElementFileUploader extends Component {
  constructor(props) {
    super(props);
    const { formValueOrj } = this.props;
    const _cData = this.props.data;
    const formValue = formValueOrj ? formValueOrj : this.props.formValue;
    let _elementValue = [];
    debugger;
    if (formValue && formValue.hasOwnProperty(_cData.name)) {
      if (props.data.contentType == 16) {
        const _list = formValue[_cData.name].DocumentList;
        for (let index = 0; index < _list.length; index++) {
          const element = {
            name: _list[index].filename,
            fileKey: _list[index].storageId,
            storageId: _list[index].storageId,
          };
          _elementValue.push(element);
        }
      } else {
        _elementValue = formValue[_cData.name]
          ? {
              name: formValue[_cData.name].filename,
              fileKey: formValue[_cData.name].storageId,
              storageId: formValue[_cData.name].storageId,
            }
          : [];
      }
    }
    this.state = {
      files: [..._elementValue],
      uploaded: [],
      default: [..._elementValue],
    };
  }
  onSuccess = (response, file) => {
    const { handleUpdate, item } = this.props;
    try {
      const state = this.state;
      debugger;
      if (response.operationResult) {
        let _f = [...state.files];
        let _u = [...state.uploaded];
        _u.push(response.dynamicValue);
        const _key = state.files.findIndex((x) => x.fileKey == file.fileKey);

        _f[_key].storageId = response.dynamicValue.storageId;

        debugger;
        this.setState({ uploaded: _u, files: _f });
        // -> update formValue
        handleUpdate(_u, item.name);
      } else {
        debugger;
        let _f = [...state.files];
        const _key = state.files.findIndex((x) => x.fileKey == file.fileKey);

        debugger;
        //_f[_key].storageId = response.dynamicValue.storageId;
        _f.splice(_key, 1);
        this.setState({ files: _f });
        Alert.error(
          Array.isArray(response.failText)
            ? response.failText[0].textValue
            : ResponseStatusCode(response.statusCode)
        );
      }
    } catch (error) {
      debugger;
    }
  };
  onRemove = (file) => {
    const { handleUpdate, item } = this.props;
    try {
      const state = this.state;
      debugger;
      let _u = [...this.state.uploaded];
      const _key = state.uploaded.findIndex(
        (x) => x.storageId == file.storageId
      );
      debugger;
      _u.splice(_key, 1);
      this.setState({ uploaded: _u });
      handleUpdate(_u, item.name);
      // -> update formValue
    } catch (error) {}
  };
  onChange = (fileList) => {
    const state = this.state;
    debugger;
    this.setState({ files: fileList });
  };
  render() {
    const { item, readOnly } = this.props;
    var token =
      localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user")).token;
    const that = this;
    return (
      <Translation>
        {(t) => (
          <>
            {item.contentType == 16 ? (
              <Uploader
                action={GetBaseStorageURL() + StorageEndPoints.UPLOAD_FILE}
                name={"File"}
                headers={{
                  Authorization: token ? `Bearer ${token}` : "",
                }}
                draggable
                multiple={true}
                disabled={readOnly}
                disabledFileItem={readOnly}
                data={{
                  RelWorkspaceID: this.props.workspace.relWorkspaceId,
                }}
                // autoUpload={false}
                // maxPreviewFileSize={2}
                shouldQueueUpdate={(fileList, newFile) => {
                  const state = this.state;

                  debugger;
                }}
                fileList={this.state.files}
                onChange={this.onChange}
                onSuccess={this.onSuccess}
                onRemove={this.onRemove}
              >
                <div style={{ lineHeight: "100px" }}>
                  {t("MSG_UPLOAD_MULTI_FILE")}
                </div>
              </Uploader>
            ) : (
              <Uploader
                action={GetBaseStorageURL() + StorageEndPoints.UPLOAD_FILE}
                name={"File"}
                headers={{
                  Authorization: token ? `Bearer ${token}` : "",
                }}
                disabled={readOnly || this.state.files.length > 0}
                multiple={false}
                disabledFileItem={readOnly}
                data={{
                  RelWorkspaceID: this.props.workspace.relWorkspaceId,
                }}
                // autoUpload={false}
                // maxPreviewFileSize={2}
                shouldQueueUpdate={(fileList, newFile) => {
                  const state = this.state;

                  debugger;
                }}
                fileList={this.state.files}
                onChange={this.onChange}
                onSuccess={this.onSuccess}
                onRemove={this.onRemove}
              ></Uploader>
            )}
          </>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer } = state;
  const {
    workspace,
    selectedService,
    services,
    models,
    allServices,
    allTransList,
    allTransactions,
    currentLang,
  } = workspaceReducer;
  //this.props.currentLang
  return {
    workspace,
    selectedService,
    services,
    models,
    allServices,
    allTransList,
    allTransactions,
    currentLang,
  };
};

const mapDispatchToProps = {
  updateServices,
  updateSelectedService,
  updatedModels,
  updateSelectedModel,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormElementFileUploader);
