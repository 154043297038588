import React, { Component } from "react";
import PropTypes from "prop-types";
import { Translation } from "react-i18next";
import i18n from "i18n";
import { dateformat5 } from "assets/globalOps/global";
import {
  Table,
  Loader,
  Icon,
  Popover,
  Whisper,
  Button,
  ButtonToolbar,
  IconButton,
  Tooltip,
} from "rsuite";
import { contentTypes, typeDetails } from "assets/globalOps/CollectionTypes";
import CImage from "components/UI/CImage";
import CCellRefModel from "components/Transaction/CCellRefModel";
import moment from "moment";
import { NumericFormat } from "react-number-format";

const { Column, HeaderCell, Cell, Pagination, ColumnGroup } = Table;

class CCell extends Component {
  objectKeysToLowerCase = function (origObj) {
    return Object.keys(origObj).reduce(function (newObj, key) {
      let val = origObj[key];
      let newVal =
        typeof val === "object" ? this.objectKeysToLowerCase(val) : val;
      newObj[key.toLowerCase()] = newVal;
      return newObj;
    }, {});
  };

  findModel = (_data, rowData, dataKey) => {
    const { allTransactions } = this.props;
    const _model = allTransactions.find(
      (x) => x.model.id == _data.relationModel.relModel.id
    )?.model;
    const data = _model.dataTypes.find(
      (x) => x.name == _data.relationModel.listColumn
    );
    const _dataKey = _data.relationModel.listColumn;

    try {
      switch (data.contentType) {
        case 1:
          return (
            <div {...this.props}>
              <p className={"custom-cell"}>
                {rowData[dataKey][_dataKey]
                  ? data.typeDetail == 2
                    ? "***"
                    : rowData[dataKey][_dataKey]
                  : ""}
              </p>
            </div>
          );
        case 4:
          return (
            <div {...this.props}>
              <p className={"custom-cell"}>
                {rowData[dataKey][_dataKey]
                  ? dateformat5(rowData[dataKey][_dataKey])
                  : ""}
              </p>
            </div>
          );
        case 5:
          // debugger
          return (
            <div {...this.props}>
              <p className={"custom-cell"}>
                {rowData[dataKey][_dataKey] ? (
                  data.relationModel.relationType ? (
                    rowData[dataKey][_dataKey].length > 0 ? (
                      <div>
                        {rowData[dataKey][_dataKey].map((x, k) =>
                          x.hasOwnProperty(data.relationModel.listColumn) ? (
                            <p key={k}>{x[data.relationModel.listColumn]}</p>
                          ) : (
                            <p key={k}>{x._id}</p>
                          )
                        )}
                      </div>
                    ) : (
                      <i style={{ color: "#dcdada" }}>
                        {i18n.t("CELL_TYPE_REFMODEL_NOT")}
                      </i>
                    )
                  ) : rowData[dataKey][_dataKey].hasOwnProperty(
                      data.relationModel.listColumn
                    ) ? (
                    typeof rowData[dataKey][_dataKey][
                      data.relationModel.listColumn
                    ] == "object" ? (
                      this.findModel(data, rowData, dataKey)
                    ) : (
                      rowData[dataKey][_dataKey][data.relationModel.listColumn]
                    )
                  ) : (
                    rowData[dataKey][_dataKey]._id
                  )
                ) : (
                  "-"
                )}
              </p>
            </div>
          );
        case 10:
          //Görüntülecek data değiştirilebilir ilk value gösteriliyor
          //  debugger
          return (
            <div {...this.props}>
              <p className={"custom-cell"}>
                {rowData[dataKey][_dataKey] ? (
                  (Array.isArray(rowData[dataKey][_dataKey])
                    ? rowData[dataKey][_dataKey].length > 0
                      ? rowData[dataKey][_dataKey][0]
                      : {}
                    : rowData[dataKey][_dataKey]
                  ).hasOwnProperty(data.refModel.dataTypes[0]["name"]) ? (
                    <CCellRefModel
                      data={data}
                      rowData={rowData}
                      dataKey={dataKey}
                    />
                  ) : (
                    <i style={{ color: "#dcdada" }}>
                      {i18n.t("CELL_TYPE_REFMODEL_NOT")}
                    </i>
                  )
                ) : (
                  ""
                )}
              </p>{" "}
            </div>
          );
        case "string":
          return (
            <div {...this.props}>
              <p className={"custom-cell"}>{rowData[dataKey][_dataKey]}</p>
            </div>
          );
        case 7:
          return (
            <div {...this.props}>
              {rowData[dataKey][_dataKey] ? (
                <Icon icon="check" style={{ color: "#32cd32" }} />
              ) : (
                <Icon icon="close" style={{ color: "#ff0000" }} />
              )}
              {
                //<p className={"custom-cell-bool"}>{rowData[dataKey][_dataKey].toString()}</p>
              }
            </div>
          );
        case 9:
          return (
            <div {...this.props}>
              {rowData[dataKey][_dataKey] ? (
                data.typeDetail == 9 ? (
                  rowData[dataKey][_dataKey].length > 0 ? (
                    <div>
                      {rowData[dataKey][_dataKey].map((i, k) => (
                        <CImage key={k} images={i} single={false} />
                      ))}
                    </div>
                  ) : (
                    <CImage images={null} single={false} />
                  )
                ) : (
                  <CImage images={rowData[dataKey][_dataKey]} single={false} />
                )
              ) : (
                ""
              )}
            </div>
          );
        case "boolean":
          return (
            <div {...this.props}>
              {rowData[dataKey][_dataKey] ? (
                <Icon icon="check" style={{ color: "#32cd32" }} />
              ) : (
                <Icon icon="close" style={{ color: "#ff0000" }} />
              )}
              {
                //<p className={"custom-cell-bool"}>{rowData[dataKey][_dataKey].toString()}</p>
              }
            </div>
          );
        case "contentTypes":
          const _cType = contentTypes.find(
            (x) => x.id == rowData[dataKey][_dataKey]
          );
          let rModelName = "";
          try {
            const _cc = /^[a-z][A-Za-z]*$/;

            rModelName =
              rowData[dataKey][_dataKey] == 5 ||
              rowData[dataKey][_dataKey] == 10
                ? rowData.relationmodel
                  ? _cc.test(Object.keys(rowData.relationmodel)[0])
                    ? rowData.relationmodel.relModel.modelName
                    : rowData.relationmodel.RelModel.ModelName
                  : _cc.test(Object.keys(rowData.refmodel)[0])
                  ? rowData.refmodel.modelName
                  : rowData.refmodel.ModelName
                : "";
          } catch (error) {
            debugger;
          }

          // debugger
          return (
            <div {...this.props}>
              <Whisper
                placement="top"
                speaker={
                  <Popover>
                    <div className={"typePopover"}>
                      <p className={"typeName"}>
                        {_cType && i18n.t(_cType.name)}
                      </p>
                      <p className={"custom-cell"}>
                        {(_cType && i18n.t(_cType.detail)) + " " + rModelName}
                      </p>
                    </div>
                  </Popover>
                }
              >
                <Button appearance="link" className={"custom-cell"}>
                  {_cType && i18n.t(_cType.name)}
                </Button>
              </Whisper>
            </div>
          );
        case "typeDetails":
          const _tDetail = typeDetails.find(
            (x) => x.id == rowData[dataKey][_dataKey]
          );
          return (
            <div {...this.props}>
              {_tDetail ? (
                <Whisper
                  placement="top"
                  speaker={
                    <Popover>
                      <div className={"typePopover"}>
                        <p className={"typeName"}>
                          {_tDetail && i18n.t(_tDetail.name)}
                        </p>
                        <p className={"custom-cell"}>
                          {_tDetail && i18n.t(_tDetail.detail)}
                        </p>
                      </div>
                    </Popover>
                  }
                >
                  <Button appearance="link" className={"custom-cell"}>
                    {_tDetail && i18n.t(_tDetail.name)}
                  </Button>
                </Whisper>
              ) : (
                <Icon icon="minus" style={{ color: "#dadada" }} />
              )}
            </div>
          );
        case 13:
          //  debugger
          return (
            <div {...this.props}>
              <p className={"custom-cell"}>
                {rowData[dataKey][_dataKey] ? (
                  rowData[dataKey][_dataKey].length > 0 ? (
                    <div>
                      {i18n.t("CELL_TYPE_REFMODEL_LIST_SENTENCE", {
                        count: rowData[dataKey][_dataKey].length,
                        name: data.displayName,
                      })}
                      {/*
                      {rowData[dataKey][_dataKey].map((x, k) => (
                        <p key={k}>{x}</p>
                      ))}
                     */}
                    </div>
                  ) : (
                    <i style={{ color: "#dcdada" }}>
                      {i18n.t("CELL_TYPE_REFMODEL_NOT")}
                    </i>
                  )
                ) : (
                  <i style={{ color: "#dcdada" }}>
                    {i18n.t("CELL_TYPE_REFMODEL_NOT")}
                  </i>
                )}
              </p>
            </div>
          );
        case 12: // Operation Time
          return (
            <div {...this.props}>
              <p className={"custom-cell"}>
                {rowData[dataKey][_dataKey]
                  ? moment(rowData[dataKey][_dataKey]).format("L LT")
                  : "-"}
              </p>
            </div>
          );
        case 11:
          return (
            <div {...this.props}>
              <p className={"custom-cell"}>
                {rowData[dataKey][_dataKey]
                  ? `${rowData[dataKey][_dataKey].firstName} ${rowData[dataKey][_dataKey].lastName}`
                  : "-"}
              </p>
            </div>
          );
        case 15:
          const data15 = rowData[dataKey][_dataKey];
          return (
            <Cell {...this.props}>
              <Whisper
                placement="top"
                trigger="hover"
                speaker={<Tooltip>{data15.filename}</Tooltip>}
              >
                <a
                  key={data15.storageId}
                  href={data15.url}
                  target="_blank"
                  download
                >
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    size="xs"
                    icon={
                      <Icon
                        icon={
                          data15.ext == ".jpg" ||
                          data15.ext == ".png" ||
                          data15.ext == ".jpeg" ||
                          data15.ext == ".svg"
                            ? "file-image-o"
                            : data15.ext == ".txt"
                            ? "file-text-o"
                            : data15.ext == ".docx" || data15.ext == ".doc"
                            ? "file-word-o"
                            : data15.ext == ".pdf"
                            ? "file-pdf-o"
                            : data15.ext == ".csv" || data15.ext == ".xlsx"
                            ? "file-excel-o"
                            : data15.ext == ".mp4" ||
                              data15.ext == ".mov" ||
                              data15.ext == ".webm" ||
                              data15.ext == ".wav"
                            ? "file-movie-o"
                            : data15.ext == ".zip" || data15.ext == ".rar"
                            ? "file-zip-o"
                            : data15.ext == ".mp3"
                            ? "file-audio-o"
                            : data15.ext == ".json" ||
                              data15.ext == ".js" ||
                              data15.ext == ".html"
                            ? "file-code-o"
                            : "file-o"
                        }
                      />
                    }
                  />
                </a>
              </Whisper>
            </Cell>
          );
        case 16:
          debugger;
          return (
            <Cell {...this.props}>
              <ButtonToolbar>
                {rowData[dataKey][_dataKey] &&
                rowData[dataKey][_dataKey]["DocumentList"] &&
                Array.isArray(rowData[dataKey][_dataKey].DocumentList)
                  ? rowData[dataKey][_dataKey].DocumentList.map(
                      (data16, key) => {
                        return (
                          <Whisper
                            placement="top"
                            trigger="hover"
                            speaker={<Tooltip>{data16.filename}</Tooltip>}
                          >
                            <a
                              download={data16.filename}
                              key={data16.storageId}
                              href={data16.url}
                              target="_blank"
                            >
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                                size="xs"
                                icon={
                                  <Icon
                                    icon={
                                      data16.ext == ".jpg" ||
                                      data16.ext == ".png" ||
                                      data16.ext == ".jpeg" ||
                                      data16.ext == ".svg"
                                        ? "file-image-o"
                                        : data16.ext == ".txt"
                                        ? "file-text-o"
                                        : data16.ext == ".docx" ||
                                          data16.ext == ".doc"
                                        ? "file-word-o"
                                        : data16.ext == ".pdf"
                                        ? "file-pdf-o"
                                        : data16.ext == ".csv" ||
                                          data16.ext == ".xlsx"
                                        ? "file-excel-o"
                                        : data16.ext == ".mp4" ||
                                          data16.ext == ".mov" ||
                                          data16.ext == ".webm" ||
                                          data16.ext == ".wav"
                                        ? "file-movie-o"
                                        : data16.ext == ".zip" ||
                                          data16.ext == ".rar"
                                        ? "file-zip-o"
                                        : data16.ext == ".mp3"
                                        ? "file-audio-o"
                                        : data16.ext == ".json" ||
                                          data16.ext == ".js" ||
                                          data16.ext == ".html"
                                        ? "file-code-o"
                                        : "file-o"
                                    }
                                  />
                                }
                              />
                            </a>
                          </Whisper>
                        );
                      }
                    )
                  : "-"}
              </ButtonToolbar>
            </Cell>
          );

        default:
          try {
            return (
              <div {...this.props}>
                <p className={"custom-cell"}>
                  {rowData[dataKey][_dataKey] &&
                  typeof rowData[dataKey][_dataKey] == "object" ? (
                    rowData[dataKey][_dataKey].hasOwnProperty("_id") ? (
                      rowData[dataKey][_dataKey]._id
                    ) : (
                      <i style={{ color: "#dcdada" }}>
                        {i18n.t("CELL_TYPE_CONTENT_TYPE_NOT")}
                      </i>
                    )
                  ) : (
                    rowData[dataKey][_dataKey]
                  )}
                </p>
              </div>
            );
          } catch (error) {
            return (
              <div {...this.props}>
                <span style={{ color: "red" }}>{i18n.t("error")}</span>
              </div>
            );
          }

          break;
      }
    } catch (error) {
      //debugger
      return "-";
    }
  };

  setAdvancedDefaults = () => {
    const { data, isDMTable } = this.props;
    const item = data;
    let _inputs = {};

    if (item.hasOwnProperty("advanced"))
      item.advanced.forEach((x) => {
        try {
          let _v = x.defaultValue ? x.defaultValue : null;
          switch (x.currentContentType) {
            case 3:
              _v = parseFloat(x.defaultValue);
              break;

            case 7:
              _v = x.defaultValue.toLowerCase() == "true";
              break;

            default:
              break;
          }
          _inputs[x.name] = _v;
        } catch (error) {}
      });

    let _events = {};
    if (item.hasOwnProperty("events"))
      item.events.forEach((x) => {
        if (x.defaultValue) {
          _events[x.name + x.eventName] = JSON.parse(x.defaultValue);
        }
      });
    /*
    this.setState({
      cInputs: _inputs,
      advanceFormValue: _inputs,
      cEvents: _events,
    });
    */
    return {
      advanced: _inputs,
      events: _events,
    };
  };
  render() {
    const { data, isDMTable, rowKey } = this.props;
    const that = this;
    const dataKey = this.props.dataKey.toLowerCase();

    const _extra = this.setAdvancedDefaults();

    let rowData = Object.keys(this.props.rowData).reduce(
      (c, k) => ((c[k.toLowerCase()] = this.props.rowData[k]), c),
      {}
    );
    // this.objectKeysToLowerCase(this.props.rowData);
    // relationModel.listColumn
    //debugger
    try {
      switch (data.contentType) {
        case 1:
          return (
            <Cell key={data.name + rowKey} {...this.props}>
              <p className={"custom-cell"}>
                {rowData[dataKey]
                  ? data.typeDetail == 2
                    ? "***"
                    : rowData[dataKey]
                  : ""}
              </p>
            </Cell>
          );
        case 3:
          return (
            <Cell
              key={
                data.name + rowKey + (rowData[dataKey] ? rowData[dataKey] : "0")
              }
              {...this.props}
            >
              <NumericFormat
                displayType={"text"}
                defaultValue={rowData[dataKey] ? rowData[dataKey] : "0"}
                thousandSeparator={"."}
                decimalSeparator={","}
                allowDecimal={_extra.advanced.IsInteger ? false : true}
                decimalScale={_extra.advanced.IsInteger ? 0 : 2}
                fixedDecimalScale={_extra.advanced.IsInteger ? undefined : true}
                //   name={data.name}
                className={"custom-fade-in  custom-cell"}
                prefix={_extra.advanced.Prefix}
                suffix={_extra.advanced.Prefix}
              />
            </Cell>
          );
        case 4:
          return (
            <Cell
              key={
                data.name + rowKey + (rowData[dataKey] ? rowData[dataKey] : "0")
              }
              {...this.props}
            >
              <p className={"custom-cell"}>
                {rowData[dataKey] ? dateformat5(rowData[dataKey]) : ""}
              </p>
            </Cell>
          );
        case 5:
          // debugger
          return (
            <Cell {...this.props}>
              <p className={"custom-cell"} key={data.name + rowKey + "5"}>
                {rowData[dataKey] ? (
                  data.relationModel.relationType ? (
                    rowData[dataKey].length > 0 ? (
                      <div>
                        {rowData[dataKey].map((x, k) =>
                          x.hasOwnProperty(data.relationModel.listColumn) ? (
                            <p key={k}>{x[data.relationModel.listColumn]}</p>
                          ) : (
                            <p key={k}>{x._id}</p>
                          )
                        )}
                      </div>
                    ) : (
                      <i style={{ color: "#dcdada" }}>
                        {i18n.t("CELL_TYPE_REFMODEL_NOT")}
                      </i>
                    )
                  ) : rowData[dataKey].hasOwnProperty(
                      data.relationModel.listColumn
                    ) ? (
                    typeof rowData[dataKey][data.relationModel.listColumn] ==
                    "object" ? (
                      this.findModel(data, rowData, dataKey)
                    ) : (
                      rowData[dataKey][data.relationModel.listColumn]
                    )
                  ) : (
                    rowData[dataKey]._id
                  )
                ) : (
                  "-"
                )}
              </p>
            </Cell>
          );
        case 10:
          //Görüntülecek data değiştirilebilir ilk value gösteriliyor
          //  debugger
          return (
            <Cell {...this.props}>
              <p className={"custom-cell"} key={data.name + rowKey + "10"}>
                {rowData[dataKey] ? (
                  (Array.isArray(rowData[dataKey])
                    ? rowData[dataKey].length > 0
                      ? rowData[dataKey][0]
                      : {}
                    : rowData[dataKey]
                  ).hasOwnProperty(data.refModel.dataTypes[0]["name"]) ? (
                    <CCellRefModel
                      data={data}
                      rowData={rowData}
                      dataKey={dataKey}
                    />
                  ) : (
                    <i style={{ color: "#dcdada" }}>
                      {i18n.t("CELL_TYPE_REFMODEL_NOT")}
                    </i>
                  )
                ) : (
                  ""
                )}
              </p>{" "}
            </Cell>
          );
        case "string":
          return (
            <Cell {...this.props}>
              <p
                className={"custom-cell"}
                key={data.name + rowKey + "str" + rowData[dataKey]}
              >
                {rowData[dataKey]}
              </p>
            </Cell>
          );
        case 7:
          return (
            <Cell
              {...this.props}
              key={data.name + rowKey + "bool" + rowData[dataKey]}
            >
              {rowData[dataKey] ? (
                <Icon icon="check" style={{ color: "#32cd32" }} />
              ) : (
                <Icon icon="close" style={{ color: "#ff0000" }} />
              )}
              {
                //<p className={"custom-cell-bool"}>{rowData[dataKey].toString()}</p>
              }
            </Cell>
          );
        case 9:
          return (
            <Cell {...this.props} key={data.name + rowKey + "ct9"}>
              {rowData[dataKey] ? (
                data.typeDetail == 9 ? (
                  rowData[dataKey].length > 0 ? (
                    <div>
                      {rowData[dataKey].map((i, k) => (
                        <CImage key={k} images={i} single={false} />
                      ))}
                    </div>
                  ) : (
                    <CImage images={null} single={false} />
                  )
                ) : (
                  <CImage images={rowData[dataKey]} single={false} />
                )
              ) : (
                ""
              )}
            </Cell>
          );
        case "boolean":
          return (
            <Cell {...this.props} key={data.name + rowKey + "bool2"}>
              {rowData[dataKey] ? (
                <Icon icon="check" style={{ color: "#32cd32" }} />
              ) : (
                <Icon icon="close" style={{ color: "#ff0000" }} />
              )}
              {
                //<p className={"custom-cell-bool"}>{rowData[dataKey].toString()}</p>
              }
            </Cell>
          );
        case "contentTypes":
          const _cType = contentTypes.find((x) => x.id == rowData[dataKey]);
          let rModelName = "";
          try {
            const _cc = /^[a-z][A-Za-z]*$/;

            rModelName =
              rowData[dataKey] == 5 || rowData[dataKey] == 10
                ? rowData.relationmodel
                  ? _cc.test(Object.keys(rowData.relationmodel)[0])
                    ? rowData.relationmodel.relModel.modelName
                    : rowData.relationmodel.RelModel.ModelName
                  : _cc.test(Object.keys(rowData.refmodel)[0])
                  ? rowData.refmodel.modelName
                  : rowData.refmodel.ModelName
                : "";
          } catch (error) {
            debugger;
          }

          // debugger
          return (
            <Cell {...this.props}>
              <Whisper
                placement="top"
                speaker={
                  <Popover>
                    <div className={"typePopover"}>
                      <p className={"typeName"}>
                        {_cType && i18n.t(_cType.name)}
                      </p>
                      <p className={"custom-cell"}>
                        {(_cType && i18n.t(_cType.detail)) + " " + rModelName}
                      </p>
                    </div>
                  </Popover>
                }
              >
                <Button appearance="link" className={"custom-cell"}>
                  {_cType && i18n.t(_cType.name)}
                </Button>
              </Whisper>
            </Cell>
          );
        case "typeDetails":
          const _tDetail = typeDetails.find((x) => x.id == rowData[dataKey]);
          return (
            <Cell {...this.props}>
              {_tDetail ? (
                <Whisper
                  placement="top"
                  speaker={
                    <Popover>
                      <div className={"typePopover"}>
                        <p className={"typeName"}>
                          {_tDetail && i18n.t(_tDetail.name)}
                        </p>
                        <p className={"custom-cell"}>
                          {_tDetail && i18n.t(_tDetail.detail)}
                        </p>
                      </div>
                    </Popover>
                  }
                >
                  <Button appearance="link" className={"custom-cell"}>
                    {_tDetail && i18n.t(_tDetail.name)}
                  </Button>
                </Whisper>
              ) : (
                <Icon icon="minus" style={{ color: "#dadada" }} />
              )}
            </Cell>
          );
        case 13:
          //  debugger
          return (
            <Cell {...this.props}>
              <p className={"custom-cell"}>
                {rowData[dataKey] ? (
                  rowData[dataKey].length > 0 ? (
                    <div>
                      {i18n.t("CELL_TYPE_REFMODEL_LIST_SENTENCE", {
                        count: rowData[dataKey].length,
                        name: data.displayName,
                      })}
                      {/*
                        {rowData[dataKey].map((x, k) => (
                          <p key={k}>{x}</p>
                        ))}
                       */}
                    </div>
                  ) : (
                    <i style={{ color: "#dcdada" }}>
                      {i18n.t("CELL_TYPE_REFMODEL_NOT")}
                    </i>
                  )
                ) : (
                  <i style={{ color: "#dcdada" }}>
                    {i18n.t("CELL_TYPE_REFMODEL_NOT")}
                  </i>
                )}
              </p>
            </Cell>
          );
        case 12: // Operation Time
          return (
            <Cell {...this.props} key={data.name + rowKey + "operationtime-12"}>
              <p className={"custom-cell"}>
                {rowData[dataKey]
                  ? moment(rowData[dataKey]).format("L LT")
                  : "-"}
              </p>
            </Cell>
          );
        case 11:
          return (
            <Cell {...this.props}>
              <p className={"custom-cell"} key={data.name + rowKey + "-11"}>
                {rowData[dataKey]
                  ? `${rowData[dataKey].firstName} ${rowData[dataKey].lastName}`
                  : "-"}
              </p>
            </Cell>
          );

        case 15:
          const data15 = rowData[dataKey];
          return (
            <Cell {...this.props}>
              <Whisper
                placement="top"
                trigger="hover"
                speaker={<Tooltip>{data15.filename}</Tooltip>}
              >
                <a
                  key={data15.storageId}
                  href={data15.url}
                  target="_blank"
                  download
                >
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    size="xs"
                    icon={
                      <Icon
                        icon={
                          data15.ext == ".jpg" ||
                          data15.ext == ".png" ||
                          data15.ext == ".jpeg" ||
                          data15.ext == ".svg"
                            ? "file-image-o"
                            : data15.ext == ".txt"
                            ? "file-text-o"
                            : data15.ext == ".docx" || data15.ext == ".doc"
                            ? "file-word-o"
                            : data15.ext == ".pdf"
                            ? "file-pdf-o"
                            : data15.ext == ".csv" || data15.ext == ".xlsx"
                            ? "file-excel-o"
                            : data15.ext == ".mp4" ||
                              data15.ext == ".mov" ||
                              data15.ext == ".webm" ||
                              data15.ext == ".wav"
                            ? "file-movie-o"
                            : data15.ext == ".zip" || data15.ext == ".rar"
                            ? "file-zip-o"
                            : data15.ext == ".mp3"
                            ? "file-audio-o"
                            : data15.ext == ".json" ||
                              data15.ext == ".js" ||
                              data15.ext == ".html"
                            ? "file-code-o"
                            : "file-o"
                        }
                      />
                    }
                  />
                </a>
              </Whisper>
            </Cell>
          );
        case 16:
          return (
            <Cell {...this.props}>
              <ButtonToolbar>
                {rowData[dataKey] &&
                rowData[dataKey].DocumentList &&
                Array.isArray(rowData[dataKey].DocumentList)
                  ? rowData[dataKey].DocumentList.map((data16, key) => {
                      return (
                        <Whisper
                          placement="top"
                          trigger="hover"
                          speaker={<Tooltip>{data16.filename}</Tooltip>}
                        >
                          <a
                            download={data16.filename}
                            key={data16.storageId}
                            href={data16.url}
                            target="_blank"
                          >
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                              size="xs"
                              icon={
                                <Icon
                                  icon={
                                    data16.ext == ".jpg" ||
                                    data16.ext == ".png" ||
                                    data16.ext == ".jpeg" ||
                                    data16.ext == ".svg"
                                      ? "file-image-o"
                                      : data16.ext == ".txt"
                                      ? "file-text-o"
                                      : data16.ext == ".docx" ||
                                        data16.ext == ".doc"
                                      ? "file-word-o"
                                      : data16.ext == ".pdf"
                                      ? "file-pdf-o"
                                      : data16.ext == ".csv" ||
                                        data16.ext == ".xlsx"
                                      ? "file-excel-o"
                                      : data16.ext == ".mp4" ||
                                        data16.ext == ".mov" ||
                                        data16.ext == ".webm" ||
                                        data16.ext == ".wav"
                                      ? "file-movie-o"
                                      : data16.ext == ".zip" ||
                                        data16.ext == ".rar"
                                      ? "file-zip-o"
                                      : data16.ext == ".mp3"
                                      ? "file-audio-o"
                                      : data16.ext == ".json" ||
                                        data16.ext == ".js" ||
                                        data16.ext == ".html"
                                      ? "file-code-o"
                                      : "file-o"
                                  }
                                />
                              }
                            />
                          </a>
                        </Whisper>
                      );
                    })
                  : "-"}
              </ButtonToolbar>
            </Cell>
          );

        default:
          try {
            return (
              <Cell {...this.props}>
                <p className={"custom-cell"}>
                  {rowData[dataKey] && typeof rowData[dataKey] == "object" ? (
                    rowData[dataKey].hasOwnProperty("_id") ? (
                      rowData[dataKey]._id
                    ) : (
                      <i style={{ color: "#dcdada" }}>
                        {i18n.t("CELL_TYPE_CONTENT_TYPE_NOT")}
                      </i>
                    )
                  ) : (
                    rowData[dataKey]
                  )}
                </p>
              </Cell>
            );
          } catch (error) {
            return (
              <Cell {...this.props}>
                <span style={{ color: "red" }}>{i18n.t("error")}</span>
              </Cell>
            );
          }

          break;
      }
    } catch (error) {
      //debugger
      return "-";
    }
  }
}

CCell.propTypes = {
  rowData: PropTypes.object,
  data: PropTypes.object,
  dataKey: PropTypes.string,
  allTransactions: PropTypes.array,
};

export default CCell;
