import React, { useEffect } from "react";
import { Button } from "rsuite";
import { Translation } from "react-i18next";

export default function AddRuleAction(props) {
 
  return (
    <Translation>
      {(t) => (
        <Button size={"sm"} appearance="primary" onClick={props.handleOnClick}>
          {t("BUTTON_ADD_RULE")}
        </Button>
      )}
    </Translation>
  );
}
/*

  switch (props.fieldData.refData.contentType) {
    case 1:
      return (
        <div>
          <Input
           size={"sm"}
            onChange={(value) => {
              props.handleOnChange(value ? value : null);
            }}
          />
        </div>
      );
      case 2:
      return (
        <div>
          <Input
           size={"sm"}
            onChange={(value) => {
              props.handleOnChange(value ? value : null);
            }}
          />
        </div>
      );
      case 3:
        return (
          <div>
            <InputNumber
              size={"sm"}
              onChange={(value) => {
                props.handleOnChange(value ? value : null);
              }}
            />
          </div>
        );
    case 8:
      return (
        <div>
          <Input
           size={"sm"}
            onChange={(value) => {
              props.handleOnChange(value ? value : null);
            }}
          />
        </div>
      );

    default:
      return <ValueEditor {...props} />;
  }
*/
