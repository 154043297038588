import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { ActionCreators as UndoActionCreators } from "redux-undo";
import Emitter from "_actions/emitter";
import i18n from "i18n";
import { ResponseStatusCode } from "_helpers";
import { addNewPage, removeComponent } from "_actions";
import { StoreService } from "_services";
import {
  Button,
  Alert,
  SelectPicker,
  Popover,
  CheckPicker,
  MultiCascader,
} from "rsuite";
import AvatarCard from "components/Custom/AvatarCard";

import { Input, Modal, Container, Row, Col } from "reactstrap";

class UpdateVersionStorePlugin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      presentFiles: [],
      customStyles: {
        pageName: "",
        pageNameState: null,
        desc: "",
        descState: null,
        catId: null,
        catState: null,
        version: null,
        versionState: null,
        photo: null,
        photoState: null,
        modulesId: null,
        modulesState: null,
        alert: null,
      },
      prepeparedModules: [],
    };
  }

  handleCreateModuleModal = () => {
    const {
      workspace,
      clearHistory,
      onRefresh,
      handleToggle,
      removeComponent,
      AddPageToProject,
      data,
    } = this.props;
    const { prepeparedModules } = this.state;
    let _state = this.state.customStyles;
    const that = this;
    that.setState({ isLoading: true });
    StoreService.STORE_ADD_NEW_VERSION(
      data.id,
      _state.pageName,
      _state.catId,
      prepeparedModules,
      true,
      _state.version,
      this.state.presentFiles,
      [
        {
          Lang: "en",
          TextValue: _state.desc,
        },
      ],
      _state.photo ? _state.photo : [],
      prepeparedModules
    )
      .then((data) => {
        onRefresh();
        Alert.success(i18n.t("SUCCESS_UPDATE"))
        //handleToggle();
      })
      .catch((err) => {
        Alert.warning(ResponseStatusCode(err.statusCode));
        that.setState({ isLoading: false });
        debugger;
        //  let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
        //   this.warningAlert(data);
      });
  };

  customStylesForm = (e, stateName) => {
    let newState = this.state.customStyles;
    newState[stateName] = e.target.value;
    if (stateName === "checkbox") {
      if (e.target.value) {
        newState.checkboxState = "valid";
      } else {
        newState.checkboxState = "invalid";
      }
    } else {
      if (e.target.value === "") {
        newState[stateName + "State"] = "invalid";
      } else {
        newState[stateName + "State"] = "valid";
      }
    }
    this.setState({
      customStyles: newState,
    });
  };

  onKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      this.validateCustomStylesForm();
    }
  };

  validateCustomStylesForm = () => {
    let newState = this.state.customStyles;
    const { prepeparedModules } = this.state;
    newState.pageName = newState.pageName.trim();

    var regex = /^[a-zA-Z][a-zA-Z0-9_ ]{2,}$/;

    if (newState.pageName === "") {
      newState.pageNameState = "invalid";
    } else if (!regex.test(newState.pageName)) {
      newState.pageNameState = "invalid";
    } else {
      newState.pageNameState = "valid";
    }

    if (newState.catId === null) {
      newState.catState = "invalid";
      Alert.error(i18n.t("MODAL_CREATE_STORE_TEXTBOX_CATEGORY_INVALID"));
    } else {
      newState.catState = "valid";
    }

    if (prepeparedModules === null || prepeparedModules.length === 0) {
      newState.modulesState = "invalid";
      Alert.error(i18n.t("MODAL_CREATE_STORE_TEXTBOX_MODULES_INVALID"));
    } else {
      newState.modulesState = "valid";
    }

    if (newState.version === null) {
      newState.versionState = "invalid";
    } else if (newState.version < 0.001) {
      newState.versionState = "invalid";
    } else {
      newState.versionState = "valid";
    }

    this.setState(
      {
        customStyles: newState,
      },
      () => {
        if (
          newState.pageNameState == "valid" &&
          newState.modulesState == "valid" &&
          newState.catState == "valid" &&
          newState.versionState == "valid"
        ) {
          this.handleCreateModuleModal();
        }
      }
    );
  };
  resetContent = () => {
    const that = this;
    const { data } = this.props;
    debugger;
    this.setState(
      {
        isLoading: false,
        presentFiles: this.props.data.presentFiles
          ? this.props.data.presentFiles
          : [],
        customStyles: {
          pageName: this.props.data.title,
          pageNameState: null,
          desc: this.props.data.description[0]?.textValue
            ? this.props.data.description[0]?.textValue
            : "",
          descState: null,
          catId: this.props.data.relCategoryId,
          catState: null,
          version: this.props.data.version,
          versionState: null,
          photo: this.props.data.appIcons,
          photoState: null,
          modulesId: this.props.data.relModules,
          modulesState: null,
          alert: null,
        },
        prepeparedModules: [],
      },
      () => {
        that.handlePrepareModules();
      }
    );
  };

  componentDidUpdate(prevProps) {
    if (this.props.isActive)
      if (prevProps.isActive != this.props.isActive && this.props.isActive) {
        this.resetContent();
      }
  }

  handleUpdatePP = (_data) => {
    let _formValue = this.state.customStyles;
    //debugger
    _formValue["photo"] = _data;
    this.setState({
      formValue: _formValue,
    });
  };

  handleUpdatePresentFile = (_data) => {
    this.setState({
      presentFiles: [
        ...this.state.presentFiles,
        {
          Type: 1,
          ConvertedImages: [..._data],
        },
      ],
    });
  };

  handlePrepareModules = () => {
    const { workspace } = this.props;
    let _state = this.state.customStyles;
    const that = this;
    that.setState({ isLoading: true });

    //Platform,ModuleIds,RelWorkspaceId

    StoreService.PRAPARE_MODULE_FOR_STORE(0, _state.modulesId, workspace.id)
      .then((data) => {
        debugger;
        that.setState({ isLoading: false });
        that.setState({
          prepeparedModules: data.dynamicValue,
        });
      })
      .catch((err) => {
        Alert.warning(ResponseStatusCode(err.statusCode));
        that.setState({ isLoading: false });
        debugger;
      });
  };

  render() {
    const { isActive, handleToggle, storeCategories, modules } = this.props;
    const { isLoading, prepeparedModules } = this.state;
    return (
      <Translation>
        {(t) => (
          <>
            <Modal
              className="modal-dialog-centered"
              isOpen={isActive}
              //size="lg"
              // scrollable={true}
            >
              <div className="modal-header">
                <h5 className="modal-title" id="CheckProjectModal">
                  {t("CREATE_NEW_VERSION")}
                </h5>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  disabled={this.state.isLoading}
                  onClick={() => handleToggle()}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body">
                <Container>
                <Row>
                    <label
                      className="form-control-label"
                      htmlFor="validationCustomPagename"
                    >
                      {t("MODAL_CREATE_STORE_TEXTBOX_NAME")}
                    </label>
                    <Input
                      //innerRef={(input) => { setTimeout(() => { try { input.focus() } catch (error) { } }, 300); }}
                      autoFocus
                      aria-describedby="inputGroupPrepend"
                      placeholder={t(
                        "MODAL_CREATE_STORE_TEXTBOX_NAME_PLACEHOLDER"
                      )}
                      type="text"
                      defaultValue={this.state.customStyles.pageName}
                      id="validationCustomPagename"
                      onKeyDown={this.onKeyDown.bind(this)}
                      valid={this.state.customStyles.pageNameState === "valid"}
                      invalid={
                        this.state.customStyles.pageNameState === "invalid"
                      }
                      disabled={true}
                      onChange={(e) => this.customStylesForm(e, "pageName")}
                    />

                    <div className="invalid-feedback">
                      {t("MODAL_CREATE_STORE_TEXTBOX_NAME_INVALID")}
                    </div>
                  </Row>
                  {/*
                    <Row style={{ flexDirection: "column" }}>
                    <label className="form-control-label">
                      {t("MODAL_CREATE_STORE_TEXTBOX_PHOTO")}
                    </label>
                    <div className={"dynamic-dt-side"}>
                      <AvatarCard
                        renderedImages={this.state.customStyles.photo}
                        isEditable={true}
                        isSquare={true}
                        handleUpdate={this.handleUpdatePP}
                        cmsKey={"photo"}
                      />
                      <div className={"dynamic-dt-popover"}>
                        {this.state.customStyles.photoState !== null && (
                          <Popover visible className={"custom-white-tooltip"}>
                            <p>{t("ERROR_DM_GLOBAL_REQUIRED")} </p>
                          </Popover>
                        )}
                      </div>
                    </div>
                  </Row>
                  <Row>
                    <label
                      className="form-control-label"
                      htmlFor="validationCustomPagename"
                    >
                      {t("MODAL_CREATE_STORE_TEXTBOX_NAME")}
                    </label>
                    <Input
                      //innerRef={(input) => { setTimeout(() => { try { input.focus() } catch (error) { } }, 300); }}
                      autoFocus
                      aria-describedby="inputGroupPrepend"
                      placeholder={t(
                        "MODAL_CREATE_STORE_TEXTBOX_NAME_PLACEHOLDER"
                      )}
                      type="text"
                      defaultValue={this.state.customStyles.pageName}
                      id="validationCustomPagename"
                      onKeyDown={this.onKeyDown.bind(this)}
                      valid={this.state.customStyles.pageNameState === "valid"}
                      invalid={
                        this.state.customStyles.pageNameState === "invalid"
                      }
                      disabled={isLoading}
                      onChange={(e) => this.customStylesForm(e, "pageName")}
                    />

                    <div className="invalid-feedback">
                      {t("MODAL_CREATE_STORE_TEXTBOX_NAME_INVALID")}
                    </div>
                  </Row>
                  <Row style={{ marginTop: "10px" }}>
                    <label>{t("MODAL_CREATE_MODULE_TEXTBOX_DESC")}</label>
                    <Input
                      type="textarea"
                      defaultValue={this.state.customStyles.desc}
                      placeholder={t("MODAL_CREATE_MODULE_TEXTBOX_DESC")}
                      disabled={isLoading}
                      onChange={(e) => this.customStylesForm(e, "desc")}
                    />
                  </Row>
                  
                  */}


                  <Row style={{ marginTop: "10px", flexDirection: "column" }}>
                    <label
                      className="form-control-label"
                      htmlFor="validationCustomLang"
                    >
                      {t("MODAL_CREATE_STORE_TEXTBOX_MODULES")}
                    </label>
                    <CheckPicker
                      onSelect={(value) => {
                        const that = this;
                        let _cs = this.state.customStyles;
                        _cs.modulesId = value;
                        _cs.modulesState = "valid";
                        this.setState({ customStyles: _cs }, () => {
                          that.handlePrepareModules();
                        });
                      }}
                      placement={"autoVertical"}
                      placeholder={t("MODAL_CREATE_STORE_TEXTBOX_MODULES")}
                      block
                      loading={isLoading}
                      disabled={isLoading}
                      data={[
                        ...modules.dynamicValue.map((item, key) => {
                          return {
                            value: item.id,
                            label: item.name,
                          };
                        }),
                      ]}
                      defaultValue={this.state.customStyles.modulesId}
                      cleanable={false}
                    />
                  </Row>

                  {prepeparedModules.length > 0 && (
                    <Row style={{ marginTop: "10px", flexDirection: "column" }}>
                      <label
                        className="form-control-label"
                        htmlFor="validationCustomLang"
                      >
                        {t("MODAL_CREATE_STORE_TEXTBOX_COPY_DATA")}
                      </label>
                      <MultiCascader
                        placement={"autoVertical"}
                        onChange={(value) => {
                          const that = this;
                          debugger;
                        }}
                        placeholder={t("MODAL_CREATE_STORE_TEXTBOX_COPY_DATA")}
                        block
                        defaultValue={[]}
                        data={[
                          ...prepeparedModules.map((item, key) => {
                            return {
                              value: item.relModule.id,
                              label: item.relModule.name,
                              children: [
                                ...item.modelsDetail.map((ii, kk) => ({
                                  value: item.relModule.id+"-"+ii.relModel.id,
                                  label:
                                    ii.relModel.modelName +
                                    ` - (${ii.dataCount} data)`,
                                  data: ii,
                                })),
                              ],
                            };
                          }),
                        ]}
                        uncheckableItemValues={[
                          ...prepeparedModules.map((item, key) => {
                            return item.relModule.id;
                          }),
                        ]}
                        cleanable={false}
                      />
                    </Row>
                  )}

                  <Row style={{ marginTop: "10px", flexDirection: "column" }}>
                    <label
                      className="form-control-label"
                      htmlFor="validationCustomLang"
                    >
                      {t("STORE_CATEGORIES")}
                    </label>
                    <SelectPicker
                      placement={"autoVertical"}
                      onSelect={(value) => {
                        let _cs = this.state.customStyles;
                        _cs.catId = value;
                        _cs.catState = "valid";
                        this.setState({ customStyles: _cs });
                      }}
                      defaultValue={this.state.customStyles.catId}
                      placeholder={t("STORE_CATEGORIES")}
                      block
                      data={[
                        ...storeCategories.map((item, key) => {
                          return {
                            value: item.id,
                            label: item.shortName,
                          };
                        }),
                      ]}
                      cleanable={false}
                    />
                  </Row>

                  <Row style={{ marginTop: "10px" }}>
                    <label
                      className="form-control-label"
                      htmlFor="validationCustomVersion"
                    >
                      {t("MODAL_CREATE_MODULE_TEXTBOX_VERSION")}
                    </label>
                    <Input
                      type="number"
                      placeholder={"0.00"}
                      min={"0.1"}
                      step={"0.1"}
                      id="validationCustomVersion"
                      onKeyDown={this.onKeyDown.bind(this)}
                      valid={this.state.customStyles.versionState === "valid"}
                      invalid={
                        this.state.customStyles.versionState === "invalid"
                      }
                      disabled={isLoading}
                      defaultValue={this.state.customStyles.version}
                      onChange={(e) => this.customStylesForm(e, "version")}
                    />
                    <div className="invalid-feedback">
                      {t("MODAL_CREATE_MODULE_TEXTBOX_VERSION_INVALID")}
                    </div>
                  </Row>
{
  /*
    <Row
                    style={{
                      paddingTop: "10px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <label className="form-control-label">
                      {t("MODAL_CREATE_STORE_ADDITIONS")}
                    </label>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      {this.state.presentFiles &&
                        this.state.presentFiles.map((item, key) => (
                          <AvatarCard
                            key={key}
                            containerStyle={{
                              padding: 0,
                              width: "30px",
                              height: "30px",
                              marginRight: "2px",
                            }}
                            style={{
                              width: "30px",
                              height: "30px",
                              borderRadius: "10px",
                            }}
                            nullStyle={{
                              width: "30px",
                              height: "30px",
                              borderRadius: "10px",
                              borderWidth: "2px",
                              fontSize: "1.8em",
                            }}
                            renderedImages={
                              item.ConvertedImages
                                ? item.ConvertedImages
                                : item.convertedImages
                            }
                            isEditable={false}
                            isSquare={true}
                          />
                        ))}
                      <AvatarCard
                        containerStyle={{
                          padding: 0,
                          width: "30px",
                          height: "30px",
                        }}
                        style={{
                          width: "30px",
                          height: "30px",
                          borderRadius: "10px",
                        }}
                        nullStyle={{
                          width: "30px",
                          height: "30px",
                          borderRadius: "10px",
                          borderWidth: "2px",
                          fontSize: "1.8em",
                        }}
                        renderedImages={[]}
                        isEditable={true}
                        //isSquare={true}
                        handleUpdate={this.handleUpdatePresentFile}
                      />
                    </div>
                  </Row>
  */
}
                
                </Container>
              </div>
              <div className="modal-footer">
                <Button
                  size={"sm"}
                  data-dismiss="modal"
                  disabled={this.state.isLoading}
                  type="button"
                  onClick={() => handleToggle()}
                >
                  {t("BUTTON_CANCEL")}
                </Button>

                <Button
                  loading={this.state.isLoading}
                  appearance="primary"
                  size={"sm"}
                  type="button"
                  onClick={() => this.validateCustomStylesForm()}
                >
                  {t("BUTTON_CREATE")}
                </Button>

                {/*
                                          <CButton
                                    color="primary"
                                    size={"sm"}
                                    loading={this.state.loading}
                                    disabled={(this.state.loading || (this.state.customStyles.nameState == "invalid"))}
                                    value={data ? t("BUTTON_UPDATE") : t("BUTTON_CREATE")}
                                    onClick={() => { this.validateCustomStylesForm() }}>
                                </CButton>
                                    */}
              </div>
              {this.state.alert}
            </Modal>
          </>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer } = state;
  const { workspace, workspaces, selectedService, storeCategories, modules } =
    workspaceReducer;

  return {
    workspace,
    workspaces,
    selectedService,
    storeCategories,
    modules,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateVersionStorePlugin);
