import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import moment from "moment";
import Emitter from "_actions/emitter";
import i18n from "i18n";
import { ResponseStatusCode } from "_helpers";
import { updateStore, updateStoreCategories } from "_actions";
 import {
  Row,
  Col,
  ButtonToolbar,
  Icon,
  Alert,
  Panel,
  SelectPicker,
} from "rsuite";
import {
  Loader,
  Container,
  Placeholder,
  Button,
  IconButton,
  FlexboxGrid,
} from "rsuite";
 
 
class RelationList extends Component {
  render() {
    const { onSelectScript, serviceRelations, onCreate, onRemove } = this.props;
     return (
      <Translation>
        {(t) => (
          <>
            <Container
              key={"workspaces-list"}
              style={{ height: "100%", padding: "1em" }}
              className={"custom-fade-in"}
            >
              <Row>
                {serviceRelations && serviceRelations.length > 0 ? (
                  serviceRelations.map((item, key) => (
                    <Col
                      className={"custom-fade-in"}
                      key={key}
                      style={{
                        paddingTop: "5px",
                        paddingBottom: "5px",
                      }}
                      md={6}
                      sm={12}
                    >
                      <Card
                        onRemove={onRemove}
                        handleSelect={() => {
                          onSelectScript(item);
                        }}
                        data={item}
                      />
                    </Col>
                  ))
                ) : (
                  <div
                    className={"global-view not-match-workspace custom-fade-in"}
                  >
                    <Icon icon="exclamation-triangle" size="3x" />
                    <span style={{ fontSize: "1.3rem" }}>
                      {t("ERROR_NOT_FOUND_RELATIONS")}
                    </span>
                  </div>
                )}
              </Row>
            
            </Container>
          </>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer, authentication } = state;
  const { loggedIn, LoginFailed, user } = authentication;

  const {
    workspace,
    workspaces,
    selectedService,
    store,
    storeCategories,
    serviceRelations,
  } = workspaceReducer;

  return {
    workspace,
    workspaces,
    selectedService,
    store,
    storeCategories,
    user,
    serviceRelations,
  };
};

const mapDispatchToProps = {
  updateStore,
  updateStoreCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(RelationList);

const Card = (props) => (
  <div key={props.data.id}>
    <div className="btn-remove-relation">
      <IconButton
        onClick={() => {
          props.onRemove(props.data);
        }}
        color="red"
        size="xs"
        icon={<Icon icon="trash-o" />}
        circle={true}
      />
    </div>

    <div
      //onClick={props.handleSelect}
      appearance="subtle"
      style={{ width: "100%", padding: 0 }}
    >
      <Panel
        header={props.header}
        bordered
        shaded
        style={{ boxShadow: "none" }}
        className={"global-scriptfile"}
      >
        <p>
          <b>{props.data.foreignModel.modelName}</b>
        </p>
        <p>{props.data.foreignModule.name}</p>
      </Panel>
    </div>
  </div>
);
