import React, { useEffect } from "react";
import { ValueEditor, ValueEditorProps } from "react-querybuilder";
import { Input, InputNumber } from "rsuite";

export default function CustomValueEditor(props) {
 // debugger; //props.operator
  useEffect(() => {
    props.handleOnChange(`<|${props.fieldData.value}${props.operator}|>`);
  }, [props.fieldData.value, props.operator]);
  return (
    <div className="query-fake-input">
      {`<|${props.fieldData.value}${props.operator}|>`}
    </div>
  );
}
/*
 <Input
      size={"sm"}
      style={{ width: "" }}
      key={`<|${props.fieldData.value}${props.operator}|>`}
      defaultValue={`<|${props.fieldData.value}${props.operator}|>`}
      onChange={(value) => {
        props.handleOnChange(value ? value : null);
      }}
    />
  switch (props.fieldData.refData.contentType) {
    case 1:
      return (
        <div>
          <Input
           size={"sm"}
            onChange={(value) => {
              props.handleOnChange(value ? value : null);
            }}
          />
        </div>
      );
      case 2:
      return (
        <div>
          <Input
           size={"sm"}
            onChange={(value) => {
              props.handleOnChange(value ? value : null);
            }}
          />
        </div>
      );
      case 3:
        return (
          <div>
            <InputNumber
              size={"sm"}
              onChange={(value) => {
                props.handleOnChange(value ? value : null);
              }}
            />
          </div>
        );
    case 8:
      return (
        <div>
          <Input
           size={"sm"}
            onChange={(value) => {
              props.handleOnChange(value ? value : null);
            }}
          />
        </div>
      );

    default:
      return <ValueEditor {...props} />;
  }
*/
