import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import i18n from "i18n";
import { loader } from "@monaco-editor/react";
import ReactResizeDetector from "react-resize-detector";
import { Loader } from "rsuite";
import dedent from "dedent";
import { Alert } from "rsuite";

import { ErpEngineService, DashBoardURLSService } from "_services";
import {
  updateGeneratedTransactions,
  updateGeneratedModules,
  updateGeneratedInterfaces,
  setSnippetsUpload,
} from "_actions";

//var moment = JSON.stringify(require("moment/moment.d.ts"))
const mySnippets = require("assets/snippets/typescript.json");
class CodeEditor extends Component {
  constructor(props) {
    super(props);
    this.editorRef = React.createRef();
    this.scriptRef = React.createRef();

    this.state = {
      script: props.value ? props.value : "",
      isLoading: false,
    };
  }

  handleGetGeneratedModels = async () => {
    const { workspace, selectedService, updateGeneratedModules } = this.props;
    const URL_MODE = "dev_URL";
    const that = this;

    return await ErpEngineService.GET_GENERATED_MODEL_BASE(
      workspace.relWorkspaceId
    )
      .then((data) => {
        if (data.operationResult) {
          updateGeneratedModules(data.dynamicValue);
          return data.dynamicValue;
        }
      })
      .catch((err) => {
        that.setState({ isLoading: false, isGetGeneratedModelsLoading: false });
        Alert.error(i18n.t("MESSAGE_ENGINE_OPERATION_UNSUCCESS"));
        debugger;
      });
  };

  handleGetInterfaces = async () => {
    const { workspace, selectedService, updateGeneratedInterfaces } =
      this.props;
    const URL_MODE = "dev_URL";
    const that = this;

    return await ErpEngineService.GET_INTERFACES(
      workspace[URL_MODE],
      workspace.relWorkspaceId
    )
      .then((data) => {
        if (data.operationResult) {
          updateGeneratedInterfaces(data.dynamicValue);
          return data.dynamicValue;
        }
      })
      .catch((err) => {
        that.setState({ isLoading: false, isGetGeneratedModelsLoading: false });
        Alert.error(i18n.t("MESSAGE_ENGINE_OPERATION_UNSUCCESS"));
        debugger;
      });
  };

  handleGenerateAllTransaction = async () => {
    const { workspace, selectedService, updateGeneratedTransactions } =
      this.props;
    const URL_MODE = "dev_URL";
    const that = this;

    return await ErpEngineService.GENERATE_ALL_TRANSACTION_BASE(
      workspace.relWorkspaceId
    )
      .then((data) => {
        if (data.operationResult) {
          updateGeneratedTransactions(data.dynamicValue);
          return data.dynamicValue;
        }
      })
      .catch((err) => {
        that.setState({
          isLoading: false,
          isGenerateAllTransactionLoading: false,
        });
        Alert.error(i18n.t("MESSAGE_ENGINE_OPERATION_UNSUCCESS"));

        // let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
        debugger;
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value != this.props.value) {
      this.setState({ script: this.props.value });
    }
  }

  componentDidMount() {
    if (this.isFirstTime) {
      this.handleGenerateScriptEditor(
        this.props.isGlobalScript ? "globalScript" : "script"
      );
    }
  }

  clearScriptFromValue = (str, _value) => {
    return str
      .split("\n")
      .filter(function (line) {
        return line.indexOf(_value) == -1;
      })
      .join("\n");
  };
  createDependencyProposals = (monaco, range) => {
    const { isSnippetsUploaded, setSnippetsUpload } = this.props;

    this.isFirstTime = false;
    let __snippets = [];
    for (const key in mySnippets) {
      if (Object.hasOwnProperty.call(mySnippets, key)) {
        const element = mySnippets[key];

        __snippets.push({
          label: element.prefix,
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: [...element.body].join("\n"),
          documentation: key,
          insertTextRules:
            monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          range: range,
        });
      }
    }
    setSnippetsUpload(true);

    return __snippets;
  };

  isFirstTime = true;

  handleGenerateScriptEditor = async (_where) => {
    const that = this;
    this.setState({ isLoading: true });
    const {
      onChangeScript,
      funcName,
      generatedTransactions,
      generatedModels,
      generatedInterfaces,
      isGlobalScript,
      selectedService,
      module,
      executableTransactionServiceName,
      readOnly,
      isSnippetsUploaded,
    } = this.props;
    // loader.config({ paths: { vs: '...' } });
    //const _gtTest = generatedTransactions.length;

    const _gat =
      generatedTransactions.length > 0
        ? generatedTransactions
        : await that.handleGenerateAllTransaction();
    const _ggm =
      generatedModels.length > 0
        ? generatedModels
        : await that.handleGetGeneratedModels();
    let _gai =
      generatedInterfaces.length > 0
        ? generatedInterfaces
        : that.handleGetInterfaces();

    let _transDetails = [];
    let _modelssDetails = [];
    let _modulesObj = [];
    let _modulesFields = "";
    let _projectInterfacesDetails = [];

    try {
      _transDetails = _gat.map((x) => {
        let result = x.detail;
        if (x.detail.indexOf(": services.") >= 1) {
          result = x.detail.replaceAll("services.", "");
        }
        return result;
      });
    } catch (error) {}
    try {
      _modelssDetails = _ggm.map((x) => x.detail);
      _projectInterfacesDetails = _gai
        ? _gai.map((x) => {
            let result = x.Detail;
            if (x.Detail.indexOf(": services.") >= 1) {
              result = x.Detail.replaceAll("services.", "");
            }
            return result;
          })
        : [];
    } catch (error) {}
    try {
      _modulesObj = _ggm.map((x) => {
        const _cName = x.fileName.replace(".ts", "");
        //_modulesFields + ("\n " + _cName + " : " + _cName + ", \n");
        // _modulesFields + ("\n " + _cName + ": new " + _cName + "(),\n");
        _modulesFields =
          _modulesFields + ("\n " + _cName + " : " + _cName + ", \n");
        return _cName;
      });
    } catch (error) {}

    let _servicesFields = "";

    try {
      _transDetails.map((x, k) => {
        let result = x.split("export class ").pop().split(" extends")[0];
        //x.split(/(?<=export class )(.#)(?= extends)/s)
        // .filter(Boolean);

        _servicesFields =
          _servicesFields +
          (result.length > 1
            ? "\n " + result[1] + ": new " + result[1] + "(),\n"
            : " ");
      });
    } catch (error) {}

    loader.init().then((monaco) => {
      let keys = [];
      monaco.languages.typescript.javascriptDefaults.setExtraLibs({});
      //  const xxas = mySnippets;
      if (that.isFirstTime && !isSnippetsUploaded) {
        monaco.languages.registerCompletionItemProvider("javascript", {
          //triggerCharacters: ["."],

          provideCompletionItems: (model, position, context, token) => {
            var word = model.getWordUntilPosition(position);
            var range = {
              startLineNumber: position.lineNumber,
              endLineNumber: position.lineNumber,
              startColumn: word.startColumn,
              endColumn: word.endColumn,
            };
            return {
              suggestions: that.createDependencyProposals(monaco, range),
            };
          },
        });
      }

      monaco.languages.typescript.javascriptDefaults.setEagerModelSync(true);

      monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
        target: monaco.languages.typescript.ScriptTarget.ES6,
        allowNonTsExtensions: true,

        //noLib: true,
        //target: monaco.languages.typescript.ScriptTarget.ES2015,
        //lib: [],
      });

      let scripts = [
        //    ..._modelssDetails,

        `
        const models = {
          ${_modulesFields}
        }
    `,
      ];

      /*
      
       export declare class BooksWStockService_oha8gBase extends MongoBaseRepository<models.oha8g_BooksWStock, AnyParamConstructor<models.oha8g_BooksWStock>> {
            public Service_BooksService_oha8g;
            public Service_BookLogsService_oha8g;
            constructor(Service_BooksService_oha8g: services.BooksService_oha8g, Service_BookLogsService_oha8g: services.BookLogsService_oha8g);
            FixData(): Promise<number>;
            create(req: models.oha8g_BooksWStock): Promise<models.oha8g_BooksWStock>;
            read(condition: any): Promise<models.oha8g_BooksWStock[]>;
        }
       */

      // debugger;

      let cleanInterfaces = [];
      //cleanScript.push(_data);
      let _interfaces = [
        ..._transDetails,
        `
        const services = {
          ${_servicesFields}
        }
    `,
        ..._projectInterfacesDetails,
      ];
      _interfaces.forEach((element) => {
        const _nLine = element
          .split('"')
          .join("")
          .toString()
          .split("\\n")
          .join("\n")
          .toString();
        let _data = dedent(
          this.clearScriptFromValue(_nLine, "import")
            .replace("export ", "")
            .replace("export class", "declare class")
            .replaceAll("private ", "public ")
        );
        //_data = dedent(this.clearScriptFromValue(_data, "@"));
        _data = dedent(this.clearScriptFromValue(_data, "* from"));
        //_data = dedent(this.clearScriptFromValue(_data, "//"));

        cleanInterfaces.push(_data);
        monaco.languages.typescript.javascriptDefaults.addExtraLib(_data);
      });

      let cleanScripts = [];
      scripts.forEach((element) => {
        const _nLine = element
          .split('"')
          .join("")
          .toString()
          .split("\\n")
          .join("\n")
          .toString();
        let _data = dedent(
          this.clearScriptFromValue(_nLine, "import")
            .replace("export ", "")
            .replace("export class", "declare class")
          //.replace("class", "export declare class")
        );
        _data = dedent(this.clearScriptFromValue(_data, "@"));
        _data = dedent(this.clearScriptFromValue(_data, "* from"));
        _data = dedent(this.clearScriptFromValue(_data, "//"));

        cleanScripts.push(_data);
        monaco.languages.typescript.javascriptDefaults.addExtraLib(_data);
      });
      //cleanScript.findIndex(y=>y == cleanScript.filter(x=>x.indexOf("export declare class "+executableTransactionServiceName)>=0)[0])

      let singleScript = "";
      [...cleanInterfaces, ...cleanScripts].map((x) => {
        singleScript = singleScript + x + "\n";
      });

      const test_sc = dedent(singleScript);
      let jsCode = "";

      if (that.isFirstTime) {
        jsCode = isGlobalScript
          ? that.props.value
          : [
              "class " +
                executableTransactionServiceName +
                "Clone" +
                " extends " +
                executableTransactionServiceName +
                //"Base" +
                "{",
              that.props.value,
              "}",
            ].join("\n");
      }
      if (!that.props.value) {
        debugger;
      }

      // monaco.editor.createModel(libSource, 'typescript', monaco.Uri.parse(libUri));
      //monaco.editor.dispose();
      let _monaco = monaco.editor.create(document.getElementById(_where), {
        loading: <Loader />,
        //onChange: that.onChangeBody,
        /*
        `
                    class OfferStatusService extends MongoBaseRepository<ws6vi_OfferStatus,AnyParamConstructor<ws6vi_OfferStatus>>{
                        public async create(req:ws6vi_OfferStatus):Promise<ws6vi_OfferStatus>{
                            return await super.create(req);
                        }
                    }
                    `
         */
        //javascript
        value: dedent(jsCode), //jsCode,
        language: "javascript",
        autoIndent: true,

        //path:monaco.editor.createModel(dedent(jsCode),  "typescript", monaco.Uri.parse("file:///main.tsx")),
        formatOnPaste: true,
        // options:options,
        readOnly: readOnly,
        formatOnType: true,
        onMount: (editor) => {
          that.handleEditorDidMount(editor);
        },
      });
      _monaco.getAction("editor.action.formatDocument").run();
      setTimeout(() => {
        that.setState({ isLoading: false });
      }, 600);
      that.editorRef.current = _monaco;
      //that.handleEditorDidMount();
      _monaco.onDidChangeModelContent(function (e, v) {
        that.setState(
          {
            [_where]: _monaco.getValue(),
          },
          () => {
            onChangeScript(_monaco.getValue());
          }
        );
      });
    });
  };

  componentWillUnmount() {
    const editor = this.editorRef.current;

    if (editor) editor.dispose();
  }

  handleEditorDidMount = (w, h) => {
    const editor = this.editorRef.current;

    if (editor) {
      /*  editor.layout({
                  width: 'auto',
                  height: 'auto',
              });
              */
      const width = w - 10;
      const height = h - 10;

      editor.layout({
        width,
        height,
      });
    }
  };
  render() {
    const { funcName, funcParams, isGlobalScript } = this.props;

    return (
      <Translation>
        {(t) => (
          <>
            <ReactResizeDetector handleWidth handleHeight>
              {({ width, height }) => {
                this.handleEditorDidMount(width, height);
                return (
                  <div
                    ref={this.scriptRef}
                    style={{ ...this.props.style }}
                    className={" script-code-side"} //custom-fade-in
                    id={isGlobalScript ? "globalScript" : "script"}
                  >
                    {this.state.isLoading && (
                      <div className={"fade-in"}>
                        <Loader backdrop vertical />
                      </div>
                    )}
                  </div>
                );
              }}
            </ReactResizeDetector>
          </>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer, authentication, currentstatesReducer } = state;
  const { loggedIn, LoginFailed, user } = authentication;
  const { isSnippetsUploaded } = currentstatesReducer;
  const {
    workspace,
    workspaces,
    selectedService,
    store,
    storeCategories,
    generatedTransactions,
    generatedModels,
    generatedInterfaces,
    module,
  } = workspaceReducer;

  return {
    workspace,
    workspaces,
    selectedService,
    store,
    storeCategories,
    user,
    generatedTransactions,
    generatedModels,
    generatedInterfaces,
    module,
    isSnippetsUploaded,
  };
};

const mapDispatchToProps = {
  updateGeneratedTransactions,
  updateGeneratedModules,
  updateGeneratedInterfaces,
  setSnippetsUpload,
};

export default connect(mapStateToProps, mapDispatchToProps)(CodeEditor);

/*
`
                    import { types, ReturnModelType } from "@typegoose/typegoose";
                    import { UpdateQuery } from "mongoose";
                    export declare abstract class MongoBaseRepository<T, U extends types.AnyParamConstructor<T> = types.AnyParamConstructor<T>> {
                        protected dataModel: ReturnModelType<U>;
                        private T_CLASS;
                        constructor(cls: U);
                        save(data: any): Promise<T>;
                        create(data: T): Promise<T>;
                        update(id: any, item: UpdateQuery<types.DocumentType<InstanceType<U>>>): Promise<boolean>;
                        read(condition: any): Promise<T[]>;
                        findAll(condition: any): Promise<T[]>;
                        findAllPOP(condition: any, pop: any): Promise<T[]>;
                        delete(id: any): Promise<boolean>;
                        findByID(Id: any): Promise<T>;
                    }
                    
                    ``
                    export declare class DocumentCT {
                        _id: string;
                        __v: number;
                    }
                    
                    `;


*/
