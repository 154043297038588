import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translation } from "react-i18next";
import i18n from "i18n";

class Settings extends Component {
    render() {
        return (
            <Translation>
                {t => (
                    <div className={"global-view"}>
                        {"Settings"}
                    </div>
                )}
            </Translation>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Settings)
