import React, { Component } from 'react'
import { Button } from "reactstrap";
import ClipLoader from "react-spinners/ClipLoader";
import { Avatar } from 'rsuite';


class CImage extends Component {

    constructor(props) {
        super(props)

        this.state = {
            img: "",
        }
        this.myRef = React.createRef();
    }
    componentDidMount() {
        this.getMyIdealImage();
    }
    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps != this.props) {

            this.getMyIdealImage();
        }
    }

    getMyIdealImage = () => {
        const { images, single, isGallery } = this.props;
        //  debugger
        let current = this.myRef.current;


        if (single) {
            this.setState({ img: images })
            return
        } else if (images == null) {
            this.setState({ img: null })
            return
        }
        else if (images.length == 0) {
            this.setState({ img: null })
            return
        }


        if (current != null) {
            let w = this.myRef.current.offsetWidth;
            let h = this.myRef.current.offsetHeight;

            if (images == null || images.length < 1)
                return;
            let closest = null;
            try {
                if (isGallery) {
                    closest = images.reduce(function (prev, curr) {
                        return (Math.abs(curr.height - h) < Math.abs(prev.height - h) ? curr : prev);
                    });
                } else {
                    closest = images.reduce(function (prev, curr) {
                        return (Math.abs(curr.width - w) < Math.abs(prev.width - w) ? curr : prev);
                    });
                }
            } catch (error) {
                //console.log(error);
            }

            if (closest != null) {
                this.setState({
                    img: closest.url
                })
            }
        }
    }


    render() {
        const { images, className, children, variant, sigle, classes } = this.props;
        const { img, age } = this.state;

        return (

            <Avatar ref={this.myRef}
                src={this.state.img}
                className={className}
                circle={variant == "circle"}>
                {children}
            </Avatar>
        )
    }
}

export default CImage
