//import { ItemInterface } from 'react-json-view'

let id = 1;
export const createId = () => {
  return id++;
}

export const applyDrag = (arr, payload, addedIndex) => {

  if (addedIndex === null) return arr;

  const result = [...arr];
  let itemToAdd = payload;

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }

  return result;
};

export const AreObjectsEqual = (first_object, second_object) => {

  return JSON.stringify(first_object) == JSON.stringify(second_object) ? true : false;

}

export const camelize = (str) => {
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
    return index === 0 ? word.toLowerCase() : word.toUpperCase();
  }).replace(/\s+/g, '');
}

export const findNestedObj = (mainState, keyValue, valToFind) => {
  if (mainState) {
    let foundObj;
    JSON.stringify(mainState, (_, nestedValue) => {
      if (nestedValue && nestedValue[keyValue] === valToFind) {
        foundObj = nestedValue;
      }
      return nestedValue;
    });
    //debugger;

    return foundObj;
  } else {
    return null;
  }

};

export const findNestedObjAndChange = (mainState, valToFind, newObj) => {
  if (mainState) {
    let _array = mainState;
    let foundObj;
    JSON.stringify(mainState, (_, nestedValue) => {
      if (nestedValue && nestedValue["_id"] === valToFind) {
        foundObj = nestedValue;
        //debugger
        nestedValue.object.data = newObj;
        // debugger;
      }
      return nestedValue;
    });
    return _array;
  } else {
    return null;
  }

};

export const findNestedObjAndChangeALL = (mainState, valToFind, newObj) => {
  if (mainState) {
    let _array = JSON.parse(JSON.stringify(mainState));
    let foundObj;
    JSON.stringify(mainState, (_, nestedValue) => {
      if (nestedValue && nestedValue["id"] === valToFind) {
        foundObj = nestedValue;
        //debugger
        nestedValue = newObj;
        // debugger;
      }
      return nestedValue;
    });
    return _array;
  } else {
    return null;
  }

};

export const getTime = () => {
  let d = new Date();
  let text = `${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`;
  return text;
}

var basePage = {
  width: 407,
  height: 787,
  scale: 1,
  scaleX: 1,
  scaleY: 1
};

export const scaleElement = (page, maxWidth, maxHeight) => {

  var scaleX = 1,
    scaleY = 1;
  scaleX = maxWidth / basePage.width;
  scaleY = maxHeight / basePage.height;
  basePage.scaleX = scaleX;
  basePage.scaleY = scaleY;
  basePage.scale = (scaleX > scaleY) ? scaleY : scaleX;

  var newLeftPos = Math.abs(Math.floor(((basePage.width * basePage.scale) - maxWidth) / 2));
  var newTopPos = Math.abs(Math.floor(((basePage.height * basePage.scale) - maxHeight) / 2));


  //webkitTransform
  page.style.webkitTransform = 'scale(' + basePage.scale + ')';
  page.style.transform = 'scale(' + basePage.scale + ')';
  //page.style.left = newLeftPos + 'px';
  //page.style.top = newTopPos + 'px';


}


export const extractRgba = (css) => {
  let _color = css.match(/[0-9.]+/gi)

  return {
    r: _color[0],
    g: _color[1],
    b: _color[2],
    a: css.includes("rgba") ? _color[3] : 1,
  }
}


export const colorToRgbA = (hex) => {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return {
      r: (c >> 16) & 255,
      g: (c >> 8) & 255,
      b: c & 255,
      a: '1'
    };

  } else if (typeof hex == "object") {
    return hex;
  } else if (hex.includes("rgb")) {
    return extractRgba(hex);
  }
  return {
    r: '4',
    g: '4',
    b: '4',
    a: '1',
  }
  // throw new Error('Bad Hex');
}


export const lightOrDark = (color) => {

  let
    r,
    g,
    b,
    hsp = null;
  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {

    // If HEX --> store the red, green, blue values in separate variables
    color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

    r = color[1];
    g = color[2];
    b = color[3];
  } else {

    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    color = +("0x" + color.slice(1).replace(
      color.length < 5 && /./g, '$&$&'
    ));

    r = color >> 16;
    g = color >> 8 & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(
    0.299 * (r * r) +
    0.587 * (g * g) +
    0.114 * (b * b)
  );

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {

    return 'light';
  } else {

    return 'dark';
  }
}