import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import moment from "moment";
import Emitter from "_actions/emitter";
import i18n from "i18n";
import { ResponseStatusCode } from "_helpers";
import {
  updateStore,
  updateStoreCategories,
  updateGeneratedTransactions,
  updateGeneratedModules,
  updateGeneratedInterfaces,
} from "_actions";
import {
  ErpEngineService,
  DashBoardURLSService,
  WorkspaceService,
  GITService,
} from "_services";
import {
  InputGroup,
  Input,
  Icon,
  Alert,
  SelectPicker,
  ButtonToolbar,
  IconButton,
  Tooltip,
  Whisper,
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock,
  Form,
} from "rsuite";
import { Loader, Container, Placeholder, Button, FlexboxGrid } from "rsuite";
import { Table, ColumnGroup, Column, HeaderCell, Cell } from "rsuite-table";

import { Modal } from "reactstrap";

import CreateURL from "./CreateURL";
import RunAnotherInstanceModal from "./RunAnotherInstanceModal";
import TransferModeDataModal from "./TransferModeDataModal";

const { Paragraph } = Placeholder;
let LANG = i18n.t("LANG");
const WAIT_INTERVAL = 500;
const ENTER_KEY = 13;

class CreateUpdatePlatformModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,

      platforms: [],
      urls: [],

      currentUrl: "",
      currentPlatform: null,
      currentWorkPlace: 0,
    };
  }

  resetContent = () => {
    this.setState({
      isLoading: false,
    });
  };
  componentDidUpdate(prevProps) {
    if (this.props.isActive)
      if (prevProps.isActive != this.props.isActive && this.props.isActive) {
        this.resetContent();
      }
  }

  componentWillMount() {
    this.timer = null;
  }
  onValidUrl = (val) => {
    const vRegex = /^[a-z0-9_-]+$/;
    return vRegex.test(val);
  };
  checkForm = () => {
    const { currentUrl, currentPlatform, currentWorkPlace } = this.state;
    if (currentUrl.length < 3 || !this.onValidUrl(currentUrl)) {
      Alert.warning(i18n.t("ERROR_URL_NOT_VALIDATE"));
      return;
    } else if (!currentPlatform) {
      Alert.warning(i18n.t("ERROR_PLATFORM_NOT_VALIDATE"));
      return;
    } else if (currentWorkPlace == null) {
      Alert.warning(i18n.t("ERROR_WORK_PLACE_NOT_VALIDATE"));
      return;
    } else {
      this.handleCreate();
    }
  };

  handleCreate = () => {
    const that = this;
    const { workspace, selectedService, handleToggle, handleUpdate } =
      this.props;
    const { currentUrl, currentPlatform, currentWorkPlace } = this.state;

    that.setState({ isLoading: true });
    DashBoardURLSService.CREATE_DASHSBOARD_URL(
      workspace.id,
      currentUrl,
      currentPlatform,
      currentWorkPlace
    )
      .then((data) => {
        //debugger;
        if (data.operationResult) {
          debugger;
          Alert.success(i18n.t("Success"));
          handleUpdate();
          handleToggle();
        }
      })
      .catch((err) => {
        that.setState({ isLoading: false, isSetSettingsLoading: false });
        let data = {
          title: i18n.t("warning"),
          desc: ResponseStatusCode(err.statusCode),
        };
        debugger;
        Alert.error(data.desc);
      });
  };

  render() {
    const {
      isActive,
      handleToggle,
      store,
      storeCategories,
      user,
      workspace,
      EngineStatus,
      gitStatus,
      data,
      platforms,
    } = this.props;

    const { isLoading, mystore, active } = this.state;
    return (
      <Translation>
        {(t) => (
          <>
            <Modal
              className="modal-dialog-centered"
              isOpen={isActive}
              size="sm"
              scrollable={true}
            >
              <div>
                <div className="modal-header store-model-header">
                  <h5 className="modal-title">
                    {data
                      ? t("MODAL_PLATFORM_MODAL_TITLE_UPDATE")
                      : t("MODAL_PLATFORM_MODAL_TITLE_CREATE")}
                  </h5>
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    disabled={this.state.isLoading}
                    onClick={() => handleToggle()}
                  >
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
              </div>
              <div className="modal-body store-model-body">
                <Container>
                  <div style={{ paddingBottom: "1em" }}>
                    <Form fluid>
                      <FormGroup>
                        <ControlLabel>Url</ControlLabel>
                        <FormControl
                          name="URL"
                          value={this.state.currentUrl}
                          onChange={(value) => {
                            this.setState({ currentUrl: value });
                          }}
                        />
                      </FormGroup>
                      <FormGroup>
                        <ControlLabel>Platform</ControlLabel>
                        <SelectPicker
                          block
                          cleanable={false}
                          data={platforms.map((x) => ({
                            label: x.name,
                            value: x.id,
                          }))}
                          onChange={(value) => {
                            this.setState({ currentPlatform: value });
                          }}
                        />
                      </FormGroup>
                      <FormGroup>
                        <ControlLabel>Mode</ControlLabel>
                        <SelectPicker
                          onChange={(value) => {
                            this.setState({ currentWorkPlace: value });
                          }}
                          block
                          cleanable={false}
                          data={[
                            {
                              value: 0,
                              label: i18n.t("INSTANCE_MODE_DEV"),
                            },
                            {
                              value: 1,
                              label: i18n.t("INSTANCE_MODE_QA"),
                            },
                            {
                              value: 2,
                              label: i18n.t("INSTANCE_MODE_PROD"),
                            },
                          ]}
                        />
                      </FormGroup>

                      <FormGroup>
                        <ButtonToolbar
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button onClick={handleToggle} appearance="default">
                            {t("BUTTON_CANCEL")}
                          </Button>
                          {data ? (
                            <Button>{t("BUTTON_UPDATE")}</Button>
                          ) : (
                            <Button
                              onClick={this.checkForm}
                              appearance="primary"
                            >
                              {t("BUTTON_CREATE")}
                            </Button>
                          )}
                        </ButtonToolbar>
                      </FormGroup>
                    </Form>
                  </div>
                  {this.state.isLoading && (
                    <div className={"fade-in"}>
                      <Loader backdrop vertical />
                    </div>
                  )}
                </Container>
              </div>

              {this.state.alert}
            </Modal>
          </>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer, authentication } = state;
  const { loggedIn, LoginFailed, user } = authentication;

  const {
    workspace,
    workspaces,
    selectedService,
    store,
    storeCategories,
    EngineStatus,
    gitStatus,
    TerminalList,
    generatedTransactions,
    generatedModels,
    generatedInterfaces,
  } = workspaceReducer;

  return {
    workspace,
    workspaces,
    selectedService,
    store,
    storeCategories,
    user,
    EngineStatus,
    TerminalList,
    gitStatus,
    generatedTransactions,
    generatedModels,
    generatedInterfaces,
  };
};

const mapDispatchToProps = {
  updateStore,
  updateStoreCategories,
  updateGeneratedTransactions,
  updateGeneratedModules,
  updateGeneratedInterfaces,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateUpdatePlatformModal);
