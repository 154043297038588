import {
    //ServiceURLs,
    GlobalScriptsEndpoints,
    GetBaseERPURL,
    MakeReqestAndGetResponse,
    GetUserLang,
  } from "./index";
  import { HTTPOperationType } from "./httpOperations";
  
  export const GlobalScriptService = {
    GET_GLOBALS,
    CREATE,
    UPDATE
 
  };
 

  function GET_GLOBALS(RelWorkspaceId) {
    var js = {
      RelWorkspaceId:RelWorkspaceId
    };

    return MakeReqestAndGetResponse(
      GetBaseERPURL() + GlobalScriptsEndpoints.GET_GLOBALS,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }
 
// InjectType 0 = singleton 1 = standart
  function CREATE(RelWorkspaceId,RelModuleId,ScriptName,InjectType) {
    var js = {
      RelWorkspaceId:RelWorkspaceId,
      RelModuleId:RelModuleId,
      ScriptName:ScriptName,
      InjectType:InjectType
    };

    return MakeReqestAndGetResponse(
      GetBaseERPURL() + GlobalScriptsEndpoints.CREATE,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }

  function UPDATE(id,RelWorkspaceId,RelModuleId,ScriptInside,InjectType) {
    var js = {
      id:id,
      RelWorkspaceId:RelWorkspaceId,
      RelModuleId:RelModuleId,
      ScriptInside:ScriptInside,
      InjectType:InjectType
    };

    return MakeReqestAndGetResponse(
      GetBaseERPURL() + GlobalScriptsEndpoints.UPDATE,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }