import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import i18n from "i18n";
import { ErpEngineService } from "_services";
import { Modal, Button, Alert, Dropdown, Loader } from "rsuite";
import moment from "moment";
import DynamicTable from "../DynamicTable";

class SelectObjectModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      data: [],

      filter: "",
      countPerPage: 10,
      currentPage: 1,
      short: "",
      FilterModel: {
        CurrentPage: 1,
        CountPerPage: 10,
        TotalDocument: 0,
        TotalPage: 0,
      },
    };
  }

  handleFilterAndGetData = (filter) => {
    const that = this;
    this.setState(
      {
        filter: filter,
      },
      () => {
        that.handleReadData();
      }
    );
  };


  updateExtraPath = (key, value) => {
    const that = this;
    this.setState(
      {
        [key]: value,
      },
      () => {
        that.handleReadData();
      }
    );
    /*
    filter:"",
      countPerPage: 10,
      currentPage: 1,
      short: "",
    */
  };

  componentDidUpdate(prevProps, prevState) {
    const { isActive } = this.props;
    if (isActive && isActive !== prevProps.isActive) {
      this.handleReadData();
    }
  }

  handleReadData = () => {
    const { workspace, selectedService } = this.props;
    const { filter, countPerPage, currentPage, short } = this.state;

    const FilterModel = {
      Filter: filter ? filter : "{}",
      CurrentPage: currentPage ? currentPage : undefined,
      CountPerPage: countPerPage ? countPerPage : undefined,
      Sorts: short ? short : undefined,
    };

    const URL_MODE = "dev_URL";
    const that = this;
    this.setState({ loading: true });
    ErpEngineService.EXECUTE_TRANSACTION_DIRECTLY(
      workspace[URL_MODE],
      "_read",
      selectedService.executableTransactionServiceName,
      "{}",
      FilterModel
    )
      .then((data) => {
        if (data.operationResult) {
          this.setState({
            data: data.dynamicValue,
            FilterModel: data.FilterModel,
          });
        }
        that.setState({ loading: false });
      })
      .catch((err) => {
        that.setState({ loading: false });
        Alert.error(i18n.t("MESSAGE_TRANSACTION_READ_UNSUCCESS"));
        debugger;
      });
  };

  render() {
    const {
      isActive,
      handleToggle,
      handleSelect,
      selectedService,
      allServices,
      modelsAndTrans
    } = this.props;
    const that = this;
    const { data,loading } = this.state;
    const _date = moment(new Date()).format("YYYY-MM-DD_h-mm");
    const _finded =
    modelsAndTrans && modelsAndTrans.find((x) =>( x.model.id === (selectedService.responseModelType
      ? selectedService.relResponseModelId
      : selectedService.reqModelId)));
    if(isActive){
      debugger
    }
    return (
      <Translation>
        {(t) => (
          <>
            <Modal full size={"md"} show={isActive} onHide={handleToggle}>
              <Modal.Header>
                <Modal.Title>{isActive && (selectedService.name.split("-"))[0]}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <> 
                <DynamicTable
                    classname={"view-table"}
                    handleSelect={(_data) => {
                      handleSelect(_data);
                      handleToggle();
                    }}
                    data={data}
                    dataModel={selectedService.model}
                    isSearchable={true}


                    searchObjectModalRef = {_finded?.model}
                    isStandartList={true}
                    updateExtraPath={this.updateExtraPath}
                    filter={this.state.filter}
                    countPerPage={this.state.countPerPage}
                    currentPage={this.state.currentPage}
                    short={this.state.short}
                    FilterModel={this.state.FilterModel}
                    handleFilterAndGetData={this.handleFilterAndGetData}
                  />
                    {loading &&
                  <div className={"fade-in"}>
                      <Loader backdrop vertical />
                    </div>
                }
                 
                </>
              
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={handleToggle} appearance="primary">
                 {t("BUTTON_OK")}
                </Button>
                <Button onClick={handleToggle} appearance="subtle">
                  {t("BUTTON_CANCEL")}
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer } = state;
  const {
    model,
    models,
    transaction,
    transactions,
    workspace,
    dataModels,
    selectedService,
    allServices,
    modelsAndTrans
  } = workspaceReducer;

  return {
    workspace,
    transaction,
    transactions,
    dataModels,
    models,
    model,
    selectedService,
    allServices,
    modelsAndTrans
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SelectObjectModal);
