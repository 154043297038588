import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import moment from "moment";
import Emitter from "_actions/emitter";
import i18n from "i18n";
import { ResponseStatusCode } from "_helpers";
import {
  erpRolesService,
  ModulesService,
  erpRolesAndUserRelationsService,
  WorkspaceUserService,
} from "_services";
import { InputGroup, Input, Icon, Alert, SelectPicker } from "rsuite";
import { Nav, Tag, TagGroup } from "rsuite";
import {
  Loader,
  Container,
  Row,
  Placeholder,
  Button,
  IconButton,
  ButtonToolbar,
  Tooltip,
  Whisper,
} from "rsuite";
import { NavLink } from "react-router-dom";
import CreateRole from "./CreateRole";
import EditRole from "./EditRole";
import EditUserRole from "./EditUserRole";

import CreateUser from "./CreateUser";

import { Modal } from "reactstrap";

const { Paragraph } = Placeholder;
let LANG = i18n.t("LANG");
const WAIT_INTERVAL = 500;
const ENTER_KEY = 13;

class UsersAndRolesModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      search: "",
      active: "users",
      cRole: null, //selected role for edit
      cUser: null, //selected user for edit
      isCreateRole: false,
      isEditRole: false,
      isCreateUser: false,
      isEditUserRole: false,
      roles: null,
      users: null,
      searchUser: "",
      searchRole: "",
      scopes: [],
    };
  }

  resetContent = () => {
    this.setState({
      isLoading: true,
      search: "",
      //   active: "users",
      isCreateUser: false,
      isEditRole: false,
      cRole: null, //selected role for edit
      cUser: null, //selected user for edit
      isCreateRole: false,
      isEditUserRole: false,
      roles: null,
      users: null,
      searchUser: "",
      searchRole: "",
    });
    this.getRoles();
    this.getUsers();
  };

  componentDidUpdate(prevProps) {
    if (this.props.isActive)
      if (prevProps.isActive != this.props.isActive && this.props.isActive) {
        this.handleGetScopes();
        this.resetContent();
      }
  }
  handleEditUser = (user = null) => {
    this.setState({ isEditUserRole: !this.state.isEditUserRole, cUser: user });
  };
  handleCreateRole = () => {
    this.setState({ isCreateRole: !this.state.isCreateRole });
  };
  handleEditRole = (_data = null) => {
    this.setState({ isEditRole: !this.state.isEditRole, cRole: _data });
  };
  handleCreateUser = () => {
    this.setState({ isCreateUser: !this.state.isCreateUser });
  };

  getUsers = () => {
    const { updateModules, workspace, module } = this.props;
    //RelERP_WORK_PLACE 0 =dev 1 = QA 2 = PROD
    const that = this;
    that.setState({ isLoading: true });

    WorkspaceUserService.GET_USERS(workspace.id, 0)
      .then((data) => {
        if (data.operationResult) {
          this.setState({
            users: data.dynamicValue,
          });
          //  updateStore(data.dynamicValue)
        }
        that.setState({ isLoading: false });
      })
      .catch((err) => {
        that.setState({ isLoading: false });
        Alert.error(ResponseStatusCode(err.statusCode));
        //  debugger;
      });
  };

  getRoles = (_search = "") => {
    const { updateModules, workspace, module } = this.props;
    const that = this;
    that.setState({ isLoading: true });
    erpRolesService
      .GET_ROLES(true, "-1", 9999, _search, workspace.id)
      .then((data) => {
        if (data.operationResult) {
          this.setState({
            roles: data.dynamicValue,
          });

          //  updateStore(data.dynamicValue)
        }
        that.setState({ isLoading: false });
      })
      .catch((err) => {
        that.setState({ isLoading: false });
        Alert.error(ResponseStatusCode(err.statusCode));
        debugger;
      });
  };

  handleGetScopes = () => {
    const { workspace } = this.props;
    const that = this;
    // that.setState({ isLoading: true });
    erpRolesService
      .GET_SCOPES(workspace.id)
      .then((data) => {
        that.setState({
          scopes: data.dynamicValue,
        });
      })
      .catch((err) => {
        Alert.warning(ResponseStatusCode(err.statusCode));
        //    that.setState({ isLoading: false });
        debugger;
        //  let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
        //   this.warningAlert(data);
      });
  };

  handleSelect = (activeKey) => {
    if (activeKey !== "users") {
      this.getRoles();
    } else {
      this.getUsers();
    }

    this.setState({
      active: activeKey,
      searchUser: "",
      searchRole: "",
    });
  };

  componentWillMount() {
    this.timer = null;
  }
  handleChange = (event) => {
    const { target } = event;
    var theEvent = event || window.event;
    clearTimeout(this.timer);
    this.setState({
      [this.state.active === "users" ? "searchUser" : "searchRole"]:
        event.target.value,
    });

    this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL);
  };

  triggerChange = () => {
    const { active, searchUser, searchRole } = this.state;
    if (active === "users") {
      this.getUsers(searchUser);
    } else {
      this.getRoles(searchRole);
    }
  };

  handleResetPassword = (item) => {
    const { workspace } = this.props;
    //RelERP_WORK_PLACE 0 =dev 1 = QA 2 = PROD
    const that = this;
    that.setState({ isLoading: true });

    WorkspaceUserService.RESET_PASSWORD_FROM_ADMIN_SIDE(
      workspace.id,
      0,
      item.id
    )
      .then((data) => {
        if (data.operationResult) {
          Alert.success(i18n.t("MESSAGE_RESET_PASSWORD_SUCCESS"));
        }
        that.setState({ isLoading: false });
      })
      .catch((err) => {
        that.setState({ isLoading: false });
        Alert.error(ResponseStatusCode(err.statusCode));
        switch (err.statusCode) {
          case 186:
            Emitter.emit("WORKSPACE_URL_NOT_FOUND");
            break;

          default:
            break;
        }
        //  debugger;
      });
  };

  handleSetActiveStatus = (item) => {
    const { workspace } = this.props;
    //RelERP_WORK_PLACE 0 =dev 1 = QA 2 = PROD
    const that = this;
    that.setState({ isLoading: true });
    const _rStatus = !item.isActive;
    WorkspaceUserService.SET_ACTIVE_STATUS(workspace.id, 0, item.id, _rStatus)
      .then((data) => {
        if (data.operationResult) {
          if (_rStatus) {
            Alert.success(i18n.t("MESSAGE_USER_STATUS_ACTIVE_SUCCESS"));
          } else {
            Alert.success(i18n.t("MESSAGE_USER_STATUS_PASSIVE_SUCCESS"));
          }
          that.getUsers();
        }
        that.setState({ isLoading: false });
      })
      .catch((err) => {
        that.setState({ isLoading: false });
        Alert.error(ResponseStatusCode(err.statusCode));
        //  debugger;
      });
  };
  render() {
    const { isActive, handleToggle } = this.props;
    const {
      isLoading,
      active,
      roles,
      users,
      isCreateRole,
      isCreateUser,
      scopes,
    } = this.state;
    return (
      <Translation>
        {(t) => (
          <>
            <Modal
              className="modal-dialog-centered"
              isOpen={isActive}
              size="lg"
              scrollable={true}
            >
              <div>
                <div className="modal-header store-model-header">
                  <h5 className="modal-title" id="CheckProjectModal">
                    {t("TITLE_MODAL_USERS_ROLES")}
                  </h5>
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    disabled={this.state.isLoading}
                    onClick={() => handleToggle()}
                  >
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
                <div className={"store-header"}>
                  <Nav
                    appearance="subtle"
                    activeKey={this.state.active}
                    onSelect={this.handleSelect}
                    style={{}}
                  >
                    <Nav.Item eventKey="users">
                      {t("TITLE_MODAL_USERS_ROLES_TAB_USERS")}
                    </Nav.Item>
                    <Nav.Item eventKey="roles">
                      {t("TITLE_MODAL_USERS_ROLES_TAB_ROLES")}
                    </Nav.Item>
                  </Nav>
                  <div className={"store-header-sub"}>
                    {this.state.active == "users" ? (
                      <Button
                        onClick={this.handleCreateUser}
                        appearance="primary"
                        style={{ marginRight: "10px" }}
                        size="sm"
                      >
                        {t("BUTTON_CREATE")}
                      </Button>
                    ) : (
                      <Button
                        onClick={this.handleCreateRole}
                        appearance="primary"
                        style={{ marginRight: "10px" }}
                        size="sm"
                      >
                        {t("BUTTON_CREATE")}
                      </Button>
                    )}

                    <InputGroup size="sm" inside style={{ width: "150px" }}>
                      <Input
                        value={
                          active === "users"
                            ? this.state.searchUser
                            : this.state.searchRole
                        }
                        onBlur={() => {}}
                        // onKeyDown={this.onKeyDown.bind(this)}
                        onInput={(value) => {
                          this.handleChange(value);
                        }}
                        placeholder={t("PLACEHOLDER_SEARCH")}
                      />
                      <InputGroup.Button>
                        <Icon icon={"search"} />
                      </InputGroup.Button>
                    </InputGroup>
                  </div>
                </div>
              </div>

              <div className="modal-body store-model-body">
                <Container>
                  {active === "users" ? (
                    <Row>
                      {users &&
                        users.map((item, key) => (
                          <div
                            className={"users-card"}
                            key={key + "" + item.id}
                          >
                            <div className={"users-card-header"}>
                              <div>
                                <div style={{ fontWeight: "600" }}>
                                  {item.firstName + " " + item.lastName}
                                </div>
                                <div>{item.username}</div>
                                <div>{item.email}</div>
                              </div>
                              <ButtonToolbar>
                                <Whisper
                                  placement="bottom"
                                  trigger="hover"
                                  speaker={
                                    <Tooltip>
                                      {item.isActive
                                        ? t("TOOLTIP_USERS_ROLES_USER_ACTIVE")
                                        : t(
                                            "TOOLTIP_USERS_ROLES_USER_INACTIVE"
                                          )}
                                    </Tooltip>
                                  }
                                >
                                  <IconButton
                                    onClick={() =>
                                      this.handleSetActiveStatus(item)
                                    }
                                    color={item.isActive ? "green" : "red"}
                                    icon={
                                      <Icon
                                        icon={item.isActive ? "check" : "ban"}
                                      />
                                    }
                                    circle
                                  />
                                </Whisper>

                                <Whisper
                                  placement="bottom"
                                  trigger="hover"
                                  speaker={
                                    <Tooltip>
                                      {t(
                                        "TOOLTIP_USERS_ROLES_USER_RESET_PASSWORD"
                                      )}
                                    </Tooltip>
                                  }
                                >
                                  <IconButton
                                    onClick={() =>
                                      this.handleResetPassword(item)
                                    }
                                    appearance="ghost"
                                    icon={<Icon icon="key" />}
                                    circle
                                  />
                                </Whisper>
                                <Whisper
                                  placement="bottom"
                                  trigger="hover"
                                  speaker={
                                    <Tooltip>
                                      {t("TOOLTIP_USERS_ROLES_USER_EDIT_ROLE")}
                                    </Tooltip>
                                  }
                                >
                                  <IconButton
                                    onClick={() => this.handleEditUser(item)}
                                    className="role-button"
                                    icon={<Icon icon="edit2" />}
                                    circle
                                  />
                                </Whisper>
                              </ButtonToolbar>
                            </div>
                          </div>
                        ))}
                    </Row>
                  ) : (
                    <Row>
                      {roles &&
                        roles.dynamicValue.map((item, key) => {
                        //  debugger
                          return (
                            <div className={"role-card"} key={key + "" + item.id}>
                              <div className={"role-card-header"}>
                                {item.roleName}
                                <ButtonToolbar>
                                  <IconButton
                                    onClick={() => this.handleEditRole(item)}
                                    className="role-button"
                                    icon={<Icon icon="edit2" />}
                                    circle
                                  />
                                  {/*
                                      <IconButton
                                    className="role-button"
                                    icon={<Icon icon="save" />}
                                    circle
                                  />
                                      
                                       */}
                                </ButtonToolbar>
                              </div>
  
                              <TagGroup>
                                {item.roles.map((ii, kk) => (
                                  <Tag
                                    className="role-tag"
                                    key={key + "" + item.id + "-" + kk}
                                  >
                                    {ii.short}
                                  </Tag>
                                ))}
                              </TagGroup>
                            </div>
                          )
                        })}
                    </Row>
                  )}

                  {(active === "users" ? users : roles) ? (
                    (active === "users" ? users : roles.dynamicValue).length <
                      1 && (
                      <div className={"store-not-found"}>
                        <Icon icon={"exclamation-triangle"} />
                        {active === "users"
                          ? t("USERS_NO_RESULT")
                          : t("ROLES_NO_RESULT")}
                      </div>
                    )
                  ) : (
                    <div className={"store-not-found"}>
                      <Icon icon={"exclamation-triangle"} />
                      {active === "users"
                        ? t("USERS_NO_RESULT")
                        : t("ROLES_NO_RESULT")}
                    </div>
                  )}

                  {isLoading && (
                    <div className={"fade-in"}>
                      <Loader backdrop vertical />
                    </div>
                  )}
                </Container>
              </div>

              {this.state.alert}
            </Modal>
            <CreateRole
              onRefresh={this.resetContent}
              isActive={this.state.isCreateRole}
              handleToggle={this.handleCreateRole}
            />
            <EditRole
              onRefresh={this.resetContent}
              isActive={this.state.isEditRole}
              data={this.state.cRole}
              scopes={scopes}
              handleToggle={this.handleEditRole}
            />
            <CreateUser
              onRefresh={this.resetContent}
              isActive={this.state.isCreateUser}
              handleToggle={this.handleCreateUser}
            />

            <EditUserRole
              onRefresh={this.resetContent}
              isActive={this.state.isEditUserRole}
              data={this.state.cUser}
              roles={this.state.roles}
              scopes={scopes}
              handleToggle={this.handleEditUser}
            />
          </>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer } = state;
  const { workspace, workspaces, selectedService, store, storeCategories } =
    workspaceReducer;

  return {
    workspace,
    workspaces,
    selectedService,
    store,
    storeCategories,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UsersAndRolesModal);
