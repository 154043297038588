/*
    18/11/2020 - Abdullah Kurnaz 
    abdullah.kurnaz@nearlineconsulting.com
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import { Table } from "rsuite";

import EditRowData from "components/Modals/EditRowData";
import CreateRowData from "components/Modals/CreateRowData";
import DeleteRowData from "components/Modals/DeleteRowData";
import EditSpecificRowData from "components/Modals/EditSpecificRowData";
import HiddenDataTypes from "components/Modals/HiddenDataTypes";
import ShowModelExtraButtons from "components/Modals/ShowModelExtraButtons";
import ShowRelationTabs from "components/Modals/ShowRelationTabs";

import { FlexboxGrid } from "rsuite";
import { decamelize } from "assets/globalOps/global";
import CCell from "./CCell";

import { CMSService } from "_services";

import { Button, IconButton, Icon, ButtonToolbar, Alert } from "rsuite";

const { Column, HeaderCell, Cell, Pagination } = Table;

export class CTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      selectedRow: null,
      isEditActive: false,
      isCreateActive: false,
      isDeleteActive: false,
      isEditSpecificRowData: false,
      isExtraButtons: false,
      isRelationTabs: false,
      data: [],
    };
  }

  handleEditSpecificRowData = () => {
    this.setState({
      isEditSpecificRowData: !this.state.isEditSpecificRowData,
    });
  };

  componentDidMount() {
    this.setState({
      data: this.props.data ? this.props.data : [],
    });
  }

  componentDidUpdate = (prevProps) => {
    const { data } = this.props;
    if (JSON.stringify(data) != JSON.stringify(prevProps.data)) {
      this.setState({
        data: this.props.data ? this.props.data : [],
      });
    }
  };

  handleCreate = (rowData) => {
    let { data } = this.state;
    const { addRowData } = this.props;
    addRowData(rowData);
  };

  handleUpdateHiddenField = (data) => {
    const { updateHiddenFieldData } = this.props;
    updateHiddenFieldData(data);

    this.setState({ isHiddenTypes: false });
  };

  handleDelete = (rowData) => {
    this.setState(
      {
        selectedRow: rowData,
      },
      () => {
        this.handleDeleteModalToggle();
      }
    );
  };

  handleEdit = (rowData) => {
    this.setState(
      {
        selectedRow: rowData,
      },
      () => {
        this.handleEditModalToggle();
      }
    );
  };
  handleDeleteData = (rowData) => {
    let { data, selectedRow } = this.state;
    const { deleteData } = this.props;
    let cIndex = data.findIndex(
      (x) => JSON.stringify(x) === JSON.stringify(selectedRow)
    );
    deleteData(rowData, cIndex);
  };

  handleUpdateRow = (rowData) => {
    let { data, selectedRow } = this.state;
    const { updateData } = this.props;
    let cIndex = data.findIndex(
      (x) => JSON.stringify(x) === JSON.stringify(selectedRow)
    );
    updateData(rowData, cIndex);
  };

  handleEditModalToggle = () => {
    this.setState({
      isEditActive: !this.state.isEditActive,
    });
  };

  handleUpdateRelationTabs = () => {
    this.setState({
      isRelationTabs: !this.state.isRelationTabs,
    });
  };

  handleCreateModalToggle = () => {
    this.setState({
      isCreateActive: !this.state.isCreateActive,
    });
  };

  handleDeleteModalToggle = () => {
    this.setState({
      isDeleteActive: !this.state.isDeleteActive,
    });
  };

  handleUpdateHiddenFieldData = () => {
    this.setState({
      isHiddenTypes: !this.state.isHiddenTypes,
    });
  };
  handleUpdateExtraButtons = () => {
    this.setState({
      isExtraButtons: !this.state.isExtraButtons,
    });
  };

  getCurrentLangDisplayName = (item) => {
    try {
      const _lang = this.props.currentLang;
      if (_lang) {
        let _langDNM = item.displayNameMultiLangs.find((x) => x.lang == _lang);
        if (_langDNM && _langDNM.textValue.trim().length > 0) {
          return _langDNM.textValue;
        } else {
          _langDNM = item.displayNameMultiLangs.find((x) => x.lang == "en");
          return _langDNM.textValue;
        }
      } else {
        const _langDNM = item.displayNameMultiLangs.find((x) => x.lang == "en");
        return _langDNM.textValue;
      }
    } catch (error) {
      return item.hasOwnProperty("displayName")
        ? item.displayName
        : decamelize(item.name, " ");
    }
  };

  render() {
    const {
      isFromMVC,
      isEditable,
      cloneData,
      isSub,
      subComponent,
      isDynamic,
      isClonable,
      isEditPriority,
      isCreatable,
      isDeletable,
      isAction,
      updateData,
      schemaModel,
      schemaModel2,
      isRefModel,
      updateHiddenFieldData,
      hiddenDataTypes,
      collectionName,
      isCreateDB,
    } = this.props;
    return (
      <Translation>
        {(t) => (
          <>
            <FlexboxGrid
              justify="end"
              style={{
                alignItems: "center",
                paddingTop: "1rem",
                paddingBottom: "10px",
                gap: 10,
              }}
            >
              {isEditPriority && (
                <Button
                  size={"xs"}
                  onClick={(e) => {
                    e.preventDefault();
                    this.handleEditSpecificRowData();
                  }}
                  appearance="ghost"
                  className={"project-create-btn"}
                >
                  {t("BUTTON_EDIT_PRIORITY")}
                </Button>
              )}

              {isSub && isCreatable && isRefModel && (
                <FlexboxGrid.Item style={{ marginRight: "10px" }}>
                  {subComponent}
                </FlexboxGrid.Item>
              )}
              {isCreatable && (
                <>
                  {this.state.data.find((x) => x.contentType == 10) && (
                    <FlexboxGrid.Item>
                      <Button
                        size={"xs"}
                        style={{
                          marginRight: "5px",
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          this.handleUpdateHiddenFieldData();
                        }}
                        appearance="ghost"
                        className={"project-create-btn"}
                      >
                        {t("BUTTON_HIDDEN_TYPES")}
                      </Button>
                    </FlexboxGrid.Item>
                  )}
                  {this.props.selectedService&& this.props.selectedService.reqModelId && (
                    <FlexboxGrid.Item>
                      <Button
                        onClick={() => this.handleUpdateRelationTabs()}
                        size={"xs"}
                        //  icon={<Icon icon="filter" />}

                        appearance="ghost"
                        className={"project-create-btn"}
                      >
                        {t("BUTTON_RELATIONS_TABS")}
                      </Button>
                    </FlexboxGrid.Item>
                  )}

                  <FlexboxGrid.Item>
                    <Button
                      onClick={() => this.props.handleSearchFilter()}
                      size={"xs"}
                      //  icon={<Icon icon="filter" />}

                      appearance="ghost"
                      className={"project-create-btn"}
                    >
                      {t("BUTTON_FILTER")}
                    </Button>
                  </FlexboxGrid.Item>
                  {!isCreateDB && this.props.selectedService&& this.props.selectedService.reqModelId && (
                    <FlexboxGrid.Item>
                      <Button
                        size={"xs"}
                        onClick={(e) => {
                          e.preventDefault();
                          this.handleUpdateExtraButtons();
                        }}
                        appearance="ghost"
                        className={"project-create-btn"}
                      >
                        {t("BUTTON_EXTRA_BUTTONS")}
                      </Button>
                    </FlexboxGrid.Item>
                  )}

                  <FlexboxGrid.Item>
                    <Button
                      size={"xs"}
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleCreateModalToggle();
                      }}
                      appearance="ghost"
                      className={"project-create-btn"}
                    >
                      {t("BUTTON_CREATE_NEW")}
                    </Button>
                  </FlexboxGrid.Item>
                </>
              )}
            </FlexboxGrid>
            <Table
              height={420}
              data={this.state.data}
              rowClassName={"table-global-dm"}
              bordered
              wordWrap={true}
              cellBordered
              autoHeight
              affixHeader
              affixHorizontalScrollbar
              onRowClick={(data, event) => {
                event.stopPropagation();
                isAction && isEditable && this.handleEdit(data);
              }}
            >
              {this.props.dataModel &&
                this.props.dataModel.map((item, key) => (
                  <Column key={key} align="center" resizable>
                    <HeaderCell className={"cell-header"}>
                      {this.getCurrentLangDisplayName(item)}
                    </HeaderCell>
                    <CCell
                      data={item}
                      dataKey={item.name}
                      allTransactions={this.props.allTransactions}
                    />
                  </Column>
                ))}
              {isAction ? (
                <Column
                  width={
                    30 +
                    (isClonable ? 40 : 0) +
                    (isEditable ? 40 : 0) +
                    (isDeletable ? 40 : 0)
                  }
                  fixed="right"
                  className={"ctable-last-column"}
                >
                  <HeaderCell>{t("TABLE_STATIC_ACTIONS")}</HeaderCell>
                  <Cell>
                    {(rowData) => {
                      return (
                        <ButtonToolbar>
                          {isClonable && (
                            <IconButton
                              size={"sm"}
                              icon={<Icon icon="clone" />}
                              onClick={(event) => {
                                event.stopPropagation();
                                cloneData(rowData);
                              }}
                            />
                          )}
                          {isEditable && (
                            <IconButton
                              size={"sm"}
                              icon={<Icon icon="far fa-edit" />}
                              onClick={(event) => {
                                event.stopPropagation();
                                this.handleEdit(rowData);
                              }}
                            />
                          )}
                          {isDeletable && (
                            <IconButton
                              size={"sm"}
                              icon={<Icon icon="fas fa-trash" />}
                              onClick={(event) => {
                                event.stopPropagation();
                                this.handleDelete(rowData);
                              }}
                            />
                          )}
                        </ButtonToolbar>
                      );
                    }}
                  </Cell>
                </Column>
              ) : (
                <Column width={0} className={"ctable-last-column"}>
                  <HeaderCell></HeaderCell>
                  <Cell></Cell>
                </Column>
              )}
            </Table>
            <HiddenDataTypes
              collection={this.state.data}
              isDynamic={isDynamic}
              schemaModel2={schemaModel2}
              schemaModel={schemaModel}
              dataModel={this.props.dataModel}
              handleUpdate={this.handleUpdateHiddenField}
              isActive={this.state.isHiddenTypes}
              handleToggle={this.handleUpdateHiddenFieldData}
              defauldValue={hiddenDataTypes}
            />
            {this.props.selectedService &&
              this.props.selectedService.reqModelId && (
                <ShowModelExtraButtons
                  isActive={this.state.isExtraButtons}
                  handleToggle={this.handleUpdateExtraButtons}
                  isRefModel={isRefModel}
                />
              )}

            {this.props.selectedService &&  this.props.selectedService.reqModelId && (
              <ShowRelationTabs
                isActive={this.state.isRelationTabs}
                handleToggle={this.handleUpdateRelationTabs}
                isRefModel={isRefModel}
              />
            )}

            {this.state.isCreateActive && (
              <CreateRowData
                collectionName={collectionName}
                collection={this.state.data}
                isDynamic={isDynamic}
                schemaModel2={schemaModel2}
                schemaModel={schemaModel}
                dataModel={this.props.dataModel}
                handleCreate={this.handleCreate}
                isActive={this.state.isCreateActive}
                handleToggle={this.handleCreateModalToggle}
              />
            )}
            <EditRowData
              collectionName={collectionName}
              isFromMVC={isFromMVC}
              collection={this.state.data}
              isDynamic={isDynamic}
              schemaModel2={schemaModel2}
              schemaModel={schemaModel}
              data={this.state.selectedRow}
              dataModel={this.props.dataModel}
              isActive={this.state.isEditActive}
              handleToggle={this.handleEditModalToggle}
              handleUpdateRow={this.handleUpdateRow}
            />
            <DeleteRowData
              collectionName={collectionName}
              data={this.state.selectedRow}
              isActive={this.state.isDeleteActive}
              handleToggle={this.handleDeleteModalToggle}
              handleDelete={this.handleDeleteData}
            />
            <EditSpecificRowData
              collectionName={collectionName}
              updatePriority={this.props.updatePriority}
              dataModel={this.props.dataModel}
              data={this.state.data}
              isActive={this.state.isEditSpecificRowData}
              handleToggle={this.handleEditSpecificRowData}
            />
          </>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer } = state;
  const {
    workspaces,
    workspace,
    selectedService,
    services,
    modules,
    module,
    allTransactions,
    currentLang,
  } = workspaceReducer;

  return {
    workspaces,
    workspace,
    modules,
    module,
    selectedService,
    services,
    allTransactions,
    currentLang,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CTable);
