import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Translation } from "react-i18next";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { ActionCreators as UndoActionCreators } from 'redux-undo'
import Emitter from "_actions/emitter";
import i18n from "i18n";
import { ResponseStatusCode } from "_helpers";
import { addNewPage, removeComponent } from '_actions'
import { WorkspaceService, DevLangService,ModulesService } from "_services";
import { Button, Alert, SelectPicker } from 'rsuite';

import {

    Input,
    Modal,
    Container,
    Row, Col
} from "reactstrap";
/*
 data={storeCategories.map((item,key)=>{
                                                return (
                                                    {
                                                        
                                                    }
                                                )
                                            })}
*/
class CreateModuleModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: false,
            langs: [],
            customStyles: {
                pageName: "",
                pageNameState: null,
                desc: "",
                descState: null,
                langId: null,
                langState: null,
                version: null,
                versionState: null,
                alert: null,
            },

        }
 

    }

    successAlert = (data) => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title={data.title}
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    confirmBtnText={i18n.t("BUTTON_OK")}
                    btnSize=""
                >
                    {data.desc}
                </ReactBSAlert>
            ),
        });
    };
    warningAlert = (data) => {
        this.setState({
            alert: (
                <div className={"modal-sweet-alert"}>
                    <ReactBSAlert
                        warning
                        style={{ display: "block", marginTop: "-100px" }}
                        title={data.title}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnBsStyle="warning"
                        confirmBtnText={i18n.t("BUTTON_OK")}
                        btnSize=""
                    >
                        {data.desc}
                    </ReactBSAlert>
                </div>

            ),
        });
    };
    hideAlert = () => {
        this.setState({
            alert: null,
        });
    };


    handleCreateModuleModal = () => {
        const {workspace, clearHistory, onRefresh, handleToggle, removeComponent, AddPageToProject } = this.props;
        let _state = this.state.customStyles;
        const that = this;
        that.setState({ isLoading: true })
        ModulesService.CREATE_MODULE(_state.pageName,[{
            "lang": "en",
            "textValue": _state.desc
        }],_state.langId,[],_state.version,workspace.id).then(data => {
            onRefresh("",workspace.id,true);
            handleToggle();
        }).catch(err => {
            Alert.warning(ResponseStatusCode(err.statusCode))
            that.setState({ isLoading: false })
            debugger
            //  let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
            //   this.warningAlert(data);
        });
    }

    customStylesForm = (e, stateName) => {
        let newState = this.state.customStyles;
        newState[stateName] = e.target.value;
        if (stateName === "checkbox") {
            if (e.target.value) {
                newState.checkboxState = "valid";
            } else {
                newState.checkboxState = "invalid";
            }
        } else {
            if (e.target.value === "") {
                newState[stateName + "State"] = "invalid";
            } else {
                newState[stateName + "State"] = "valid";
            }
        }
        this.setState({
            customStyles: newState
        });
    };

    onKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault()
            this.validateCustomStylesForm()
        }
    }

    validateCustomStylesForm = () => {
        let newState = this.state.customStyles;
        newState.pageName = newState.pageName.trim();

        var regex = /^[a-zA-Z][a-zA-Z0-9_]{1,}$/;


        if (newState.pageName === "") {
            newState.pageNameState = "invalid";
        } else if (!regex.test(newState.pageName)) {
            newState.pageNameState = "invalid";
        }
        else {
            newState.pageNameState = "valid";
        }

        if (newState.langId === null) {
            newState.langState = "invalid";
            Alert.error(i18n.t("MODAL_CREATE_MODULE_TEXTBOX_LANG_INVALID"))
        }
        else {
            newState.langState = "valid";
        }

        if (newState.version === null) {
            newState.versionState = "invalid";
        } else if (newState.version < 0.001) {
            newState.versionState = "invalid";
        }
        else {
            newState.versionState = "valid";
        }


        this.setState({
            customStyles: newState
        }, () => {
            if (newState.pageNameState == "valid" && newState.langState == "valid" && newState.versionState == "valid") {
                this.handleCreateModuleModal()
            }
        });
    };
    resetContent = () => {
        this.getDevLangs();
        this.setState({
            isLoading: false,
            customStyles: {
                pageName: "",
                pageNameState: null,
                desc: "",
                descState: null,
                langId: null,
                langState: null,
                version: null,
                versionState: null,
                alert: null,
            },

        })

    }

    getDevLangs = () => {
        const { clearHistory, onRefresh, handleToggle, removeComponent, AddPageToProject } = this.props;
        let _state = this.state.customStyles;
        const that = this;
        that.setState({ isLoading: true })
        DevLangService.GET_DEV_LANGS().then(data => {
            if (data.operationResult) {
                let _array = [];
                data.dynamicValue.forEach(element => {
                    _array.push({
                        "label": element.name,
                        "value": element.id,
                    })
                });
                that.setState({ langs: _array });

            }
        }).catch(err => {
            Alert.warning(ResponseStatusCode(err.statusCode))
            debugger
            //  let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
            //   this.warningAlert(data);
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.isActive)
            if (prevProps.isActive != this.props.isActive && this.props.isActive) {
                this.resetContent();
            }
    }



    render() {
        const { isActive, handleToggle } = this.props;
        const { isLoading } = this.state;
        return (
            <Translation>
                {t => (
                    <>
                        <Modal
                            className="modal-dialog-centered"
                            isOpen={isActive}
                        //size="lg"
                        // scrollable={true}
                        >
                            <div className="modal-header">
                                <h5 className="modal-title" id="CheckProjectModal">
                                    {t("SERVICES_MENU_ITEM_CREATEMODULE")}
                                </h5>
                                <button
                                    aria-label="Close"
                                    className="close"
                                    data-dismiss="modal"
                                    type="button"
                                    disabled={this.state.isLoading}
                                    onClick={() => handleToggle()}
                                >
                                    <span aria-hidden={true}>×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <Container>
                                    <Row>
                                        <label
                                            className="form-control-label"
                                            htmlFor="validationCustomPagename"
                                        >
                                            {t("MODAL_CREATE_WORKSPACE_TEXTBOX_NAME")}
                                        </label>
                                        <Input
                                            //innerRef={(input) => { setTimeout(() => { try { input.focus() } catch (error) { } }, 300); }}
                                            autoFocus
                                            aria-describedby="inputGroupPrepend"
                                            placeholder={t("MODAL_CREATE_MODULE_TEXTBOX_NAME_PLACEHOLDER")}
                                            type="text"
                                            defaultValue={this.state.customStyles.pageName}
                                            id="validationCustomPagename"
                                            onKeyDown={this.onKeyDown.bind(this)}
                                            valid={
                                                this.state.customStyles.pageNameState === "valid"
                                            }
                                            invalid={
                                                this.state.customStyles.pageNameState ===
                                                "invalid"
                                            }
                                            disabled={isLoading}
                                            onChange={e => this.customStylesForm(e, "pageName")}
                                        />

                                        <div className="invalid-feedback">
                                            {t("MODAL_CREATE_MODULE_TEXTBOX_NAME_INVALID")}
                                        </div>
                                    </Row>
                                    <Row style={{ marginTop: "10px" }}>
                                        <label >
                                            {t("MODAL_CREATE_MODULE_TEXTBOX_DESC")}
                                        </label>
                                        <Input type="textarea" placeholder={t("MODAL_CREATE_MODULE_TEXTBOX_DESC")}
                                             
                                            disabled={isLoading}
                                            onChange={e => this.customStylesForm(e, "desc")}
                                        />
                                         
                                    </Row>
                                    <Row style={{ marginTop: "10px", flexDirection: "column" }}>
                                        <label
                                            className="form-control-label"
                                            htmlFor="validationCustomLang"
                                        >
                                            {t("MODAL_CREATE_MODULE_TEXTBOX_DEV_LANG")}
                                        </label>
                                        <SelectPicker onSelect={(value) => {
                                            let _cs = this.state.customStyles;
                                            _cs.langId = value;
                                            _cs.langState = "valid";
                                            this.setState({customStyles:_cs})
                                        }} placeholder={t("MODAL_CREATE_MODULE_TEXTBOX_DEV_LANG")} block data={this.state.langs} cleanable={false} />                                    </Row>
                                    <Row style={{ marginTop: "10px" }}>
                                        <label
                                            className="form-control-label"
                                            htmlFor="validationCustomVersion"
                                        >
                                            {t("MODAL_CREATE_MODULE_TEXTBOX_VERSION")}
                                        </label>
                                        <Input type="number" placeholder={"0.00"} min={"0.1"} step={"0.1"}
                                            id="validationCustomVersion"
                                            onKeyDown={this.onKeyDown.bind(this)}
                                            valid={
                                                this.state.customStyles.versionState === "valid"
                                            }
                                            invalid={
                                                this.state.customStyles.versionState ===
                                                "invalid"
                                            }
                                            disabled={isLoading}
                                            onChange={e => this.customStylesForm(e, "version")}
                                        />
                                        <div className="invalid-feedback">
                                            {t("MODAL_CREATE_MODULE_TEXTBOX_VERSION_INVALID")}
                                        </div>
                                    </Row>
                                </Container>
                            </div>
                            <div className="modal-footer">

                                <Button
                                    size={"sm"}
                                    data-dismiss="modal"
                                    disabled={this.state.isLoading}
                                    type="button"
                                    onClick={() => handleToggle()}
                                >
                                    {t("BUTTON_CANCEL")}
                                </Button>

                                <Button loading={this.state.isLoading} appearance="primary" size={"sm"} type="button" onClick={() => this.validateCustomStylesForm()}>
                                    {t("BUTTON_CREATE")}
                                </Button>

                                {
                                    /*
                                          <CButton
                                    color="primary"
                                    size={"sm"}
                                    loading={this.state.loading}
                                    disabled={(this.state.loading || (this.state.customStyles.nameState == "invalid"))}
                                    value={data ? t("BUTTON_UPDATE") : t("BUTTON_CREATE")}
                                    onClick={() => { this.validateCustomStylesForm() }}>
                                </CButton>
                                    */
                                }
                            </div>
                            {this.state.alert}
                        </Modal>

                    </>

                )}
            </Translation>
        )
    }
}

const mapStateToProps = (state, props) => {
    const { workspaceReducer } = state;
    const { workspace, workspaces, selectedService } = workspaceReducer;

    return {
        workspace,
        workspaces,
        selectedService
    };
}


const mapDispatchToProps = (dispatch) => {
    return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateModuleModal)
