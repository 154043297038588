import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import i18n from "i18n";
import {
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Schema,
  ButtonToolbar,
} from "rsuite";
import { Alert, Loader, Input, Button, SelectPicker } from "rsuite";
import { moveInstanceService } from "_services";
import {
  updateStore,
  updateStoreCategories,
  updateGeneratedTransactions,
  updateGeneratedModules,
  updateGeneratedInterfaces,
} from "_actions";
import { Modal, Container, Row } from "reactstrap";
const { StringType, NumberType } = Schema.Types;

const model = Schema.Model({});
class RunAnotherInstanceModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formValue: {
        currentMode: "0",
      },
      formError: {},
      loading: false,
      modes: [
        {
          value: "0",
          label: i18n.t("INSTANCE_MODE_DEV"),
        },
        {
          value: "1",
          label: i18n.t("INSTANCE_MODE_QA"),
        },
        {
          value: "2",
          label: i18n.t("INSTANCE_MODE_PROD"),
        },
      ],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleSubmit(e) {
    const { formValue } = this.state;
    debugger;
    if (!this.form.check()) {
      //  console.error('Form Error');
      return;
    }
    this.handleRun();
  }

  handleRun = () => {
    const that = this;
    const { formValue } = this.state;
    const { workspace, selectedService } = this.props;
    debugger;
    moveInstanceService
      .RUN_ANOTHER_INSTANCE(workspace.id, formValue.currentMode)
      .then(async (data) => {
        if (data.operationResult) {
          Alert.success(i18n.t("Success"));
        }
        that.setState({ loading: false }, () => {
          that.handleToggleLocal();
        });
      })
      .catch((err) => {
        Alert.error("Error code : " + err.statusCode);
        that.setState({ loading: false });
      });
  };

  handleToggleLocal = () => {
    const { isActive, handleToggle } = this.props;
    const { loading } = this.state;

    if (!loading) {
      handleToggle();
    }
  };

  render() {
    const { isActive, handleToggle } = this.props;
    const { formValue, formError, loading } = this.state;
    return (
      <Translation>
        {(t) => (
          <Modal
            className="modal-dialog-centered"
            isOpen={isActive}
            //size="lg"
            toggle={this.handleToggleLocal}
            scrollable={true}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="CheckProjectModal">
                {t("TITLE_MODAL_RUN_INSTANCE")}
              </h5>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => this.handleToggleLocal()}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <Container>
                <Row>
                  <div className={" modal-form custom-fade-in "}>
                    <Form
                      ref={(ref) => (this.form = ref)}
                      onChange={(formValue) => {
                        this.setState({ formValue });
                      }}
                      onCheck={(formError) => {
                        this.setState({ formError });
                      }}
                      formValue={formValue}
                      model={model}
                      fluid
                    >
                      <FormGroup>
                        <ControlLabel>{t("TITLE_INSTANCE_MODES")}</ControlLabel>
                        <FormControl
                          accepter={SelectPicker}
                          data={this.state.modes}
                          defaultValue={"0"}
                          disabled={loading}
                          block
                          cleanable={false}
                          name="currentMode"
                        />
                      </FormGroup>
                      <FormGroup>
                        <ButtonToolbar className={"creator-toobar"}>
                          <Button
                            disabled={loading}
                            loading={loading}
                            appearance="primary"
                            onClick={this.handleSubmit}
                          >
                            {t("BUTTON_RUN")}
                          </Button>
                          <Button
                            disabled={loading}
                            appearance="default"
                            onClick={this.handleToggleLocal}
                          >
                            {t("BUTTON_CANCEL")}
                          </Button>
                        </ButtonToolbar>
                      </FormGroup>
                    </Form>
                  </div>
                </Row>
              </Container>
            </div>
          </Modal>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer, authentication } = state;
  const { loggedIn, LoginFailed, user } = authentication;

  const {
    workspace,
    workspaces,
    selectedService,
    store,
    storeCategories,
    EngineStatus,
    gitStatus,
    TerminalList,
    generatedTransactions,
    generatedModels,
    generatedInterfaces,
  } = workspaceReducer;

  return {
    workspace,
    workspaces,
    selectedService,
    store,
    storeCategories,
    user,
    EngineStatus,
    TerminalList,
    gitStatus,
    generatedTransactions,
    generatedModels,
    generatedInterfaces,
  };
};

const mapDispatchToProps = {
  updateStore,
  updateStoreCategories,
  updateGeneratedTransactions,
  updateGeneratedModules,
  updateGeneratedInterfaces,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RunAnotherInstanceModal);
