import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import Emitter from "_actions/emitter";
import i18n from "i18n";
import { ResponseStatusCode } from "_helpers";
import { erpRolesService } from "_services";
import { Button, Alert, MultiCascader, CheckTreePicker } from "rsuite";

import { Input, Modal, Container, Row, Col } from "reactstrap";

class HiddenDataTypes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      types: [],
      selectedTypes: [],
    };
  }

  onKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      this.validateCustomStylesForm();
    }
  };

  resetContent = () => {
    const { data, scopes, defauldValue } = this.props;
    const that = this;

    let _t = [...defauldValue];
    let _tS = [];

    defauldValue.forEach((e) => {
      _tS.push(e.name + "*" + e.target);
    });
    debugger
    this.setState({
      isLoading: false,
      types: [..._t],
      selectedTypes: [..._tS],
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.isActive)
      if (prevProps.isActive != this.props.isActive && this.props.isActive) {
        this.resetContent();
      }
  }

  render() {
    const { isActive, handleToggle, data, handleUpdate, collection, model } =
      this.props;
    const { isLoading, types, selectedTypes } = this.state;
    const _MyList = collection.filter((e) => e.contentType == 10);
    if (isActive) {
      debugger;
    }
    return (
      <Translation>
        {(t) => (
          <>
            <Modal
              className="modal-dialog-centered"
              isOpen={isActive}
              //size="lg"
              // scrollable={true}
            >
              <div className="modal-header">
                <h5 className="modal-title" id="CreateRoleModal">
                  {t("TITLE_MODAL_UPDATE_HIDDEN_TYPES")}
                </h5>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  disabled={this.state.isLoading}
                  onClick={() => handleToggle()}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body">
                <Container>
                  <Row style={{ marginTop: "10px", flexDirection: "column" }}>
                    <div>
                      <label
                        className="form-control-label"
                        htmlFor="validationCustomRoles"
                      >
                        {t("TEXT_UPDATE_HIDDEN_TYPES")}
                      </label>
                    </div>

                    <MultiCascader
                      onChange={(value) => {
                        let _types = [];

                        value.forEach((element) => {
                          const _d = element.split("*");
                          _types.push({
                            Name: _d[0],
                            Target: _d[1],
                          });
                        });

                        this.setState({ selectedTypes: value, types: _types });
                      }}
                      placeholder={t("TEXT_UPDATE_HIDDEN_TYPES")}
                      block
                      uncheckableItemValues={[
                        ..._MyList.map((item, key) => {
                          return item.name;
                        }),
                      ]}
                      data={[
                        ..._MyList.map((item, key) => {
                          //debugger;
                          return {
                            value: item.name,
                            label: item.name,
                            children: [
                              ...[
                                ...(item.hasOwnProperty("refModel")
                                  ? item.refModel.dataTypes
                                  : item.hasOwnProperty("RefModel")
                                  ? item.RefModel.dataTypes
                                  : []),
                              ].map((ii, kk) => ({
                                value: ii.name + "*" + item.name,
                                label: ii.name,
                                data: item,
                              })),
                            ],
                          };
                        }),
                      ]}
                      cleanable={false}
                      value={this.state.selectedTypes}
                    />
                  </Row>
                </Container>
              </div>
              <div className="modal-footer">
                <Button
                  size={"sm"}
                  data-dismiss="modal"
                  disabled={this.state.isLoading}
                  type="button"
                  onClick={() => handleToggle()}
                >
                  {t("BUTTON_CANCEL")}
                </Button>

                <Button
                  loading={this.state.isLoading}
                  appearance="primary"
                  size={"sm"}
                  type="button"
                  onClick={() => handleUpdate(types)}
                >
                  {t("BUTTON_UPDATE")}
                </Button>
              </div>
              {this.state.alert}
            </Modal>
          </>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer } = state;
  const {
    workspace,
    workspaces,
    selectedService,
    storeCategories,
    modules,
    models,
    model,
  } = workspaceReducer;

  return {
    workspace,
    workspaces,
    selectedService,
    storeCategories,
    modules,
    models,
    model,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(HiddenDataTypes);
