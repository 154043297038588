import { combineReducers } from "redux";
import undoable from 'redux-undo'
 
//import { SocketReducer } from './socket.reducer';

import { workspaceReducer } from "./workspace.reducer";
import { authentication } from "./authentication.reducer";
import { currentstatesReducer } from "./currentstates.reducer";
import {SocketReducer} from './socket.reducer';
const rootReducer = combineReducers({
    authentication,
    workspaceReducer,
    currentstatesReducer,
    SocketReducer,
});

export default rootReducer;
