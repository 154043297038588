/*
    18/11/2020 - Abdullah Kurnaz 
    abdullah.kurnaz@nearlineconsulting.com
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import { Table, ColumnGroup, Column, HeaderCell, Cell } from "rsuite-table";
import i18n from "i18n";
import { Dropdown, Schema } from "rsuite";
import SearchFilterModal from "components/Modals/SearchFilterModal";
import OperationRowDataDT from "./Modals/OperationRowDataDT";

import DeleteRowData from "components/Modals/DeleteRowData";
import EditSpecificRowData from "components/Modals/EditSpecificRowData";

import { decamelize } from "assets/globalOps/global";
import CCell from "components/Custom/CCell";
import { enqueueSnackbar } from "notistack";

import {
  Button,
  IconButton,
  Icon,
  ButtonToolbar,
  Alert,
  InputGroup,
  Input,
  CheckPicker,
  SelectPicker,
  FlexboxGrid,
} from "rsuite";
import DynamicTablePagination from "./DynamicTablePagination";
import ShowTransactionModal from "./Modals/ShowTransactionModal";
import { ErpEngineService } from "_services";
import EasyInvoiceViewer from "components/Modals/EasyInvoiceViewer";
import CustomDropDown2 from "components/Custom/CustomDropDown2";
import ConfirmModal from "components/Modals/ConfirmModal";

 
//const { ColumnGroup, Column, HeaderCell, Cell, Pagination } = Table;
const WAIT_INTERVAL = 500;
const { StringType, NumberType, DateType, ObjectType, BooleanType, ArrayType } =
  Schema.Types;
const model = Schema.Model({});
class DynamicTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      selectedRow: null,
      isEditActive: false,
      isDeleteActive: false,
      isEditSpecificRowData: false,
      isCreateActive: false,
      isInvoiceViewer: false,

      data: [],
      page: 1,
      displayLength: 10,
      searchText: "",
      searchFields: [],
      searchType: "",
      isSearchFilter: false,
      isTransModal: false,
      refTransaction: null,
      refButton: null,

      isConfirmModal: false,
      confirmTitle: "",
    };
    this.handleSortColumn = this.handleSortColumn.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeLength = this.handleChangeLength.bind(this);
  }

  handleConfirmModal = (title = "") => {
    this.setState({
      confirmTitle: title,
      isConfirmModal: !this.state.isConfirmModal,
    });
  };

  handleInvoiceViewerModal = () => {
    this.setState({
      isInvoiceViewer: !this.state.isInvoiceViewer,
    });
  };

  handleChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };
  handleIsSearchFilter = () => {
    this.setState({
      isSearchFilter: !this.state.isSearchFilter,
    });
  };

  handleSearch = () => {
    const { dataModel } = this.props;
    const { searchText, searchFields, searchType } = this.state;
    debugger;
    const _searchFields = searchFields.map((x) => {
      return dataModel.find((y) => y.name == x);
    });

    if (searchText.trim().length == 0) {
      return;
    }

    const msg = `
      Search text : ${searchText}
      Search fields : ${
        _searchFields.length > 0
          ? _searchFields.map((x) => {
              return `${x.name} (${x.contentType})`;
            })
          : "-"
      }
      Search type : ${searchType ? searchType : "-"} 
    `;
    Alert.info(msg);
    console.info(msg);
  };

  componentWillMount() {
    this.timer = null;
  }
  handleSortColumn(sortColumn, sortType) {
    const { isStandartList, updateExtraPath } = this.props;
    if (isStandartList) {
      const _s = `${sortColumn}=${sortType == "desc" ? -1 : 1}`;
      updateExtraPath("short", _s);
    }
    this.setState({
      sortColumn,
      sortType,
    });
  }

  handleChangePage(dataKey) {
    this.props.updateExtraPath("currentPage", dataKey);
    this.setState({
      page: dataKey,
    });
  }
  handleChangeLength(dataKey) {
    this.props.updateExtraPath("countPerPage", dataKey);
    this.props.updateExtraPath("currentPage", 1);

    this.setState({
      page: 1,
      displayLength: dataKey,
    });
  }

  handleEditSpecificRowData = () => {
    this.setState({
      isEditSpecificRowData: !this.state.isEditSpecificRowData,
    });
  };

  componentDidMount() {
    this.setState({
      data: this.props.data ? this.props.data : [],
      searchText: "",
      searchFields: [],
      searchType: "",
    });
  }

  componentDidUpdate = (prevProps) => {
    const { data } = this.props;
    if (JSON.stringify(data) != JSON.stringify(prevProps.data)) {
      this.setState({
        data: this.props.data ? this.props.data : [],
        searchText: "",
        searchFields: [],
        searchType: "",
      });
    }
  };

  handleDelete = (rowData) => {
    this.setState(
      {
        selectedRow: rowData,
      },
      () => {
        this.handleDeleteModalToggle();
      }
    );
  };

  handleEdit = (rowData, i) => {
    const { editData } = this.props;
    this.setState(
      {
        isEditActive: true,
        selectedIndex: i,
        selectedRow: rowData,
      },
      () => {}
    );
  };
  handleDeleteData = (rowData) => {
    let { data, selectedRow } = this.state;
    const { deleteData } = this.props;
    let cIndex = data.findIndex(
      (x) => JSON.stringify(x) === JSON.stringify(selectedRow)
    );
    deleteData(rowData, cIndex);
  };

  handleDeleteModalToggle = () => {
    this.setState({
      isDeleteActive: !this.state.isDeleteActive,
    });
  };

  getData() {
    const { data, sortColumn, sortType } = this.state;

    let result = [];
    if (!data) return result;
    try {
      result = [...data];
    } catch (error) {
      debugger;
    }

    return result;
  }

  handleCreateModalToggle = () => {
    this.setState({
      isCreateActive: !this.state.isCreateActive,
    });
  };
  getCurrentLangDisplayName = (item) => {
    try {
      const _lang = this.props.currentLang;
      if (_lang) {
        let _langDNM = item.displayNameMultiLangs.find((x) => x.lang == _lang);
        if (_langDNM && _langDNM.textValue.trim().length > 0) {
          return _langDNM.textValue;
        } else {
          _langDNM = item.displayNameMultiLangs.find((x) => x.lang == "en");
          return _langDNM.textValue;
        }
      } else {
        const _langDNM = item.displayNameMultiLangs.find((x) => x.lang == "en");
        return _langDNM.textValue;
      }
    } catch (error) {
      return item.hasOwnProperty("displayName")
        ? item.displayName
        : decamelize(item.name, " ");
    }
  };

  GenerateColumnRefModel = (data, h) => {
    const that = this;
    let counter = 0;

    const result = data.map((item) => {
      counter++;
      return (
        <Column
          width={200}
          key={item.name + "-" + counter + (h ? "-" + h : "")}
          align="center"
          resizable
        >
          <HeaderCell className={"cell-header"}>
            {this.getCurrentLangDisplayName(item)}
          </HeaderCell>
          {item.contentType == "10" ? (
            <ColumnGroup header={item.name}>
              {that.GenerateColumnRefModel(item.refModel.dataTypes, item.name)}
            </ColumnGroup>
          ) : (
            <CCell
              data={item}
              dataKey={item.name}
              allTransactions={this.props.allTransactions}
            />
          )}
        </Column>
      );
    });
    debugger;
    return result;
  };

  handleCreate = (rowData, refForm, refSchemaModel, funcModal) => {
    let { data } = this.state;
    const { addRowData } = this.props;
    debugger;
    if (!refForm.check()) {
      debugger;
      Alert.error("Form not validated");
      return;
    }
    addRowData(rowData);
    funcModal();
  };

  handleEditIndex = (sData, refForm, refSchemaModel, funcModal) => {
    const { data, editData } = this.props;
    const { dynamicDataList, formValue, selectedIndex } = this.state;
    debugger;

    if (!refForm.check()) {
      debugger;
      Alert.error("Form not validated");
      return;
    }
    debugger;
    let _cData = [...this.getData()];
    _cData[selectedIndex] = sData;

    editData(_cData);
    this.setState({
      data: _cData,
      // isEditActive: false,
      selectedRow: null,
    });
    funcModal();
  };

  handleRemoveIndex = (sData, index) => {
    const { data, deleteData } = this.props;
    const { dynamicDataList, formValue } = this.state;

    let _cData = [...this.getData()];
    _cData.splice(index, 1);

    /* const _key = data.name;
    let _obj = Object.assign({}, formValue);
    _obj[_key] = _cData; 
*/
    deleteData(sData, index);
    this.setState({ data: _cData });
  };

  handleTransModal = (refTransaction = null, formValue, btn, status) => {
    this.setState({
      refTransaction: refTransaction,
      refButton: btn,
      isTransModal: status == undefined ? !this.state.isTransModal : status,
      refFormValue: formValue,
    });
  };

  handleRunTransaction = (_selectedService, _formValue) => {
    const { formValue } = this.state;
    const {
      workspace,
      onSubmit,
      selectedService,
      model,
      isRefModelForm,
      refModel,
    } = this.props;

    const URL_MODE = "dev_URL";
    const that = this;

    const _sModels = model
      ? model.find(
          (x) =>
            x.id ===
            (isRefModelForm
              ? refModel.id
              : selectedService.responseModelType
              ? selectedService.relResponseModelId
              : selectedService.reqModelId)
        ).dataTypes
      : (isRefModelForm ? refModel : selectedService)
      ? isRefModelForm
        ? refModel.dataTypes
        : selectedService.model
      : [];

    this.setState({ loading: true });

    const _preFunc = this.preTransactionFunc(
      _formValue,
      _selectedService,
      _formValue
    );

    if (_preFunc.status) {
      const _body = JSON.stringify(_preFunc.body);
      debugger;
      that.setState({ loading: true });
      ErpEngineService.EXECUTE_TRANSACTION_DIRECTLY(
        workspace[URL_MODE],
        this.props.isListDetail
          ? this.props.itemOperation
            ? this.props.itemOperation
            : _selectedService.functionName
          : _selectedService.functionName,
        _selectedService.executableTransactionServiceName,
        _body
      )
        .then((data) => {
          if (that.postTransactionFunc) {
            that.postTransactionFunc(
              data.operationResult,
              data.dynamicValue,
              "amntar",
              _selectedService,
              _sModels
            );
          }

          that.setState({ loading: false });
        })
        .catch((err) => {
          debugger;
          that.setState({ loading: false });
          try {
            Alert.error(
              Array.isArray(err.failText)
                ? err.failText.join(". ")
                : err.failText
            );
          } catch (error) {
            Alert.error(i18n.t("MESSAGE_TRANSACTION_SUBMIT_UNSUCCESS"));
          }
          // let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
        });
    } else {
      this.setState({ loading: false });
    }
  };
  prepareGenerateSchema = () => {
    const { selectedService, models, isRefModelForm, refModel } = this.props;

    let model = {}; // Schema.Model({});
    /*
    const _sModels = models
      ? models.dynamicValue.find((x) => x.id === selectedService.reqModelId)
          .dataTypes
      : selectedService.model;
*/
    // if (isRefModelForm) debugger;
    const _sModels = model
      ? this.props.model.find(
          (x) =>
            x.id ===
            (isRefModelForm
              ? refModel.id
              : selectedService.responseModelType
              ? selectedService.relResponseModelId
              : selectedService.reqModelId)
        ).dataTypes
      : (isRefModelForm ? refModel : selectedService)
      ? isRefModelForm
        ? refModel.dataTypes
        : selectedService.model
      : [];

    _sModels.map((item, key) => {
      if (!item.privateField) {
        let _obj = {};
        switch (item.contentType) {
          case 1:
            // String
            _obj = StringType();
            break;
          case 2:
            // Rich Text
            _obj = StringType();
            break;
          case 3:
            // Number
            _obj = NumberType();
            break;
          case 4:
            // Date
            _obj = DateType();
            break;
          case 5:
            // Relations
            _obj = ObjectType(); // string ti
            break;
          case 6:
            // Enum
            _obj = StringType();
            break;
          case 7:
            // Boolean
            _obj = BooleanType();
            break;
          case 8:
            // Identity
            _obj = StringType();
            break;
          case 9:
            // Image
            _obj = ObjectType();
            break;
          case 10:
            // Relations
            _obj = ObjectType(); // ObjectType();
            break;
          case 11:
            // Relations
            _obj = ObjectType(); // ObjectType();
            break;
          case 12:
            // Relations
            _obj = ObjectType(); // ObjectType();
            break;
          case 13:
            // Relations
            _obj = ArrayType(); // ObjectType();
            break;
            case 15:
              // Relations
              _obj = ObjectType();
              break;
            case 16:
              // Relations
              _obj = ObjectType(); // ObjectType();
              break;
              case 17:
                // Relations
                _obj = ObjectType(); // ObjectType();
                break;
          default:
            break;
        }
        if (item.reqiured || item.notNull) {
          if (_obj) _obj.isRequired(i18n.t("ERROR_DM_GLOBAL_REQUIRED"));
        }
        if (item.uniq) {
        }

        switch (item.typeDetail) {
          case 1:
            // STANDART
            break;
          case 2:
            // PASSWORD
            break;
          case 3:
            // EMAIL
            _obj.isEmail(i18n.t("ERROR_DM_GLOBAL_EMAIL_FORMAT"));
            break;
          case 4:
            // INT
            //_obj.isInteger(i18n.t("ERROR_DM_GLOBAL_INT"));
            break;
          case 5:
            // BIGINT
            // _obj.isInteger(i18n.t("ERROR_DM_GLOBAL_INT"));
            break;
          case 6:
            // DECIMAL
            // _obj.isInteger(i18n.t("ERROR_DM_GLOBAL_INT"));
            break;
          case 7:
            // DOUBLE
            //_obj.isInteger(i18n.t("ERROR_DM_GLOBAL_INT"));
            break;
          case 8:
            // DOUBLE
            //_obj.isInteger(i18n.t("ERROR_DM_GLOBAL_INT"));
            break;
          case 9:
            // DOUBLE
            //_obj.isInteger(i18n.t("ERROR_DM_GLOBAL_INT"));
            break;
          case 10:
            // DOUBLE
            //_obj.isInteger(i18n.t("ERROR_DM_GLOBAL_INT"));
            break;
          case 11:
            // DOUBLE
            //_obj.isInteger(i18n.t("ERROR_DM_GLOBAL_INT"));
            break;

          default:
            break;
        }
        if (
          item.contentType !== 8 &&
          item.contentType !== 12 &&
          item.contentType !== 11 &&
          item.contentType !== 14 &&
          item.contentType !== 17
        ) {
          model[item.name] = _obj;
        }
      }
    });
    return Schema.Model(model);
  };

  transactionPrepareFunc = (_formValue) => {
    const { formValue } = this.state;
    const { selectedService, model, isRefModelForm, refModel } = this.props;
    const _sModels = model
      ? model.find(
          (x) =>
            x.id ===
            (isRefModelForm
              ? refModel.id
              : selectedService.responseModelType
              ? selectedService.relResponseModelId
              : selectedService.reqModelId)
        ).dataTypes
      : (isRefModelForm ? refModel : selectedService)
      ? isRefModelForm
        ? refModel.dataTypes
        : selectedService.model
      : [];
    return {
      formData: JSON.parse(JSON.stringify(_formValue)),
      model: _sModels,
    };
  };

  ToastMessage = (msg, type) => {
    try {
      switch (type) {
        case "success":
          Alert.success(msg);
          break;
        case "error":
          Alert.error(msg);
          break;
        case "info":
          Alert.info(msg);
          break;
        case "warning":
          Alert.warning(msg);
          break;
        default:
          Alert.info(msg);
          break;
      }
    } catch (error) {
      Alert.info(msg);
    }
  };
  SnackMessage = (msg, type) => {
    try {
      enqueueSnackbar(msg, { variant: type });
    } catch (error) {
      enqueueSnackbar(msg);
    }
  };

  preTransactionFunc = (data, _selectedService, _formValue) => {
    const {
      isRunTransModal,
      runTransModalClose,
      updateBaseForm,
      handleRunPreTransaction,
    } = this.props;

    const _dynamicSchemaModel = this.prepareGenerateSchema();

    const that = this;
    let result = {
      status: true,
      body: data,
    };

    const runTransaction = that.runTransaction;
    const transactions = this.props.allTransList;
    const Toast = this.ToastMessage;
    const SnackToast = this.SnackMessage;

    const easyinvoice = require("easyinvoice").default; //easyinvoice.download('myInvoice.pdf', data.InvoiceBinary);
    const showInvoiceModal = this.handleInvoiceViewerModal;
    const showConfirmModal = this.handleConfirmModal;

    const runPreTransaction = () => {
      if (isRunTransModal && handleRunPreTransaction) {
        handleRunPreTransaction();
      }
    };

    const updatePreForm = (formValue) => {
      if (isRunTransModal) {
        //debugger;
        updateBaseForm(formValue);
      } else {
        this.setState({ formValue });
        if (this.props.isShowTransaction) {
          this.props.updateBaseForm(formValue);
        } else if (this.props.isRefModelForm) {
          // handleUpdateRefData(formValue);
          that.handleUpdateRefData(formValue, this.form, _dynamicSchemaModel);
        } else {
        }
      }
    };
    const closeModal = () => {
      if (this.props.isShowTransaction) {
        this.props.showTransactionClose();
      } else if (isRunTransModal) {
        runTransModalClose();
      } else {
        that.setState({
          isTransModal: false,
        });
      }
    };

    const checkFormValidation = () => {
      try {
        if (!that.form.check()) {
          Alert.error("Form not validated");
          result.status = false;

          return false;
        } else {
          return true;
        }
      } catch (error) {
        return false;
      }
    };

    let { formData, model } = this.transactionPrepareFunc(_formValue);
    const updateData = that.UpdateFormValue;
    debugger;
    if (
      _selectedService.hasOwnProperty("preScript") &&
      _selectedService.preScript.trim().length > 0
    ) {
      try {
        if (_selectedService.preScript.includes("await ")) {
          result.body = eval(
            "(async function() => { " + _selectedService.preScript + "})()"
          );
        } else {
          result.body = eval(
            "(function() {" + _selectedService.preScript + "}())"
          );
        }
      } catch (error) {
        debugger;
      }
    }
    /*
    preScript(pin): "alert("pre")"
postScript(pin): "alert("post")"
    */

    try {
      // debugger;
      if (result) {
        return result;
      }
      return data;
    } catch (error) {
      return data;
    }
  };

  postTransactionFunc = (
    status,
    response,
    responseModel,
    _selectedService,
    _sModels
  ) => {
    const { isRunTransModal, runTransModalClose, updateBaseForm, baseData } =
      this.props;

    const _dynamicSchemaModel = this.prepareGenerateSchema();

    const that = this;
    const runTransaction = that.runTransaction;
    const transactions = this.props.allTransList;
    const Toast = this.ToastMessage;
    const SnackToast = this.SnackMessage;

    const runPreTransaction = () => {
      if (that.props.isRunTransModal && that.props.handleRunPreTransaction) {
        that.props.handleRunPreTransaction();
      }
    };

    const updatePreForm = (formValue) => {
      if (isRunTransModal) {
        debugger;
        updateBaseForm(formValue);
      } else {
        this.setState({ formValue });
        if (this.props.isShowTransaction) {
          this.props.updateBaseForm(formValue);
        } else if (this.props.isRefModelForm) {
          // handleUpdateRefData(formValue);
          that.handleUpdateRefData(formValue, this.form, _dynamicSchemaModel);
        } else {
        }
      }
    };
    const closeModal = () => {
      if (this.props.isShowTransaction) {
        this.props.showTransactionClose();
      } else if (isRunTransModal) {
        runTransModalClose();
      } else {
        that.setState({
          isTransModal: false,
        });
      }
    };
    const easyinvoice = require("easyinvoice").default; //easyinvoice.download('myInvoice.pdf', data.InvoiceBinary);
    const showInvoiceModal = this.handleInvoiceViewerModal;
    const showConfirmModal = this.handleConfirmModal;
    let { formData, model } = this.transactionPrepareFunc(response);
    const updateData = that.UpdateFormValue;

    debugger;

    if (
      _selectedService.hasOwnProperty("postScript") &&
      _selectedService.postScript
    ) {
      try {
        let result = null;

        if (_selectedService.postScript.includes("await ")) {
          result = eval(
            "(async function() => { " + _selectedService.postScript + "})()"
          );
        } else {
          result = eval("(function() {" + _selectedService.postScript + "}())");
        }

        if (
          (!_selectedService.postScript ||
            _selectedService.postScript.trim().length == 0) &&
          status
        ) {
          if (_selectedService.responseModelType === 0) {
            let _myValues = {};
            _sModels.map((x) => {
              if (response.hasOwnProperty(x.name)) {
                _myValues[x.name] = response[x.name];
              } else if (formData.hasOwnProperty(x.name)) {
                _myValues[x.name] = formData[x.name];
              } else {
              }
            });

            that.setState({
              formValue: { ...that.state.formValue, ..._myValues },
              formValueUpdateTime: new Date(),
            });
          }
        }
        if (result) {
          //    return result;
        }
        /*
        if (data.operationResult) {
          //that.handleReset();
          //Alert.success(i18n.t("MESSAGE_TRANSACTION_SUBMIT_SUCCESS"));
      
          if (_selectedService.responseModelType === 0) {
            let _myValues = {};
            _sModels.map((x) => {
              if (data.dynamicValue.hasOwnProperty(x.name)) {
                _myValues[x.name] = data.dynamicValue[x.name];
              } else if (formValue.hasOwnProperty(x.name)) {
                _myValues[x.name] = formValue[x.name];
              } else {
              }
            });
            
            that.setState({
              formValue: _myValues,
              formValueUpdateTime: new Date(),
            });
          }

          //data.dynamicValue
         
        }

*/
      } catch (error) {
        debugger;
      }
    } else {
      if (_selectedService.responseModelType === 0) {
        let _myValues = {};
        _sModels.map((x) => {
          if (response.hasOwnProperty(x.name)) {
            _myValues[x.name] = response[x.name];
          } else if (formData.hasOwnProperty(x.name)) {
            _myValues[x.name] = formData[x.name];
          } else {
          }
        });
        that.setState({
          formValue: _myValues,
          formValueUpdateTime: new Date(),
        });
      }
    }
  };

  getDataRefModel = () => {
    const { displayLength, page, data } = this.state;

    const _data = data.filter((v, i) => {
      const start = displayLength * (page - 1);
      const end = start + displayLength;
      return i >= start && i < end;
    });
    //debugger;
    return _data;
  };

  getCurrentLangDisplayNameList = (list) => {
    try {
      const _lang = this.props.currentLang;
      if (_lang) {
        let _langDNM = list.find((x) => x.lang == _lang);
        if (_langDNM && _langDNM.textValue.trim().length > 0) {
          return _langDNM.textValue;
        } else {
          _langDNM = list.find((x) => x.lang == "en");
          return _langDNM.textValue;
        }
      } else {
        const _langDNM = list.find((x) => x.lang == "en");
        return _langDNM.textValue;
      }
    } catch (error) {
      return list.length > 0 ? list[0].textValue : "-";
    }
  };

  render() {
    const {
      isCreatable,
      isSearchable,
      isDeletable,
      isAction,
      handleSelect,
      height,
      refModel,
      fromRelation,
      allServices,
      extraButtons,
      extraButtonsAll,
      isShowTransaction,
      selectedService,
      isRefModelForm,
      runBlobTransaction,
    } = this.props;
    const { page, data } = this.state;
    const that = this;

    let tableData = []; //this.getData();
    tableData = !this.props.isStandartList
      ? this.getDataRefModel()
      : this.getData();

    const _fService = allServices.find(
      (x) => x.id === (isRefModelForm ? refModel.id : selectedService.id)
    );

    const base = that.props.refTransaction
      ? allServices.find(
          (x) =>
            x.relBaseModelId ===
            (isRefModelForm
              ? this.props.refTransaction.reqModelId
              : selectedService.responseModelType
              ? selectedService.relResponseModelId
              : selectedService.reqModelId)
        )
      : _fService;
    const isHasPriority = base
      ? base.view[Object.keys(base.view)[0]].hasOwnProperty("priority")
      : false;

    return (
      <Translation>
        {(t) => (
          <div>
            {/*
                            <FlexboxGrid justify="end">
                                <FlexboxGrid.Item
                                    style={{
                                        paddingTop: "1rem",
                                    }}>
                                      
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                             */}
            {(isCreatable || isSearchable) && (
              <FlexboxGrid justify={"end"} style={{ display: "flex", gap: 10 }}>
                <FlexboxGrid.Item
                  style={{
                    paddingTop: "1rem",
                    paddingBottom: "10px",
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  {extraButtonsAll &&
                    extraButtonsAll
                      .filter(
                        (x) =>
                          x.runType != "TableActionModal" &&
                          x.runType != "TableActionModalOnLoad" &&
                          x.runType != "TableActionCurrent"
                      )
                      .map((btn, key) => {
                        //  debugger;
                        const _f = allServices.find(
                          (x) => x.id == btn.relTransactions.id
                        );
                        return (
                          <Button
                            key={"table-extra" + key}
                            size={"xs"}
                            onClick={(e) => {
                              e.preventDefault();
                              debugger;
                              const _sTrans = allServices.find(
                                (x) => x.id == btn.relTransactions.id
                              );
                              switch (btn.runType) {
                                case "Modal":
                                  this.handleTransModal(_f, tableData);
                                  break;
                                case "Current":
                                  if (_sTrans) {
                                    this.handleRunTransaction(
                                      _sTrans,
                                      tableData
                                    );
                                  }
                                case "CurrentBlob":
                                  if (runBlobTransaction) {
                                    runBlobTransaction(_sTrans, tableData);
                                  }
                                  break;

                                default:
                                  this.handleTransModal(_f, tableData);
                                  break;
                              }
                              // this.handleCreateModalToggle();
                            }}
                            appearance="ghost"
                            className={"project-create-btn"}
                          >
                            {this.getCurrentLangDisplayNameList(
                              btn.buttonCaptions
                            )}
                          </Button>
                        );
                      })}
                </FlexboxGrid.Item>

                {isSearchable && (
                  <FlexboxGrid.Item
                    style={{
                      paddingTop: "1rem",
                      paddingBottom: "10px",
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    {this.props.isStandartList &&
                      (this.props.searchObjectModalRef
                        ? this.props.searchObjectModalRef?.query
                        : this.props.selectedService.baseModelData?.query) && (
                        <IconButton
                          onClick={() => this.handleIsSearchFilter()}
                          size={"xs"}
                          icon={<Icon icon="filter" />}
                          appearance="ghost"
                          className={"project-create-btn"}
                        />
                      )}

                    {this.props.isStandartList &&
                      (this.props.searchObjectModalRef
                        ? this.props.searchObjectModalRef?.query
                        : this.props.selectedService.baseModelData?.query) &&
                      this.props.filter &&
                      this.props.filter.length > 0 && (
                        <Button
                          onClick={() =>
                            this.props.updateExtraPath("filter", "")
                          }
                          size={"xs"}
                          icon={<Icon icon="filter" />}
                          appearance="ghost"
                          className={"project-create-btn"}
                        >
                          {t("BUTTON_CLEAR_FILTER")}
                        </Button>
                      )}

                    {/*
                      <div style={{ display: "flex", gap: 10 }}>
                        <InputGroup
                        inside
                        style={{ maxWidth: 300, width: "100%" }}
                      >
                        <Input
                          onChange={(value) => {
                            this.handleChange("searchText", value);
                          }}
                          placeholder={t("TABLE_SEARCH_TEXT")}
                          style={{
                            boxShadow: "none",
                            border: "1px solid #ececf1",
                            borderRadius: 0,
                          }}
                        />
                        <InputGroup.Addon>
                          <Icon icon="search" style={{ color: "#dddddd" }} />
                        </InputGroup.Addon>
                      </InputGroup>
                      <CheckPicker
                        onChange={(value) => {
                          this.handleChange("searchFields", value);
                        }}
                        placeholder={t("TABLE_SEARCH_FIELDS")}
                        data={
                          this.props.dataModel
                            ? this.props.dataModel.map((x) => {
                                return {
                                  label: x.hasOwnProperty("displayName")
                                    ? x.displayName
                                    : decamelize(x.name, " "),
                                  value: x.name,
                                };
                              })
                            : []
                        }
                        style={{
                          maxWidth: 170,
                          boxShadow: "none",
                          border: "1px solid #ececf1",
                        }}
                      />
                      <SelectPicker
                        onChange={(value) => {
                          this.handleChange("searchType", value);
                        }}
                        placeholder={t("TABLE_SEARCH_TYPE")}
                        style={{
                          boxShadow: "none",
                          border: "1px solid #ececf1",
                        }}
                        data={[
                          {
                            label: "And",
                            value: "And",
                          },
                          {
                            label: "Or",
                            value: "Or",
                          },
                        ]}
                      />
                      <IconButton
                        onClick={this.handleSearch}
                        icon={<Icon icon={"search"} />}
                        appearance="primary"
                      ></IconButton>
                        </div>
                      */}
                  </FlexboxGrid.Item>
                )}

                <FlexboxGrid.Item
                  style={{
                    paddingTop: "1rem",
                    paddingBottom: "10px",
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  {isCreatable && (
                    <Button
                      size={"xs"}
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleCreateModalToggle();
                      }}
                      appearance="ghost"
                      className={"project-create-btn"}
                    >
                      {t("BUTTON_CREATE_NEW")}
                    </Button>
                  )}
                </FlexboxGrid.Item>
              </FlexboxGrid>
            )}
            <div
              style={{
                height: height ? height + "px" : "360px",
              }}
            >
              <Table
                height={height ? height : 360}
                fillHeight={true}
                className={"view-table"}
                virtualized
                wordWrap={true}
                data={tableData ? tableData : []}
                sortColumn={this.state.sortColumn}
                sortType={this.state.sortType}
                onSortColumn={this.handleSortColumn}
                loading={this.props.loading}
                rowClassName={"table-global-dm"}
                bordered
                cellBordered
                // autoHeight
                // affixHeader
                // affixHorizontalScrollbar
                onRowClick={(data, event) => {
                  //debugger
                  event.stopPropagation();
                  if (handleSelect) {
                    handleSelect(data);
                  }
                  // isAction &&
                  // this.handleEdit(data);
                }}
              >
                {this.props.dataModel &&
                  [
                    ...this.props.dataModel.filter((y) => {
                      if (this.props.mantar) {
                        let _m = this.props.mantar;
                        const _v = selectedService.view[_m.name];
                        // debugger

                        const _c = _v
                          ? _v.hasOwnProperty("columns")
                            ? _v.columns
                            : []
                          : [];
                        const _index = _c.find((x) => x.name == y.name);
                        //  debugger;
                        return _index ? (_index.visible ? y : false) : y;
                      }
                      return y;
                    }),
                  ]
                    .sort((a, b) => {
                      if (this.props.mantar) {
                        let _m = this.props.mantar;
                        const _v = selectedService.view[_m.name];
                        const _c = _v
                          ? _v.hasOwnProperty("columns")
                            ? _v.columns
                            : []
                          : [];
                        const _ia = _c.findIndex((x) => x.name == a.name);
                        const _ib = _c.findIndex((x) => x.name == b.name);

                        return _ia - _ib;
                      } else if (isHasPriority) {
                        let _base = base;
                        if (base.id == selectedService.id) {
                          _base = selectedService;
                        }
                        if (_base.view[a.name] && _base.view[b.name]) {
                          return (
                            _base.view[a.name].priority -
                            _base.view[b.name].priority
                          );
                        } else {
                          //  debugger;
                          return 0;
                        }
                      } else return 0;
                    })
                    .map((item, key) => (
                      <Column
                        width={200}
                        key={key}
                        align="center"
                        resizable
                        sortable
                      >
                        <HeaderCell className={"cell-header"}>
                          {this.getCurrentLangDisplayName(item)}
                        </HeaderCell>
                        <CCell
                          data={item}
                          dataKey={item.name}
                          rowKey={key}
                          allTransactions={this.props.allTransactions}
                        />
                      </Column>
                    ))}

                {extraButtons && (
                  <Column fixed="right" className={"ctable-last-column"}>
                    <HeaderCell>{t("TABLE_STATIC_ACTIONS")}</HeaderCell>
                    <Cell>
                      {(rowData, i) => {
                        return (
                          <>
                            {extraButtons && extraButtons.length > 1 ? (
                              <CustomDropDown2
                                extraButtons={extraButtons}
                                customClick={(btn) => {
                                  debugger;
                                  const _f = allServices.find(
                                    (x) => x.id == btn.relTransactions.id
                                  );
                                  debugger;
                                  if (btn.runType == "TableActionCurrent") {
                                    const _sTrans = allServices.find(
                                      (x) => x.id == btn.relTransactions.id
                                    );
                                    this.handleRunTransaction(_sTrans, rowData);
                                  } else {
                                    this.handleTransModal(_f, rowData, btn);
                                  }
                                }}
                              />
                            ) : (
                              <ButtonToolbar>
                                {extraButtons.map((btn, key) => (
                                  <Button
                                    key={
                                      key + "-table-extrabutton-" + i + btn._id
                                    }
                                    icon={<Icon icon="far fa-edit" />}
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      const _f = allServices.find(
                                        (x) => x.id == btn.relTransactions.id
                                      );
                                      debugger;
                                      if (btn.runType == "TableActionCurrent") {
                                        const _sTrans = allServices.find(
                                          (x) => x.id == btn.relTransactions.id
                                        );
                                        this.handleRunTransaction(
                                          _sTrans,
                                          rowData
                                        );
                                      } else {
                                        this.handleTransModal(_f, rowData, btn);
                                      }
                                    }}
                                  >
                                    {btn.buttonCaptions[0].textValue}
                                  </Button>
                                ))}
                              </ButtonToolbar>
                            )}
                          </>
                        );
                      }}
                    </Cell>
                  </Column>
                )}
                {isAction ? (
                  <Column
                    width={100}
                    fixed="right"
                    className={"ctable-last-column"}
                  >
                    <HeaderCell>{t("TABLE_STATIC_ACTIONS")}</HeaderCell>
                    <Cell>
                      {(rowData, i) => {
                        return (
                          <ButtonToolbar>
                            <IconButton
                              icon={<Icon icon="far fa-edit" />}
                              onClick={(event) => {
                                event.stopPropagation();
                                this.handleEdit(rowData, i);
                              }}
                            />
                            {isDeletable && (
                              <IconButton
                                icon={<Icon icon="fas fa-trash" />}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  this.handleDelete(rowData);
                                }}
                              />
                            )}
                          </ButtonToolbar>
                        );
                      }}
                    </Cell>
                  </Column>
                ) : (
                  <Column width={0} className={"ctable-last-column"}>
                    <HeaderCell></HeaderCell>
                    <Cell></Cell>
                  </Column>
                )}
              </Table>
            </div>

            {this.props.isStandartList &&
              this.props.FilterModel.TotalPage > 0 &&
              this.props.FilterModel.TotalDocument >
                this.props.countPerPage && ( //this.state.displayLength
                <DynamicTablePagination
                  style={{
                    padding: "10px 0px 0px",
                  }}
                  lengthMenu={[
                    {
                      value: 10,
                      label: 10,
                    },
                    {
                      value: 20,
                      label: 20,
                    },
                    {
                      value: 50,
                      label: 50,
                    },
                  ]}
                  activePage={this.props.currentPage}
                  displayLength={this.props.countPerPage}
                  total={this.props.FilterModel.TotalDocument}
                  onChangePage={this.handleChangePage}
                  onChangeLength={this.handleChangeLength}
                />
              )}

            <DeleteRowData
              data={this.state.selectedRow}
              isActive={this.state.isDeleteActive}
              handleToggle={this.handleDeleteModalToggle}
              handleDelete={this.handleDeleteData}
            />
            <EditSpecificRowData
              updatePriority={this.props.updatePriority}
              dataModel={this.props.dataModel}
              data={this.state.data}
              isActive={this.state.isEditSpecificRowData}
              handleToggle={this.handleEditSpecificRowData}
            />

            <OperationRowDataDT
              title={t("TITLE_MODAL_CREATE_ROW")}
              operationName={t("BUTTON_CREATE")}
              operationColor={"cyan"}
              collection={this.state.data}
              isDynamic={true}
              refModel={refModel}
              readOnly={false}
              isCreateRow={true}
              currentValues={this.selectedRow}
              dataModel={this.props.dataModel}
              handleCreate={(_data, refForm, refSchemaModel, funcModal) =>
                this.handleCreate(_data, refForm, refSchemaModel, funcModal)
              }
              isActive={this.state.isCreateActive}
              handleEdit={(rowData, index) => {
                debugger;
              }}
              deleteData={(rowData, index) => {
                this.handleRemoveIndex(rowData, index);
              }}
              //handleUpdateData={this.handleUpdateRefData}
              handleToggle={this.handleCreateModalToggle}
            />

            <OperationRowDataDT
              title={t("TITLE_MODAL_EDIT_ROW")}
              operationName={t("BUTTON_UPDATE")}
              operationColor={"green"}
              collection={this.state.data}
              isDynamic={true}
              refModel={refModel}
              readOnly={false}
              currentValues={this.state.selectedRow}
              dataModel={this.props.dataModel}
              handleCreate={(_data, refForm, refSchemaModel, funcModal) =>
                this.handleEditIndex(_data, refForm, refSchemaModel, funcModal)
              }
              isActive={this.state.isEditActive}
              deleteData={(rowData, index) => {
                this.handleRemoveIndex(rowData, index);
              }}
              //handleUpdateData={this.handleUpdateRefData}
              handleToggle={() => {
                this.setState({
                  isEditActive: !this.state.isEditActive,
                });
              }}
            />
            {this.props.isStandartList && (
              <SearchFilterModal
                searchObjectModalRef={this.props.searchObjectModalRef}
                isActive={this.state.isSearchFilter}
                handleToggle={this.handleIsSearchFilter}
                tableModel={
                  this.props.dataModelOrj
                    ? this.props.dataModelOrj
                    : this.props.dataModel
                }
                //tableModelOrj={this.props.dataModel}
                handleFilterAndGetData={(_filter) => {
                  this.props.handleFilterAndGetData(_filter);
                  this.handleIsSearchFilter();
                }}
              />
            )}

            {this.state.isTransModal && (
              <ShowTransactionModal
                baseData={this.state.refFormValue}
                refTransaction={this.state.refTransaction}
                isActive={this.state.isTransModal}
                handleToggle={this.handleTransModal}
                refFormValue={this.state.refFormValue}
                refButton={this.state.refButton}
                handleResetBaseForm={this.handleReset}
                updateBaseForm={(formValue) => {
                  debugger;
                }}
              />
            )}
            {this.state.isInvoiceViewer && (
              <EasyInvoiceViewer
                isActive={this.state.isInvoiceViewer}
                handleToggle={this.handleInvoiceViewerModal}
              />
            )}
            {this.state.isConfirmModal && (
              <ConfirmModal
                isActive={this.state.isConfirmModal}
                handleToggle={this.handleConfirmModal}
                title={this.state.confirmTitle}
              />
            )}
          </div>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer } = state;
  const {
    transaction,
    workspace,
    dataModels,
    selectedService,
    models,
    model,
    allServices,
    allTransactions,
    allTransList,
  } = workspaceReducer;

  return {
    workspace,
    transaction,
    dataModels,
    selectedService,
    selectedServicex: selectedService,
    allTransactions,
    models,
    model,
    allServices,
    allTransList,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DynamicTable);
