import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import i18n from "i18n";
import Emitter from "_actions/emitter";
import { Tree, Input, InputGroup, Icon, Button, Alert, Loader } from "rsuite";
import {
  updateSelectedService,
  updatedModels,
  updatedTransactions,
  updateSelectedModel,
  updateAllTransactions,
} from "_actions";
import { findNestedObj } from "assets/util";
import CreateDataModel from "components/Modals/CreateDataModel";
import { ModelsService, TransactionService } from "_services";
const WAIT_INTERVAL = 200;

class ServicesTree extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      isCreateDataModel: false,
      loading: false,
    };
  }

  componentDidMount() {
    this.getModelsCollections();
    Emitter.on("CHANGE_MODULE", (newValue) => this.getModelsCollections());
  }

  componentWillUnmount = () => {
    Emitter.off("CHANGE_MODULE");
  };

  searchServices = (eleman) => {
    return eleman.label.toLowerCase().includes(this.state.search.toLowerCase());
  };

  handleCreateDataModel = () => {
    this.setState({
      isCreateDataModel: !this.state.isCreateDataModel,
    });
  };

  handleChange = (value) => {
    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      this.triggerChange(value);
    }, WAIT_INTERVAL);
  };

  triggerChange = (value) => {
    this.setState({
      search: value,
    });
  };

  componentWillMount() {
    this.timer = null;
  }

  createNewCollection = (_data) => {
    const that = this;
    const {
      module,
      dynamicCollections,
      setDynamicCollections,
      dynamicDatabase,
    } = this.props;
    if (module) {
      this.setState({ loading: true });
      ModelsService.CREATE_MODEL(
        module.id,
        _data.collectionName,
        _data.isDatabaseObject,
        _data.dataTypes,
        [],
        [], //ExtraButtons
        _data.isDataBaseFalseObjectAllowTransaction
      )
        .then((data) => {
          that.getModelsCollections();
          Alert.success(i18n.t("SUCCESS_CREATED_COLLECTION"));
          that.setState({
            isCreateDataModel: false,
            loading: false,
          });
        })
        .catch((err) => {
          debugger;
          Alert.error("Error code : " + err.statusCode);
          this.setState({ loading: false });
        });
    }
  };

  getAllModels = async () => {
    const that = this;
    const {
      modules,
      module,
      updatedModels,
      updateSelectedModel,
      updatedTransactions,
    } = this.props;
    let _list = [];
    await modules.dynamicValue.forEach(async (e) => {
      await ModelsService.STEPBYLOAD(false, "-1", 9999, "", e.id)
        .then(async (data) => {
          await data.dynamicValue.dynamicValue.forEach((element) => {
            _list.push({
              moduleWModel: element.modelName + " - (" + e.name + ")",
              ...element,
            });
          });
        })
        .catch((err) => {
          Alert.error("Error code : " + err.statusCode);
          this.setState({ loading: false });
        });
    });
    updateSelectedModel(_list);
  };

  getModelsCollections = () => {
    const that = this;
    this.getAllModels();
    const { module, updatedModels, updateSelectedModel, updatedTransactions } =
      this.props;
    if (module) {
      this.setState({ loading: true });
      ModelsService.STEPBYLOAD_W_GROUP(false, "-1", 9999, "", module.id)
        .then((data) => {
          let _list = []; //data.dynamicValue.slice(0);
          let _trans = [];

          data.dynamicValue.forEach((element, key) => {
            let _el = {
              ...element.model,
              label: element.model.modelName,
              value: element.model.id,
              children: [],
              isService: true,
              isRefObject: !element.model.isDatabaseObject,
            };
            if (
              !element.model.isDatabaseObject &&
              !element.model.isDataBaseFalseObjectAllowTransaction
            ) {
              delete _el.children;
            }

            element.transActions.forEach((item) => {
              let _view =
                item.viewCustom == "" ? {} : JSON.parse(item.viewCustom);

              _el.dataTypes.map((itemdt, key) => {
                if (!_view.hasOwnProperty(itemdt.name)) {
                  _view[itemdt.name] = {
                    visible: true,
                    col: 12,
                    priority: key,
                  };
                }
              });
              try {
                if (
                  !_el.isRefObject ||
                  (_el.isRefObject &&
                    element.model.isDataBaseFalseObjectAllowTransaction)
                )
                  _el.children.push({
                    ...item,
                    label: item.name,
                    value: item.id,
                    refId: _el.id,
                    dataModelID: "",
                    type: 0,
                    model: [..._el.dataTypes],
                    baseModelData: element.model,
                    view: _view,
                    controller: "",
                    script: {
                      params: [],
                      code: "",
                    },
                  });
              } catch (error) {}
              try {
                _trans.push({
                  ...item,
                  label: item.name,
                  value: item.id,
                  refId: _el.id,
                  dataModelID: "",
                  type: 0,
                  baseModelData: element.model,
                  model: [..._el.dataTypes],
                  view: _view,
                  controller: "",
                  script: {
                    params: [],
                    code: "",
                  },
                });
              } catch (error) {
                debugger;
              }
            });
            _list.push(_el);
          });

          //data.dynamicValue = _list;

          updatedTransactions({
            dynamicValue: _trans,
          });

          updatedModels({
            dynamicValue: _list,
          });

          that.setState({ loading: false });
        })
        .catch((err) => {
          debugger;
          Alert.error("Error code : " + err.statusCode);
          this.setState({ loading: false });
        });
    }
  };

  render() {
    const data = [
      ...this.props.models.dynamicValue, //.filter(x => x.isDatabaseObject)
    ];
    return (
      <Translation>
        {(t) => (
          <div className={"service-tree-layout"}>
            <div
              style={{
                marginBottom: "10px",
                paddingRight: "1em",
              }}
            >
              <InputGroup inside>
                <Input
                  size="md"
                  placeholder="Search..."
                  onChange={(value, event) => {
                    this.handleChange(value);
                  }}
                />
                <InputGroup.Button>
                  <Icon icon="search" />
                </InputGroup.Button>
              </InputGroup>
            </div>

            <div className={"service-tree-nodes"}>
              <Tree
                key={
                  this.props.selectedService ? "selected-st" : "noselected-st"
                }
                className={"custom-fade-in"}
                defaultValue={
                  this.props.selectedService && this.props.selectedService.value
                }
                data={data.filter(this.searchServices)}
                defaultExpandAll
                onSelect={(activeNode, value, event) => {
                  const fData = findNestedObj(data, "value", value);
                  this.props.updateSelectedService(fData);
                  setTimeout(() => {
                    Emitter.emit("CHANGE_CONTROLLER_PAGE", fData);
                  }, 10);
                  //this.props.updateSelectedService(fData);
                }}
              />
            </div>

            <div className={"collections-add-button custom-fade-in"}>
              <Button
                size="sm"
                onClick={this.handleCreateDataModel}
                appearance="default"
              >
                <Icon icon="plus" />
                <p className={"custom-fade-in"}>{t("BUTTON_CREATE_NEW")}</p>
              </Button>
            </div>
            {this.state.isCreateDataModel && (
              <CreateDataModel
                isActive={this.state.isCreateDataModel}
                handleToggle={() => {
                  this.setState({ isCreateDataModel: false });
                }}
                handleCreateDM={this.createNewCollection}
              />
            )}

            {this.state.loading && (
              <div className={"fade-in"}>
                <Loader backdrop vertical />
              </div>
            )}
          </div>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer } = state;
  const {
    models,
    workspaces,
    workspace,
    selectedService,
    services,
    modules,
    module,
    allTransactions,
  } = workspaceReducer;

  return {
    workspaces,
    workspace,
    modules,
    module,
    selectedService,
    services,
    models,
    allTransactions,
  };
};

const mapDispatchToProps = {
  updateSelectedService,
  updatedModels,
  updateSelectedModel,
  updatedTransactions,
  updateAllTransactions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ServicesTree);
