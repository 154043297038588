import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import i18n from "i18n";
import { Modal, Container, Row, Col, Card, CardBody } from "reactstrap";

import { GlobalScriptService } from "_services";

import { Button, Alert } from "rsuite";
import {
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  InputPicker,
  Schema,
  ButtonToolbar,
} from "rsuite";

const { StringType } = Schema.Types;

const model = Schema.Model({
  scriptName: StringType()
    .minLength(3, i18n.t("ERROR_DM_GLOBAL_MIN3_LENGTH"))
    .isRequired(i18n.t("ERROR_DM_GLOBAL_REQUIRED")),
});

class GlobalScriptCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formValue: {
        scriptName: "",
        scriptType: 0,
      },
      formError: {},
      loading: false,
    };
  }

  CREATE_SCRIPT = () => {
    const that = this;
    const { onRefresh, workspace, module } = this.props;
    const { formValue } = this.state;
    const { scriptName, scriptType } = formValue;
    //RelWorkspaceId,RelModuleId,ScriptName,InjectType
    that.setState({ loading: true });
    GlobalScriptService.CREATE(
      workspace.relWorkspaceId,
      module.id,
      scriptName,
      scriptType
    )
      .then(async (data) => {
        that.setState({ loading: false });
        if (data.operationResult) {
          that.setState({
            formValue: {
              scriptName: "",
              scriptType: 0,
            },
            formError: {},
          });
          onRefresh();
        }
      })
      .catch((err) => {
        Alert.error("Error code : " + err.statusCode);
        that.setState({ loading: false });
      });
  };

  handleSubmit = (e) => {
    const { formValue } = this.state;
    if (!this.form.check()) {
      //  console.error('Form Error');
      return;
    }
    this.CREATE_SCRIPT();
  };

  handleCloseModal = () => {
    const { handleToggle } = this.props;
    this.setState(
      {
        formValue: {
          scriptName: "",
          scriptType: 0,
        },
        formError: {},
        loading: false,
      },
      () => {
        handleToggle();
      }
    );
  };

  render() {
    const { isActive, handleToggle } = this.props;
    const { formValue, formError, loading } = this.state;

    return (
      <Translation>
        {(t) => (
          <Modal
            className="modal-dialog-centered"
            isOpen={isActive}
            //size="lg"
            toggle={this.handleCloseModal}
            scrollable={true}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="CreateGlobalScriptModal">
                {t("TITLE_SCRIPT_CREATE")}
              </h5>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => this.handleCloseModal()}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body" style={{ padding: 0 }}>
              <Container>
                <Row>
                  <Col style={{ marginBottom: "15px", padding: "0px 1.25rem" }}>
                    <Form
                      ref={(ref) => (this.form = ref)}
                      onChange={(formValue) => {
                        this.setState({ formValue });
                      }}
                      onCheck={(formError) => {
                        this.setState({ formError });
                      }}
                      formValue={formValue}
                      model={model}
                      fluid
                    >
                      <FormGroup>
                        <ControlLabel>{t("TITLE_SCRIPT_NAME")}</ControlLabel>
                        <FormControl
                          disabled={loading}
                          name="scriptName"
                          placeholder={t("TITLE_SCRIPT_NAME")}
                        />
                      </FormGroup>
                      <FormGroup>
                        <ControlLabel>
                          {t("TITLE_SCRIPT_INJECT_TYPE")}
                        </ControlLabel>
                        <FormControl
                          accepter={InputPicker}
                          cleanable={false}
                          block={true}
                          data={[
                            {
                              label: t("TITLE_SCRIPT_INJECT_TYPE_0"),
                              value: 0,
                            },
                            {
                              label: t("TITLE_SCRIPT_INJECT_TYPE_1"),
                              value: 1,
                            },
                          ]}
                          disabled={loading}
                          name="scriptType"
                          placeholder={t("TITLE_SCRIPT_INJECT_TYPE")}
                        />
                      </FormGroup>
                      <FormGroup>
                        <ButtonToolbar className={"creator-toobar"}>
                          <Button
                            disabled={loading}
                            loading={loading}
                            appearance="primary"
                            onClick={this.handleSubmit}
                          >
                            {t("BUTTON_CREATE")}
                          </Button>
                          <Button
                            disabled={loading}
                            appearance="default"
                            onClick={this.handleCloseModal}
                          >
                            {t("BUTTON_CANCEL")}
                          </Button>
                        </ButtonToolbar>
                      </FormGroup>
                    </Form>
                  </Col>
                </Row>

                {/* <Row className={"member-info-action-row"}>
                                    <Button appearance='subtle' block>Block</Button>
                                        </Row>*/}
              </Container>
            </div>
          </Modal>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer, authentication } = state;
  const { loggedIn, LoginFailed, user } = authentication;

  const {
    workspace,
    workspaces,
    selectedService,
    store,
    storeCategories,
    module,
    modules,
  } = workspaceReducer;

  return {
    workspace,
    workspaces,
    selectedService,
    store,
    storeCategories,
    user,
    modules,
    module,
  };
};

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(GlobalScriptCreate);
