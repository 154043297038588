import React, { Component } from 'react';
import PropTypes from 'prop-types';


import { Table } from 'rsuite';

const { Cell } = Table;

class EditCell extends Component {

  


    render() {
        const { rowData,dataKey, onChange } = this.props;
     //   const { rowData } = this.state;
        const editing = rowData.__isEditXAPPTABLE === 'EDIT';

        return (
            <Cell {...this.props} className={editing ? 'table-content-editing' : ''}>
                {editing ? (
                    <input
                        className="rs-input"
                        defaultValue={rowData[dataKey]}
                        /*onChange={event => {
                            onChange && onChange(rowData.id, dataKey, event.target.value);
                        }}*/
                    />
                ) : (
                        <span className="table-content-edit-span">{rowData[dataKey]}</span>
                    )}
            </Cell>
        );
    }
}

EditCell.propTypes = {
    rowData: PropTypes.object,
    dataKey: PropTypes.string,
    onChange: PropTypes.func,
};

export default EditCell;