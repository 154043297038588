import {
  //ServiceURLs,
  TransactionEndpoint,
  GetBaseERPURL,
  MakeReqestAndGetResponse,
  GetUserLang,
} from "./index";
import { HTTPOperationType } from "./httpOperations";

export const TransactionService = {
  CREATE_TRANSACTION,
  STEPBYLOAD,
  UPDATE_TRANSACTION,
  GET_TRANSACTION_BYID,
  SAVE_SCRIPT_ONLY,
  SAVE_PREANDPOST_SCRIPT_ONLY,
  SAVE_VIEW_ONLY,
};
function STEPBYLOAD(
  IsOrderByDesc,
  LastIndexId,
  Count,
  SearchTerm,
  RelWorkSpaceId,
  RelModuleId
) {
  var js = {
    SearchTerm: SearchTerm,
    Count: Count,
    LastIndexId: LastIndexId,
    IsOrderByDesc: IsOrderByDesc,
    DynamicObj: {
      RelWorkSpaceId: RelWorkSpaceId,
      RelModuleId: RelModuleId,
    },
  };

  return MakeReqestAndGetResponse(
    GetBaseERPURL() + TransactionEndpoint.STEPBYLOAD,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

//ResponseModelType 0 = single 1 = multi
//_TransactionResponseOperationType
/* 
public enum TransactionResponseOperationType
	{
		NONE, 0
		CURRENT_PAGE, 1
		MODAL, 2 
		NEW_PAGE 3
		
	}

*/
function CREATE_TRANSACTION(
  Name,
  ShortName,
  FunctionName,
  Description,
  RelBaseModelId,
  ReqModelId,
  ViewCustom,
  RelModuleId,
  RelWorkSpaceId,
  RelResponseModelId,
  ResponseModelType,
  IsActive,
  _TransactionResponseOperationType,
  ReqModelType,
  AllowUnauthorizedAccess,
  PassReqAndResParamsToTransaction,
  HideFromDashboard,
  EditableList
) {
  var js = {
    Name: Name,
    ShortName: ShortName,
    FunctionName: FunctionName,
    Description: Description,
    RelBaseModelId: RelBaseModelId,
    ReqModelId: ReqModelId,
    ViewCustom: ViewCustom,
    RelModuleId: RelModuleId,
    RelResponseModelId: RelResponseModelId,
    ResponseModelType: ResponseModelType,
    RelWorkSpaceId: RelWorkSpaceId,
    IsActive,
    _TransactionResponseOperationType: _TransactionResponseOperationType,
    ReqModelType: ReqModelType,
    AllowUnauthorizedAccess: AllowUnauthorizedAccess,
    PassReqAndResParamsToTransaction: PassReqAndResParamsToTransaction,
    HideFromDashboard: HideFromDashboard,
    EditableList: EditableList,
  };
  return MakeReqestAndGetResponse(
    GetBaseERPURL() + TransactionEndpoint.CREATE_TRANSACTION,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

//ResponseModelType 0 = single 1 = multi
function UPDATE_TRANSACTION(
  Id,
  Name,
  ShortName,
  FunctionName,
  Description,
  RelBaseModelId,
  ReqModelId,
  ViewCustom,
  RelModuleId,
  RelWorkSpaceId,
  RelResponseModelId,
  ResponseModelType,
  Script,
  IsActive,
  _TransactionResponseOperationType,
  ReqModelType,
  AllowUnauthorizedAccess,
  PassReqAndResParamsToTransaction,
  HideFromDashboard,
  TransactionButton,
  EditableList
) {
  var js = {
    Id: Id,
    Name: Name,
    ShortName: ShortName,
    FunctionName: FunctionName,
    Description: Description,
    RelBaseModelId: RelBaseModelId,
    ReqModelId: ReqModelId,
    ViewCustom: ViewCustom,
    RelModuleId: RelModuleId,
    RelResponseModelId: RelResponseModelId,
    ResponseModelType: ResponseModelType,
    RelWorkSpaceId: RelWorkSpaceId,
    Script,
    IsActive,
    _TransactionResponseOperationType: _TransactionResponseOperationType,
    ReqModelType: ReqModelType,
    AllowUnauthorizedAccess: AllowUnauthorizedAccess,
    PassReqAndResParamsToTransaction: PassReqAndResParamsToTransaction,
    HideFromDashboard: HideFromDashboard,
    TransactionButton: TransactionButton,
    EditableList: EditableList,
  };
  return MakeReqestAndGetResponse(
    GetBaseERPURL() + TransactionEndpoint.UPDATE_TRANSACTION,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

function GET_TRANSACTION_BYID(Id, RelWorkSpaceId, RelModuleId) {
  var js = {
    Id: Id,
    RelWorkSpaceId: RelWorkSpaceId,
    RelModuleId: RelModuleId,
  };
  return MakeReqestAndGetResponse(
    GetBaseERPURL() + TransactionEndpoint.GET_TRANSACTION_BYID,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

function SAVE_VIEW_ONLY(Id, RelWorkSpaceId, RelModuleId, ViewCustom) {
  var js = {
    Id: Id,
    RelWorkSpaceId: RelWorkSpaceId,
    RelModuleId: RelModuleId,
    ViewCustom: ViewCustom,
  };
  return MakeReqestAndGetResponse(
    GetBaseERPURL() + TransactionEndpoint.SAVE_VIEW_ONLY,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

function SAVE_SCRIPT_ONLY(Id, RelWorkSpaceId, RelModuleId, Script) {
  var js = {
    Id: Id,
    RelWorkSpaceId: RelWorkSpaceId,
    RelModuleId: RelModuleId,
    Script: Script,
  };
  return MakeReqestAndGetResponse(
    GetBaseERPURL() + TransactionEndpoint.SAVE_SCRIPT_ONLY,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

function SAVE_PREANDPOST_SCRIPT_ONLY(
  Id,
  RelWorkSpaceId,
  RelModuleId,
  PreScript,
  PostScript
) {
  var js = {
    Id: Id,
    RelWorkSpaceId: RelWorkSpaceId,
    RelModuleId: RelModuleId,
    PreScript: PreScript,
    PostScript: PostScript,
  };
  return MakeReqestAndGetResponse(
    GetBaseERPURL() + TransactionEndpoint.SAVE_PREANDPOST_SCRIPT_ONLY,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}
