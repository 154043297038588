import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import i18n from "i18n";
import Emitter from "_actions/emitter";

import { ModelsService } from "_services";
import { Grid, Row, Col, FlexboxGrid } from "rsuite";
import { Button, Schema, Alert, Checkbox } from "rsuite";

import CTable from "components/Custom/CTable";
import CreateDataModel from "components/Modals/CreateDataModel";
import CreateDB from "components/Modals/CreateDB";
import UpdateSearchFilterModal from "components/Modals/UpdateSearchFilterModal";
import {
  updateSelectedService,
  updateSelectedTransaction,
  updatedModels,
  updatedTransactions,
  updateSelectedModel,
  updateAllTransactions,
} from "_actions";
import CloneModel from "components/Modals/CloneModel";
import CloneDataModel from "components/Modals/CloneDataModel";

const { StringType, NumberType, ArrayType } = Schema.Types;

const DMModel = Schema.Model({
  name: StringType()
    .isRequired(i18n.t("ERROR_DM_GLOBAL_REQUIRED"))
    .pattern(/^[A-Za-z][A-Za-z_]/, i18n.t("ERROR_DM_GLOBAL_PATTERN"))
    .minLength(2, i18n.t("ERROR_DM_GLOBAL_MIN2_LENGTH"))
    .maxLength(30, i18n.t("ERROR_DM_GLOBAL_MAX30_LENGTH")),

  displayName: StringType()
    .isRequired(i18n.t("ERROR_DM_GLOBAL_REQUIRED"))
    .minLength(2, i18n.t("ERROR_DM_GLOBAL_MIN2_LENGTH"))
    .maxLength(30, i18n.t("ERROR_DM_GLOBAL_MAX30_LENGTH")),
  contentType: StringType().isRequired(i18n.t("ERROR_DM_GLOBAL_REQUIRED_TYPE")),
});

const DMModel2 = Schema.Model({
  name: StringType()
    .isRequired(i18n.t("ERROR_DM_GLOBAL_REQUIRED"))
    .pattern(/^[A-Za-z][A-Za-z_]/, i18n.t("ERROR_DM_GLOBAL_PATTERN"))
    .minLength(2, i18n.t("ERROR_DM_GLOBAL_MIN2_LENGTH"))
    .maxLength(30, i18n.t("ERROR_DM_GLOBAL_MAX30_LENGTH")),
  displayName: StringType()
    .isRequired(i18n.t("ERROR_DM_GLOBAL_REQUIRED"))
    .minLength(2, i18n.t("ERROR_DM_GLOBAL_MIN2_LENGTH"))
    .maxLength(30, i18n.t("ERROR_DM_GLOBAL_MAX30_LENGTH")),
  contentType: StringType().isRequired(i18n.t("ERROR_DM_GLOBAL_REQUIRED_TYPE")),
  typeDetail: StringType().isRequired(i18n.t("ERROR_DM_GLOBAL_REQUIRED_TYPE")),
});

class ModelLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSearchFilter: false,
      hiddenDataTypes: this.props.selectedService.hasOwnProperty(
        "hiddenDataTypes"
      )
        ? this.props.selectedService.hiddenDataTypes
        : [], //hiddenDataTypes
      extraButtons: this.props.selectedService.hasOwnProperty("extraButtons")
        ? this.props.selectedService.extraButtons
        : [], //extraButtons
      isDataBaseFalseObjectAllowTransaction:
        this.props.selectedService.isDataBaseFalseObjectAllowTransaction,
      data: [
        ...this.props.selectedService[props.isRefModel ? "dataTypes" : "model"],
      ],
      counter: 0,
      isEdit: false,
      __update: 0,
      formValue: {
        collectionName: "",
        dataTypes: [],
        isDatabaseObject: true,
      },
      formError: {},
      loading: false,
      dataModel: [
        {
          name: "priority",
          editable: true,
          contentType: "string",
          privateField: true,
        },
        {
          name: "name",
          editable: true,
          contentType: "string",
          privateField: false,
        },
        {
          name: "displayName",
          editable: true,
          contentType: "string",
          privateField: false,
        },
        {
          name: "contentType",
          editable: true,
          contentType: "contentTypes",
          privateField: false,
        },
        {
          name: "typeDetail",
          editable: true,
          contentType: "typeDetails",
          privateField: false,
        },
        {
          name: "reqiured",
          editable: true,
          contentType: "boolean",
          privateField: false,
        },
        {
          name: "unique",
          editable: true,
          contentType: "boolean",
          privateField: false,
        },
        {
          name: "notNull",
          editable: true,
          contentType: "boolean",
          privateField: false,
        },
        {
          name: "privateField",
          editable: true,
          contentType: "boolean",
          privateField: false,
        },
      ],
      isCloneModel: false,
      isCustomCloneModel: false,
    };
  }
  handleIsSearchFilter = () => {
    this.setState({
      isSearchFilter: !this.state.isSearchFilter,
    });
  };

  componentDidMount() {
    const { workspace, selectedService, dataModels, allTransactions } =
      this.props;
    if (selectedService && !allTransactions.length > 0) {
      this.getAllTransAndModels();
    }
    Emitter.on("UPDATE_ALL_TRANS", (newValue) => this.updateAllTrans());
  }
  componentWillUnmount = () => {
    Emitter.off("UPDATE_ALL_TRANS");
  };

  updateAllTrans = () => {
    this.handleEditModel();
    this.getAllTransAndModels();
    this.props.updateSelectedService(null);
    Emitter.emit("CHANGE_MODULE", null);
  };

  getAllTransAndModels = () => {
    const that = this;

    const { workspace, updateAllTransactions } = this.props;
    if (workspace) {
      this.setState({ loading: true }); //IsOrderByDesc,LastIndexId,Count,SearchTerm,DynamicObj
      ModelsService.STEPBYLOAD_W_GROUP_W_WORKSPACE(
        false,
        "-1",
        9999,
        "",
        workspace.id
      )
        .then((data) => {
          let _list = []; //data.dynamicValue.slice(0);
          let _trans = [];
          let _objList = {};
          data.dynamicValue.forEach((element, key) => {
            let _el = {
              ...element.model,
              label: element.model.modelName,
              value: element.model.id,
              children: [],
              isService: true,
            };
            element.transActions.forEach((item) => {
              let _view = {};
              if (item.viewCustom) {
                // debugger;
                _view = JSON.parse(item.viewCustom);
              } else {
                _el.dataTypes.map((item) => {
                  _view[item.name] = {
                    visible: true,
                    col: 12,
                  };
                });
              }

              _el.children.push({
                ...item,
                label: item.name,
                value: item.id,
                refId: _el.id,
                dataModelID: "",
                type: 0,
                model: [..._el.dataTypes],
                view: _view,
                controller: "",
                script: {
                  params: [],
                  code: "",
                },
              });
              _trans.push({
                ...item,
                label: item.name,
                value: item.id,
                refId: _el.id,
                dataModelID: "",
                type: 0,
                model: [..._el.dataTypes],
                view: _view,
                controller: "",
                script: {
                  params: [],
                  code: "",
                },
              });
              _objList[
                item.executableTransactionServiceName + "_" + item.functionName
              ] = {
                ...item,
                label: item.name,
                value: item.id,
                refId: _el.id,
                dataModelID: "",
                type: 0,
                model: [..._el.dataTypes],
                view: _view,
                controller: "",
                script: {
                  params: [],
                  code: "",
                },
              };
            });
            _list.push(_el);
          });

          updateAllTransactions(data.dynamicValue, _trans, _objList);
          this.setState({ loading: false });
        })
        .catch((err) => {
          debugger;
          Alert.error("Error code : " + err.statusCode);
          this.setState({ loading: false });
        });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { selectedService, isRefModel } = this.props;
    if (
      selectedService &&
      selectedService.id !== prevProps.selectedService.id
    ) {
      this.setState({
        data: [
          ...this.props.selectedService[isRefModel ? "dataTypes" : "model"],
        ],
      });
    }
  }
  handleCloneModel = () => {
    this.setState({ isCloneModel: !this.state.isCloneModel });
  };

  handleCustomCloneModel = () => {
    this.setState({ isCustomCloneModel: !this.state.isCustomCloneModel });
  };

  handleEditModel = () => {
    this.setState({ isEdit: !this.state.isEdit });
  };
  handleAddNewRow = (_newData) => {
    let _data = this.state.data;
    _data.push(_newData);
    this.setState({ data: _data, counter: this.state.counter + 1 });
  };
  handleUpdateRow = (rowData, _index) => {
    let _data = [...this.state.data];
    _data[_index] = rowData;
    this.setState({ data: _data, counter: this.state.counter + 1 });
  };

  handleSaveModel = () => {
    const {
      data,
      isDataBaseFalseObjectAllowTransaction,
      hiddenDataTypes,
      extraButtons,
    } = this.state;
    const { selectedService, isRefModel } = this.props;
    const that = this;
    // debugger;
    ModelsService.UPDATE_MODEL(
      selectedService.relModuleId,
      selectedService[isRefModel ? "id" : "relBaseModelId"],
      data,
      !isRefModel,
      hiddenDataTypes,
      extraButtons,
      isDataBaseFalseObjectAllowTransaction
    )
      .then((data) => {
        if (data.operationResult) {
          that.setState({ isEdit: false });
          Alert.success(i18n.t("SUCCESS_SAVED"));
          Emitter.emit("UPDATE_ALL_TRANS", null);
        }
      })
      .catch((err) => {
        Alert.error("Error code : " + err.statusCode);
        this.setState({ loading: false });
      });
  };

  handleUpdateHiddenField = (data) => {
    let _hTypes = [...data];

    this.setState({
      hiddenDataTypes: _hTypes,
    });
  };

  handleUpdateExtraButtons = (data) => {
    let _extButtons = [...data];

    this.setState({
      extraButtons: _extButtons,
    });
  };

  render() {
    const { isEdit, counter, data, selectedService, models } = this.state;
    const _mS =
      models &&
      models.dynamicValue.find((x) => x.id == selectedService.relBaseModelId);
    return (
      <Translation>
        {(t) => (
          <div className={"custom-fade-in"}>
            <Grid fluid>
              <Row className="show-grid">
                <Col>
                  {this.props.selectedService && (
                    <>
                      <FlexboxGrid justify="end">
                        <FlexboxGrid.Item>
                          <Button
                            style={{ marginRight: "5px" }}
                            size={"xs"}
                            onClick={(e) => {
                              e.preventDefault();

                              this.handleCustomCloneModel();
                            }}
                            appearance={"ghost"}
                            className={"project-create-btn"}
                          >
                            {t("BUTTON_CUSTOM_CLONE")}
                          </Button>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item>
                          <Button
                            style={{ marginRight: "5px" }}
                            size={"xs"}
                            onClick={(e) => {
                              e.preventDefault();

                              this.handleCloneModel();
                            }}
                            appearance={"ghost"}
                            className={"project-create-btn"}
                          >
                            {t("BUTTON_CLONE")}
                          </Button>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item>
                          <Button
                            size={"xs"}
                            onClick={(e) => {
                              e.preventDefault();

                              this.handleEditModel();
                            }}
                            color={isEdit ? "red" : undefined}
                            appearance={isEdit ? "primary" : "ghost"}
                            className={"project-create-btn"}
                          >
                            {isEdit ? t("BUTTON_CANCEL") : t("BUTTON_EDIT")}
                          </Button>
                        </FlexboxGrid.Item>
                        {isEdit && (
                          <FlexboxGrid.Item>
                            <Button
                              size={"xs"}
                              onClick={(e) => {
                                e.preventDefault();

                                this.handleSaveModel();
                              }}
                              color={"green"}
                              style={{ marginLeft: "5px" }}
                              appearance={"primary"}
                              className={"project-create-btn"}
                            >
                              {t("BUTTON_SAVE")}
                            </Button>
                          </FlexboxGrid.Item>
                        )}
                      </FlexboxGrid>
                      <div
                        key={
                          this.props.selectedService.id +
                          "-" +
                          this.state.dataModel.length +
                          "-" +
                          counter
                        }
                      >
                        <CTable
                          addRowData={this.handleAddNewRow}
                          updateData={this.handleUpdateRow}
                          isSub={true}
                          updateHiddenFieldData={this.handleUpdateHiddenField}
                          hiddenDataTypes={this.state.hiddenDataTypes}
                          isRefModel={
                            this.props.isRefModel ||
                            (_mS && !_mS.isDatabaseObject)
                          }
                          subComponent={
                            <div key={this.props.selectedService.id + "chk"}>
                              <Checkbox
                                onChange={(value) => {
                                  //    debugger;
                                  this.setState({
                                    isDataBaseFalseObjectAllowTransaction:
                                      !this.state
                                        .isDataBaseFalseObjectAllowTransaction,
                                  });
                                }}
                                defaultChecked={
                                  this.state
                                    .isDataBaseFalseObjectAllowTransaction
                                }
                                className="cbox-allow-trans"
                              >
                                {t("TITLE_ASK_IS_ALLOW_TRASACTION")}
                              </Checkbox>
                            </div>
                          }
                          //updatePriority={this.updateDMPriority}
                          isEditPriority={false}
                          isDeletable={false}
                          isCreatable={isEdit}
                          handleSearchFilter={this.handleIsSearchFilter}
                          isAction={isEdit}
                          isEditable={isEdit}
                          schemaModel={DMModel}
                          schemaModel2={DMModel2}
                          data={this.state.data}
                          dataModel={this.state.dataModel}
                          isFromMVC={true}
                        />
                      </div>
                      {
                        <UpdateSearchFilterModal
                          isActive={this.state.isSearchFilter}
                          handleToggle={this.handleIsSearchFilter}
                          tableModel={this.state.data}
                        />
                      }
                      {this.state.isCloneModel && (
                        <CloneModel
                          isActive={this.state.isCloneModel}
                          handleToggle={this.handleCloneModel}
                          data={this.state.data}
                          isDataBaseFalseObjectAllowTransaction={
                            this.state.isDataBaseFalseObjectAllowTransaction
                          }
                          hiddenDataTypes={this.state.hiddenDataTypes}
                          extraButtons={this.state.extraButtons}
                        />
                      )}
                      {this.state.isCustomCloneModel && (
                        <CloneDataModel
                          isActive={this.state.isCustomCloneModel}
                          handleToggle={this.handleCustomCloneModel}
                        />
                      )}
                    </>
                  )}
                </Col>
              </Row>
            </Grid>
          </div>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer } = state;
  const {
    workspace,
    selectedService,
    dataModels,
    modules,
    module,
    models,
    allTransactions,
    allServices,
  } = workspaceReducer;

  return {
    workspace,
    selectedService,
    dataModels,
    modules,
    module,
    models,
    allTransactions,
    allServices,
  };
};

const mapDispatchToProps = {
  updateSelectedService,
  updateSelectedTransaction,
  updatedModels,
  updateSelectedModel,
  updatedTransactions,
  updateAllTransactions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModelLayout);
