import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import Emitter from "_actions/emitter";
import i18n from "i18n";
import { ResponseStatusCode } from "_helpers";
import { updateStore, updateStoreCategories } from "_actions";
import { GlobalScriptService } from "_services";
import { FormGroup, ButtonToolbar, Icon, Alert, SelectPicker } from "rsuite";
import { Loader, Container, Placeholder, Button, FlexboxGrid } from "rsuite";
import CodeEditor from "components/Custom/CodeEditor";

const { Paragraph } = Placeholder;
let LANG = i18n.t("LANG");
const WAIT_INTERVAL = 500;
const ENTER_KEY = 13;

class ScriptEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      script: props.file.scriptInside,
      injectType: props.file.injectType,
    };
  }
  handleSubmit = () => {this.updateScript()};

  updateScript = () => {
    const { workspace, updateGlobalScripts, file } = this.props;
    const { onSubmit } = this.props;
    const { script, injectType } = this.state;
    const that = this;
    this.setState({ loading: true });
    GlobalScriptService.UPDATE(
      file.id,
      workspace.relWorkspaceId,
      file.relModuleId,
      script,
      injectType
    )
      .then((data) => {
        if (data.operationResult) {
          Alert.success(i18n.t("SUCCESS_SAVED"));
          onSubmit();
        }
        that.setState({ loading: false });
      })
      .catch((err) => {
        that.setState({ loading: false });
        let data = {
          title: i18n.t("warning"),
          desc: ResponseStatusCode(err.statusCode),
        };
        Alert.error(data.desc);
        debugger;
      });
  };

  render() {
    const { isLoading, file } = this.props;
    return (
      <Translation>
        {(t) => (
          <>
            <div style={{ paddingBottom: "1em", flex: 1 }}>
              <div style={{ height: "100%", display: "flex" }}>
                <CodeEditor
                  //funcName={active + "Service"}
                  style={{ height: "100%" }}
                  isGlobalScript={true}
                  funcParams={["param"]}
                  value={file.scriptInside}
                  onChangeScript={(script) => {
                    // console.log(script)
                    this.setState({ script: script });
                  }}
                />
              </div>
            </div>

            <FormGroup>
              <ButtonToolbar className={"creator-toobar"}>
                <Button
                  disabled={isLoading}
                  loading={isLoading}
                  appearance="primary"
                  onClick={this.handleSubmit}
                >
                  {t("BUTTON_SAVE")}
                </Button>
                <Button
                  disabled={isLoading}
                  appearance="default"
                  onClick={this.props.onCancel}
                >
                  {t("BUTTON_CANCEL")}
                </Button>
              </ButtonToolbar>
            </FormGroup>
          </>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer, authentication } = state;
  const { loggedIn, LoginFailed, user } = authentication;

  const { workspace, workspaces, selectedService, store, storeCategories } =
    workspaceReducer;

  return {
    workspace,
    workspaces,
    selectedService,
    store,
    storeCategories,
    user,
  };
};

const mapDispatchToProps = {
  updateStore,
  updateStoreCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScriptEditor);
