import { workspaceConstants } from "../_constants";

const initialState = {
  workspaces: {
    searchTerm: "",
    count: 0,
    isOrderByDesc: false,
    dynamicValue: [],
  },
  workspace: null,
  module: null,
  modules: {
    searchTerm: "",
    count: 0,
    isOrderByDesc: false,
    dynamicValue: [],
  },
  models: {
    searchTerm: "",
    count: 0,
    isOrderByDesc: false,
    dynamicValue: [],
  },
  model: [],
  transactions: {
    searchTerm: "",
    count: 0,
    isOrderByDesc: false,
    dynamicValue: [],
  },
  transaction: null,
  selectedService: null,
  store: {
    searchTerm: "",
    count: 0,
    isOrderByDesc: false,
    dynamicValue: [],
  },
  storeCategories: [],
  dataModels: {},
  services: [],
  generatedTransactions: [],
  generatedModels: [],
  generatedInterfaces: [],
  allModelScripts: [],
  allTransScripts: [],
  globalScripts: [],
  serviceRelations: [],
  serviceRelationsModels: [],
  environments: null,
  EngineStatus: false,
  TerminalList: [],
  allTransactions: [],
  allServices: [],
  allTransList: {},
  gitStatus: false,
  workspaceAllTextAndValues: [],
  workspaceDuplicatedTextAndValues: [],
  workspaceLangs: [],
  supportedLangs: [],
  currentLang: localStorage.getItem("lang")
    ? localStorage.getItem("lang")
    : "en",
};

export function workspaceReducer(state = initialState, { type, payload }) {
  switch (type) {
    case workspaceConstants.UPDATE_WORKSPACE:
      return {
        ...state,
        selectedService: payload.data ? state.selectedService : null,
        workspace: payload.data
          ? {
              ...payload.data,
            }
          : null,
      };
    case workspaceConstants.UPDATE_WORKSPACELIST:
      return {
        ...state,
        workspaces: payload.data
          ? {
              ...payload.data,
            }
          : null,
      };
    case workspaceConstants.UPDATE_MODULES:
      return {
        ...state,
        modules: payload.data
          ? {
              ...payload.data,
            }
          : null,
      };
    case workspaceConstants.UPDATE_SELECTEDMODULE:
      return {
        ...state,
        selectedService: null,
        module: payload.data,
      };

    case workspaceConstants.UPDATE_MODELS:
      return {
        ...state,
        models: payload.data,
      };
    case workspaceConstants.UPDATE_SELECTEDMODEL:
      return {
        ...state,
        model: payload.data,
      };
    case workspaceConstants.UPDATE_TRANSACTIONS:
      return {
        ...state,
        transactions: payload.data,
      };
    case workspaceConstants.UPDATE_ALL_TRANSACTIONS:
      return {
        ...state,
        allTransactions: payload.data,
        allServices: payload.data2,
        allTransList: payload.data3,
      };
    case workspaceConstants.UPDATE_SELECTEDTRANSACTION:
      return {
        ...state,
        transaction: payload.data,
      };
    case workspaceConstants.CLEAR_WORKSPACE:
      return {
        ...state,
        workspace: null,
        module: null,
        modules: {
          searchTerm: "",
          count: 0,
          isOrderByDesc: false,
          dynamicValue: [],
        },
        models: {
          searchTerm: "",
          count: 0,
          isOrderByDesc: false,
          dynamicValue: [],
        },
        model: [],
        transactions: {
          searchTerm: "",
          count: 0,
          isOrderByDesc: false,
          dynamicValue: [],
        },
        transaction: null,
        selectedService: null,
        store: {
          searchTerm: "",
          count: 0,
          isOrderByDesc: false,
          dynamicValue: [],
        },
        storeCategories: [],
        dataModels: {},
        services: [],
        generatedTransactions: [],
        generatedModels: [],
        generatedInterfaces: [],
        allModelScripts: [],
        allTransScripts: [],
        globalScripts: [],
        serviceRelations: [],
        serviceRelationsModels: [],
        environments: null,
        EngineStatus: false,
        TerminalList: [],
        allTransactions: [],
        allServices: [],
        allTransList: {},
        gitStatus: false,
        workspaceAllTextAndValues: [],
        supportedLangs: [],
        workspaceDuplicatedTextAndValues: [],
        workspaceLangs: [],
        currentLang: localStorage.getItem("lang")
          ? localStorage.getItem("lang")
          : "en",
      };
    case workspaceConstants.UPDATE_SELECTEDSERVICE:
      return {
        ...state,
        selectedService: payload.data ? payload.data : null,
        transaction: null,
      };
    case workspaceConstants.UPDATE_SERVICES:
      return {
        ...state,
        services: payload.data ? payload.data : [],
      };

    case workspaceConstants.UPDATE_STORE_LIST:
      return {
        ...state,
        store: payload.data,
      };
    case workspaceConstants.UPDATE_CATEGORY_STORE_LIST:
      return {
        ...state,
        storeCategories: payload.data,
      };

    case workspaceConstants.UPDATE_GENERATED_TRANSACTIONS:
      return {
        ...state,
        generatedTransactions: payload.data,
      };
    case workspaceConstants.UPDATE_GENERATED_MODELS:
      return {
        ...state,
        generatedModels: payload.data,
      };
    case workspaceConstants.UPDATE_GENERATED_INTERFACES:
      return {
        ...state,
        generatedInterfaces: payload.data,
      };
    case workspaceConstants.UPDATE_GLOBAL_SCRIPTS:
      return {
        ...state,
        globalScripts: payload.data,
      };
    case workspaceConstants.UPDATE_ALL_MODEL_SCRIPTS:
      return {
        ...state,
        allModelScripts: payload.data,
      };
    case workspaceConstants.UPDATE_ALL_TRANS_SCRIPTS:
      return {
        ...state,
        allTransScripts: payload.data,
      };
    case workspaceConstants.UPDATE_SERVICE_RELATIONS:
      return {
        ...state,
        serviceRelations: payload.data,
      };
    case workspaceConstants.UPDATE_SERVICE_RELATIONS_ALL_MODEL:
      return {
        ...state,
        serviceRelationsModels: payload.data,
      };
    case workspaceConstants.UPDATE_ENVIRONMENTS:
      return {
        ...state,
        environments: payload.data,
      };
    case workspaceConstants.UPDATE_ENGINE_STATUS:
      return {
        ...state,
        EngineStatus: payload.data,
      };
    case workspaceConstants.UPDATE_TERMINAL_LIST:
      return {
        ...state,
        TerminalList: payload.data,
      };

    case workspaceConstants.UPDATE_GIT_STATUS:
      return {
        ...state,
        gitStatus: payload.data,
      };
    case workspaceConstants.UPDATE_WORKSPACE_LANGS:
      return {
        ...state,
        workspaceLangs: payload.data,
      };
    case workspaceConstants.UPDATE_SUPPORTED_LANGS:
      return {
        ...state,
        supportedLangs: payload.data,
      };
    case workspaceConstants.UPDATE_WORKSPACE_ALL_TEXT_AND_VALUES:
      return {
        ...state,
        workspaceAllTextAndValues: payload.data,
      };
    case workspaceConstants.UPDATE_WORKSPACE_DUPLICATED_TEXT_AND_VALUES:
      return {
        ...state,
        workspaceDuplicatedTextAndValues: payload.data,
      };
    case workspaceConstants.UPDATE_WORKSPACE_CURRENT_LANG:
      localStorage.setItem("lang", payload.data);
      return {
        ...state,
        currentLang: payload.data,
      };

    default:
      return state;
  }
}
/*

EngineStatus:false,
  TerminalList:[]

export undoable(componentReducer, {
    limit: 10 // set a limit for the size of the history
})*/
