import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import i18n from "i18n";
import Emitter from "_actions/emitter";
import { WorkspaceService } from "_services";

import {
  Button,
  Loader,
  Whisper,
  Tooltip,
  Container,
  Header,
  Footer,
  Content,
  Sidebar,
  Icon,
  IconButton,
  Message,
  Navbar,
  Nav,
  Dropdown,
  ButtonToolbar,
  ButtonGroup,
  SelectPicker,
  Alert,
} from "rsuite";
import { ModulesService, StoreService } from "_services";

import ServiceLayout from "./ServiceLayout";
import MVCLayout from "./MVCLayout";
import RefModelLayout from "./RefModelLayout";
import ServiceViewLayout from "./ServiceViewLayout";

import CreateModuleModal from "components/Modals/CreateModuleModal";
import ShowStoreList from "components/Modals/ShowStoreList";
import EditNPMPackages from "components/Modals/EditNPMPackages";
import EngineModal from "components/Modals/EngineModal";
import TerminalModal from "components/Modals/TerminalModal";
import UsersAndRolesModal from "components/Modals/UsersAndRolesModal";
import GlobalScriptModal from "components/Modals/GlobalScriptModal";
import WorkspaceEndpoints from "components/Modals/WorkspaceEndpoints";
import ManageEnvironmentsModal from "components/Modals/ManageEnvironmentsModal";

import ServicesTree from "components/Sidebar/ServicesTree";
import TransactionsTree from "components/Sidebar/TransactionsTree";
import { PrapareSocket, SocketOperationLocal } from "_socketOperations";
import {
  updateWorkspace,
  updateModules,
  clearWorkspace,
  updateSelectedModule,
  updateStore,
  setRefSocketRef,
  setEngineStatus,
  setTerminalList,
  setGitStatus,
} from "_actions";
import { NavLink } from "react-router-dom";
import LangSettings from "components/Modals/LangSettings";
import WorkspaceSettingsModal from "components/Modals/WorkspaceSettingsModal";

class Workspace extends Component {
  constructor(props) {
    super(props);
    const _online = window.navigator.onLine;

    this.state = {
      online: _online,
      expand: true,
      activeTab: "service",
      isHasntWorkspace: false,
      isCreateModule: false,
      isEditNPM: false,
      isModuleCanChange: false,
      isLoadingModule: false,
      isShowStore: false,
      isEngine: false,
      isUsersRoles: false,
      isGlobalScript: false,
      isEndpoints: false,
      isEnv: false,
      isTerminal: false,
      isLangSettings: false,
      isSettings: false,
    };
    this.handleToggle = this.handleToggle.bind(this);
  }

  handleShowLangSettings = () => {
    this.setState({
      isLangSettings: !this.state.isLangSettings,
    });
  };

  handleSelecetWorkspace = (item) => {
    const that = this;
    that.setState({ isLoading: true });

    WorkspaceService.GET_CONFIGS(item.id)
      .then((data) => {
        //debugger;
        if (data.operationResult) {
          this.props.updateWorkspace({
            ...data.dynamicValue,
            id: item.id,
            name: item.name,
          });
          PrapareSocket(setRefSocketRef, {
            ...data.dynamicValue,
            id: item.id,
            name: item.name,
          });
        }
        that.setState({ isLoading: false });
      })
      .catch((err) => {
        that.setState({ isLoading: false });
        // let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
        debugger;
      });
  };

  handleConnected = (data) => {
    const { setEngineStatus } = this.props;
    setEngineStatus(data);
    //  alert("connected");
  };
  handleDisconnected = (data) => {
    const { setEngineStatus } = this.props;
    setEngineStatus(false);
    // alert("disconnect");
  };
  /*
  handleConsoleResponse = (data) => {
    alert("handleConsoleResponse");
  };
  handleTerminalResponse = (data) => {
    alert("TerminalResponse");
  };
*/
  handleTerminalListHasBeenUpdated = (data) => {
    const { setTerminalList } = this.props;
    setTerminalList(data);
  };

  componentWillUnmount = () => {
    const { setRefSocketRef, socket } = this.props;

    Emitter.off(SocketOperationLocal.Connected);
    Emitter.off(SocketOperationLocal.DisConnected);
    Emitter.off(SocketOperationLocal.TerminalGuids);
    Emitter.off(SocketOperationLocal.GitServerAliveStatus);

    try {
      socket.disconnect();
    } catch (error) {}
    try {
      setRefSocketRef(null);
    } catch (error) {}
  };
  handleGitStatusUpdate = (data) => {
    this.props.setGitStatus(data == "true" ? true : false);
  };

  handleCheckWorkspaceStatus = (item) => {
    const { updateWorkspace, workspace, workspaces, setRefSocketRef } =
      this.props;
    const that = this;

    debugger;
    WorkspaceService.GET_MIGRATION_STATUS(item?.id)
      .then((data) => {
        if (data.operationResult) {
          if (data.dynamicValue == "0") {
            that.handleRunDidMount();
          } else {
            Alert.warning(i18n.t("STATUS_WORKSPACE_NOT_READY_TO_USE"));
            that.props.history.push("/workspace");
          }
        }
        that.setState({ isLoading: false });
      })
      .catch((err) => {
        that.setState({ isLoading: false });
        Alert.warning(i18n.t("STATUS_WORKSPACE_NOT_READY_TO_USE"));
        that.props.history.push("/workspace");
        // let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
        debugger;
      });
  };

  componentDidMount = () => {
    const { updateWorkspace, workspace, workspaces, setRefSocketRef } =
      this.props;

    Emitter.on(SocketOperationLocal.Connected, (newValue) =>
      this.handleConnected(newValue)
    );
    Emitter.on(SocketOperationLocal.DisConnected, (newValue) =>
      this.handleDisconnected(newValue)
    );
    Emitter.on(SocketOperationLocal.TerminalGuids, (newValue) =>
      this.handleTerminalListHasBeenUpdated(newValue)
    );

    Emitter.on(SocketOperationLocal.GitServerAliveStatus, (newValue) =>
      this.handleGitStatusUpdate(newValue)
    );

    if (!workspace) {
      const paths = window.location.pathname.slice(1).split("/");

      if (paths.length > 1) {
        let _cData = workspaces.dynamicValue.find((x) => x.id === paths[1]);
        if (_cData) {
          const _data = {
            id: _cData.id,
            name: _cData.name,
          };
          this.handleCheckWorkspaceStatus(_cData);
        }
      }
    } else {
      this.handleCheckWorkspaceStatus(workspace);
    }
  };

  handleRunDidMount = () => {
    const { updateWorkspace, workspace, workspaces, setRefSocketRef } =
      this.props;
    const that = this;
    if (!workspace) {
      const paths = window.location.pathname.slice(1).split("/");

      if (paths.length > 1) {
        let _cData = workspaces.dynamicValue.find((x) => x.id === paths[1]);
        if (_cData) {
          const _data = {
            id: _cData.id,
            name: _cData.name,
          };

          that.handleSelecetWorkspace(_data);
          //updateWorkspace(_data);
          that.getModules("", _cData.id);
        }
      }
    } else {
      // Comming from workspaces
      // debugger;
      that.getModules("", workspace.id);
      PrapareSocket(setRefSocketRef, workspace);
    }

    window.addEventListener("offline", function (e) {
      that.setState({ online: false });
    });

    window.addEventListener("online", function (e) {
      that.setState({ online: true });
      Alert.success(i18n.t("MESSAGE_NETWORK_REONLINE"));
    });
  };

  getModules = (_search = "", id = null, isCreate = false) => {
    const { updateModules, workspace, module, updateSelectedModule } =
      this.props;
    const that = this;
    that.setState({ isLoading: true });
    that.setState({ isLoadingModule: true });

    ModulesService.STEPBYLOAD(false, "-1", 9999, _search, id)
      .then((data) => {
        if (data.operationResult) {
          updateModules(data.dynamicValue);
          that.setState({ isModuleCanChange: true });
          if (isCreate) {
            updateSelectedModule(
              data.dynamicValue.dynamicValue[
                data.dynamicValue.dynamicValue.length - 1
              ]
            );
          } else {
            if (module) {
              let _fData = data.dynamicValue.dynamicValue.find(
                (x) => x.id === module.id
              );
              updateSelectedModule(_fData);
            } else updateSelectedModule(data.dynamicValue.dynamicValue[0]);
          }
          setTimeout(() => {
            Emitter.emit("CHANGE_MODULE", "null");
          }, 300);
        }
        that.setState({ isLoading: false });
        that.setState({ isLoadingModule: false });
      })
      .catch((err) => {
        that.setState({ isLoading: false });
        debugger;
      });
  };

  handleSelect = (activeKey) => {
    this.setState({ activeTab: activeKey });
  };
  handleToggle() {
    this.setState({
      expand: !this.state.expand,
    });
  }

  handleCreateModule = () => {
    this.setState({ isCreateModule: !this.state.isCreateModule });
  };

  handleShowStore = () => {
    this.setState({ isShowStore: !this.state.isShowStore });
  };
  handleShowUsersRoles = () => {
    this.setState({ isUsersRoles: !this.state.isUsersRoles });
  };

  handleShowGlobalScript = () => {
    this.setState({ isGlobalScript: !this.state.isGlobalScript });
  };

  handleEditNPM = () => {
    this.setState({ isEditNPM: !this.state.isEditNPM });
  };

  handleEngine = () => {
    this.setState({ isEngine: !this.state.isEngine });
  };
  handleTerminalModal = () => {
    this.setState({ isTerminal: !this.state.isTerminal });
  };
  handleShowEndpoints = () => {
    this.setState({ isEndpoints: !this.state.isEndpoints });
  };
  handleShowEnvironments = () => {
    this.setState({ isEnv: !this.state.isEnv });
  };
  handleShowSettings = () => {
    this.setState({ isSettings: !this.state.isSettings });
  };

  render() {
    const { expand, activeTab, isHasntWorkspace, isLoadingModule } = this.state;
    const {
      workspace,
      modules,
      updateSelectedModule,
      module,
      transaction,
      EngineStatus,
      TerminalList,
    } = this.props;
    return (
      <Translation>
        {(t) => (
          <div style={{ width: "100%" }} className="sidebar-page">
            {this.props.workspace && (
              <Container
                key={"selected-workspace"}
                style={{ height: "100%" }}
                className={"custom-fade-in"}
              >
                <Header className={"nav-service-header"}>
                  <Navbar appearance="subtle">
                    <Navbar.Header className={"nav-service-navbar-header"}>
                      <NavLink to={"/workspaces"}>
                        <IconButton
                          style={{ marginRight: "15px" }}
                          appearance="default"
                          onClick={() => {
                            //   this.props.socket.disconnect();
                            //  this.props.setRefSocketRef(null);
                            this.props.clearWorkspace();
                            this.props.updateWorkspace(null);
                          }}
                          icon={<Icon icon={"angle-left"} />}
                          circle
                          size="sm"
                        />
                      </NavLink>

                      {this.props.workspace.name}

                      {expand && modules.dynamicValue.length > 0 ? (
                        <>
                          {" /"}
                          <SelectPicker
                            //disabled={!this.state.isModuleCanChange}
                            appearance="subtle"
                            cleanable={false}
                            value={module && module.id}
                            placeholder={t("SERVICES_MENU_ITEM_SELECTMODULE")}
                            onSelect={(value) => {
                              let _c = modules.dynamicValue.find(
                                (x) => x.id == value
                              );
                              updateSelectedModule(_c);
                              setTimeout(() => {
                                Emitter.emit("CHANGE_MODULE", "null");
                              }, 300);
                            }}
                            data={modules.dynamicValue.map((item) => ({
                              label: item.name,
                              value: item.id,
                            }))}
                            style={{
                              boxShadow: "none",
                            }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </Navbar.Header>
                    <Navbar.Body>
                      <Nav></Nav>
                      <Nav pullRight>
                        <ButtonToolbar className={"header-nav-buttons"}>
                          <Whisper
                            placement="bottom"
                            trigger="hover"
                            speaker={
                              <Tooltip>
                                {t("SERVICES_MENU_ITEM_CREATEMODULE")}
                              </Tooltip>
                            }
                          >
                            <IconButton
                              className={"custom-fade-in"}
                              icon={<Icon icon="plus" />}
                              appearance="default"
                              onClick={this.handleCreateModule}
                            ></IconButton>
                          </Whisper>

                          <Whisper
                            placement="bottom"
                            trigger="hover"
                            speaker={
                              <Tooltip>{t("SERVICES_MENU_ITEM_STORE")}</Tooltip>
                            }
                          >
                            <IconButton
                              className={"custom-fade-in"}
                              icon={<Icon icon="shopping-bag" />}
                              appearance="default"
                              onClick={this.handleShowStore}
                            ></IconButton>
                          </Whisper>

                          <Whisper
                            placement="bottom"
                            trigger="hover"
                            speaker={
                              <Tooltip>
                                {t("SERVICES_MENU_ITEM_ENDPOINTS")}
                              </Tooltip>
                            }
                          >
                            <IconButton
                              className={"custom-fade-in"}
                              icon={<Icon icon="project" />}
                              appearance="default"
                              onClick={this.handleShowEndpoints}
                            ></IconButton>
                          </Whisper>

                          {module && (
                            <Whisper
                              placement="bottom"
                              trigger="hover"
                              speaker={
                                <Tooltip>
                                  {t("SERVICES_MENU_ITEM_NPM_PACK")}
                                </Tooltip>
                              }
                            >
                              <IconButton
                                icon={<Icon icon="cubes" />}
                                appearance="default"
                                onClick={this.handleEditNPM}
                              ></IconButton>
                            </Whisper>
                          )}
                          <Whisper
                            placement="bottom"
                            trigger="hover"
                            speaker={
                              <Tooltip>
                                {t("SERVICES_MENU_ITEM_LANG_SETTINGS")}
                              </Tooltip>
                            }
                          >
                            <IconButton
                              icon={<Icon icon="language" />}
                              appearance="default"
                              onClick={this.handleShowLangSettings}
                            ></IconButton>
                          </Whisper>
                          {module && (
                            <Whisper
                              placement="bottom"
                              trigger="hover"
                              speaker={
                                <Tooltip>
                                  {t("SERVICES_MENU_ITEM_USERS_ROLES")}
                                </Tooltip>
                              }
                            >
                              <IconButton
                                icon={<Icon icon="people-group" />}
                                appearance="default"
                                onClick={this.handleShowUsersRoles}
                              ></IconButton>
                            </Whisper>
                          )}
                          {module && (
                            <Whisper
                              placement="bottom"
                              trigger="hover"
                              speaker={
                                <Tooltip>
                                  {t("SERVICES_MENU_ITEM_ENVIROMENTS")}
                                </Tooltip>
                              }
                            >
                              <IconButton
                                icon={<Icon icon="eye" />}
                                appearance="default"
                                onClick={this.handleShowEnvironments}
                              ></IconButton>
                            </Whisper>
                          )}
                          {module && (
                            <Whisper
                              placement="bottom"
                              trigger="hover"
                              speaker={
                                <Tooltip>
                                  {t("SERVICES_MENU_ITEM_GLOBAL_SCRIPT")}
                                </Tooltip>
                              }
                            >
                              <IconButton
                                icon={<Icon icon="code" />}
                                appearance="default"
                                onClick={this.handleShowGlobalScript}
                              ></IconButton>
                            </Whisper>
                          )}
                          {module && (
                            <Whisper
                              placement="bottom"
                              trigger="hover"
                              speaker={
                                <Tooltip>
                                  {t("SERVICES_MENU_ITEM_SETTINGS")}
                                </Tooltip>
                              }
                            >
                              <IconButton
                                icon={<Icon icon="setting" />}
                                appearance="default"
                                onClick={this.handleShowSettings}
                              ></IconButton>
                            </Whisper>
                          )}

                          {module && (
                            <Whisper
                              placement="bottom"
                              trigger="hover"
                              speaker={
                                <Tooltip>{t("SERVICES_MENU_ITEM_RUN")}</Tooltip>
                              }
                            >
                              <IconButton
                                className={"custom-fade-in"}
                                appearance="primary"
                                icon={<Icon icon="play2" />}
                                active
                                onClick={() => {
                                  this.handleEngine();
                                }}
                                placement="right"
                              >
                                {t("SERVICES_MENU_ITEM_RUN")}
                              </IconButton>
                            </Whisper>
                          )}
                        </ButtonToolbar>
                      </Nav>
                    </Navbar.Body>
                  </Navbar>
                </Header>
                <Container>
                  {modules.dynamicValue.length > 0 ? (
                    module && (
                      <>
                        <Sidebar
                          width={expand ? 230 : 26}
                          collapsible
                          className={"workspace-sidebar"}
                        >
                          {expand && (
                            <>
                              <Nav
                                activeKey={activeTab}
                                onSelect={this.handleSelect}
                                appearance="subtle"
                                justified
                                className={"service-tab"}
                              >
                                <Nav.Item eventKey="service">
                                  {t("WORKSPACE_TAB_SERVICE")}
                                </Nav.Item>
                                <Nav.Item eventKey="transaction">
                                  {t("WORKSPACE_TAB_TRANSACTION")}
                                </Nav.Item>
                              </Nav>
                              {activeTab === "service" ? (
                                <ServicesTree />
                              ) : (
                                <div>
                                  <TransactionsTree />
                                </div>
                              )}
                            </>
                          )}
                          <IconButton
                            appearance="default"
                            className={"service-sidebar-button"}
                            onClick={this.handleToggle}
                            icon={
                              <Icon
                                icon={expand ? "angle-left" : "angle-right"}
                              />
                            }
                            circle
                            size="sm"
                          />
                        </Sidebar>
                        <Content>
                          {
                            /*
                                                            <ServiceLayout />
                                                            <MVCLayout />
                                                        */
                            this.props.selectedService ? (
                              this.props.selectedService.isService ? (
                                this.props.selectedService.isRefObject &&
                                !this.props.selectedService
                                  .isDataBaseFalseObjectAllowTransaction ? (
                                  <RefModelLayout />
                                ) : (
                                  <ServiceLayout
                                    key={this.props.selectedService.id}
                                    sidebarActive={expand}
                                  />
                                )
                              ) : activeTab === "service" ? (
                                <MVCLayout
                                  key={this.props.selectedService.id}
                                />
                              ) : (
                                <ServiceViewLayout
                                  key={this.props.selectedService.id}
                                  sidebarActive={expand}
                                />
                              )
                            ) : (
                              <></>
                            )
                          }
                        </Content>
                      </>
                    )
                  ) : (
                    <div style={{ height: "100%" }} className={"no-workspaces"}>
                      <Icon icon="database" size="4x" />
                      {t("MESSAGE_WORKSPACE_HAS_NOT_MODULE")}

                      <IconButton
                        onClick={this.handleCreateModule}
                        className={"no-workspaces-button"}
                        appearance="ghost"
                        icon={<Icon icon="plus" />}
                      >
                        {t("BUTTON_CREATE")}
                      </IconButton>
                    </div>
                  )}
                  {isLoadingModule && (
                    <Loader backdrop content={t("LOADING")} vertical />
                  )}
                </Container>
                <Footer className="workspace-footer">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      height: "100%",
                      marginLeft: "6px",
                    }}
                  >
                    <IconButton
                      style={{ marginRight: "15px" }}
                      appearance="subtle"
                      onClick={() => {
                        this.handleTerminalModal();
                      }}
                      icon={<Icon icon={"terminal"} />}
                      size="sm"
                    />
                    <div className="engine-status-box">
                      <Whisper
                        placement="auto"
                        trigger="hover"
                        speaker={<Tooltip>{t("TITLE_ENGINE_STATUS")}</Tooltip>}
                      >
                        <div
                          className={
                            "engine-status-color " +
                            (EngineStatus
                              ? " engine-status-on"
                              : " engine-status-off")
                          }
                        />
                      </Whisper>
                    </div>
                    {/*
                               <div className="engine-status-box">
                            <Whisper
                              placement="bottom"
                              trigger="hover"
                              speaker={
                                <Tooltip>{t("TITLE_API_STATUS")}</Tooltip>
                              }
                            >
                              <div className="engine-status-color engine-status-loading" />
                            </Whisper>
                          </div>
                            */}
                  </div>
                </Footer>
              </Container>
            )}

            {workspace && (
              <>
                <GlobalScriptModal
                  isActive={this.state.isGlobalScript}
                  handleToggle={this.handleShowGlobalScript}
                />
                <CreateModuleModal
                  onRefresh={this.getModules}
                  isActive={this.state.isCreateModule}
                  handleToggle={this.handleCreateModule}
                />
                <ShowStoreList
                  isActive={this.state.isShowStore}
                  handleToggle={this.handleShowStore}
                  onRefresh={this.getModules}
                />
                <UsersAndRolesModal
                  isActive={this.state.isUsersRoles}
                  handleToggle={this.handleShowUsersRoles}
                />

                <EditNPMPackages
                  onRefresh={() => {
                    this.getModules("", workspace.id);
                  }}
                  isActive={this.state.isEditNPM}
                  handleToggle={this.handleEditNPM}
                />

                <EngineModal
                  isActive={this.state.isEngine}
                  handleToggle={this.handleEngine}
                />

                <TerminalModal
                  isActive={this.state.isTerminal}
                  handleToggle={this.handleTerminalModal}
                />

                <WorkspaceEndpoints
                  isActive={this.state.isEndpoints}
                  handleToggle={this.handleShowEndpoints}
                />

                <ManageEnvironmentsModal
                  isActive={this.state.isEnv}
                  handleToggle={this.handleShowEnvironments}
                />
                <WorkspaceSettingsModal
                  isActive={this.state.isSettings}
                  handleToggle={this.handleShowSettings}
                />
              </>
            )}

            {workspace && (
              <LangSettings
                isActive={this.state.isLangSettings}
                handleToggle={this.handleShowLangSettings}
              />
            )}

            {!this.state.online && (
              <Message
                style={{ zIndex: 9999 }}
                full
                showIcon
                title="Warning"
                type="warning"
                description={i18n.t("MESSAGE_NETWORK_OFFLINE")}
              />
            )}
          </div>
        )}
      </Translation>
    );
  }
}
const mapStateToProps = (state, props) => {
  const { workspaceReducer, SocketReducer } = state;

  const { socket } = SocketReducer;
  const {
    workspaces,
    workspace,
    selectedService,
    modules,
    module,
    transaction,
    EngineStatus,
    TerminalList,
    gitStatus,
  } = workspaceReducer;

  return {
    workspaces,
    workspace,
    modules,
    module,
    selectedService,
    transaction,
    socket,
    EngineStatus,
    TerminalList,
    gitStatus,
  };
};

const mapDispatchToProps = {
  updateWorkspace,
  updateModules,
  clearWorkspace,
  updateSelectedModule,
  updateStore,
  setRefSocketRef,
  setEngineStatus,
  setTerminalList,
  setGitStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(Workspace);
