import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, Button, Panel, Alert } from "rsuite";
import { formatQuery, defaultRuleProcessorMongoDB } from "react-querybuilder";
import { Translation } from "react-i18next";
import i18n from "i18n";
import CustomFormGenerator from "components/Transaction/CustomFormGenerator";

class SearchFilterModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      query: { combinator: "and", rules: [] },
      queryData: [],
      formModel: null,
    };
  }

  updateQuery = (data) => {
    this.setState({
      query: data,
    });
  };
  getObjectFields = async (data, ref) => {
    const { allServices, allTransactions } = this.props;
    const that = this;

    let tableModel = [];
    let refService = null;
    switch (data.contentType) {
      case 5:
        refService = allTransactions.find(
          (x) => x.model.id === data.relationModel.relModel.id
        );
        debugger;
        tableModel = refService ? [...refService.model?.dataTypes] : [];
        break;
      case 10:
        refService = allTransactions.find(
          (x) => x.model.id === data.refModel.id
        ); //allServices.find((x) => x.relBaseModelId === data.refModel.id);
        tableModel = refService ? [...refService.model?.dataTypes] : [];

        try {
        } catch (error) {
          debugger;
        }

        break;

      default:
        break;
    }

    let allFields = [];

    for (let e of tableModel) {
      let _obj = {
        label: data.name + "." + e.name, //e.displayName,
        value: data.name + "." + e.name,
        name: data.name + "." + e.name,
        refData: e,
      };
      debugger;
      switch (e.contentType) {
        case 1:
          _obj["inputType"] = "text";
          allFields.push(_obj);
          break;
        case 2:
          _obj["inputType"] = "textarea";
          allFields.push(_obj);
          break;
        case 3:
          _obj["inputType"] = "number";
          allFields.push(_obj);
          break;
        case 4:
          _obj["inputType"] = "date";
          allFields.push(_obj);
          break;
        case 7:
          _obj["valueEditorType"] = "checkbox";
          allFields.push(_obj);
          break;
        case 8:
          _obj["inputType"] = "number";
          allFields.push(_obj);
          break;
        case 5:
          const c5 = await this.getObjectFields(e, null);
          _obj["inputType"] = "text";
          //_obj.label = e.name;
          allFields.push(_obj);
          allFields = [...allFields, ...c5];
          break;
        case 10:
          const c10 = await this.getObjectFields(e, null);
          _obj["inputType"] = "text";
          //_obj.label = e.name;
          allFields.push(_obj);
          allFields = [...allFields, ...c10];
          break;
        case 14:
          _obj = null;
          break;
        default:
          _obj = {
            label: data.name + "." + e.name,
            value: data.name + "." + e.name,
            name: data.name + "." + e.name,
            refData: e,
          };
          allFields.push(_obj);
          break;
      }
    }

    return allFields;
  };

  generateModelObject = async () => {
    const { tableModel } = this.props;
    let allFields = [];

    for (let e of tableModel) {
      let _obj = {
        label: e.displayName,
        value: e.name,
        name: e.name,
        refData: e,
      };

      switch (e.contentType) {
        case 1:
          _obj["inputType"] = "text";
          allFields.push(_obj);
          break;
        case 2:
          _obj["inputType"] = "textarea";
          allFields.push(_obj);
          break;
        case 3:
          _obj["inputType"] = "number";
          allFields.push(_obj);
          break;
        case 4:
          _obj["inputType"] = "date";
          allFields.push(_obj);
          break;
        case 7:
          _obj["valueEditorType"] = "checkbox";
          allFields.push(_obj);
          break;
        case 5:
          const c5 = await this.getObjectFields(e, null);
          _obj["inputType"] = "text";
          _obj.label = e.name;
          allFields.push(_obj);
          allFields = [...allFields, ...c5];

          break;
        case 10:
          const c10 = await this.getObjectFields(e, null);
          _obj["inputType"] = "text";
          _obj.label = e.name;
          allFields.push(_obj);
          allFields = [...allFields, ...c10];

          break;
        case 14:
          _obj = null;
          break;
        default:
          _obj = {
            label: e.displayName,
            value: e.name,
            name: e.name,
            refData: e,
          };
          allFields.push(_obj);
          break;
      }
    }

    this.setState({
      data: allFields,
    });
  };

  generateSearchFormNested = (query) => {
    const { data } = this.state;
    let _sfModel = [];
    query.rules.forEach((element) => {
      if (element.hasOwnProperty("rules")) {
        const nestedList = this.generateSearchFormNested(element);
        _sfModel = [..._sfModel, ...nestedList];
      } else {
        let _obj = {
          queryId: element.id,
          operator: element.operator,
          field: element.field,
          refData: data.find((x) => x.value == element.field),
        };

        _obj.refData.refData.privateField = false;
        _sfModel.push(_obj);
      }
    });
    return _sfModel;
  };

  generateSearchForm = () => {
    const { data, query } = this.state;
    let _sfModel = [];
    query.rules.forEach((element) => {
      if (element.hasOwnProperty("rules")) {
        const nestedList = this.generateSearchFormNested(element);
        _sfModel = [..._sfModel, ...nestedList];
      } else {
        let _obj = {
          queryId: element.id,
          operator: element.operator,
          field: element.field,
          refData: data.find((x) => x.value == element.field),
        };
        _obj.refData.refData.privateField = false;
        switch (_obj.refData.refData.contentType) {
          case 8: // Identity
            _obj.refData.refData.contentType = 3;
            _obj.refData.refData.typeDetail = 1;
            break;

          default:
            break;
        }
        _sfModel.push(_obj);
      }
    });
    const refModel = [];

    _sfModel.forEach((x) => {
      debugger;
      let _o = Object.assign({}, x.refData.refData);
      _o["name"] = x.queryId;
      refModel.push(_o);
    });

    this.setState({
      formModel: _sfModel,
      refModel: refModel,
    });
  };
  componentDidUpdate(prevProps, prevState) {
    const that = this;
    const { data, query, refModel, formModel } = this.state;
    const { isActive, searchObjectModalRef } = this.props;
    const selectedService = searchObjectModalRef
      ? searchObjectModalRef
      : this.props.selectedService;

    if (prevProps.isActive !== isActive && isActive && data == null) {
      this.generateModelObject();
    }
    if (prevProps.isActive !== isActive && isActive && selectedService) {
      const query = selectedService?.baseModelData?.query
        ? JSON.parse(selectedService.baseModelData.query)
        : selectedService?.query
        ? JSON.parse(selectedService?.query)
        : { combinator: "and", rules: [] };

      // { combinator: "and", rules: [] };
      const queryData = query ? formatQuery(query, "mongodb") : "";

      this.setState({
        query: query,
        queryData: queryData,
      });
    }

    if (isActive && data && query && !refModel && !formModel) {
      this.generateSearchForm();
    }
  }

  replaceValueOfTheLabel = (obj, id, value) => {
    debugger;
    if (obj.id === id) {
      return (obj.value =
        typeof value == "object"
          ? value.hasOwnProperty("_id")
            ? `${value._id}`
            : value
          : value);
    }
//`ObjectId("${value._id}")`
    if (obj.rules)
      for (const ob of obj.rules) {
        this.replaceValueOfTheLabel(ob, id, value);
      }
  };

  changeAllNestedValues = (obj, targetKey, newValue) => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (typeof obj[key] === "object") {
          this.changeAllNestedValues(obj[key], targetKey, newValue);
        } else if (key === targetKey) {
          if (!Array.isArray(obj[key])) {
            obj[key] = newValue;
          } else {
            for (var i = 0; i < obj[key].length; i++) {
              obj[key][i] = newValue;
            }
          }
        }
      }
    }
    return obj;
  };

  /*
  componentWillMount() {
    const { isActive } = this.props;
    document.addEventListener("keydown", this.onKeyPressed.bind(this));
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyPressed.bind(this));
  }

  onKeyPressed(e) {
    switch (e.keyCode) {
      case "Enter":
        e.preventDefault();
        this.handleSubmit();
        break;

      default:
        break;
    }
  }
  */
  handleSubmit = () => {
    const { formValue, query } = this.state;
    if (!this.props.isActive) return;

    try {
      let _formToQuery = this.changeAllNestedValues(
        JSON.parse(JSON.stringify(query)),
        "value",
        ""
      );
      Object.keys(formValue).forEach((el) => {
        this.replaceValueOfTheLabel(_formToQuery, el, formValue[el]);
      });
      debugger;
      const _f = formatQuery(_formToQuery, {
        format: "mongodb",
        ruleProcessor: (rule, options) => {
          if (
            rule.operator === "contains" ||
            rule.operator === "beginsWith" ||
            rule.operator === "endsWith" ||
            rule.operator === "doesNotContain" ||
            rule.operator === "doesNotBeginWith" ||
            rule.operator === "doesNotEndWith"
          ) {
            // "has" operator not handled by default "jsonlogic" rule processor
            return this.customDefaultRuleProcessorMongoDB(rule, options);
          }
          // Fall back to the default rule processor for all other operators
          return defaultRuleProcessorMongoDB(rule, options);
        },
      });

      this.props.handleFilterAndGetData(_f);
    } catch (error) {
      debugger;
    }
  };

  handleKeyDown = (e) => {
    switch (e.key) {
      case "Enter":
        e.preventDefault();
        this.handleSubmit();
        break;

      default:
        break;
    }
  };

  render() {
    const { isActive, handleToggle, tableModel } = this.props;
    const { formValue, data, queryData, query, fields, refModel } = this.state;

    if (data && query && refModel && refModel.length > 0) {
      //debugger;
    }
    return (
      <Translation>
        {(t) => (
          <>
            <Modal overflow={true} show={isActive} onHide={handleToggle}>
              <Modal.Header>
                <Modal.Title>{t("TITLE_MODAL_SEARCH_FILTER")}</Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ padding: 0 }}>
                {data && query && refModel && refModel.length > 0 && (
                  <div
                    className="search-filter-form-panel"
                    tabIndex={0}
                    onKeyDown={this.handleKeyDown}
                  >
                    <CustomFormGenerator
                      isSearchFilterForm={true}
                      currentValues={this.state.formValue}
                      currentValuesOrj={this.state.formValue}
                      refModel={refModel}
                      handleUpdateRefData={(_data, form, _shema) => {
                        const merged = Object.assign(
                          {},
                          this.state.formValue,
                          _data
                        );

                        this.setState({
                          formValue: merged,
                          form: form,
                          shema: _shema,
                        });
                      }}
                    />
                  </div>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => {
                    this.handleSubmit();
                  }}
                  appearance="primary"
                >
                  {t("BUTTON_FILTER")}
                </Button>

                <Button onClick={handleToggle} appearance="subtle">
                  {t("BUTTON_CANCEL")}
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </Translation>
    );
  }

  customDefaultRuleProcessorMongoDB = (
    { field, operator, value, valueSource },
    { parseNumbers } = {}
  ) => {
    const valueIsField = valueSource === "field";
    const escapeDoubleQuotes = (v) =>
      typeof v !== "string"
        ? v
        : v.replaceAll("\\", "\\\\").replaceAll(`"`, `\\"`);

    if (operator === "contains") {
      return valueIsField
        ? `{"$where":"this.${field}.includes(this.${value})"}`
        : `{"${field}":{"$regex":"${escapeDoubleQuotes(
            value
          )}","$options" : "i"}}`;
    } else if (operator === "beginsWith") {
      return valueIsField
        ? `{"$where":"this.${field}.startsWith(this.${value})"}`
        : `{"${field}":{"$regex":"^${escapeDoubleQuotes(
            value
          )}" ,"$options" : "i"}}`;
    } else if (operator === "endsWith") {
      return valueIsField
        ? `{"$where":"this.${field}.endsWith(this.${value})"}`
        : `{"${field}":{"$regex":"${escapeDoubleQuotes(
            value
          )}$" ,"$options" : "i"}}`;
    } else if (operator === "doesNotContain") {
      return valueIsField
        ? `{"$where":"!this.${field}.includes(this.${value})"}`
        : `{"${field}":{"$not":{"$regex":"${escapeDoubleQuotes(
            value
          )}","$options" : "i"}}}`;
    } else if (operator === "doesNotBeginWith") {
      return valueIsField
        ? `{"$where":"!this.${field}.startsWith(this.${value})"}`
        : `{"${field}":{"$not":{"$regex":"^${escapeDoubleQuotes(
            value
          )}" ,"$options" : "i"}}}`;
    } else if (operator === "doesNotEndWith") {
      return valueIsField
        ? `{"$where":"!this.${field}.endsWith(this.${value})"}`
        : `{"${field}":{"$not":{"$regex":"${escapeDoubleQuotes(
            value
          )}$" ,"$options" : "i"}}}`;
    }
    return defaultRuleProcessorMongoDB(
      { field, operator, value, valueSource },
      ({ parseNumbers } = {})
    );
  };
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer } = state;
  const {
    model,
    models,
    transaction,
    transactions,
    workspaces,
    workspace,
    dataModels,
    selectedService,
    services,
    allServices,
    allTransactions,
  } = workspaceReducer;

  return {
    workspace,
    transaction,
    transactions,
    workspaces,
    dataModels,
    models,
    model,
    selectedService,
    services,
    allServices,
    allTransactions,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SearchFilterModal);

/*

 <div style={{ paddingRight: "6px" }}>
                  <Dropdown
                    className="search-single-dropdown"
                    title={t("BUTTON_ADD")}
                    toggleComponentClass={Button}
                    appearance="default"
                  >
                    <Dropdown.Item
                      onSelect={() =>
                        this.addQueryData(
                          { _time: new Date() + "", type: "single" },
                          0
                        )
                      }
                    >
                      {t("SEARCH_FIELD_FIELD")}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onSelect={() =>
                        this.addQueryData(
                          { _time: new Date() + "", type: "group" },
                          0
                        )
                      }
                    >
                      {t("SEARCH_FIELD_GROUP")}
                    </Dropdown.Item>
                  </Dropdown>

                  <div>
                     } 
                    {queryData &&
                        queryData.map((x, k) => {
                          if (x.type == "single")
                            return (
                              <SingleField
                                addQueryData={this.addQueryData}
                                updateQueryData={this.updateQueryData}
                                key={x._time}
                                tableModel={data}
                                refdata={{
                                  key: k,
                                  data: x,
                                  refData: null,
                                }}
                              />
                            );
                          else
                            return (
                              <GroupField
                              addQueryData={this.addQueryData}
                                key={k._time}
                                tableModel={data}
                                refdata={{
                                  key: k,
                                  data: x,
                                  refData: null,
                                }}
                              />
                            );
                        })}
                      
                    </div>
                  </div>

*/
