import React, { Component } from "react";
import { useEffect } from "react";

import { connect } from "react-redux";
import { Translation } from "react-i18next";
import i18n from "i18n";
import { ErpEngineService } from "_services";

import { Row, Col } from "reactstrap";

import FormElement from "./FormElement";

import { Button, Alert, IconButton, Icon } from "rsuite";
import { Form, FormGroup, Schema, ButtonToolbar } from "rsuite";
import { Tooltip, Whisper } from "rsuite";

import { typeDetailsSP } from "assets/globalOps/CollectionTypes";
import { enqueueSnackbar } from "notistack";

import ShowTransactionModal from "components/Transaction/Modals/ShowTransactionModal";
import { ResponseStatusCode } from "_helpers";
import EasyInvoiceViewer from "components/Modals/EasyInvoiceViewer";
import ConfirmModal from "components/Modals/ConfirmModal";

//import easyinvoice from "easyinvoice";

const { StringType, NumberType, DateType, ObjectType, BooleanType, ArrayType } =
  Schema.Types;
const model = Schema.Model({});

class FormGenerator extends Component {
  constructor(props) {
    super(props);

    const {
      dataModels,
      allTransactions,
      selectedService,
      allServices,
      currentValues,
      models,
    } = props;
    debugger;
    const dataModel = allTransactions
      ? allTransactions.find(
          (x) =>
            x.model.id ===
            (selectedService.responseModelType
              ? selectedService.relResponseModelId
              : selectedService.reqModelId)
        )?.dataTypes
      : selectedService.model;

    this.state = {
      formValue: currentValues ? currentValues : {},
      formError: {},
      formValueUpdateTime: new Date(),
      loading: false,
      activeElement: null,
      dataModel: dataModel ? dataModel : [],
      formKey: "fg" + "-" + new Date().toJSON(),
      isTransModal: false,
      refTransaction: null,
      isInvoiceViewer: false,
      isConfirmModal: false,
      confirmTitle: "",
    };
  }
  handleConfirmModal = (title = "") => {
    this.setState({
      confirmTitle: title,
      isConfirmModal: !this.state.isConfirmModal,
    });
  };
  handleInvoiceViewerModal = () => {
    this.setState({
      isInvoiceViewer: !this.state.isInvoiceViewer,
    });
  };

  handleModalTransaction = (transaction = null, data) => {
    this.handleTransModal(transaction, data, true);
  };

  handleTransModal = (refTransaction = null, formValue, status) => {
    debugger;
    this.setState({
      refTransaction: refTransaction,
      isTransModal: status == undefined ? !this.state.isTransModal : status,
      refFormValue: formValue,
    });
  };

  componentDidMount() {
    const { isRefModelForm, handleUpdateRefData } = this.props;
    const { formValue } = this.state;
    const that = this;
    if (isRefModelForm && handleUpdateRefData) {
      handleUpdateRefData(
        formValue.hasOwnProperty("0") ? formValue[0] : formValue,
        that.form,
        null
      );
    }
  }

  handlePostValidatedForm = () => {
    const { formValue } = this.state;
    const { workspace, selectedService, onSubmit } = this.props;
    const URL_MODE = "dev_URL";
    const that = this;
    this.setState({ loading: true });
    debugger;
    ErpEngineService.EXECUTE_TRANSACTION_DIRECTLY(
      workspace[URL_MODE],
      this.props.isListDetail
        ? this.props.itemOperation
        : selectedService.functionName,
      selectedService.executableTransactionServiceName,
      JSON.stringify(formValue)
    )
      .then((data) => {
        if (data.operationResult) {
          that.handleReset();
          Alert.success(i18n.t("MESSAGE_TRANSACTION_SUBMIT_SUCCESS"));
          if (onSubmit) {
            onSubmit();
          }
        }
        that.setState({ loading: false });
      })
      .catch((err) => {
        that.setState({ loading: false });
        try {
          Alert.error(
            Array.isArray(err.failText) ? err.failText.join(". ") : err.failText
          );
        } catch (error) {
          Alert.error(i18n.t("MESSAGE_TRANSACTION_SUBMIT_UNSUCCESS"));
        }

        debugger;
      });
  };

  UpdateFormValue = (formValue) => {
    const { isRefModelForm, handleUpdateRefData, _dynamicSchemaModel } =
      this.props;
    this.setState({ formValue, formValueUpdateTime: new Date() });
    const that = this;
    if (isRefModelForm) {
      handleUpdateRefData(formValue, this.form, _dynamicSchemaModel);
    }
  };

  preTransactionFunc = (data, _selectedService, _formValue) => {
    const {
      isRunTransModal,
      runTransModalClose,
      updateBaseForm,
      handleRunPreTransaction,
    } = this.props;

    const _dynamicSchemaModel = this.prepareGenerateSchema();

    const that = this;
    let result = {
      status: true,
      body: data,
    };

    const runTransaction = that.runTransaction;
    const transactions = this.props.allTransList;
    const Toast = this.ToastMessage;
    const SnackToast = this.SnackMessage;

    const easyinvoice = require("easyinvoice").default; //easyinvoice.download('myInvoice.pdf', data.InvoiceBinary);
    const showInvoiceModal = this.handleInvoiceViewerModal;
    const showConfirmModal = this.handleConfirmModal;
    const runPreTransaction = () => {
      if (isRunTransModal && handleRunPreTransaction) {
        handleRunPreTransaction();
      }
    };

    const updatePreForm = (formValue) => {
      if (isRunTransModal) {
        //debugger;
        updateBaseForm(formValue);
      } else {
        this.setState({ formValue });
        if (this.props.isShowTransaction) {
          this.props.updateBaseForm(formValue);
        } else if (this.props.isRefModelForm) {
          // handleUpdateRefData(formValue);
          that.handleUpdateRefData(formValue, this.form, _dynamicSchemaModel);
        } else {
        }
      }
    };
    const closeModal = () => {
      if (this.props.isShowTransaction) {
        this.props.showTransactionClose();
      } else if (isRunTransModal) {
        runTransModalClose();
      } else {
        that.setState({
          isTransModal: false,
        });
      }
    };

    const checkFormValidation = () => {
      try {
        if (!that.form.check()) {
          Alert.error("Form not validated");
          result.status = false;

          return false;
        } else {
          return true;
        }
      } catch (error) {
        return false;
      }
    };

    let { formData, model } = this.transactionPrepareFunc();
    const updateData = that.UpdateFormValue;
    debugger;
    if (
      _selectedService.hasOwnProperty("preScript") &&
      _selectedService.preScript.trim().length > 0
    ) {
      try {
        if (_selectedService.preScript.includes("await ")) {
          result.body = eval(
            "(async function() => { " + _selectedService.preScript + "})()"
          );
        } else {
          result.body = eval(
            "(function() {" + _selectedService.preScript + "}())"
          );
        }
      } catch (error) {
        debugger;
      }
    }
    /*
    preScript(pin): "alert("pre")"
postScript(pin): "alert("post")"
    */

    try {
      // debugger;
      if (result) {
        return result;
      }
      return data;
    } catch (error) {
      return data;
    }
  };
  ToastMessage = (msg, type) => {
    try {
      switch (type) {
        case "success":
          Alert.success(msg);
          break;
        case "error":
          Alert.error(msg);
          break;
        case "info":
          Alert.info(msg);
          break;
        case "warning":
          Alert.warning(msg);
          break;
        default:
          Alert.info(msg);
          break;
      }
    } catch (error) {
      Alert.info(msg);
    }
  };
  SnackMessage = (msg, type) => {
    try {
      debugger
      enqueueSnackbar(msg, { variant: type });
    } catch (error) {
      debugger
      enqueueSnackbar(msg);
    }
  };
  postTransactionFunc = (
    status,
    response,
    responseModel,
    _selectedService,
    _sModels
  ) => {
    const { isRunTransModal, runTransModalClose, updateBaseForm, baseData } =
      this.props;

    const _dynamicSchemaModel = this.prepareGenerateSchema();

    const that = this;
    const runTransaction = that.runTransaction;
    const transactions = this.props.allTransList;
    const Toast = this.ToastMessage;
    const SnackToast = this.SnackMessage;

    const runPreTransaction = () => {
      if (that.props.isRunTransModal && that.props.handleRunPreTransaction) {
        that.props.handleRunPreTransaction();
      }
    };

    const updatePreForm = (formValue) => {
      if (isRunTransModal) {
        debugger;
        updateBaseForm(formValue);
      } else {
        this.setState({ formValue });
        if (this.props.isShowTransaction) {
          this.props.updateBaseForm(formValue);
        } else if (this.props.isRefModelForm) {
          // handleUpdateRefData(formValue);
          that.handleUpdateRefData(formValue, this.form, _dynamicSchemaModel);
        } else {
        }
      }
    };
    const closeModal = () => {
      if (this.props.isShowTransaction) {
        this.props.showTransactionClose();
      } else if (isRunTransModal) {
        runTransModalClose();
      } else {
        that.setState({
          isTransModal: false,
        });
      }
    };
    const easyinvoice = require("easyinvoice").default; //easyinvoice.download('myInvoice.pdf', data.InvoiceBinary);
    const showInvoiceModal = this.handleInvoiceViewerModal;
    const showConfirmModal = this.handleConfirmModal;
    let { formData, model } = this.transactionPrepareFunc();
    const updateData = that.UpdateFormValue;

    debugger;

    if (
      _selectedService.hasOwnProperty("postScript") &&
      _selectedService.postScript
    ) {
      try {
        let result = null;

        if (_selectedService.postScript.includes("await ")) {
          result = eval(
            "(async function() => { " + _selectedService.postScript + "})()"
          );
        } else {
          result = eval("(function() {" + _selectedService.postScript + "}())");
        }

        if (
          (!_selectedService.postScript ||
            _selectedService.postScript.trim().length == 0) &&
          status
        ) {
          if (_selectedService.responseModelType === 0) {
            let _myValues = {};
            _sModels.map((x) => {
              if (response.hasOwnProperty(x.name)) {
                _myValues[x.name] = response[x.name];
              } else if (formData.hasOwnProperty(x.name)) {
                _myValues[x.name] = formData[x.name];
              } else {
              }
            });

            that.setState({
              formValue: { ...that.state.formValue, ..._myValues },
              formValueUpdateTime: new Date(),
            });
          }
        }
        if (result) {
          //    return result;
        }
        /*
        if (data.operationResult) {
          //that.handleReset();
          //Alert.success(i18n.t("MESSAGE_TRANSACTION_SUBMIT_SUCCESS"));
      
          if (_selectedService.responseModelType === 0) {
            let _myValues = {};
            _sModels.map((x) => {
              if (data.dynamicValue.hasOwnProperty(x.name)) {
                _myValues[x.name] = data.dynamicValue[x.name];
              } else if (formValue.hasOwnProperty(x.name)) {
                _myValues[x.name] = formValue[x.name];
              } else {
              }
            });
            
            that.setState({
              formValue: _myValues,
              formValueUpdateTime: new Date(),
            });
          }

          //data.dynamicValue
         
        }

*/
      } catch (error) {
        debugger;
      }
    } else {
      if (_selectedService.responseModelType === 0) {
        let _myValues = {};
        _sModels.map((x) => {
          if (response.hasOwnProperty(x.name)) {
            _myValues[x.name] = response[x.name];
          } else if (formData.hasOwnProperty(x.name)) {
            _myValues[x.name] = formData[x.name];
          } else {
          }
        });
        that.setState({
          formValue: _myValues,
          formValueUpdateTime: new Date(),
        });
      }
    }
  };

  transactionPrepareFunc = () => {
    const { formValue } = this.state;
    const { selectedService, model, isRefModelForm, refModel } = this.props;
    const _sModels = model
      ? model.find(
          (x) =>
            x.id === (isRefModelForm ? refModel.id : (selectedService.responseModelType
              ? selectedService.relResponseModelId
              : selectedService.reqModelId))
        ).dataTypes
      : (isRefModelForm ? refModel : selectedService)
      ? isRefModelForm
        ? refModel.dataTypes
        : selectedService.model
      : [];
    return {
      formData: JSON.parse(JSON.stringify(formValue)),
      model: _sModels,
    };
  };

  handleRunTransaction = (_selectedService, _formValue) => {
    const { formValue } = this.state;
    const { workspace, onSubmit, model, isRefModelForm, refModel } = this.props;

    const selectedService = _selectedService
      ? _selectedService
      : this.state.selectedService;

    const URL_MODE = "dev_URL";
    const that = this;

    const _sModels = model
      ? model.find(
          (x) =>
            x.id === (isRefModelForm ? refModel.id : (selectedService.responseModelType
              ? selectedService.relResponseModelId
              : selectedService.reqModelId))
        ).dataTypes
      : (isRefModelForm ? refModel : selectedService)
      ? isRefModelForm
        ? refModel.dataTypes
        : selectedService.model
      : [];

    debugger;

    this.setState({ loading: true });

    const _preFunc = this.preTransactionFunc(
      _formValue,
      _selectedService,
      _formValue
    );

    if (_preFunc.status) {
      const _body = JSON.stringify(_preFunc.body);
      debugger;
      that.setState({ loading: true });
      ErpEngineService.EXECUTE_TRANSACTION_DIRECTLY(
        workspace[URL_MODE],
        this.props.isListDetail
          ? this.props.itemOperation
            ? this.props.itemOperation
            : _selectedService.functionName
          : _selectedService.functionName,
        _selectedService.executableTransactionServiceName,
        _body
      )
        .then((data) => {
          that.postTransactionFunc(
            data.operationResult,
            data.dynamicValue,
            "amntar",
            _selectedService,
            _sModels
          );

          that.setState({ loading: false });
        })
        .catch((err) => {
          debugger;
          that.setState({ loading: false });
          try {

            Alert.error(
              Array.isArray(err.failText)
                ? err.failText.join(". ")
                : err.failText
            );
            
          } catch (error) {
            Alert.error(i18n.t("MESSAGE_TRANSACTION_SUBMIT_UNSUCCESS"));
          }
          // let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
        });
    } else {
      this.setState({ loading: false });
    }
  };

  handleSubmit = (e) => {
    const { formValue, formError } = this.state;
    const that = this;
    debugger;
    if (!this.form.check()) {
      Alert.error("Form not validated");
      return;
    }

    //this.props.handleUpdateRow(this.state.formValue);
    //  return
    this.handlePostValidatedForm();
    //Alert.success("Form validated");
  };

  handleReset = () => {
    this.setState({
      formValue: {},
      formError: {},
      formValueUpdateTime: new Date(),
      loading: false,
      formKey: "fg" + "-" + new Date(),
      // dataModel: [],
    });
  };
  /*



*/
  prepareGenerateSchema = () => {
    const { selectedService, models, isRefModelForm, refModel } = this.props;

    let model = {}; // Schema.Model({});
    /*
    const _sModels = models
      ? models.dynamicValue.find((x) => x.id === selectedService.reqModelId)
          .dataTypes
      : selectedService.model;
*/
    // if (isRefModelForm) debugger;

    const _xx =
      model &&
      this.props.model.find(
        (x) =>
          x.id === (isRefModelForm ? refModel.id : (selectedService.responseModelType
            ? selectedService.relResponseModelId
            : selectedService.reqModelId))
      );

    if (!_xx) {
      debugger;
    }

    const _sModels = model
      ? this.props.model.find(
          (x) =>
            x.id === (isRefModelForm ? refModel.id : (selectedService.responseModelType
              ? selectedService.relResponseModelId
              : selectedService.reqModelId))
        ).dataTypes
      : (isRefModelForm ? refModel : selectedService)
      ? isRefModelForm
        ? refModel.dataTypes
        : selectedService.model
      : [];

    _sModels.map((item, key) => {
      if (!item.privateField) {
        let _obj = {};
        switch (item.contentType) {
          case 1:
            // String
            _obj = StringType();
            break;
          case 2:
            // Rich Text
            _obj = StringType();
            break;
          case 3:
            // Number
            _obj = NumberType();
            break;
          case 4:
            // Date
            _obj = DateType();
            break;
          case 5:
            // Relations
            _obj = ObjectType(); // string ti
            break;
          case 6:
            // Enum
            _obj = StringType();
            break;
          case 7:
            // Boolean
            _obj = BooleanType();
            break;
          case 8:
            // Identity
            _obj = StringType();
            break;
          case 9:
            // Image
            _obj = ObjectType();
            break;
          case 10:
            // Relations
            _obj = ObjectType(); // ObjectType();
            break;
          case 11:
            // Relations
            _obj = ObjectType(); // ObjectType();
            break;
          case 12:
            // Relations
            _obj = ObjectType(); // ObjectType();
            break;
          case 13:
            // Relations
            _obj = ArrayType(); // ObjectType();
            break;
            case 15:
              // Relations
              _obj = ObjectType();
              break;
            case 16:
              // Relations
              _obj = ObjectType(); // ObjectType();
              break;
              case 17:
                // Relations
                _obj = ObjectType(); // ObjectType();
                break;
          default:
            break;
        }
        if (item.reqiured || item.notNull) {
          if (_obj) _obj.isRequired(i18n.t("ERROR_DM_GLOBAL_REQUIRED"));
        }
        if (item.uniq) {
        }

        switch (item.typeDetail) {
          case 1:
            // STANDART
            break;
          case 2:
            // PASSWORD
            break;
          case 3:
            // EMAIL
            _obj.isEmail(i18n.t("ERROR_DM_GLOBAL_EMAIL_FORMAT"));
            break;
          case 4:
            // INT
            //_obj.isInteger(i18n.t("ERROR_DM_GLOBAL_INT"));
            break;
          case 5:
            // BIGINT
            // _obj.isInteger(i18n.t("ERROR_DM_GLOBAL_INT"));
            break;
          case 6:
            // DECIMAL
            // _obj.isInteger(i18n.t("ERROR_DM_GLOBAL_INT"));
            break;
          case 7:
            // DOUBLE
            //_obj.isInteger(i18n.t("ERROR_DM_GLOBAL_INT"));
            break;
          case 8:
            // DOUBLE
            //_obj.isInteger(i18n.t("ERROR_DM_GLOBAL_INT"));
            break;
          case 9:
            // DOUBLE
            //_obj.isInteger(i18n.t("ERROR_DM_GLOBAL_INT"));
            break;
          case 10:
            // DOUBLE
            //_obj.isInteger(i18n.t("ERROR_DM_GLOBAL_INT"));
            break;
          case 11:
            // DOUBLE
            //_obj.isInteger(i18n.t("ERROR_DM_GLOBAL_INT"));
            break;
            case 12:
              // DOUBLE
              //_obj.isInteger(i18n.t("ERROR_DM_GLOBAL_INT"));
              break;
              case 13:
                // DOUBLE
                //_obj.isInteger(i18n.t("ERROR_DM_GLOBAL_INT"));
                break;
          default:
            break;
        }
        if (
          item.contentType !== 8 &&
          item.contentType !== 12 &&
          item.contentType !== 11 &&
          item.contentType !== 14 &&
          item.contentType !== 17
        ) {
          model[item.name] = _obj;
        }
      }
    });
    return Schema.Model(model);
  };

  handleUpdatePP = (_data, cmsKey) => {
    let _formValue = this.state.formValue;
    _formValue[cmsKey] = _data;
    this.setState({
      formValue: _formValue,
      formValueUpdateTime: new Date(),
    });
  };

  handleUpdateEnum = (_data) => {
    const { formValue } = this.state;
    formValue["defaultData"] = _data;
    this.setState({
      formValue: formValue,
      formValueUpdateTime: new Date(),
    });
  };

  handleSetActiveElement = (id) => {
    this.setState({
      activeElement: id,
    });
  };

  handleUpdateList = (newState) => {
    this.setState({ dataModel: newState });
  };
  getFormRef = () => {
    return this.form;
  };

  render() {
    const {
      formValue,
      formError,
      loading,
      _model,
      dataModel,
      formValueUpdateTime,
      refTransaction,
    } = this.state;
    const {
      isRefModelForm,
      isShowTransaction,
      refModel,
      selectedService,
      onSubmit,
      currentValuesOrj,
      isListDetail,
      itemOperation,
      model,
      models,
      handleUpdateRefData,
      readOnly,
      allServices,
      allTransactions,
      isCreateRow,
    } = this.props;
    const that = this;
    const _typeDetails = typeDetailsSP.filter(
      (x) => x.role == formValue["contenttype"]
    );

    const _dynamicSchemaModel = this.prepareGenerateSchema();

    const _fModel = allServices.find(
      (x) =>
        x.relBaseModelId ===
        (isRefModelForm ? refModel.reqModelId : (selectedService.responseModelType
          ? selectedService.relResponseModelId
          : selectedService.reqModelId))
    );

    const _MModel = model.find(
      (x) =>
        x.id ===
        (isRefModelForm ? refModel.reqModelId : (selectedService.responseModelType
          ? selectedService.relResponseModelId
          : selectedService.reqModelId))
    );

    /*
    _fModel.transActions.find(x=>x.id == selectedService.id)

    
    */
    const _fService = allServices.find(
      (x) => x.id === (isRefModelForm ? refModel.id : selectedService.id)
    );

    /*
    allServices.find(
      (x) =>
        x.relBaseModelId ===
        (isRefModelForm ? refModel.id : selectedService.reqModelId)
    );
    
    */

    const getFromAll = allTransactions.find(
      (x) =>
        x.model.id ===
        (isRefModelForm ? refModel.id : (selectedService.responseModelType
          ? selectedService.relResponseModelId
          : selectedService.reqModelId))
    )?.model;

    const _sModels = _fModel
      ? _fModel.model
      : (isRefModelForm ? refModel : _MModel ? _MModel : selectedService)
      ? isRefModelForm
        ? refModel.dataTypes
        : _MModel
        ? _MModel.dataTypes
        : selectedService.model
      : [];
    debugger;
    const _sService = model
      ? getFromAll
        ? getFromAll
        : model.find(
            (x) =>
              x.id ===
              (isRefModelForm ? refModel.id : (selectedService.responseModelType
                ? selectedService.relResponseModelId
                : selectedService.reqModelId))
          )
      : (isRefModelForm ? refModel : selectedService)
      ? isRefModelForm
        ? refModel
        : selectedService
      : [];
    debugger;
    /* if (!(that.props.refTransaction ? that.props.refTransaction : _fService)) {
     }
*/
    const visibleFields =
      isRefModelForm && refModel.visibleFields
        ? JSON.parse(refModel.visibleFields)
        : [];

    const base = that.props.refTransaction
      ? allServices.find(
          (x) =>
            x.relBaseModelId ===
            (isRefModelForm
              ? this.props.refTransaction.reqModelId
              : selectedService.reqModelId)
        )
      : _fService;

    const isHasPriority = base
      ? base.view[Object.keys(base.view)[0]].hasOwnProperty("priority")
      : false;

    return (
      <Translation>
        {(t) => (
          <>
            <div
              className="modal-body"
              style={{ padding: 0 }}
              key={this.state.formKey + formValueUpdateTime + "div_frm"}
            >
              <Row>
                <Col></Col>
              </Row>
              <Row>
                <Col style={{ marginBottom: "15px", padding: "0px 1.25rem" }}>
                  <Form
                    key={this.state.formKey + formValueUpdateTime}
                    readOnly={readOnly}
                    ref={(ref) => {
                      this.form = ref;
                    }}
                    onChange={(formValue) => {
                      this.setState({ formValue });
                      if (isRefModelForm) {
                        handleUpdateRefData(
                          formValue,
                          this.form,
                          _dynamicSchemaModel
                        );
                      }
                    }}
                    onCheck={(formError) => {
                      this.setState({ formError });
                    }}
                    className={
                      "dynamic-create-form " +
                      ((this.props.isListDetail
                        ? this.props.itemOperation
                        : selectedService.functionName) === "delete"
                        ? "delete-form-data"
                        : "")
                    }
                    formValue={formValue}
                    model={_dynamicSchemaModel}
                    fluid
                    plaintext={
                      (this.props.isListDetail
                        ? this.props.itemOperation
                        : selectedService.functionName) === "delete"
                    }
                  >
                    <Row>
                      {selectedService &&
                        _sModels
                          .filter((x) => !x.privateField)
                          .filter((y) => {
                            if (!isCreateRow && visibleFields.length > 0)
                              return visibleFields.includes(y.name);
                            else return true;
                          })
                          .sort((a, b) => {
                            if (isHasPriority) {
                              let _base = base;
                              if (base.id == selectedService.id) {
                                _base = selectedService;
                              }
                              if (_base.view[a.name] && _base.view[b.name]) {
                                return (
                                  _base.view[a.name].priority -
                                  _base.view[b.name].priority
                                );
                              } else {
                                debugger;
                                return 0;
                              }
                            } else return 0;
                          })
                          .map((item, key) => {
                            const that = this;
                            return (
                              <FormElement
                                formModel={_sModels.filter(
                                  (x) => !x.privateField
                                )}
                                item={item}
                                baseData={this.props.baseData}
                                base={base}
                                refTransaction={this.props.refTransaction}
                                preRefTransaction={this.props.preRefTransaction}
                                key={key}
                                _key={key}
                                formRef={this.getFormRef}
                                updateData={this.UpdateFormValue}
                                formOnChange={(formValue) => {
                                  this.setState({ formValue });
                                  if (isRefModelForm) {
                                    // handleUpdateRefData(formValue);
                                    handleUpdateRefData(
                                      formValue,
                                      this.form,
                                      _dynamicSchemaModel
                                    );
                                  }
                                }}
                                isDesigner={true}
                                activeElement={this.state.activeElement}
                                handleSetActiveElement={
                                  this.handleSetActiveElement
                                }
                                readOnly={
                                  readOnly ||
                                  (this.props.isListDetail
                                    ? this.props.itemOperation
                                    : selectedService.functionName) === "delete"
                                }
                                _typeDetails={_typeDetails}
                                formValue={formValue}
                                formValueOrj={currentValuesOrj}
                                loading={loading}
                                runTransaction={this.handleRunTransaction}
                                isShowTransaction={this.props.isShowTransaction}
                                showTransactionClose={
                                  this.props.showTransactionClose
                                }
                                updateBaseForm={this.props.updateBaseForm}
                                handleRunPreTransaction={
                                  this.props.handleRunPreTransaction
                                }
                              />
                            );
                          })}
                    </Row>
                  </Form>
                </Col>
              </Row>
            </div>
            {(!isRefModelForm || this.props.canUpdateDelete) && (
              <div className="modal-footer form-genarator-p">
                {
                  <FormGroup
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        // 👇️ your logic here
                        alert("Enter key pressed ✅");
                      }
                    }}
                  >
                    <ButtonToolbar className={"creator-toobar"}>
                      {(selectedService.functionName == "create" ||
                        selectedService.functionName == "update" ||
                        selectedService.functionName == "delete" ||
                        this.props.isListDetail) && (
                        <Button
                          disabled={loading}
                          loading={loading}
                          style={{ textTransform: "capitalize" }}
                          appearance="primary"
                          color={
                            (this.props.isListDetail
                              ? this.props.itemOperation
                              : selectedService.functionName) === "delete"
                              ? "red"
                              : (this.props.isListDetail
                                  ? this.props.itemOperation
                                  : selectedService.functionName) === "update"
                              ? "green"
                              : "cyan"
                          }
                          onClick={this.handleSubmit}
                        >
                          {this.props.isListDetail
                            ? this.props.itemOperation
                            : selectedService.functionName}
                        </Button>
                      )}

                      {!(
                        (this.props.isListDetail
                          ? this.props.itemOperation
                          : selectedService.functionName) == "delete" ||
                        (this.props.isListDetail
                          ? this.props.itemOperation
                          : selectedService.functionName) == "update"
                      ) && (
                        <Whisper
                          placement="auto"
                          trigger="hover"
                          speaker={<Tooltip>{t("TOOLTIP_CLEAR_FORM")}</Tooltip>}
                        >
                          <IconButton
                            disabled={loading}
                            loading={loading}
                            style={{ textTransform: "capitalize" }}
                            icon={<Icon icon="trash" />}
                            color="red"
                            circle
                            onClick={this.handleReset}
                            //appearance="ghost"
                          ></IconButton>
                        </Whisper>
                      )}

                      {_sService &&
                        [
                          ...(_sService.hasOwnProperty("extraButtons")
                            ? _sService.extraButtons.filter(
                                (x) =>
                                  x.runType != "TableActionModal" &&
                                  x.runType != "TableActionModalOnLoad" &&
                                  x.runType != "TableActionCurrent"
                              )
                            : []),
                        ].map((btn, key) => {
                          const _f = allServices.find(
                            (x) => x.id == btn.relTransactions.id
                          );

                          return (
                            <Button
                              key={key}
                              disabled={loading}
                              loading={loading}
                              style={{
                                textTransform: "capitalize",
                                marginRight: "5px",
                              }}
                              onClick={
                                () => {
                                  const _sTrans = allServices.find(
                                    (x) => x.id == btn.relTransactions.id
                                  );
                                  debugger;
                                  switch (btn.runType) {
                                    case "Modal":
                                      this.handleTransModal(_f, formValue);
                                      break;
                                    case "Current":
                                      if (_sTrans) {
                                        this.handleRunTransaction(
                                          _sTrans,
                                          formValue
                                        );
                                      }

                                      break;
                                    default:
                                      this.handleTransModal(_f, formValue);
                                      break;
                                  }
                                }
                                //this.handleRunTransaction(_f, formValue)
                              }
                            >
                              {btn.buttonCaptions[0].textValue}
                            </Button>
                          );
                        })}

                      {/**
                           <IconButton
                        disabled={loading}
                        loading={loading}
                        style={{ textTransform: "capitalize" }}
                        icon={<Icon icon="medium" />}
                        color="violet"
                        // onClick={this.handleTransModal}
                        onClick={() =>
                          this.handleRunTransaction(selectedService, formValue)
                        }
                        _formValue
                        //appearance="ghost"
                      ></IconButton>
                           */}

                      {/*
                                                <Button disabled={loading} appearance="default" onClick={this.handleCloseModal}>{t("BUTTON_CANCEL")}</Button>
                                            */}
                    </ButtonToolbar>
                  </FormGroup>
                }
              </div>
            )}
            {this.state.isTransModal && (
              <ShowTransactionModal
                baseData={formValue}
                refTransaction={this.state.refTransaction}
                isActive={this.state.isTransModal}
                handleToggle={this.handleTransModal}
                refFormValue={this.state.refFormValue}
                handleResetBaseForm={this.handleReset}
                updateBaseForm={(formValue) => {
                  debugger;
                  this.setState({
                    formValue,
                    formKey: new Date().toJSON(),
                    formValueUpdateTime: "krnz-" + new Date().toJSON(),
                  });
                  if (isRefModelForm) {
                    // handleUpdateRefData(formValue);
                    handleUpdateRefData(
                      formValue,
                      this.form,
                      _dynamicSchemaModel
                    );
                  }
                }}
              />
            )}

            {this.state.isInvoiceViewer && (
              <EasyInvoiceViewer
                isActive={this.state.isInvoiceViewer}
                handleToggle={this.handleInvoiceViewerModal}
              />
            )}

            {this.state.isConfirmModal && (
              <ConfirmModal
                isActive={this.state.isConfirmModal}
                handleToggle={this.handleConfirmModal}
                title={this.state.confirmTitle}
              />
            )}
          </>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer } = state;
  const {
    transaction,
    workspace,
    dataModels,
    selectedService,
    models,
    model,
    allServices,
    allTransactions,
    allTransList,
  } = workspaceReducer;

  return {
    workspace,
    transaction,
    dataModels,
    selectedService,
    selectedServicex: selectedService,
    allTransactions,
    models,
    model,
    allServices,
    allTransList,
  };
};

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(FormGenerator);
