import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";

import i18n from "i18n";

import { Container, Header, Content, Footer, Sidebar, Alert } from "rsuite";
import { Navbar, Nav, Dropdown, Icon, Sidenav } from "rsuite";

import ModelLayout from "./MVC/ModelLayout";
import ViewLayout from "./MVC/ViewLayout";
import ControllerLayout from "./MVC/ControllerLayout";
import { TransactionService } from "_services";

class MVCLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "Model",
      trans: null,
    };
  }

  getTransDetail = () => {
    const that = this;
    const {
      module,
      workspace,
      transaction,
      selectedService,
      updatedModels,
      setDynamicCollections,
      dynamicDatabase,
    } = this.props;
    if ( module && workspace && selectedService) {
      this.setState({ loading: true });
      TransactionService.GET_TRANSACTION_BYID(
        selectedService.id,
        workspace.id,
        module.id
      )
        .then((data) => {
          that.setState({
            trans: data.dynamicValue,
          });
          that.setState({ loading: false });
        })
        .catch((err) => {
          this.setState({ loading: false });
        });
    }
  };
  componentDidMount() {
    this.getTransDetail();
  }

  render() {
    const { activeTab,trans } = this.state;
    return (
      <Translation>
        {(t) => (
          <Container
            className={"custom-fade-in"}
            style={{ width: "100%", height: "100%" }}
          >
            <Header
              style={{
                minHeight: "56px",
              }}
            >
              <Nav
                appearance="tabs"
                justified
                activeKey={activeTab}
                onSelect={(eventKey) => {
                  this.setState({ activeTab: eventKey });
                }}
              >
                <Nav.Item eventKey={"Model"}>Model</Nav.Item>
                <Nav.Item eventKey={"View"}>View</Nav.Item>
                <Nav.Item eventKey={"Service"}>Service</Nav.Item>
              </Nav>
            </Header>

            <Content className={"mvc-div"}>
              {activeTab == "Model" ? (
                <ModelLayout trans={trans}/>
              ) : activeTab == "View" ? (
                <ViewLayout trans={trans}/>
              ) : activeTab == "Service" ? (
                <ControllerLayout trans={trans}/>
              ) : (
                <></>
              )}
            </Content>
          </Container>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer } = state;
  const {
    models,
    transaction,
    workspaces,
    workspace,
    selectedService,
    services,
    modules,
    module,
  } = workspaceReducer;

  return {
    workspaces,
    workspace,
    modules,
    module,
    selectedService,
    services,
    models,
    transaction,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MVCLayout);
