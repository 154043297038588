import React, { useRef, useState } from "react";
import { Menu, MenuItem, MenuButton, ControlledMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import { Button, Icon, IconButton } from "rsuite";

export default function CustomDropDown2(props) {
  const [isOpen, setOpen] = useState(false);

  const [portal, setPortal] = useState(true);
  const anchorRef = useRef(null);

  return (
    <div>
      <button
        className="rs-btn rs-btn-default rs-btn-icon custom-fade-in rs-btn-icon-placement-left"
        ref={anchorRef}
        onClick={(e) => {
          e.stopPropagation();
          setOpen(true);
        }}
      >
        <Icon icon={"ellipsis-v"} />
      </button>
      <ControlledMenu
        arrow
        state={isOpen ? "open" : "closed"}
        onClose={() => setOpen(false)}
        align="center"
        position="anchor"
        viewScroll="auto"
        anchorRef={anchorRef}
        portal={portal}
        direction={"left"}
      >
        {props.extraButtons &&
          props.extraButtons.map((btn, key) => (
            <div onClick={(e) => e.stopPropagation()}>
              <MenuItem
                onClick={(e) => {
                  props.customClick(btn);
                }}
                key={key}
              >
                {btn.buttonCaptions[0].textValue}
              </MenuItem>
            </div>
          ))}
      </ControlledMenu>
    </div>
  );
}
