import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translation } from "react-i18next";
import CropBox from '../GalleryModal/CropBox';

import {
    Button,
    Input,
    Modal,
    Container,
    Row, Col
} from "reactstrap";

class ImageCropper extends Component {

    constructor(props) {
        super(props)

        this.state = {

        }
    }




    render() {
        const { isActive, handleToggle, handleCropSuccess, isImageUploadLoading } = this.props;

        return (
            <Translation>
                {t => (
                    <Modal
                        className="modal-dialog-centered"
                        isOpen={isActive}
                        size="lg"
                        scrollable={true}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title" id="CheckProjectModal">
                                {t("MODAL_IMAGE_EDITOR_TITLE")}
                            </h5>
                            <button
                                aria-label="Close"
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                disabled={isImageUploadLoading}
                                onClick={() => handleToggle()}
                            >
                                <span aria-hidden={true}>×</span>
                            </button>
                        </div>
                        <div className="modal-body">

                            <Container>
                                <CropBox
                                    uploadId={this.props.uploadId}
                                    isSquare={this.props.isSquare}
                                    currentImage={this.props.currentImage}
                                    handleSuccess={handleCropSuccess}
                                    handleToggleBack={handleToggle}
                                />
                               
                            </Container>

                        </div>

                    </Modal>
                )}
            </Translation>
        )
    }
}

 
const mapStateToProps = (state, props) => {
    const { currentstatesReducer } = state;
    const { isImageUploadLoading } = currentstatesReducer;

    return {
        isImageUploadLoading,
    };

}
const mapDispatchToProps = {
   
}
export default connect(mapStateToProps, mapDispatchToProps)(ImageCropper)
