import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import i18n from "i18n";
import Emitter from "_actions/emitter";
import { Tree, Input, InputGroup, Icon, Button, Alert, Loader } from "rsuite";
import {
  updateSelectedService,
  updateSelectedTransaction,
  updatedModels,
  updatedTransactions,
  updateSelectedModel,
  updateAllTransactions,
} from "_actions";
import { findNestedObj } from "assets/util";
import CreateDataModel from "components/Modals/CreateDataModel";
import { ModelsService, TransactionService } from "_services";

const WAIT_INTERVAL = 200;

class TransactionsTree extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      isCreateDataModel: false,
      loading: false,
    };
  }

  getAllTransAndModels = () => {
    const that = this;

    const { workspace, updateAllTransactions } = this.props;
    if (workspace) {
      this.setState({ loading: true }); //IsOrderByDesc,LastIndexId,Count,SearchTerm,DynamicObj
      ModelsService.STEPBYLOAD_W_GROUP_W_WORKSPACE(
        false,
        "-1",
        9999,
        "",
        workspace.id
      )
        .then((data) => {
          let _list = []; //data.dynamicValue.slice(0);
          let _trans = [];

          let _objList = {};
          data.dynamicValue.forEach((element, key) => {
            let _el = {
              ...element.model,
              label: element.model.modelName,
              value: element.model.id,
              children: [],
              isService: true,
            };
            element.transActions.forEach((item) => {
              let _view = {};
              if (item.viewCustom) {
             
                _view = JSON.parse(item.viewCustom)
              }else{
                _el.dataTypes.map((item,__key) => {
                  _view[item.name] = {
                    visible: true,
                    col: 12,
                    priority: __key,
                  };
                });
              }
              _el.children.push({
                ...item,
                label: item.name,
                value: item.id,
                refId: _el.id,
                dataModelID: "",
                type: 0,
                model: [..._el.dataTypes],
                baseModelData:element.model,
                view: _view,
                controller: "",
                script: {
                  params: [],
                  code: "",
                },
              });
              _trans.push({
                ...item,
                label: item.name,
                value: item.id,
                refId: _el.id,
                dataModelID: "",
                type: 0,
                baseModelData:element.model,
                model: [..._el.dataTypes],
                view: _view,
                controller: "",
                script: {
                  params: [],
                  code: "",
                },
              });
              _objList[item.executableTransactionServiceName+"_"+item.functionName] = {
                ...item,
                label: item.name,
                value: item.id,
                refId: _el.id,
                dataModelID: "",
                type: 0,
                model: [..._el.dataTypes],
                baseModelData:element.model,
                view: _view,
                controller: "",
                script: {
                  params: [],
                  code: "",
                },
              };
            });
             _list.push(_el);
           
          });

          updateAllTransactions(data.dynamicValue, _trans,_objList);

          
          this.setState({ loading: false });
        })
        .catch((err) => {
          debugger;
          Alert.error("Error code : " + err.statusCode);
          this.setState({ loading: false });
        });
    }
  };

  componentDidMount() {
    this.getTransCollections();
    Emitter.on("CHANGE_MODULE", (newValue) => this.getTransCollections());

    const { workspace, selectedService, dataModels, allTransactions } =
      this.props;
    if (!allTransactions.length > 0) {
      this.getAllTransAndModels();
    }
    Emitter.on("UPDATE_ALL_TRANS", (newValue) => this.getAllTransAndModels());
  }

  componentWillUnmount = () => {
    Emitter.off("CHANGE_MODULE");
    Emitter.off("UPDATE_ALL_TRANS");
  };

  searchServices = (eleman) => {
    return (
      eleman.label.toLowerCase() +
      " " +
      eleman.shortName.toLowerCase()
    ).includes(this.state.search.toLowerCase());
  };

  handleCreateDataModel = () => {
    this.setState({
      isCreateDataModel: !this.state.isCreateDataModel,
    });
  };

  createNewCollection = (_data) => {
    const that = this;
    const {
      module,
      dynamicCollections,
      setDynamicCollections,
      dynamicDatabase,
    } = this.props;
    if (module) {
      this.setState({ loading: true });
      ModelsService.CREATE_MODEL(
        module.id,
        _data.collectionName,
        _data.isDatabaseObject,
        _data.dataTypes,
        [],
        [], //ExtraButtons
        _data.isDataBaseFalseObjectAllowTransaction
      )
        .then((data) => {
          /* let _cols = Object.assign({}, dynamicCollections);
                     _cols.dynamicValue.push(data.dynamicValue);
                     setDynamicCollections(_cols);
 */
          Alert.success(i18n.t("SUCCESS_CREATED_COLLECTION"));
          that.setState({ loading: false });
        })
        .catch((err) => {
          debugger;
          Alert.error("Error code : " + err.statusCode);
          this.setState({ loading: false });
        });
    }
  };

  getAllModels = async () => {
    const that = this;
    const {
      modules,
      module,
      updatedModels,
      updateSelectedModel,
      updatedTransactions,
    } = this.props;
    let _list = [];
    await modules.dynamicValue.forEach(async (e) => {
      await ModelsService.STEPBYLOAD(false, "-1", 9999, "", e.id)
        .then(async (data) => {
          await data.dynamicValue.dynamicValue.forEach((item) => {
            /*
              _list.push({
                moduleWModel: element.modelName + " - (" + e.name + ")",
                ...element,
              });
            */
            /*  let _el = {
                ...element.model,
                label: element.model.modelName,
                value: element.model.id,
                children: [],
                isService: true,
              };
              */
            let _el = {};
            let _view = {};
            if (item.viewCustom) {
           
              _view = JSON.parse(item.viewCustom)
            }else{
              _el.dataTypes.map((item,__key) => {
                _view[item.name] = {
                  visible: true,
                  col: 12,
                  priority: __key,
                };
              });
            }
            _list.push({
              ...item,
              label: item.name,
              value: item.id,
              refId: _el.id,
              dataModelID: "",
              type: 0,
              model: [..._el.dataTypes],
           
              view: _view,
              controller: "",
              script: {
                params: [],
                code: "",
              },
            });
          });
        })
        .catch((err) => {
          Alert.error("Error code : " + err.statusCode);
          this.setState({ loading: false });
        });
    });
  };
  getTransCollections = () => {
    const that = this;
   // this.getAllModels();
    const { module, updatedModels, updateSelectedModel, updatedTransactions } =
      this.props;
    if (module) {
      this.setState({ loading: true });
      ModelsService.STEPBYLOAD_W_GROUP(false, "-1", 9999, "", module.id)
        .then((data) => {
          let _list = []; //data.dynamicValue.slice(0);
          let _trans = [];

          data.dynamicValue.forEach((element, key) => {
            let _el = {
              ...element.model,
              label: element.model.modelName,
              value: element.model.id,
              children: [],
              isService: true,
            };
            element.transActions.forEach((item) => {
              let _view = {};
              if (item.viewCustom) {
             
                _view = JSON.parse(item.viewCustom)
              }else{
                _el.dataTypes.map((item,__key) => {
                  _view[item.name] = {
                    visible: true,
                    col: 12,
                    priority: __key,
                  };
                });
              }
              _el.children.push({
                ...item,
                label: item.name,
                value: item.id,
                refId: _el.id,
                dataModelID: "",
                type: 0,
                model: [..._el.dataTypes],
                baseModelData:element.model,
                view: _view,
                controller: "",
                script: {
                  params: [],
                  code: "",
                },
              });
              _trans.push({
                ...item,
                label: item.name,
                value: item.id,
                refId: _el.id,
                dataModelID: "",
                type: 0,
                model: [..._el.dataTypes],
                baseModelData:element.model,
                view: _view,
                controller: "",
                script: {
                  params: [],
                  code: "",
                },
              });
            });
            _list.push(_el);
          });

          //data.dynamicValue = _list;

          updatedTransactions({
            dynamicValue: _trans,
          });

          updatedModels({
            dynamicValue: _list,
          });

          that.setState({ loading: false });
       //   that.getAllTransAndModels();
        })
        .catch((err) => {
          debugger;
          Alert.error("Error code : " + err.statusCode);
          this.setState({ loading: false });
        });
    }
  };

  handleChange = (value) => {
    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      this.triggerChange(value);
    }, WAIT_INTERVAL);
  };

  triggerChange = (value) => {
    this.setState({
      search: value,
    });
  };

  componentWillMount() {
    this.timer = null;
  }

  render() {
    const data = [...this.props.transactions.dynamicValue];
    return (
      <Translation>
        {(t) => (
          <div className={"service-tree-layout"}>
            <div
              style={{
                marginBottom: "10px",
                paddingRight: "1em",
              }}
            >
              <InputGroup inside>
                <Input
                  size="md"
                  placeholder="Search..."
                  onChange={(value, event) => {
                    this.handleChange(value);
                  }}
                />
                <InputGroup.Button>
                  <Icon icon="search" />
                </InputGroup.Button>
              </InputGroup>
            </div>

            <div className={"service-tree-nodes"}>
              <Tree
                className={"trans-tree"}
                defaultValue={
                  this.props.selectedService && this.props.selectedService.value
                }
                data={data.filter(this.searchServices)}
                defaultExpandAll
                onSelect={(activeNode, value, event) => {
                  const fData = findNestedObj(data, "value", value);
                  this.props.updateSelectedService(fData);
                }}
                renderTreeNode={(nodeData) => {
                 
                  return (
                    <div className={"trans-tree-node custom-fade-in"}>
                      <span className={"trans-tree-node-name"}>
                        {" "}
                        {nodeData.label}{" "}
                      </span>
                      <span className={"trans-tree-node-code"}>
                        {nodeData.shortName}
                      </span>
                    </div>
                  );
                }}
              />
            </div>

            {this.state.loading && (
              <div className={"fade-in"}>
                <Loader backdrop vertical />
              </div>
            )}
          </div>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer } = state;
  const {
    workspaces,
    workspace,
    selectedService,
    services,
    modules,
    module,
    transactions,
    allTransactions,
  } = workspaceReducer;

  return {
    workspaces,
    workspace,
    modules,
    module,
    selectedService,
    services,
    transactions,
    allTransactions,
  };
};

const mapDispatchToProps = {
  updateSelectedService,
  updatedModels,
  updateSelectedTransaction,
  updateSelectedModel,
  updatedTransactions,
  updateAllTransactions,
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsTree);
