import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import i18n from "i18n";
import { ErpEngineService, ModelsService } from "_services";
import { Modal, Button, ButtonToolbar, Alert, Dropdown, Loader } from "rsuite";
import moment from "moment";
import DynamicTable from "../DynamicTable";

import CreateTransaction from "components/Modals/CreateTransaction";
import UpdateTransaction from "components/Modals/UpdateTransaction";

import FormGenerator from "components/Transaction/FormGenerator";
import ListGenerator from "components/Transaction/ListGenerator";
import UpdateDeleteFormGenerator from "components/Transaction/UpdateDeleteFormGenerator";
import { enqueueSnackbar } from "notistack";

import {
  updateSelectedService,
  updateSelectedTransaction,
  updatedModels,
  updatedTransactions,
  updateSelectedModel,
  updateAllTransactions,
} from "_actions";
import EasyInvoiceViewer from "components/Modals/EasyInvoiceViewer";
import ConfirmModal from "components/Modals/ConfirmModal";
import { decamelize } from "assets/globalOps/global";

class ShowTransactionModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      data: [],
      isCreateTrans: false,
      isUpdateTrans: false,
      formValue: {},
      updatedTime: new Date().toJSON(),
      isInvoiceViewer: false,

      isConfirmModal: false,
      confirmTitle: "",

      refTransaction: null,
      isTransModal: false,
    };
  }

  handleTransModal = (refTransaction = null, formValue, status) => {
    debugger;
    this.setState({
      refTransaction: refTransaction,
      isTransModal: status == undefined ? !this.state.isTransModal : status,
      refFormValue: formValue,
    });
  };

  handleConfirmModal = (title = "") => {
    this.setState({
      confirmTitle: title,
      isConfirmModal: !this.state.isConfirmModal,
    });
  };

  handleInvoiceViewerModal = () => {
    this.setState({
      isInvoiceViewer: !this.state.isInvoiceViewer,
    });
  };

  handleUpdateTrans = () => {
    this.setState({
      isUpdateTrans: !this.state.isUpdateTrans,
    });
  };

  handleCreateTrans = () => {
    this.setState({
      isCreateTrans: !this.state.isCreateTrans,
    });
  };

  componentDidMount = () => {
    const { refTransaction, refButton } = this.props;
    this.setState(
      { formValue: this.props.baseData, updatedTime: new Date().toJSON() },
      () => {
        if (
          refTransaction &&
          refTransaction.responseModelType == 0 &&
          refButton
        ) {
          if (refButton.runType == "TableActionModalOnLoad") {
            this.handlePostValidatedForm(true);
          }
        }
      }
    );

    if (refTransaction && refTransaction.responseModelType == 1) {
      debugger;
      this.handleReadData();
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { isActive, formValue, refButton } = this.props;
    const { refFormValue } = this.props;
    if (isActive && isActive !== prevProps.isActive) {
      debugger;
      this.setState({
        formValue: refFormValue,
      });
    }
  }

  getCurrentLangDisplayName = (item, alternative) => {
    try {
      const _lang = this.props.currentLang;
      if (_lang) {
        let _langDNM = item.find((x) => x.lang == _lang);
        if (_langDNM && _langDNM.textValue.trim().length > 0) {
          return _langDNM.textValue;
        } else {
          _langDNM = item.find((x) => x.lang == "en");
          return _langDNM.textValue;
        }
      } else {
        const _langDNM = item.find((x) => x.lang == "en");
        return _langDNM.textValue;
      }
    } catch (error) {
      debugger;
      return item.length > 0 ? item[0].textValue : decamelize(alternative, " ");
    }
  };
  UpdateFormValue = (formValue) => {
    debugger;

    this.setState({ formValue: formValue, updatedTime: new Date().toJSON() });
  };

  prepareGenerateSchema = () => {
    return null;
  };
  preTransactionFunc = (data, _selectedService, _formValue) => {
    const {
      isRunTransModal,
      runTransModalClose,
      updateBaseForm,
      handleRunPreTransaction,
    } = this.props;
    const _dynamicSchemaModel = this.prepareGenerateSchema();

    const that = this;

    const runTransaction = that.runTransaction;
    const transactions = this.props.allTransList;
    const Toast = this.ToastMessage;
    const SnackToast = this.SnackMessage;
    const easyinvoice = require("easyinvoice").default; //easyinvoice.download('myInvoice.pdf', data.InvoiceBinary);
    const showInvoiceModal = this.handleInvoiceViewerModal;
    const showConfirmModal = this.handleConfirmModal;
    const runPreTransaction = () => {
      if (isRunTransModal && handleRunPreTransaction) {
        handleRunPreTransaction();
      }
    };

    const updatePreForm = (formValue) => {
      if (isRunTransModal) {
        //debugger;
        updateBaseForm(formValue);
      } else {
        this.setState({ formValue });
        if (this.props.isShowTransaction) {
          this.props.updateBaseForm(formValue);
        } else if (this.props.isRefModelForm) {
          // handleUpdateRefData(formValue);
          that.handleUpdateRefData(formValue, this.form, _dynamicSchemaModel);
        } else {
        }
      }
    };
    const closeModal = () => {
      //  debugger;
      if (this.props.isShowTransaction) {
        this.props.showTransactionClose();
      } else if (isRunTransModal) {
        //debugger;
        runTransModalClose();
      } else {
        that.setState({
          isTransModal: false,
        });
      }
    };

    let { formData, model } = this.transactionPrepareFunc();
    const updateData = that.UpdateFormValue;

    let result = null;
    if (
      _selectedService.hasOwnProperty("preScript") &&
      _selectedService.preScript
    ) {
      try {
        if (_selectedService.preScript.includes("await ")) {
          result = eval(
            "(async function() => { " + _selectedService.preScript + "})()"
          );
        } else {
          result = eval("(function() {" + _selectedService.preScript + "}())");
        }
      } catch (error) {
        debugger;
      }
    }

    try {
      // debugger;
      if (result) {
        return result;
      }
      return data;
    } catch (error) {
      return data;
    }
  };
  ToastMessage = (msg, type) => {
    try {
      switch (type) {
        case "success":
          Alert.success(msg);
          break;
        case "error":
          Alert.error(msg);
          break;
        case "info":
          Alert.info(msg);
          break;
        case "warning":
          Alert.warning(msg);
          break;
        default:
          Alert.info(msg);
          break;
      }
    } catch (error) {
      Alert.info(msg);
    }
  };
  SnackMessage = (msg, type) => {
    debugger
    try {
      debugger
      enqueueSnackbar(msg, { variant: type });
    } catch (error) {
      debugger
      enqueueSnackbar(msg);
    }
  };
  transactionPrepareFunc = () => {
    const { formValue } = this.state;
    const { refTransaction, model, isRefModelForm, refModel } = this.props;

    let selectedService = refTransaction;
    const _sModels = model
      ? model.find(
          (x) =>
            x.id ===
            (isRefModelForm
              ? refModel.id
              : selectedService.responseModelType
              ? selectedService.relResponseModelId
              : selectedService.reqModelId)
        ).dataTypes
      : (isRefModelForm ? refModel : selectedService)
      ? isRefModelForm
        ? refModel.dataTypes
        : selectedService.model
      : [];
    return {
      formData: JSON.parse(JSON.stringify(formValue)),
      model: _sModels,
    };
  };

  postTransactionFunc = (
    status,
    response,
    responseModel,
    _selectedService,
    _sModels,
    isOnLoad
  ) => {
    const { isRunTransModal, runTransModalClose, updateBaseForm, baseData } =
      this.props;

    const _dynamicSchemaModel = this.prepareGenerateSchema();

    const that = this;
    const runTransaction = that.runTransaction;
    const transactions = this.props.allTransList;
    const Toast = this.ToastMessage;
    const SnackToast = this.SnackMessage;

    const runPreTransaction = () => {
      debugger;
      if (that.props.isRunTransModal && that.props.handleRunPreTransaction) {
        that.props.handleRunPreTransaction();
      }
    };

    const updatePreForm = (formValue) => {
      if (isRunTransModal) {
        //  debugger;
        updateBaseForm(formValue);
      } else {
        this.setState({ formValue });
        if (this.props.isShowTransaction) {
          this.props.updateBaseForm(formValue);
        } else if (this.props.isRefModelForm) {
          // handleUpdateRefData(formValue);
          that.handleUpdateRefData(formValue, this.form, _dynamicSchemaModel);
        } else {
        }
      }
    };
    const closeModal = () => {
      //  debugger;
      if (this.props.isShowTransaction) {
        this.props.showTransactionClose();
      } else if (isRunTransModal) {
        //  debugger;
        runTransModalClose();
      } else {
        that.setState({
          isTransModal: false,
        });
      }
    };

    const easyinvoice = require("easyinvoice").default; //easyinvoice.download('myInvoice.pdf', data.InvoiceBinary);
    const showInvoiceModal = this.handleInvoiceViewerModal;
    const showConfirmModal = this.handleConfirmModal;

    let { formData, model } = this.transactionPrepareFunc();
    const updateData = that.UpdateFormValue;
    //  debugger;
    let __result = null;

    if (
      _selectedService.hasOwnProperty("postScript") &&
      _selectedService.postScript
    ) {
      try {
        if (_selectedService.postScript.trim().length > 0 && status) {
          if (_selectedService.postScript.includes("await ")) {
            __result = eval(
              "(async function() => { " + _selectedService.postScript + "})()"
            );
          } else {
            __result = eval(
              "(function() {" + _selectedService.postScript + "}())"
            );
          }
        }

        if (that.props.handleToggle && status && !isOnLoad) {
          that.handleReset();
          Alert.success(i18n.t("MESSAGE_TRANSACTION_SUBMIT_SUCCESS"));
          that.props.handleToggle();
        }
        if (status && isOnLoad) {
          debugger;
          if (
            (!_selectedService.postScript ||
              _selectedService.postScript.trim().length == 0) &&
            status
          ) {
            if (_selectedService.responseModelType === 0) {
              let _myValues = {};
              model.map((x) => {
                if (response.hasOwnProperty(x.name)) {
                  _myValues[x.name] = response[x.name];
                } else if (formData.hasOwnProperty(x.name)) {
                  _myValues[x.name] = formData[x.name];
                } else {
                }
              });

              that.setState({
                formValue: { ...that.state.formValue, ..._myValues },
                updatedTime: new Date().toJSON(),
              });
            }
          }
        }
      } catch (error) {
        if (that.props.handleToggle && status && !isOnLoad) {
          that.handleReset();
          Alert.success(i18n.t("MESSAGE_TRANSACTION_SUBMIT_SUCCESS"));
          that.props.handleToggle();
        }
        debugger;
      }
    } else {
      if (that.props.handleToggle && status && !isOnLoad) {
        that.handleReset();
        Alert.success(i18n.t("MESSAGE_TRANSACTION_SUBMIT_SUCCESS"));
        that.props.handleToggle();
      }
    }
  };

  getAllTransAndModels = () => {
    const that = this;
    //this.getAllModels();
    const {
      workspace,
      updateAllTransactions,
      updateSelectedModel,
      updatedTransactions,
    } = this.props;
    if (workspace) {
      this.setState({ loading: true }); //IsOrderByDesc,LastIndexId,Count,SearchTerm,DynamicObj
      ModelsService.STEPBYLOAD_W_GROUP_W_WORKSPACE(
        false,
        "-1",
        9999,
        "",
        workspace.id
      )
        .then((data) => {
          let _list = []; //data.dynamicValue.slice(0);
          let _trans = [];
          let _objList = {};
          data.dynamicValue.forEach((element, key) => {
            let _el = {
              ...element.model,
              label: element.model.modelName,
              value: element.model.id,
              children: [],
              isService: true,
            };
            element.transActions.forEach((item) => {
              let _view = {};
              //debugger
              if (item.viewCustom) {
                debugger;
                _view = JSON.parse(item.viewCustom);
              } else {
                _el.dataTypes.map((item) => {
                  _view[item.name] = {
                    visible: true,
                    col: 12,
                  };
                });
              }
              _el.children.push({
                ...item,
                label: item.name,
                value: item.id,
                refId: _el.id,
                dataModelID: "",
                type: 0,
                model: [..._el.dataTypes],
                view: _view,
                controller: "",
                script: {
                  params: [],
                  code: "",
                },
              });
              _trans.push({
                ...item,
                label: item.name,
                value: item.id,
                refId: _el.id,
                dataModelID: "",
                type: 0,
                model: [..._el.dataTypes],
                view: _view,
                controller: "",
                script: {
                  params: [],
                  code: "",
                },
              });
              _objList[
                item.executableTransactionServiceName + "_" + item.functionName
              ] = {
                ...item,
                label: item.name,
                value: item.id,
                refId: _el.id,
                dataModelID: "",
                type: 0,
                model: [..._el.dataTypes],
                view: _view,
                controller: "",
                script: {
                  params: [],
                  code: "",
                },
              };
            });
            _list.push(_el);
          });

          updateAllTransactions(data.dynamicValue, _trans, _objList);
          this.setState({ loading: false });
        })
        .catch((err) => {
          debugger;
          Alert.error("Error code : " + err.statusCode);
          this.setState({ loading: false });
        });
    }
  };

  //STEPBYLOAD_W_GROUP_W_WORKSPACE
  handleReadData = () => {
    const { workspace, refTransaction } = this.props;
    const URL_MODE = "dev_URL";
    const that = this;
    this.setState({ loading: true });
    ErpEngineService.EXECUTE_TRANSACTION_DIRECTLY(
      workspace[URL_MODE],
      "read",
      refTransaction.executableTransactionServiceName,
      "{}"
    )
      .then((data) => {
        if (data.operationResult) {
          this.setState({
            data: data.dynamicValue,
          });
        }
        that.setState({ loading: false });
      })
      .catch((err) => {
        that.setState({ loading: false });
        Alert.error(i18n.t("MESSAGE_TRANSACTION_READ_UNSUCCESS"));
        debugger;
      });
  };

  handleSubmit = (e) => {
    const { formValue, formError, form, shema } = this.state;
    const that = this;
    try {
      debugger;
      form.state.formValue = formValue;
      if (!shema.check(formValue)) {
        Alert.error("Form not validated");
        debugger;
        return;
      }
      /*
      if (!form.check()) {
        Alert.error("Form not validated");
        return;
      }
*/
      //this.props.handleUpdateRow(this.state.formValue);
      //  return
      this.handlePostValidatedForm();
    } catch (error) {
      debugger;

      this.handlePostValidatedForm();
    }

    //Alert.success("Form validated");
  };

  handlePostValidatedForm = (isOnLoad) => {
    const {
      workspace,
      allServices,
      isActive,
      handleToggle,
      handleSelect,
      refTransaction,
      baseData,
      model,
    } = this.props;
    const that = this;
    let selectedService = Object.assign({}, refTransaction);

    selectedService =
      selectedService.transactionButton && !isOnLoad
        ? allServices.find(
            (x) =>
              x.id ==
              (selectedService.transactionButton
                ? selectedService.transactionButton
                : selectedService.id)
          )
        : refTransaction;

    const { formValue, formError, form } = this.state;
    const URL_MODE = "dev_URL";
    const _body = JSON.stringify(
      this.preTransactionFunc(formValue, selectedService, formValue)
    );
    this.setState({ loading: true });
    debugger;
    ErpEngineService.EXECUTE_TRANSACTION_DIRECTLY(
      workspace[URL_MODE],
      this.props.isListDetail
        ? this.props.itemOperation
        : selectedService.functionName,
      selectedService.executableTransactionServiceName,
      _body
    )
      .then((data) => {
        that.postTransactionFunc(
          data.operationResult,
          data?.dynamicValue,
          "mantar",
          selectedService,
          selectedService,
          isOnLoad
        );

        that.setState({ loading: false });
      })
      .catch((err) => {
        that.setState({ loading: false });
        try {
          Alert.error(
            Array.isArray(err.failText) ? err.failText.join(". ") : err.failText
          );
        } catch (error) {
          Alert.error(i18n.t("MESSAGE_TRANSACTION_SUBMIT_UNSUCCESS"));
        }

        // let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
        debugger;
      });
  };

  handleReset = () => {
    this.setState({
      loading: false,
      data: [],
      isCreateTrans: false,
      isUpdateTrans: false,
      formValue: {},
    });
  };

  handleRunTransaction = (_selectedService, _formValue) => {
    const { formValue } = this.state;
    const {
      workspace,
      onSubmit,
      selectedService,
      model,
      isRefModelForm,
      refModel,
    } = this.props;

    const URL_MODE = "dev_URL";
    const that = this;

    const _sModels = model
      ? model.find(
          (x) =>
            x.id ===
            (isRefModelForm
              ? refModel.id
              : selectedService.responseModelType
              ? selectedService.relResponseModelId
              : selectedService.reqModelId)
        ).dataTypes
      : (isRefModelForm ? refModel : selectedService)
      ? isRefModelForm
        ? refModel.dataTypes
        : selectedService.model
      : [];

    this.setState({ loading: true });

    const _preFunc = this.preTransactionFunc(
      _formValue,
      _selectedService,
      _formValue
    );

    if (_preFunc.status) {
      const _body = JSON.stringify(_preFunc.body);
      debugger;
      that.setState({ loading: true });
      ErpEngineService.EXECUTE_TRANSACTION_DIRECTLY(
        workspace[URL_MODE],
        this.props.isListDetail
          ? this.props.itemOperation
            ? this.props.itemOperation
            : _selectedService.functionName
          : _selectedService.functionName,
        _selectedService.executableTransactionServiceName,
        _body
      )
        .then((data) => {
          that.postTransactionFunc(
            data.operationResult,
            data.dynamicValue,
            "amntar",
            _selectedService,
            _sModels
          );

          that.setState({ loading: false });
        })
        .catch((err) => {
          debugger;
          that.setState({ loading: false });
          try {
            Alert.error(
              Array.isArray(err.failText)
                ? err.failText.join(". ")
                : err.failText
            );
          } catch (error) {
            Alert.error(i18n.t("MESSAGE_TRANSACTION_SUBMIT_UNSUCCESS"));
          }
          // let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
        });
    } else {
      this.setState({ loading: false });
    }
  };

  render() {
    const {
      allServices,
      isActive,
      handleToggle,
      handleSelect,
      refTransaction,
      baseData,
      model,
      refFormValue,
    } = this.props;

    let selectedService = Object.assign({}, refTransaction);
    let _refSelectedService = allServices.find(
      (x) => x.id == selectedService.transactionButton
    );

    // const _myModel = model.find((x) => x.id == selectedService.relBaseModelId);

    if (isActive) {
    //  debugger;
    }

    const that = this;
    const { data, loading, formValue } = this.state;
    const _date = moment(new Date()).format("YYYY-MM-DD_h-mm");

    const _fm = model.find(
      (x) =>
        x.id ==
        (selectedService.responseModelType
          ? selectedService.relResponseModelId
          : selectedService.reqModelId)
    );
    const _hasList = _fm?.dataTypes.find(
      (x) =>
        (x.contentType == 5 && x.relationModel.relationType) ||
        (x.contentType == 10 && x.refModel.relationType)
    );

    return (
      <Translation>
        {(t) => (
          <>
            {selectedService && (
              <Modal
                size={
                  selectedService.responseModelType || _hasList ? "lg" : "sm"
                }
                show={isActive}
                onHide={handleToggle}
              >
                <Modal.Header>
                  <Modal.Title>
                    {isActive &&
                      this.getCurrentLangDisplayName(
                        selectedService.description,
                        selectedService.name
                          ? selectedService.name.split("-")[0]
                          : ""
                      )}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ paddingBottom: 0 }}>
                  <>
                    {selectedService && isActive && (
                      <>
                        {selectedService.responseModelType ? (
                          <DynamicTable
                            classname={"view-table"}
                            handleSelect={(_data) => {}}
                            data={data}
                            dataModel={selectedService.model}
                            isSearchable={true}
                          />
                        ) : selectedService.functionName === "update" ||
                          selectedService.functionName === "delete" ? (
                          <UpdateDeleteFormGenerator />
                        ) : (
                          <div key={this.state.updatedTime}>
                            <FormGenerator
                              isRefModelForm={true}
                              isListDetail={true}
                              isShowTransaction={true}
                              showTransactionClose={handleToggle}
                              updateBaseForm={this.props.updateBaseForm}
                              readOnly={false}
                              currentValues={
                                formValue && Object.keys(formValue).length > 0
                                  ? formValue
                                  : refFormValue
                              }
                              currentValuesOrj={baseData}
                              refModel={model.find(
                                (x) =>
                                  x.id ==
                                  (selectedService.responseModelType
                                    ? selectedService.relResponseModelId
                                    : selectedService.reqModelId)
                              )}
                              refTransaction={selectedService}
                              handleUpdateRefData={(_data, form, _shema) => {
                                debugger;
                                const merged = Object.assign(
                                  {},
                                  this.state.formValue,
                                  _data
                                );

                                this.setState({
                                  formValue: merged,
                                  form: form,
                                  //updatedTime:new Date().toJSON() ,
                                  shema: _shema,
                                });
                              }}
                            />
                            <div className="modal-footer form-genarator-p">
                              <ButtonToolbar className={"creator-toobar"}>
                                <Button
                                  disabled={loading}
                                  loading={loading}
                                  style={{ textTransform: "capitalize" }}
                                  appearance="primary"
                                  color={
                                    selectedService.functionName === "delete"
                                      ? "red"
                                      : selectedService.functionName ===
                                        "update"
                                      ? "green"
                                      : "cyan"
                                  }
                                  onClick={this.handleSubmit}
                                >
                                  {isActive &&
                                    this.getCurrentLangDisplayName(
                                      (_refSelectedService
                                        ? _refSelectedService
                                        : selectedService
                                      ).description,
                                      (_refSelectedService
                                        ? _refSelectedService
                                        : selectedService
                                      ).name.split("-")[0]
                                    )}
                                </Button>

                                {/*
                                _myModel &&
                                  [
                                    ...(_myModel.hasOwnProperty("extraButtons")
                                      ? _myModel.extraButtons.filter(
                                          (x) =>
                                            x.runType != "TableActionModal" &&
                                            x.runType !=
                                              "TableActionModalOnLoad" &&
                                            x.runType != "TableActionCurrent"
                                        )
                                      : []),
                                  ].map((btn, key) => {
                                    const _f = allServices.find(
                                      (x) => x.id == btn.relTransactions.id
                                    );

                                    return (
                                      <Button
                                        key={key}
                                        disabled={loading}
                                        loading={loading}
                                        style={{
                                          textTransform: "capitalize",
                                          marginRight: "5px",
                                        }}
                                        onClick={
                                          () => {
                                            const _sTrans = allServices.find(
                                              (x) =>
                                                x.id == btn.relTransactions.id
                                            );
                                            debugger;
                                            switch (btn.runType) {
                                              case "Modal":
                                                this.handleTransModal(
                                                  _f,
                                                  formValue
                                                );
                                                break;
                                              case "Current":
                                                if (_sTrans) {
                                                  this.handleRunTransaction(
                                                    _sTrans,
                                                    formValue
                                                  );
                                                }

                                                break;
                                              default:
                                                this.handleTransModal(
                                                  _f,
                                                  formValue
                                                );
                                                break;
                                            }
                                          }
                                          //this.handleRunTransaction(_f, formValue)
                                        }
                                      >
                                        {btn.buttonCaptions[0].textValue}
                                      </Button>
                                    );
                                  })
                                  
                                  */}
                              </ButtonToolbar>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                    {loading && (
                      <div className={"fade-in"}>
                        <Loader backdrop vertical />
                      </div>
                    )}
                  </>
                </Modal.Body>
              </Modal>
            )}

            <CreateTransaction
              onRefresh={this.onRefresh}
              isActive={this.state.isCreateTrans}
              handleToggle={this.handleCreateTrans}
            />
            <UpdateTransaction
              onRefresh={this.onRefresh}
              // script={this.state.script}
              isActive={this.state.isUpdateTrans}
              handleToggle={this.handleUpdateTrans}
            />
            {this.state.isInvoiceViewer && (
              <EasyInvoiceViewer
                isActive={this.state.isInvoiceViewer}
                handleToggle={this.handleInvoiceViewerModal}
              />
            )}

            {this.state.isConfirmModal && (
              <ConfirmModal
                isActive={this.state.isConfirmModal}
                handleToggle={this.handleConfirmModal}
                title={this.state.confirmTitle}
              />
            )}

            {this.state.isTransModal && (
              <ShowTransactionModal
                baseData={formValue}
                refTransaction={this.state.refTransaction}
                isActive={this.state.isTransModal}
                handleToggle={this.handleTransModal}
                refFormValue={this.state.refFormValue}
                handleResetBaseForm={this.handleReset}
                updateBaseForm={(formValue) => {
                  debugger;
                  this.setState({
                    formValue,
                    formKey: new Date().toJSON(),
                    formValueUpdateTime: "krnz-" + new Date().toJSON(),
                  });
                }}
              />
            )}
          </>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer } = state;
  const {
    model,
    models,
    transaction,
    transactions,
    workspaces,
    workspace,
    dataModels,
    selectedService,
    services,
    allServices,
    currentLang,
  } = workspaceReducer;

  return {
    workspace,
    transaction,
    transactions,
    workspaces,
    dataModels,
    models,
    model,
    selectedService,
    services,
    allServices,
    currentLang,
  };
};

const mapDispatchToProps = {
  updateSelectedService,
  updateSelectedTransaction,
  updatedModels,
  updateSelectedModel,
  updatedTransactions,
  updateAllTransactions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShowTransactionModal);
