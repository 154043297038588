import {
  //ServiceURLs,
  UserEndPoints,
  GetBaseConnectURL,
  MakeReqestAndGetResponse,
  GetUserLang,
} from "./index";
import { HTTPOperationType } from "./httpOperations";

export const userService = {
  login,
  logout,
  register,
  forgotPassword,
  changePasswordWithToken,
  changePassword,
  changeEmail,
  changeUsername,
  getMyInformation,
  confirm,
  getGender,
  checkUserNameIsAvailable,
  checkEmailIsAvailable,
  updatePP,
  setRedirectTemp,
  getRedirectTemp,
  setRedirectToken,
  getRedirectToken,
  getProject
};

function getProject(path) {
  return MakeReqestAndGetResponse(
    GetBaseConnectURL() + UserEndPoints.GET_PROJECT_TOKEN_URL + path,
    HTTPOperationType.get
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
  // remove user from local storage to log user out
}

function getRedirectToken(path) {
  return MakeReqestAndGetResponse(
    GetBaseConnectURL() + UserEndPoints.GET_REDIRECT_TOKEN_URL + path,
    HTTPOperationType.get
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
  // remove user from local storage to log user out
}
function setRedirectToken(_Token, _RedirectURL) {
  var js = {
    DynamicObj: { Token: _Token, RedirectURL: _RedirectURL },
  };

  return MakeReqestAndGetResponse(
    GetBaseConnectURL() + UserEndPoints.SET_REDIRECT_TOKEN_URL,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

function getRedirectTemp(path) {
  return MakeReqestAndGetResponse(
    GetBaseConnectURL() + UserEndPoints.GET_REDIRECT_URL + path,
    HTTPOperationType.get
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
  // remove user from local storage to log user out
}

function setRedirectTemp() {
  var js = {
    URLs: {
      currentURL:window.location.href,
          authURL:window.location.origin+"/auth/",
    }
  };

  return MakeReqestAndGetResponse(
    GetBaseConnectURL() + UserEndPoints.SET_REDIRECT_URL,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

function login(emailOrUsername, password, RememberMe) {
  var js = {
    Email: emailOrUsername,
    UserName: emailOrUsername,
    Password: password,
    RememberMe: RememberMe,
    Lang: GetUserLang(),
  };

  return MakeReqestAndGetResponse(
    GetBaseConnectURL() + UserEndPoints.LOGIN,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      localStorage.setItem("user", JSON.stringify(res.dynamicValue));
      return res;
    }
  });
}

function getGender() {
  let lang = "de";
  return MakeReqestAndGetResponse(
    GetBaseConnectURL() + UserEndPoints.GET_GENDER + lang,
    HTTPOperationType.get
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
  // remove user from local storage to log user out
}

//getmyinformation
function getMyInformation() {
  return MakeReqestAndGetResponse(
    GetBaseConnectURL() + UserEndPoints.GET_MY_INFORMATION,
    HTTPOperationType.get
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
  // remove user from local storage to log user out
}

function confirm(_UserName, _Token) {
  return MakeReqestAndGetResponse(
    GetBaseConnectURL() +
      UserEndPoints.CONFIRM_USER +
      "/" +
      _UserName +
      "/" +
      _Token,
    HTTPOperationType.get
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
  // remove user from local storage to log user out
}

function forgotPassword(UsernameOrEmail) {
  var js = {
    Email: UsernameOrEmail,
    UserName: UsernameOrEmail,
    Lang: GetUserLang(),
  };

  return MakeReqestAndGetResponse(
    GetBaseConnectURL() + UserEndPoints.FORGOT_PASSWORD,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

function changeUsername(_Username) {
  var js = {
    UserName: _Username,
  };

  return MakeReqestAndGetResponse(
    GetBaseConnectURL() + UserEndPoints.UPDATE_USERNAME,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

function updatePP(Images) {
  var js = {
    ProfilePhotoImages: Images,
  };

  return MakeReqestAndGetResponse(
    GetBaseConnectURL() + UserEndPoints.UPDATE_PP,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

function changeEmail(email) {
  var js = {
    Email: email,
  };

  return MakeReqestAndGetResponse(
    GetBaseConnectURL() + UserEndPoints.UPDATE_EMAIL,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

function changePassword(_OldPassword, _Password) {
  var js = {
    OldPassword: _OldPassword,
    Password: _Password,
  };

  return MakeReqestAndGetResponse(
    GetBaseConnectURL() + UserEndPoints.UPDATE_PASSWORD,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

function changePasswordWithToken(_Password, _Token) {
  var js = {
    Password: _Password,
    Token: _Token,
  };

  return MakeReqestAndGetResponse(
    GetBaseConnectURL() + UserEndPoints.CHANGE_PASS_W_TOKEN,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

function register(_Name, _Email, _Password) {
  var js = {
    Name: _Name,
    Email: _Email,
    _pass1word_: _Password,
    Lang: GetUserLang(),
  };

  return MakeReqestAndGetResponse(
    GetBaseConnectURL() + UserEndPoints.REGISTER,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

function logout() {

  return MakeReqestAndGetResponse(
    GetBaseConnectURL() + UserEndPoints.LOGOUT,
    HTTPOperationType.get
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      localStorage.removeItem("user");
      return res;
    }
  });
  // remove user from local storage to log user out
}
//es7
async function checkUserNameIsAvailable(email) {
  var js = {
    UserName: email,
  };

  let res = await MakeReqestAndGetResponse(
    GetBaseConnectURL() + UserEndPoints.GET_USERNAME_AVAILABLE,
    HTTPOperationType.post,
    js
  );

  return await res;
}
//es7
async function checkEmailIsAvailable(email) {
  var js = {
    Email: email,
  };

  let res = await MakeReqestAndGetResponse(
    GetBaseConnectURL() + UserEndPoints.GET_EMAIL_AVAILABLE,
    HTTPOperationType.post,
    js
  );

  return await res;
}
