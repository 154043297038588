import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import i18n from "i18n";

import Editor from "@monaco-editor/react";

import ReactResizeDetector from "react-resize-detector";
import { Loader,Button } from "rsuite";

//var moment = JSON.stringify(require("moment/moment.d.ts"))

class CodeEditor extends Component {
  constructor(props) {
    super(props);
    this.editorRef = React.createRef();
    this.scriptRef = React.createRef();

    this.state = {
      script: props.value ? props.value : "",
      isLoading: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value != this.props.value) {
      this.setState({ script: this.props.value });
    }
  }

  handleEditorDidMount = (w, h) => {
    const editor = this.editorRef.current;
    if (editor) {
      /*  editor.layout({
                  width: 'auto',
                  height: 'auto',
              });
              */
      const width = w - 10;
      const height = h - 10;

      editor.layout({
        width,
        height,
      });
      editor.focus();
    }
  };

  onChange(newValue, e) {
    console.log("onChange", newValue, e);
  }

  editorDidMount(editor, monaco) {
  
  }

  render() {
    const { funcName, funcParams, isGlobalScript } = this.props;
    const { script } = this.state;
    const options = {
      selectOnLineNumbers: true,
    };
    return (
      <Translation>
        {(t) => (
          <>
            <ReactResizeDetector handleWidth handleHeight>
              {({ width, height }) => {
                this.handleEditorDidMount(width, height);
                return (
                  <div
                    ref={this.scriptRef}
                    style={{ ...this.props.style }}
                    className={" script-code-side"} //custom-fade-in
                    id={"filter-query-json"}
                  >
        
                    <Editor
                      height={"300px"}
                      width={width}
                      defaultLanguage="json"
                      defaultValue={script}
                      onMount={this.editorDidMount}
                      onChange={(value)=>{
                        this.props.handleUpdate(value)
                      }}
                      options={{
                        "autoIndent": true,
                        "formatOnPaste": true,
                        "formatOnType": true
                      }}
                    />

                    {this.state.isLoading && (
                      <div className={"fade-in"}>
                        <Loader backdrop vertical />
                      </div>
                    )}
                  </div>
                );
              }}
            </ReactResizeDetector>
          </>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer, authentication } = state;
  const { loggedIn, LoginFailed, user } = authentication;

  const {
    workspace,
    workspaces,
    selectedService,
    store,
    storeCategories,
    generatedTransactions,
    generatedModels,
    generatedInterfaces,
    module,
  } = workspaceReducer;

  return {
    workspace,
    workspaces,
    selectedService,
    store,
    storeCategories,
    user,
    generatedTransactions,
    generatedModels,
    generatedInterfaces,
    module,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CodeEditor);

/*
`
                    import { types, ReturnModelType } from "@typegoose/typegoose";
                    import { UpdateQuery } from "mongoose";
                    export declare abstract class MongoBaseRepository<T, U extends types.AnyParamConstructor<T> = types.AnyParamConstructor<T>> {
                        protected dataModel: ReturnModelType<U>;
                        private T_CLASS;
                        constructor(cls: U);
                        save(data: any): Promise<T>;
                        create(data: T): Promise<T>;
                        update(id: any, item: UpdateQuery<types.DocumentType<InstanceType<U>>>): Promise<boolean>;
                        read(condition: any): Promise<T[]>;
                        findAll(condition: any): Promise<T[]>;
                        findAllPOP(condition: any, pop: any): Promise<T[]>;
                        delete(id: any): Promise<boolean>;
                        findByID(Id: any): Promise<T>;
                    }
                    
                    ``
                    export declare class DocumentCT {
                        _id: string;
                        __v: number;
                    }
                    
                    `;


*/
