import { SocketOperationsLocal,SocketOperations } from "../_constants";

  export function setRefSocketRef(_socket) {
    return {
        type: SocketOperationsLocal.SET_SOCKET_REF,
        socket:_socket
    }
}
 
/*
export const SocketOperationFunctions={
  SetCurrentPage,
  GetCurrentPageUsers,
  SetAutoSave,
  GetExistAutoSaved,
  ClearAutoSaved,
  setRefSocketRef
}

function SetCurrentPage(socket,_ProjectID,_PageID){
  if(socket==null)
  return;
  socket.emit(SocketOperations.SET_CURRENT_PAGE, {
    ProjectID: _ProjectID,
    PageID:_PageID
  });
}
function GetCurrentPageUsers(socket,_ProjectID,_PageID){
  if(socket==null)
  return;
  socket.emit(SocketOperations.GET_CURRENT_PAGE_USERS, {
    ProjectID: _ProjectID,
    PageID:_PageID
  });
}
function SetAutoSave(socket,_ProjectID,_PageID,_Version,_Detail){
  if(socket==null)
  return;
  socket.emit(SocketOperations.SET_AUTO_SAVE, {
    ProjectID: _ProjectID,
    PageID:_PageID,
    Version:_Version,
    Detail:_Detail
  });
}

function GetExistAutoSaved(socket,_ProjectID,_PageID,_Version){
  if(socket==null)
  return;
  socket.emit(SocketOperations.GET_AUTO_SAVE, {
    ProjectID: _ProjectID,
    PageID:_PageID,
    Version:_Version
  });
}

function ClearAutoSaved(socket,_ProjectID,_PageID,_Version){
  if(socket==null)
  return;
  socket.emit(SocketOperations.CLEAR_AUTO_SAVED, {
    ProjectID: _ProjectID,
    PageID:_PageID,
    Version:_Version
  });
}
*/