import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translation } from "react-i18next";

import { NavLink } from "react-router-dom";

import { Icon, Button } from 'rsuite';


class NoMatchWorkspace extends Component {
    render() {
        return (
            <Translation>
                {t => (
                    <div className={"global-view not-match-workspace custom-fade-in"}>
                        <Icon icon='exclamation-triangle' size="5x" />
                        <span>{t("ERROR_NOT_FOUND_WORKSPACE_TITLE")}</span>
                        <NavLink to={"/workspaces"}>
                            <Button appearance="primary">{t("BUTTON_VIEW_WORKSPACES")}</Button>
                        </NavLink>
                    </div>
                )}
            </Translation>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(NoMatchWorkspace)
