import {
    currentstatesConstants
} from "../_constants";

const initialState = {
     customHistory: null,
     isImageUploadLoading: false,
     isSnippetsUploaded:false,

};

export function currentstatesReducer(state = initialState, {
    type,
    payload
}) {
    switch (type) {
        case currentstatesConstants.SET_HISTORY:
            return {
                ...state,
                customHistory: payload.data
            };
            case currentstatesConstants.SET_UPLOADIMAGELOADING:
            return {
                ...state,
                isImageUploadLoading: payload.data,
            };

            case currentstatesConstants.CODE_EDITOR_SNIPPETS_UPLOAD:
                return {
                    ...state,
                    isSnippetsUploaded: payload.data,
                };
            

        default:
            return state;
    }
}