import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import i18n from "i18n";
import { Dropdown, Button, Alert, Schema } from "rsuite";
import moment from "moment";

import { ErpEngineService } from "_services";

import DynamicTable from "./DynamicTable";
import ListItemOperations from "./Modals/ListItemOperations";
import ImportDataModal from "components/Modals/ImportDataModal";
/* PDF */
import jsPDF from "jspdf";
import "jspdf-autotable";
import pdfFonts from "pdfmake/build/vfs_fonts";
import EasyInvoiceViewer from "components/Modals/EasyInvoiceViewer";
import ConfirmModal from "components/Modals/ConfirmModal";

import { enqueueSnackbar } from "notistack";

var pdfMake = require("pdfmake");
pdfMake.vfs = pdfFonts.pdfMake.vfs;
/* PDF */
const { StringType, NumberType, DateType, ObjectType, BooleanType, ArrayType } =
  Schema.Types;
class ListGenerator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: [],
      selectedItem: null,
      isDetailOperations: false,
      isImportData: false,
      isInvoiceViewer: false,
      filter: "",
      countPerPage: 10,
      currentPage: 1,
      short: "",
      FilterModel: {
        CurrentPage: 1,
        CountPerPage: 10,
        TotalDocument: 0,
        TotalPage: 0,
      },
      isConfirmModal: false,
      confirmTitle: "",
    };
  }
  handleInvoiceViewerModal = () => {
    this.setState({
      isInvoiceViewer: !this.state.isInvoiceViewer,
    });
  };
  handleConfirmModal = (title = "") => {
    this.setState({
      confirmTitle: title,
      isConfirmModal: !this.state.isConfirmModal,
    });
  };
  updateExtraPath = (key, value) => {
    const that = this;
    this.setState(
      {
        [key]: value,
      },
      () => {
        that.handleReadData();
      }
    );
    /*
    filter:"",
      countPerPage: 10,
      currentPage: 1,
      short: "",
    */
  };

  handleIsImportData = () => {
    this.setState({
      isImportData: !this.state.isImportData,
    });
  };

  handleIsDetailOperations = () => {
    this.setState({ isDetailOperations: false });
  };

  exportPDF = () => {
    const { selectedService } = this.props;
    const _date = moment(new Date()).format("YYYY-MM-DD_h-mm");
    const _date2 = moment(new Date()).format("YYYY/MM/DD h:mm");

    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(12);

    const title = selectedService.name;

    let list = [];

    this.state.data.map((x) => {
      let _obj = [];
      selectedService.model.map((y) => {
        _obj.push(
          x.hasOwnProperty(y.name)
            ? y.contentType == "5"
              ? x[y.name].hasOwnProperty(y.relationModel.listColumn)
                ? x[y.name][y.relationModel.listColumn]
                : x[y.name]._id
              : x[y.name]
            : ""
        );
      });
      list.push(_obj);
    });

    //   debugger;

    const data = list; //this.state.people.map(elt => [elt.name, elt.profession]);

    const _headers = [...selectedService.model.map((x) => x.name)];
    const docDefinition = {
      footer: function (currentPage, pageCount) {
        return [
          {
            text: currentPage.toString() + " of " + pageCount,
            style: "footer",
            tocItem: true,
          },
        ];
      },
      header: function (currentPage, pageCount, pageSize) {
        // you can apply any logic and return any valid pdfmake element

        return [
          { text: title, style: "header", tocItem: true },
          {
            canvas: [
              { type: "rect", x: 170, y: 32, w: pageSize.width - 170, h: 40 },
            ],
          },
        ];
      },
      content: [
        {
          layout: "lightHorizontalLines", // optional
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 1,
            body: [[..._headers], ...data],
          },
        },
      ],
      styles: {
        header: {
          fontSize: 16,
          bold: true,
          marginLeft: 10,
          marginTop: 10,
        },
        footer: {
          rigth: 0,
        },
        anotherStyle: {
          italics: true,
          alignment: "right",
        },
      },
    };
    pdfMake.createPdf(docDefinition).download();

    // doc.save(selectedService.name + "_" + _date);
  };

  componentDidMount() {
    this.handleReadData();
  }

  handleReadData = () => {
    const { workspace, selectedService } = this.props;
    const { filter, countPerPage, currentPage, short } = this.state;

    const URL_MODE = "dev_URL";
    const that = this;

    const FilterModel = {
      Filter: filter ? filter : "{}",
      CurrentPage: currentPage ? currentPage : undefined,
      CountPerPage: countPerPage ? countPerPage : undefined,
      Sorts: short ? short : undefined,
    };

    this.setState({ loading: true });
    ErpEngineService.EXECUTE_TRANSACTION_DIRECTLY(
      workspace[URL_MODE],
      `${selectedService.functionName == "read" ? "_" : ""}${
        selectedService.functionName
      }`,
      selectedService.executableTransactionServiceName,
      "{}",
      FilterModel
    )
      .then((data) => {
        if (data.operationResult) {
          this.setState({
            data: data.dynamicValue,
            FilterModel: data.FilterModel,
          });
        }
        that.setState({ loading: false });
      })
      .catch((err) => {
        that.setState({ loading: false });
        Alert.error(i18n.t("MESSAGE_TRANSACTION_READ_UNSUCCESS"));
        debugger;
      });
  };

  handleOperation = () => {
    this.handleReadData();
    this.handleIsDetailOperations();
  };

  handleSelect = (_value) => {
    const { data, loading, isObject, selectedService } = this.props;
    let _cValue = Object.assign({}, _value);

    this.setState({ selectedItem: _cValue, isDetailOperations: true });
  };

  handleFilterAndGetData = (filter) => {
    const that = this;
    this.setState(
      {
        filter: filter,
      },
      () => {
        that.handleReadData();
      }
    );
  };
  prepareGenerateSchema = () => {
    const { selectedService, models, isRefModelForm, refModel } = this.props;

    let model = {}; // Schema.Model({});
    /*
    const _sModels = models
      ? models.dynamicValue.find((x) => x.id === selectedService.reqModelId)
          .dataTypes
      : selectedService.model;
*/
    // if (isRefModelForm) debugger;

    const _xx =
      model &&
      this.props.model.find(
        (x) =>
          x.id === (isRefModelForm ? refModel.id : (selectedService.responseModelType
            ? selectedService.relResponseModelId
            : selectedService.reqModelId))
      );

    if (!_xx) {
      debugger;
    }

    const _sModels = model
      ? this.props.model.find(
          (x) =>
            x.id === (isRefModelForm ? refModel.id : (selectedService.responseModelType
              ? selectedService.relResponseModelId
              : selectedService.reqModelId))
        ).dataTypes
      : (isRefModelForm ? refModel : selectedService)
      ? isRefModelForm
        ? refModel.dataTypes
        : selectedService.model
      : [];

    _sModels.map((item, key) => {
      if (!item.privateField) {
        let _obj = {};
        switch (item.contentType) {
          case 1:
            // String
            _obj = StringType();
            break;
          case 2:
            // Rich Text
            _obj = StringType();
            break;
          case 3:
            // Number
            _obj = NumberType();
            break;
          case 4:
            // Date
            _obj = DateType();
            break;
          case 5:
            // Relations
            _obj = ObjectType(); // string ti
            break;
          case 6:
            // Enum
            _obj = StringType();
            break;
          case 7:
            // Boolean
            _obj = BooleanType();
            break;
          case 8:
            // Identity
            _obj = StringType();
            break;
          case 9:
            // Image
            _obj = ObjectType();
            break;
          case 10:
            // Relations
            _obj = ObjectType(); // ObjectType();
            break;
          case 11:
            // Relations
            _obj = ObjectType(); // ObjectType();
            break;
          case 12:
            // Relations
            _obj = ObjectType(); // ObjectType();
            break;
          case 13:
            // Relations
            _obj = ArrayType(); // ObjectType();
            break;
            case 15:
              // Relations
              _obj = ObjectType();
              break;
            case 16:
              // Relations
              _obj = ObjectType(); // ObjectType();
              break;
              case 17:
                // Relations
                _obj = ObjectType(); // ObjectType();
                break;
          default:
            break;
        }
        if (item.reqiured || item.notNull) {
          if (_obj) _obj.isRequired(i18n.t("ERROR_DM_GLOBAL_REQUIRED"));
        }
        if (item.uniq) {
        }

        switch (item.typeDetail) {
          case 1:
            // STANDART
            break;
          case 2:
            // PASSWORD
            break;
          case 3:
            // EMAIL
            _obj.isEmail(i18n.t("ERROR_DM_GLOBAL_EMAIL_FORMAT"));
            break;
          case 4:
            // INT
            //_obj.isInteger(i18n.t("ERROR_DM_GLOBAL_INT"));
            break;
          case 5:
            // BIGINT
            // _obj.isInteger(i18n.t("ERROR_DM_GLOBAL_INT"));
            break;
          case 6:
            // DECIMAL
            // _obj.isInteger(i18n.t("ERROR_DM_GLOBAL_INT"));
            break;
          case 7:
            // DOUBLE
            //_obj.isInteger(i18n.t("ERROR_DM_GLOBAL_INT"));
            break;
          case 8:
            // DOUBLE
            //_obj.isInteger(i18n.t("ERROR_DM_GLOBAL_INT"));
            break;
          case 9:
            // DOUBLE
            //_obj.isInteger(i18n.t("ERROR_DM_GLOBAL_INT"));
            break;
          case 10:
            // DOUBLE
            //_obj.isInteger(i18n.t("ERROR_DM_GLOBAL_INT"));
            break;
          case 11:
            // DOUBLE
            //_obj.isInteger(i18n.t("ERROR_DM_GLOBAL_INT"));
            break;

          default:
            break;
        }
        if (
          item.contentType !== 8 &&
          item.contentType !== 12 &&
          item.contentType !== 11 &&
          item.contentType !== 14 &&
          item.contentType !== 17
        ) {
          model[item.name] = _obj;
        }
      }
    });
    return Schema.Model(model);
  };
  transactionPrepareFunc = () => {
    const formValue  = this.state.data;
    const { selectedService, model, isRefModelForm, refModel } = this.props;
    debugger
    const _sModels = model
      ? model.find(
          (x) =>
            x.id === (isRefModelForm ? refModel.id : (selectedService.responseModelType
              ? selectedService.relResponseModelId
              : selectedService.reqModelId))
        ).dataTypes
      : (isRefModelForm ? refModel : selectedService)
      ? isRefModelForm
        ? refModel.dataTypes
        : selectedService.model
      : [];
    return {
      formData: JSON.parse(JSON.stringify(formValue)),
      model: _sModels,
    };
  };

  ToastMessage = (msg, type) => {
    try {
      switch (type) {
        case "success":
          Alert.success(msg);
          break;
        case "error":
          Alert.error(msg);
          break;
        case "info":
          Alert.info(msg);
          break;
        case "warning":
          Alert.warning(msg);
          break;
        default:
          Alert.info(msg);
          break;
      }
    } catch (error) {
      Alert.info(msg);
    }
  };
  SnackMessage = (msg, type) => {
    try {
      enqueueSnackbar(msg, { variant: type });
    } catch (error) {
      enqueueSnackbar(msg);
    }
  };

  preTransactionFunc = (data, _selectedService, _formValue) => {
    const {
      isRunTransModal,
      runTransModalClose,
      updateBaseForm,
      handleRunPreTransaction,
    } = this.props;

    const _dynamicSchemaModel = this.prepareGenerateSchema();

    const that = this;
    let result = {
      status: true,
      body: data,
    };
    debugger
    const runTransaction = that.runTransaction;
    const transactions = this.props.allTransList;
    const Toast = this.ToastMessage;
    const SnackToast = this.SnackMessage;
    const easyinvoice = require("easyinvoice").default; //easyinvoice.download('myInvoice.pdf', data.InvoiceBinary);
    const showInvoiceModal = this.handleInvoiceViewerModal;
    const showConfirmModal = this.handleConfirmModal;
    const runPreTransaction = () => {
      if (isRunTransModal && handleRunPreTransaction) {
        handleRunPreTransaction();
      }
    };
    
    const updatePreForm = (formValue) => {
      if (isRunTransModal) {
        //debugger;
        updateBaseForm(formValue);
      } else {
        this.setState({ formValue });
        if (this.props.isShowTransaction) {
          this.props.updateBaseForm(formValue);
        } else if (this.props.isRefModelForm) {
          // handleUpdateRefData(formValue);
          that.handleUpdateRefData(formValue, this.form, _dynamicSchemaModel);
        } else {
        }
      }
    };
    const closeModal = () => {
      if (this.props.isShowTransaction) {
        this.props.showTransactionClose();
      } else if (isRunTransModal) {
        runTransModalClose();
      } else {
        that.setState({
          isTransModal: false,
        });
      }
    };

    const checkFormValidation = () => {
      try {
        if (!that.form.check()) {
          Alert.error("Form not validated");
          result.status = false;

          return false;
        } else {
          return true;
        }
      } catch (error) {
        return false;
      }
    };

    let { formData, model } = this.transactionPrepareFunc();
    const updateData = that.UpdateFormValue;
    debugger;
    if (
      _selectedService.hasOwnProperty("preScript") &&
      _selectedService.preScript.trim().length > 0
    ) {
      try {
        if (_selectedService.preScript.includes("await ")) {
          result.body = eval(
            "(async function() => { " + _selectedService.preScript + "})()"
          );
        } else {
          result.body = eval(
            "(function() {" + _selectedService.preScript + "}())"
          );
        }
      } catch (error) {
        debugger;
      }
    }
    /*
    preScript(pin): "alert("pre")"
postScript(pin): "alert("post")"
    */

    try {
      // debugger;
      if (result) {
        return result;
      }
      return data;
    } catch (error) {
      return data;
    }
  };
  runBlobTransaction = (_selectedService, _formValue) => {
    const  formValue  = this.state.data;
    const { workspace, onSubmit, model, isRefModelForm, refModel } = this.props;

    const selectedService = _selectedService
      ? _selectedService
      : this.state.selectedService;

    const URL_MODE = "dev_URL";
    const that = this;

    const _sModels = model
      ? model.find(
          (x) =>
            x.id === (isRefModelForm ? refModel.id : (selectedService.responseModelType
              ? selectedService.relResponseModelId
              : selectedService.reqModelId))
        ).dataTypes
      : (isRefModelForm ? refModel : selectedService)
      ? isRefModelForm
        ? refModel.dataTypes
        : selectedService.model
      : [];

    debugger;

    this.setState({ loading: true });

    const _preFunc = this.preTransactionFunc(
      _formValue,
      _selectedService,
      _formValue
    );

    if (_preFunc.status) {
      const _body = JSON.stringify(_preFunc.body);
      debugger;
      that.setState({ loading: true });
      ErpEngineService.EXECUTE_TRANSACTION_DIRECTLY_BLOB(
        workspace[URL_MODE],
        this.props.isListDetail
          ? this.props.itemOperation
            ? this.props.itemOperation
            : _selectedService.functionName
          : _selectedService.functionName,
        _selectedService.executableTransactionServiceName,
        _body
      )
      .then((_data) => {
          const { data, res } = _data;
         debugger

          that.setState({ loading: false });
          try {
            const href = URL.createObjectURL(data);

            // create "a" HTML element with href to file & click
            const link = document.createElement("a");
            link.href = href;
            debugger;
            const contentDisposition = res.headers["x-suggested-filename"];

            if (contentDisposition) {
              link.setAttribute("download", `${contentDisposition}`); //or any other extension
            } else {
              switch (res.headers["content-type"]) {
                case "application/pdf":
                  link.setAttribute("download", `${new Date().toJSON()}.pdf`); //or any other extension
                  break;
                default:
                  link.setAttribute("download", `${new Date().toJSON()}.xlsx`); //or any other extension

                  break;
              }
            }
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            debugger;
            return true;
          } catch (error) {
            return false;
          }
        })
        .catch((err) => {
          debugger;
          that.setState({ loading: false });
          try {
            Alert.error(
              Array.isArray(err.failText)
                ? err.failText.join(". ")
                : err.failText
            );
          } catch (error) {
            Alert.error(i18n.t("MESSAGE_TRANSACTION_SUBMIT_UNSUCCESS"));
          }
          // let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
        });
    } else {
      this.setState({ loading: false });
    }
  };


  render() {
    const { selectedService, model } = this.props;
    const { data } = this.state;
    /*
    const dataSet1 = Array.from(Array(30).keys()).map((x, key) => {
      let _obj = {};
      selectedService.model.map((y) => {
        _obj[y.name] = "FAKE VALUE" + key;
      });
      return {
        id: key,
        ..._obj,
      };
    });
    */

    const _sService = model
      ? model.find(
          (x) =>
            x.id ===
            (selectedService.responseModelType
              ? selectedService.reqModelId
              : selectedService.reqModelId)
        )//relResponseModelId
      : [];

    const _extraButtons =
      _sService &&
      _sService.hasOwnProperty("extraButtons") &&
      _sService.extraButtons.filter(
        (x) =>
          x.runType == "TableActionModal" ||
          x.runType == "TableActionModalOnLoad" ||
          x.runType == "TableActionCurrent"
      );

    return (
      <Translation>
        {(t) => (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "1em 0",
              }}
            >
              {/*
                 <Dropdown title="Export">
                <ExcelFile
                  filename={selectedService.name + "_" + _date}
                  element={<Button>Excel</Button>}
                >
                  <ExcelSheet
                    data={data.map((x) => {
                      let _obj = {};
                      selectedService.model.forEach((el) => {
                        switch (el.contentType) {
                          case 5: //ref model
                            _obj[el.name] = x[el.name].hasOwnProperty(
                              el.relationModel.listColumn
                            )
                              ? x[el.name][el.relationModel.listColumn]
                              : x[el.name]._id;
                            break;
                          case 10: // rel obj
                            _obj[el.name] = x._id;
                            break;
                          default:
                            _obj[el.name] = x[el.name];
                            break;
                        }
                 
                      });
                      return _obj;
                    })}
                    name={selectedService.name}
                  >
                    {selectedService.model.map((x, key) => (
                      <ExcelColumn label={x.displayName} value={x.name} />
                    ))}
                  </ExcelSheet>
                </ExcelFile>
                <Dropdown.Item onClick={this.exportPDF}>PDF</Dropdown.Item>
              </Dropdown>
                */}
              <Button onClick={this.handleIsImportData} appearance="default">
                {t("BUTTON_IMPORT")}
              </Button>
            </div>

            <DynamicTable
              height={window.innerHeight - 430}
              data={data}
              isSearchable={true}
              loading={this.state.loading}
              dataModelOrj={
                model.find((x) => x.id == selectedService.relResponseModelId)
                  .dataTypes
              }
              dataModel={model
                .find((x) => x.id == selectedService.relResponseModelId)
                .dataTypes.filter((x) => !x.privateField)}
              handleSelect={(_data) => {
                if (selectedService.editableList) {
                  debugger;
                  this.handleSelect(_data);
                }
              }}
              isStandartList={true}
              updateExtraPath={this.updateExtraPath}
              filter={this.state.filter}
              countPerPage={this.state.countPerPage}
              currentPage={this.state.currentPage}
              short={this.state.short}
              FilterModel={this.state.FilterModel}
              handleFilterAndGetData={this.handleFilterAndGetData}
              runBlobTransaction={this.runBlobTransaction}
              extraButtonsAll={
                _sService && _sService.hasOwnProperty("extraButtons")
                  ? _sService.extraButtons.filter(
                      (x) =>
                        x.runType != "TableActionModal" &&
                        x.runType != "TableActionModalOnLoad" &&
                        x.runType != "TableActionCurrent"
                    )
                  : []
              }
              extraButtons={_extraButtons}
            />
            {this.state.selectedItem && this.state.isDetailOperations && (
              <ListItemOperations
                handleUpdate={this.handleOperation}
                selectedItem={this.state.selectedItem}
                isActive={this.state.isDetailOperations}
                handleToggle={this.handleIsDetailOperations}
              />
            )}
            <ImportDataModal
              isActive={this.state.isImportData}
              handleToggle={this.handleIsImportData}
            />

            {this.state.isInvoiceViewer && (
              <EasyInvoiceViewer
                isActive={this.state.isInvoiceViewer}
                handleToggle={this.handleInvoiceViewerModal}
              />
            )}

            {this.state.isConfirmModal && (
              <ConfirmModal
                isActive={this.state.isConfirmModal}
                handleToggle={this.handleConfirmModal}
                title={this.state.confirmTitle}
              />
            )}
          </>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer } = state;
  const {
    transaction,
    workspace,
    dataModels,
    selectedService,
    models,
    model,
    allServices,
    allTransactions,
    allTransList,
  } = workspaceReducer;

  return {
    workspace,
    transaction,
    dataModels,
    selectedService,
    selectedServicex: selectedService,
    allTransactions,
    models,
    model,
    allServices,
    allTransList,
  };
};

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ListGenerator);
