//var crypto = require('crypto');
var CryptoJS = require("crypto-js");

var key = 'f4gb68a-26f-77c8-n06a-6a99fcgf0c'; //replace with your key
var iv = 'ty-9345-g9fo874g'; //replace with your IV

exports.guid = function () {
    function s4() {
        return Math.floor(1 + Math.random() * 0x10000).toString(16).substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4() + s4();
}

function EncryptAES(Data) {

    let  key2 = CryptoJS.enc.Utf8.parse(key);
    let iv2 = CryptoJS.enc.Utf8.parse(iv);

    var aesEncryptor = CryptoJS.AES.encrypt(Data, key2, { iv: iv2 });
  
    /*
    var cipher = cryptoBrowserify.createCipher('aes256', key);
    var crypted = cipher.update(Data, 'utf8', 'base64');
    crypted += cipher.final('base64');
    */
    return aesEncryptor.toString();
}

function EnCryptMD5(data) {
     
    const _data = CryptoJS.MD5(data);
    return  _data.toString().toLowerCase();//crypto.createHash('md5').update(data).digest("hex").toLowerCase();
}

function EnCryptForHandShake(data) {
    const __aes = EncryptAES(data).toString();
    const _result = EnCryptMD5(__aes);
 //   debugger;
    return _result
}

exports.EncryptAES = EncryptAES
exports.EnCryptMD5 = EnCryptMD5
exports.EnCryptForHandShake = EnCryptForHandShake