import React, { Component } from "react";
import { connect } from "react-redux";
import { SelectPicker } from "rsuite";
import { Translation } from "react-i18next";
import i18n from "i18n";

class AdvancedEventField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedAdvTrans: props.cData ? props.cData.transId : null,
      selectedAdvKey: props.cData ? props.cData.modelKey : null,
      selectedAdvRunType: props.cData ? props.cData.runType : "current",
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const that = this;
    if (JSON.stringify(prevProps.cData) !== JSON.stringify(this.props.cData)) {
      debugger;
      this.setState({
        selectedAdvTrans: this.props.cData ? this.props.cData.transId : null,
        selectedAdvKey: this.props.cData ? this.props.cData.modelKey : null,
        selectedAdvRunType: this.props.cData
          ? this.props.cData.runType
          : "current",
      });
    }
  }

  render() {
    const { selectedAdvTrans, selectedAdvKey, selectedAdvRunType } = this.state;
    const { allServices, onUpdate, defData, refData, cData, onDelete,allTransactions } =
      this.props;
    /* if(cData){
      debugger
    }*/

    let _cdTrans = selectedAdvTrans
      ? allServices.find((x) => x.id == selectedAdvTrans)
      : null;
    if (selectedAdvTrans && !_cdTrans) {
      onDelete();
    } else if (selectedAdvTrans && _cdTrans) {
      debugger
      const re = allTransactions.find(
        (x) => x.model.id == _cdTrans.reqModelId
      );
      if (re) {
        _cdTrans = re.model;
      } else {
        debugger
        onDelete();
      }
    } else {
    }
    return (
      <Translation>
        {(t) => (
          <div className="form-element-advanced-options">
            {allServices && (
              <>
                <SelectPicker
                  className="picker-full custom-fade-in"
                  placement={"autoVertical"}
                  cleanable={false}
                  placeholder={t("PLACEHOLDER_TRANSACTION")}
                  value={selectedAdvTrans}
                  //defaultValue={cData?cData["transId"]:null}
                  onSelect={(value, item, e) => {
                    debugger
                    this.setState({
                      selectedAdvTrans: value,
                      selectedAdvKey: null,
                      selectedAdvRunType: "modal",
                    });
                    onUpdate(refData.name + refData.eventName, {
                      transId: value,
                      modelKey: null,
                      runType: "current",
                    });
                  }}
                  data={this.props.allServices.map((x) => ({
                    value: x.id,
                    label:
                      (x.shortName.length > 0
                        ? "(" + x.shortName + ") - "
                        : "") + x.name,
                  }))}
                />
              </>
            )}

            {selectedAdvTrans && _cdTrans && (
              <>
                <SelectPicker
                  className="picker-full custom-fade-in"
                  placement={"autoVertical"}
                  cleanable={false}
                  placeholder={t("PLACEHOLDER_TRANSACTION_SET_KEY")}
                  value={selectedAdvKey}
                  data={
                    _cdTrans
                      ? _cdTrans.dataTypes.map((x) => ({
                          value: x.name,
                          label: x.name,
                        }))
                      : []
                  }
                  onSelect={(value, item, e) => {
                    debugger
                    this.setState({
                      selectedAdvKey: value,
                    });
                    onUpdate(refData.name + refData.eventName, {
                      transId: this.state.selectedAdvTrans,
                      modelKey: value,
                      runType: this.state.selectedAdvRunType,
                    });
                  }}
                />
                <SelectPicker
                  className="picker-full custom-fade-in"
                  placement={"autoVertical"}
                  cleanable={false}
                  placeholder={t("PLACEHOLDER_TRANSACTION_RUN_TYPE")}
                  value={selectedAdvRunType}
                  data={["current", "modal","blob"].map((x) => ({
                    value: x,
                    label: x,
                  }))}
                  onSelect={(value, item, e) => {
                    debugger
                    this.setState({
                      selectedAdvRunType: value,
                    });
                    onUpdate(refData.name + refData.eventName, {
                      transId: this.state.selectedAdvTrans,
                      modelKey: this.state.selectedAdvKey,
                      runType: value,
                    });
                  }}
                />
              </>
            )}
          </div>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer } = state;
  const {
    workspace,
    selectedService,
    dataModels,
    modules,
    module,
    models,
    allTransactions,
    allServices,
  } = workspaceReducer;

  return {
    workspace,
    selectedService,
    dataModels,
    modules,
    module,
    models,
    allTransactions,
    allServices,
  };
};
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedEventField);
