import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { ActionCreators as UndoActionCreators } from "redux-undo";
import Emitter from "_actions/emitter";
import i18n from "i18n";
import { ResponseStatusCode } from "_helpers";
import { addNewPage, removeComponent } from "_actions";
import { WorkspaceService, DashBoardURLSService } from "_services";
import { Button, Alert } from "rsuite";

import { Input, Modal, Container, Row, Col } from "reactstrap";

class CreateURL extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      customStyles: {
        pageName: "",
        pageNameState: null,
        alert: null,
      },
    };
    this.myRef = React.createRef();
  }

    openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  handleCreateURL = () => {
    const { onRefresh, handleToggle, workspace } = this.props;
    let _state = this.state.customStyles;
    const that = this;
    that.setState({ isLoading: true });
    DashBoardURLSService.CREATE_DASHSBOARD_URL(workspace.id, _state.pageName)
      .then((data) => {
         Alert.success(i18n.t("SUCCESS_SAVED"));
        this.openInNewTab(data.dynamicValue.url)
        handleToggle();
      })
      .catch((err) => {
        Alert.warning(ResponseStatusCode(err.statusCode));
        that.setState({ isLoading: false });
         //  let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
        //   this.warningAlert(data);
      });
  };

  customStylesForm = (e, stateName) => {
    let newState = this.state.customStyles;
    newState[stateName] = e.target.value;
    if (stateName === "checkbox") {
      if (e.target.value) {
        newState.checkboxState = "valid";
      } else {
        newState.checkboxState = "invalid";
      }
    } else {
      if (e.target.value === "") {
        newState[stateName + "State"] = "invalid";
      } else {
        newState[stateName + "State"] = "valid";
      }
    }
    this.setState({
      customStyles: newState,
    });
  };

  onKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      this.validateCustomStylesForm();
    }
  };

  validateCustomStylesForm = () => {
    let newState = this.state.customStyles;
    newState.pageName = newState.pageName.trim();

    var regex = /^[a-zA-Z][a-zA-Z0-9_-]{2,}$/;

    if (newState.pageName === "") {
      newState.pageNameState = "invalid";
    } else if (!regex.test(newState.pageName)) {
      newState.pageNameState = "invalid";
    } else {
      newState.pageNameState = "valid";
    }

    this.setState(
      {
        customStyles: newState,
      },
      () => {
        if (newState.pageNameState == "valid") {
          this.handleCreateURL();
        }
      }
    );
  };
  resetContent = () => {
    this.setState({
      isLoading: false,
      customStyles: {
        pageName: "",
        pageNameState: null,
        alert: null,
      },
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.isActive)
      if (prevProps.isActive != this.props.isActive && this.props.isActive) {
        this.resetContent();
      }
  }

  render() {
    const { isActive, handleToggle } = this.props;
    const { isLoading } = this.state;
    return (
      <Translation>
        {(t) => (
          <>
            <Modal
              className="modal-dialog-centered"
              isOpen={isActive}
              //size="lg"
              // scrollable={true}
            >
              <div className="modal-header">
                <h5 className="modal-title" id="CheckProjectModal">
                  {t("MODAL_TITLE_CREATEURL")}
                </h5>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  disabled={this.state.isLoading}
                  onClick={() => handleToggle()}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body">
                <Container>
                  <Row>
                    <label
                      className="form-control-label"
                      htmlFor="validationCustomPagename"
                    >
                      {t("TXT_CREATEURL_NAME")}
                    </label>
                    <Input
                      innerRef={(input) => {
                        setTimeout(() => {
                          try {
                            input.focus();
                          } catch (error) {}
                        }, 300);
                      }}
                      autoFocus
                      aria-describedby="inputGroupPrepend"
                      id="validationCustomPagename"
                      placeholder={t("TXT_CREATEURL_NAME_PLACEHOLDER")}
                      type="text"
                      onKeyDown={this.onKeyDown.bind(this)}
                      defaultValue={this.state.customStyles.pageName}
                      valid={this.state.customStyles.pageNameState === "valid"}
                      invalid={
                        this.state.customStyles.pageNameState === "invalid"
                      }
                      disabled={isLoading}
                      onChange={(e) => this.customStylesForm(e, "pageName")}
                    />

                    <div className="invalid-feedback">
                      {t("TXT_CREATEURL_NAME_INVALID")}
                    </div>
                  </Row>
                </Container>
              </div>
              <div className="modal-footer">
                <Button
                  //color="white"
                  size={"sm"}
                  data-dismiss="modal"
                  disabled={this.state.isLoading}
                  type="button"
                  onClick={() => handleToggle()}
                >
                  {t("BUTTON_CANCEL")}
                </Button>

                <Button
                  loading={this.state.isLoading}
                  //color="primary"
                  size={"sm"}
                  type="button"
                  onClick={() => this.validateCustomStylesForm()}
                >
                  {t("BUTTON_CREATE")}
                </Button>
              </div>
              {this.state.alert}
            </Modal>
          </>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer } = state;
  const {
    models,
    workspaces,
    workspace,
    selectedService,
    services,
    modules,
    module,
  } = workspaceReducer;

  return {
    workspaces,
    workspace,
    modules,
    module,
    selectedService,
    services,
    models,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateURL);
