import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translation } from "react-i18next";
import i18n from 'i18n';

import { dateformat4 } from 'assets/globalOps/global';
import CCell from 'components/Custom/CCell';
import { Table, Panel, Button } from 'rsuite';
const { Column, HeaderCell, Cell, Pagination } = Table;

class CDataModelCard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            dataModel: [
                {
                    name: "priority",
                    editable: false,
                    type: "string",
                    privateField: true,
                },
                {
                    name: "name",
                    editable: false,
                    type: "string",
                    privateField: true,
                },
                {
                    name: "displayName",
                    editable: false,
                    type: "string",
                    privateField: true,
                },
                {
                    name: "contentType",
                    editable: false,
                    type: "contentTypes",
                    privateField: true,
                },
                {
                    name: "typeDetail",
                    editable: false,
                    type: "typeDetails",
                    privateField: true,
                },
                {
                    name: "reqiured",
                    editable: false,
                    type: "boolean",
                    privateField: true,
                },
                {
                    name: "unique",
                    editable: false,
                    type: "boolean",
                    privateField: true,
                },
                {
                    name: "notNull",
                    editable: false,
                    type: "boolean",
                    privateField: true,
                },
                {
                    name: "privateField",
                    editable: false,
                    type: "boolean",
                    privateField: true,
                },

            ],
        }
    }

    render() {
        const { data, handleSetDataModel } = this.props;
        return (
            <Translation>
                {t => (
                    <Panel className={"global-dm-panel"} header={
                        <div className={"global-dm-header"}>
                            <div>
                                <p className={"global-dm-panel-title"}>{data.collectionName}</p>
                                <p className={"global-dm-panel-text"}>{dateformat4(data.createdTime)}</p>
                            </div>
                            <Button onClick={() => { handleSetDataModel(data) }} appearance="primary">{t("BUTTON_USE")}</Button>
                        </div>
                    } >
                        <Table
                            height={420}
                            data={data.dataTypes}
                            rowClassName={"table-global-dm"}
                            bordered
                            cellBordered
                            autoHeight
                            affixHeader
                            affixHorizontalScrollbar>
                            {
                                this.state.dataModel.map((item, key) => (
                                    <Column key={key}  resizable>
                                        <HeaderCell>{item.name}</HeaderCell>
                                        <CCell data={item} dataKey={item.name} allTransactions={this.props.allTransactions} />
                                    </Column>

                                ))
                            }
                        </Table>

                        {
                            /*
                            data.dataTypes.map((item, key) => (
                                <div key={key}>
                                    <div>
                                        <p>{item.name}</p>
                                        <p>{item.displayName}</p>
                                    </div>
                                    <p>:</p>
                                    <p>{item.name}</p>
                                </div>
        
                            ))
                            */
                        }
                    </Panel>
                )}
            </Translation>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(CDataModelCard)
