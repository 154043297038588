import React, { Component } from "react";
import { Translation } from "react-i18next";
import { Modal, Loader } from "rsuite";

class EasyInvoiceViewer extends Component {
  render() {
    const { isActive, handleToggle, loading } = this.props;

    return (
      <Translation>
        {(t) => (
          <>
            <Modal
              className="easyinvoicemodal"
              size={"lg"}
              show={isActive}
              onHide={handleToggle}
            >
              <Modal.Header>
                {/*
             <Modal.Title>{t("TITLE_INCOIVE_VIEWER")}</Modal.Title>
               */}
              </Modal.Header>
              <Modal.Body
                style={{ paddingBottom: 0, height: "100%", maxHeight: "76vh" }}
              >
                <>
                  <div ref={this.myInvoice} id={"invoice_viewer"}></div>

                  {loading && (
                    <div className={"fade-in"}>
                      <Loader backdrop vertical />
                    </div>
                  )}
                </>
              </Modal.Body>
            </Modal>
          </>
        )}
      </Translation>
    );
  }
}

export default EasyInvoiceViewer;
