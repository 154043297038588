import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Translation } from "react-i18next";

import i18n from 'i18n';
import {
    Modal,
    Container,
    Row,
    Col,
    Card,
    CardBody,
} from "reactstrap";

import { ProjectService } from '_services';
import { setProjects, setProjectsLoading } from '_actions';

import { Divider, Icon, Tooltip, Whisper, Loading, Button, Alert, Animation } from 'rsuite';
import { Form, FormGroup, FormControl, ControlLabel, SelectPicker, Schema, ButtonToolbar } from 'rsuite';

import { decamelize } from 'assets/globalOps/global';
import { isoLangs } from 'assets/globalOps/isoLangs';


const { Fade, Collapse, Transition, Slide } = Animation;
const { StringType, NumberType } = Schema.Types;



class DeleteRowData extends Component {
    constructor(props) {
        super(props)
        this.state = {
            formValue: {
            },
            formError: {},
            loading: false,
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isActive !== this.props.isActive) {

            if (this.props.isActive) {
                this.setState({
                    formValue: {
                        ...this.props.data
                    }
                })
            }
        }
    }

    componentDidMount = () => {

    }

    handleSubmit = (e) => {
     
        this.props.handleDelete(this.state.formValue);
        this.handleCloseModal();
    }

    handleCloseModal = () => {
        const { handleToggle } = this.props;
        this.setState({
            formValue: {},
            formError: {},
            loading: false,
        }, () => { handleToggle(); })
    }

    render() {
        const { isActive, handleToggle, data, dataModel } = this.props;
        const { formValue, formError, loading } = this.state;

        return (
            <Translation>
                {t => (
                    <Modal
                        className="modal-dialog-centered"
                        isOpen={isActive}
                        //size="lg"
                        toggle={this.handleCloseModal}
                        scrollable={true}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title" id="CheckProjectModal">
                                {""}
                            </h5>
                            <button
                                aria-label="Close"
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => this.handleCloseModal()}
                            >
                                <span aria-hidden={true}>×</span>
                            </button>
                        </div>
                        <div className="modal-body" style={{ padding: 0 }}>
                            <Container>
                                <Row>
                                    <Col style={{ marginBottom: "15px", padding: "0px 1.25rem" }}>
                                        <div className={"delete-modal"}>
                                        <i className="fas fa-question"></i>
                                            <p>{t("MODAL_ASK_DELETE_DATA")}</p>
                                        </div>

                                        <ButtonToolbar className={"creator-toobar"}>
                                            <Button disabled={loading} loading={loading} appearance="primary" onClick={this.handleSubmit}>{t("BUTTON_DELETE")}</Button>
                                            <Button disabled={loading} appearance="default" onClick={this.handleCloseModal}>{t("BUTTON_CANCEL")}</Button>
                                        </ButtonToolbar>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </Modal>
                )}
            </Translation>
        )
    }
}

const mapStateToProps = (state) => {
    // const { projectsReducer } = state;
    return {

    }
}

const mapDispatchToProps = {

}
export default connect(mapStateToProps, mapDispatchToProps)(DeleteRowData)
