import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";

import i18n from "i18n";
import { Modal, Container, Row, Col, Card, CardBody } from "reactstrap";

import { ModelsService } from "_services";

import {
  Divider,
  Icon,
  Tooltip,
  Whisper,
  Loading,
  Button,
  Alert,
  Animation,
  Toggle,
  Checkbox,
} from "rsuite";
import {
  updateSelectedService,
  updateSelectedTransaction,
  updatedModels,
  updatedTransactions,
  updateSelectedModel,
  updateAllTransactions,
} from "_actions";

import {
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock,
  Schema,
  ButtonToolbar,
  SelectPicker,
} from "rsuite";
import Emitter from "_actions/emitter";

const { Fade, Collapse, Transition, Slide } = Animation;
const { StringType, NumberType } = Schema.Types;

const model = Schema.Model({
  modelName: StringType()
    .minLength(3, i18n.t("ERROR_MESSAGE_COLLECTION_NAME_MIN3"))
    .maxLength(50, i18n.t("ERROR_MESSAGE_COLLECTION_NAME_MAX50"))
    .isRequired(i18n.t("ERROR_MESSAGE_COLLECTION_NAME_REQUIRED"))
    .containsLetterOnly(i18n.t("ERROR_MESSAGE_COLLECTION_NAME_TRIM")),
});

class CloneModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formValue: {
        modelName: "",
        moduleId: props.selectedService.relModuleId,
        isDBObject: props.selectedService.isDatabaseObject,
        isDataBaseFalseObjectAllowTransaction:
          props.selectedService.isDataBaseFalseObjectAllowTransaction,
      },
      formError: {},
      loading: false,
    };
  }

  handleSubmit = (e) => {
    const { formValue } = this.state;
    if (!this.form.check()) {
      //  console.error('Form Error');
      return;
    }
    this.CLONE_MODEL();
  };

  CLONE_MODEL = () => {
    const that = this;
    const { formValue } = this.state;
    const {
      data,
      hiddenDataTypes,
      extraButtons,
      isDataBaseFalseObjectAllowTransaction,
    } = this.props;
    this.setState({ loading: true });

    const { selectedService, isRefModel } = this.props;

    /*
      REL_CLONE_MODEL_ID,
      RelModuleId,
      ModelName,
      IsDatabaseObject,
      DataTypes,
      HiddenDataTypes,
      ExtraButtons,
      IsDataBaseFalseObjectAllowTransaction  
   */
    ModelsService.CLONE_MODEL(
      selectedService.relBaseModelId, //REL_CLONE_MODEL_ID
      formValue.moduleId ? formValue.moduleId : selectedService.relModuleId, //RelModuleId
      formValue.modelName, //ModelName
      formValue.isDBObject, //IsDatabaseObject
      this.props.selectedService[isRefModel ? "dataTypes" : "model"], //DataTypes
      this.props.selectedService.hasOwnProperty("hiddenDataTypes")
        ? this.props.selectedService.hiddenDataTypes
        : [],
      this.props.selectedService.hasOwnProperty("extraButtons")
        ? this.props.selectedService.extraButtons
        : [],
      formValue.isDataBaseFalseObjectAllowTransaction
    )
      .then((data) => {
        if (data.operationResult) {
          that.setState({ isEdit: false });
          Alert.success(i18n.t("SUCCESS_CLONED"));
          Emitter.emit("UPDATE_ALL_TRANS", null);
        }
      })
      .catch((err) => {
        Alert.error("Error code : " + err.statusCode);
        this.setState({ loading: false });
      });
  };

  handleCloseModal = () => {
    const {
      handleToggle,
      selectedService,
      isDataBaseFalseObjectAllowTransaction,
    } = this.props;
    this.setState(
      {
        formValue: {
          modelName: "",
          moduleId: selectedService.relModuleId,
          isDBObject: selectedService.isDatabaseObject,
           isDataBaseFalseObjectAllowTransaction:
          selectedService.isDataBaseFalseObjectAllowTransaction,
        },
        formError: {},
        loading: false,
      },
      () => {
        handleToggle();
      }
    );
  };

  render() {
    const { isActive, handleToggle, selectedService } = this.props;
    const { formValue, formError, loading } = this.state;

    return (
      <Translation>
        {(t) => (
          <Modal
            className="modal-dialog-centered"
            isOpen={isActive}
            //size="lg"
            toggle={this.handleCloseModal}
            scrollable={true}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="CheckProjectModal">
                {t("TITLE_CLONE_MODEL_MODAL_TITLE")}
                <span
                  style={{
                    marginLeft: "5px",
                    fontSize: "14px",
                    color: "#42b7c6",
                  }}
                >{`(${this.props?.selectedService.isRefObject? this.props?.selectedService.modelName : this.props?.selectedService.baseModelData?.modelName})`}</span>
              </h5>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => this.handleCloseModal()}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body" style={{ padding: 0 }}>
              <Container>
                <Row>
                  <Col style={{ marginBottom: "15px", padding: "0px 1.25rem" }}>
                    <Form
                      ref={(ref) => (this.form = ref)}
                      onChange={(formValue) => {
                        this.setState({ formValue });
                      }}
                      onCheck={(formError) => {
                        this.setState({ formError });
                      }}
                      formValue={formValue}
                      model={model}
                      fluid
                    >
                      <FormGroup>
                        <ControlLabel>
                          {t("TITLE_CLONE_MODEL_NAME")}
                        </ControlLabel>
                        <FormControl
                          disabled={loading}
                          name="modelName"
                          placeholder={t("TITLE_CLONE_MODEL_NAME_PLACEHOLDER")}
                        />
                      </FormGroup>
                      <FormGroup>
                        <ControlLabel>
                          {t("TITLE_CLONE_MODEL_MODULE")}
                        </ControlLabel>
                        <FormControl
                          disabled={loading}
                          accepter={SelectPicker}
                          block
                          cleanable={false}
                          data={this.props.modules?.dynamicValue.map(
                            (item, key) => {
                              return {
                                label: item.name,
                                value: item.id,
                              };
                            }
                          )}
                          name="moduleId"
                          placeholder={t(
                            "TITLE_CLONE_MODEL_MODULE_PLACEHOLDER"
                          )}
                        />
                      </FormGroup>
                      <FormGroup>
                        <ControlLabel>
                          {t("TITLE_ASK_IS_DB_OBJECT")}
                        </ControlLabel>
                        <FormControl
                          disabled={loading}
                          accepter={Checkbox}
                          block
                          cleanable={false}
                          name="isDBObject"
                        />
                      </FormGroup>
                      <FormGroup>
                        <ControlLabel>
                          {t("TITLE_ASK_IS_ALLOW_TRASACTION")}
                        </ControlLabel>
                        <FormControl
                          disabled={loading}
                          accepter={Checkbox}
                          block
                          cleanable={false}
                          name="isDataBaseFalseObjectAllowTransaction"
                        />
                      </FormGroup>

                      <FormGroup>
                        <ButtonToolbar className={"creator-toobar"}>
                          <Button
                            disabled={loading}
                            loading={loading}
                            appearance="primary"
                            onClick={this.handleSubmit}
                          >
                            {t("BUTTON_CLONE")}
                          </Button>
                          <Button
                            disabled={loading}
                            appearance="default"
                            onClick={this.handleCloseModal}
                          >
                            {t("BUTTON_CANCEL")}
                          </Button>
                        </ButtonToolbar>
                      </FormGroup>
                    </Form>
                  </Col>
                </Row>

                {/* <Row className={"member-info-action-row"}>
                                    <Button appearance='subtle' block>Block</Button>
                                        </Row>*/}
              </Container>
            </div>
          </Modal>
        )}
      </Translation>
    );
  }
}
const mapStateToProps = (state, props) => {
  const { workspaceReducer } = state;
  const {
    workspace,
    selectedService,
    dataModels,
    modules,
    module,
    models,
    allTransactions,
    allServices,
  } = workspaceReducer;

  return {
    workspace,
    selectedService,
    dataModels,
    modules,
    module,
    models,
    allTransactions,
    allServices,
  };
};

const mapDispatchToProps = {
  updateSelectedService,
  updateSelectedTransaction,
  updatedModels,
  updateSelectedModel,
  updatedTransactions,
  updateAllTransactions,
};

export default connect(mapStateToProps, mapDispatchToProps)(CloneModel);
