import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translation } from "react-i18next";
import i18n from "i18n";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";


import { Container, Header, Content, Sidebar } from 'rsuite';
import { Icon, IconButton } from 'rsuite';
import { Row, Col, Panel, Placeholder, Badge, Button } from 'rsuite';

import { Loader, Navbar, Nav, Dropdown, ButtonToolbar, ButtonGroup } from 'rsuite';

import NoMatchWorkspace from './NoMatchWorkspace'

import Workspace from './Workspace';
import { updateWorkspace } from '_actions'

const { Paragraph } = Placeholder;

class Workspaces extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
        }

    }
    getRoutes = () => {
        return this.props.workspaces.dynamicValue.length < 1 ? [] : this.props.workspaces.dynamicValue.map((prop, key) => {
            const layout = "/workspace/"
             return (
                <Route
                    path={layout +prop.id}
                    component={Workspace}
                    key={key}
                />
            );

        });
    };


    render() {
        const { workspaces } = this.props;
        const { isLoading } = this.state;
        return (
            <Translation>
                {t => (
                    <div className={"global-view"}>
                        <Switch>
                            {this.getRoutes()}
                            <Route path="*">
                                <NoMatchWorkspace />
                            </Route>
                        </Switch>

                    </div>
                )}
            </Translation>
        )
    }
}

const mapStateToProps = (state, props) => {
    const { workspaceReducer } = state;
    const { workspace, workspaces, selectedService } = workspaceReducer;

    return {
        workspace,
        workspaces,
        selectedService
    };
}


const mapDispatchToProps = {
    updateWorkspace
}

export default connect(mapStateToProps, mapDispatchToProps)(Workspaces)

const Card = props => (

    <Badge content={props.demoBadge == 10} style={{ width: "100%", }}>
        <Button onClick={props.handleSelect} appearance="subtle" style={{ width: "100%", padding: 0 }}>
            <Panel header={props.header} bordered shaded>
                <Paragraph />
            </Panel>
        </Button>
    </Badge>
);