import {
    //ServiceURLs,
    ErpRolesAndUsersRelationsEndpoints,
    GetBaseERPURL,
    MakeReqestAndGetResponse,
    GetUserLang,
  } from "./index";
  import { HTTPOperationType } from "./httpOperations";
  
  export const erpRolesAndUserRelationsService = {
    GET_USER_ROLES,
    ASSIGN_ROLE_TO_USER,
    REMOVE_ROLE_FROM_USER
 
  };
 

  function GET_USER_ROLES(_RelWorkSpaceId,_RelERP_WORK_PLACE,_RelOAuthUserId) {
    var js = {
      RelWorkSpaceId:_RelWorkSpaceId,
      ErpWorkPlace :_RelERP_WORK_PLACE,
      RelOAuthUserId:_RelOAuthUserId
    };

    return MakeReqestAndGetResponse(
      GetBaseERPURL() + ErpRolesAndUsersRelationsEndpoints.GET_USER_ROLES,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }

  function ASSIGN_ROLE_TO_USER(_RelWorkSpaceId,_RelERP_WORK_PLACE,_RelOAuthUserId,_RelErpRoleId) {
    var js = {
      RelWorkSpaceId:_RelWorkSpaceId,
      ErpWorkPlace :_RelERP_WORK_PLACE,
      RelOAuthUserId:_RelOAuthUserId,
      RelErpRoleId:_RelErpRoleId
    };

    return MakeReqestAndGetResponse(
      GetBaseERPURL() + ErpRolesAndUsersRelationsEndpoints.ASSIGN_ROLE_TO_USER,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }

  function REMOVE_ROLE_FROM_USER(_RelWorkSpaceId,_RelERP_WORK_PLACE,_RelOAuthUserId,_RelErpRoleId) {
    var js = {
      RelWorkSpaceId:_RelWorkSpaceId,
      ErpWorkPlace :_RelERP_WORK_PLACE,
      RelOAuthUserId:_RelOAuthUserId,
      RelErpRoleId:_RelErpRoleId
    };

    return MakeReqestAndGetResponse(
      GetBaseERPURL() + ErpRolesAndUsersRelationsEndpoints.REMOVE_ROLE_FROM_USER,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }
 