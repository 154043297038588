import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { ActionCreators as UndoActionCreators } from "redux-undo";
import Emitter from "_actions/emitter";
import i18n from "i18n";
import { ResponseStatusCode } from "_helpers";
import { addNewPage, removeComponent } from "_actions";
import { WorkspaceService } from "_services";
import { Button, Alert, MultiCascader, Loader, Checkbox, Toggle } from "rsuite";

import { Input, Modal, Container, Row, Col } from "reactstrap";

class CloneWorkspace extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPreparing: false,
      isLoading: false,
      customStyles: {
        pageName: "",
        pageNameState: null,
        alert: null,
        withRoles: true,
      },
      prepeparedModules: [],
      prepeparedModulesSelecteds: [],
    };
    this.myRef = React.createRef();
  }

  successAlert = (data) => {
    this.setState({
      alert: (
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={data.title}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText={i18n.t("BUTTON_OK")}
          btnSize=""
        >
          {data.desc}
        </ReactBSAlert>
      ),
    });
  };
  warningAlert = (data) => {
    this.setState({
      alert: (
        <div className={"modal-sweet-alert"}>
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title={data.title}
            onConfirm={() => this.hideAlert()}
            onCancel={() => this.hideAlert()}
            confirmBtnBsStyle="warning"
            confirmBtnText={i18n.t("BUTTON_OK")}
            btnSize=""
          >
            {data.desc}
          </ReactBSAlert>
        </div>
      ),
    });
  };
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  handlePrepareModules = () => {
    const { data } = this.props;
    let _state = this.state.customStyles;
    const that = this;
    that.setState({ isPreparing: true });

    //Platform,ModuleIds,RelWorkspaceId

    WorkspaceService.PREPARE_WORKSPACE_FOR_CLONE(data.id)
      .then((data) => {
        that.setState(
          {
            prepeparedModules: data.dynamicValue,
          },
          () => {
            that.handleSelectAllCopyData();
          }
        );
      })
      .catch((err) => {
        Alert.warning(ResponseStatusCode(err.statusCode));
        that.setState({ isPreparing: false });
        debugger;
      });
  };

  handleSelectAllCopyData = () => {
    let _list = [...this.state.prepeparedModules];

    let selectedIds = [];

    _list.forEach((e1) => {
      e1.modelsDetail.forEach((e2) => {
        selectedIds.push(e1.relModule.id + "-" + e2.relModel.id);
      });
    });
    this.setState({
      prepeparedModulesSelecteds: selectedIds,
      isPreparing: false,
    });
  };

  handleSelectUpdateCopyData = () => {
    let _list = [...this.state.prepeparedModules];
    debugger;
    try {
      _list.forEach((e1) => {
        e1.modelsDetail.forEach((e2) => {
          if (
            this.state.prepeparedModulesSelecteds.find(
              (x) => e1.relModule.id + "-" + e2.relModel.id == x
            )
          ) {
            e2.copyData = true;
          } else {
            e2.copyData = false;
          }
        });
      });
    } catch (error) {
      debugger;
    }

    debugger;
    return _list;
  };

  handleCloneWorkspace = async () => {
    const {
      clearHistory,
      onRefresh,
      handleToggle,
      removeComponent,
      AddPageToProject,
      data,
    } = this.props;
    let _state = this.state.customStyles;
    const that = this;
    const prepeparedModules = this.state.prepeparedModules;

    that.setState({ isLoading: true });

    const prepareCopyData = await this.handleSelectUpdateCopyData();

    await WorkspaceService.CLONE_WORKSPACE(
      data.id,
      _state.pageName,
      prepareCopyData,
      _state.withRoles
    )
      .then((data) => {
        debugger;
        Alert.success(i18n.t("SUCCESS_CLONED"));
        onRefresh();
        that.setState({ isLoading: false });
        handleToggle();
      })
      .catch((err) => {
        Alert.warning(ResponseStatusCode(err.statusCode));
        that.setState({ isLoading: false });
        debugger;
        //  let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
        //   this.warningAlert(data);
      });
  };

  customStylesForm = (e, stateName) => {
    let newState = this.state.customStyles;
    newState[stateName] = e.target.value;
    if (stateName === "checkbox") {
      if (e.target.value) {
        newState.checkboxState = "valid";
      } else {
        newState.checkboxState = "invalid";
      }
    } else {
      if (e.target.value === "") {
        newState[stateName + "State"] = "invalid";
      } else {
        newState[stateName + "State"] = "valid";
      }
    }
    this.setState({
      customStyles: newState,
    });
  };

  onKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      this.validateCustomStylesForm();
    }
  };

  validateCustomStylesForm = () => {
    let newState = this.state.customStyles;
    newState.pageName = newState.pageName.trim();

    var regex = /^[a-zA-Z][a-zA-Z0-9_]{2,}$/;

    if (newState.pageName === "") {
      newState.pageNameState = "invalid";
    } else if (!regex.test(newState.pageName)) {
      newState.pageNameState = "invalid";
    } else {
      newState.pageNameState = "valid";
    }

    this.setState(
      {
        customStyles: newState,
      },
      () => {
        if (newState.pageNameState == "valid") {
          this.handleCloneWorkspace();
        }
      }
    );
  };

  resetContent = () => {
    const { data } = this.props;
    const that = this;

    this.setState(
      {
        isPreparing: false,
        isLoading: false,
        customStyles: {
          pageName: "",
          pageNameState: null,
          alert: null,
          withRoles: true,
        },
        prepeparedModules: [],
        prepeparedModulesSelecteds: [],
      },
      () => {
        that.handlePrepareModules();
      }
    );
  };

  componentDidUpdate(prevProps) {
    if (this.props.isActive)
      if (prevProps.isActive != this.props.isActive && this.props.isActive) {
        this.resetContent();
      }
  }

  render() {
    const { isActive, handleToggle } = this.props;
    const { isLoading, isPreparing, prepeparedModules } = this.state;
    return (
      <Translation>
        {(t) => (
          <>
            <Modal
              className="modal-dialog-centered"
              isOpen={isActive}
              //size="lg"
              // scrollable={true}
            >
              <div className="modal-header">
                <h5 className="modal-title" id="CheckProjectModal">
                  {t("MODAL_TITLE_CLONE_WORKSPACE")}
                </h5>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  disabled={this.state.isLoading}
                  onClick={() => handleToggle()}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body" style={{
                paddingTop:0,
                paddingBottom:0
              }}>
                <Container>
                  <Row>
                    <div>
                      <label
                        className="form-control-label"
                        htmlFor="validationCustomPagename"
                      >
                        {t("MODAL_CREATE_WORKSPACE_TEXTBOX_NAME")}
                      </label>
                    </div>

                    {!isPreparing && (
                      <Input
                        innerRef={(input) => {
                          setTimeout(() => {
                            try {
                              input.focus();
                            } catch (error) {}
                          }, 300);
                        }}
                        autoFocus
                        aria-describedby="inputGroupPrepend"
                        id="validationCustomPagename"
                        placeholder={t(
                          "MODAL_CREATE_WORKSPACE_TEXTBOX_NAME_PLACEHOLDER"
                        )}
                        type="text"
                        onKeyDown={this.onKeyDown.bind(this)}
                        defaultValue={this.state.customStyles.pageName}
                        valid={
                          this.state.customStyles.pageNameState === "valid"
                        }
                        invalid={
                          this.state.customStyles.pageNameState === "invalid"
                        }
                        disabled={isLoading}
                        onChange={(e) => this.customStylesForm(e, "pageName")}
                      />
                    )}

                    <div className="invalid-feedback">
                      {t("MODAL_CREATE_WORKSPACE_TEXTBOX_NAME_INVALID")}
                    </div>
                  </Row>

               

                  {prepeparedModules.length > 0 && (
                    <Row style={{ marginTop: "10px", flexDirection: "column" }}>
                      <div>
                        <label
                          className="form-control-label"
                          htmlFor="validationCustomLang"
                        >
                          {t("MODAL_CREATE_STORE_TEXTBOX_COPY_DATA")}
                        </label>
                        <Button
                          onClick={this.handleSelectAllCopyData}
                          size="xs"
                          appearance="link"
                          style={{
                            fontSize: "10px",
                          }}
                        >
                          {"(Select all)"}
                        </Button>
                      </div>

                      <MultiCascader
                        onClean={() => {
                          this.setState({
                            prepeparedModulesSelecteds: [],
                          });
                        }}
                        cleanable={true}
                        placement={"autoVertical"}
                        onChange={(value, a, s, d) => {
                          const that = this;
                          this.setState({
                            prepeparedModulesSelecteds: value,
                          });
                        }}
                        placeholder={t("MODAL_CREATE_STORE_TEXTBOX_COPY_DATA")}
                        block
                        value={this.state.prepeparedModulesSelecteds}
                        data={[
                          ...prepeparedModules.map((item, key) => {
                            return {
                              value: item.relModule.id,
                              label: item.relModule.name,
                              children: [
                                ...item.modelsDetail.map((ii, kk) => ({
                                  value:
                                    item.relModule.id + "-" + ii.relModel.id,
                                  label:
                                    ii.relModel.modelName +
                                    ` - (${ii.dataCount} data)`,
                                  data: ii,
                                  main: item,
                                })),
                              ],
                            };
                          }),
                        ]}
                        uncheckableItemValues={[
                          ...prepeparedModules.map((item, key) => {
                            return item.relModule.id;
                          }),
                        ]}
                      />
                    </Row>
                  )}

<Row style={{ marginTop: "10px", flexDirection: "column" }}>
                    <div>
                      <label
                        className="form-control-label"
                        htmlFor="validationCustomPagename"
                      >
                        {t("MODAL_CREATE_WORKSPACE_CHECKBOX_WITH_ROLES")}
                      </label>
                    </div>

                    {!isPreparing && (
                      <div>
                        <Toggle
                        disabled={isLoading}
                        defaultChecked={this.state.customStyles}
                        onChange={(checked) => {
                          let _new = Object.assign({}, this.state.customStyles);
                          _new.withRoles = checked;
                        }}
                      />
                      </div>
                    )}

                    <div className="invalid-feedback">
                      {t("MODAL_CREATE_WORKSPACE_TEXTBOX_NAME_INVALID")}
                    </div>
                  </Row>
                </Container>
              </div>
              <div className="modal-footer">
                <Button
                  color="white"
                  size={"sm"}
                  data-dismiss="modal"
                  disabled={this.state.isLoading}
                  type="button"
                  onClick={() => handleToggle()}
                >
                  {t("BUTTON_CANCEL")}
                </Button>

                <Button
                  loading={this.state.isLoading}
                  color="primary"
                  size={"sm"}
                  type="button"
                  onClick={() => this.validateCustomStylesForm()}
                >
                  {t("BUTTON_CLONE")}
                </Button>

                {/*
                                          <CButton
                                    color="primary"
                                    size={"sm"}
                                    loading={this.state.loading}
                                    disabled={(this.state.loading || (this.state.customStyles.nameState == "invalid"))}
                                    value={data ? t("BUTTON_UPDATE") : t("BUTTON_CREATE")}
                                    onClick={() => { this.validateCustomStylesForm() }}>
                                </CButton>
                                    */}
              </div>
              {this.state.alert}
              {(isPreparing || isLoading) && <Loader backdrop vertical />}
            </Modal>
          </>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const {} = state;

  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(CloneWorkspace);
