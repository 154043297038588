import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import moment from "moment";
import Emitter from "_actions/emitter";
import { SocketOperationLocal } from "_socketOperations";
import SwipeableViews from "react-swipeable-views";

import i18n from "i18n";
import { ResponseStatusCode } from "_helpers";
import { updateStore, updateStoreCategories } from "_actions";
import { ErpEngineService, DashBoardURLSService } from "_services";
import {
  InputGroup,
  Input,
  Icon,
  Alert,
  SelectPicker,
  IconButton,
} from "rsuite";
import {
  Loader,
  Container,
  Placeholder,
  Drawer,
  Button,
  FlexboxGrid,
  Whisper,
  Tooltip,
  Nav,
} from "rsuite";
import ResponsiveNav from "@rsuite/responsive-nav";
import { Modal } from "reactstrap";
import Terminal from "react-console-emulator";

import CreateURL from "./CreateURL";
import ResponsesConsole from "components/Custom/ResponsesConsole";

const { Paragraph } = Placeholder;
let LANG = i18n.t("LANG");
const WAIT_INTERVAL = 500;
const ENTER_KEY = 13;

class TerminalModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      locked: false,
      increment: 0,
      isProgressing: false,
      progress: 0,
      activeKey: "base",
      termList: ["base"],
      size: 0,
    };
    this.terminalmain0 = React.createRef();
  }

  componentDidMount() {
    for (let index = 0; index < this.props.TerminalList.length; index++) {
      let _aa = [...this.state.termList];
      _aa.push(this.props.TerminalList[index]);
      this.setState({
        termList: _aa,
      });

      this["terminalmain" + (index + 1)] = React.createRef();
    }
    Emitter.on(SocketOperationLocal.TerminalResponse, (newValue) =>
      this.handleGetBaseCommendLine(newValue)
    );
    Emitter.on(SocketOperationLocal.ConsoleResponse, (newValue) =>
      this.handleGetCommendLine(newValue)
    );
  }

  componentWillUnmount() {
    Emitter.off(SocketOperationLocal.TerminalResponse);
    Emitter.off(SocketOperationLocal.ConsoleResponse);
  }

  handleCreateUrl = () => {
    this.setState({
      isCreateUrl: !this.state.isCreateUrl,
    });
  };

  resetContent = () => {};
  componentDidUpdate(prevProps) {
    if (this.props.isActive) {
      if (prevProps.isActive != this.props.isActive && this.props.isActive) {
        this.resetContent();
      }
      if (
        JSON.stringify(this.props.TerminalList) !==
          JSON.stringify(prevProps.TerminalList) ||
        this.props.TerminalList.length !== this.state.termList.length - 1
      ) {
        let _aa = ["base"];
        for (let index = 0; index < this.props.TerminalList.length; index++) {
          _aa.push(this.props.TerminalList[index]);
          this.setState({
            termList: _aa,
          });
          this["terminalmain" + (index + 1)] = React.createRef();
        }

        //this.terminalmain = React.createRef();
      }
    }
  }

  componentWillMount() {
    this.timer = null;
  }

  handleCreateNewTerminal = () => {
    const { workspace, selectedService } = this.props;
    const URL_MODE = "dev_URL";
    const that = this;
    ErpEngineService.CREATE_NEW_TERMINAL(workspace[URL_MODE], workspace.id)
      .then((data) => {
        debugger;
      })
      .catch((err) => {
        Alert.error(i18n.t("MESSAGE_TERMINAL_CREATE_UNSUCCESS"));
        debugger;
      });
  };

  handleSendCommendLine = (commandLine, terminalId) => {
    const { workspace, selectedService } = this.props;
    const URL_MODE = "dev_URL";
    const that = this;
    ErpEngineService.ERP_COMMAN_LINE(
      workspace[URL_MODE],
      commandLine,
      terminalId,
      workspace.id
    )
      .then((data) => {
        // debugger;
      })
      .catch((err) => {
        Alert.error(i18n.t("MESSAGE_ENGINE_OPERATION_UNSUCCESS"));
        debugger;
      });
  };

  handleGetCommendLine = (value) => {
    const that = this;
    const terminal = this.terminalmain0.current;

    //terminal.pushToStdout("data geldi !!");
    terminal.pushToStdout(value);
    terminal.scrollToBottom();
  };

  handleGetBaseCommendLine = (value) => {
    /*
      Guid:"b11d6670-96be-4a7f-b179-818452f637f8"
      out: ""
    */
    const that = this;

    let findedIndex =
      typeof value == "string"
        ? 0
        : this.props.TerminalList.findIndex((x) => x == value.Guid);

    if (findedIndex > -1) {
      const terminal =
        this[
          "terminalmain" +
            (typeof value == "string" ? findedIndex : findedIndex + 1)
        ].current;
      if (terminal) {
        //terminal.pushToStdout("data geldi !!");
        terminal.pushToStdout(typeof value == "string" ? value : value.out);
        terminal.scrollToBottom();
      } else {
        debugger;
      }
    }
  };

  render() {
    const {
      isActive,
      handleToggle,
      store,
      storeCategories,
      user,
      workspace,
      EngineStatus,
      TerminalList,
    } = this.props;

    const sizes = ["sm", "md", "lg", "full"];

    return (
      <Translation>
        {(t) => (
          <>
            <Drawer
              size={sizes[this.state.size]}
              full={this.state.size > 0}
              className={
                "terminal-drawer " + (isActive ? "" : " terminal-drawer-close")
              }
              backdropClassName={
                isActive ? "terminal-drawer-bd" : "terminal-drawer-bd-close"
              }
              placement={"bottom"}
              show={true}
              onHide={handleToggle}
            >
              <Drawer.Header className="terminal-modal">
                <div className="terminal-drawer-h">
                  <div className="engine-status-box">
                    <Whisper
                      placement="auto"
                      trigger="hover"
                      speaker={<Tooltip>{t("TITLE_ENGINE_STATUS")}</Tooltip>}
                    >
                      <div
                        className={
                          "engine-status-color " +
                          (EngineStatus
                            ? " engine-status-on"
                            : " engine-status-off")
                        }
                      />
                    </Whisper>
                  </div>
                  <Drawer.Title>{t("TITLE_TERMINAL")}</Drawer.Title>
                  <Button
                    onClick={() => this.handleCreateNewTerminal()}
                    size={"xs"}
                  >
                    Add new
                  </Button>
                  {/*angle-double-down */}
                  {this.state.size > 0 && (
                    <IconButton
                      size="xs"
                      className="custom-fade-in"
                      icon={<Icon icon={"angle-double-down"} />}
                      onClick={() => {
                        this.setState({
                          size: this.state.size - 1,
                        });
                      }}
                    />
                  )}
                  {this.state.size < 1 && false && (
                    <IconButton
                      size="xs"
                      className="custom-fade-in"
                      icon={<Icon icon={"angle-double-up"} />}
                      onClick={() => {
                        this.setState({
                          size: this.state.size + 1,
                        });
                      }}
                    />
                  )}
                </div>
              </Drawer.Header>
              <div
                style={{ padding: 0 }}
                className="modal-body store-model-body terminal-modal-body"
              >
                <Container style={{ background: "#19141c" }}>
                  <div style={{ padding: "0.3em" }}>
                    <Nav
                      //removable
                      appearance="tabs"
                      className={"terminal-tabs"}
                      activeKey={this.state.activeKey}
                      onSelect={(value) => {
                        this.setState({ activeKey: value });
                      }}
                    >
                      <Nav.Item key={"responses"} eventKey={"responses"}>
                        {"Responses"}
                      </Nav.Item>
                      <Nav.Item key={"base"} eventKey={"base"}>
                        {"Base"}
                      </Nav.Item>
                      {[...TerminalList].map((item) => (
                        <Nav.Item key={item} eventKey={item}>
                          {item}
                        </Nav.Item>
                      ))}
                    </Nav>
                    <div style={{ maxHeight: "40vh" }}>
                      {workspace && (
                        <SwipeableViews
                          style={{
                            minHeight: "40vh",
                            //background: "red",
                            padding: "5px",
                          }}
                          index={[
                            "responses",
                            "base",
                            ...TerminalList,
                          ].findIndex((x) => x == this.state.activeKey)}
                          ref={(el) => (this.reactSwipeEl = el)}
                        >
                          <ResponsesConsole />
                          {[...this.state.termList].map((item, key) => {
                            const _fName =
                              item == "base"
                                ? "terminalmain0"
                                : "terminalmain" + key;
                            return (
                              <div key={_fName}>
                                {/*<p>{_fName}</p> */}
                                <Terminal
                                  ref={this[_fName]}
                                  style={{
                                    maxHeight: "35vh",
                                    minHeight: "34vh",
                                    borderRadius: "6px",
                                  }}
                                  autoFocus={true}
                                  welcomeMessage="Wellcome to TheXApp"
                                  promptLabel={
                                    user.name.toLowerCase() +
                                    "@" +
                                    workspace.name +
                                    ":~$"
                                  }
                                  commands={{}}
                                  promptLabelStyle={{ color: "#2baebf" }}
                                  readOnly={item == "base"}
                                  disabled={this.state.isProgressing}
                                  locked={this.state.isProgressing}
                                  errorText={" "}
                                  commandCallback={(commandResult) =>
                                    this.handleSendCommendLine(
                                      commandResult.command,
                                      item
                                    )
                                  }
                                />
                              </div>
                            );
                          })}
                        </SwipeableViews>
                      )}
                    </div>
                  </div>
                  {false && (
                    <div className={"fade-in"}>
                      <Loader backdrop vertical />
                    </div>
                  )}
                </Container>
              </div>
            </Drawer>

            {this.state.alert}
          </>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer, authentication } = state;
  const { loggedIn, LoginFailed, user } = authentication;

  const {
    workspace,
    workspaces,
    selectedService,
    store,
    storeCategories,
    EngineStatus,
    TerminalList,
  } = workspaceReducer;

  return {
    workspace,
    workspaces,
    selectedService,
    store,
    storeCategories,
    user,
    EngineStatus,
    TerminalList,
  };
};

const mapDispatchToProps = {
  updateStore,
  updateStoreCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(TerminalModal);
