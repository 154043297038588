import axios from "axios";

import {
  ServiceURLs,
  UserEndPoints,
  GetBaseConnectURL,
  GetBaseStorageURL,
  StorageEndPoints,
} from "./_serviceSettings";

const FileDownload = require("js-file-download");

var createGuest = require("cross-domain-storage/guest");

export function getSession() {
  try {
    let get = localStorage.getItem("user");
    if (get === null || get === undefined || get === "null") {
      return new Promise(function (resolve, reject) {
        var bazStorage = createGuest("http://localhost:3020/getsession");
        bazStorage.get("user", function (error, value) {
          if (error) {
            reject(error);
          } else {
            localStorage.setItem("user", value);
            resolve(value);
          }
        });
      });
    } else return Promise.resolve(get);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchTheData(someValue) {
  return new Promise(function (resolve, reject) {
    var bazStorage = createGuest("https://auth.mobilex360.io/getsession");
    bazStorage.get(someValue, function (error, value) {
      if (error) {
        reject(error);
      } else {
        resolve(value);
      }
    });
  });
}

export async function MakeReqestAndGetResponse(
  OperationUrl,
  _HTTPOperationType,
  JsonParams = null
) {
  let request;
  let ress;
  try {
    /*
    let res=await getSession();
    var token = (res!=="" && res!==null && res!=="null" && res!==undefined)?
    JSON.parse(getSession).token:"";
   */

    var token =
      localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user")).token;

    request = client(token);

    var js = JSON.stringify(JsonParams);
    //console.log(js);
    switch (_HTTPOperationType) {
      case HTTPOperationType.get:
        ress = await request.get(OperationUrl);
        break;
      case HTTPOperationType.post:
        ress = await request.post(OperationUrl, js);
        break;
      case HTTPOperationType.put:
        ress = await request.put(OperationUrl, js);
        break;
      case HTTPOperationType.delete:
        ress = await request.delete(OperationUrl);
        break;
      default:
        break;
    }

    if (ress.data.isLoginRequired === false) return ress.data;
    else {
      localStorage.removeItem("user");
      window.location.reload(true);
    }
  } catch (error) {
    console.log(error);

    // request = client(token);
    //eger token dusmus ise

    await request
      .get(GetBaseConnectURL() + UserEndPoints.GET_MY_INFORMATION)
      .then((xx) => {
        if (xx.data.isLoginRequired === true) {
          localStorage.removeItem("user");
          window.location.reload(true);
        }
      })
      .catch((xx) => {
        if (xx.response.status === 401) {
          localStorage.removeItem("user");
          window.location.reload(true);
        }
      });

    return ress.data;
  }
}

export async function MakeReqestBlob(
  OperationUrl,
  _HTTPOperationType = HTTPOperationType.post,
  JsonParams = null
) {
  let request;
  let ress;
  try {
    /*
    let res=await getSession();
    var token = (res!=="" && res!==null && res!=="null" && res!==undefined)?
    JSON.parse(getSession).token:"";
   */

    var token =
      localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user")).token;

    request = clientBlob(token);

    var js = JSON.stringify(JsonParams);

    //console.log(js);

    try {
      debugger;
      switch (_HTTPOperationType) {
        case HTTPOperationType.get:
          ress = await request.get(OperationUrl);
          break;
        case HTTPOperationType.post:
          ress = await request.post(OperationUrl, js);
          break;
        case HTTPOperationType.put:
          ress = await request.put(OperationUrl, js);
          break;
        case HTTPOperationType.delete:
          ress = await request.delete(OperationUrl);
          break;
        default:
          break;
      }
    } catch (error) {
      debugger;
    }

    if (ress.data) {
      debugger;
      return {data:ress.data,res:ress};
      //  return ress.data;
    }
  } catch (error) {
    console.log(error);

    // request = client(token);
    //eger token dusmus ise

    return ress.data;
  }
}

export const HTTPOperationType = {
  get: "get",
  post: "post",
  put: "put",
  delete: "delete",
};

export const client = (token = null) => {
  const defaultOptions = {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
   crossdomain: true,
  };
  return {
    get: (FullURL, options = {}) =>
      axios.get(FullURL, {
        ...defaultOptions,
        ...options,
      }),
    post: (FullURL, data, options = {}) =>
      axios.post(FullURL, data, {
        ...defaultOptions,
        ...options,
      }),
    put: (FullURL, data, options = {}) =>
      axios.put(FullURL, data, {
        ...defaultOptions,
        ...options,
      }),
    delete: (FullURL, options = {}) =>
      axios.delete(FullURL, {
        ...defaultOptions,
        ...options,
      }),
  };
};
export const clientBlob = (token = null) => {
  const defaultOptions = {
    responseType: "blob",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json",
    },
  };
  return {
    get: (FullURL, options = {}) =>
      axios.get(FullURL, {
        ...defaultOptions,
        ...options,
      }),
    post: (FullURL, data, options = {}) =>
      axios.post(FullURL, data, {
        ...defaultOptions,
        ...options,
      }),
    put: (FullURL, data, options = {}) =>
      axios.put(FullURL, data, {
        ...defaultOptions,
        ...options,
      }),
    delete: (FullURL, options = {}) =>
      axios.delete(FullURL, {
        ...defaultOptions,
        ...options,
      }),
  };
};

export async function UploadFile(files, SettingID, Name, RelProjectId) {
  var token =
    localStorage.getItem("user") &&
    JSON.parse(localStorage.getItem("user")).token;

  const config = {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "content-type": "multipart/form-data",
    },
  };
  const formData = new FormData();
  formData.append("SettingID", SettingID);
  //formData.append("file", this.state.pictures);

  for (var index = 0; index < files.length; index++) {
    var element = files[index];
    formData.append("file", element);
  }
  formData.append("Name", Name);
  formData.append("RelProjectId", RelProjectId);

  let res = await axios.post(
    GetBaseStorageURL() + StorageEndPoints.UPLOAD,
    formData,
    config
  );
  return res.data;
}

export async function UploadFileBase64(file, settingID, Name, RelProjectId) {
  var token =
    localStorage.getItem("user") &&
    JSON.parse(localStorage.getItem("user")).token;

  const config = {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "content-type": "multipart/form-data",
    },
  };
  const formData = new FormData();
  //formData.append("file", this.state.pictures);
  formData.append("SettingID", settingID);
  formData.append("Image", file);
  formData.append("Name", Name);
  formData.append("RelProjectId", RelProjectId);

  let res = await axios.post(
    GetBaseStorageURL() + StorageEndPoints.UPLOAD_BASE64,
    formData,
    config
  );
  return res.data;
}

export async function UploadSound(file, Name, RelProjectId) {
  var token =
    localStorage.getItem("user") &&
    JSON.parse(localStorage.getItem("user")).token;

  const config = {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "content-type": "multipart/form-data",
    },
  };
  const formData = new FormData();
  //formData.append("file", this.state.pictures);

  formData.append("Stream", file);
  formData.append("Name", Name);
  formData.append("RelProjectId", RelProjectId);

  let res = await axios.post(
    GetBaseStorageURL() + StorageEndPoints.UPLOAD_BASE64SOUND,
    formData,
    config
  );
  return res.data;
}

export async function UploadVideo(files, Name, RelProjectId) {
  var token =
    localStorage.getItem("user") &&
    JSON.parse(localStorage.getItem("user")).token;

  const config = {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "content-type": "multipart/form-data",
    },
  };
  const formData = new FormData();
  //formData.append("SettingID", SettingID);
  //formData.append("file", this.state.pictures);
  for (var index = 0; index < files.length; index++) {
    var element = files[index];
    formData.append("file", element);
  }
  formData.append("Name", Name);
  formData.append("RelProjectId", RelProjectId);

  let res = await axios.post(
    GetBaseStorageURL() + StorageEndPoints.UPLOAD_VIDEO,
    formData,
    config
  );
  return res.data;
}
