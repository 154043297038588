import React, { Component } from "react";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import { Icon } from "rsuite";

const DragHandle = sortableHandle(() => (
  <Icon style={{ color: "#2baebf" }} icon={"arrows"} />
));

const SortableItem1 = sortableElement(({ value, extra, dragable }) => (
  <li className="dndsitem">
    {value}
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      {dragable && <DragHandle />}
      {extra}
    </div>
  </li>
));

export default function SortableItem(props) {
  return <SortableItem1 {...props} />;
}
