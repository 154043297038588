import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import i18n from "i18n";

import { Icon, List } from "rsuite";
import { FormControl, Input, InputGroup, IconButton } from "rsuite";

import DynamicTable from "./DynamicTable";
import SearchObjectModal from "./Modals/SearchObjectModal";

class FormListElement extends Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
    const { formValueOrj } = this.props;
    const _cData = this.props.data;
    const formValue = formValueOrj ? formValueOrj : this.props.formValue;
    let _elementValue = [];
    if (formValue && formValue.hasOwnProperty(_cData.name)) {
      _elementValue = formValue[_cData.name];
    }
    debugger;
    this.state = {
      listData: _elementValue ? _elementValue : [],
      textValue: "",
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const _cData = this.props.data;
    const formValue = this.props.formValue;
    if (
      JSON.stringify(formValue[_cData.name]) !=
        JSON.stringify(prevProps.formValue[_cData.name]) &&
      JSON.stringify(formValue[_cData.name]) !=
        JSON.stringify(this.state.listData)
    ) {
      this.setState({
        listData: formValue[_cData.name],
        textValue: "",
      });
    }
  }

  updateList = (_value) => {
    debugger;
    const { data, isObject, runTransaction, _extra, allServices } = this.props;
    const { formRef, formValue, formOnChange } = this.props;

    const _key = data.name;
    let _obj = Object.assign({}, formValue);
    _obj[_key] = _value; //_cValueIsObj;

    formOnChange(_obj);
  };

  setAdvancedDefaults = () => {
    const item = this.props.data;

    let _inputs = {};
    item.advanced.forEach((x) => {
      try {
        let _v = null;
        switch (x.currentContentType) {
          case 3:
            _v = parseFloat(x.defaultValue);
            break;

          case 7:
            _v = x.defaultValue.toLowerCase() == "true";
            break;

          default:
            break;
        }
        _inputs[x.name] = _v;
      } catch (error) {}
    });

    let _events = {};
    item.events.forEach((x) => {
      //debugger;
      if (x.defaultValue) {
        _events[x.name] = JSON.parse(x.defaultValue);
      }
    });
    /*
    this.setState({
      cInputs: _inputs,
      advanceFormValue: _inputs,
      cEvents: _events,
    });
    */
    return {
      advanced: _inputs,
      events: _events,
    };
  };

  handleAddNewIndex = (data) => {
    let _data = data.trim();
    if (_data.length > 0) {
      let _listData = [...this.state.listData];
      _listData.push(data);
      this.setState({
        listData: _listData,
        textValue: "",
      });
      this.updateList(_listData);
    } else {
      this.setState({
        textValue: _data,
      });
    }
  };

  handleDeleteIndex = (index) => {
    let _listData = [...this.state.listData];
    _listData.splice(index, 1);
    this.setState({
      listData: _listData,
    });
    this.updateList(_listData);
  };

  onKeyDown = (event) => {
    if (event.key === "Enter") {
      this.handleAddNewIndex(this.state.textValue);
      event.preventDefault();
    }
  };

  render() {
    const {
      transaction,
      readOnly,
      data,
      models,
      loading,
      isObject,
      selectedService,
      formValue,
      allServices,
    } = this.props;
    const _extra = this.setAdvancedDefaults();
    /*

*/
    const _listData = formValue.hasOwnProperty(data.name)
      ? formValue[data.name]
      : [];

    const { listData } = this.state;

    return (
      <Translation>
        {(t) => (
          <>
            <div className={"refFormControl"}>
              <List className="elementList " bordered>
                {[...listData].map((item, index) => (
                  <List.Item
                    key={index}
                    index={index}
                    className="elementListItem custom-fade-in"
                  >
                    {item}
                    {!readOnly && (
                      <IconButton
                        size="md"
                        icon={<Icon icon="trash" />}
                        onClick={() => {
                          this.handleDeleteIndex(index);
                        }}
                      />
                    )}
                  </List.Item>
                ))}
                {listData.length == 0 && (
                  <List.Item className="empty-list-item">
                    {t("MESSAGE_EMPTY_LIST_ELEMENT")}
                  </List.Item>
                )}
                {!readOnly && (
                  <List.Item>
                    <InputGroup style={{}}>
                      <Input
                        type={data.typeDetail === 11 ? "number" : "text"}
                        placeholder={t("PLACEHOLDER_ADD_NEW_LIST_ITEM")}
                        value={this.state.textValue}
                        onChange={(value) => {
                          this.setState({ textValue: value });
                        }}
                        onKeyDown={this.onKeyDown}
                      />
                      <InputGroup.Button
                        onClick={() => {
                          this.handleAddNewIndex(this.state.textValue);
                        }}
                      >
                        <Icon icon="plus" style={{ marginRight: "10px" }} />
                        {t("BUTTON_ADD")}
                      </InputGroup.Button>
                    </InputGroup>
                  </List.Item>
                )}
              </List>
            </div>
          </>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer } = state;
  const {
    models,
    transaction,
    workspace,
    dataModels,
    selectedService,
    allServices,
  } = workspaceReducer;

  return {
    workspace,
    transaction,
    dataModels,
    models,
    selectedService,
    allServices,
  };
};

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(FormListElement);
