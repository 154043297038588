import {
    //ServiceURLs,
    ErpLangsEndPoint,
    GetBaseERPURL,
    MakeReqestAndGetResponse,
    GetUserLang,
  } from "./index";
  import { HTTPOperationType } from "./httpOperations";
  
  export const erpLangService = {
    GET_LANGS,
    GET_SUPPORTEDLANGS,
    ADD_NEW_LANGS,
    REMOVE_LANGS_SUPPORT,
    GET_WORKSPACE_LANGS,
    GET_WORKSPACE_DUPLICATED_LANGS,
    UPDATE_WORKSPACE_DUPLICATED_LANGS,
    UPDATE_WORKSPACE_ONE_LANGS

  };
  function UPDATE_WORKSPACE_ONE_LANGS(RelWorkspaceId,RelObject) {
    var js = {
      RelWorkspaceId: RelWorkspaceId,
      RelObject:RelObject
    };
    return MakeReqestAndGetResponse(
      GetBaseERPURL() + ErpLangsEndPoint.UPDATE_WORKSPACE_ONE_LANGS,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }
  function UPDATE_WORKSPACE_DUPLICATED_LANGS(RelWorkspaceId,LangsValues) {
    var js = {
      RelWorkspaceId: RelWorkspaceId,
      RelObject:{LangsValues:LangsValues}
    };
    return MakeReqestAndGetResponse(
      GetBaseERPURL() + ErpLangsEndPoint.UPDATE_WORKSPACE_DUPLICATED_LANGS,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }
  function GET_WORKSPACE_DUPLICATED_LANGS(RelWorkspaceId) {
    var js = {
      RelWorkspaceId: RelWorkspaceId,
    };
    return MakeReqestAndGetResponse(
      GetBaseERPURL() + ErpLangsEndPoint.GET_WORKSPACE_DUPLICATED_LANGS,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }
  function GET_WORKSPACE_LANGS(RelWorkspaceId) {
    var js = {
      RelWorkspaceId: RelWorkspaceId,
    };
    return MakeReqestAndGetResponse(
      GetBaseERPURL() + ErpLangsEndPoint.GET_WORKSPACE_LANGS,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }
  function GET_LANGS() {
   
    return MakeReqestAndGetResponse(
      GetBaseERPURL() + ErpLangsEndPoint.GET_LANGS,
      HTTPOperationType.get,
       
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }
  function GET_SUPPORTEDLANGS(RelWorkspaceId) {
    var js = {
      RelWorkspaceId: RelWorkspaceId,
    };
    return MakeReqestAndGetResponse(
      GetBaseERPURL() + ErpLangsEndPoint.GET_SUPPORTEDLANGS,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }
  function ADD_NEW_LANGS(RelWorkspaceId,Lang_id) {
    var js = {
      RelWorkspaceId: RelWorkspaceId,
      id:Lang_id
    };
    return MakeReqestAndGetResponse(
      GetBaseERPURL() + ErpLangsEndPoint.ADD_NEW_LANGS,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }
  function REMOVE_LANGS_SUPPORT(RelWorkspaceId,Lang_id) {
    var js = {
      RelWorkspaceId: RelWorkspaceId,
      id:Lang_id
    };
    return MakeReqestAndGetResponse(
      GetBaseERPURL() + ErpLangsEndPoint.REMOVE_LANGS_SUPPORT,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }
 
 
  
  