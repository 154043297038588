import React, { Component } from 'react';
import { Table, IconButton, Icon,Alert } from 'rsuite';

import EditCell from './EditCell'
import ActionCell from './ActionCell'

const { Column, HeaderCell, Cell, Pagination } = Table;



class KrnzTable extends Component {
    constructor(props) {
        super(props)

        this.state = {
            displayLength: 10,
            loading: false,
            page: 1,
            fakeData: [
                {
                    id: "1",
                    c1: "aaa",
                    c2: "bbb",

                },
                {
                    id: "2",
                    c1: "c1 2222",
                    c2: "c2 22222",

                }, {
                    id: "3",
                    c1: "üç",
                    c2: "|||",
                },
                {
                    id: "4",
                    c1: "Abdullah",
                    c2: "Kurnaz",

                }
            ]
        };
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeLength = this.handleChangeLength.bind(this);
    }
    handleChangePage(dataKey) {
        this.setState({
            page: dataKey
        });
    }
    handleChangeLength(dataKey) {
        this.setState({
            page: 1,
            displayLength: dataKey
        });
    }
    getData() {
        const { displayLength, page, fakeData } = this.state;

        return fakeData.filter((v, i) => {
            const start = displayLength * (page - 1);
            const end = start + displayLength;
            return i >= start && i < end;
        });
    }


    handleChange = (id, key, value) => {
        const { fakeData } = this.state;
        const nextData = Object.assign([], fakeData);


        if (typeof id == "object")
            return


        nextData.find(item => item.id === id)[key] = value;

        this.setState({
            fakeData: nextData,
        })

    };
    handleEditState = id => {
        let { fakeData } = this.state;

        const nextData = Object.assign([], fakeData);
        const activeItem = nextData.find(item => item.id === id);
        //debugger
        if (!activeItem)
            return
        if (!activeItem.hasOwnProperty("__isEditXAPPTABLE")) {
            activeItem.__isEditXAPPTABLE = null;
        }
        activeItem.__isEditXAPPTABLE = activeItem.__isEditXAPPTABLE ? null : 'EDIT';
        this.setState({
            fakeData: nextData,
        })
    };

    handleCancelEdit = (id) => {
        Alert.error(id);
        let { fakeData } = this.state;
        const nextData = Object.assign([], fakeData);
        const activeItem = nextData.find(item => item.id === id);
        if (!activeItem.hasOwnProperty("__isEditXAPPTABLE")) {
            activeItem.__isEditXAPPTABLE = null;
        }
        activeItem.__isEditXAPPTABLE = activeItem.__isEditXAPPTABLE ? null : 'EDIT';
        this.setState({
            fakeData: nextData,
        })

    }


    render() {
        const data = this.getData();
        const { loading, displayLength, page, fakeData } = this.state;

        return (
            <div>
                <Table wordWrap  height={400} data={data} loading={loading} affixHeader>
                    {
                        data &&
                        data.length > 0 &&
                        Object.keys(data[0]).map((x, key) => (
                            x != "__isEditXAPPTABLE" &&
                            <Column key={key}  width={200}>
                                <HeaderCell>{x}</HeaderCell>
                                <EditCell height={62} dataKey={x} onChange={this.handleChange} />
                            </Column>
                        ))
                    }
                    <Column flexGrow={1}>
                        <HeaderCell>Action</HeaderCell>
                        <ActionCell dataKey="id" onClick={this.handleEditState} handleCancel={this.handleCancelEdit} />
                    </Column>
                </Table>

                <Pagination
                    lengthMenu={[
                        {
                            value: 10,
                            label: 10
                        },
                        {
                            value: 20,
                            label: 20
                        }
                    ]}
                    activePage={page}
                    displayLength={displayLength}
                    total={fakeData.length}
                    onChangePage={this.handleChangePage}
                    onChangeLength={this.handleChangeLength}
                />
            </div>
        );
    }
}

export default KrnzTable;