import {
  //ServiceURLs,
  MoveInstanceEndPoint,
  GetBaseERPURL,
  MakeReqestAndGetResponse,
  GetUserLang,
} from "./index";
import { HTTPOperationType } from "./httpOperations";

export const moveInstanceService = {
  RUN_ANOTHER_INSTANCE,
  COMPLETED_IMAGE_PUSH,
};

function RUN_ANOTHER_INSTANCE(RelWorkspaceId, _ERP_WORK_PLACE) {
  var js = {
    RelWorkspaceId: RelWorkspaceId,
    _ERP_WORK_PLACE: _ERP_WORK_PLACE,
  };

  return MakeReqestAndGetResponse(
    GetBaseERPURL() + MoveInstanceEndPoint.RUN_ANOTHER_INSTANCE,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

function COMPLETED_IMAGE_PUSH(id, key) {
  var js = {
    id: id,
    key: key,
  };

  return MakeReqestAndGetResponse(
    GetBaseERPURL() + MoveInstanceEndPoint.COMPLETED_IMAGE_PUSH,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}
