import React, { Component } from "react";
import { Translation } from "react-i18next";
import { Modal, Loader, Icon, Button } from "rsuite";

class ConfirmModal extends Component {
  render() {
    const { isActive, handleToggle, loading, title } = this.props;

    return (
      <Translation>
        {(t) => (
          <>
            <Modal
              // className="easyinvoicemodal"
              backdrop="static"
              size={"xs"}
              show={isActive}
              onHide={handleToggle}
            >
              <Modal.Body
                style={{
                  paddingBottom: 0,
                  height: "100%",
                  maxHeight: "76vh",
                  marginTop: "10px",
                }}
              >
                <div className="confirmModal">
                  <Icon
                    icon="exclamation-circle"
                    className="confirmModalIcon"
                  />
                  <div className="confirmMessageDiv">
                    <span className="confirmTitle">{t("warning")}</span>
                    <span className="confirmMsg">{title}</span>
                  </div>

                  {loading && (
                    <div className={"fade-in"}>
                      <Loader backdrop vertical />
                    </div>
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={handleToggle} appearance="primary">
                  {t("BUTTON_OK")}
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </Translation>
    );
  }
}

export default ConfirmModal;
