import {
    //ServiceURLs,
    ModelsEndpoint,
    GetBaseERPURL,
    MakeReqestAndGetResponse,
    GetUserLang,
  } from "./index";
  import { HTTPOperationType } from "./httpOperations";
  
  export const ModelsService = {
    CREATE_MODEL,
    ADD_DATA_TYPES,
    DELETE_DATA_TYPES,
    STEPBYLOAD,
    STEPBYLOAD_W_GROUP,
    UPDATE_MODEL,
    STEPBYLOAD_W_GROUP_W_WORKSPACE,
    UPDATE_FILTER,
    CLONE_MODEL,
    SET_EXTRA_BUTTON,
    UpdateRelationTabs
  };

  /*
  
  ORNEK!! {
    "_id": "6140ac9f66261d7879a26dd9",
    "RelationTabs": [
        {
            "TabName": [{
                    "lang": "en",
                    "textValue": "TEST"
                },
                {
                    "lang": "de",
                    "textValue": "TEST"
                },
                {
                    "lang": "tr",
                    "textValue": "TEST"
                }],
            "CurrentProperty": {
                        "name": "CreditLimit",
                        "contentType": 3,
                        "displayName": "Credit Limit",
                        "typeDetail": 6,
                        "privateField": false,
                        "reqiured": false,
                        "unique": false,
                        "notNull": false,
                        "displayNameMultiLangs": [
                            {
                                "lang": "en",
                                "textValue": "Credit Limit"
                            }
                        ],
                        "events": [],
                        "advanced": [],
                        "isAutopopulate": false,
                        "sendCurrentModel": false,
                        "priority": 0,
                        "editableList": true
                    },
            "Type": "",
            "ForeignTransaction": "",
            "ForeignProperty": {
                        "name": "CreditLimit",
                        "contentType": 3,
                        "displayName": "Credit Limit",
                        "typeDetail": 6,
                        "privateField": false,
                        "reqiured": false,
                        "unique": false,
                        "notNull": false,
                        "displayNameMultiLangs": [
                            {
                                "lang": "en",
                                "textValue": "Credit Limit"
                            }
                        ],
                        "events": [],
                        "advanced": [],
                        "isAutopopulate": false,
                        "sendCurrentModel": false,
                        "priority": 0,
                        "editableList": true
                    }
        }
    ]
}
  */

  function UpdateRelationTabs(id,RelationTabs) {
    var js = {
      id:id,
      RelationTabs:RelationTabs
    };
 
     return MakeReqestAndGetResponse(
      GetBaseERPURL() + ModelsEndpoint.UpdateRelationTabs,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }

  //ExtraButtons dizi olacak.
  function SET_EXTRA_BUTTON(id, RelModuleId,RelWorkSpaceId,ExtraButtons) {
    var js = {
      id:id,
      RelModuleId:RelModuleId,
      RelWorkSpaceId:RelWorkSpaceId,
      ExtraButtons:ExtraButtons
    };
 
     return MakeReqestAndGetResponse(
      GetBaseERPURL() + ModelsEndpoint.SET_EXTRA_BUTTON,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }
  function UPDATE_FILTER(id, RelModuleId,RelWorkSpaceId,Query,QueryDirect) {

    var js = {
      id:id,
      RelModuleId:RelModuleId,
      RelWorkSpaceId:RelWorkSpaceId,
      Query:JSON.stringify(Query),
      QueryDirect:QueryDirect
    };
 
 
     return MakeReqestAndGetResponse(
      GetBaseERPURL() + ModelsEndpoint.UPDATE_FILTER,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }

  function STEPBYLOAD_W_GROUP_W_WORKSPACE(IsOrderByDesc,LastIndexId,Count,SearchTerm,DynamicObj) {
    var js = {
      SearchTerm: SearchTerm,
      Count : Count,
      LastIndexId : LastIndexId,
      IsOrderByDesc:IsOrderByDesc,
      DynamicObj:DynamicObj
    };
  
    return MakeReqestAndGetResponse(
      GetBaseERPURL() + ModelsEndpoint.STEPBYLOAD_W_GROUP_W_WORKSPACE,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }
// postman ornegi ERP > MODELS > CREATE MODEL ya da update model
  function UPDATE_MODEL(RelModuleId,id,DataTypes,IsDatabaseObject,HiddenDataTypes,ExtraButtons,IsDataBaseFalseObjectAllowTransaction=false) {

    var js = {
      id:id,
      RelModuleId:RelModuleId,
      DataTypes:DataTypes,
      HiddenDataTypes,
      ExtraButtons:ExtraButtons
    };
 
    if(!IsDatabaseObject){
      js["IsDataBaseFalseObjectAllowTransaction"]=IsDataBaseFalseObjectAllowTransaction;
    }
     
     return MakeReqestAndGetResponse(
      GetBaseERPURL() + ModelsEndpoint.UPDATE_MODEL,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }
  function STEPBYLOAD_W_GROUP(IsOrderByDesc,LastIndexId,Count,SearchTerm,DynamicObj) {
    var js = {
      SearchTerm: SearchTerm,
      Count : Count,
      LastIndexId : LastIndexId,
      IsOrderByDesc:IsOrderByDesc,
      DynamicObj:DynamicObj
    };
  
    return MakeReqestAndGetResponse(
      GetBaseERPURL() + ModelsEndpoint.STEPBYLOAD_W_GROUP,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }

  function STEPBYLOAD(IsOrderByDesc,LastIndexId,Count,SearchTerm,DynamicObj) {
    var js = {
      SearchTerm: SearchTerm,
      Count : Count,
      LastIndexId : LastIndexId,
      IsOrderByDesc:IsOrderByDesc,
      DynamicObj:DynamicObj
    };
  
    return MakeReqestAndGetResponse(
      GetBaseERPURL() + ModelsEndpoint.STEPBYLOAD,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }
   
  /*
  "ExtraButtons": [
        {
            "ButtonCaptions": [
                {
                    "lang": "en",
                    "textValue": "desc2"
                },
                {
                    "lang": "de",
                    "textValue": "desc2"
                },
                {
                    "lang": "tr",
                    "textValue": "desc2"
                }
            ],
            "RelTransactions": {}
        }],
   */
 

  function CREATE_MODEL(RelModuleId,ModelName,IsDatabaseObject,DataTypes,HiddenDataTypes,ExtraButtons,IsDataBaseFalseObjectAllowTransaction=false) {
    var js = {
      RelModuleId:RelModuleId,
      ModelName:ModelName,
      IsDatabaseObject:IsDatabaseObject,
      DataTypes:DataTypes,
      HiddenDataTypes:HiddenDataTypes,
      ExtraButtons:ExtraButtons
    };
    if(!IsDatabaseObject){
      js["IsDataBaseFalseObjectAllowTransaction"]=IsDataBaseFalseObjectAllowTransaction;
    }
   // debugger
     return MakeReqestAndGetResponse(
      GetBaseERPURL() + ModelsEndpoint.CREATE_MODEL,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }

  function CLONE_MODEL(REL_CLONE_MODEL_ID,RelModuleId,ModelName,IsDatabaseObject,DataTypes,HiddenDataTypes,ExtraButtons,IsDataBaseFalseObjectAllowTransaction=false) {
    var js = {
      id:REL_CLONE_MODEL_ID,
      RelModuleId:RelModuleId,
      ModelName:ModelName,
      IsDatabaseObject:IsDatabaseObject,
      DataTypes:DataTypes,
      HiddenDataTypes:HiddenDataTypes,
      ExtraButtons:ExtraButtons
    };
    if(!IsDatabaseObject){
      js["IsDataBaseFalseObjectAllowTransaction"]=IsDataBaseFalseObjectAllowTransaction;
    }
   // debugger
     return MakeReqestAndGetResponse(
      GetBaseERPURL() + ModelsEndpoint.CLONE_MODEL,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }

  function ADD_DATA_TYPES(id,DataTypes) {
    var js = {
      id:id,
      DataTypes:DataTypes
    };
    return MakeReqestAndGetResponse(
      GetBaseERPURL() + ModelsEndpoint.ADD_DATA_TYPES,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }
 



  function DELETE_DATA_TYPES(id,DataTypes) {
    var js = {
      id:id,
      DataTypes:DataTypes
      
    };
    return MakeReqestAndGetResponse(
      GetBaseERPURL() + ModelsEndpoint.DELETE_DATA_TYPES,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }
 