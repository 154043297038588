import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import i18n from "i18n";
import { ErpEngineService, ModelsService } from "_services";
import {
  Modal,
  Button,
  ButtonToolbar,
  Alert,
  Dropdown,
  Loader,
  Schema,
} from "rsuite";
import moment from "moment";
import DynamicTable from "../DynamicTable";

import CreateTransaction from "components/Modals/CreateTransaction";
import UpdateTransaction from "components/Modals/UpdateTransaction";

import FormGenerator from "components/Transaction/FormGenerator";
import ListGenerator from "components/Transaction/ListGenerator";
import UpdateDeleteFormGenerator from "components/Transaction/UpdateDeleteFormGenerator";
import { enqueueSnackbar } from "notistack";

import {
  updateSelectedService,
  updateSelectedTransaction,
  updatedModels,
  updatedTransactions,
  updateSelectedModel,
  updateAllTransactions,
} from "_actions";
import { decamelize } from "assets/globalOps/global";
const { StringType, NumberType, DateType, ObjectType, BooleanType, ArrayType } =
  Schema.Types;
class RunTransactionModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      data: [],
      isCreateTrans: false,
      isUpdateTrans: false,
      formValue: {},
      formValueUpdateTime: new Date() + "",
    };
  }

  handleUpdateTrans = () => {
    this.setState({
      isUpdateTrans: !this.state.isUpdateTrans,
    });
  };

  handleCreateTrans = () => {
    this.setState({
      isCreateTrans: !this.state.isCreateTrans,
    });
  };

  componentDidMount = () => {
    // this.handleStart();
    const { refTransaction, refFormValue } = this.props;
    this.setState({
      formValue: this.props.refFormValue,
      data: Array.isArray(refFormValue) ? [...refFormValue] : [],
      formValueUpdateTime: new Date() + "",
    });
  };

  handleStart = () => {
    const { refTransaction } = this.props;

    debugger;
    if (refTransaction && refTransaction.responseModelType == 1) {
      this.handleReadData();
    } else {
      this.handleRunTransaction();
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { isActive, formValue } = this.props;
    const { refFormValue } = this.props;
    if (isActive && isActive !== prevProps.isActive) {
      debugger;
      this.setState({
        formValue: refFormValue,
        data: Array.isArray(refFormValue) ? [...refFormValue] : [],
        formValueUpdateTime: new Date() + "",
      });
    }
  }

  //STEPBYLOAD_W_GROUP_W_WORKSPACE
  handleReadData = () => {
    const { workspace, refTransaction } = this.props;
    const URL_MODE = "dev_URL";
    const that = this;
    this.setState({ loading: true });
    ErpEngineService.EXECUTE_TRANSACTION_DIRECTLY(
      workspace[URL_MODE],
      "read",
      refTransaction.executableTransactionServiceName,
      "{}"
    )
      .then((data) => {
        if (data.operationResult) {
          this.setState({
            data: data.dynamicValue,
          });
        }
        that.setState({ loading: false });
      })
      .catch((err) => {
        that.setState({ loading: false });
        Alert.error(i18n.t("MESSAGE_TRANSACTION_READ_UNSUCCESS"));
        debugger;
      });
  };

  handleSubmit = (e) => {
    const { formValue, formError, form } = this.state;
    const that = this;
    debugger;
    if (!form.check()) {
      Alert.error("Form not validated");
      return;
    }

    //this.props.handleUpdateRow(this.state.formValue);
    //  return
    this.handlePostValidatedForm();
    //Alert.success("Form validated");
  };

  handleRunTransaction = () => {
    const { workspace, refTransaction } = this.props;
    const that = this;
    let selectedService = refTransaction;
    const { formValue, formError, form } = this.state;
    const URL_MODE = "dev_URL";
    this.setState({ loading: true });
    ErpEngineService.EXECUTE_TRANSACTION_DIRECTLY(
      workspace[URL_MODE],
      this.props.isListDetail
        ? this.props.itemOperation
        : selectedService.functionName,
      selectedService.executableTransactionServiceName,
      JSON.stringify(formValue)
    )
      .then((data) => {
        if (data.operationResult) {
          const aa = that.state.formValue;
          that.setState({
            formValue: data.dynamicValue,
            formValueUpdateTime: new Date() + "",
          });
          debugger;
        }
        that.setState({ loading: false });
      })
      .catch((err) => {
        that.setState({ loading: false });
        try {
          Alert.error(
            Array.isArray(err.failText) ? err.failText.join(". ") : err.failText
          );
        } catch (error) {
          Alert.error(i18n.t("MESSAGE_TRANSACTION_SUBMIT_UNSUCCESS"));
        }

        // let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
        debugger;
      });
  };

  handlePostValidatedForm = () => {
    const {
      workspace,
      allServices,
      isActive,
      handleToggle,
      handleSelect,
      refTransaction,
      model,
    } = this.props;
    const that = this;
    let selectedService = refTransaction;
    const { formValue, formError, form } = this.state;
    const URL_MODE = "dev_URL";

    this.setState({ loading: true });
    ErpEngineService.EXECUTE_TRANSACTION_DIRECTLY(
      workspace[URL_MODE],
      this.props.isListDetail
        ? this.props.itemOperation
        : selectedService.functionName,
      selectedService.executableTransactionServiceName,
      JSON.stringify(formValue)
    )
      .then((data) => {
        if (data.operationResult) {
          that.handleReset();
          Alert.success(i18n.t("MESSAGE_TRANSACTION_SUBMIT_SUCCESS"));
          if (handleToggle) {
            this.handleReset();
            handleToggle();
          }
        }
        that.setState({ loading: false });
      })
      .catch((err) => {
        that.setState({ loading: false });
        try {
          Alert.error(
            Array.isArray(err.failText) ? err.failText.join(". ") : err.failText
          );
        } catch (error) {
          Alert.error(i18n.t("MESSAGE_TRANSACTION_SUBMIT_UNSUCCESS"));
        }

        // let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
        debugger;
      });
  };

  getCurrentLangDisplayName = (item, alternative) => {
    try {
      const _lang = this.props.currentLang;
      if (_lang) {
        let _langDNM = item.find((x) => x.lang == _lang);
        if (_langDNM && _langDNM.textValue.trim().length > 0) {
          return _langDNM.textValue;
        } else {
          _langDNM = item.find((x) => x.lang == "en");
          return _langDNM.textValue;
        }
      } else {
        const _langDNM = item.find((x) => x.lang == "en");
        return _langDNM.textValue;
      }
    } catch (error) {
      debugger;
      return item.length > 0 ? item[0].textValue : decamelize(alternative, " ");
    }
  };

  handleReset = () => {
    this.setState({
      loading: false,
      data: [],
      isCreateTrans: false,
      isUpdateTrans: false,
      formValue: {},
      formValueUpdateTime: new Date() + "",
    });
  };
  prepareGenerateSchema = () => {
    const { selectedService, models, isRefModelForm, refModel } = this.props;

    let model = {}; // Schema.Model({});
    /*
    const _sModels = models
      ? models.dynamicValue.find((x) => x.id === selectedService.reqModelId)
          .dataTypes
      : selectedService.model;
*/
    // if (isRefModelForm) debugger;

    const _xx =
      model &&
      this.props.model.find(
        (x) =>
          x.id ===
          (isRefModelForm
            ? refModel.id
            : selectedService.responseModelType
            ? selectedService.relResponseModelId
            : selectedService.reqModelId)
      );

    if (!_xx) {
      debugger;
    }

    const _sModels = model
      ? this.props.model.find(
          (x) =>
            x.id ===
            (isRefModelForm
              ? refModel.id
              : selectedService.responseModelType
              ? selectedService.relResponseModelId
              : selectedService.reqModelId)
        ).dataTypes
      : (isRefModelForm ? refModel : selectedService)
      ? isRefModelForm
        ? refModel.dataTypes
        : selectedService.model
      : [];

    _sModels.map((item, key) => {
      if (!item.privateField) {
        let _obj = {};
        switch (item.contentType) {
          case 1:
            // String
            _obj = StringType();
            break;
          case 2:
            // Rich Text
            _obj = StringType();
            break;
          case 3:
            // Number
            _obj = NumberType();
            break;
          case 4:
            // Date
            _obj = DateType();
            break;
          case 5:
            // Relations
            _obj = ObjectType(); // string ti
            break;
          case 6:
            // Enum
            _obj = StringType();
            break;
          case 7:
            // Boolean
            _obj = BooleanType();
            break;
          case 8:
            // Identity
            _obj = StringType();
            break;
          case 9:
            // Image
            _obj = ObjectType();
            break;
          case 10:
            // Relations
            _obj = ObjectType(); // ObjectType();
            break;
          case 11:
            // Relations
            _obj = ObjectType(); // ObjectType();
            break;
          case 12:
            // Relations
            _obj = ObjectType(); // ObjectType();
            break;
          case 13:
            // Relations
            _obj = ArrayType(); // ObjectType();
            break;
            case 15:
              // Relations
              _obj = ObjectType();
              break;
            case 16:
              // Relations
              _obj = ObjectType(); // ObjectType();
              break;
              case 17:
                // Relations
                _obj = ObjectType(); // ObjectType();
                break;
          default:
            break;
        }
        if (item.reqiured || item.notNull) {
          if (_obj) _obj.isRequired(i18n.t("ERROR_DM_GLOBAL_REQUIRED"));
        }
        if (item.uniq) {
        }

        switch (item.typeDetail) {
          case 1:
            // STANDART
            break;
          case 2:
            // PASSWORD
            break;
          case 3:
            // EMAIL
            _obj.isEmail(i18n.t("ERROR_DM_GLOBAL_EMAIL_FORMAT"));
            break;
          case 4:
            // INT
            //_obj.isInteger(i18n.t("ERROR_DM_GLOBAL_INT"));
            break;
          case 5:
            // BIGINT
            // _obj.isInteger(i18n.t("ERROR_DM_GLOBAL_INT"));
            break;
          case 6:
            // DECIMAL
            // _obj.isInteger(i18n.t("ERROR_DM_GLOBAL_INT"));
            break;
          case 7:
            // DOUBLE
            //_obj.isInteger(i18n.t("ERROR_DM_GLOBAL_INT"));
            break;
          case 8:
            // DOUBLE
            //_obj.isInteger(i18n.t("ERROR_DM_GLOBAL_INT"));
            break;
          case 9:
            // DOUBLE
            //_obj.isInteger(i18n.t("ERROR_DM_GLOBAL_INT"));
            break;
          case 10:
            // DOUBLE
            //_obj.isInteger(i18n.t("ERROR_DM_GLOBAL_INT"));
            break;
          case 11:
            // DOUBLE
            //_obj.isInteger(i18n.t("ERROR_DM_GLOBAL_INT"));
            break;

          default:
            break;
        }
        if (
          item.contentType !== 8 &&
          item.contentType !== 12 &&
          item.contentType !== 11 &&
          item.contentType !== 14 &&
          item.contentType !== 17
        ) {
          model[item.name] = _obj;
        }
      }
    });
    return Schema.Model(model);
  };
  transactionPrepareFunc = () => {
    const formValue = this.state.data;
    const { selectedService, model, isRefModelForm, refModel } = this.props;
    debugger;
    const _sModels = model
      ? model.find(
          (x) =>
            x.id ===
            (isRefModelForm
              ? refModel.id
              : selectedService.responseModelType
              ? selectedService.relResponseModelId
              : selectedService.reqModelId)
        ).dataTypes
      : (isRefModelForm ? refModel : selectedService)
      ? isRefModelForm
        ? refModel.dataTypes
        : selectedService.model
      : [];
    return {
      formData: JSON.parse(JSON.stringify(formValue)),
      model: _sModels,
    };
  };

  ToastMessage = (msg, type) => {
    try {
      switch (type) {
        case "success":
          Alert.success(msg);
          break;
        case "error":
          Alert.error(msg);
          break;
        case "info":
          Alert.info(msg);
          break;
        case "warning":
          Alert.warning(msg);
          break;
        default:
          Alert.info(msg);
          break;
      }
    } catch (error) {
      Alert.info(msg);
    }
  };
  SnackMessage = (msg, type) => {
    try {
      debugger;
      enqueueSnackbar(msg, { variant: type });
    } catch (error) {
      debugger;
      enqueueSnackbar(msg);
    }
  };

  preTransactionFunc = (data, _selectedService, _formValue) => {
    const {
      isRunTransModal,
      runTransModalClose,
      updateBaseForm,
      handleRunPreTransaction,
      baseData,
    } = this.props;

    const _dynamicSchemaModel = this.prepareGenerateSchema();

    const that = this;
    let result = {
      status: true,
      body: data,
    };
    debugger;
    const runTransaction = that.runTransaction;
    const transactions = this.props.allTransList;
    const Toast = this.ToastMessage;
    const SnackToast = this.SnackMessage;
    const easyinvoice = require("easyinvoice").default; //easyinvoice.download('myInvoice.pdf', data.InvoiceBinary);
    const showInvoiceModal = this.handleInvoiceViewerModal;
    const showConfirmModal = this.handleConfirmModal;
    const runPreTransaction = () => {
      if (isRunTransModal && handleRunPreTransaction) {
        handleRunPreTransaction();
      }
    };

    const updatePreForm = (formValue) => {
      if (isRunTransModal) {
        //debugger;
        updateBaseForm(formValue);
      } else {
        this.setState({ formValue });
        if (this.props.isShowTransaction) {
          this.props.updateBaseForm(formValue);
        } else if (this.props.isRefModelForm) {
          // handleUpdateRefData(formValue);
          that.handleUpdateRefData(formValue, this.form, _dynamicSchemaModel);
        } else {
        }
      }
    };
    const closeModal = () => {
      if (this.props.isShowTransaction) {
        this.props.showTransactionClose();
      } else if (isRunTransModal) {
        runTransModalClose();
      } else {
        that.setState({
          isTransModal: false,
        });
      }
    };

    const checkFormValidation = () => {
      try {
        if (!that.form.check()) {
          Alert.error("Form not validated");
          result.status = false;

          return false;
        } else {
          return true;
        }
      } catch (error) {
        return false;
      }
    };

    let { formData, model } = this.transactionPrepareFunc();
    const updateData = that.UpdateFormValue;
    debugger;
    if (
      _selectedService.hasOwnProperty("preScript") &&
      _selectedService.preScript.trim().length > 0
    ) {
      try {
        if (_selectedService.preScript.includes("await ")) {
          result.body = eval(
            "(async function() => { " + _selectedService.preScript + "})()"
          );
        } else {
          result.body = eval(
            "(function() {" + _selectedService.preScript + "}())"
          );
        }
      } catch (error) {
        debugger;
      }
    }
    /*
    preScript(pin): "alert("pre")"
postScript(pin): "alert("post")"
    */

    try {
      // debugger;
      if (result) {
        return result;
      }
      return data;
    } catch (error) {
      return data;
    }
  };
  runBlobTransaction = (_selectedService, _formValue) => {
    const formValue = this.state.data;
    const { workspace, onSubmit, model, isRefModelForm, refModel } = this.props;

    const selectedService = _selectedService
      ? _selectedService
      : this.state.selectedService;

    const URL_MODE = "dev_URL";
    const that = this;

    const _sModels = model
      ? model.find(
          (x) =>
            x.id ===
            (isRefModelForm
              ? refModel.id
              : selectedService.responseModelType
              ? selectedService.relResponseModelId
              : selectedService.reqModelId)
        ).dataTypes
      : (isRefModelForm ? refModel : selectedService)
      ? isRefModelForm
        ? refModel.dataTypes
        : selectedService.model
      : [];

    debugger;

    this.setState({ loading: true });

    const _preFunc = this.preTransactionFunc(
      _formValue,
      _selectedService,
      _formValue
    );

    if (_preFunc.status) {
      const _body = JSON.stringify(_preFunc.body);
      debugger;
      that.setState({ loading: true });
      ErpEngineService.EXECUTE_TRANSACTION_DIRECTLY_BLOB(
        workspace[URL_MODE],
        this.props.isListDetail
          ? this.props.itemOperation
            ? this.props.itemOperation
            : _selectedService.functionName
          : _selectedService.functionName,
        _selectedService.executableTransactionServiceName,
        _body
      )
        .then((_data) => {
          const { data, res } = _data;
          debugger;

          that.setState({ loading: false });
          try {
            const href = URL.createObjectURL(data);

            // create "a" HTML element with href to file & click

            const link = document.createElement("a");
            link.href = href;
            debugger;

            const contentDisposition = res.headers["x-suggested-filename"];

            if (contentDisposition) {
              link.setAttribute("download", `${contentDisposition}`); //or any other extension
            } else {
              switch (res.headers["content-type"]) {
                case "application/pdf":
                  link.setAttribute("download", `${new Date().toJSON()}.pdf`); //or any other extension
                  break;
                default:
                  link.setAttribute("download", `${new Date().toJSON()}.xlsx`); //or any other extension

                  break;
              }
            }

            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            debugger;
            return true;
          } catch (error) {
            return false;
          }
        })
        .catch((err) => {
          debugger;
          that.setState({ loading: false });
          try {
            Alert.error(
              Array.isArray(err.failText)
                ? err.failText.join(". ")
                : err.failText
            );
          } catch (error) {
            Alert.error(i18n.t("MESSAGE_TRANSACTION_SUBMIT_UNSUCCESS"));
          }
          // let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
        });
    } else {
      this.setState({ loading: false });
    }
  };
  /*
  runBlobTransaction = (_selectedService, _formValue) => {
    const formValue = this.state.data;
    const { workspace, onSubmit, model, isRefModelForm, refModel } = this.props;

    const selectedService = _selectedService
      ? _selectedService
      : this.state.selectedService;

    const URL_MODE = "dev_URL";
    const that = this;

    const _sModels = model
      ? model.find(
          (x) =>
            x.id ===
            (isRefModelForm
              ? refModel.id
              : selectedService.responseModelType
              ? selectedService.relResponseModelId
              : selectedService.reqModelId)
        ).dataTypes
      : (isRefModelForm ? refModel : selectedService)
      ? isRefModelForm
        ? refModel.dataTypes
        : selectedService.model
      : [];

    debugger;

    this.setState({ loading: true });

    const _preFunc = this.preTransactionFunc(
      _formValue,
      _selectedService,
      _formValue
    );

    if (_preFunc.status) {
      const _body = JSON.stringify(_preFunc.body);
      debugger;
      that.setState({ loading: true });
      ErpEngineService.EXECUTE_TRANSACTION_DIRECTLY_BLOB(
        workspace[URL_MODE],
        this.props.isListDetail
          ? this.props.itemOperation
            ? this.props.itemOperation
            : _selectedService.functionName
          : _selectedService.functionName,
        _selectedService.executableTransactionServiceName,
        _body
      )
        .then((data) => {
          debugger;

          that.setState({ loading: false });
          try {
            const href = URL.createObjectURL(data);

            // create "a" HTML element with href to file & click
            const link = document.createElement("a");
            link.href = href;
            debugger;
            link.setAttribute("download", `${new Date().toJSON()}.xlsx`); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            debugger;
            return true;
          } catch (error) {
            return false;
          }
        })
        .catch((err) => {
          debugger;
          that.setState({ loading: false });
          try {
            Alert.error(
              Array.isArray(err.failText)
                ? err.failText.join(". ")
                : err.failText
            );
          } catch (error) {
            Alert.error(i18n.t("MESSAGE_TRANSACTION_SUBMIT_UNSUCCESS"));
          }
          // let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
        });
    } else {
      this.setState({ loading: false });
    }
  };

  */
  render() {
    const {
      allServices,
      isActive,
      handleToggle,
      handleSelect,
      refTransaction,
      model,
      refFormValue,
      allTransactions,
    } = this.props;

    let selectedService = refTransaction;
    if (!selectedService) {
      debugger;
    }
    const _model = allTransactions.find(
      (x) =>
        x.model.id ==
        (selectedService.responseModelType
          ? selectedService.relResponseModelId
          : selectedService.reqModelId)
    );

    const _sService = model
      ? model.find(
          (x) =>
            x.id ===
            (selectedService.responseModelType
              ? selectedService.relResponseModelId
              : selectedService.reqModelId)
        )
      : [];

    const that = this;
    const { data, loading, formValue } = this.state;
    const _date = moment(new Date()).format("YYYY-MM-DD_h-mm");

    const _fm = model.find(
      (x) =>
        x.id ==
        (selectedService.responseModelType
          ? selectedService.relResponseModelId
          : selectedService.reqModelId)
    );
    const _hasList = _fm.dataTypes.find(
      (x) =>
        (x.contentType == 5 && x.relationModel.relationType) ||
        (x.contentType == 10 && x.refModel.relationType)
    );

    if (isActive) {
      debugger;
    }

    return (
      <Translation>
        {(t) => (
          <>
            {selectedService && (
              <Modal
                size={
                  selectedService.responseModelType || _hasList ? "lg" : "sm"
                }
                show={isActive}
                onHide={handleToggle}
              >
                <Modal.Header>
                  <Modal.Title>
                    {isActive &&
                      this.getCurrentLangDisplayName(
                        selectedService.description,
                        selectedService.name.split("-")[0]
                      )}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ paddingBottom: 0 }}>
                  <div key={this.state.formValueUpdateTime}>
                    {selectedService && isActive && (
                      <>
                        {selectedService.responseModelType ? (
                          <DynamicTable
                            classname={"view-table"}
                            handleSelect={(_data) => {}}
                            data={data}
                            dataModel={
                              _model
                                ? _model.model.dataTypes
                                : selectedService.model
                            }
                            runBlobTransaction={this.runBlobTransaction}
                            extraButtonsAll={
                              _sService &&
                              _sService.hasOwnProperty("extraButtons")
                                ? _sService.extraButtons.filter(
                                    (x) =>
                                      x.runType != "TableActionModal" &&
                                      x.runType != "TableActionModalOnLoad" &&
                                      x.runType != "TableActionCurrent"
                                  )
                                : []
                            }
                            isSearchable={true}
                          />
                        ) : selectedService.functionName === "update" ||
                          selectedService.functionName === "delete" ? (
                          <UpdateDeleteFormGenerator />
                        ) : (
                          <div>
                            <FormGenerator
                              baseData={this.props.baseData}
                              isRunTransModal={true}
                              handleRunCurrentTransaction={this.handleStart}
                              handleRunPreTransaction={
                                this.props.handleRunPreTransaction
                                  ? this.props.handleRunPreTransaction
                                  : this.handleStart
                              }
                              updateBaseForm={this.props.updateBaseForm}
                              runTransModalClose={handleToggle}
                              isRefModelForm={true}
                              isListDetail={true}
                              isShowTransaction={false}
                              readOnly={false}
                              currentValues={
                                Object.keys(formValue).length > 0
                                  ? formValue
                                  : refFormValue
                              }
                              currentValuesOrj={refFormValue}
                              refModel={model.find(
                                (x) =>
                                  x.id ==
                                  (selectedService.responseModelType
                                    ? selectedService.relResponseModelId
                                    : selectedService.reqModelId)
                              )}
                              refTransaction={refTransaction}
                              preRefTransaction={this.props.preRefTransaction}
                              handleUpdateRefData={(_data, form, _shema) =>
                                this.setState({
                                  formValue: _data,
                                  //  formValueUpdateTime: new Date()+"",
                                  form: form,
                                  shema: _shema,
                                })
                              }
                            />
                          </div>
                        )}
                      </>
                    )}
                    {loading && (
                      <div className={"fade-in"}>
                        <Loader backdrop vertical />
                      </div>
                    )}
                  </div>
                </Modal.Body>
              </Modal>
            )}

            <CreateTransaction
              onRefresh={this.onRefresh}
              isActive={this.state.isCreateTrans}
              handleToggle={this.handleCreateTrans}
            />
            <UpdateTransaction
              onRefresh={this.onRefresh}
              // script={this.state.script}
              isActive={this.state.isUpdateTrans}
              handleToggle={this.handleUpdateTrans}
            />
          </>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer } = state;
  const {
    model,
    models,
    transaction,
    transactions,
    workspaces,
    workspace,
    dataModels,
    selectedService,
    services,
    allServices,
    allTransactions,
    currentLang,
  } = workspaceReducer;

  return {
    workspace,
    transaction,
    transactions,
    workspaces,
    dataModels,
    models,
    model,
    selectedService,
    services,
    allServices,
    allTransactions,
    currentLang,
  };
};

const mapDispatchToProps = {
  updateSelectedService,
  updateSelectedTransaction,
  updatedModels,
  updateSelectedModel,
  updatedTransactions,
  updateAllTransactions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RunTransactionModal);
