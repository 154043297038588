//base url ler burada olacak

//eger localde frontend yapilip back end calismiyor ise her baglantiyi publish e zorla
let ForceProductionMode = true;

export function GetUserLang() {
  //burasi sonra yapilabilie..
  return "en";
}

export function GetBaseERPProductionOnly() {
  return ServiceURLs.ERP_Production_URL;
}

export function GetConsoleFrontEnd() {
  var CurrentURL = "";
  //if (ForceProductionMode) return ServiceURLs.Console_Production_FrontEnd_URL;
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    CurrentURL = ServiceURLs.Console_Dev_FrontEnd_URL;
  } else {
    CurrentURL = ServiceURLs.Console_Production_FrontEnd_URL;
  }
  return CurrentURL;
}

export function GetCreatorFrontEnd() {
  var CurrentURL = "";
  // if (ForceProductionMode) return ServiceURLs.Creator_Production_FrontEnd_URL;
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    CurrentURL = ServiceURLs.Creator_Dev_FrontEnd_URL;
  } else {
    CurrentURL = ServiceURLs.Creator_Production_FrontEnd_URL;
  }
  return CurrentURL;
}

export function GetBaseStorageURL() {
  var CurrentURL = "";
  if (ForceProductionMode) return ServiceURLs.Storage_Production_URL;
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    CurrentURL = ServiceURLs.Storage_DEV_URL;
  } else {
    CurrentURL = ServiceURLs.Storage_Production_URL;
  }
  return CurrentURL;
}

export function GetBaseConnectURL() {
  var CurrentURL = "";
  if (ForceProductionMode) return ServiceURLs.Connect_Production_URL;
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    CurrentURL = ServiceURLs.Connect_DEV_URL;
  } else {
    CurrentURL = ServiceURLs.Connect_Production_URL;
  }
  return CurrentURL;
}

export function GetBaseConsoleURL() {
  var CurrentURL = "";
  if (ForceProductionMode) return ServiceURLs.Console_Production_URL;
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    CurrentURL = ServiceURLs.Console_DEV_URL;
  } else {
    CurrentURL = ServiceURLs.Console_Production_URL;
  }
  return CurrentURL;
}

export function GetBaseApiURL() {
  var CurrentURL = "";

  if (ForceProductionMode) return ServiceURLs.Api_Production_URL;

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    CurrentURL = ServiceURLs.Api_DEV_URL;
  } else {
    CurrentURL = ServiceURLs.Api_Production_URL;
  }
  return CurrentURL;
}

export function GetBaseERPURL() {
  var CurrentURL = "";

  if (ForceProductionMode) return ServiceURLs.ERP_Production_URL;

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    CurrentURL = ServiceURLs.ERP_DEV_URL;
  } else {
    CurrentURL = ServiceURLs.ERP_Production_URL;
  }
  return CurrentURL;
}

export function GetBaseSocketURL() {
  var CurrentURL = "";

  // socket local servis remote olunca aciklamaya al publish de unutma..
  if (ForceProductionMode) return ServiceURLs.Socket_Production_URL;
  //test icin
  //return ServiceURLs.Socket_DEV_URL;

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    CurrentURL = ServiceURLs.Socket_DEV_URL;
  } else {
    CurrentURL = ServiceURLs.Socket_Production_URL;
  }
  return CurrentURL;
}

export function GetErpEngineSocketURL() {
  var CurrentURL = "";
  return ServiceURLs.ERP_Socket_DEV_URL;
  /*
  // socket local servis remote olunca aciklamaya al publish de unutma..
  if (ForceProductionMode) return ServiceURLs.Socket_Production_URL;
  //test icin
  //return ServiceURLs.Socket_DEV_URL;

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    CurrentURL = ServiceURLs.Socket_DEV_URL;
  } else {
    CurrentURL = ServiceURLs.Socket_Production_URL;
  }
  return CurrentURL;
  */
}

export function GetBaseVideoURL() {
  var CurrentURL = "";

  // socket local servis remote olunca aciklamaya al publish de unutma..
  if (ForceProductionMode) return ServiceURLs.Video_Production_URL;
  //test icin
  //return ServiceURLs.Socket_DEV_URL;

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    CurrentURL = ServiceURLs.Video_DEV_URL;
  } else {
    CurrentURL = ServiceURLs.Video_Production_URL;
  }
  return CurrentURL;
}

export function GetAuthURL() {
  var CurrentURL = "";

  // socket local servis remote olunca aciklamaya al publish de unutma..
  // if (ForceProductionMode) return ServiceURLs.Auth_Production_URL;
  //test icin
  //return ServiceURLs.Socket_DEV_URL;

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    CurrentURL = ServiceURLs.Auth_DEV_URL;
  } else {
    CurrentURL = ServiceURLs.Auth_Production_URL;
  }
  return CurrentURL;
}

export function GetBaseCreatorURL() {
  var CurrentURL = "";

  // socket local servis remote olunca aciklamaya al publish de unutma..
  if (ForceProductionMode) return ServiceURLs.Creator_Production_URL;
  //test icin
  //return ServiceURLs.Socket_DEV_URL;

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    CurrentURL = ServiceURLs.Creator_DEV_URL;
  } else {
    CurrentURL = ServiceURLs.Creator_Production_URL;
  }
  return CurrentURL;
}

export function Get_FRONTEND_CMS_URL() {
  var CurrentURL = "";

  // socket local servis remote olunca aciklamaya al publish de unutma..
  if (ForceProductionMode) return ServiceURLs.CMS_Production_FrontEnd_URL;
  //test icin
  //return ServiceURLs.Socket_DEV_URL;

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    CurrentURL = ServiceURLs.CMS_Dev_FrontEnd_URL;
  } else {
    CurrentURL = ServiceURLs.CMS_Production_FrontEnd_URL;
  }
  return CurrentURL;
}

export function GetBaseCMSURL() {
  var CurrentURL = "";

  // socket local servis remote olunca aciklamaya al publish de unutma..
  if (ForceProductionMode) return ServiceURLs.CMS_Production_URL;
  //test icin
  //return ServiceURLs.Socket_DEV_URL;

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    CurrentURL = ServiceURLs.CMS_DEV_URL;
  } else {
    CurrentURL = ServiceURLs.CMS_Production_URL;
  }
  return CurrentURL;
}

export const ServiceURLs = {
  Connect_Production_URL: "https://connect.mobilex360.io/api/",
  Connect_DEV_URL: "http://localhost:7001/api/",
  Api_Production_URL: "https://api.mobilex360.io/api/",
  Api_DEV_URL: "http://localhost:7000/api/",
  Storage_Production_URL: "https://storage.mobilex360.io/api/",
  Storage_DEV_URL: "http://localhost:7002/api/",
  Auth_Production_URL: "https://auth.mobilex360.io/login/",
  Auth_DEV_URL: "http://localhost:3002/login/",
  Creator_Production_URL: "https://creatorapi.mobilex360.io/api/",
  Creator_DEV_URL: "http://localhost:7003/api/",
  Socket_DEV_URL: "http://127.0.0.1:9393",
  Socket_Production_URL: "https://socket.mobilex360.io/",
  Console_Production_URL: "https://consoleapi.mobilex360.io/api/",
  Console_DEV_URL: "http://localhost:7004/api/",
  Creator_Production_FrontEnd_URL: "https://creator.mobilex360.io/",
  Creator_Dev_FrontEnd_URL: "http://localhost:3001/",
  Console_Dev_FrontEnd_URL: "http://localhost:3020/",
  Console_Production_FrontEnd_URL: "https://console.mobilex360.io/",
  CMS_Dev_FrontEnd_URL: "http://localhost:3010/",
  CMS_Production_FrontEnd_URL: "https://cms.mobilex360.io/",
  CMS_Production_URL: "https://cmsapi.mobilex360.io/api/",
  CMS_DEV_URL: "http://localhost:7010/api/",
  ERP_Production_URL: "https://api-erp.thexapp.com/api/",
  ERP_DEV_URL: "http://localhost:7012/api/",
  ERP_Socket_DEV_URL: "http://localhost:7000",
};

export const UserEndPoints = {
  GET_MY_INFORMATION: "user/getmyinformation",
  LOGIN: "user/login",
  REGISTER: "user/register",
  CHANGE_PASS_W_TOKEN: "user/changepasswordwithtoken",
  UPDATE_PASSWORD: "user/changepassword",
  UPDATE_EMAIL: "user/changeemail",
  UPDATE_USERNAME: "user/changeusername",
  CONFIRM_USER: "user/confirm",
  LOGOUT: "user/logout",
  FORGOT_PASSWORD: "user/forgotpassword",
  GET_GENDER: "gender/withlang/",
  GET_RANDOM: "user/getrandom/",
  GET_USERNAME_AVAILABLE: "user/checkusernameusable",
  GET_EMAIL_AVAILABLE: "user/checkemailusable",
  SEARCH_USER: "user/stepbyload",
  GET_LAST_LOGGINED: "user/getlastloggined",
  UPDATE_PP: "user/updatepp",
  ADD_ITEM_TO_GALLERY: "gallery/addnewgalleryitem",
  REMOVE_ITEM_FROM_GALLERY: "gallery/deletegalleryitem",
  GET_MY_GALLERY: "gallery/getmygallery",
  GET_USER_GALLERY: "gallery/getusergallery",
  EDIT_USER01: "user/edituserinfo1",
  GET_RANDOM_NEWS_PHOTOS: "gallery/getnewrandomphotos",
  SET_REDIRECT_URL: "redirecttemp/seturl",
  GET_REDIRECT_URL: "redirecttemp/geturl/",
  SET_REDIRECT_TOKEN_URL: "redirecttemp/settoken",
  GET_REDIRECT_TOKEN_URL: "redirecttemp/gettoken/",
  GET_PROJECT_TOKEN_URL: "redirecttemp/getproject/",
};

export const StorageEndPoints = {
  UPLOAD: "storage/upload",
  UPLOAD_FILE: "storage/uploadfile",
  UPLOAD_BASE64: "storage/uploadbase64",
  UPLOAD_BASE64SOUND: "storage/uploadsound",
  UPLOAD_VIDEO: "storage/uploadvideo",
  STORAGE_GET_MY_STREAM: "storage/getmystreams",
  STORAGE_GET_MY_PROJECT_STREAM: "storage/getprojectstream",
  STORAGE_GET_BOTH_STREAM: "storage/getbothstream",
  STORAGE_ADD_PROJECTID_TO_STREAM: "storage/addprojectidtostream",
  STORAGE_REMOVE_PROJECT_ID_FROM_STREAM: "storage/removeprojectidtostream",
  STORAGE_DELETE: "storage/deletestream",
  STORAGE_GET_ADMIN_ONLY: "storage/getsystemonly",
};

export const CreatorEndPoints = {
  CREATE_PROJECT: "projects/createproject",
  SAVE_PROJECT: "projects/saveproject",
  GET_PROJECT_SETTING: "projects/getprojectsetting",
  SET_PROJECT_SETTING: "projects/setprojectsetting",
  CHECK_IS_HAVE_PROJECT_SETTING: "projects/checkishaveprojectsetting",
  GET_PAGING: "projects/paging",
  FIND_USER_FROM_TEAM: "team/finduser",
  CREATE_TEAM: "team/createteam",
  ADD_USER_TO_TEAM: "team/addusertoteam",
  UPDATE_USER_TEAM: "team/updateuserrole",
  REMOVE_FROM_TEAM: "team/removefromteam",
  ASSIGN_PAGE_TO_USER: "team/assignpagetouser",
  REMOVE_USER_FROM_PAGE: "team/removeuserfrompage",
  CHECK_IS_TEAM_OWNER: "team/checkisteamowner",
  GET_PROJECT_MEMBERS: "team/getprojectmembers",
  ADD_PAGE_TO_PROJECT: "pages/addpagetoproject",
  GET_PAGE_BY_ID: "pages/getpagebyid",
  GET_PAGES_ALL: "pages/getpagesall",
  GET_PAGES_THUMB: "pages/getpagesthumb",
  GET_PAGES_CONFIRMED_ONLY: "pages/getpagesconfirmedonly",
  GET_PAGES_UNCONFIRMED_ONLY: "pages/getpagesunconfirmedonly",
  SET_PAGE_ACTIVE_STATUS: "pages/setpageactivestatus",
  CLONE_PAGE: "pages/clonepage",
  SET_COMMIT_PAGE: "pages/setcommit",
  INC_BUILD_NUMBER: "pages/incbuildnumber",
  EDIT_PAGE_DETAIL: "pages/editpagedetail",
  EDIT_PAGE_PROPERTIES: "pages/editpageproperties",
  GENERATE_COMPONENT_ID: "component/generatecomponentid",
  REMOVE_COMPONENT_ID: "component/removecomponentid",
  GET_COMPONENTS: "component/getcomponents",
  GET_CUSTOM_COMPONENTS: "customcomponent/getmycustomcomponents/",
  SAVE_CUSTOM_COMPONENTS: "customcomponent/savecustomcomponent",
  REMOVE_CUSTOM_COMPONENTS: "customcomponent/removecustomcomponent",
  SEARCH_CUSTOM_COMPONENTS: "customcomponent/stepbyload",
  GET_HISTORY_BY_VERSION: "projecthistory/gethistorybyversion",
  SET_NEW_PROJECT_HISTORY: "projecthistory/setnewprojecthistory",
  HISTORY_STEP_BY_LOAD: "projecthistory/stepbyload",
  GET_GLOBAL_PROP_TYPES: "globalproptypes/get",
  GET_GLOBAL_PROPERTIES: "globalproperties/getglobalproperties",
  CREATE_GLOBAL_PROPERTIES: "globalproperties/createglobalproperties",
  UPDATE_GLOBAL_PROPERTIES: "globalproperties/updateglobalproperties",
  REMOVE_GLOBAL_PROPERTIES: "globalproperties/removeglobalproperties",
};

export const ErpEngineEndPoints = {
  GET_GENERATED_MODEL: "/xerp-engine/source/getgeneratedmodel",
  GENERATE_ALL_MODEL: "/xerp-engine/source/generateallmodel",
  GET_BASE_PROJECT: "/xerp-engine/source/getbaseproject",
  SET_SETTING_BACKEND: "/xerp-engine/source/getandsetsettings",
  GENERATE_ALL_TRANSACTION: "/xerp-engine/source/generatealltransaction",
  ERP_COMMAN_LINE: "/xerp-engine/shell/runcommand",
  EXECUTE_TRANSACTION_DIRECTLY: "/main/executetransaction",
  RUN_ON_DEV_MODE: "/xerp-engine/shell/runinstance",
  CREATE_NEW_TERMINAL: "/xerp-engine/shell/createnewinstance/",
  GET_GENERATED_MODEL_BASE: "model/getgeneratedmodels",
  GENERATE_ALL_TRANSACTION_BASE: "transaction/generatealltransaction",
  GET_INTERFACES: "/xerp-engine/source/getinterfaces/",
};

export const TicketEndPoints = {
  GET_TICKET_CATEGORY: "ticketcategory/withlang/",
  GET_OR_SEARCH_TICKET: "tickets/stepbyload",
  GET_OR_SEARCH_IN_TICKET_DETAIL: "ticketdetail/getselectedticket",
  GET_OR_SEARCH_IN_ALL_TICKET_DETAIL: "ticketdetail/getownonly",
  CREATE_TICKET: "tickets/createticket",
  ADD_REPLY_TO_TICKET: "tickets/addreplytoticket",
  CHANGE_REPORTER_USER: "tickets/changereporteruser",
  CREATE_TICKET_STATUS: "tickets/changeticketstatus",
  GET_OR_SEARCH_TICKET_W_PAGING: "tickets/paging",
};

export const WorkspaceEndpoint = {
  CREATE_WORK_SPACE: "workspace/createworkspace",
  STEPBYLOAD: "workspace/stepbyload",
  SET_SETTINGS: "workspace/setsettings",
  GET_CONFIGS: "workspace/getconfigs",
  RE_DEPLOY_ENGINE: "workspace/redeploybackendstack",
  PREPARE_WORKSPACE_FOR_CLONE: "workspace/praparecloneworkspace",
  CLONE_WORKSPACE: "workspace/cloneworkspace",
  GET_MIGRATION_STATUS: "workspace/getmigrationstatus",
  PRAPARE_CLONE_WORKSPACE_WITH_TARGET:"workspace/praparecloneworkspacewtarget",
  TRANSFER_DATA_WORKSPACE:"workspace/transferdatawworkspace",
  GET_DASHBOARD_LOGO:"workspace/getdashboardlogo",
  SET_DASHBOARD_LOGO:"workspace/setdashboardlogo"

};

export const ModulesEndpoint = {
  CREATE_MODULE: "modules/createmodule",
  STEPBYLOAD: "modules/stepbyload",
  EDIT_MODULE: "modules/editmodule",
  UPDATE_NPM_PACKAGE: "modules/updatenpmpackage",
  GET_FROM_STORE: "modules/getfromstore",
  UPDATE_FROM_STORE: "modules/updatefromstore",
};

export const TransactionEndpoint = {
  CREATE_TRANSACTION: "transaction/createtransaction",
  STEPBYLOAD: "transaction/stepbyload",
  UPDATE_TRANSACTION: "transaction/updatetransaction",
  GET_TRANSACTION_BYID: "transaction/gettransactionbyid",
  SAVE_SCRIPT_ONLY: "transaction/savescriptonly",
  SAVE_VIEW_ONLY: "transaction/saveviewonly",
  SAVE_PREANDPOST_SCRIPT_ONLY: "transaction/savepreandpostonly",
};
export const CategoryEndPoint = {
  GET_W_LANG: "category/withlang/",
};
export const StoreEndPoint = {
  ADD_STORE: "store/addtostore",
  STEPBYLOAD: "store/stepbyload",
  STEPBYLOAD_OWN: "store/stepbyloadown",
  STORE_ADD_NEW_VERSION: "store/addnewversiontostore",
  PRAPARE_MODULE_FOR_STORE: "store/praparemoduleforstore",
};

export const ModelsEndpoint = {
  CREATE_MODEL: "model/createmodel",
  UPDATE_MODEL: "model/updatemodel",
  STEPBYLOAD: "model/stepbyload",
  ADD_DATA_TYPES: "model/adddatatypes",
  DELETE_DATA_TYPES: "model/deletedatatype",
  STEPBYLOAD_W_GROUP: "model/stepbyloadwgroup",
  STEPBYLOAD_W_GROUP_W_WORKSPACE: "model/stepbyloadwgroupworkspace",
  UPDATE_FILTER: "model/updatefilter",
  CLONE_MODEL: "model/clonemodel",
  SET_EXTRA_BUTTON:"model/setextrabutton",
  UpdateRelationTabs:"model/updaterelationtabs"
};

export const DevelopingLangsEndPoint = {
  GET_DEV_LANGS: "DevelopingLangs",
};

export const FAQEndPoints = {
  GET_FAQ: "faq/withlang/",
  ADD_FAQ: "faq/addfaq",
  PAGING: "faq/paging",
  STEPBYLOAD: "faq/stepbyload",
};

export const ProfileDetailEndPoints = {
  GET_COUNTRIES: "country/withlang/",
  GET_MY_DETAIL: "profiledetail/getmydetail",
  SAVE_DETAIL: "profiledetail/savemydetail",
};

export const ProjectEntPoints = {
  CLONE_PROJECT: "projects/cloneproject",
  CREATE_PROJECT: "projects/createproject",
  GET_MY_PROJECT: "projects/paging",
  GET_PROJECT_TYPES: "projecttypes/withlang/",
  GET_APP_CATEGORIES: "appcategory/withlang/",
  SET_PROJECT_DETAIL: "redirecttemp/setproject",
};

export const PaymentEndpoints = {
  ADD_NEW_CREDIT_CARD: "creditcard/create",
  SET_DEFAULT_CREDIT_CARD: "creditcard/setdefault",
  REMOVE_CREDIT_CARD: "creditcard/remove",
  GET_MY_CREDIT_CARD: "creditcard/paging",
};

export const CMSEndPoints = {
  GET_ALL_FOR_PANEL: "static/getallforpanel",
  GET_BY_ID: "static/getbyid",
  SAVE: "static/save",
  SAVE_FROM_CREATOR: "static/savefromcreator",
  CHECK_IS_HAVE_CMS_DB: "cmsconfigs/checkishavecmsdb",
  FIND_GLOBAL_PAGING: "collections/paging",
  FIND_GLOBAL_STEP_BY_LOAD: "collections/stepbyload",
  STEPBY_LOAD_OWN: "collections/stepbyloadown",
  PAGING_OWN: "collections/pagingown",
  CREATE_CMS_DB: "cmsconfigs/createcmsdb",
  CHECK_IS_HAVE_COLLECTIONS: "collections/checkishavecollections",
  CREATE_COLLECTIONS: "collections/createcollection",
  ADD_DATA_TYPES: "collections/adddatatypes",
  CHANGE_PRIORITES: "collections/changepriorities",
  DELETE_DATA_TYPES: "collections/deletedatatype",
  DATA_INSERT_RECORD: "data/adddata",
  DATA_GET_DATA: "data/cmspaging",
  DATA_UPDATE_RECORD: "data/updatedata",
  DATA_DELETE_RECORD: "data/deletedata",
};

export const ErpRolesEndpoints = {
  GET_SCOPES: "erproles/scopes",
  GET_ROLES: "erproles/stepbyload",
  CREATE_ROLE: "erproles/createerprole",
  UPDATE_ROLE: "erproles/updateerprole",
};

export const ErpRolesAndUsersRelationsEndpoints = {
  GET_USER_ROLES: "erprolesanduserrelation/getuserandrole",
  ASSIGN_ROLE_TO_USER: "erprolesanduserrelation/assignroletouser",
  REMOVE_ROLE_FROM_USER: "erprolesanduserrelation/removerolefromuser",
};

export const WorkspaceUserEndpoints = {
  GET_USERS: "workspaceusers/getalluser",
  CREATE_USER: "workspaceusers/createuser",
  RESET_PASSWORD_FROM_ADMIN_SIDE: "workspaceusers/resetpasswordadminside",
  SET_ACTIVE_STATUS: "workspaceusers/setactivestatus",
};

export const DashboardUrlsEndpoints = {
  CREATE_DASHSBOARD_URL: "dashboardurl/createurl",
  GET_DASHBOARD_URL: "dashboardurl/geturl/",
  GET_PLATFORMS:"frontendplatforms/getavailableplatforms/",
  CHECK_URL:"dashboardurl/geturl/",
  RUN_UNDASHBOARDURL:"dashboardurl/rerundashboardurl/",


};

export const GlobalScriptsEndpoints = {
  GET_GLOBALS: "globalscripts/getglobals",
  CREATE: "globalscripts/create",
  UPDATE: "globalscripts/update",
};

export const ServiceRelationsEndpoints = {
  GET_ALL_MODELS: "servicerelation/getallmodels",
  GET_RELATIONS: "servicerelation/getrelations",
  CREATE: "servicerelation/create",
  REMOVE: "servicerelation/remove",
};

export const DashboardEndPoints = {
  FEED_MENU: "dashboard/feedmenu",
  FEED_MODEL_AND_TRANSACTIONS: "dashboard/feedmodelsandtransactions",
};

export const EnvironmentsEndpoints = {
  GET_ALL_ENVIRONMENTS: "environments/getenvironments",
  UPDATE_ENVIRONMENTS: "environments/updateenvironments",
  CREATE: "environments/createenvironments",
  REMOVE: "environments/removeenvironments",
  GET_ALL_ENVIRONMENTS_FROM_MODULE: "environments/getenvironments",
};

export const InputDefaultpoints = {
  GET_SELECTED_INPUTS: "inputdefaults/getselectedinputs/",
  GET_SELECTED_EVENTS: "inputdefaults/getselectedevents/",
};

export const GITEndPoints = {
  START_GIT: "/xerp-engine/git/startgitservice",
  STOP_GIT: "/xerp-engine/git/stopgitservice",
  GET_GIT_URL: "/xerp-engine/git/getgiturl",
};

export const ErpLangsEndPoint = {
  GET_LANGS: "erplang",
  GET_SUPPORTEDLANGS: "erplang/getsupportedlangs",
  ADD_NEW_LANGS: "erplang/addnewlangsupport",
  REMOVE_LANGS_SUPPORT: "erplang/removelangsupport",
  GET_WORKSPACE_LANGS: "erplang/getworkspacelangs",
  GET_WORKSPACE_DUPLICATED_LANGS: "erplang/getduplicatedlangs",
  UPDATE_WORKSPACE_DUPLICATED_LANGS: "erplang/updateuplicated",
  UPDATE_WORKSPACE_ONE_LANGS: "erplang/updateone",
};


export const MoveInstanceEndPoint = {
  RUN_ANOTHER_INSTANCE: "moveinstance/runanotherinstance",
  COMPLETED_IMAGE_PUSH: "moveinstance/completedimagepush",
};