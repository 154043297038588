import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import moment from "moment";
import Emitter from "_actions/emitter";
import i18n from "i18n";
import { ResponseStatusCode } from "_helpers";
import { updateStore, updateStoreCategories } from "_actions";
import { ErpEngineService, DashBoardURLSService } from "_services";
import {
  Row,
  Col,
  ButtonToolbar,
  Icon,
  Alert,
  Panel,
  SelectPicker,
} from "rsuite";
import {
  Loader,
  Container,
  Placeholder,
  Button,
  IconButton,
  FlexboxGrid,
} from "rsuite";
import CodeEditor from "components/Custom/CodeEditor";
import { Modal } from "reactstrap";
import Terminal from "react-console-emulator";

const { Paragraph } = Placeholder;
let LANG = i18n.t("LANG");
const WAIT_INTERVAL = 500;
const ENTER_KEY = 13;

class ScriptList extends Component {
  render() {
    const { onSelectScript, globalScripts, onCreate } = this.props;

    return (
      <Translation>
        {(t) => (
          <>
            <Container
              key={"workspaces-list"}
              style={{ height: "100%", padding: "1em" }}
              className={"custom-fade-in"}
            >
              <Row>
                <Col
                  className={"custom-fade-in"}
                  style={{
                    paddingTop: "5px",
                    paddingBottom: "5px",
                   
                  }}
                  md={8}
                  sm={12}
                >
                  <Button
                    onClick={onCreate}
                    appearance="subtle"
                    style={{ width: "100%", height: "100%", padding: 0 }}
                  >
                    <Panel
                      //header={props.header}

                      //bordered
                      shaded
                      className={"global-scriptfile-create"}
                    >
                        <Icon icon="plus" />
                        <div className="global-scriptfile-create-div"> {t("BUTTON_CREATE")}</div>
                       
                      
                    </Panel>
                  </Button>
                </Col>
                {globalScripts &&
                  globalScripts.map((item, key) => (
                    <Col
                      className={"custom-fade-in"}
                      key={key}
                      style={{
                        paddingTop: "5px",
                        paddingBottom: "5px",
                      }}
                      md={8}
                      sm={12}
                    >
                      <Card
                        handleSelect={() => {
                          onSelectScript(item);
                        }}
                        cID={item.id}
                        demoBadge={false}
                        file={item}
                        header={item.scriptName}
                      />
                    </Col>
                  ))}
              </Row>
            </Container>
            {/*
               <div>
              <div>
                {globalScripts.map((item, key) => (
                  <Button
                    key={key}
                    onClick={() => {
                      onSelectScript(item);
                    }}
                  >
                    {item.scriptName}
                  </Button>
                ))}
              </div>
            </div>
             */}
          </>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer, authentication } = state;
  const { loggedIn, LoginFailed, user } = authentication;

  const {
    workspace,
    workspaces,
    selectedService,
    store,
    storeCategories,
    globalScripts,
  } = workspaceReducer;

  return {
    workspace,
    workspaces,
    selectedService,
    store,
    storeCategories,
    user,
    globalScripts,
  };
};

const mapDispatchToProps = {
  updateStore,
  updateStoreCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScriptList);

const Card = (props) => (
  <div key={props.file.id}>
    <Button
      onClick={props.handleSelect}
      appearance="subtle"
      style={{ width: "100%", padding: 0 }}
    >
      <Panel
        header={props.header}
        bordered
        shaded
        className={"global-scriptfile"}
      >
        <p>
          <b>{i18n.t("TITLE_SCRIPT_INJECT_TYPE_" + props.file.injectType)}</b>
        </p>
        <p>{props.file.fileName}</p>
      </Panel>
    </Button>
  </div>
);
