import {
  //ServiceURLs,
  ErpEngineEndPoints,
  MakeReqestAndGetResponse,
  MakeReqestBlob,
  GetUserLang,
  GetBaseERPURL,
} from "./index";
import { HTTPOperationType } from "./httpOperations";

export const ErpEngineService = {
  GET_GENERATED_MODEL,
  GENERATE_ALL_MODEL,
  GET_BASE_PROJECT,
  SET_SETTING_BACKEND,
  GENERATE_ALL_TRANSACTION,
  ERP_COMMAN_LINE,
  EXECUTE_TRANSACTION_DIRECTLY,
  EXECUTE_TRANSACTION_DIRECTLY_BLOB,
  RUN_ON_DEV_MODE,
  GET_GENERATED_MODEL_BASE,
  GENERATE_ALL_TRANSACTION_BASE,
  CREATE_NEW_TERMINAL,
  GET_INTERFACES,
};
const forceLocal = false;
function GetErpURL(url) {
  if (forceLocal == true) return `http://localhost:7000`;
  else return `https://${url}`;
}
function GET_INTERFACES(ERP_URL, WorkSpaceId) {
  return MakeReqestAndGetResponse(
    GetErpURL(ERP_URL) + ErpEngineEndPoints.GET_INTERFACES + WorkSpaceId,
    HTTPOperationType.get
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

function CREATE_NEW_TERMINAL(ERP_URL, WorkSpaceId) {
  return MakeReqestAndGetResponse(
    GetErpURL(ERP_URL) + ErpEngineEndPoints.CREATE_NEW_TERMINAL + WorkSpaceId,
    HTTPOperationType.get
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

function GET_GENERATED_MODEL_BASE(WorkspaceId) {
  var js = {
    id: WorkspaceId,
  };

  return MakeReqestAndGetResponse(
    GetBaseERPURL() + ErpEngineEndPoints.GET_GENERATED_MODEL_BASE,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

function GENERATE_ALL_TRANSACTION_BASE(WorkspaceId) {
  var js = {
    id: WorkspaceId,
  };

  return MakeReqestAndGetResponse(
    GetBaseERPURL() + ErpEngineEndPoints.GENERATE_ALL_TRANSACTION_BASE,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

function GET_GENERATED_MODEL(ERP_URL, WorkspaceId) {
  var js = {
    WorkspaceId: WorkspaceId,
  };

  return MakeReqestAndGetResponse(
    GetErpURL(ERP_URL) + ErpEngineEndPoints.GET_GENERATED_MODEL,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

function GENERATE_ALL_MODEL(ERP_URL, WorkspaceId) {
  var js = {
    WorkspaceId: WorkspaceId,
  };

  return MakeReqestAndGetResponse(
    GetErpURL(ERP_URL) + ErpEngineEndPoints.GENERATE_ALL_MODEL,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

function GET_BASE_PROJECT(ERP_URL, version) {
  var js = {
    version: version,
  };

  return MakeReqestAndGetResponse(
    GetErpURL(ERP_URL) + ErpEngineEndPoints.GET_BASE_PROJECT,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

function SET_SETTING_BACKEND(ERP_URL, WorkSpaceId, ServiceType, Version) {
  var js = {
    Version: Version,
    WorkSpaceId: WorkSpaceId,
    ServiceType: ServiceType,
  };

  return MakeReqestAndGetResponse(
    GetErpURL(ERP_URL) + ErpEngineEndPoints.SET_SETTING_BACKEND,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

//GENERATE_ALL_TRANSACTION

function GENERATE_ALL_TRANSACTION(ERP_URL, WorkspaceId) {
  var js = {
    WorkspaceId: WorkspaceId,
  };

  return MakeReqestAndGetResponse(
    GetErpURL(ERP_URL) + ErpEngineEndPoints.GENERATE_ALL_TRANSACTION,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

//ERP_COMMAN_LINE

function ERP_COMMAN_LINE(ERP_URL, command, guid, WorkSpaceId) {
  var js = {
    command: command,
    guid: guid,
    WorkSpaceId: WorkSpaceId,
  };

  return MakeReqestAndGetResponse(
    GetErpURL(ERP_URL) + ErpEngineEndPoints.ERP_COMMAN_LINE,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

//EXECUTE_TRANSACTION_DIRECTLY

function EXECUTE_TRANSACTION_DIRECTLY(
  ERP_URL,
  TransactionName,
  ServiceName,
  Body,
  FilterModel
) {
  var js = {
    TransactionName: TransactionName,
    ServiceName: ServiceName,
    Body: Body,
  };
  if (FilterModel) {
    js["FilterModel"] = FilterModel;
  }

  return MakeReqestAndGetResponse(
    GetErpURL(ERP_URL) + ErpEngineEndPoints.EXECUTE_TRANSACTION_DIRECTLY,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

function EXECUTE_TRANSACTION_DIRECTLY_BLOB(
  ERP_URL,
  TransactionName,
  ServiceName,
  Body,
  FilterModel,
  FileName = new Date().toJSON() + ".csv"
) {
  var js = {
    TransactionName: TransactionName,
    ServiceName: ServiceName,
    Body: Body,
  };
  if (FilterModel) {
    js["FilterModel"] = FilterModel;
  }
  const _props = {
    OperationUrl:
      GetErpURL(ERP_URL) + ErpEngineEndPoints.EXECUTE_TRANSACTION_DIRECTLY,
    _HTTPOperationType: HTTPOperationType.post,
    JsonParams: js,
  };
  debugger;
  return MakeReqestBlob(
    _props.OperationUrl,
    _props._HTTPOperationType,
    _props.JsonParams
  ).then((res) => {
    debugger;
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

//RUN_ON_DEV_MODE

function RUN_ON_DEV_MODE(ERP_URL, WorkspaceId, Version) {
  var js = {
    WorkspaceId: WorkspaceId,
    ServiceType: "dev",
    Version: Version,
  };

  return MakeReqestAndGetResponse(
    GetErpURL(ERP_URL) + ErpEngineEndPoints.RUN_ON_DEV_MODE,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}
