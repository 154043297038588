import React, { Component } from "react";
import { connect } from "react-redux";
import { Hook, Console, Decode } from "@alican.kuklaci/console-feed";
import { Translation } from "react-i18next";

import Emitter from "_actions/emitter";
import { SocketOperationLocal } from "_socketOperations";
import { Icon, IconButton } from "rsuite";

const WAIT_INTERVAL = 2000;

class ResponsesConsole extends Component {
  constructor(props) {
    super(props);

    this.state = {
      count: 0,
      logs: [
        {
          method: "result",
          data: ["Wellcome to TheXApp"],
        },
      ],
    };
    this.consoleResponses = React.createRef();
  }

  removeEmpty(data) {
    //transform properties into key-values pairs and filter all the empty-values
    // const entries = Object.entries(data).filter(([, value]) => value != null);
    let entries = Object.entries(data);
    if (Array.isArray(data) && data.length == 0) {
      return ["__console_feed_remaining__0"];
    } else {
      const clean = entries.map(([key, v]) => {
        const value = typeof v == "object" ? this.removeEmpty(v) : v;
        return [key, value];
      });
      //transform the key-value pairs back to an object.
      return Object.fromEntries(clean);
    }
    //.filter(([, value]) => value != null);
    //map through all the remaining properties and check if the value is an object.
    //if value is object, use recursion to remove empty properties
  }

  componentDidMount() {
    Emitter.on(SocketOperationLocal.HTTP_LOG_OBSERVE, (newValue) =>
      this.handleGetResponseCommendLine2(newValue)
    );
    /*
    Hook(window.console, (log) => {
      const _wc = window.console;
      if (log[0].method == "dir") {
        debugger;
      }

      this.setState((state) => {
        var logz = state.logs.slice();

        logz.push(Decode(log));

        return {
          logs: logz,
        };
      });
    });
    */
  }
  componentWillUnmount() {
    Emitter.off(SocketOperationLocal.HTTP_LOG_OBSERVE);
  }
  scrollToBottom = () => {
    const el = this.consoleResponses;

    el?.current?.scrollBy(0, el?.current?.children[0].offsetHeight);
  };

  _list = [];

  handleGetResponseCommendLine = () => {
    let _logs = [...this.state.logs, ...this._list];
    const that = this;

    this.setState(
      ({ logs }) => ({ logs: _logs, count: this.state.count + 1 }),
      () => {
        this._list = [];
        this.scrollToBottom();
      }
    );
  };

  handleGetResponseCommendLine2 = (data) => {
    try {
      const that = this;
      let _logs = [];
      if (data.includes("Response Body:") || data.includes("Request Body:")) {
        try {
          const _split = data.includes("Response Body:")
            ? data.split("Response Body:")
            : data.includes("Request Body:")
            ? data.split("Request Body:")
            : "";
       
          let _re = _split[1];
          _re = JSON.parse(_re);
          // console.dir(_re);
          this._list.push({
            method: "nested",
            data: [this.removeEmpty(_re)],
          });
          // const _DD = Decode(_re);
        } catch (error) {
          this._list.push({
            method: "command",
            data: [data],
          });
        }
        const that = this;
        this.timer = setTimeout(() => {
          that.handleGetResponseCommendLine();
        }, WAIT_INTERVAL);
      } else {
        this._logs.push({
          method: "command",
          data: [data],
        });
        const that = this;
        this.timer = setTimeout(() => {
          that.handleGetResponseCommendLine();
        }, WAIT_INTERVAL);
      }
    } catch (error) {}

    //   debugger;
  };

  componentWillMount() {
    this.timer = null;
  }

  handleClear = () => {
    this.setState({
      logs: [
        {
          method: "result",
          id: new Date() + "-",
          data: ["Console cleared.."],
        },
      ],
    });
  };

  render() {
    return (
      <Translation>
        {(t) => (
          <>
            <div
              ref={this.consoleResponses}
              key={this.state.count + "-CONSOLE"}
              className="console-tab-log"
            >
              <Console
                logs={this.state.logs}
                variant="dark"
                //filter={["dir"]}
                logFilter={(a, b, c, d) => {
                  debugger;
                }}
              />
              <IconButton
                className="console-tab-log-clear"
                size="xs"
                appearance="ghost"
                circle
                icon={<Icon icon={"trash-o"} />}
                onClick={() => {
                  this.handleClear();
                }}
              />
            </div>
          </>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ResponsesConsole);
