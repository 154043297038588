
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import classnames from "classnames";
 
// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";


class WorkspacesHeader extends React.Component {
  openSearch = () => {
    document.body.classList.add("g-navbar-search-showing");
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-showing");
      document.body.classList.add("g-navbar-search-show");
    }, 150);
    setTimeout(function () {
      document.body.classList.add("g-navbar-search-shown");
    }, 300);
  };
  // function that on mobile devices makes the search close
  closeSearch = () => {
    document.body.classList.remove("g-navbar-search-shown");
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-show");
      document.body.classList.add("g-navbar-search-hiding");
    }, 150);
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hiding");
      document.body.classList.add("g-navbar-search-hidden");
    }, 300);
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hidden");
    }, 500);
  };


  handleChange = (e) => {
   // debugger
    const { handleSearch } = this.props;
    handleSearch(e.target.value);

  }

  render() {
    const { openCreateModal } = this.props;
    return (
      <>
        <div className="header  pb-0">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-2 pb-4">
                <Col lg="6" xs="7">
                  <Button color="default"
                    onClick={e => {
                      e.preventDefault();
                      openCreateModal();
                    }}
                    size="sm"
                    outline
                    className={"project-create-btn"}
                    type="button">
                    Create new
                  </Button>
                </Col>
                <Col className="text-right" lg="6" xs="5">
                  <Form
                    style={{ float: "right", }}
                    className={classnames(
                      "navbar-search form-inline ml-sm-3",
                      { "navbar-search-light": this.props.theme === "dark" },
                      { "navbar-search-dark": this.props.theme === "light" }
                    )}
                  >
                    <FormGroup className="mb-0">
                      <InputGroup className="input-group-alternative input-group-merge project-search-input">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder="Search" maxLength="30" onChange={this.handleChange} type="text" className="form-control-sm" />
                      </InputGroup>
                    </FormGroup>
                    <button
                      aria-label="Close"
                      className="close"
                      type="button"
                      onClick={this.closeSearch}
                    >
                      <span aria-hidden={true}>×</span>
                    </button>
                  </Form>
                </Col>
              </Row>


            </div>
          </Container>
        </div>
      </>
    );
  }
}

WorkspacesHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string
};

export default WorkspacesHeader;
