import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translation } from "react-i18next";
import i18n from "i18n";

import {
    Modal,
    Container,
    Row,
    Col,
} from "reactstrap";



import { Toggle, Icon, Tooltip, Popover, Whisper, Loading, Button, Alert, Animation } from 'rsuite';
import { Divider, Form, FormGroup, FormControl, ControlLabel, SelectPicker, Input, InputGroup, InputNumber, DatePicker, Schema, ButtonToolbar } from 'rsuite';

import CTable from './CTable';
 

import { contentTypesSP, typeDetailsSP } from 'assets/globalOps/CollectionTypes';
const { StringType, NumberType, ArrayType, DateType, ObjectType, BooleanType } = Schema.Types;
const model = Schema.Model({});


class CFormRefElement extends Component {
    constructor(props) {
        super(props)
        this.textInput = React.createRef();
      
    }

 

    render() {
         const { transaction, data, models, loading } = this.props;


        return (
            <Translation>
                {t => (
                    <>
                        {//data.relationModel.relModel.id
                            data.relationModel &&
                                data.relationModel.relationType === 1 ?
                                <CTable
                                    data={[]}
                                    dataModel={[]}
                                /> :
                                <div className={"refFormControl"}>
                                    <InputGroup style={{}}>
                                        <Input
                                            disabled
                                            />
                                        <InputGroup.Button disabled >
                                            <Icon icon="more" />
                                        </InputGroup.Button>
                                    </InputGroup>
                                    
                                </div>
                        }

                    </>
                )}
            </Translation>
        )
    }
}

const mapStateToProps = (state, props) => {
    const { workspaceReducer } = state;
    const { models, transaction, workspace, dataModels } = workspaceReducer;

    return {
        workspace,
        transaction,
        dataModels,
        models
    };
}


const mapDispatchToProps = {

}
export default connect(mapStateToProps, mapDispatchToProps)(CFormRefElement)



    /*
      <FormControl
      fluid
      accepter={Form}
      defaultValue={(data.typeDetail === 6 || data.typeDetail === 7) ? 0.01 : 0} step={(data.typeDetail === 6 || data.typeDetail === 7) ? 0.01 : 1}
      disabled={(data.privateField || loading)}
      name={data.name.toLowerCase()} className={"custom-fade-in"} >
      <FormGroup>
          <ControlLabel>Username</ControlLabel>
          <FormControl name="name" />
      </FormGroup>
      <FormGroup>
          <ControlLabel>Email</ControlLabel>
          <FormControl name="email" type="email" />
      </FormGroup>
  </FormControl>
    
    */
