import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import moment from "moment";
import Emitter from "_actions/emitter";
import i18n from "i18n";
import { ResponseStatusCode } from "_helpers";
import {
  erpRolesService,
  ModulesService,
  erpRolesAndUserRelationsService,
  WorkspaceUserService,
} from "_services";
import { InputGroup, Input, Icon, Alert, SelectPicker } from "rsuite";
import { Nav, Tag, TagGroup } from "rsuite";
import DynamicTable from "../Transaction/DynamicTable";
import {
  Loader,
  Container,
  Row,
  Col,
  Placeholder,
  Button,
  IconButton,
  Cascader,
  Dropdown,
  ButtonGroup,
} from "rsuite";

import { Modal } from "reactstrap";
const { Paragraph } = Placeholder;
let LANG = i18n.t("LANG");
const WAIT_INTERVAL = 500;
const ENTER_KEY = 13;

class ImportDataModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  resetContent = () => {
    this.setState({
      isLoading: false,
    });
  };

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (this.props.isActive)
      if (prevProps.isActive != this.props.isActive && this.props.isActive) {
        this.resetContent();
      }
  }

  componentWillMount() {
    this.timer = null;
  }

  render() {
    const { isActive, handleToggle, selectedService,model ,data} = this.props;
    const { isLoading } = this.state;

    if(isActive){
      debugger
    }
    return (
      <Translation>
        {(t) => (
          <>
            <Modal
              className="modal-dialog-centered"
              isOpen={isActive}
              size="lg"
              scrollable={true}
            >
              <div>
                <div className="modal-header store-model-header">
                  <h5 className="modal-title" id="CheckProjectModal">
                    {t("TITLE_MODAL_IMPORT_DATA")}
                  </h5>
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    disabled={this.state.isLoading}
                    onClick={() => handleToggle()}
                  >
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
                <div className={"store-header"}></div>
              </div>

              <div className="modal-body store-model-body">
                <Container>
                  <Row>
                  <DynamicTable
                      height={window.innerHeight - 320}
                      data={data}
                      dataModel={
                        model.find((x) => x.id == selectedService.relResponseModelId)
                          .dataTypes
                      }
                     
            />

                  </Row>

                  {isLoading && (
                    <div className={"fade-in"}>
                      <Loader backdrop vertical />
                    </div>
                  )}
                </Container>
              </div>

              {this.state.alert}
            </Modal>
          </>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer } = state;
  const { models, model, transaction, workspace, dataModels, selectedService } =
    workspaceReducer;

  return {
    workspace,
    transaction,
    dataModels,
    models,
    model,
    selectedService,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ImportDataModal);
