import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import { Button } from "rsuite";

class CCellRefModel extends Component {
  render() {
    const { data, rowData, dataKey } = this.props;
    let _rd = "";
    let _lc = "_id";
    try {
      _rd = rowData.hasOwnProperty(data.name.toLowerCase())
        ? rowData[data.name.toLowerCase()]
        : null;
      _lc = data.refModel.hasOwnProperty("listColumn")
        ? data.refModel.listColumn
        : "_id";
    } catch (error) {
      debugger;
    }

    try {
      return (
        <Translation>
          {(t) => (
            <>
              <Button appearance="link" className={"custom-cell"}>
                {data.refModel.relationType == 0
                  ? _rd && _rd.length > 0
                    ? _rd[0][_lc]
                    : "-"
                  : t("CELL_TYPE_REFMODEL_LIST_SENTENCE", {
                      count: _rd && _rd.length > 0 ? _rd.length : "0",
                      name: data.displayName,
                    })}
              </Button>
            </>
          )}
        </Translation>
      );
    } catch (error) {
      debugger;
      return "-";
    }
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CCellRefModel);

/*
<Whisper
              enterable
              onClick={(e) => {
                if (e.stopPropagation) e.stopPropagation();
              }}
              trigger="hover"
              placement="auto"
              speaker={
                <Popover>
                  <div style={{ width: "50vw" }} className={"typePopover"}>
                    <p className={"typeName"}>{data.displayName}</p>
                    {data.refModel.relationType == 0 ? (
                      <FormGenerator
                        readOnly={true}
                        isRefModelForm={true}
                        refModel={data.refModel}
                        handleUpdateRefData={() => {}}
                      />
                    ) : (
                      <DynamicTable
                        isDynamic={true}
                        height={200}
                        data={rowData[dataKey]}
                        dataModel={data.refModel.dataTypes}
                        handleSelect={(_data) => {

                          //this.handleSelect(_data);
                        }}
                      />
                    )}
                  </div>
                </Popover>
              }
            >
              <Button appearance="link" className={"custom-cell"}>
                {data.refModel.relationType == 0
                  ? t("CELL_TYPE_REFMODEL_OBJ")
                  : t("CELL_TYPE_REFMODEL_LIST")}
              </Button>
            </Whisper>


*/
