import { workspaceConstants } from "../_constants";

export function updateWorkspace(_data) {
  return {
    type: workspaceConstants.UPDATE_WORKSPACE,
    payload: {
      data: _data,
    },
  };
}

export function updateWorkspaceList(_data) {
  return {
    type: workspaceConstants.UPDATE_WORKSPACELIST,
    payload: {
      data: _data,
    },
  };
}

export function updateSelectedService(_data, _ref) {
  return {
    type: workspaceConstants.UPDATE_SELECTEDSERVICE,
    payload: {
      data: _data,
      ref: _ref,
    },
  };
}

export function updateServices(_data) {
  return {
    type: workspaceConstants.UPDATE_SERVICES,
    payload: {
      data: _data,
    },
  };
}

export function updateModules(_data) {
  return {
    type: workspaceConstants.UPDATE_MODULES,
    payload: {
      data: _data,
    },
  };
}
export function updateSelectedModule(_data) {
  return {
    type: workspaceConstants.UPDATE_SELECTEDMODULE,
    payload: {
      data: _data,
    },
  };
}
export function updatedModels(_data) {
  return {
    type: workspaceConstants.UPDATE_MODELS,
    payload: {
      data: _data,
    },
  };
}
export function updateSelectedModel(_data) {
  return {
    type: workspaceConstants.UPDATE_SELECTEDMODEL,
    payload: {
      data: _data,
    },
  };
}

export function updatedTransactions(_data) {
  return {
    type: workspaceConstants.UPDATE_TRANSACTIONS,
    payload: {
      data: _data,
    },
  };
}
export function updateAllTransactions(_data, _data2, _data3) {
  return {
    type: workspaceConstants.UPDATE_ALL_TRANSACTIONS,
    payload: {
      data: _data,
      data2: _data2,
      data3: _data3,
    },
  };
}

export function updateSelectedTransaction(_data) {
  return {
    type: workspaceConstants.UPDATE_SELECTEDTRANSACTION,
    payload: {
      data: _data,
    },
  };
}

export function clearWorkspace() {
  return {
    type: workspaceConstants.CLEAR_WORKSPACE,
    payload: {
      data: "",
    },
  };
}

export function updateStore(_data) {
  return {
    type: workspaceConstants.UPDATE_STORE_LIST,
    payload: {
      data: _data,
    },
  };
}

export function updateStoreCategories(_data) {
  return {
    type: workspaceConstants.UPDATE_CATEGORY_STORE_LIST,
    payload: {
      data: _data,
    },
  };
}

export function updateGeneratedTransactions(_data) {
  return {
    type: workspaceConstants.UPDATE_GENERATED_TRANSACTIONS,
    payload: {
      data: _data,
    },
  };
}
export function updateGeneratedModules(_data) {
  return {
    type: workspaceConstants.UPDATE_GENERATED_MODELS,
    payload: {
      data: _data,
    },
  };
}

export function updateGeneratedInterfaces(_data) {
  return {
    type: workspaceConstants.UPDATE_GENERATED_INTERFACES,
    payload: {
      data: _data,
    },
  };
}

export function updateGlobalScripts(_data) {
  return {
    type: workspaceConstants.UPDATE_GLOBAL_SCRIPTS,
    payload: {
      data: _data,
    },
  };
}
export function updateAllModelScripts(_data) {
  return {
    type: workspaceConstants.UPDATE_ALL_MODEL_SCRIPTS,
    payload: {
      data: _data,
    },
  };
}
export function updateAllTransScripts(_data) {
  return {
    type: workspaceConstants.UPDATE_ALL_TRANS_SCRIPTS,
    payload: {
      data: _data,
    },
  };
}
export function updateServiceRelations(_data) {
  return {
    type: workspaceConstants.UPDATE_SERVICE_RELATIONS,
    payload: {
      data: _data,
    },
  };
}

export function updateServiceRelationsModels(_data) {
  return {
    type: workspaceConstants.UPDATE_SERVICE_RELATIONS_ALL_MODEL,
    payload: {
      data: _data,
    },
  };
}
export function setEnvironments(_data) {
  return {
    type: workspaceConstants.UPDATE_ENVIRONMENTS,
    payload: {
      data: _data,
    },
  };
}

export function setEngineStatus(_data) {
  return {
    type: workspaceConstants.UPDATE_ENGINE_STATUS,
    payload: {
      data: _data,
    },
  };
}

export function setTerminalList(_data) {
  return {
    type: workspaceConstants.UPDATE_TERMINAL_LIST,
    payload: {
      data: _data,
    },
  };
}

export function setGitStatus(_data) {
  return {
    type: workspaceConstants.UPDATE_GIT_STATUS,
    payload: {
      data: _data,
    },
  };
}

export function updateWorkspaceLangs(_data) {
  return {
    type: workspaceConstants.UPDATE_WORKSPACE_LANGS,
    payload: {
      data: _data,
    },
  };
}

export function updateSupportedLangs(_data) {
  return {
    type: workspaceConstants.UPDATE_SUPPORTED_LANGS,
    payload: {
      data: _data,
    },
  };
}

export function updateWorkspaceTextAndValues(_data) {
  debugger;
  return {
    type: workspaceConstants.UPDATE_WORKSPACE_ALL_TEXT_AND_VALUES,
    payload: {
      data: _data,
    },
  };
}
export function updateWorkspaceDupTextAndValues(_data) {
  return {
    type: workspaceConstants.UPDATE_WORKSPACE_DUPLICATED_TEXT_AND_VALUES,
    payload: {
      data: _data,
    },
  };
}
export function updateWorkspaceCurrentLang(_data) {
  return {
    type: workspaceConstants.UPDATE_WORKSPACE_CURRENT_LANG,
    payload: {
      data: _data,
    },
  };
}

