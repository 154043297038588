import {
  //ServiceURLs,
  WorkspaceEndpoint,
  GetBaseERPURL,
  MakeReqestAndGetResponse,
  GetUserLang,
} from "./index";
import { HTTPOperationType } from "./httpOperations";

export const WorkspaceService = {
  CREATE_WORK_SPACE,
  STEPBYLOAD,
  SET_SETTINGS,
  GET_CONFIGS,
  RE_DEPLOY_ENGINE,
  PREPARE_WORKSPACE_FOR_CLONE,
  CLONE_WORKSPACE,
  GET_MIGRATION_STATUS,
  PRAPARE_CLONE_WORKSPACE_WITH_TARGET,
  TRANSFER_DATA_WORKSPACE,
  GET_DASHBOARD_LOGO,
  SET_DASHBOARD_LOGO
};
function SET_DASHBOARD_LOGO(
  WorkSpaceId,
  DashboardLogo
) {
  var js = {
    WorkSpaceId: WorkSpaceId,
    DashboardLogo: DashboardLogo
  };
  return MakeReqestAndGetResponse(
    GetBaseERPURL() + WorkspaceEndpoint.SET_DASHBOARD_LOGO,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}
function GET_DASHBOARD_LOGO(
  WorkSpaceId,
) {
  var js = {
    WorkSpaceId: WorkSpaceId,
  };
  return MakeReqestAndGetResponse(
    GetBaseERPURL() + WorkspaceEndpoint.GET_DASHBOARD_LOGO,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}


function CLONE_WORKSPACE(
  WorkSpaceId,
  WorkspaceName,
  RefMigrateData,
  CloneRoles = false
) {
  var js = {
    id: WorkSpaceId,
    WorkspaceName: WorkspaceName,
    RefMigrateData: RefMigrateData,
    CloneRoles: CloneRoles,
  };
  return MakeReqestAndGetResponse(
    GetBaseERPURL() + WorkspaceEndpoint.CLONE_WORKSPACE,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

function PRAPARE_CLONE_WORKSPACE_WITH_TARGET(RelCloneWorkspaceId, FromTarget) {
  var js = {
    RelCloneWorkspaceId: RelCloneWorkspaceId,
    FromTarget: FromTarget,
  };
  return MakeReqestAndGetResponse(
    GetBaseERPURL() + WorkspaceEndpoint.PRAPARE_CLONE_WORKSPACE_WITH_TARGET,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

function TRANSFER_DATA_WORKSPACE(
  WorkSpaceId,
  FromTarget,
  ToTarget,
  RefMigrateData
) {
  var js = {
    RelCloneWorkspaceId: WorkSpaceId,
    FromTarget: FromTarget,
    ToTarget: ToTarget,
    RefMigrateData: RefMigrateData,
  };
  return MakeReqestAndGetResponse(
    GetBaseERPURL() + WorkspaceEndpoint.TRANSFER_DATA_WORKSPACE,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

function PREPARE_WORKSPACE_FOR_CLONE(WorkSpaceId) {
  var js = {
    id: WorkSpaceId,
  };
  return MakeReqestAndGetResponse(
    GetBaseERPURL() + WorkspaceEndpoint.PREPARE_WORKSPACE_FOR_CLONE,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}
//  "ServiceType":"DEV"
function RE_DEPLOY_ENGINE(WorkSpaceId, ServiceType) {
  var js = {
    WorkSpaceId: WorkSpaceId,
    ServiceType: ServiceType,
  };
  return MakeReqestAndGetResponse(
    GetBaseERPURL() + WorkspaceEndpoint.RE_DEPLOY_ENGINE,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

function STEPBYLOAD(IsOrderByDesc, LastIndexId, Count, SearchTerm, DynamicObj) {
  var js = {
    SearchTerm: SearchTerm,
    Count: Count,
    LastIndexId: LastIndexId,
    IsOrderByDesc: IsOrderByDesc,
    DynamicObj: DynamicObj,
  };

  return MakeReqestAndGetResponse(
    GetBaseERPURL() + WorkspaceEndpoint.STEPBYLOAD,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

function CREATE_WORK_SPACE(Name) {
  var js = {
    Name: Name,
  };
  return MakeReqestAndGetResponse(
    GetBaseERPURL() + WorkspaceEndpoint.CREATE_WORK_SPACE,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

function GET_CONFIGS(WorkSpaceId) {
  var js = {
    WorkSpaceId: WorkSpaceId,
  };
  return MakeReqestAndGetResponse(
    GetBaseERPURL() + WorkspaceEndpoint.GET_CONFIGS,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}
function GET_MIGRATION_STATUS(WorkSpaceId) {
  var js = {
    id: WorkSpaceId,
  };
  return MakeReqestAndGetResponse(
    GetBaseERPURL() + WorkspaceEndpoint.GET_MIGRATION_STATUS,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

function SET_SETTINGS(WorkSpaceId, ServiceType, Version) {
  var js = {
    WorkSpaceId: WorkSpaceId,
    ServiceType: ServiceType,
    Version: Version,
  };
  return MakeReqestAndGetResponse(
    GetBaseERPURL() + WorkspaceEndpoint.SET_SETTINGS,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}
