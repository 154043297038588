import { userConstants } from "../_constants";


export const authenticationAction={
    LoginSuccess,
    LoginFailure,
    logout,
 };


export function UpdateUser(_user) {
    return {
        type: userConstants.UPDATE_USER,
        user:_user
    }
}

export function LoginSuccess(_user) {
    return {
        type: userConstants.LOGIN_SUCCESS,
        user: _user
    }
}
export function LoginFailure() {
    return {
        type: userConstants.LOGIN_FAILURE,

    }
}

export function logout() {

    return {
        type: userConstants.LOGOUT,
    }
}
 

