import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translation } from "react-i18next";
import i18n from 'i18n';

import {
    Modal,
    Container,
    Row,
    Col,
    Card,
    CardBody,
} from "reactstrap";

import CDataModelCard from 'components/Custom/CDataModelCard';
import { PanelGroup } from 'rsuite';
import { CMSService } from '_services';
import { } from '_actions';

class GlobalDataModels extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
        }
    }

    handleSetDataModel = (_data) => {
        const { setDataModel, handleToggle } = this.props;
        setDataModel(_data);
        handleToggle();
    }


    render() {
        const { data, isActive, handleToggle } = this.props;
        const { loading } = this.state;
        return (
            <Translation>
                {t => (
                    <Modal
                        className="modal-dialog-centered stepper-modal"
                        isOpen={isActive}
                        size={"lg"}
                        toggle={handleToggle}
                        scrollable={true}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title" id="GlobalDataModels">
                                {t("TITLE_GLOBAL_DATA_MODELS")}
                                <p style={{ marginBottom: '0px' }}>{t("SUBTITLE_GLOBAL_DATA_MODELS")}</p>
                            </h5>
                            <button
                                aria-label="Close"
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => handleToggle()}
                            >
                                <span aria-hidden={true}>×</span>
                            </button>
                        </div>
                        <div className="modal-body" style={{ paddingTop: "0px" }} >
                            <Container>
                                <Row >
                                    <Col xs={12}>
                                        <PanelGroup>
                                            {
                                                data.map((item, key) => (
                                                    <CDataModelCard handleSetDataModel={this.handleSetDataModel} key={key} data={item} />
                                                ))
                                            }
                                        </PanelGroup>
                                    </Col>
                                </Row >
                            </Container>
                        </div>
                    </Modal>
                )}
            </Translation>
        )
    }
}

const mapStateToProps = (state, props) => {
    const { workspaceReducer } = state;
    const { workspaces, workspace, selectedService, services, modules, module } = workspaceReducer;

    return {
        workspaces,
        workspace,
        modules,
        module,
        selectedService,
        services
    };
}
const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(GlobalDataModels)
