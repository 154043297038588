import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import i18n from "i18n";

import { Icon } from "rsuite";
import { FormControl, Input, InputGroup, Alert } from "rsuite";

import DynamicTable from "./DynamicTable";
import SearchObjectModal from "./Modals/SearchObjectModal";
import RunTransactionModal from "components/Transaction/Modals/RunTransactionModal";
import { ErpEngineService, ModelsService } from "_services";
import EasyInvoiceViewer from "components/Modals/EasyInvoiceViewer";
import ConfirmModal from "components/Modals/ConfirmModal";
import ListItemOperations from "./Modals/ListItemOperations";
import ListItemOperationsCustom from "./Modals/ListItemOperationsCustom";
import { enqueueSnackbar } from "notistack";

class FormRefElement extends Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
    const { formValueOrj } = this.props;
    const _cData = this.props.data;
    const formValue = formValueOrj
      ? { formValueOrj, ...this.props.formValue }
      : this.props.formValue;

    let _cDataRefRel =
      formValue && formValue.hasOwnProperty(_cData.name)
        ? this.getNestedValue(_cData, formValue)
        : "";
    debugger;
    //let _cDataRefRel = "";
    // debugger
    /*
    if (formValue && formValue.hasOwnProperty(_cData.name)) {
      if (_cData.contentType == "5") {
        debugger
        _cDataRefRel = formValue.hasOwnProperty(_cData.name)
          ? typeof formValue[_cData.name] === "object"
            ? formValue[_cData.name] ?(formValue[_cData.name][
                formValue[_cData.name].hasOwnProperty(
                  _cData.relationModel.listColumn
                )
                  ? _cData.relationModel.listColumn
                  : "_id"
              ]):formValue.hasOwnProperty("_id")?formValue._id:"-"
            : formValue[_cData.name]
          : "";
      } else {
        _cDataRefRel = formValue._id;
      }
    }
*/

    this.state = {
      text: _cDataRefRel,
      isTransModal: false,
      refTransaction: null,
      refFormValue: null,
      isInvoiceViewer: false,
      isConfirmModal: false,
      confirmTitle: "",
      selectedItem: null,
      isDetailOperations: false,
    };
  }
  handleConfirmModal = (title = "") => {
    this.setState({
      confirmTitle: title,
      isConfirmModal: !this.state.isConfirmModal,
    });
  };
  handleInvoiceViewerModal = () => {
    this.setState({
      isInvoiceViewer: !this.state.isInvoiceViewer,
    });
  };
  handleRunTransaction = (refTransaction, _formValue, refEvent) => {
    const { workspace, data } = this.props;
    const item = data;
    const that = this;
    let selectedService = refTransaction;
    const { formValue, formError, form } = this.state;
    const URL_MODE = "dev_URL";
    debugger;
    const _body = {
      [refEvent.modelKey]: _formValue,
    };
    this.setState({ loading: true });
    ErpEngineService.EXECUTE_TRANSACTION_DIRECTLY(
      workspace[URL_MODE],
      this.props.isListDetail
        ? this.props.itemOperation
        : selectedService.functionName,
      selectedService.executableTransactionServiceName,
      JSON.stringify(this.preTransactionFunc(_body, refTransaction, _body))
    )
      .then((data) => {
        debugger;
        if (data.operationResult) {
          that.postTransactionFunc(
            data.operationResult,
            data.dynamicValue,
            refEvent,
            refTransaction
          );
          that.handleTransModal(
            refTransaction,
            data.dynamicValue,
            undefined,
            refEvent
          );
          //debugger;
        }
        that.setState({ loading: false });
      })
      .catch((err) => {
        that.setState({ loading: false });
        try {
          Alert.error(
            Array.isArray(err.failText) ? err.failText.join(". ") : err.failText
          );
        } catch (error) {
          Alert.error(i18n.t("MESSAGE_TRANSACTION_SUBMIT_UNSUCCESS"));
        }
        // let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
        debugger;
      });
  };

  transactionPrepareFunc = () => {
    const { selectedService, model, isRefModelForm, refModel, formValue } =
      this.props;
    const _sModels = model
      ? model.find(
          (x) =>
            x.id ===
            (isRefModelForm
              ? refModel.id
              : selectedService.responseModelType
              ? selectedService.relResponseModelId
              : selectedService.reqModelId)
        ).dataTypes
      : (isRefModelForm ? refModel : selectedService)
      ? isRefModelForm
        ? refModel.dataTypes
        : selectedService.model
      : [];
    return {
      formData: JSON.parse(JSON.stringify(formValue)),
      model: _sModels,
    };
  };

  runTransaction = (refTransaction, _formValue) => {
    const { workspace, item } = this.props;
    const that = this;
    let selectedService = refTransaction;
    const { formValue, formError, form } = this.state;
    const URL_MODE = "dev_URL";

    const _body = _formValue;
    this.setState({ loading: true });
    ErpEngineService.EXECUTE_TRANSACTION_DIRECTLY(
      workspace[URL_MODE],
      this.props.isListDetail
        ? this.props.itemOperation
        : selectedService.functionName,
      selectedService.executableTransactionServiceName,
      JSON.stringify(this.preTransactionFunc(_body, refTransaction, _body))
    )
      .then((data) => {
        if (data.operationResult) {
          that.postTransactionFunc(
            data.operationResult,
            data.dynamicValue,
            "refEvent",
            refTransaction
          );

          //debugger;
        }

        that.setState({ loading: false });
      })
      .catch((err) => {
        that.setState({ loading: false });
        try {
          Alert.error(
            Array.isArray(err.failText) ? err.failText.join(". ") : err.failText
          );
        } catch (error) {
          Alert.error(i18n.t("MESSAGE_TRANSACTION_SUBMIT_UNSUCCESS"));
        } // let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
        debugger;
      });
  };

  handleTransModal = (
    refTransaction = null,
    formValue,
    status,
    refEvent = null
  ) => {
    this.setState({
      isTransModal: status == undefined ? !this.state.isTransModal : status,
      refTransaction: refTransaction,
      refFormValue: formValue,
      refEvent: refEvent,
    });
  };
  handleIsDetailOperations = () => {
    this.setState({ isDetailOperations: false });
  };
  preTransactionFunc = (data, _selectedService, _formValue) => {
    const { isRunTransModal, runTransModalClose, updateBaseForm } = this.props;
    const _dynamicSchemaModel = this.prepareGenerateSchema();

    const that = this;

    const runTransaction = that.runTransaction;
    const transactions = this.props.allTransList;
    const Toast = this.ToastMessage;
    const SnackToast = this.SnackMessage;
    const easyinvoice = require("easyinvoice").default; //easyinvoice.download('myInvoice.pdf', data.InvoiceBinary);
    const showInvoiceModal = this.handleInvoiceViewerModal;
    const showConfirmModal = this.handleConfirmModal;
    const runPreTransaction = () => {
      if (that.props.isRunTransModal && that.props.handleRunPreTransaction) {
        that.props.handleRunPreTransaction();
      }
    };
    const updatePreForm = (formValue) => {
      if (isRunTransModal) {
        debugger;
        updateBaseForm(formValue);
        debugger;
        runTransModalClose();
      } else {
        this.setState({ formValue });
        if (this.props.isRefModelForm) {
          // handleUpdateRefData(formValue);
          that.handleUpdateRefData(formValue, this.form, _dynamicSchemaModel);
        }
      }
    };

    const closeModal = () => {
      if (isRunTransModal) {
        runTransModalClose();
      } else {
        that.setState({
          isTransModal: false,
        });
      }
    };

    let { formData, model } = this.transactionPrepareFunc();
    const updateData = that.UpdateFormValue;

    let result = null;
    if (
      _selectedService.hasOwnProperty("preScript") &&
      _selectedService.preScript
    ) {
      try {
        if (_selectedService.preScript.includes("await ")) {
          result = eval(
            "(async function() => { " + _selectedService.preScript + "})()"
          );
        } else {
          result = eval("(function() {" + _selectedService.preScript + "}())");
        }
      } catch (error) {
        debugger;
      }
    }
    /*
    preScript(pin): "alert("pre")"
postScript(pin): "alert("post")"
    */

    try {
      debugger;
      if (result) {
        return result;
      }
      return data;
    } catch (error) {
      return data;
    }
  };
  prepareGenerateSchema = () => {
    return null;
  };
  ToastMessage = (msg, type) => {
    try {
      switch (type) {
        case "success":
          Alert.success(msg);
          break;
        case "error":
          Alert.error(msg);
          break;
        case "info":
          Alert.info(msg);
          break;
        case "warning":
          Alert.warning(msg);
          break;
        default:
          Alert.info(msg);
          break;
      }
    } catch (error) {
      Alert.info(msg);
    }
  };
  SnackMessage = (msg, type) => {
    try {
      enqueueSnackbar(msg, { variant: type });
    } catch (error) {
      enqueueSnackbar(msg);
    }
  };
  postTransactionFunc = (
    status,
    response,
    responseModel,
    _selectedService,
    _sModels
  ) => {
    const { isRunTransModal, runTransModalClose, updateBaseForm } = this.props;

    const _dynamicSchemaModel = this.prepareGenerateSchema();

    const that = this;
    const runTransaction = that.runTransaction;
    const transactions = this.props.allTransList;
    const Toast = this.ToastMessage;
    const SnackToast = this.SnackMessage;
    const updatePreForm = (formValue) => {
      if (isRunTransModal) {
        debugger;
        updateBaseForm(formValue);
      } else {
        this.setState({ formValue });
        if (this.props.isRefModelForm) {
          // handleUpdateRefData(formValue);
          that.handleUpdateRefData(formValue, this.form, _dynamicSchemaModel);
        }
      }
    };
    const closeModal = () => {
      debugger;
      if (isRunTransModal) {
        debugger;
        runTransModalClose();
      } else {
        that.setState({
          isTransModal: false,
        });
      }
    };

    const easyinvoice = require("easyinvoice").default; //easyinvoice.download('myInvoice.pdf', data.InvoiceBinary);
    const showInvoiceModal = this.handleInvoiceViewerModal;
    const showConfirmModal = this.handleConfirmModal;
    let { formData, model } = this.transactionPrepareFunc();
    const updateData = that.UpdateFormValue;
    // debugger;
    if (
      _selectedService.hasOwnProperty("postScript") &&
      _selectedService.postScript
    ) {
      try {
        let result = null;

        if (_selectedService.postScript.includes("await ")) {
          result = eval(
            "(async function() => { " + _selectedService.postScript + "})()"
          );
        } else {
          result = eval("(function() {" + _selectedService.postScript + "}())");
        }

        if (
          (!_selectedService.postScript ||
            _selectedService.postScript.trim().length == 0) &&
          status
        ) {
          if (_selectedService.responseModelType === 0) {
            let _myValues = {};

            _sModels.map((x) => {
              if (response.hasOwnProperty(x.name)) {
                _myValues[x.name] = response[x.name];
              } else if (formData.hasOwnProperty(x.name)) {
                _myValues[x.name] = formData[x.name];
              } else {
              }
            });

            that.setState({
              formValue: _myValues,
              formValueUpdateTime: new Date(),
            });
          }
        }
        if (result) {
          //    return result;
        }
      } catch (error) {}
    } else {
      if (_selectedService.responseModelType === 0) {
        let _myValues = {};
        const { isRefModelForm, refModel, allTransactions } = this.props;
        const _sModels = model
          ? allTransactions.find(
              (x) =>
                x.model.id ===
                (isRefModelForm
                  ? refModel.id
                  : _selectedService.responseModelType
                  ? _selectedService.relResponseModelId
                  : _selectedService.reqModelId)
            ).model.dataTypes
          : (isRefModelForm ? refModel : _selectedService)
          ? isRefModelForm
            ? refModel.dataTypes
            : _selectedService.model
          : [];
        debugger;
        _sModels.map((x) => {
          if (response.hasOwnProperty(x.name)) {
            _myValues[x.name] = response[x.name];
          } else if (formData.hasOwnProperty(x.name)) {
            _myValues[x.name] = formData[x.name];
          } else {
          }
        });

        that.setState({
          formValue: _myValues,
          formValueUpdateTime: new Date(),
        });
      }
    }
  };

  onKeyDown = (event) => {
    if (event.key === "Enter") {
      this.handleOpenSearch();
      event.preventDefault();
    }
  };

  handleOpenSearch = () => {
    this.setState({
      isSearch: !this.state.isSearch,
    });
  };

  getNestedValue = (data, value, _data, _key) => {
    const that = this;
    const { models } = this.props;
    switch (data.contentType) {
      case 11: // User
        return `${value.firstName} ${value.lastName}`;
        break;
      case 5:
        const _lc = data.relationModel.listColumn;
        const _rv = value[data.name]
          ? value?.[data.name]?.hasOwnProperty(_lc)
            ? value[data.name][_lc]
            : value[data.name].hasOwnProperty("_id")
            ? value._id
            : typeof value[data.name] == "string"
            ? value[data.name]
            : i18n.t("DATA_CANNOT_DISPLAYED")
          : "";
        return _rv;

        break;
      default:
        return Array.isArray(value)
          ? i18n.t("CELL_TYPE_REFMODEL_LIST_SENTENCE", {
              count: value.length,
              name: data.displayName,
            })
          : typeof value == "object"
          ? value.hasOwnProperty("_id")
            ? value._id
            : i18n.t("DATA_CANNOT_DISPLAYED")
          : i18n.t("DATA_CANNOT_DISPLAYED");
        break;
    }
  };

  handleSelect = (_value) => {
    debugger;
    const that = this;

    const { isSearch } = this.state;
    const {
      data,
      isObject,
      runTransaction,
      _extra,
      allServices,
      allTransactions,
    } = this.props;
    const { formRef, formValue, formOnChange } = this.props;
    const _key = data.name;
    let _obj = Object.assign({}, formValue);

    const _cData = this.props.data;
    const _cDataRefRel =
      _cData.contentType == "10" ? _cData.refModel : _cData.relationModel;

    const _cValue = _value.hasOwnProperty(_cDataRefRel.listColumn)
      ? _value[_cDataRefRel.listColumn]
      : _value._id;

    const _cValueIsObj = isObject ? _value : _cValue;
    _obj[_key] = _cDataRefRel.relationType ? [_value] : _value; //_cValueIsObj;

    //Alert.warning(JSON.stringify(_value))
    debugger;
    if (_extra && _extra.events.hasOwnProperty("onSelectSingle")) {
      const _cDetail = _extra.events.onSelectSingle;
      const _sTrans = allServices.find((x) => x.id == _cDetail.transId);
      if (_sTrans) {
        let _fv = JSON.parse(JSON.stringify(isSearch ? _obj : _obj));
        _fv["MakeStandartTransformation"] = true;

        switch (_cDetail.runType) {
          case "modal":
            this.handleRunTransaction(_sTrans, _value, _cDetail);
            break;
          case "current":
            runTransaction(_sTrans, _value);
            break;
          default:
            runTransaction(_sTrans, _fv);
            break;
        }
      }
    } else if (
      _extra &&
      _extra.advanced.hasOwnProperty("UpdateDeleteRow") &&
      _extra.advanced.UpdateDeleteRow
    ) {
      let __cValue = Object.assign({}, _value);
      this.setState({ selectedItem: __cValue, isDetailOperations: true });
    } else {
      debugger;
      formOnChange(isSearch ? _obj : _obj);
      //Burası değişecek büyük ihtimal görünür field seçilebilir.

      if (typeof _cValue == "object") {
        switch (data.contentType) {
          case 5:
            const _relModelId = data.relationModel.relModel.id;
            const _listColumn = data.relationModel.listColumn;

            const _model = allTransactions.find(
              (x) => x.model.id == _relModelId
            )?.model;
            const _data = _model.dataTypes.find((x) => x.name == _listColumn);

            const _returnValue = this.getNestedValue(
              _data,
              _cValue,
              _obj,
              _key
            );

            this.setState({ text: _returnValue });

            break;

          default:
            this.setState({
              text: _cValue.hasOwnProperty("_id")
                ? _cValue._id
                : "Veri görüntülenemiyor",
            });
            break;
        }
      } else {
        this.setState({ text: _cValue });
      }
    }

    /* setTimeout(() => {
            formRef().check()
        }, 200);*/
    //debugger
  };

  setAdvancedDefaults = () => {
    const item = this.props.data;

    let _inputs = {};
    item.advanced.forEach((x) => {
      try {
        let _v = null;
        switch (x.currentContentType) {
          case 3:
            _v = parseFloat(x.defaultValue);
            break;

          case 7:
            _v = x.defaultValue.toLowerCase() == "true";
            break;

          default:
            break;
        }
        _inputs[x.name] = _v;
      } catch (error) {}
    });

    let _events = {};
    item.events.forEach((x) => {
      //debugger;
      if (x.defaultValue) {
        _events[x.name] = JSON.parse(x.defaultValue);
      }
    });
    /*
    this.setState({
      cInputs: _inputs,
      advanceFormValue: _inputs,
      cEvents: _events,
    });
    */
    return {
      advanced: _inputs,
      events: _events,
    };
  };
  handleOperation = () => {
    // this.handleReadData();
    this.handleIsDetailOperations();
  };
  render() {
    const {
      transaction,
      readOnly,
      data,
      models,
      loading,
      isObject,
      selectedService,
      formValue,
      allServices,
      allTransactions,
    } = this.props;
    const _extra = this.setAdvancedDefaults();
    const _extraSearchable = _extra.advanced["HideSearchPanel"];

    return (
      <Translation>
        {(t) => (
          <>
            {
              //data.relationModel.relModel.id
              data.relationModel && data.relationModel.relationType === 1 ? (
                <DynamicTable
                  mantar={data}
                  isSearchable={!_extraSearchable}
                  formValue={formValue}
                  data={
                    formValue.hasOwnProperty(data.name)
                      ? formValue[data.name]
                      : []
                  }
                  //data={[]}
                  handleSelect={this.handleSelect}
                  dataModelOrj={
                    allServices &&
                    allServices.length > 0 &&
                    allServices.find(
                      (x) => x.relBaseModelId === data.relationModel.relModel.id
                    ).model
                  }
                  dataModel={
                    allServices &&
                    allServices.length > 0 &&
                    allServices
                      .find(
                        (x) =>
                          x.relBaseModelId === data.relationModel.relModel.id
                      )
                      .model.filter((x) => !x.privateField)
                  }
                />
              ) : (
                <div className={"refFormControl"}>
                  <InputGroup style={{}} disabled={readOnly}>
                    <Input
                      value={this.state.text}
                      onChange={(value) => {
                        this.setState({ text: value });
                      }}
                      onKeyDown={this.onKeyDown.bind(this)}
                      disabled={
                        (selectedService &&
                          selectedService.functionName === "delete") ||
                        readOnly
                      }
                    />
                    <InputGroup.Button
                      onClick={() => {
                        if (
                          (selectedService &&
                            selectedService.functionName === "delete") ||
                          readOnly
                        ) {
                          return;
                        }
                        this.handleOpenSearch();
                      }}
                      disabled={
                        (selectedService &&
                          selectedService.functionName === "delete") ||
                        readOnly
                      }
                    >
                      <Icon icon="more" />
                    </InputGroup.Button>
                  </InputGroup>

                  <SearchObjectModal
                    isObject={isObject}
                    handleSelect={this.handleSelect}
                    search={this.state.text}
                    data={data}
                    defaultValue={formValue[data.name]}
                    formValue={this.props.formValue}
                    _extra={_extra}
                    isActive={this.state.isSearch}
                    handleToggle={this.handleOpenSearch}
                  />
                  <FormControl
                    ref={this.textInput}
                    name={data.name.toLowerCase()}
                    //id={data.name.toLowerCase()}
                    accepter={Input}
                    style={{
                      maxWidth: "20px",
                      maxHeight: "1px",
                      overflow: "hidden",
                      opacity: "0",
                      position: "absolute",
                    }}
                    className={"picker-full custom-fade-in"}
                    disabled={data.privateField || loading}
                  />
                </div>
              )
            }
            {this.state.isTransModal && (
              <RunTransactionModal
                baseData={formValue}
                refTransaction={this.state.refTransaction}
                preRefTransaction={this.props.refTransaction}
                isActive={this.state.isTransModal}
                handleToggle={() => {
                  this.setState({ isTransModal: false });
                }}
                refFormValue={this.state.refFormValue}
                updateBaseForm={this.props.formOnChange}
              />
            )}

            {this.state.isInvoiceViewer && (
              <EasyInvoiceViewer
                isActive={this.state.isInvoiceViewer}
                handleToggle={this.handleInvoiceViewerModal}
              />
            )}

            {this.state.isConfirmModal && (
              <ConfirmModal
                isActive={this.state.isConfirmModal}
                handleToggle={this.handleConfirmModal}
                title={this.state.confirmTitle}
              />
            )}

            {this.state.selectedItem && this.state.isDetailOperations && (
              <ListItemOperationsCustom
                refModel={
                  allTransactions.find((x) => {
                    //debugger;
                    return x.model.id === data.relationModel.relModel.id;
                  }).model
                }
                handleUpdate={this.handleOperation}
                selectedItem={this.state.selectedItem}
                isActive={this.state.isDetailOperations}
                handleToggle={this.handleIsDetailOperations}
                isListDetail={false}
                isRefModelForm={true}
              />
            )}
          </>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer } = state;
  const {
    model,
    models,
    transaction,
    workspace,
    dataModels,
    selectedService,
    allServices,
    allTransList,
    allTransactions,
  } = workspaceReducer;

  return {
    workspace,
    transaction,
    dataModels,
    model,
    models,
    selectedService,
    allServices,
    allTransList,
    allTransactions,
  };
};

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(FormRefElement);

/*
      <FormControl
      fluid
      accepter={Form}
      defaultValue={(data.typeDetail === 6 || data.typeDetail === 7) ? 0.01 : 0} step={(data.typeDetail === 6 || data.typeDetail === 7) ? 0.01 : 1}
      disabled={(data.privateField || loading)}
      name={data.name.toLowerCase()} className={"custom-fade-in"} >
      <FormGroup>
          <ControlLabel>Username</ControlLabel>
          <FormControl name="name" />
      </FormGroup>
      <FormGroup>
          <ControlLabel>Email</ControlLabel>
          <FormControl name="email" type="email" />
      </FormGroup>
  </FormControl>
    
    */
