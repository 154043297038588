import i18n from "i18n";

export function ResponseStatusCode(code) {
  let response = "";
  switch (code) {
    case 9:
      response = i18n.t("ERROR_CODE_9");
      break;
    case 10:
      response = i18n.t("ERROR_CODE_10");
      break;
    case 11:
      response = i18n.t("ERROR_CODE_11");
      break;
    case 45:
      response = i18n.t("ERROR_CODE_45");
      break;
    case 46:
      response = i18n.t("ERROR_CODE_46");
      break;
    case 47:
      response = i18n.t("ERROR_CODE_47");
      break;
    case 48:
      response = i18n.t("ERROR_CODE_48");
      break;
    case 49:
      response = i18n.t("ERROR_CODE_49");
      break;
    case 50:
      response = i18n.t("ERROR_CODE_50");
      break;
    case 51:
      response = i18n.t("ERROR_CODE_51");
      break;
    case 52:
      response = i18n.t("ERROR_CODE_52");
      break;
    case 53:
      response = i18n.t("ERROR_CODE_53");
      break;
    case 54:
      response = i18n.t("ERROR_CODE_54");
      break;


      case 116:
      response = i18n.t("ERROR_CODE_116");
      break;
      case 179:
        response = i18n.t("ERROR_CODE_179");
        break;

        case 185:
          response = i18n.t("ERROR_CODE_185");
          break;
        case 186:
          response = i18n.t("ERROR_CODE_186");
          break;
      
    default:
      response = i18n.t("somethingWrong");
      break;
  }
  return response;
}