import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";

import i18n from "i18n";
import { Modal, Container, Row, Col } from "reactstrap";

import FormGenerator from "components/Transaction/FormGenerator";
import { Loading, Button, Alert } from "rsuite";
import { FormGroup, ButtonToolbar } from "rsuite";

class OperationRowDataDT extends Component {
  constructor(props) {
    super(props);
    this.triggerRefRelationModel = React.createRef();
    this.triggerRefColums = React.createRef();
    this.triggerRelationType = React.createRef();

    this.state = {
      formValue: {},
      formError: {},
      loading: false,
      refForm: null,
      refSchemaModel: null,
    };
  }

  handleSubmit = (e) => {
    const that = this;
    debugger;
    const { formValue, refForm, refSchemaModel } = this.state;
    this.props.handleCreate(
      formValue,
      refForm,
      refSchemaModel,
      this.handleCloseModal
    );
    // this.handleCloseModal();
  };

  handleCloseModal = () => {
    const { handleToggle } = this.props;
    this.setState(
      {
        formValue: {
          //  priority: 0,
        },
        formError: {},
        loading: false,
      },
      () => {
        handleToggle();
      }
    );
  };

  handleUpdateData = (formValue, refForm, refSchemaModel) => {
    const that = this;

    debugger;
    this.setState({
      formValue,
      refForm,
      refSchemaModel,
    });
  };

  handleGetForm = (_call) => {};

  render() {
    const {
      title,
      isActive,
      data,
      refModel,
      currentValues,
      readOnly,
      operationColor,
      operationName,
      dataModel,
      isCreateRow,
    } = this.props;
    const { loading } = this.state;

    const _hasList = dataModel.find(
      (x) =>
        (x.contentType == 5 && x.relationModel.relationType) ||
        x.contentType == 10
    );
    return (
      <Translation>
        {(t) => (
          <Modal
            className="modal-dialog-centered"
            isOpen={isActive}
            size={_hasList ? "lg" : "md"}
            //toggle={this.handleCloseModal}
            scrollable={true}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="CheckProjectModal">
                {title}
              </h5>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => this.handleCloseModal()}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body" style={{ padding: 0 }}>
              <Container>
                <Row>
                  <Col style={{ marginBottom: "15px", padding: "0px 1.25rem" }}>
                    <FormGenerator
                      isRefModelForm={true}
                      isListDetail={true}
                      readOnly={readOnly}
                      currentValues={currentValues}
                      currentValuesOrj={currentValues}
                      refModel={refModel}
                      handleUpdateRefData={this.handleUpdateData}
                      handleGetForm={this.handleGetForm}
                      isCreateRow={isCreateRow}
                    />
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="modal-footer">
              <FormGroup>
                <ButtonToolbar className={"creator-toobar"}>
                  <Button
                    disabled={loading}
                    loading={loading}
                    appearance="primary"
                    color={operationColor}
                    onClick={this.handleSubmit}
                  >
                    {operationName}
                  </Button>
                  <Button
                    disabled={loading}
                    appearance="default"
                    onClick={this.handleCloseModal}
                  >
                    {t("BUTTON_CANCEL")}
                  </Button>
                </ButtonToolbar>
              </FormGroup>
            </div>
          </Modal>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer } = state;
  //const { workspaces, workspace, selectedService, services, modules, module, transactions } = workspaceReducer;
  const { models, model } = workspaceReducer;
  return {
    //workspaces,
    //workspace,
    //modules,
    //module,
    //selectedService,
    //services,
    //transactions,
    models,
    model,
  };
};

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(OperationRowDataDT);
