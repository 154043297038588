export const isoLangs = [
    {"value":"ab","label":"Abkhaz - аҧсуа"},
    {"value":"aa","label":"Afar - Afaraf"},
    {"value":"af","label":"Afrikaans - Afrikaans"},
    {"value":"ak","label":"Akan - Akan"},
    {"value":"sq","label":"Albanian - Shqip"},
    {"value":"am","label":"Amharic - አማርኛ"},
    {"value":"ar","label":"Arabic - العربية"},
    {"value":"an","label":"Aragonese - Aragonés"},
    {"value":"hy","label":"Armenian - Հայերեն"},
    {"value":"as","label":"Assamese - অসমীয়া"},
    {"value":"av","label":"Avaric - авар мацӀ, магӀарул мацӀ"},
    {"value":"ae","label":"Avestan - avesta"},
    {"value":"ay","label":"Aymara - aymar aru"},
    {"value":"az","label":"Azerbaijani - azərbaycan dili"},
    {"value":"bm","label":"Bambara - bamanankan"},
    {"value":"ba","label":"Bashkir - башҡорт теле"},
    {"value":"eu","label":"Basque - euskara, euskera"},
    {"value":"be","label":"Belarusian - Беларуская"},
    {"value":"bn","label":"Bengali - বাংলা"},
    {"value":"bh","label":"Bihari - भोजपुरी"},
    {"value":"bi","label":"Bislama - Bislama"},
    {"value":"bs","label":"Bosnian - bosanski jezik"},
    {"value":"br","label":"Breton - brezhoneg"},
    {"value":"bg","label":"Bulgarian - български език"},
    {"value":"my","label":"Burmese - ဗမာစာ"},
    {"value":"ca","label":"Catalan; Valencian - Català"},
    {"value":"ch","label":"Chamorro - Chamoru"},
    {"value":"ce","label":"Chechen - нохчийн мотт"},
    {"value":"ny","label":"Chichewa; Chewa; Nyanja - chiCheŵa, chinyanja"},
    {"value":"zh","label":"Chinese - 中文 (Zhōngwén), 汉语, 漢語"},
    {"value":"cv","label":"Chuvash - чӑваш чӗлхи"},
    {"value":"kw","label":"Cornish - Kernewek"},
    {"value":"co","label":"Corsican - corsu, lingua corsa"},
    {"value":"cr","label":"Cree - ᓀᐦᐃᔭᐍᐏᐣ"},
    {"value":"hr","label":"Croatian - hrvatski"},
    {"value":"cs","label":"Czech - česky, čeština"},
    {"value":"da","label":"Danish - dansk"},
    {"value":"dv","label":"Divehi; Dhivehi; Maldivian; - ދިވެހި"},
    {"value":"nl","label":"Dutch - Nederlands, Vlaams"},
    {"value":"en","label":"English - English"},
    {"value":"eo","label":"Esperanto - Esperanto"},
    {"value":"et","label":"Estonian - eesti, eesti keel"},
    {"value":"ee","label":"Ewe - Eʋegbe"},
    {"value":"fo","label":"Faroese - føroyskt"},
    {"value":"fj","label":"Fijian - vosa Vakaviti"},
    {"value":"fi","label":"Finnish - suomi, suomen kieli"},
    {"value":"fr","label":"French - français, langue française"},
    {"value":"ff","label":"Fula; Fulah; Pulaar; Pular - Fulfulde, Pulaar, Pular"},
    {"value":"gl","label":"Galician - Galego"},
    {"value":"ka","label":"Georgian - ქართული"},
    {"value":"de","label":"German - Deutsch"},
    {"value":"el","label":"Greek, Modern - Ελληνικά"},
    {"value":"gn","label":"Guaraní - Avañeẽ"},
    {"value":"gu","label":"Gujarati - ગુજરાતી"},
    {"value":"ht","label":"Haitian; Haitian Creole - Kreyòl ayisyen"},
    {"value":"ha","label":"Hausa - Hausa, هَوُسَ"},
    {"value":"he","label":"Hebrew (modern) - עברית"},
    {"value":"hz","label":"Herero - Otjiherero"},
    {"value":"hi","label":"Hindi - हिन्दी, हिंदी"},
    {"value":"ho","label":"Hiri Motu - Hiri Motu"},
    {"value":"hu","label":"Hungarian - Magyar"},
    {"value":"ia","label":"Interlingua - Interlingua"},
    {"value":"id","label":"Indonesian - Bahasa Indonesia"},
    {"value":"ie","label":"Interlingue - Originally called Occidental; then Interlingue after WWII"},
    {"value":"ga","label":"Irish - Gaeilge"},
    {"value":"ig","label":"Igbo - Asụsụ Igbo"},
    {"value":"ik","label":"Inupiaq - Iñupiaq, Iñupiatun"},
    {"value":"io","label":"Ido - Ido"},
    {"value":"is","label":"Icelandic - Íslenska"},
    {"value":"it","label":"Italian - Italiano"},
    {"value":"iu","label":"Inuktitut - ᐃᓄᒃᑎᑐᑦ"},
    {"value":"ja","label":"Japanese - 日本語 (にほんご／にっぽんご)"},
    {"value":"jv","label":"Javanese - basa Jawa"},
    {"value":"kl","label":"Kalaallisut, Greenlandic - kalaallisut, kalaallit oqaasii"},
    {"value":"kn","label":"Kannada - ಕನ್ನಡ"},
    {"value":"kr","label":"Kanuri - Kanuri"},
    {"value":"ks","label":"Kashmiri - कश्मीरी, كشميري‎"},
    {"value":"kk","label":"Kazakh - Қазақ тілі"},
    {"value":"km","label":"Khmer - ភាសាខ្មែរ"},
    {"value":"ki","label":"Kikuyu, Gikuyu - Gĩkũyũ"},
    {"value":"rw","label":"Kinyarwanda - Ikinyarwanda"},
    {"value":"ky","label":"Kirghiz, Kyrgyz - кыргыз тили"},
    {"value":"kv","label":"Komi - коми кыв"},
    {"value":"kg","label":"Kongo - KiKongo"},
    {"value":"ko","label":"Korean - 한국어 (韓國語), 조선말 (朝鮮語)"},
    {"value":"ku","label":"Kurdish - Kurdî, كوردی‎"},
    {"value":"kj","label":"Kwanyama, Kuanyama - Kuanyama"},
    {"value":"la","label":"Latin - latine, lingua latina"},
    {"value":"lb","label":"Luxembourgish, Letzeburgesch - Lëtzebuergesch"},
    {"value":"lg","label":"Luganda - Luganda"},
    {"value":"li","label":"Limburgish, Limburgan, Limburger - Limburgs"},
    {"value":"ln","label":"Lingala - Lingála"},
    {"value":"lo","label":"Lao - ພາສາລາວ"},
    {"value":"lt","label":"Lithuanian - lietuvių kalba"},
    {"value":"lu","label":"Luba-Katanga - "},
    {"value":"lv","label":"Latvian - latviešu valoda"},
    {"value":"gv","label":"Manx - Gaelg, Gailck"},
    {"value":"mk","label":"Macedonian - македонски јазик"},
    {"value":"mg","label":"Malagasy - Malagasy fiteny"},
    {"value":"ms","label":"Malay - bahasa Melayu, بهاس ملايو‎"},
    {"value":"ml","label":"Malayalam - മലയാളം"},
    {"value":"mt","label":"Maltese - Malti"},
    {"value":"mi","label":"Māori - te reo Māori"},
    {"value":"mr","label":"Marathi (Marāṭhī) - मराठी"},
    {"value":"mh","label":"Marshallese - Kajin M̧ajeļ"},
    {"value":"mn","label":"Mongolian - монгол"},
    {"value":"na","label":"Nauru - Ekakairũ Naoero"},
    {"value":"nv","label":"Navajo, Navaho - Diné bizaad, Dinékʼehǰí"},
    {"value":"nb","label":"Norwegian Bokmål - Norsk bokmål"},
    {"value":"nd","label":"North Ndebele - isiNdebele"},
    {"value":"ne","label":"Nepali - नेपाली"},
    {"value":"ng","label":"Ndonga - Owambo"},
    {"value":"nn","label":"Norwegian Nynorsk - Norsk nynorsk"},
    {"value":"no","label":"Norwegian - Norsk"},
    {"value":"ii","label":"Nuosu - ꆈꌠ꒿ Nuosuhxop"},
    {"value":"nr","label":"South Ndebele - isiNdebele"},
    {"value":"oc","label":"Occitan - Occitan"},
    {"value":"oj","label":"Ojibwe, Ojibwa - ᐊᓂᔑᓈᐯᒧᐎᓐ"},
    {"value":"cu","label":"Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic - ѩзыкъ словѣньскъ"},
    {"value":"om","label":"Oromo - Afaan Oromoo"},
    {"value":"or","label":"Oriya - ଓଡ଼ିଆ"},
    {"value":"os","label":"Ossetian, Ossetic - ирон æвзаг"},
    {"value":"pa","label":"Panjabi, Punjabi - ਪੰਜਾਬੀ, پنجابی‎"},
    {"value":"pi","label":"Pāli - पाऴि"},
    {"value":"fa","label":"Persian - فارسی"},
    {"value":"pl","label":"Polish - polski"},
    {"value":"ps","label":"Pashto, Pushto - پښتو"},
    {"value":"pt","label":"Portuguese - Português"},
    {"value":"qu","label":"Quechua - Runa Simi, Kichwa"},
    {"value":"rm","label":"Romansh - rumantsch grischun"},
    {"value":"rn","label":"Kirundi - kiRundi"},
    {"value":"ro","label":"Romanian, Moldavian, Moldovan - română"},
    {"value":"ru","label":"Russian - русский язык"},
    {"value":"sa","label":"Sanskrit (Saṁskṛta) - संस्कृतम्"},
    {"value":"sc","label":"Sardinian - sardu"},
    {"value":"sd","label":"Sindhi - सिन्धी, سنڌي، سندھی‎"},
    {"value":"se","label":"Northern Sami - Davvisámegiella"},
    {"value":"sm","label":"Samoan - gagana faa Samoa"},
    {"value":"sg","label":"Sango - yângâ tî sängö"},
    {"value":"sr","label":"Serbian - српски језик"},
    {"value":"gd","label":"Scottish Gaelic; Gaelic - Gàidhlig"},
    {"value":"sn","label":"Shona - chiShona"},
    {"value":"si","label":"Sinhala, Sinhalese - සිංහල"},
    {"value":"sk","label":"Slovak - slovenčina"},
    {"value":"sl","label":"Slovene - slovenščina"},
    {"value":"so","label":"Somali - Soomaaliga, af Soomaali"},
    {"value":"st","label":"Southern Sotho - Sesotho"},
    {"value":"es","label":"Spanish; Castilian - español, castellano"},
    {"value":"su","label":"Sundanese - Basa Sunda"},
    {"value":"sw","label":"Swahili - Kiswahili"},
    {"value":"ss","label":"Swati - SiSwati"},
    {"value":"sv","label":"Swedish - svenska"},
    {"value":"ta","label":"Tamil - தமிழ்"},
    {"value":"te","label":"Telugu - తెలుగు"},
    {"value":"tg","label":"Tajik - тоҷикӣ, toğikī, تاجیکی‎"},
    {"value":"th","label":"Thai - ไทย"},
    {"value":"ti","label":"Tigrinya - ትግርኛ"},
    {"value":"bo","label":"Tibetan Standard, Tibetan, Central - བོད་ཡིག"},
    {"value":"tk","label":"Turkmen - Türkmen, Түркмен"},
    {"value":"tl","label":"Tagalog - Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔"},
    {"value":"tn","label":"Tswana - Setswana"},
    {"value":"to","label":"Tonga (Tonga Islands) - faka Tonga"},
    {"value":"tr","label":"Turkish - Türkçe"},
    {"value":"ts","label":"Tsonga - Xitsonga"},
    {"value":"tt","label":"Tatar - татарча, tatarça, تاتارچا‎"},
    {"value":"tw","label":"Twi - Twi"},
    {"value":"ty","label":"Tahitian - Reo Tahiti"},
    {"value":"ug","label":"Uighur, Uyghur - Uyƣurqə, ئۇيغۇرچە‎"},
    {"value":"uk","label":"Ukrainian - українська"},
    {"value":"ur","label":"Urdu - اردو"},
    {"value":"uz","label":"Uzbek - zbek, Ўзбек, أۇزبېك‎"},
    {"value":"ve","label":"Venda - Tshivenḓa"},
    {"value":"vi","label":"Vietnamese - Tiếng Việt"},
    {"value":"vo","label":"Volapük - Volapük"},
    {"value":"wa","label":"Walloon - Walon"},
    {"value":"cy","label":"Welsh - Cymraeg"},
    {"value":"wo","label":"Wolof - Wollof"},
    {"value":"fy","label":"Western Frisian - Frysk"},
    {"value":"xh","label":"Xhosa - isiXhosa"},
    {"value":"yi","label":"Yiddish - ייִדיש"},
    {"value":"yo","label":"Yoruba - Yorùbá"},
    {"value":"za","label":"Zhuang, Chuang - Saɯ cueŋƅ, Saw cuengh"}
  ]