import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, Button, Alert, Icon, Dropdown, PanelGroup } from "rsuite";
import { formatQuery } from "react-querybuilder";
import Emitter from "_actions/emitter";

import { Translation } from "react-i18next";
import i18n from "i18n";
import { ModelsService } from "_services";

import CustomQueryBuilder from "components/Custom/SearchFilter/CustomQueryBuilder";

class UpdateSearchFilterModal extends Component {
  constructor(props) {
    super(props);
    debugger;
    this.state = {
      data: null,
      query: { combinator: "and", rules: [] },
      queryData: "",
    };
  }

  updateQuery = (data) => {
    this.setState({
      query: data,
    });
  };

  SaveQuery = () => {
    const { updateModules, module, updateStore, onRefresh } = this.props;
    const that = this;
    that.setState({ isLoading: true });

    const { workspace, selectedService, handleToggle } = this.props;
    const { query } = this.state;

    ModelsService.UPDATE_FILTER(
      selectedService.relBaseModelId,
      selectedService.relModuleId,
      workspace.relWorkspaceId,
      query,
      formatQuery(query, "mongodb")
    )
      .then((data) => {
        if (data.operationResult) {
          debugger;
          Alert.success(i18n.t("SUCCESS_SAVED"));
          Emitter.emit("CHANGE_MODULE");
          handleToggle();
        }
        that.setState({ isLoading: false });
      })
      .catch((err) => {
        that.setState({ isLoading: false });
        debugger;
      });
  };

  getObjectFields = (data, ref) => {
    const { allTransactions } = this.props;
    const that = this;
    let tableModel = [];
    let refService = null;
    switch (data.contentType) {
      case 5:
        refService = allTransactions.find(
          (x) => x.model.id === data.relationModel.relModel.id
        )?.model;
        tableModel = refService ? [...refService.dataTypes] : [];
   
        break;
      case 10:
        refService = allTransactions.find(
          (x) => x.model.id === data.refModel.id
        )?.model; //allServices.find((x) => x.relBaseModelId === data.refModel.id);
        tableModel = refService ? [...refService.dataTypes] : [];
     
        break;

      default:
        break;
    }

    let allFields = [];
    tableModel.forEach((e) => {
      let _obj = {
        label: (ref ? ref.name : data.name) + "." + e.name,
        value: (ref ? ref.name : data.name) + "." + e.name,
        name: (ref ? ref.name : data.name) + "." + e.name,
        refData: e,
      };
      switch (e.contentType) {
        case 1:
          _obj["inputType"] = "text";
          allFields.push(_obj);
          break;
        case 2:
          _obj["inputType"] = "textarea";
          allFields.push(_obj);
          break;
        case 3:
          _obj["inputType"] = "number";
          allFields.push(_obj);
          break;
        case 4:
          _obj["inputType"] = "date";
          allFields.push(_obj);
          break;
        case 7:
          _obj["valueEditorType"] = "checkbox";
          allFields.push(_obj);
          break;
        case 5:
          _obj["inputType"] = "text";
            // _obj.label = ref.name + "." + e.name;
        //  _obj.value = ref.name + "." + e.name;
         // _obj.name = ref.name + "." + e.name;
debugger
          allFields.push(_obj);
          const c5 = this.getObjectFields(e, _obj);

          allFields = [...allFields, ...c5];
          break;
        case 10:
          _obj["inputType"] = "text";
         // _obj.label = ref.name + "." + e.name;
        //  _obj.value = ref.name + "." + e.name;
         // _obj.name = ref.name + "." + e.name;
          debugger
          allFields.push(_obj);
          const c10 = this.getObjectFields(e, _obj);

          allFields = [...allFields, ...c10];
          break;
        case 14:
          _obj = null;
          break;
        default:
          _obj = {
            label: (ref ? ref.name : data.name) + "." + e.name,
            value: (ref ? ref.name : data.name) + "." + e.name,
            name: (ref ? ref.name : data.name) + "." + e.name,
            refData: e,
          };
          allFields.push(_obj);
          break;
      }
    });

    return allFields;
  };

  generateModelObject = () => {
    const { tableModel } = this.props;
    debugger;
    let allFields = [];
    tableModel.forEach((e) => {
      let _obj = {
        label: e.displayName,
        value: e.name,
        name: e.name,
        refData: e,
      };

      switch (e.contentType) {
        case 1:
          _obj["inputType"] = "text";
          allFields.push(_obj);
          break;
        case 2:
          _obj["inputType"] = "textarea";
          allFields.push(_obj);
          break;
        case 3:
          _obj["inputType"] = "number";
          allFields.push(_obj);
          break;
        case 4:
          _obj["inputType"] = "date";
          allFields.push(_obj);
          break;
        case 7:
          _obj["valueEditorType"] = "checkbox";
          allFields.push(_obj);
          break;
        case 5:
          _obj["inputType"] = "text";
          _obj.label = e.name;
          allFields.push(_obj);

          const c5 = this.getObjectFields(e, _obj);

          allFields = [...allFields, ...c5];

          break;
        case 10:
          _obj["inputType"] = "text";
          _obj.label = e.name;
          allFields.push(_obj);
          const c10 = this.getObjectFields(e, _obj);

          allFields = [...allFields, ...c10];

          break;
        case 14:
          _obj = null;
          break;
        default:
          _obj = {
            label: e.displayName,
            value: e.name,
            name: e.name,
            refData: e,
          };
          allFields.push(_obj);
          break;
      }
    });
    debugger;
    this.setState({
      data: allFields,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const that = this;
    const { data } = this.state;
    const { isActive, selectedService } = this.props;
    if (prevProps.isActive !== isActive && isActive && data == null) {
      this.generateModelObject();
    }
    if (
      prevProps.isActive !== isActive &&
      isActive &&
      selectedService &&
      selectedService.hasOwnProperty("baseModelData")
    ) {
      this.setState({
        query: selectedService.baseModelData.query
          ? JSON.parse(selectedService.baseModelData.query)
          : { combinator: "and", rules: [] },
        queryData: selectedService.baseModelData.query
          ? formatQuery(
              JSON.parse(selectedService.baseModelData.query),
              "mongodb"
            )
          : "",
      });
    }
  }

  render() {
    const { isActive, handleToggle, tableModel } = this.props;
    const { data, queryData, query, fields } = this.state;

    return (
      <Translation>
        {(t) => (
          <>
            <Modal full overflow={true} show={isActive} onHide={handleToggle}>
              <Modal.Header>
                <Modal.Title>{t("TITLE_MODAL_SEARCH_FILTER")}</Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ height: "100vh" }}>
                {data && (
                  <CustomQueryBuilder
                    fields={data}
                    query={this.state.query}
                    updateQuery={this.updateQuery}
                  />
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => {
                    const that = this;
                    const { data, queryData, query, fields } = this.state;

                    debugger;
                    this.SaveQuery();
                  }}
                  appearance="primary"
                  color="green"
                >
                  {t("BUTTON_SAVE")}
                </Button>
                <Button onClick={handleToggle} appearance="subtle">
                  {t("BUTTON_CANCEL")}
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer } = state;
  const {
    model,
    models,
    transaction,
    transactions,
    workspaces,
    workspace,
    dataModels,
    selectedService,
    services,
    allServices,
    allTransactions,
  } = workspaceReducer;

  return {
    workspace,
    transaction,
    transactions,
    workspaces,
    dataModels,
    models,
    model,
    selectedService,
    services,
    allServices,
    allTransactions,
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateSearchFilterModal);

/*

 <div style={{ paddingRight: "6px" }}>
                  <Dropdown
                    className="search-single-dropdown"
                    title={t("BUTTON_ADD")}
                    toggleComponentClass={Button}
                    appearance="default"
                  >
                    <Dropdown.Item
                      onSelect={() =>
                        this.addQueryData(
                          { _time: new Date() + "", type: "single" },
                          0
                        )
                      }
                    >
                      {t("SEARCH_FIELD_FIELD")}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onSelect={() =>
                        this.addQueryData(
                          { _time: new Date() + "", type: "group" },
                          0
                        )
                      }
                    >
                      {t("SEARCH_FIELD_GROUP")}
                    </Dropdown.Item>
                  </Dropdown>

                  <div>
                     } 
                    {queryData &&
                        queryData.map((x, k) => {
                          if (x.type == "single")
                            return (
                              <SingleField
                                addQueryData={this.addQueryData}
                                updateQueryData={this.updateQueryData}
                                key={x._time}
                                tableModel={data}
                                refdata={{
                                  key: k,
                                  data: x,
                                  refData: null,
                                }}
                              />
                            );
                          else
                            return (
                              <GroupField
                              addQueryData={this.addQueryData}
                                key={k._time}
                                tableModel={data}
                                refdata={{
                                  key: k,
                                  data: x,
                                  refData: null,
                                }}
                              />
                            );
                        })}
                      
                    </div>
                  </div>

*/
