import {
    //ServiceURLs,
    ErpRolesEndpoints,
    GetBaseERPURL,
    MakeReqestAndGetResponse,
    GetUserLang,
  } from "./index";
  import { HTTPOperationType } from "./httpOperations";
  
  export const erpRolesService = {
    GET_SCOPES,
    GET_ROLES,
    CREATE_ROLE,
    UPDATE_ROLE
 
  };
 

  function GET_SCOPES(_RelWorkSpaceId,_RelERP_WORK_PLACE) {
    var js = {
      RelWorkSpaceId:_RelWorkSpaceId
    };

    return MakeReqestAndGetResponse(
      GetBaseERPURL() + ErpRolesEndpoints.GET_SCOPES,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }

  function GET_ROLES(IsOrderByDesc,LastIndexId,Count,SearchTerm,RelWorkSpaceId) {
    var js = {
      SearchTerm: SearchTerm,
      Count : Count,
      LastIndexId : LastIndexId,
      IsOrderByDesc:IsOrderByDesc,
      DynamicObj:{RelWorkSpaceId:RelWorkSpaceId}
    };

    return MakeReqestAndGetResponse(
      GetBaseERPURL() + ErpRolesEndpoints.GET_ROLES,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }

  function CREATE_ROLE(_RelWorkSpaceId,_RoleName,_Roles) {
    var js = {
      RelWorkSpaceId:_RelWorkSpaceId,
      RoleName:_RoleName,
      Roles:_Roles
    };

    return MakeReqestAndGetResponse(
      GetBaseERPURL() + ErpRolesEndpoints.CREATE_ROLE,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }

  function UPDATE_ROLE(_RelWorkSpaceId,_RoleName,_Roles,_id) {
    var js = {
      RelWorkSpaceId:_RelWorkSpaceId,
      RoleName:_RoleName,
      Roles:_Roles,
      id:_id
    };

    return MakeReqestAndGetResponse(
      GetBaseERPURL() + ErpRolesEndpoints.UPDATE_ROLE,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }