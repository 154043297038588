import {
    //ServiceURLs,
    InputDefaultpoints,
    GetBaseERPURL,
    MakeReqestAndGetResponse,
    GetUserLang,
  } from "./index";
  import { HTTPOperationType } from "./httpOperations";
  
  export const inputDefaultsService = {
    GET_SELECTED_EVENTS,
    GET_SELECTED_INPUTS,
  };
 
/* ! 

  public enum ContentTypes
    {
       
        Text = 1,
        RichText, 2
        Number, 3
        Date, 4
        Relations, 5
        Enum, 6
        Boolean, 7
        Identity, 8
        Image, 9
        RefModel, 10
        CurrentUser 11
        ProcessingTime 12


    }

*/

 
  function GET_SELECTED_EVENTS(refContentType) {
   

    return MakeReqestAndGetResponse(
      GetBaseERPURL() + InputDefaultpoints.GET_SELECTED_EVENTS+refContentType,
      HTTPOperationType.get,
     
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }
  function GET_SELECTED_INPUTS(refContentType) {
   

    return MakeReqestAndGetResponse(
      GetBaseERPURL() + InputDefaultpoints.GET_SELECTED_INPUTS+refContentType,
      HTTPOperationType.get,
     
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }
 