import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import moment from "moment";
import Emitter from "_actions/emitter";
import i18n from "i18n";
import { ResponseStatusCode } from "_helpers";
import {
  erpRolesService,
  ModulesService,
  erpRolesAndUserRelationsService,
  WorkspaceUserService,
} from "_services";
import { Icon, Alert, SelectPicker } from "rsuite";
import { Tag, TagGroup } from "rsuite";
import {
  Loader,
  Container,
  Row,
  Col,
  Placeholder,
  Button,
  IconButton,
  Cascader,
  Dropdown,
  ButtonGroup,
} from "rsuite";

import ImportDataPreviewModal from "./ImportDataPreviewModal";

import { Modal } from "reactstrap";
const { Paragraph } = Placeholder;
let LANG = i18n.t("LANG");
const WAIT_INTERVAL = 500;
const ENTER_KEY = 13;

class ImportDataModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      importedFiles: [],
      fieldRelations: {},
      jsonCascaderData: [],
      jsonCascaderHelperData: [],
      previewData: [],
      isPreview: false,
    };
  }

  resetContent = () => {
    this.setState({
      isLoading: false,
      importedFiles: [],
      fieldRelations: {},
      jsonCascaderData: [],
      jsonCascaderHelperData: [],
      previewData: [],
      isPreview: false,
    });
  };

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (this.props.isActive)
      if (prevProps.isActive != this.props.isActive && this.props.isActive) {
        this.resetContent();
      }
  }

  componentWillMount() {
    this.timer = null;
  }

  handleReadLocalFile = () => {
    const that = this;
    let input = document.getElementById("upload-json");
    /*
    input.onchange = function (params) {
      debugger
      that.handleReadJson(params);
    };*/
    input.click();
  };

  handleReadJson = (e) => {
    const that = this;
    const fileReader = new FileReader();
    let _Name = e.target.files[0].name;

    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = (e) => {
      let result = JSON.parse(e.target.result);

      let _List = Object.values(result)[0];

      if (_List.length < 1) {
        Alert.error(i18n.t("MESSAGE_IMPORT_DATA_IS_EMPTY"));
        return;
      }

      let _Fields = _List.length > 0 ? Object.keys(_List[0]) : [];
      const _obj = {
        name: _Name,
        data: _List,
        fields: _Fields,
      };
      let _if = [...this.state.importedFiles];
      _if.push(_obj);
      that.setState(
        {
          importedFiles: _if,
        },
        () => {
          that.handlePrepareJsonDataCascader();
        }
      );

      let input = document.getElementById("upload-json");
      input.value = "";
    };
  };

  handlePrepareJsonDataCascader = () => {
    const that = this;
    const { importedFiles } = this.state;

    let jsonCascaderData = [];
    let jsonCascaderHelperData = [];
    let _index = 0;
    importedFiles.forEach((jsonObj) => {
      let _o = {
        label: jsonObj.name,
        value: jsonObj.name,
        children: [],
      };
      jsonObj.fields.forEach((jsonObjField) => {
        _o.children.push({
          label: jsonObjField,
          value: jsonObj.name + "-" + jsonObjField,
        });
      });
      jsonCascaderData.push(_o);
      if (_index > 0) {
        jsonCascaderHelperData.push(_o);
      }
      _index = _index + 1;
    });

    this.setState({
      jsonCascaderData: jsonCascaderData,
      jsonCascaderHelperData: jsonCascaderHelperData,
    });
  };

  findAndReplace = (relation, index) => {
    const { importedFiles } = this.state;
    const fFile = this.findDataFile(relation.fromJsonFile);
    const tFile = this.findDataFile(relation.toJsonFile);

    let _ref = tFile.data.find(
      (ff) => ff[relation.toJsonField] == fFile.data[index][relation.fromField]
    );
    const result = _ref ? _ref[relation.newJsonField] : null;

    return result;
  };

  findDataFile = (fileName) => {
    const { importedFiles } = this.state;
    const cFile = importedFiles.find((xx) => xx.name == fileName);

    return cFile;
  };

  setFieldValue = (fieldName, fromFile, index) => {
    const result =
      fromFile.data.length >= index ? fromFile.data[index][fieldName] : null;
    return result;
  };

  findDataField = (fromFile, toFile, fromName, toName, newAddress, index) => {
    let _ref = toFile.data.find(
      (ff) => ff[toName] == fromFile.data[index][fromName]
    );
    const result = _ref ? _ref[newAddress] : null;
    return result;
  };

  fieldOperation = (data, index) => {
    let result = "";
    switch (data.operationType) {
      case 1:
        const cFile = this.findDataFile(data.relation.jsonFile);
        result = this.setFieldValue(data.relation.jsonField, cFile, index);
        break;
      case 2:
        break;
      case 3:
        result = this.findAndReplace(data.relation, index);
        break;

      case 4:
        break;
      default:
        break;
    }
    return result;
  };

  handlePreview = () => {
    const that = this;
    const { selectedService } = this.props;
    const { importedFiles, fieldRelations } = this.state;
    that.setState({ isLoading: true });
    let _obj = {};
    selectedService.model.forEach((item) => {
      _obj[item.name] = null;
    });

    let _data = [];

    importedFiles[0].data.forEach((element) => {
      let _nObj = Object.assign({}, _obj);
      for (const x in fieldRelations) {
        _nObj[x] = "";
        for (const y of fieldRelations[x]) {
          _nObj[x] = _nObj[x] + " " + this.fieldOperation(y, _data.length);
          _nObj[x] = _nObj[x].trim();
        }

        /*
          fieldRelations[x].operationType
          fieldRelations[x].relation.jsonFile
          fieldRelations[x].relation.jsonField
        */
      }
      _data.push(_nObj);
    });
    debugger;
    that.setState({
      isLoading: false,
      previewData: _data,
      isPreview: true,
    });
  };

  render() {
    const { isActive, handleToggle, selectedService } = this.props;
    const {
      isLoading,
      active,
      importedFiles,
      fieldRelations,
      jsonCascaderData,
      jsonCascaderHelperData,
    } = this.state;

    const _operations = [
      {
        id: 1,
        name: i18n.t("OPERATION_SELECT_FIELD"),
      },
      {
        id: 2,
        name: i18n.t("OPERATION_FIX_VALUE"),
      },
      {
        id: 3,
        name: i18n.t("OPERATION_FIND_AND_REPLACE"),
      },
      {
        id: 4,
        name: i18n.t("OPERATION_CONDITION"),
      },
    ];

    const if_ops = [
      {
        id: "1",
        name: i18n.t("OPERATION_1"),
        value: "=",
      },
      {
        id: "2",
        name: i18n.t("OPERATION_2"),
        value: "!=",
      },

      {
        id: "3",
        name: i18n.t("OPERATION_3"),
        value: ">",
      },
      {
        id: "4",
        name: i18n.t("OPERATION_4"),
        value: "<",
      },
      {
        id: "5",
        name: i18n.t("OPERATION_5"),
        value: ">=",
      },
      {
        id: "6",
        name: i18n.t("OPERATION_6"),
        value: "<=",
      },
      {
        id: "7",
        name: i18n.t("OPERATION_7"),
        value: "and",
      },
      {
        id: "8",
        name: i18n.t("OPERATION_8"),
        value: "or",
      },
    ];

    return (
      <Translation>
        {(t) => (
          <>
            <Modal
              className="modal-dialog-centered"
              isOpen={isActive}
              size="lg"
              scrollable={true}
            >
              <div>
                <div className="modal-header store-model-header">
                  <h5 className="modal-title" id="CheckProjectModal">
                    {t("TITLE_MODAL_IMPORT_DATA")}
                  </h5>
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    disabled={this.state.isLoading}
                    onClick={() => handleToggle()}
                  >
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
                <div className={"store-header"}></div>
              </div>

              <div className="modal-body store-model-body">
                <Container>
                  <Row>
                    {importedFiles.length > 0 ? (
                      <>
                        <Col xs={12}>
                          <div>
                            {importedFiles.length > 0 && (
                              <div>
                                <div>
                                  <span>
                                    {t("TITLE_IMPORT_DATA_BASE_FILE") + " : "}
                                  </span>
                                  <Tag
                                    color={"cyan"}
                                    closable={true}
                                    onClose={() => {
                                      const that = this;
                                      let _importedFiles = [...importedFiles];
                                      _importedFiles.shift();
                                      this.setState(
                                        {
                                          importedFiles: _importedFiles,
                                          fieldRelations: {},
                                        },
                                        () => {
                                          that.handlePrepareJsonDataCascader();
                                        }
                                      );
                                      Alert.warning(
                                        t("MESSAGE_DELETE_BASE_FILE")
                                      );
                                    }}
                                  >
                                    {importedFiles[0].name}
                                  </Tag>
                                </div>
                                <div
                                  style={{ display: "flex", marginTop: "10px" }}
                                >
                                  <span>
                                    {t("TITLE_IMPORT_DATA_SUB_FILEs") + " : "}
                                  </span>

                                  <TagGroup style={{ marginLeft: "0px" }}>
                                    {importedFiles.map((f, k) => {
                                      if (k) {
                                        return (
                                          <Tag
                                            closable={true}
                                            key={k}
                                            onClose={() => {
                                              const that = this;
                                              let _importedFiles = [
                                                ...importedFiles,
                                              ];
                                              _importedFiles.shift();
                                              this.setState(
                                                {
                                                  importedFiles: _importedFiles,
                                                  fieldRelations: {},
                                                },
                                                () => {
                                                  that.handlePrepareJsonDataCascader();
                                                }
                                              );
                                              Alert.warning(
                                                t("MESSAGE_DELETE_BASE_FILE")
                                              );
                                            }}
                                          >
                                            {f.name}
                                          </Tag>
                                        );
                                      }
                                    })}
                                  </TagGroup>
                                </div>
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col xs={12}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              style={{ marginRight: "10px" }}
                              onClick={() => this.handleReadLocalFile()}
                            >
                              {t("BUTTON_IMPORT_FILE")}
                            </Button>

                            <Button
                              appearance="primary"
                              onClick={() => this.handlePreview()}
                            >
                              Preview
                            </Button>
                          </div>
                        </Col>
                      </>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          style={{}}
                          onClick={() => this.handleReadLocalFile()}
                        >
                          {t("BUTTON_IMPORT_FILE")}
                        </Button>
                      </div>
                    )}

                    <input
                      id="upload-json"
                      style={{
                        position: "absolute",
                        visibility: "hidden",
                        width: "0px",
                        height: "0px",
                      }}
                      onChange={(params) => {
                        this.handleReadJson(params);
                      }}
                      type="file"
                      accept="application/JSON"
                    />
                  </Row>

                  {importedFiles.length > 0 && selectedService && (
                    <Row style={{ marginTop: "10px" }}>
                      {selectedService.model.map((item, key) => {
                        return (
                          <div className="import-field-key" key={key}>
                            <div className="import-field-text">
                              {item.reqiured && (
                                <span style={{ color: "red" }}>*</span>
                              )}
                              {item.name}
                            </div>
                            <div className="import-field-text-divider">:</div>
                            {fieldRelations.hasOwnProperty(item.name) && (
                              <div className="fade-in import-field-custom-container">
                                {fieldRelations[item.name].map(
                                  (relation, k) => {
                                    return (
                                      <div
                                        key={k + relation.date}
                                        className="fade-in import-field-custom-operationtype"
                                      >
                                        {k > 0 && (
                                          <Icon
                                            style={{
                                              transform: "rotate(45deg)",
                                              margin: "0px 4px",
                                              marginRight: "10px",
                                            }}
                                            icon={"compress"}
                                          />
                                        )}
                                        <div style={{ marginRight: "4px" }}>
                                          {relation.operationType == 1 ? (
                                            <>
                                              {/*
                                              <Cascader
                                              data={jsonCascaderData}
                                              placeholder={t("OPERATION_SELECT_FIELD")}
                                              onChange={(value, event) => {
                                                const _myPath = value.split("-");
                                                 let _fieldRelations = JSON.parse(JSON.stringify(fieldRelations))
                                                _fieldRelations[item.name]["relation"] =
                                                  {
                                                    jsonFile: _myPath[0],
                                                    jsonField: _myPath[1],
                                                  };
                                                  this.setState({fieldRelations:_fieldRelations})
                                              }}
                                            />
                                               */}
                                              <SelectPicker
                                                placement="auto"
                                                data={
                                                  jsonCascaderData[0].children
                                                }
                                                placeholder={t(
                                                  "OPERATION_SELECT_FIELD"
                                                )}
                                                defaultValue={
                                                  fieldRelations[item.name][k]
                                                    .value
                                                }
                                                onChange={(value, event) => {
                                                  const _myPath =
                                                    value.split("-");
                                                  let _fieldRelations =
                                                    JSON.parse(
                                                      JSON.stringify(
                                                        fieldRelations
                                                      )
                                                    );
                                                  _fieldRelations[item.name][k][
                                                    "date"
                                                  ] = new Date();
                                                  _fieldRelations[item.name][k][
                                                    "value"
                                                  ] = value;

                                                  _fieldRelations[item.name][k][
                                                    "relation"
                                                  ] = {
                                                    jsonFile: _myPath[0],
                                                    jsonField: _myPath[1],
                                                  };
                                                  this.setState({
                                                    fieldRelations:
                                                      _fieldRelations,
                                                  });
                                                }}
                                              />
                                            </>
                                          ) : relation.operationType == 2 ? (
                                            t("OPERATION_FIX_VALUE")
                                          ) : relation.operationType == 3 ? (
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <SelectPicker
                                                  placement="auto"
                                                  data={
                                                    jsonCascaderData[0].children
                                                  }
                                                  placeholder={t(
                                                    "OPERATION_SELECT_FIELD"
                                                  )}
                                                  defaultValue={
                                                    fieldRelations[item.name][k]
                                                      .value
                                                  }
                                                  onChange={(value, event) => {
                                                    const _myPath =
                                                      value.split("-");
                                                    let _fieldRelations =
                                                      JSON.parse(
                                                        JSON.stringify(
                                                          fieldRelations
                                                        )
                                                      );
                                                    _fieldRelations[item.name][
                                                      k
                                                    ]["date"] = new Date();
                                                    _fieldRelations[item.name][
                                                      k
                                                    ]["value"] = value;
                                                    _fieldRelations[item.name][
                                                      k
                                                    ]["relation"] = {
                                                      ..._fieldRelations[
                                                        item.name
                                                      ][k]["relation"],
                                                      fromJsonFile: _myPath[0],
                                                      fromField: _myPath[1],
                                                    };
                                                    this.setState({
                                                      fieldRelations:
                                                        _fieldRelations,
                                                    });
                                                  }}
                                                />
                                                <Icon
                                                  icon={"exchange"}
                                                  style={{ margin: "0px 10px" }}
                                                />

                                                <Cascader
                                                  placement="auto"
                                                  data={jsonCascaderHelperData}
                                                  placeholder={t(
                                                    "OPERATION_SELECT_FIELD"
                                                  )}
                                                  defaultValue={
                                                    fieldRelations[item.name][k]
                                                      .value
                                                  }
                                                  onChange={(value, event) => {
                                                    const _myPath =
                                                      value.split("-");
                                                    let _fieldRelations =
                                                      JSON.parse(
                                                        JSON.stringify(
                                                          fieldRelations
                                                        )
                                                      );
                                                    _fieldRelations[item.name][
                                                      k
                                                    ]["relation"] = {
                                                      ..._fieldRelations[
                                                        item.name
                                                      ][k]["relation"],
                                                      toJsonFile: _myPath[0],
                                                      toJsonField: _myPath[1],
                                                    };
                                                    _fieldRelations[item.name][
                                                      k
                                                    ]["value"] = value;

                                                    _fieldRelations[item.name][
                                                      k
                                                    ]["date"] = new Date();

                                                    this.setState({
                                                      fieldRelations:
                                                        _fieldRelations,
                                                    });
                                                  }}
                                                />
                                              </div>

                                              {fieldRelations[item.name]
                                                .relation &&
                                                Object.keys(
                                                  fieldRelations[item.name]
                                                    .relation
                                                ).length > 2 && (
                                                  <SelectPicker
                                                    placement="auto"
                                                    defaultValue={
                                                      fieldRelations[item.name][
                                                        k
                                                      ].value
                                                    }
                                                    style={{
                                                      marginTop: "10px",
                                                    }}
                                                    data={
                                                      jsonCascaderData.find(
                                                        (nd) =>
                                                          nd.value ==
                                                          fieldRelations[
                                                            item.name
                                                          ].relation.toJsonFile
                                                      ).children
                                                    }
                                                    placeholder={t(
                                                      "OPERATION_SELECT_FIELD"
                                                    )}
                                                    onChange={(
                                                      value,
                                                      event
                                                    ) => {
                                                      const _myPath =
                                                        value.split("-");
                                                      let _fieldRelations =
                                                        Object.assign(
                                                          {},
                                                          fieldRelations
                                                        );
                                                      _fieldRelations[
                                                        item.name
                                                      ][k]["relation"] = {
                                                        ..._fieldRelations[
                                                          item.name
                                                        ][k]["relation"],
                                                        newJsonField:
                                                          _myPath[1],
                                                      };

                                                      _fieldRelations[
                                                        item.name
                                                      ][k]["value"] = value;

                                                      _fieldRelations[
                                                        item.name
                                                      ][k]["date"] = new Date();
                                                      this.setState({
                                                        fieldRelations:
                                                          _fieldRelations,
                                                      });
                                                    }}
                                                  />
                                                )}
                                            </div>
                                          ) : relation.operationType == 4 ? (
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                  alignItems: "center",
                                                }}
                                              >
                                                {" ( " + t("TXT_IF")}
                                                <SelectPicker
                                                  style={{ margin: "0px 4px" }}
                                                  placement="auto"
                                                  data={
                                                    jsonCascaderData[0].children
                                                  }
                                                  placeholder={t(
                                                    "OPERATION_SELECT_FIELD"
                                                  )}
                                                  defaultValue={
                                                    fieldRelations[item.name][k]
                                                      .value
                                                  }
                                                  onChange={(value, event) => {
                                                    const _myPath =
                                                      value.split("-");
                                                    let _fieldRelations =
                                                      JSON.parse(
                                                        JSON.stringify(
                                                          fieldRelations
                                                        )
                                                      );
                                                    _fieldRelations[item.name][
                                                      k
                                                    ]["date"] = new Date();
                                                    _fieldRelations[item.name][
                                                      k
                                                    ]["value"] = value;
                                                    _fieldRelations[item.name][
                                                      k
                                                    ]["relation"] = {
                                                      ..._fieldRelations[
                                                        item.name
                                                      ][k]["relation"],
                                                      fromJsonFile: _myPath[0],
                                                      fromField: _myPath[1],
                                                    };
                                                    this.setState({
                                                      fieldRelations:
                                                        _fieldRelations,
                                                    });
                                                  }}
                                                />

                                                <Dropdown
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                  }}
                                                  placement="auto"
                                                  renderTitle={() => {
                                                    return (
                                                      <Button
                                                        size="xs"
                                                        appearance="default"
                                                        circle
                                                      >
                                                        {relation.ops
                                                          ? if_ops[parseInt(relation.ops)-1].value
                                                          : if_ops[0].value}
                                                      </Button>
                                                    );
                                                  }}
                                                >
                                                  {if_ops.map((x, key) => (
                                                    <Dropdown.Item
                                                      key={key}
                                                      onClick={() => {
                                                        const that = this;
                                                        let _data = {
                                                          ...fieldRelations,
                                                        };
                                                        let _myData = [];
                                                        if (
                                                          _data.hasOwnProperty(
                                                            item.name
                                                          )
                                                        )
                                                          _myData = [
                                                            ..._data[item.name],
                                                          ];

                                                        let newRelation = {
                                                          ops: x.id,
                                                          date: new Date(),
                                                        };
                                                        const _fir =
                                                          fieldRelations[
                                                            item.name
                                                          ].findIndex(
                                                            (_ir) =>
                                                              _ir == relation
                                                          );
                                                        debugger;
                                                        _myData[
                                                          _fir
                                                        ] = {
                                                          ..._myData[
                                                            _fir
                                                          ],
                                                          ...newRelation,
                                                        };

                                                        _data[item.name] =
                                                          _myData;

                                                        this.setState({
                                                          fieldRelations: _data,
                                                          date: new Date(),
                                                        });
                                                      }}
                                                    >
                                                      {x.name}
                                                    </Dropdown.Item>
                                                  ))}
                                                </Dropdown>

                                                {" ? " + t("TXT_THEN") + " "}
                                                {t("TXT_ELSE") + " )"}
                                              </div>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                        <IconButton
                                          size="xs"
                                          style={{ margin: "0px 4px" }}
                                          appearance="subtle"
                                          icon={<Icon icon="trash-o" />}
                                          circle
                                          onClick={() => {
                                            let _data = JSON.parse(
                                              JSON.stringify(fieldRelations)
                                            );
                                            debugger;
                                            _data[item.name].splice(k, 1);
                                            debugger;

                                            if (_data[item.name].length == 0)
                                              delete _data[item.name];

                                            this.setState({
                                              fieldRelations: _data,
                                              date: new Date(),
                                            });
                                          }}
                                        />
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            )}
                            <Dropdown
                              style={{ display: "flex", alignItems: "center" }}
                              placement="auto"
                              renderTitle={() => {
                                return (
                                  <IconButton
                                    size="xs"
                                    appearance="primary"
                                    icon={<Icon icon="plus" />}
                                    circle
                                  />
                                );
                              }}
                            >
                              {_operations.map((x, key) => (
                                <Dropdown.Item
                                  key={key}
                                  onClick={() => {
                                    const that = this;
                                    let _data = { ...fieldRelations };
                                    let _myData = [];
                                    if (_data.hasOwnProperty(item.name))
                                      _myData = [..._data[item.name]];

                                    let newRelation = {
                                      operationType: x.id,
                                      date: new Date(),
                                    };

                                    switch (x.id) {
                                      case 4:
                                        //if codition
                                        newRelation.ops = 0;
                                        break;

                                      default:
                                        break;
                                    }

                                    _myData.push(newRelation);
                                    _data[item.name] = _myData;

                                    this.setState({
                                      fieldRelations: _data,
                                      date: new Date(),
                                    });
                                  }}
                                >
                                  {x.name}
                                </Dropdown.Item>
                              ))}
                            </Dropdown>
                          </div>
                        );
                      })}
                    </Row>
                  )}

                  {isLoading && (
                    <div className={"fade-in"}>
                      <Loader backdrop vertical />
                    </div>
                  )}
                </Container>
              </div>

              {this.state.alert}
              <ImportDataPreviewModal
                data={this.state.previewData}
                handleToggle={() => {
                  this.setState({
                    isPreview: false,
                  });
                }}
                isActive={this.state.isPreview}
              />
            </Modal>
          </>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer } = state;
  const { models, model, transaction, workspace, dataModels, selectedService } =
    workspaceReducer;

  return {
    workspace,
    transaction,
    dataModels,
    models,
    model,
    selectedService,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ImportDataModal);
