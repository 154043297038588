import React, { Component } from "react";
import PropTypes from "prop-types";
import i18n from "i18n";
import { Translation } from "react-i18next";
import { connect } from "react-redux";
import { setEnvironments } from "_actions";
import { EnvironmentsService } from "_services";
import { ResponseStatusCode } from "_helpers";
import Emitter from "_actions/emitter";
import { Loader } from "rsuite";

import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";

import { Modal, Row } from "reactstrap";

class ManageEnvironmentsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }
  componentDidUpdate = (prevProps) => {
    const { isActive } = this.props;
    if (prevProps.isActive !== isActive && isActive) {
      this.getEnviroments();
      this.setState({ data: [] });
    }
  };
  getEnviroments = async () => {
    const that = this;
    const { workspace, module, setEnvironments } = this.props;

    that.setState({ isLoading: true });
    await EnvironmentsService.GET_ALL_ENVIRONMENTS_FROM_MODULE(
      workspace.id,
      module.id
    )
      .then((data) => {
        that.setState({ isLoading: false });
        const _data = data.dynamicValue;
        setEnvironments(_data);
      })
      .catch((err) => {
        debugger;
        that.setState({ isLoading: false });
        let data = {
          title: i18n.t("warning"),
          desc: ResponseStatusCode(err.statusCode),
        };
        Emitter.emit("SHOW_WARNING_MODAL", data);
        //Alert.error(data.dynamicValue)
      });
  };
  createEnviroment = async (name, value) => {
    const that = this;
    const { workspace, module } = this.props;
    const { setEnvironments } = this.props;
    that.setState({ isLoading: true });
    await EnvironmentsService.CREATE(workspace.id, module.id, name, value)
      .then((data) => {
        that.setState({ isLoading: false });
        const _data = data.dynamicValue;

        const { environments } = that.props;
        let _newArray = [...environments];
        _newArray.push(_data);
        setEnvironments(_newArray);
      })
      .catch((err) => {
        debugger;
        that.setState({ isLoading: false });
        let data = {
          title: i18n.t("warning"),
          desc: ResponseStatusCode(err.statusCode),
        };
        Emitter.emit("SHOW_WARNING_MODAL", data);
        //Alert.error(data.dynamicValue)
      });
  };
  updateEnviroment = async (_id, name, value, IsActive) => {
    const that = this;
    const { setEnvironments } = this.props;
    const { workspace, module } = this.props;
    debugger;
    that.setState({ isLoading: true });
    await EnvironmentsService.UPDATE_ENVIRONMENTS(
      _id,
      workspace.id,
      module.id,
      value
    )
      .then((data) => {
        that.setState({ isLoading: false });
        const _data = data.dynamicValue;
        debugger;
        const { environments } = that.props;
        let _newArray = [...environments];
        let fIndex = _newArray.findIndex((x) => x.id == _data.id);
        if (fIndex != -1) {
          _newArray[fIndex] = _data;
          setEnvironments(_newArray);
        }
      })
      .catch((err) => {
        debugger;
        that.setState({ isLoading: false });
        let data = {
          title: i18n.t("warning"),
          desc: ResponseStatusCode(err.statusCode),
        };
        Emitter.emit("SHOW_WARNING_MODAL", data);
        //Alert.error(data.dynamicValue)
      });
  };
  deleteEnviroment = async (_id) => {
    const that = this;
    const { setEnvironments } = this.props;
    const { workspace, module } = this.props;
    that.setState({ isLoading: true });
    await EnvironmentsService.REMOVE(workspace.id, module.id, _id)
      .then((data) => {
        that.setState({ isLoading: false });
        // const _data = data.dynamicValue;
        debugger;
      })
      .catch((err) => {
        that.setState({ isLoading: false });
        debugger;
        let data = {
          title: i18n.t("warning"),
          desc: ResponseStatusCode(err.statusCode),
        };
        Emitter.emit("SHOW_WARNING_MODAL", data);
        //Alert.error(data.dynamicValue)
      });
  };

  afterSaveCell = (oldValue, newValue, row, column) => {
    const that = this;
    //debugger
  };
  beforeSaveCell = (oldValue, newValue, row, column, done) => {
    if (oldValue !== newValue) {
      if (row.id === "") {
        const { data } = this.state;
        this.createEnviroment(
          column.dataField == "name" ? newValue : row.name,
          column.dataField == "value" ? newValue : row.value
        );
        done(false);
        return { async: true };
      } else {
        this.updateEnviroment(
          row.id,
          column.dataField == "name" ? newValue : row.name,
          column.dataField == "value" ? newValue : row.value,
          true
        );
        done(false);
        return { async: true };
      }
    } else {
      done(false);
      return { async: true };
    }
  };
  generateFormatter = (cell, row, rowIndex, formatExtraData) => {
    return row.id ? (
      cell === "" ? (
        formatExtraData === i18n.t("PLACEHOLDER_ENVIRONMENT_ADDNEW") ? (
          <span className={"enviroment-new-add"}>
            {i18n.t("PLACEHOLDER_ENVIRONMENT_KEY")}
          </span>
        ) : (
          <span className={"enviroment-new-add"}>
            {i18n.t("PLACEHOLDER_ENVIRONMENT_VALUE")}
          </span>
        )
      ) : (
        <span className={"enviroment-cell"}>{cell}</span>
      )
    ) : (
      <span className={"enviroment-new-add"}>{formatExtraData}</span>
    );
  };
  render() {
    const { isActive, handleToggle, environments } = this.props;
    const { isLoading, body, data } = this.state;

    const columns = [
      {
        dataField: "isActive",
        type: "bool",
        hidden: true,
        text: "Is Active",
      },
      {
        dataField: "name",
        text: "VARIABLE",
        formatter: this.generateFormatter,
        formatExtraData: i18n.t("PLACEHOLDER_ENVIRONMENT_ADDNEW"),
        editorRenderer: (
          editorProps,
          value,
          row,
          column,
          rowIndex,
          columnIndex
        ) => (
          <CustomInput
            placeholder={i18n.t("PLACEHOLDER_ENVIRONMENT_KEY")}
            {...editorProps}
            isLoading={isLoading}
            value={value}
          />
        ),
        editorClasses: (cell, row, rowIndex, colIndex) =>
          "rs-input rs-input-sm",
      },
      {
        dataField: "value",
        formatter: this.generateFormatter,
        formatExtraData: "", //i18n.t("PLACEHOLDER_ENVIRONMENT_VALUE"),
        text: "Value",
        editorRenderer: (
          editorProps,
          value,
          row,
          column,
          rowIndex,
          columnIndex
        ) => (
          <CustomInput
            placeholder={i18n.t("PLACEHOLDER_ENVIRONMENT_VALUE")}
            {...editorProps}
            isLoading={isLoading}
            value={value}
          />
        ),
        editorClasses: (cell, row, rowIndex, colIndex) =>
          "rs-input rs-input-sm",
      },
    ];

    return (
      <Translation>
        {(t) => (
          <Modal
            isOpen={isActive}
            size="lg"
            scrollable={true}
            className={"modal-dialog-centered httpobject-modal"}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="EnvListModal">
                {t("MODAL_TITLE_MANAGE_ENVIRONMENTS")}
              </h5>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => handleToggle()}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <div>
                <Row>
                  <div
                    className={"icons-modal-container httpobject-headers-table"}
                  >
                    <BootstrapTable
                      style={{
                        width: "100%",
                      }}
                      loading={isLoading}
                      keyField="id"
                      data={
                        environments
                          ? [
                              ...environments,
                              {
                                id: "",
                                isActive: true,
                                name: "",
                                value: "",
                              },
                            ]
                          : []
                      }
                      columns={columns}
                      cellEdit={cellEditFactory({
                        mode: "click",
                        blurToSave: true,
                        afterSaveCell: this.afterSaveCell,
                        beforeSaveCell: this.beforeSaveCell,
                      })}
                    />
                    {isLoading && (
                      <Loader
                        backdrop={true}
                        style={{ position: "absolute", top: 0 }}
                      />
                    )}
                  </div>
                </Row>
              </div>
            </div>
          </Modal>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer, authentication } = state;

  const { workspace, selectedService, module, environments } = workspaceReducer;

  return {
    workspace,
    module,
    environments,
    selectedService,
  };
};
const mapDispatchToProps = {
  setEnvironments,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageEnvironmentsModal);

class CustomInput extends React.Component {
  static propTypes = {
    value: PropTypes.number,
    isLoading: PropTypes.bool,
    onUpdate: PropTypes.func.isRequired,
  };
  static defaultProps = {
    value: 0,
  };
  getValue() {
    return this.edittext.value;
  }
  render() {
    const { value, isLoading, onUpdate, ...rest } = this.props;
    // debugger
    return (
      <div style={{ position: "relative" }}>
        <input
          {...rest}
          className={"rs-input rs-input-sm"}
          key="edittext"
          ref={(node) => (this.edittext = node)}
        />
        {isLoading && (
          <Loader backdrop={true} style={{ position: "absolute", top: 0 }} />
        )}
      </div>
    );
  }
}
