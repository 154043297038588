export const currentstatesConstants = {
    SET_HISTORY: 'SET_HISTORY',

    SET_CURRENTPAGE: 'SET_CURRENTPAGE',
    SET_MYSTREAM: 'SET_MYSTREAM',
    SET_PROJECTSTREAM: 'SET_PROJECTSTREAM',
    SET_SYSTEMSTREAM: 'SET_SYSTEMSTREAM',


    SET_EDITORLOADING: 'SET_EDITORLOADING',
    SET_COMPONENTLISTLOADING: 'SET_COMPONENTLISTLOADING',
    SET_MYCOMPONENTLISTLOADING: 'SET_MYCOMPONENTLISTLOADING',
    SET_UPLOADIMAGELOADING: 'SET_UPLOADIMAGELOADING',
    SET_MYSTREAMLOADING: 'SET_MYSTREAMLOADING',
    SET_PROJECTSTREAMLOADING: 'SET_PROJECTSTREAMLOADING',
    SET_SYSTEMSTREAMLOADING: 'SET_SYSTEMSTREAMLOADING',
    SET_PAGESTATUS: 'SET_PAGESTATUS',


    SET_ONMOVE_STATUS: 'SET_ONMOVE_STATUS',
    SET_ONADD_STATUS: 'SET_ONADD_STATUS',
    SET_ONADD_UPDATE: 'SET_ONADD_UPDATE',
    SET_ONREMOVE_STATUS: 'SET_ONREMOVE_STATUS',
    SET_ONEND_STATUS: "SET_ONEND_STATUS",

    SET_PROJECT_ID: "SET_PROJECT_ID",

    SET_PROJECT_STATIC_COLLECTIONS: 'SET_PROJECT_STATIC_COLLECTIONS',

    SET_PROJECT_DYNAMIC_COLLECTIONS: 'SET_PROJECT_DYNAMIC_COLLECTIONS',
    SET_PROJECT_DYNAMIC_DB: 'SET_PROJECT_DYNAMIC_DB',

    CODE_EDITOR_SNIPPETS_UPLOAD:'CODE_EDITOR_SNIPPETS_UPLOAD'





}