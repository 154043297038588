import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import Emitter from "_actions/emitter";
import { Loader } from "rsuite";

import i18n from "i18n";

import { Container, Header, Content, Footer, Sidebar } from "rsuite";
import { Icon, Sidenav } from "rsuite";

import {
  Navbar,
  Nav,
  Alert,
  Dropdown,
  ButtonToolbar,
  ButtonGroup,
  IconButton,
  Button,
} from "rsuite";
import { Tooltip, Whisper } from "rsuite";
import { WorkspaceService, ModelsService, TransactionService } from "_services";

import CodeEditor from "components/Custom/CodeEditor";
import CreateTransaction from "components/Modals/CreateTransaction";
import UpdateTransaction from "components/Modals/UpdateTransaction";
import ServiceRelationsModal from "components/Modals/ServiceRelationsModal";

import {
  updateSelectedService,
  updateSelectedTransaction,
  updatedModels,
  updatedTransactions,
  updateSelectedModel,
} from "_actions";

const CustomButton = ({ child, tip }) => (
  <Whisper
    trigger="hover"
    placement={"autoVertical"}
    speaker={<Tooltip>{tip}</Tooltip>}
  >
    {child}
  </Whisper>
);

class ServiceLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      script: "",
      data: null,
      loading: false,
      isCreateTrans: false,
      isUpdateTrans: false,
      isServiceRelations: false,

      //  active: this.props.selectedService && this.props.selectedService.children[0].label
    };
  }

  handleSaveTrans = () => {
    //Id,RelWorkSpaceId,RelModuleId,Script
    const {
      transaction,
      selectedService,
      workspace,
      clearHistory,
      onRefresh,
      handleToggle,
      removeComponent,
      AddPageToProject,
      script,
    } = this.props;

    const that = this;

    that.setState({ loading: true });
    TransactionService.SAVE_SCRIPT_ONLY(
      transaction.id,
      workspace.id,
      selectedService.relModuleId,
      this.state.script
    )
      .then(async (data) => {
        that.setState({ loading: false });
        Alert.success(i18n.t("SUCCESS_SAVED"));
      })
      .catch((err) => {
        Alert.error("Error code : " + err.statusCode);
        this.setState({ loading: false });
      });
  };

  handleUpdateTrans = () => {
    this.setState({
      isUpdateTrans: !this.state.isUpdateTrans,
    });
  };

  handleCreateTrans = () => {
    this.setState({
      isCreateTrans: !this.state.isCreateTrans,
    });
  };

  handleSelect = (activeKey) => {
    this.setState({ active: activeKey });
  };

  onRefresh = () => {
    this.getModelsCollections();
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.transaction !== null &&
      this.props.transaction != prevProps.transaction
    ) {
      this.getTransDetail();
    }
  }
  handleShowServiceRelations = () => {
    this.setState({ isServiceRelations: !this.state.isServiceRelations });
  };

  getAllModels = async () => {
    const that = this;
    const {
      modules,
      module,
      updatedModels,
      updateSelectedModel,
      updatedTransactions,
    } = this.props;
    let _list = [];
    await modules.dynamicValue.forEach(async (e) => {
      await ModelsService.STEPBYLOAD(false, "-1", 9999, "", e.id)
        .then(async (data) => {
          await data.dynamicValue.dynamicValue.forEach((element) => {
            _list.push({
              moduleWModel: element.modelName + " - (" + e.name + ")",
              ...element,
            });
          });
        })
        .catch((err) => {
          Alert.error("Error code : " + err.statusCode);
          this.setState({ loading: false });
        });
    });
    updateSelectedModel(_list);
  };

  getModelsCollections = () => {
    const that = this;
    this.getAllModels();
    const {
      updateSelectedService,
      module,
      updatedModels,
      selectedService,
      updateSelectedModel,
      updatedTransactions,
    } = this.props;
    if (module) {
      this.setState({ loading: true });
      ModelsService.STEPBYLOAD_W_GROUP(false, "-1", 9999, "", module.id)
        .then((data) => {
          let _list = []; //data.dynamicValue.slice(0);
          let _data = data.dynamicValue;
          let _trans = [];

          data.dynamicValue.forEach((element, key) => {
            let _el = {
              ...element.model,
              label: element.model.modelName,
              value: element.model.id,
              children: [],
              isService: true,
            };

            element.transActions.forEach((item) => {
              let _view = {};
              _el.dataTypes.map((item) => {
                _view[item.name] = {
                  visible: true,
                  col: 12,
                };
              });
              _el.children.push({
                ...item,
                label: item.name,
                value: item.id,
                refId: _el.id,
                dataModelID: "",
                type: 0,
                model: [..._el.dataTypes],
                view: _view,
                controller: "",
                script: {
                  params: [],
                  code: "",
                },
              });
              _trans.push({
                ...item,
                label: item.name,
                value: item.id,
                refId: _el.id,
                dataModelID: "",
                type: 0,
                model: [..._el.dataTypes],
                view: _view,
                controller: "",
                script: {
                  params: [],
                  code: "",
                },
              });
            });
            if (_el.id === selectedService.id) {
              updateSelectedService(_el);
            }
            _list.push(_el);
          });

          //data.dynamicValue = _list;

          updatedTransactions({
            dynamicValue: _trans,
          });

          updatedModels({
            dynamicValue: _list,
          });
          /*
                                          that.updateSelectedService();
                                                        setTimeout(() => {
                                                            Emitter.emit("CHANGE_CONTROLLER_PAGE",fData)
                                                        }, 10);
                    */

          that.setState({ loading: false });
        })
        .catch((err) => {
          debugger;
          Alert.error("Error code : " + err.statusCode);
          this.setState({ loading: false });
        });
    }
  };

  getTransDetail = () => {
    const that = this;
    const {
      module,
      workspace,
      transaction,
      updatedModels,
      setDynamicCollections,
      dynamicDatabase,
    } = this.props;
    if (module && workspace && transaction) {
      this.setState({ loading: true });
      TransactionService.GET_TRANSACTION_BYID(
        transaction.id,
        workspace.id,
        module.id
      )
        .then((data) => {
          that.setState({
            script: data.dynamicValue.script,
            data: data.dynamicValue,
          });
          that.setState({ loading: false });
        })
        .catch((err) => {
          debugger;
          Alert.error("Error code : " + err.statusCode);
          this.setState({ loading: false });
        });
    }
  };

  render() {
    const { active } = this.state;

    return (
      <Translation>
        {(t) => (
          <>
            <Container
              className={"custom-fade-in"}
              style={{ width: "100%", height: "100%" }}
            >
              <Header className={"nav-service-header"}>
                <Navbar appearance="subtle">
                  {!this.props.sidebarActive && (
                    <Navbar.Header
                      className={"nav-service-navbar-header custom-fade-in"}
                      style={{ marginLeft: 10 }}
                    >
                      {this.props.selectedService.label}
                    </Navbar.Header>
                  )}

                  <Navbar.Body>
                    <Nav></Nav>
                    <Nav pullRight>
                      <ButtonToolbar className={"header-nav-buttons"}>
                        {this.props.transaction && (
                          <>
                            <CustomButton
                              child={
                                <IconButton
                                  onClick={this.handleSaveTrans}
                                  size="sm"
                                  circle={true}
                                  appearance="ghost"
                                  className={"custom-fade-in"}
                                  icon={<Icon icon="save" />}
                                  style={{ boxShadow: "none" }}
                                  placement="right"
                                />
                              }
                              tip={t("BUTTON_SAVE")}
                            />
                            <CustomButton
                              child={
                                <IconButton
                                  onClick={this.handleUpdateTrans}
                                  size="sm"
                                  circle={true}
                                  appearance="ghost"
                                  className={"custom-fade-in"}
                                  icon={<Icon icon="edit2" />}
                                  style={{ boxShadow: "none" }}
                                  placement="right"
                                />
                              }
                              tip={t("BUTTON_UPDATE")}
                            />
                            <CustomButton
                              child={
                                <IconButton
                                  icon={<Icon icon="code-fork" />}
                                  size="sm"
                                  circle={true}
                                  appearance="ghost"
                                  style={{ boxShadow: "none" }}
                                  className={"custom-fade-in"}
                                  onClick={this.handleShowServiceRelations}
                                ></IconButton>
                              }
                              tip={t("SERVICES_MENU_ITEM_SERVICE_RELATIONS")}
                            />
                          </>
                        )}

                        <CustomButton
                          child={
                            <IconButton
                              onClick={this.handleCreateTrans}
                              size="sm"
                              circle={true}
                              appearance="ghost"
                              className={"custom-fade-in"}
                              icon={<Icon icon="plus" />}
                              style={{ boxShadow: "none" }}
                              placement="right"
                            />
                          }
                          tip={t("BUTTON_CREATE_TRANSACTION")}
                        />
                      </ButtonToolbar>
                    </Nav>
                  </Navbar.Body>
                </Navbar>
              </Header>
              <Container>
                <Sidebar
                  className={"service-sidebar"}
                  style={{ width: "auto" }}
                >
                  <Nav
                    appearance="subtle"
                    vertical
                    activeKey={active}
                    onSelect={this.handleSelect}
                    style={{ width: "100%", height: "100%" }}
                  >
                    {this.props.selectedService &&
                      this.props.selectedService.children.map((item, key) => (
                        <Nav.Item
                          onSelect={() => {
                            this.props.updateSelectedTransaction(item);
                          }}
                          key={key}
                          eventKey={item.label}
                        >
                          {item.label}
                        </Nav.Item>
                      ))}
                  </Nav>
                </Sidebar>
                <Content className={" mvc-div"} style={{paddingBottom:0}}>
                  {this.props.transaction && !this.state.loading && (
                    <>
                      {
                        <div
                          style={{ height: "100%" }}
                          key={
                            this.state.data ? this.state.data.id + "TS" : "TS"
                          }
                        >
                          <CodeEditor
                            executableTransactionServiceName={
                              this.props.transaction
                                .executableTransactionServiceName
                            }
                            key={active + "JS"}
                            funcName={active + "Service"}
                            funcParams={["param"]}
                            value={this.state.script}
                            onChangeScript={(script) => {
                              this.setState({ script: script });
                            }}
                          />
                        </div>
                      }
                    </>
                  )}
                  {this.state.loading && (
                    <div
                      style={{
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <Loader />
                    </div>
                  )}
                </Content>
              </Container>
            </Container>
            <CreateTransaction
              onRefresh={this.onRefresh}
              isActive={this.state.isCreateTrans}
              handleToggle={this.handleCreateTrans}
            />
            <UpdateTransaction
              onRefresh={this.onRefresh}
              script={this.state.script}
              isActive={this.state.isUpdateTrans}
              handleToggle={this.handleUpdateTrans}
            />

            <ServiceRelationsModal
              handleToggle={this.handleShowServiceRelations}
              isActive={this.state.isServiceRelations}
            />
          </>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer } = state;
  const {
    models,
    transaction,
    workspaces,
    workspace,
    selectedService,
    services,
    modules,
    module,
  } = workspaceReducer;

  return {
    workspaces,
    workspace,
    modules,
    module,
    selectedService,
    services,
    models,
    transaction,
  };
};

const mapDispatchToProps = {
  updateSelectedService,
  updateSelectedTransaction,
  updatedModels,
  updateSelectedModel,
  updatedTransactions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceLayout);
