
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table, Button } from 'rsuite';

const { Cell } = Table;

class ActionCell extends Component {

    render() {
        const { rowData, dataKey, onClick , handleCancel} = this.props;

        return (
            <Cell {...this.props} style={{ padding: '6px 0' }}>
                <Button
                    appearance="link"
                    onClick={() => {
                        onClick && onClick(rowData.id);
                    }}
                >
                    {rowData.__isEditXAPPTABLE === 'EDIT' ? 'Save' : 'Edit'}
                </Button>
                {
                    rowData.__isEditXAPPTABLE === 'EDIT' &&
                    <Button
                        appearance="link"
                        onClick={() => {
                            handleCancel && handleCancel(rowData.id);
                        }}
                    >
                        Cancel
               </Button>
                }
            </Cell>
        );
    }
}

ActionCell.propTypes = {
    rowData: PropTypes.object,
    dataKey: PropTypes.string,
    onClick: PropTypes.func,
};

export default ActionCell;