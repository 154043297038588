import {
  //ServiceURLs,
  GetBaseConnectURL,
  GetBaseCreatorURL,
  ProjectEntPoints,
  CreatorEndPoints,
  GetBaseConsoleURL,
  MakeReqestAndGetResponse,
  GetUserLang,
} from "./index";
import { HTTPOperationType } from "./httpOperations";

export const ProjectService = {
 Create,
 GetProjectSetting,
 SetProjectSetting,
 CheckIsHaveProjectSetting,
 GetPaging,
 GET_PROJECT_TYPES,
 cloneProject,
 GetAppCategory,
 SetProjectDetail,
};

function SetProjectDetail(ProjectID) {
  var js = {
    ProjectID:ProjectID,
    Token : JSON.parse(localStorage.getItem("user")).token
  };

  return MakeReqestAndGetResponse(
    GetBaseConnectURL() + ProjectEntPoints.SET_PROJECT_DETAIL,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
} 

function GetAppCategory() {
 
  return MakeReqestAndGetResponse(
    GetBaseConsoleURL() + ProjectEntPoints.GET_APP_CATEGORIES+GetUserLang(),
    HTTPOperationType.get
    
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

function GET_PROJECT_TYPES() {
  return MakeReqestAndGetResponse(
    GetBaseConsoleURL() + ProjectEntPoints.GET_PROJECT_TYPES+GetUserLang(),
    HTTPOperationType.get
    
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}



function Create(ProjectName,ProjectTypeId,id) {
  var js = {
    ProjectName:ProjectName,
    ProjectTypeId : ProjectTypeId,
    id:id 
  };

  return MakeReqestAndGetResponse(
    GetBaseConsoleURL() + ProjectEntPoints.CREATE_PROJECT,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
} 

function cloneProject(ProjectName,ProjectTypeId,id) {
  var js = {
    ProjectName:ProjectName,
    ProjectTypeId : ProjectTypeId,
    id:id
  };

  return MakeReqestAndGetResponse(
    GetBaseConsoleURL() + ProjectEntPoints.CLONE_PROJECT,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
} 


function GetProjectSetting(ProjectID) {
  var js = {
    id: ProjectID
  };

  return MakeReqestAndGetResponse(
    GetBaseConsoleURL() + CreatorEndPoints.GET_PROJECT_SETTING,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}
//target,icons,Icon,description,iOSPackageName,androidPackageName,RelCategoryId,portrait,upsideDown,landscapeLeft,landscapeRight girilmeli
function SetProjectSetting(ProjectID,Setting,ProjectName) {
  var js = {
    id: ProjectID,
    setting:  Setting,
    ProjectName:ProjectName
}

  return MakeReqestAndGetResponse(
    GetBaseConsoleURL() + CreatorEndPoints.SET_PROJECT_SETTING,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

function CheckIsHaveProjectSetting(ProjectID) {
  var js =  {
    id: ProjectID
    
}

  return MakeReqestAndGetResponse(
    GetBaseConsoleURL() + CreatorEndPoints.CHECK_IS_HAVE_PROJECT_SETTING,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

//5ebee11749159d00014cfe17
function GetPaging(_FirstIndexID,_LastIndexId,_IsOrderByDesc,Page,RowPerCount) {
  var js =  {
    Page: Page,
    RowPerCount: RowPerCount,
    FirstIndexID :_FirstIndexID,
    IsOrderByDesc: _IsOrderByDesc,
    LastIndexId: _LastIndexId
}
  return MakeReqestAndGetResponse(
    GetBaseCreatorURL() + CreatorEndPoints.GET_PAGING,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}
