import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import Emitter from "_actions/emitter";
import i18n from "i18n";
import { ResponseStatusCode } from "_helpers";
import { erpRolesService } from "_services";
import { Button, Alert, CheckPicker, MultiCascader } from "rsuite";

import { Input, Modal, Container, Row, Col } from "reactstrap";

class CreateRole extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      scopes: [],
      customStyles: {
        RoleName: "",
        Roles: [],
      },
    };
  }
  handleGetScopes = () => {
    const { workspace } = this.props;
    const that = this;
    that.setState({ isLoading: true });
    erpRolesService
      .GET_SCOPES(workspace.id)
      .then((data) => {
        that.setState({
          scopes: data.dynamicValue,
        });
      })
      .catch((err) => {
        Alert.warning(ResponseStatusCode(err.statusCode));
        that.setState({ isLoading: false });
        debugger;
        //  let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
        //   this.warningAlert(data);
      });
  };

  handleCreateRole = () => {
    const {
      workspace,
      clearHistory,
      onRefresh,
      handleToggle,
      removeComponent,
      AddPageToProject,
    } = this.props;
    let _state = this.state.customStyles;
    let _roles = _state.Roles.map((x) => {
      return {
        long: x,
        short: x.slice((x.indexOf(".")+1),x.length)
      };
    });
    const that = this;
    that.setState({ isLoading: true });
    erpRolesService
      .CREATE_ROLE(workspace.id, _state.RoleName, _roles)
      .then((data) => {
        onRefresh();
      })
      .catch((err) => {
        Alert.warning(ResponseStatusCode(err.statusCode));
        that.setState({ isLoading: false });
        debugger;
        //  let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
        //   this.warningAlert(data);
      });
  };

  customStylesForm = (e, stateName) => {
    let newState = this.state.customStyles;
    newState[stateName] = e.target.value;

    if (stateName === "checkbox") {
      if (e.target.value) {
        newState.checkboxState = "valid";
      } else {
        newState.checkboxState = "invalid";
      }
    } else {
      if (e.target.value === "") {
        newState[stateName + "State"] = "invalid";
      } else {
        newState[stateName + "State"] = "valid";
      }
    }
    this.setState({
      customStyles: newState,
    });
  };

  onKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      this.validateCustomStylesForm();
    }
  };

  validateCustomStylesForm = () => {
    let newState = this.state.customStyles;
    newState.RoleName = newState.RoleName.trim();

    var regex = /^[a-zA-Z][a-zA-Z0-9_ ]{2,}$/;

    if (newState.RoleName === "") {
      newState.RoleNameState = "invalid";
    } else if (!regex.test(newState.RoleName)) {
      newState.RoleNameState = "invalid";
    } else {
      newState.RoleNameState = "valid";
    }

    if (newState.Roles.length < 1) {
      newState.RolesState = "invalid";
      Alert.error(i18n.t("MODAL_CREATE_ROLE_ROLESY_INVALID"));
    } else {
      newState.RolesState = "valid";
    }

    this.setState(
      {
        customStyles: newState,
      },
      () => {
        if (
          newState.RoleNameState == "valid" &&
          newState.RolesState == "valid"
        ) {
          this.handleCreateRole();
        }
      }
    );
  };
  resetContent = () => {
    this.handleGetScopes();
    this.setState({
      isLoading: false,
      customStyles: {
        RoleName: "",
        Roles: [],
      },
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.isActive)
      if (prevProps.isActive != this.props.isActive && this.props.isActive) {
        this.resetContent();
      }
  }

  render() {
    const { isActive, handleToggle, storeCategories, modules } = this.props;
    const { isLoading, scopes } = this.state;
    return (
      <Translation>
        {(t) => (
          <>
            <Modal
              className="modal-dialog-centered"
              isOpen={isActive}
              //size="lg"
              // scrollable={true}
            >
              <div className="modal-header">
                <h5 className="modal-title" id="CreateRoleModal">
                  {t("TITLE_MODAL_CREATE_ROLE")}
                </h5>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  disabled={this.state.isLoading}
                  onClick={() => handleToggle()}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body">
                <Container>
                  <Row>
                    <label
                      className="form-control-label"
                      htmlFor="validationCustomRoleName"
                    >
                      {t("TITLE_MODAL_CREATE_ROLE_ROLE_NAME")}
                    </label>
                    <Input
                      //innerRef={(input) => { setTimeout(() => { try { input.focus() } catch (error) { } }, 300); }}
                      autoFocus
                      aria-describedby="inputGroupPrepend"
                      placeholder={t("TITLE_MODAL_CREATE_ROLE_ROLE_NAME")}
                      type="text"
                      defaultValue={this.state.customStyles.RoleName}
                      id="validationCustomRoleName"
                      onKeyDown={this.onKeyDown.bind(this)}
                      valid={this.state.customStyles.RoleNameState === "valid"}
                      invalid={
                        this.state.customStyles.RoleNameState === "invalid"
                      }
                      disabled={isLoading}
                      onChange={(e) => this.customStylesForm(e, "RoleName")}
                    />

                    <div className="invalid-feedback">
                      {t("MODAL_CREATE_ROLE_ROLENAME_INVALID")}
                    </div>
                  </Row>

                  <Row style={{ marginTop: "10px", flexDirection: "column" }}>
                    <label
                      className="form-control-label"
                      htmlFor="validationCustomRoles"
                    >
                      {t("TITLE_MODAL_CREATE_ROLE_ROLES")}
                    </label>
                    <MultiCascader
                      onChange={(value) => {
                        let _cs = this.state.customStyles;
                        _cs.Roles = value;
                         if (value.length === 0) {
                          _cs.RolesState = "invalid";
                        } else {
                          _cs.RolesState = "valid";
                        }
                        this.setState({ customStyles: _cs });
                      }}
                      //  inline
                      placeholder={t("MODAL_CREATE_ROLE_ROLESY_INVALID")}
                      block
                      uncheckableItemValues={[
                        ...scopes.map((item, key) => {
                          return item.moduleName;
                        }),
                      ]}
                      data={[
                        ...scopes.map((item, key) => {
                          return {
                            value: item.moduleName,
                            label: item.moduleName,
                            children: [
                              ...item.scopes.map((ii, kk) => ({
                                value: ii.long,
                                label: ii.short,
                                data: ii,
                              })),
                            ],
                          };
                        }),
                      ]}
                      cleanable={false}
                    />
                  </Row>
                </Container>
              </div>
              <div className="modal-footer">
                <Button
                  size={"sm"}
                  data-dismiss="modal"
                  disabled={this.state.isLoading}
                  type="button"
                  onClick={() => handleToggle()}
                >
                  {t("BUTTON_CANCEL")}
                </Button>

                <Button
                  loading={this.state.isLoading}
                  appearance="primary"
                  size={"sm"}
                  type="button"
                  onClick={() => this.validateCustomStylesForm()}
                >
                  {t("BUTTON_CREATE")}
                </Button>

                {/*
                                          <CButton
                                    color="primary"
                                    size={"sm"}
                                    loading={this.state.loading}
                                    disabled={(this.state.loading || (this.state.customStyles.nameState == "invalid"))}
                                    value={data ? t("BUTTON_UPDATE") : t("BUTTON_CREATE")}
                                    onClick={() => { this.validateCustomStylesForm() }}>
                                </CButton>
                                    */}
              </div>
              {this.state.alert}
            </Modal>
          </>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer } = state;
  const { workspace, workspaces, selectedService, storeCategories, modules } =
    workspaceReducer;

  return {
    workspace,
    workspaces,
    selectedService,
    storeCategories,
    modules,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateRole);
