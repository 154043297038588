import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import { ErpEngineService, TransactionService } from "_services";

import i18n from "i18n";

import DynamicTable from "../DynamicTable";
import FormGenerator from "../FormGenerator";

import {
  Modal,
  Icon,
  Tooltip,
  Popover,
  Whisper,
  Loading,
  Button,
  Alert,
  Nav,
} from "rsuite";

class ListItemOperationsCustom extends Component {
  constructor(props) {
    super(props);

    this.state = {
      listdata: [],
      loading: false,
      itemOperation: "update",
    };
  }

  handleUpdateOperation = (value) => {
    this.setState({
      itemOperation: value,
    });
  };
  componentDidUpdate(prevProps, prevState) {
    const { isActive } = this.props;
    if (isActive && isActive !== prevProps.isActive) {
      //  this.findTrans();
    }
  }

  render() {
    const {
      isActive,
      handleToggle,
      isListDetail,
      handleSelect,
      selectedItem,
      isRefModelForm,
      refModel,
      selectedService,
    } = this.props;
    const that = this;

    if (isActive) {
      debugger;
    }
    return (
      <Translation>
        {(t) => (
          <>
            <Modal full size={"md"} show={isActive} onHide={handleToggle}>
              <Modal.Header>
                <Modal.Title>
                  {
                    <Nav
                      appearance="subtle"
                      activeKey={this.state.itemOperation}
                      onSelect={(eventKey) =>
                        this.handleUpdateOperation(eventKey)
                      }
                    >
                      <Nav.Item eventKey="update">
                        {t("BUTTON_UPDATE")}
                      </Nav.Item>
                      <Nav.Item eventKey="delete">
                        {t("BUTTON_DELETE")}
                      </Nav.Item>
                    </Nav>
                  }
                </Modal.Title>
              </Modal.Header>
              <Modal.Body
                className="modal-lio-body"
                style={{ paddingBottom: 0 }}
              >
                {
                  //
                  <FormGenerator
                    //key={this.state.itemOperation}
                    isShowTransaction={true}
                    isListDetail={true}
                    isRefModelForm={true}
                    refModel={refModel}
                    itemOperation={this.state.itemOperation}
                    currentValues={selectedItem}
                    readOnly={this.state.itemOperation == "delete"}
                    currentValuesOrj={selectedItem}
                    onSubmit={this.props.handleUpdate}
                    canUpdateDelete={true}
                  />
                }
              </Modal.Body>
              {/*
                <Modal.Footer>
                <Button onClick={handleToggle} appearance="primary">
                 {t("BUTTON_OK")}
                </Button>
                <Button onClick={handleToggle} appearance="subtle">
                  {t("BUTTON_CANCEL")}
                </Button>
              </Modal.Footer>
                */}
            </Modal>
          </>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer } = state;
  const {
    model,
    models,
    transaction,
    transactions,
    workspace,
    dataModels,
    selectedService,
  } = workspaceReducer;

  return {
    workspace,
    transaction,
    transactions,
    dataModels,
    models,
    model,
    selectedService,
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListItemOperationsCustom);
