import i18n from "i18n";



import Workspace from "views/Workspaces/index";
import Main from "views/Workspaces/Main";

import Settings from "views/Settings/index";
import Packages from "views/Packages/index";

const routes = [
  /*
  {
    collapse: true,
    name: i18n.t("PAGE_NAME_COLLECTIONS"),
    icon: "fas fa-database text-primary",
    state: "staticCollapse",
    views: [
      {
        path: "static-collections",
        name: i18n.t("PAGE_NAME_STATICCOLLECTIONS"),
        miniName:(i18n.t("PAGE_NAME_STATICCOLLECTIONS")).charAt(0).toUpperCase(),
        component: StaticCollection,
        layout: "/"
      },
      {
        path: "dynamic-collections",
        name: i18n.t("PAGE_NAME_DYNAMICCOLLECTIONS"),
        miniName:(i18n.t("PAGE_NAME_DYNAMICCOLLECTIONS")).charAt(0).toUpperCase(),
        component: DynamicCollection,
        layout: "/"
      },
      {
        path: "data-models",
        name: i18n.t("PAGE_NAME_DYNAMICDATAMODELS"),
        miniName:(i18n.t("PAGE_NAME_DYNAMICDATAMODELS")).charAt(0).toUpperCase(),
        component: DynamicDataModel,
        layout: "/"
      },
    
    ]
  },
  */



  {
    path: "workspaces",
    name: i18n.t("PAGE_NANE_WORKSPACES"),
    icon: "fas fa-desktop",
    component: Main,
    layout: "/",
  },
  {
    path: "packages",
    name: i18n.t("PAGE_NANE_PACKAGES"),
    icon: "fas fa-cube",
    component: Packages,
    layout: "/",
  },
  {
    path: "settings",
    name: i18n.t("PAGE_NANE_SETTINGS"),
    icon: "fas fa-cog",
    component: Settings,
    layout: "/",
  },
  {
    path: "workspace",
    name: i18n.t("PAGE_NANE_WORKSPACES"),
    icon: "fas fa-desktop",
    component: Workspace,
    layout: "/",
    hidden: true,
  },

];

export default routes;