import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import Emitter from "_actions/emitter";
import i18n from "i18n";
import { ResponseStatusCode } from "_helpers";
import { ModelsService } from "_services";

import { Button, Alert, SelectPicker, CheckTreePicker, Icon } from "rsuite";

import { Input, Modal, Container, Row, Col } from "reactstrap";

import {
  updateServices,
  updateSelectedService,
  updatedModels,
  updateSelectedModel,
} from "_actions";

class CreateEditModelExtraButtons extends Component {
  constructor(props) {
    super(props);
    debugger;
    this.state = {
      customStyles: {
        ButtonCaptions: props.refData
          ? props.refData.buttonCaptions[0].textValue
          : "",
        RelTransactions: props.refData
          ? props.refData.relTransactions.id
          : null,
        RunType: props.refData ? props.refData.runType : "Modal",
      },
      loading: false,
      counter: 0,
    };
  }
  handleCloseModal = () => {
    const { handleToggle } = this.props;
    handleToggle();
  };

  handleSubmit = (e) => {
    const that = this;
    const _cData = this.state.customStyles;
    const { refData, selectedService, allServices, allTransactions } =
      this.props;

    const _rTrans = allServices.find((x) => x.id == _cData.RelTransactions);
    const _rModel = allTransactions.find(
      (x) => x.model.id == _rTrans.relBaseModelId
    );
    const _cTrans = _rModel.transActions.find(
      (x) => x.id == _cData.RelTransactions
    );

    if (_cTrans) {
      let nData = {
        ButtonCaptions: [
          {
            lang: "en",
            textValue: _cData.ButtonCaptions,
          },
          {
            lang: "de",
            textValue: _cData.ButtonCaptions,
          },
          {
            lang: "tr",
            textValue: _cData.ButtonCaptions,
          },
        ],
        RelTransactions: _cTrans,
        RunType: _cData.RunType,
      };
      const _eb = allTransactions.find(
        (x) => x.model.id == this.props.selectedService.reqModelId
      );

      let _myExtras = _eb.model.hasOwnProperty("extraButtons")
        ? [..._eb.model.extraButtons]
        : [];

      if (refData) {
        _myExtras[refData.key] = nData;
      } else {
        _myExtras.push(nData);
      }
      debugger;
      this.handleSaveModel(_myExtras);
    } else {
      debugger;
    }

    // const { formValue, formError } = this.state;

    //this.props.handleUpdateRow(this.state.formValue);
    //this.handleCloseModal();
  };

  handleSaveModel = (_extras) => {
    const { selectedService, isRefModel, handleToggleAll } = this.props;

    const that = this;

 
    const extraButtons = _extras;
 
    that.setState({
      loading: true,
    });
    debugger;
    ModelsService.SET_EXTRA_BUTTON(
      selectedService[isRefModel ? "id" : "relBaseModelId"],
      selectedService.relModuleId,
      selectedService.relWorkSpaceId,
      extraButtons,
    )
      .then((data) => {
        that.setState({ loading: false });
        if (data.operationResult) {
          //      that.saveTrans();
          Emitter.emit("UPDATE_ALL_TRANS", null);
          handleToggleAll();
        }
      })
      .catch((err) => {
        Alert.error("Error code : " + err.statusCode);
        that.setState({ loading: false });
      });
  };

  customStylesForm = (e, stateName) => {
    let newState = this.state.customStyles;
    newState[stateName] = e.target.value;

    if (stateName === "checkbox") {
      if (e.target.value) {
        newState.checkboxState = "valid";
      } else {
        newState.checkboxState = "invalid";
      }
    } else {
      if (e.target.value === "") {
        newState[stateName + "State"] = "invalid";
      } else {
        newState[stateName + "State"] = "valid";
      }
    }
    this.setState({
      customStyles: newState,
    });
  };

  onKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      this.validateCustomStylesForm();
    }
  };

  validateCustomStylesForm = () => {
    let newState = this.state.customStyles;
    newState.ButtonCaptions = newState.ButtonCaptions.trim();

    var regex = /^.{1,50}$/;

    if (newState.ButtonCaptions === "") {
      newState.ButtonCaptionsState = "invalid";
    } else if (!regex.test(newState.ButtonCaptions)) {
      newState.ButtonCaptionsState = "invalid";
    } else {
      newState.ButtonCaptionsState = "valid";
    }
    debugger

    if (!newState.RelTransactions) {
      newState.RelTransactionsState = "invalid";
      Alert.error(i18n.t("TITLE_MODAL_CREATE_EXTRABUTTON_TRANS_INVALIDE"));
    } else {
      newState.RelTransactionsState = "valid";
    }

    this.setState(
      {
        customStyles: newState,
      },
      () => {
        if (
          newState.ButtonCaptionsState == "valid" &&
          newState.RelTransactionsState == "valid"
        ) {
          this.handleSubmit();
        }
      }
    );
  };

  handleDelete = () => {
    const { allTransactions, refData } = this.props;
    const _eb = allTransactions.find(
      (x) => x.model.id == this.props.selectedService.reqModelId
    );

    let _myExtras = _eb.model.hasOwnProperty("extraButtons")
      ? [..._eb.model.extraButtons]
      : [];

    if (refData) {
      _myExtras.splice(refData.key, 1);
      this.handleSaveModel(_myExtras);
    }
  };

  render() {
    const { isActive, handleToggle, selectedService, allServices, refData } =
      this.props;
    const { loading } = this.state;

    return (
      <Translation>
        {(t) => (
          <>
            <Modal
              className="modal-dialog-centered"
              isOpen={isActive}
              //size="lg"
              //toggle={this.handleCloseModal}
              scrollable={true}
            >
              <div className="modal-body" style={{ padding: 0 }}>
                <div className="modal-header">
                  <h5 className="modal-title" id="ShowModelExtra">
                    {t(
                      refData
                        ? "TITLE_MODAL_MODEL_EXTRAS_EDIT"
                        : "TITLE_MODAL_MODEL_EXTRAS_CREATE"
                    )}
                  </h5>
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => this.handleCloseModal()}
                  >
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
              </div>
              <div className="modal-body">
                <Container>
                  <Row>
                    <label
                      className="form-control-label-name"
                      htmlFor="validationCustomButtonCaptions"
                    >
                      {t("TITLE_MODAL_CREATE_EXTRABUTTON_NAME")}
                    </label>
                    <Input
                      //innerRef={(input) => { setTimeout(() => { try { input.focus() } catch (error) { } }, 300); }}
                      autoFocus
                      aria-describedby="inputGroupPrepend"
                      placeholder={t("TITLE_MODAL_CREATE_EXTRABUTTON_NAME")}
                      type="text"
                      defaultValue={this.state.customStyles.ButtonCaptions}
                      id="validationCustomButtonCaptions"
                      onKeyDown={this.onKeyDown.bind(this)}
                      valid={
                        this.state.customStyles.ButtonCaptionsState === "valid"
                      }
                      invalid={
                        this.state.customStyles.ButtonCaptionsState ===
                        "invalid"
                      }
                      disabled={loading}
                      onChange={(e) =>
                        this.customStylesForm(e, "ButtonCaptions")
                      }
                    />

                    <div className="invalid-feedback">
                      {t("TITLE_MODAL_CREATE_EXTRABUTTON_NAME_INVALIDE")}
                    </div>
                  </Row>

                  <Row style={{ marginTop: "10px", flexDirection: "column" }}>
                    <label
                      className="form-control-label"
                      htmlFor="validationCustomTrans"
                    >
                      {t("TITLE_MODAL_CREATE_EXTRABUTTON_TRANS")}
                    </label>
                    <SelectPicker
                      onChange={(value) => {
                        let _cs = this.state.customStyles;
                        _cs.RelTransactions = value;
                        _cs.RelTransactionsState = "valid";
                        this.setState({ customStyles: _cs });
                      }}
                      //  inline
                      placement="autoVerticalStart"
                      placeholder={t("TITLE_MODAL_CREATE_EXTRABUTTON_TRANS")}
                      block
                      data={[
                        ...allServices.map((item, key) => {
                          return {
                            value: item.id,
                            label:
                              item.shortName.length > 0
                                ? "(" + item.shortName + ") - " + item.name
                                : item.name,
                          };
                        }),
                      ]}
                      cleanable={false}
                      defaultValue={this.state.customStyles.RelTransactions}
                    />
                  </Row>
                  <Row style={{ marginTop: "10px", flexDirection: "column" }}>
                    <label
                      className="form-control-label"
                      htmlFor="validationCustomTrans"
                    >
                      {t("TITLE_MODAL_CREATE_EXTRABUTTON_RUN_TYPE")}
                    </label>
                    <SelectPicker
                      onChange={(value) => {
                        let _cs = this.state.customStyles;
                        _cs.RunType = value;
                        _cs.RunTypeState = "valid";
                        this.setState({ customStyles: _cs });
                      }}
                      //  inline
                      defaultValue={
                        this.props.refData
                          ? this.props.refData.runType
                          : "Modal"
                      }
                      placement="autoVerticalStart"
                      placeholder={t("TITLE_MODAL_CREATE_EXTRABUTTON_RUN_TYPE")}
                      block
                      data={[
                        { value: "Modal", label: "Modal" },
                        { value: "Current", label: "Current" },
                        { value: "CurrentBlob", label: "Blob" },
                        {
                          value: "TableActionModal",
                          label: "Table Action Modal",
                        },
                        {
                          value: "TableActionModalOnLoad",
                          label: "Table Action Modal (onLoad)",
                        },
                        {
                          value: "TableActionCurrent",
                          label: "Table Action Current",
                        },
                      ]}
                      cleanable={false}
                    />
                  </Row>
                </Container>
              </div>
              <div className="modal-footer">
                <Button
                  size={"sm"}
                  data-dismiss="modal"
                  disabled={loading}
                  type="button"
                  onClick={() => handleToggle()}
                >
                  {t("BUTTON_CANCEL")}
                </Button>

                {refData && (
                  <Button
                    loading={loading}
                    appearance="ghost"
                    color="red"
                    size={"sm"}
                    type="button"
                    onClick={() => this.handleDelete()}
                  >
                    {t("BUTTON_DELETE")}
                  </Button>
                )}

                <Button
                  loading={loading}
                  appearance="primary"
                  size={"sm"}
                  type="button"
                  onClick={() => this.validateCustomStylesForm()}
                >
                  {t(refData ? "BUTTON_EDIT" : "BUTTON_CREATE")}
                </Button>
              </div>
            </Modal>
          </>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer } = state;
  const {
    workspace,
    selectedService,
    dataModels,
    modules,
    module,
    models,
    allTransactions,
    allServices,
  } = workspaceReducer;

  return {
    workspace,
    selectedService,
    dataModels,
    modules,
    module,
    models,
    allTransactions,
    allServices,
  };
};
const mapDispatchToProps = {
  updateServices,
  updateSelectedService,
  updatedModels,
  updateSelectedModel,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateEditModelExtraButtons);
