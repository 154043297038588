import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import i18n from "i18n";
import { decamelize } from "assets/globalOps/global";

import { Modal, Container, Row, Col, Card, CardBody } from "reactstrap";

import CDataModelCard from "components/Custom/CDataModelCard";
import GlobalDataModels from "./GlobalDataModels";
import { CMSService } from "_services";
import {} from "_actions";
import { Toggle, Steps, Panel, ButtonGroup } from "rsuite";
import {
  Table,
  Form,
  Loader,
  FormGroup,
  FormControl,
  ControlLabel,
  Button,
  Schema,
  ButtonToolbar,
  Alert,
} from "rsuite";
import CCell from "components/Custom/CCell";
import CTable from "components/Custom/CTable";

const { StringType, NumberType, ArrayType } = Schema.Types;
const { Column, HeaderCell, Cell, Pagination } = Table;

// <HelpBlock>{t("ERROR_DM_ARRAY_REQUIRED")}</HelpBlock>

const model = Schema.Model({
  collectionName: StringType()
    .minLength(3, i18n.t("ERROR_MESSAGE_COLLECTION_NAME_MIN3"))
    .maxLength(50, i18n.t("ERROR_MESSAGE_COLLECTION_NAME_MAX50"))
    .isRequired(i18n.t("ERROR_MESSAGE_COLLECTION_NAME_REQUIRED"))
    .containsLetterOnly(i18n.t("ERROR_MESSAGE_COLLECTION_NAME_TRIM")),
});

let DMModel = Schema.Model({
  name: StringType()
    .isRequired(i18n.t("ERROR_DM_GLOBAL_REQUIRED"))
    .pattern(/^[A-Za-z][A-Za-z_]/, i18n.t("ERROR_DM_GLOBAL_PATTERN"))
    .minLength(2, i18n.t("ERROR_DM_GLOBAL_MIN2_LENGTH"))
    .maxLength(30, i18n.t("ERROR_DM_GLOBAL_MAX30_LENGTH")),
  displayName: StringType()
    .isRequired(i18n.t("ERROR_DM_GLOBAL_REQUIRED"))
    .minLength(2, i18n.t("ERROR_DM_GLOBAL_MIN2_LENGTH"))
    .maxLength(30, i18n.t("ERROR_DM_GLOBAL_MAX30_LENGTH")),
  contentType: StringType().isRequired(i18n.t("ERROR_DM_GLOBAL_REQUIRED_TYPE")),
});

let DMModel2 = Schema.Model({
  name: StringType()
    .isRequired(i18n.t("ERROR_DM_GLOBAL_REQUIRED"))
    .pattern(/^[A-Za-z][A-Za-z_]/, i18n.t("ERROR_DM_GLOBAL_PATTERN"))
    .minLength(2, i18n.t("ERROR_DM_GLOBAL_MIN2_LENGTH"))
    .maxLength(30, i18n.t("ERROR_DM_GLOBAL_MAX30_LENGTH")),
  displayName: StringType()
    .isRequired(i18n.t("ERROR_DM_GLOBAL_REQUIRED"))
    .minLength(2, i18n.t("ERROR_DM_GLOBAL_MIN2_LENGTH"))
    .maxLength(30, i18n.t("ERROR_DM_GLOBAL_MAX30_LENGTH")),
  contentType: StringType().isRequired(i18n.t("ERROR_DM_GLOBAL_REQUIRED_TYPE")),
  typeDetail: StringType().isRequired(i18n.t("ERROR_DM_GLOBAL_REQUIRED_TYPE")),
});

class CreateDataModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      __update: 0,
      formValue: {
        collectionName: "",
        dataTypes: [],
        isDatabaseObject: true,
      },
      globalCollections: {
        lastIndexId: "-1",
        dynamicValue: [],
      },
      formError: {},
      loading: false,
      step: 0,
      loadingName: false,
      loadingFindGlobal: false,
      isGlobalDataModel: false,
      dataModel: [
        {
          name: "priority",
          editable: true,
          contentType: "string",
          privateField: true,
        },
        {
          name: "name",
          editable: true,
          contentType: "string",
          privateField: false,
        },
        {
          name: "displayName",
          editable: true,
          contentType: "string",
          privateField: false,
        },
        {
          name: "contentType",
          editable: true,
          contentType: "contentTypes",
          privateField: false,
        },
        {
          name: "typeDetail",
          editable: true,
          contentType: "typeDetails",
          privateField: false,
        },
        {
          name: "reqiured",
          editable: true,
          contentType: "boolean",
          privateField: false,
        },
        {
          name: "unique",
          editable: true,
          contentType: "boolean",
          privateField: false,
        },
        {
          name: "notNull",
          editable: true,
          contentType: "boolean",
          privateField: false,
        },
        {
          name: "privateField",
          editable: true,
          contentType: "boolean",
          privateField: false,
        },
      ],
    };
    this.updateSchemaModel();
  }

  updateSchemaModel = () => {
    DMModel.schema.name.addRule((value, data) => {
      return !this.chechHasName(value, this);
    }, i18n.t("ERROR_DM_GLOBAL_SAME_NAME"));
    DMModel2.schema.name.addRule((value, data) => {
      return !this.chechHasName(value, this);
    }, i18n.t("ERROR_DM_GLOBAL_SAME_NAME"));
  };

  componentDidUpdate(prevProps, prevState) {
    //handleCloseModal

    if (this.props.isActive !== prevProps.isActive && this.props.isActive) {
      this.handleCloseModal(true);
    }
  }

  handleSetDataType = (_data) => {
    const _new = this.state.formValue;
    _new.dataTypes = _data.dataTypes;
    this.setState({
      formValue: _new,
    });
  };
  handleShowGlobalDM = () => {
    this.setState({
      isGlobalDataModel: !this.state.isGlobalDataModel,
    });
  };
  onChangeStep = (nextStep) => {
    // const { step } = this.state;
    this.setState({
      step: nextStep < 0 ? 0 : nextStep > 2 ? 2 : nextStep,
    });
  };
  onNext = () => {
    this.setState({
      loading: true,
      loadingName: this.state.step === 0,
    });
    if (this.state.step === 0) {
      //check name

      if (this.handleCheck()) {
        this.CHECK_NAME();
      }
    } else if (this.state.step === 1) {
      this.setState({ loading: false });
      const { formValue } = this.state;
      if (formValue.dataTypes.length > 0) {
        this.onChangeStep(this.state.step + 1);
      } else {
        Alert.error(i18n.t("ERROR_DM_ARRAY_REQUIRED"));
      }
    } else if (this.state.step === 2) {
      debugger;
    }
  };
  onPrevious = () => {
    if (this.state.step === 1) {
      this.setState({
        globalCollections: {
          lastIndexId: "-1",
          dynamicValue: [],
        },
      });
    }
    this.onChangeStep(this.state.step - 1);
  };

  onCreate = () => {
    const { formValue } = this.state;
    //    debugger
    //   return
    const { handleCreateDM } = this.props;
    if (this.handleCheck()) {
      handleCreateDM(formValue);
      // this.handleCloseModal();
    } else {
      debugger;
    }
  };
  handleCheck = (e) => {
    const { formValue } = this.state;
    if (!this.form.check()) {
      //  console.error('Form Error');
      this.setState({
        loading: false,
        loadingName: false,
        loadingFindGlobal: false,
      });
      return false;
    }
    return true;
  };
  CHECK_NAME = () => {
    const that = this;
    const { formValue } = this.state;
    const { module, DB } = this.props;
    //debugger
    this.setState({ loading: true });
    that.onChangeStep(that.state.step + 1);
    that.setState({ loadingName: false, loading: false }); //loadingFindGlobal: true,
  };

  handleCloseModal = (isClear) => {
    const { handleToggle } = this.props;
    this.setState(
      {
        formValue: {
          collectionName: "",
          dataTypes: [],
          isDatabaseObject: true,
        },
        globalCollections: {
          lastIndexId: "-1",
          dynamicValue: [],
        },
        formError: {},
        loading: false,
        step: 0,
        loadingName: false,
        loadingFindGlobal: false,
      },
      () => {
        if (!isClear) handleToggle();
      }
    );
  };

  handleUpdateRow = (rowData, _index) => {
    const { formValue } = this.state;
    //formValue.dataTypes
    /*
               if (!uniqData) {
                   Alert.error("Uniq data belirtilmeli")
                   return
               }
               */
    // const uniqData = "name";
    // let cIndex = formValue.dataTypes.findIndex(x => x[uniqData] == rowData[uniqData]);

    if (_index != -1) {
      formValue.dataTypes[_index] = rowData;
      this.setState({
        formValue: formValue,
      });
    }
  };

  chechHasName = (name, that) => {
    let formValue = JSON.parse(JSON.stringify(that.state.formValue));
    let result = false;
    formValue.dataTypes.forEach((element) => {
      if (element.name.toLocaleLowerCase() === name.toLocaleLowerCase()) {
        result = true;
      }
    });
    return result;
  };

  handleCloneRow = (rowData) => {
    const that = this;
    let _rData = Object.assign({}, rowData);
    _rData.name = "C" + _rData.name;
    debugger;
    if (this.chechHasName(_rData.name, that)) {
      this.handleCloneRow(_rData);
    } else {
      this.handleAddNewRow(_rData);
    }
  };

  handleDeleteRow = (rowData, _index) => {
    const { formValue } = this.state;
    if (_index != -1) {
      formValue.dataTypes.splice(_index, 1);
      this.setState({
        formValue: formValue,
      });
    }
  };

  handleAddNewRow = (_newData) => {
    const that = this;
    let formValue = JSON.parse(JSON.stringify(this.state.formValue));
    formValue.dataTypes.push(_newData);
    this.setState({
      formValue: formValue,
      __update: this.state.__update + 1,
    });
    //   debugger
  };

  render() {
    const { isActive, handleToggle } = this.props;
    const {
      formValue,
      formError,
      loading,
      loadingName,
      loadingFindGlobal,
      step,
      globalCollections,
    } = this.state;
    return (
      <Translation>
        {(t) => (
          <Modal
            className="modal-dialog-centered stepper-modal"
            isOpen={isActive}
            size={step == 0 ? "md" : "xl"}
            //toggle={this.handleCloseModal}
            scrollable={true}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="CheckProjectModal">
                {t("TITLE_CREATE_DATA_MODEL_MODAL")}
              </h5>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => this.handleCloseModal()}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body" style={{ padding: 0 }}>
              <Container>
                <Row>
                  <Col style={{ marginBottom: "15px", padding: "0px 1.25rem" }}>
                    <div>
                      <Steps current={step}>
                        <Steps.Item
                          icon={loadingName ? <Loader /> : null}
                          title="Name"
                        />
                        <Steps.Item title="Data model" />
                        <Steps.Item title="Preview" />
                      </Steps>
                      <hr />
                      <Panel>
                        {step == 0 ? (
                          <Form
                            ref={(ref) => (this.form = ref)}
                            onChange={(formValue) => {
                              this.setState({ formValue });
                            }}
                            onCheck={(formError) => {
                              this.setState({ formError });
                            }}
                            style={{ paddingBottom: "30px" }}
                            formValue={formValue}
                            model={model}
                            fluid
                          >
                            <FormGroup>
                              <ControlLabel>
                                {t("TITLE_COLLECTION_NAME")}
                              </ControlLabel>
                              <FormControl
                                disabled={loading}
                                name="collectionName"
                                placeholder={t("TITLE_COLLECTION_NAME")}
                              />
                            </FormGroup>
                          </Form>
                        ) : step == 1 ? (
                          <>
                            {loadingFindGlobal && <Loader backdrop vertical />}
                            <ControlLabel>
                              {t("TITLE_DATATYPE")}
                              {formValue["collectionName"]
                                ? " (" + formValue["collectionName"] + ") "
                                : ""}
                            </ControlLabel>
                            <CTable
                              collectionName={formValue["collectionName"]}
                              isDeletable={true}
                              isCreatable={true}
                              isClonable={true}
                              isEditable={true}
                              isAction={true}
                              __update={this.state.__update}
                              schemaModel={DMModel}
                              addRowData={this.handleAddNewRow}
                              schemaModel2={DMModel2}
                              cloneData={this.handleCloneRow}
                              updateData={this.handleUpdateRow}
                              deleteData={this.handleDeleteRow}
                              data={formValue.dataTypes}
                              dataModel={this.state.dataModel}
                              isCreateDB={true}
                            />
                            <GlobalDataModels
                              setDataModel={this.handleSetDataType}
                              data={globalCollections.dynamicValue}
                              isActive={this.state.isGlobalDataModel}
                              handleToggle={this.handleShowGlobalDM}
                            />
                          </>
                        ) : (
                          <>
                            <CTable
                              collectionName={formValue["collectionName"]}
                              isDeletable={false}
                              isCreatable={false}
                              isAction={false}
                              data={formValue.dataTypes}
                              dataModel={this.state.dataModel}
                            />
                            <Form
                              ref={(ref) => (this.form = ref)}
                              onChange={(formValue) => {
                                this.setState({ formValue });
                              }}
                              onCheck={(formError) => {
                                this.setState({ formError });
                              }}
                              style={{
                                paddingTop: "30px",
                                paddingBottom: "30px",
                              }}
                              formValue={formValue}
                              model={model}
                              fluid
                            >
                              <FormGroup>
                                <ControlLabel>
                                  {t("TITLE_ASK_IS_DB_OBJECT")}
                                </ControlLabel>
                                <FormControl
                                  accepter={Toggle}
                                  defaultChecked
                                  disabled={loading}
                                  name="isDatabaseObject"
                                />
                              </FormGroup>

                              {!formValue["isDatabaseObject"] && (
                                <FormGroup>
                                  <ControlLabel>
                                    {t("TITLE_ASK_IS_ALLOW_TRASACTION")}
                                  </ControlLabel>
                                  <FormControl
                                    accepter={Toggle}
                                    disabled={loading}
                                    name="isDataBaseFalseObjectAllowTransaction"
                                  />
                                </FormGroup>
                              )}
                            </Form>
                          </>
                        )}
                      </Panel>

                      <div className={"custom-modal-footer"}>
                        <ButtonGroup>
                          <Button
                            onClick={this.onPrevious}
                            disabled={step === 0}
                          >
                            {t("BUTTON_PREV")}
                          </Button>
                          <Button
                            loading={loading}
                            disabled={loading || loadingName}
                            appearance={step == 2 ? "primary" : "default"}
                            onClick={step == 2 ? this.onCreate : this.onNext}
                          >
                            {step == 2 ? t("BUTTON_CREATE") : t("BUTTON_NEXT")}
                          </Button>
                        </ButtonGroup>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </Modal>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer } = state;
  const {
    workspaces,
    models,
    model,
    workspace,
    selectedService,
    services,
    modules,
    module,
  } = workspaceReducer;

  return {
    workspaces,
    workspace,
    modules,
    module,
    selectedService,
    services,
    models,
    model,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CreateDataModel);
