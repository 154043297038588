//connection bilgileri burada dir.
//import socketIOClient from "socket.io-client";

import { EnCryptForHandShake } from "./external";
import { GetErpEngineSocketURL } from "_services/_serviceSettings";
import { SocketOperations } from "_constants";
import Emitter from "_actions/emitter";

const io = require("socket.io-client");
//import { SocketOperationsLocal } from "_constants";
var socket = null;
var prop = null;
var PingLoopIsSet = false;
var WorkspaceClone = null;
export function PrapareSocket(refOBJ, _workspace) {
  var token = {
    token: "ananinamiankaragucu",
  };

  socket = io("https://" + _workspace.dev_URL, {
    transports: ["websocket", "polling"],
    query: token,
  });
  WorkspaceClone = _workspace;

  socket.on("securequestion", securequestionAnswer);
  socket.on("giveMeToken", sendToken);
  socket.on("Pong", Pong);

  socket.on("basicEmit", basicEmit);

  socket.on("disconnect", () => {
    // console.log(socket.id); // undefined
    DisConnected();
  });

  refOBJ(socket);

  if (PingLoopIsSet === false) PingLoop();
}

function PingLoop() {
  console.log("PingLoop");
  PingLoopIsSet = true;
  setInterval(() => {
    socket.emit("Ping");
  }, 15000);
}

function basicEmit(_SocketOperation, Data) {
  console.log(`basicEmit ${_SocketOperation}`);
  switch (_SocketOperation) {
    case SocketOperationLocal.BasicEmit:
      console.log(`hii`);
      break;
    case SocketOperationLocal.GiveToken:
      sendToken();
      sendObServe();
      break;
    case SocketOperationLocal.TerminalGuids:
      TerminalListHasBeenUpdated(Data);
      break;
    case SocketOperationLocal.TerminalResponse:
      TerminalResponse(Data);
      break;
    case SocketOperationLocal.ConsoleResponse:
      ConsoleResponse(Data);
      break;
    case SocketOperationLocal.Connected:
      Connected();
      break;
    case SocketOperationLocal.GitServerAliveStatus:
      GitStatus(Data);
      break;

    case SocketOperationLocal.HTTP_LOG_OBSERVE:
      HTTP_LOG_OBSERVE(Data);
      break;
    default:
      break;
  }
}

function GitStatus(data) {
  Emitter.emit(SocketOperationLocal.GitServerAliveStatus, data);
}

function HTTP_LOG_OBSERVE(data) {
  Emitter.emit(SocketOperationLocal.HTTP_LOG_OBSERVE, data);
}

function Pong(data) {
  console.log(`pong ${data}`);
}

function DisConnected() {
  Emitter.emit(SocketOperationLocal.DisConnected, false);
}

function Connected() {
  Emitter.emit(SocketOperationLocal.Connected, true);
}
function ConsoleResponse(data) {
  Emitter.emit(SocketOperationLocal.ConsoleResponse, data);
  Emitter.emit("TerminalResponse2", data);
}
function TerminalResponse(data) {
  Emitter.emit(SocketOperationLocal.TerminalResponse, data);
  //Emitter.emit("TerminalResponse2",data);
}
function TerminalListHasBeenUpdated(data) {
  console.log("TerminalListHasBeenUpdated");
  //console.log(`terminal list ${data}`);
  Emitter.emit(SocketOperationLocal.TerminalGuids, data);
  //debugger;
}

function sendToken() {
  var token =
    localStorage.getItem("user") &&
    JSON.parse(localStorage.getItem("user")).token;
  let data = {
    token: token,
    workspaceId: WorkspaceClone.id,
  };
  socket.emit("giveMeToken", data);
}

function sendObServe() {
  setTimeout(() => {
    socket.emit("JoinDEV_HTTP_LOG_BUILD");
  }, 2500);
}

function securequestionAnswer(data, callback) {
  let obj = JSON.parse(data);
  var calc = EnCryptForHandShake(obj.data.Request);
  console.log(calc);
  callback({
    Response: calc,
  });
  /*
  socket.emit("securequestion", {
    Response: calc
  });
  */
}

export function GetSocket() {
  return socket;
}

export const SocketOperationLocal = {
  None: 9999,
  BasicEmit: 10000,
  TerminalResponse: 10001,
  ConsoleResponse: 10002,
  GiveToken: 10003,
  TerminalGuids: 10004,
  Connected: 10005,
  DisConnected: 10006,
  GitServerAliveStatus: 10007,
  HTTP_LOG_OBSERVE: 10008,
};
