import {
  //ServiceURLs,
  EnvironmentsEndpoints,
  GetBaseERPURL,
  MakeReqestAndGetResponse,
  GetUserLang,
} from "./index";
import { HTTPOperationType } from "./httpOperations";

export const EnvironmentsService = {
  GET_ALL_ENVIRONMENTS,
  UPDATE_ENVIRONMENTS,
  CREATE,
  REMOVE,
  GET_ALL_ENVIRONMENTS_FROM_MODULE,
};

function GET_ALL_ENVIRONMENTS(RelWorkspaceId) {
  var js = {
    RelWorkspaceId: RelWorkspaceId,
  };

  return MakeReqestAndGetResponse(
    GetBaseERPURL() + EnvironmentsEndpoints.GET_ALL_ENVIRONMENTS,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}
function UPDATE_ENVIRONMENTS(id, RelWorkspaceId, RelModuleId, Value) {
  var js = {
    RelWorkspaceId: RelWorkspaceId,
    RelModuleId: RelModuleId,
    Value: Value,
    id: id,
  };
  debugger;
  return MakeReqestAndGetResponse(
    GetBaseERPURL() + EnvironmentsEndpoints.UPDATE_ENVIRONMENTS,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

function CREATE(RelWorkspaceId, RelModuleId, Name, Value) {
  var js = {
    RelWorkspaceId: RelWorkspaceId,
    RelModuleId: RelModuleId,
    Name: Name,
    Value: Value,
  };

  return MakeReqestAndGetResponse(
    GetBaseERPURL() + EnvironmentsEndpoints.CREATE,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

function REMOVE(RelWorkspaceId, RelModuleId, id) {
  var js = {
    RelWorkspaceId: RelWorkspaceId,
    RelModuleId: RelModuleId,
    id: id,
  };

  return MakeReqestAndGetResponse(
    GetBaseERPURL() + EnvironmentsEndpoints.REMOVE,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

function GET_ALL_ENVIRONMENTS_FROM_MODULE(RelWorkspaceId, RelModuleId) {
  var js = {
    RelWorkspaceId: RelWorkspaceId,
    RelModuleId: RelModuleId,
  };

  return MakeReqestAndGetResponse(
    GetBaseERPURL() + EnvironmentsEndpoints.GET_ALL_ENVIRONMENTS_FROM_MODULE,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}
