import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import Emitter from "_actions/emitter";
import i18n from "i18n";
import { ResponseStatusCode } from "_helpers";
import { ModelsService } from "_services";

import { Button, Icon, IconButton } from "rsuite";
import { Input, Modal, Container, Row, Col } from "reactstrap";

import {
  updateServices,
  updateSelectedService,
  updatedModels,
  updateSelectedModel,
} from "_actions";

import CreateEditModelExtraButtons from "./CreateEditModelExtraButtons";

class ShowModelExtraButtons extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      counter: 0,
      isExtraButtons: false,
      refData: null,
    };
  }

  handleCloseModal = () => {
    const { handleToggle } = this.props;
    this.setState(
      {
        loading: false,
        counter: 0,
        isExtraButtons: false,
        refData: null,
      },
      () => {
        handleToggle();
      }
    );
  };

  handleSubmit = (e) => {
    const that = this;
    // const { formValue, formError } = this.state;

    //this.props.handleUpdateRow(this.state.formValue);
    //this.handleCloseModal();
  };

  handleUpdateExtraButtons = (_refData = null) => {
    this.setState({
      isExtraButtons: !this.state.isExtraButtons,
      refData: _refData,
    });
  };

  handleDeleteExtraButton = (item, key) => {
    const { isActive, selectedService, allTransactions } = this.props;
    const mSelected =
      isActive &&
      allTransactions.find((x) => x.model.id == selectedService.reqModelId)
        .model;

    let _eb = mSelected.extraButtons ? [...mSelected.extraButtons] : [];

    debugger;
  };

  render() {
    const {
      isActive,
      handleToggle,
      selectedService,
      isRefModel,
      allTransactions,
    } = this.props;
    const { loading, isExtraButtons, refData } = this.state;
    const mSelected =
      isActive &&
      allTransactions.find((x) => x.model.id == (selectedService.responseModelType
        ? selectedService.reqModelId
        : selectedService.reqModelId))
        .model;

    // if (mSelected) debugger;
    if (isActive) {
      debugger;
    }
    return (
      <Translation>
        {(t) => (
          <>
            <Modal
              className="modal-dialog-centered"
              isOpen={isActive}
              //size="lg"
              //toggle={this.handleCloseModal}
              scrollable={true}
            >
              <div className="modal-body" style={{ padding: 0 }}>
                <div className="modal-header">
                  <div className="modal-header-extra">
                    <div className="modal-header-sub">
                      <h5
                        className="modal-title"
                        id="ShowModelExtra"
                        style={{ marginRight: "1em" }}
                      >
                        {t("TITLE_MODAL_MODEL_EXTRAS")}
                      </h5>
                      <Button
                        loading={loading}
                        appearance="primary"
                        size={"sm"}
                        type="button"
                        onClick={() => this.handleUpdateExtraButtons()}
                      >
                        {t("BUTTON_CREATE")}
                      </Button>
                    </div>

                    <button
                      aria-label="Close"
                      className="close"
                      data-dismiss="modal"
                      type="button"
                      onClick={() => this.handleCloseModal()}
                    >
                      <span aria-hidden={true}>×</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="modal-body" style={{ paddingBottom: "2em" }}>
                {mSelected &&
                  mSelected.extraButtons &&
                  mSelected.extraButtons.map((item, key) => {
                    t("LANG");
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "6px 0",
                          borderBottom: "1px solid rgb(234, 234, 234)",
                        }}
                        key={key}
                      >
                        <span>{item.buttonCaptions[0].textValue}</span>
                        <div>
                          <Button
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                              this.handleUpdateExtraButtons({ ...item, key });
                            }}
                          >
                            {t("BUTTON_EDIT")}
                          </Button>

                          {/*
                           <Button
                          color="red"
                            onClick={() => {
                              this.handleUpdateExtraButtons({ ...item, key });
                            }}
                          >
                            {t("BUTTON_DELETE")}
                          </Button>
                          */}
                        </div>
                      </div>
                    );
                  })}
                {(!mSelected ||
                  !mSelected.hasOwnProperty("extraButtons") ||
                  mSelected.extraButtons.length == 0) && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      fontSize: "14px",
                      color: "#bfbfbf",
                    }}
                  >
                    <Icon
                      style={{
                        color: "#b0bbc5c2",
                        fontSize: "3em",
                        marginTop: "1rem",
                        marginBottom: "10px",
                      }}
                      icon={"squares"}
                    />
                    {t("EXTRABUTTONS_LIST_EMPTY")}
                  </div>
                )}
              </div>
            </Modal>
            {isExtraButtons && (
              <CreateEditModelExtraButtons
                isActive={this.state.isExtraButtons}
                handleToggle={this.handleUpdateExtraButtons}
                handleToggleAll={() => {
                  this.handleUpdateExtraButtons();
                  handleToggle();
                }}
                refData={refData}
                isRefModel={isRefModel}
              />
            )}{" "}
          </>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer } = state;
  const {
    workspace,
    selectedService,
    dataModels,
    modules,
    module,
    models,
    allTransactions,
    allServices,
  } = workspaceReducer;

  return {
    workspace,
    selectedService,
    dataModels,
    modules,
    module,
    models,
    allTransactions,
    allServices,
  };
};
const mapDispatchToProps = {
  updateServices,
  updateSelectedService,
  updatedModels,
  updateSelectedModel,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShowModelExtraButtons);
