import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import i18n from "i18n";
import { Row, Col } from "rsuite";
import FormElement from "./FormElement";
import { Form } from "rsuite";
import PerfectScrollbar from "react-perfect-scrollbar";

class CustomFormGenerator extends Component {
  constructor(props) {
    super(props);

    const { currentValues, refModel } = props;

    this.state = {
      formValue: currentValues ? currentValues : {},
      formValueUpdateTime: new Date(),
      formError: {},
      loading: false,
      activeElement: null,
      dataModel: refModel,
      formKey: "fg" + "-" + new Date(),
      isTransModal: false,
      refTransaction: null,
    };
  }

  handleReset = () => {
    this.setState({
      formValue: {},
      formError: {},
      formValueUpdateTime: new Date(),
      loading: false,
      formKey: "fg" + "-" + new Date(),
      // dataModel: [],
    });
  };

  handleSetActiveElement = (id) => {
    this.setState({
      activeElement: id,
    });
  };

  render() {
    const { formValue, loading, formValueUpdateTime } = this.state;
    const {
      refModel,
      selectedService,
      currentValuesOrj,
      handleUpdateRefData,
      readOnly,
       isShowTransaction,
    } = this.props;

    const _dynamicSchemaModel = null;

    return (
      <Translation>
        {(t) => (
          <>
            
              <div className="modal-body" style={{ padding: 0 }}>
                <Row>
                  <Col></Col>
                </Row>
                <Row>
                  <Col style={{ marginBottom: "15px", padding: "0px" }}>
                    <Form
                      key={this.state.formKey + formValueUpdateTime}
                     readOnly={readOnly}
                      ref={(ref) => (this.form = ref)}
                      onChange={(formValue) => {
                        this.setState({ formValue });
                        handleUpdateRefData(
                          formValue,
                          this.form,
                          _dynamicSchemaModel
                        );
                      }}
                      className={
                        "dynamic-create-form " +
                        ((this.props.isListDetail
                          ? this.props.itemOperation
                          : selectedService.functionName) === "delete"
                          ? "delete-form-data"
                          : "")
                      }
                      formValue={formValue}
                      fluid
                     
                    >
                      <Row>
                        {refModel &&
                          refModel.map((item, key) => {
                            return (
                              <FormElement
                              isFilter={true}
                              formModel = {refModel}
                                item={item}
                                base={{dataTypes:[], view: {}}}
                                 fModel={refModel}
                                key={key}
                                _key={key}
                                formOnChange={(formValue) => {
                                  debugger;
                                  this.setState({
                                    formValue: formValue,
                                   // formKey: new Date(),
                                  });
                                  handleUpdateRefData(
                                    formValue,
                                    this.form,
                                    _dynamicSchemaModel
                                  );
                                }}
                                isDesigner={true}
                                activeElement={this.state.activeElement}
                                handleSetActiveElement={
                                  this.handleSetActiveElement
                                }
                                formValue={formValue}
                                formValueOrj={currentValuesOrj}
                                loading={loading}
                              />
                            );
                          })}
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </div>
       
          </>
        )}
      </Translation>
    );
  }
}
const mapStateToProps = (state, props) => {
  const { workspaceReducer } = state;
  const {
    selectedService
  } = workspaceReducer;
   return {
    selectedService
  };
};

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(CustomFormGenerator);
