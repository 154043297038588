export const workspaceConstants = {

    UPDATE_WORKSPACE: 'UPDATE_WORKSPACE',
    UPDATE_SELECTEDSERVICE: 'UPDATE_SELECTEDSERVICE',
    UPDATE_SERVICES: 'UPDATE_SERVICES',
    UPDATE_WORKSPACELIST:'UPDATE_WORKSPACELIST',
    UPDATE_MODULES: 'UPDATE_MODULES',
    UPDATE_SELECTEDMODULE: 'UPDATE_SELECTEDMODULE',
    UPDATE_MODELS: 'UPDATE_MODELS',
    UPDATE_SELECTEDMODEL: 'UPDATE_SELECTEDMODEL',
    UPDATE_TRANSACTIONS: 'UPDATE_TRANSACTIONS',
    UPDATE_SELECTEDTRANSACTION: 'UPDATE_SELECTEDTRANSACTION',
    CLEAR_WORKSPACE:'CLEAR_WORKSPACE',
    UPDATE_STORE_LIST:'UPDATE_STORE_LIST',
    UPDATE_CATEGORY_STORE_LIST:'UPDATE_CATEGORY_STORE_LIST',

    UPDATE_GENERATED_TRANSACTIONS: 'UPDATE_GENERATED_TRANSACTIONS',
    UPDATE_GENERATED_MODELS: 'UPDATE_GENERATED_MODELS',

    UPDATE_ALL_MODEL_AND_TRANS_SCRIPTS: 'UPDATE_ALL_MODEL_AND_TRANS_SCRIPTS',
    UPDATE_GLOBAL_SCRIPTS: 'UPDATE_GLOBAL_SCRIPTS',
    UPDATE_SERVICE_RELATIONS: 'UPDATE_SERVICE_RELATIONS',
    UPDATE_SERVICE_RELATIONS_ALL_MODEL: 'UPDATE_SERVICE_RELATIONS_ALL_MODEL',
    UPDATE_ENVIRONMENTS:'UPDATE_ENVIRONMENTS',
    UPDATE_ENGINE_STATUS:'UPDATE_ENGINE_STATUS',
    UPDATE_TERMINAL_LIST:'UPDATE_TERMINAL_LIST',
    UPDATE_GENERATED_INTERFACES:'UPDATE_GENERATED_INTERFACES',
    UPDATE_ALL_TRANSACTIONS:'UPDATE_ALL_TRANSACTIONS',
    UPDATE_GIT_STATUS:"UPDATE_GIT_STATUS",

    UPDATE_WORKSPACE_LANGS:"UPDATE_WORKSPACE_LANGS",
    UPDATE_SUPPORTED_LANGS:"UPDATE_SUPPORTED_LANGS",
    UPDATE_WORKSPACE_ALL_TEXT_AND_VALUES:"UPDATE_WORKSPACE_ALL_TEXT_AND_VALUES",
    UPDATE_WORKSPACE_DUPLICATED_TEXT_AND_VALUES:"UPDATE_WORKSPACE_DUPLICATED_TEXT_AND_VALUES",
    UPDATE_WORKSPACE_CURRENT_LANG:"UPDATE_WORKSPACE_CURRENT_LANG"
}
 