import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translation } from "react-i18next";

import i18n from "i18n";

import { Container, Header, Content, Footer, Sidebar } from 'rsuite';
 
import ModelLayout from './MVC/ModelLayout';
 

class RefModelLayout extends Component {
    constructor(props) {
        super(props)

        this.state = {
            activeTab: "Model"
        }
    }

    render() {
        const { activeTab } = this.state;
        return (
            <Translation>
                {t => (
                    <Container className={"custom-fade-in"} style={{ width: "100%", height: "100%"  }}>
                        {
                            /*
                             <Header style={{
                            minHeight: "56px",
                            
                         }}>
                       
                        </Header>
                     
                            */
                        }
                       
                        <Content className={"mvc-div"}>{
                            <div key={this.props.selectedService.id}>
                                <ModelLayout isRefModel={true}/>
                            </div>
                           
                        }</Content>
                    </Container>
                )}
            </Translation>
        )
    }
}


const mapStateToProps = (state, props) => {
    const { workspaceReducer } = state;
    const {
      models,
      workspaces,
      workspace,
      selectedService,
      services,
      modules,
      module,
    } = workspaceReducer;
  
    return {
      workspaces,
      workspace,
      modules,
      module,
      selectedService,
      services,
      models,
    };
  };

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(RefModelLayout)
