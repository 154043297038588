import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import i18n from "i18n";
import arrayMove from "array-move";

import { Modal, Container, Row, Col } from "reactstrap";

import AvatarCard from "components/Custom/AvatarCard";
import DynamicTag from "components/Custom/DynamicTag";
import { sortableHandle } from "react-sortable-hoc";
import {
  Toggle,
  IconButton,
  Icon,
  Slider,
  Popover,
  Whisper,
  Loading,
  Button,
  Alert,
  Animation,
  Drawer,
} from "rsuite";
import {
  HelpBlock,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  SelectPicker,
  InputNumber,
  DatePicker,
  Schema,
  ButtonToolbar,
} from "rsuite";

import { findNestedObj } from "assets/util";
import {
  updateServices,
  updateSelectedService,
  updatedModels,
  updateSelectedModel,
} from "_actions";
import CFormRefElement from "./CFormRefElement";

import { decamelize } from "assets/globalOps/global";
import { isoLangs } from "assets/globalOps/isoLangs";
import {
  contentTypesSP,
  typeDetailsSP,
} from "assets/globalOps/CollectionTypes";
import SortableItem from "./SortableItem";
import SortableContainer from "./SortableContainer";
const { StringType, NumberType, ArrayType, DateType, ObjectType, BooleanType } =
  Schema.Types;
const model = Schema.Model({});

const settingsModel = Schema.Model({
  visible: StringType().isRequired("This field is required."),
  col: StringType()
    .isEmail("Please enter a valid email address.")
    .isRequired("This field is required."),
});

const DragHandle = sortableHandle(() => (
  <Icon className={"move-handle custom-fade-in"} icon="arrows" />
));

class CFormElement extends Component {
  constructor(props) {
    super(props);
    const { allTransactions, model } = props;

    const _dataModel =
      props.item.contentType == 10
        ? props.item.refModel
        : props.item.contentType == 5
        ? props.item.relationModel.relModel
        : null;
    let refModel = null;

    if (_dataModel) {
      refModel = model.find((x) => x.id == _dataModel.id);

      //refModel.dataTypes

      debugger;

      this.state = {
        isShowSettings: false,
        _formValue: {
          ...props.selectedService.view[props.item.name],
          columns: _dataModel
            ? props.selectedService.view[props.item.name].hasOwnProperty(
                "columns"
              )
              ? [
                  ...this.updateArray2(
                    refModel.dataTypes,
                    props.selectedService.view[props.item.name].columns,
                    "name"
                  ),
                ].map((x, key) => {
                  debugger
                  return {
                    name: x.name,
                    visible: x.visible,
                  };
                }) // mevcut dışında yenir bir field var mı diye bakılmalı
              : [
                  ...refModel.dataTypes.map((x) => {
                    return {
                      name: x.name,
                      visible: !x.privateField,
                    };
                  }),
                ]
            : undefined,
        },
        formError: {},
        loading: false,

        //dataTypes:item.contentType== 10 ?[]
      };
    } else {
      this.state = {
        isShowSettings: false,
        _formValue: {
          ...props.selectedService.view[props.item.name],
        },
        formError: {},
        loading: false,

        //dataTypes:item.contentType== 10 ?[]
      };
    }
  }

  setFormValue = (_formValue) => {
    this.setState({
      _formValue: _formValue,
    });
  };

  handleShowSettings = (isReset) => {
    const { props } = this;
    const { allTransactions, model } = props;
    const that = this;
    const _dataModel =
      props.item.contentType == 10
        ? props.item.refModel
        : props.item.contentType == 5
        ? props.item.relationModel.relModel
        : null;
    let refModel = null;

    if (_dataModel) {
      refModel = model.find((x) => x.id == _dataModel.id);
      debugger;
      this.setState({
        isShowSettings: !this.state.isShowSettings,
        _formValue: isReset
          ? {
              ...this.props.selectedService.view[this.props.item.name],
              columns: _dataModel
                ? props.selectedService.view[props.item.name].hasOwnProperty(
                    "columns"
                  )
                  ? [
                      ...this.updateArray2(
                        refModel.dataTypes,
                        props.selectedService.view[props.item.name].columns,
                        "name"
                      ),
                    ].map((x) => {
                      return {
                        name: x.name,
                        visible: x.visible,
                      };
                    }) // mevcut dışında yenir bir field var mı diye bakılmalı
                  : [
                      ...refModel.dataTypes.map((x) => {
                        return {
                          name: x.name,
                          visible: !x.privateField,
                        };
                      }),
                    ]
                : undefined,
            }
          : this.state._formValue,
      });
    } else {
      this.setState({
        isShowSettings: !this.state.isShowSettings,
        _formValue: isReset
          ? {
              ...this.props.selectedService.view[this.props.item.name],
            }
          : this.state._formValue,
      });
    }
  };

  handleSave = () => {
    const {
      item,
      loading,
      updatedModels,
      updateSelectedModel,
      updateServices,
      services,
      selectedService,
      updateSelectedService,
      models,
    } = this.props;
    let _cloneService = JSON.parse(JSON.stringify(selectedService));

    // let _cloneTransactionIndex =  _cloneService

    _cloneService.view[item.name] = this.state._formValue;
    debugger;
    let _cloneServices = JSON.parse(JSON.stringify(models));

    let _rID = _cloneServices.dynamicValue.findIndex(
      (z) => z.id === selectedService.refId
    );
    let _cS = _cloneServices.dynamicValue[_rID];

    _cS.children[_cS.children.findIndex((x) => x.id === selectedService.id)] =
      _cloneService;

    updateServices(_cloneServices);
    updateSelectedService(_cloneService);

    updatedModels(_cloneServices);
    //updateSelectedModel(_cloneService)

    this.handleShowSettings();
  };

  onKeyDown = (event) => {
    if (event.key === "Enter") {
      this.handleSave();
      event.preventDefault();
    }
  };

  getCurrentLangDisplayName = (item) => {
    try {
      const _lang = this.props.currentLang;
      if (_lang) {
        let _langDNM = item.displayNameMultiLangs.find((x) => x.lang == _lang);
        if (_langDNM && _langDNM.textValue.trim().length > 0) {
          return _langDNM.textValue;
        } else {
          _langDNM = item.displayNameMultiLangs.find((x) => x.lang == "en");
          return _langDNM.textValue;
        }
      } else {
        const _langDNM = item.displayNameMultiLangs.find((x) => x.lang == "en");
        return _langDNM.textValue;
      }
    } catch (error) {
      return item.hasOwnProperty("displayName")
        ? item.displayName
        : decamelize(item.name, " ");
    }
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { _formValue } = this.state;
    debugger;
    const _newItems = arrayMove(_formValue.columns, oldIndex, newIndex);

    let _fv = Object.assign({}, _formValue);

    _fv.columns = _newItems;
    this.setState({ _formValue: _fv });
  };

  updateArray2 = (array1, array2, key) => {
    debugger;
    const updatedArray2 = array2.map((item2) => {
      const matchingItem1 = array1.find((item1) => item1[key] === item2[key]);

      if (matchingItem1 && matchingItem1.name !== item2.name) {
        return { ...item2, name: matchingItem1.name };
      }

      return item2;
    });

    return updatedArray2;
  };

  render() {
    const { _formValue, items } = this.state;

    const { item, _typeDetails, formValue, loading, selectedService, trans } =
      this.props;
    const { _key, isDesigner, activeElement, handleSetActiveElement } =
      this.props;
    const Locale = {
      sunday: i18n.t("sunday"),
      monday: i18n.t("monday"),
      tuesday: i18n.t("tuesday"),
      wednesday: i18n.t("wednesday"),
      thursday: i18n.t("thursday"),
      friday: i18n.t("friday"),
      saturday: i18n.t("saturday"),
      ok: i18n.t("ok"),
      today: i18n.t("today"),
      yesterday: i18n.t("yesterday"),
      hours: i18n.t("hours"),
      minutes: i18n.t("minutes"),
      seconds: i18n.t("seconds"),
    };
    const _col = selectedService.view[item.name].col;
    const _visible = selectedService.view[item.name].visible;

    if (
      (item.contentType == 5 || item.contentType == 10) &&
      this.state.isShowSettings
    ) {
      debugger;
    }

    return (
      <Translation>
        {(t) => (
          <>
            {
              <Col xs={_col}>
                <div className={"view-designer-card"}>
                  <div className={"view-designer-card-header"}>
                    <span>{this.getCurrentLangDisplayName(item)}</span>
                    <ButtonToolbar>
                      <IconButton
                        onClick={this.handleShowSettings}
                        size="xs"
                        disabled={loading}
                        icon={<Icon icon="edit" />}
                      />
                      {!loading && <DragHandle></DragHandle>}
                    </ButtonToolbar>
                  </div>
                  <div
                    onClick={() => {
                      handleSetActiveElement(_key);
                    }}
                    className={isDesigner ? "view-designer-card-wrapper " : ""}
                  >
                    {_visible &&
                      (item.contentType == "typeDetails" ? (
                        _typeDetails &&
                        _typeDetails.length > 0 && (
                          <FormGroup className={"custom-fade-in"}>
                            <ControlLabel className={"cell-header"}>
                              {this.getCurrentLangDisplayName(item)}
                            </ControlLabel>
                            <FormControl
                              cleanable={false}
                              block
                              placement="autoVerticalStart"
                              accepter={SelectPicker}
                              data={_typeDetails}
                              disabled={item.privateField || loading}
                              name={item.name.toLowerCase()}
                            />
                          </FormGroup>
                        )
                      ) : (
                        <FormGroup>
                          <ControlLabel className={"cell-header"}>
                            {this.getCurrentLangDisplayName(item)}
                          </ControlLabel>
                          {item.contentType == "string" ||
                          item.contentType == 1 ? (
                            <FormControl
                              type={
                                item["typeDetail"] == 2 ? "password" : "text"
                              }
                              disabled={item.privateField || loading}
                              name={item.name.toLowerCase()}
                              className={"custom-fade-in"}
                            />
                          ) : item.contentType == "boolean" ||
                            item.contentType == 7 ? (
                            <FormControl
                              accepter={Toggle}
                              disabled={item.privateField || loading}
                              defaultChecked={
                                formValue[item.name.toLowerCase()]
                              }
                              name={item.name.toLowerCase()}
                              className={"custom-fade-in"}
                            />
                          ) : item.contentType == "contentTypes" ? (
                            <>
                              <FormControl
                                cleanable={false}
                                block
                                placement="autoVerticalStart"
                                accepter={SelectPicker}
                                data={contentTypesSP}
                                disabled={item.privateField || loading}
                                name={item.name.toLowerCase()}
                                className={"custom-fade-in"}
                              />
                              {
                                //ENUM type
                                formValue["contentType".toLowerCase()] ==
                                  "6" && (
                                  <>
                                    {!item.privateField && (
                                      <div className={"dynamic-dt-side"}>
                                        <div className={"dynamic-enum-side"}>
                                          <DynamicTag
                                            data={
                                              formValue[
                                                "defaultData".toLowerCase()
                                              ]
                                                ? formValue[
                                                    "defaultData".toLowerCase()
                                                  ]
                                                : []
                                            }
                                            handleUpdate={this.handleUpdateEnum}
                                          />
                                        </div>
                                        <div className={"dynamic-dt-popover"}>
                                          {this.state.formError.hasOwnProperty(
                                            "defaultdata"
                                          ) && (
                                            <Popover
                                              visible
                                              className={"custom-white-tooltip"}
                                            >
                                              <p>
                                                {t(
                                                  "ERROR_DM_GLOBAL_REQUIRED_ENUM"
                                                )}{" "}
                                              </p>
                                            </Popover>
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  </>
                                )
                              }
                            </>
                          ) : item.contentType == "lang" ? (
                            <FormControl
                              name={item.name.toLowerCase()}
                              accepter={SelectPicker}
                              cleanable={false}
                              data={isoLangs}
                              className={"picker-full custom-fade-in"}
                              disabled={item.privateField || loading}
                            />
                          ) : item.contentType == 2 ? (
                            <FormControl
                              rows={5}
                              disabled={item.privateField || loading}
                              name={item.name.toLowerCase()}
                              className={"custom-fade-in"}
                              componentClass="textarea"
                            />
                          ) : item.contentType == 3 ? (
                            //Number NOT: INT TIPLERI GEÇİCİ OLARAK KALDIRILDI
                            <FormControl
                              accepter={InputNumber}
                              defaultValue={0}
                              step={1}
                              /*  defaultValue={
                        item.typeDetail === 6 || item.typeDetail === 7
                          ? 0.01
                          : 0
                      }
                      step={
                        item.typeDetail === 6 || item.typeDetail === 7
                          ? 0.01
                          : 1
                      }*/
                              disabled={item.privateField || loading}
                              name={item.name.toLowerCase()}
                              className={"custom-fade-in"}
                            />
                          ) : item.contentType == 4 ? (
                            //DATE
                            <FormControl
                              block
                              placement="autoVerticalStart"
                              format={"DD/MM/YYYY"}
                              locale={Locale}
                              accepter={DatePicker}
                              disabled={item.privateField || loading}
                              name={item.name.toLowerCase()}
                              className={"custom-fade-in"}
                            />
                          ) : item.contentType == 5 ? (
                            <CFormRefElement loading={loading} data={item} />
                          ) : item.contentType == 6 ? (
                            <FormControl
                              name={item.name.toLowerCase()}
                              accepter={SelectPicker}
                              cleanable={false}
                              data={item.defaultData.map((x, key) => ({
                                label: x,
                                value: x,
                              }))}
                              className={"picker-full custom-fade-in"}
                              disabled={item.privateField || loading}
                            />
                          ) : item.contentType == 8 ? (
                            <FormControl
                              readOnly
                              disabled
                              name={item.name.toLowerCase()}
                              className={"custom-fade-in"}
                            />
                          ) : item.contentType == 9 ? (
                            //IMAGE
                            !item.privateField && (
                              <div className={"dynamic-dt-side"}>
                                <AvatarCard
                                  renderedImages={
                                    formValue[item.name.toLowerCase()]
                                  }
                                  isEditable={true}
                                  isSquare={false}
                                  handleUpdate={this.handleUpdatePP}
                                  cmsKey={item.name.toLowerCase()}
                                />
                                <div className={"dynamic-dt-popover"}>
                                  {this.state.formError.hasOwnProperty(
                                    item.name.toLowerCase()
                                  ) && (
                                    <Popover
                                      visible
                                      className={"custom-white-tooltip"}
                                    >
                                      <p>{t("ERROR_DM_GLOBAL_REQUIRED")} </p>
                                    </Popover>
                                  )}
                                </div>
                              </div>
                            )
                          ) : (
                            item.contentType + " content type not found"
                          )}
                        </FormGroup>
                      ))}
                  </div>
                </div>
              </Col>
            }

            {item && (
              <Drawer
                size={"xs"}
                backdrop={true}
                show={this.state.isShowSettings}
                onHide={() => {
                  this.handleShowSettings(true);
                }}
              >
                <Drawer.Header>
                  <Drawer.Title>
                    {this.getCurrentLangDisplayName(item)}
                  </Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                  <Form
                    formValue={_formValue}
                    onChange={(_formValue) => this.setFormValue(_formValue)}
                  >
                    <FormGroup>
                      <ControlLabel>Visible</ControlLabel>
                      <FormControl
                        accepter={Toggle}
                        defaultChecked={_formValue ? _formValue.visible : true}
                        min={1}
                        max={12}
                        name="visible"
                      />
                    </FormGroup>

                    <FormGroup>
                      <ControlLabel>Col</ControlLabel>
                      <FormControl
                        accepter={InputNumber}
                        min={1}
                        max={12}
                        name="col"
                        onKeyDown={this.onKeyDown.bind(this)}
                      />
                    </FormGroup>
                    {item.contentType == 5 && (
                      <FormGroup>
                        <ControlLabel>Table Columns</ControlLabel>
                      </FormGroup>
                    )}
                    {item.contentType == 5 &&
                      item.relationModel.relationType == 1 &&
                      _formValue.columns &&
                      _formValue.columns.length > 0 && (
                        <>
                          <SortableContainer
                            onSortEnd={this.onSortEnd}
                            useDragHandle
                          >
                            {_formValue.columns.map((v, index) => {
                              debugger;
                              return (
                                <SortableItem
                                  dragable={true}
                                  key={`item-${v.name}`}
                                  index={index}
                                  value={v.name}
                                  extra={
                                    <div>
                                      <Toggle
                                        size="xs"
                                        defaultChecked={v.visible}
                                        checkedChildren="visible"
                                        unCheckedChildren="invisible"
                                        style={{ marginLeft: "20px" }}
                                        onChange={(value) => {
                                          debugger;
                                          let _fv = Object.assign(
                                            {},
                                            _formValue
                                          );
                                          let _c = [..._formValue.columns];
                                          const cIndex = _c.findIndex(
                                            (x) => x.name == v.name
                                          );
                                          _c[cIndex].visible = value;
                                          _fv.columns = _c;
                                          this.setState({ _formValue: _fv });
                                        }}
                                      />
                                    </div>
                                  }
                                />
                              );
                            })}
                          </SortableContainer>
                        </>
                      )}

                    {item.contentType == 10 &&
                      item.refModel.relationType == 1 && (
                        <FormGroup>
                          <ControlLabel>Table Columns</ControlLabel>
                        </FormGroup>
                      )}
                    {item.contentType == 10 &&
                      item.refModel.relationType == 1 &&
                      _formValue.columns &&
                      _formValue.columns.length > 0 && (
                        <>
                          <SortableContainer
                            onSortEnd={this.onSortEnd}
                            useDragHandle
                          >
                            {_formValue.columns.map((v, index) => {
                              debugger;
                              return (
                                <SortableItem
                                  dragable={true}
                                  key={`item-${v.name}`}
                                  index={index}
                                  value={v.name}
                                  extra={
                                    <div>
                                      <Toggle
                                        size="xs"
                                        defaultChecked={v.visible}
                                        checkedChildren="visible"
                                        unCheckedChildren="invisible"
                                        style={{ marginLeft: "20px" }}
                                        onChange={(value) => {
                                          debugger;
                                          let _fv = Object.assign(
                                            {},
                                            _formValue
                                          );
                                          let _c = [..._formValue.columns];
                                          const cIndex = _c.findIndex(
                                            (x) => x.name == v.name
                                          );
                                          _c[cIndex].visible = value;
                                          _fv.columns = _c;
                                          this.setState({ _formValue: _fv });
                                        }}
                                      />
                                    </div>
                                  }
                                />
                              );
                            })}
                          </SortableContainer>
                        </>
                      )}
                  </Form>
                </Drawer.Body>
                <Drawer.Footer>
                  <Button onClick={this.handleSave} appearance="primary">
                    Confirm
                  </Button>
                  <Button
                    onClick={() => {
                      this.handleShowSettings(true);
                    }}
                    appearance="subtle"
                  >
                    Cancel
                  </Button>
                </Drawer.Footer>
              </Drawer>
            )}
          </>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer } = state;
  const {
    workspace,
    selectedService,
    services,
    models,
    currentLang,
    allTransactions,
    model,
  } = workspaceReducer;

  return {
    workspace,
    selectedService,
    services,
    models,
    currentLang,
    model,
    allTransactions,
  };
};

const mapDispatchToProps = {
  updateServices,
  updateSelectedService,
  updatedModels,
  updateSelectedModel,
};

export default connect(mapStateToProps, mapDispatchToProps)(CFormElement);
