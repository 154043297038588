import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import i18n from "i18n";
import { ReactSortable } from "react-sortablejs";
import Emitter from "_actions/emitter";

import { Row, Col } from "reactstrap";

import CFormElement from "components/Custom/CFormElement";

import { Toggle, Icon, Loading, Button, Alert, Animation } from "rsuite";
import { FlexboxGrid, Form, Schema } from "rsuite";

import {
  updateServices,
  updateSelectedService,
  updatedModels,
  updateSelectedModel,
} from "_actions";

import { ModelsService, TransactionService } from "_services";

import { typeDetailsSP } from "assets/globalOps/CollectionTypes";

import { SortableElement, SortableContainer } from "react-sortable-hoc";
import arrayMove from "array-move";
import Infinite from "react-infinite";

class CForm extends Component {
  constructor(props) {
    super(props);

    const { dataModels, selectedService } = props;
    const dataModel = selectedService.model;

    const isHasPriority =
      selectedService.view[Object.keys(selectedService.view)[0]].hasOwnProperty(
        "priority"
      );

    debugger;
    this.state = {
      formValue: {},
      formError: {},
      loading: false,
      isLoading: false,
      activeElement: null,
      dataModel: dataModel ? dataModel : [],
      items: dataModel
        .filter((y) => {
          if (y.privateField) {
            return false;
          }
          return true;
        })
        .sort((a, b) => {
          debugger;
          if (isHasPriority)
            return (
              selectedService.view[a.name].priority -
              selectedService.view[b.name].priority
            );
          else return 0;
        }),
    };
  }

  saveTrans = () => {
    const { selectedService, isRefModel, transaction, workspace } = this.props;
    let { trans } = this.props;
    const { items } = this.state;
    const that = this;
    let _cloneTrans = Object.assign({}, trans);
    let _cloneViewFirst = Object.assign({}, selectedService.view);
    let _cloneViewNew = {};

    items.forEach((element, key) => {
      _cloneViewNew[element.name] = _cloneViewFirst[element.name];
    });

    for (let index = 0; index < items.length; index++) {
      const element = items[index];
      _cloneViewNew[element.name] = _cloneViewFirst[element.name];
      _cloneViewNew[element.name].priority = index;
    }

    _cloneTrans.viewCustom = _cloneViewNew;

    debugger;
    TransactionService.SAVE_VIEW_ONLY(
      trans.id,
      workspace.id,
      selectedService.relModuleId,
      JSON.stringify(_cloneTrans.viewCustom)
    )
      .then((data) => {
        that.setState({ isLoading: false });
        Alert.success(i18n.t("MESSAGE_ROW_DATA_UPDATE_SUCCESS"));
        Emitter.emit("CHANGE_MODULE");
        Emitter.emit("UPDATE_ALL_TRANS");
      })
      .catch((err) => {
        that.setState({ isLoading: false });
        debugger;
        //  let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
        //   this.warningAlert(data);
      });
  };

  handleSetActiveElement = (id) => {
    this.setState({
      activeElement: id,
    });
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(
      ({ items }) => ({
        items: arrayMove(items, oldIndex, newIndex),
      }),
      () => {
        const { items } = this.state;
        debugger;
      }
    );
  };

  render() {
    const { formValue, formError, loading, _model, dataModel, isLoading } =
      this.state;
    const { selectedService } = this.props;
    const _typeDetails = typeDetailsSP.filter(
      (x) => x.role == formValue["contenttype"]
    );

    const SortableItem = SortableElement(({ item, index }) => (
      <CFormElement
        item={item}
        _key={index}
        isDesigner={true}
        activeElement={this.state.activeElement}
        handleSetActiveElement={this.handleSetActiveElement}
        _typeDetails={_typeDetails}
        formValue={formValue}
        loading={loading || isLoading}
        trans={this.props.trans}
      />
    ));

    const SortableList = SortableContainer(({ items }) => {
      return (
        <Row>
          {items.map((item, key) => (
            <SortableItem key={`item-${item.name}`} index={key} item={item} />
          ))}
        </Row>
      );
    });

    return (
      <Translation>
        {(t) => (
          <>
            <div
              className="modal-body"
              style={{ padding: 0 }}
              key={
                "v" +
                (selectedService && selectedService.name + selectedService.id)
              }
            >
              <Row>
                <Col style={{ marginBottom: "15px", padding: "0px 1.25rem" }}>
                  <FlexboxGrid justify="end" style={{ marginBottom: "10px" }}>
                    <FlexboxGrid.Item>
                      <Button
                        size={"xs"}
                        loading={this.state.isLoading}
                        onClick={(e) => {
                          e.preventDefault();
                          this.saveTrans();
                        }}
                        color={"green"}
                        style={{ marginLeft: "5px" }}
                        appearance={"primary"}
                        className={"project-create-btn"}
                      >
                        {t("BUTTON_SAVE")}
                      </Button>
                    </FlexboxGrid.Item>
                  </FlexboxGrid>
                  <Form
                    ref={(ref) => (this.form = ref)}
                    className={"dynamic-create-form"}
                    formValue={formValue}
                    readOnly
                    fluid
                  >
                    <SortableList
                      items={this.state.items}
                      useDragHandle
                      onSortEnd={this.onSortEnd}
                    />
                  </Form>
                </Col>
              </Row>
            </div>
          </>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer } = state;
  const {
    workspace,
    models,
    selectedService,
    dataModels,
    allTransactions,
    transaction,
  } = workspaceReducer;

  return {
    workspace,
    selectedService,
    dataModels,
    models,
    allTransactions,
    transaction,
  };
};

const mapDispatchToProps = {
  updateServices,
  updateSelectedService,
  updatedModels,
  updateSelectedModel,
};
export default connect(mapStateToProps, mapDispatchToProps)(CForm);
