import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import i18n from "i18n";

import { Icon, Row, Col } from "rsuite";
import { Form, FormControl, Input, InputGroup, ControlLabel } from "rsuite";

import FormGenerator from "./FormGenerator";
import SelectObjectModal from "./Modals/SelectObjectModal";

class UpdateDeleteFormGenerator extends Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
    this.state = {
      text: "",
      selectedItem: null,
    };
  }
  handleReset = () => {
    this.setState({
      text: "",
      selectedItem: null,
    });
  };
  onKeyDown = (event) => {
    if (event.key === "Enter") {
      this.handleOpenSearch();
      event.preventDefault();
    }
  };

  handleOpenSearch = () => {
    this.setState({
      isSearch: !this.state.isSearch,
    });
  };

  handleSelect = (_value) => {
    const { data, loading, isObject, selectedService } = this.props;
    let _cValue = Object.assign({}, _value);

    

    //  debugger;
    this.setState({
      text: _value._id,
      selectedItem: _cValue,
      selectedItemOrj: _value,
    });
  };

  render() {
    const { data, loading, isObject, selectedService } = this.props;
    const { text, selectedItem, selectedItemOrj } = this.state;
    //currentValues
    return (
      <Translation>
        {(t) => (
          <div>
            <Row>
              <Col xs={24}>
                <Form>
                  <Row>
                    <Col
                      xs={24}
                      style={{
                        paddingBottom: "15px",
                        borderBottom: "1px solid #f7f7fa",
                      }}
                    >
                      <div className={"refFormControl"}>
                        <ControlLabel>
                          {t("TITLE_SELECT_UPDATE_OR_DELETE_OBJECT")}{" "}
                        </ControlLabel>
                        <InputGroup style={{}}>
                          <Input
                            readOnly={true}
                            value={this.state.text}
                            onChange={(value) => {
                              //debugger
                              this.setState({ text: value });
                            }}
                            onKeyDown={this.onKeyDown.bind(this)}
                          />
                          <InputGroup.Button onClick={this.handleOpenSearch}>
                            <Icon icon="more" />
                          </InputGroup.Button>
                        </InputGroup>
                        <SelectObjectModal
                          handleSelect={this.handleSelect}
                          isActive={this.state.isSearch}
                          handleToggle={this.handleOpenSearch}
                        />

                        <FormControl
                          ref={this.textInput}
                          name={selectedService.name}
                          id={selectedService.name}
                          accepter={Input}
                          style={{
                            maxWidth: "20px",
                            maxHeight: "1px",
                            overflow: "hidden",
                            opacity: "0",
                            position: "absolute",
                          }}
                          className={"picker-full custom-fade-in"}
                          disabled={true}
                        />
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Col>
              {selectedItem && (
                <Col
                  xs={24}
                  style={{
                    borderRadius: "4px",
                    marginTop: "10px",
                    padding: "1em",
                  }}
                >
                    <FormGenerator
                      key={text}
                      currentValues={selectedItem}
                      currentValuesOrj={selectedItemOrj}
                      onSubmit={this.handleReset}
                    />
                </Col>
              )}
            </Row>
          </div>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer } = state;
  const { models, transaction, workspace, dataModels, selectedService } =
    workspaceReducer;

  return {
    workspace,
    transaction,
    dataModels,
    models,
    selectedService,
  };
};

const mapDispatchToProps = {};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateDeleteFormGenerator);
