import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import moment from "moment";
import Emitter from "_actions/emitter";
import i18n from "i18n";
import { ResponseStatusCode } from "_helpers";
import {
  updateStore,
  updateStoreCategories,
  updateGeneratedTransactions,
  updateGeneratedModules,
  updateGeneratedInterfaces,
} from "_actions";
import {
  ErpEngineService,
  DashBoardURLSService,
  WorkspaceService,
  GITService,
} from "_services";
import { InputGroup, Input, Icon, Alert, SelectPicker } from "rsuite";
import { Loader, Container, Placeholder, Button, FlexboxGrid } from "rsuite";
import { SocketOperationLocal } from "_socketOperations";

import { Modal } from "reactstrap";
import Terminal from "react-console-emulator";

import CreateURL from "./CreateURL";
import RunAnotherInstanceModal from "./RunAnotherInstanceModal";
import TransferModeDataModal from "./TransferModeDataModal";
import PlatformsModal from "./PlatformsModal";

const { Paragraph } = Placeholder;
let LANG = i18n.t("LANG");
const WAIT_INTERVAL = 500;
const ENTER_KEY = 13;

class EngineModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreateUrl: false,
      isLoading: false,
      isRunLoading: false,
      isGetBaseProjectLoading: false,
      isGenerateAllModelsLoading: false,
      isGetGeneratedModelsLoading: false,
      isGenerateAllTransactionLoading: false,
      isSetSettingsLoading: false,
      isReDeployEnginesLoading: false,
      isPlatformModal: false,
      search: "",
      locked: false,
      increment: 0,
      isProgressing: false,
      progress: 0,
      isGitLoading: false,
      isRunInstance: false,
      isTransferModelData: false,
    };
    this.terminal = React.createRef();
  }

  componentDidMount() {
    Emitter.on("WORKSPACE_URL_NOT_FOUND", (payload) => this.handleCreateUrl());
    Emitter.on("TerminalResponse2", (newValue) =>
      this.handleGetBaseCommendLine(newValue)
    );
    const that = this;
    setTimeout(() => {
      that.handleGetAllBase();
    }, 1000);
  }
  componentWillUnmount() {
    Emitter.off("WORKSPACE_URL_NOT_FOUND");
    Emitter.off("TerminalResponse2");
  }

  handlePlatformModal = () => {
    this.setState({
      isPlatformModal: !this.state.isPlatformModal,
    });
  };

  handleCreateUrl = () => {
    this.setState({
      isCreateUrl: !this.state.isCreateUrl,
    });
  };
  handleRunInstance = () => {
    this.setState({
      isRunInstance: !this.state.isRunInstance,
    });
  };

  handleTransferModelData = () => {
    this.setState({
      isTransferModelData: !this.state.isTransferModelData,
    });
  };

  resetContent = () => {
    this.setState({
      isLoading: false,
      isRunLoading: false,
      isGetBaseProjectLoading: false,
      isGenerateAllModelsLoading: false,
      isGetGeneratedModelsLoading: false,
      isGenerateAllTransactionLoading: false,
      isSetSettingsLoading: false,
      isReDeployEnginesLoading: false,
      search: "",
      locked: false,
      increment: 0,
      isProgressing: false,
      progress: 0,
      isGitLoading: false,
      isRunInstance: false,
      isTransferModelData: false,
    });
  };
  componentDidUpdate(prevProps) {
    if (this.props.isActive)
      if (prevProps.isActive != this.props.isActive && this.props.isActive) {
        this.resetContent();
      }
  }

  componentWillMount() {
    this.timer = null;
  }

  handleRun = () => {
    const { workspace, selectedService } = this.props;
    const URL_MODE = "dev_URL";

    const that = this;

    this.setState({ isLoading: true, isRunLoading: true });
    ErpEngineService.RUN_ON_DEV_MODE(
      workspace[URL_MODE],
      workspace.id,
      "0.1" // -->> VERSION
    )
      .then((data) => {
        debugger;
        if (data.operationResult) {
          Alert.success(
            i18n.t("RUN_ON_DEV_MODE") +
              i18n.t("MESSAGE_ENGINE_OPERATION_SUCCESS")
          );
        }
        that.setState({ isLoading: false, isRunLoading: false });
      })
      .catch((err) => {
        that.setState({ isLoading: false, isRunLoading: false });
        Alert.error(i18n.t("MESSAGE_ENGINE_OPERATION_UNSUCCESS"));

        // let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
        debugger;
      });
  };

  handleGetBase = () => {
    const { workspace, selectedService } = this.props;
    const URL_MODE = "dev_URL";

    const that = this;

    this.setState({ isLoading: true, isGetBaseProjectLoading: true });
    ErpEngineService.GET_BASE_PROJECT(
      workspace[URL_MODE],
      "0.1" // -->> VERSION
    )
      .then((data) => {
        debugger;
        if (data.operationResult) {
          debugger;
          Alert.success(
            i18n.t("GET_BASE_PROJECT") +
              i18n.t("MESSAGE_ENGINE_OPERATION_SUCCESS")
          );
        }
        that.setState({ isLoading: false, isGetBaseProjectLoading: false });
      })
      .catch((err) => {
        that.setState({ isLoading: false, isGetBaseProjectLoading: false });
        Alert.error(i18n.t("MESSAGE_ENGINE_OPERATION_UNSUCCESS"));

        // let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
        debugger;
      });
  };
  handleGenerateAllModels = () => {
    const { workspace, selectedService } = this.props;
    const URL_MODE = "dev_URL";
    const that = this;

    this.setState({ isLoading: true, isGenerateAllModelsLoading: true });
    ErpEngineService.GENERATE_ALL_MODEL(workspace[URL_MODE], workspace.id)
      .then((data) => {
        debugger;
        if (data.operationResult) {
          Alert.success(
            i18n.t("GENERATE_ALL_MODEL") +
              i18n.t("MESSAGE_ENGINE_OPERATION_SUCCESS")
          );
        }
        that.setState({ isLoading: false, isGenerateAllModelsLoading: false });
      })
      .catch((err) => {
        that.setState({ isLoading: false, isGenerateAllModelsLoading: false });
        Alert.error(i18n.t("MESSAGE_ENGINE_OPERATION_UNSUCCESS"));

        // let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
        debugger;
      });
  };
  handleGetGeneratedModels = () => {
    const { workspace, selectedService } = this.props;
    const URL_MODE = "dev_URL";
    const that = this;
    this.setState({ isLoading: true, isGetGeneratedModelsLoading: true });
    ErpEngineService.GET_GENERATED_MODEL(workspace[URL_MODE], workspace.id)
      .then((data) => {
        debugger;
        if (data.operationResult) {
          Alert.success(
            i18n.t("GET_GENERATED_MODEL") +
              i18n.t("MESSAGE_ENGINE_OPERATION_SUCCESS")
          );
        }
        that.setState({ isLoading: false, isGetGeneratedModelsLoading: false });
      })
      .catch((err) => {
        that.setState({ isLoading: false, isGetGeneratedModelsLoading: false });
        Alert.error(i18n.t("MESSAGE_ENGINE_OPERATION_UNSUCCESS"));

        // let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
        debugger;
      });
  };
  handleGenerateAllTransaction = () => {
    const { workspace, selectedService } = this.props;
    const URL_MODE = "dev_URL";
    const that = this;

    this.setState({ isLoading: true, isGenerateAllTransactionLoading: true });
    ErpEngineService.GENERATE_ALL_TRANSACTION(workspace[URL_MODE], workspace.id)
      .then((data) => {
        debugger;
        if (data.operationResult) {
          Alert.success(
            i18n.t("GENERATE_ALL_TRANSACTION") +
              i18n.t("MESSAGE_ENGINE_OPERATION_SUCCESS")
          );
        }
        that.setState({
          isLoading: false,
          isGenerateAllTransactionLoading: false,
        });
      })
      .catch((err) => {
        that.setState({
          isLoading: false,
          isGenerateAllTransactionLoading: false,
        });
        Alert.error(i18n.t("MESSAGE_ENGINE_OPERATION_UNSUCCESS"));

        // let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
        debugger;
      });
  };

  handleGetBaseCommendLine = (value) => {
    const terminal = this.terminal.current;
    terminal.pushToStdout(value);
    terminal.scrollToBottom();
  };

  handleSetSettings = () => {
    const { workspace, selectedService } = this.props;
    const that = this;
    const URL_MODE = "dev_URL";

    this.setState({ isLoading: true, isSetSettingsLoading: true });
    ErpEngineService.SET_SETTING_BACKEND(
      workspace[URL_MODE],
      workspace.id,
      "dev",
      "0.1" // -->> VERSION
    )
      .then((data) => {
        debugger;
        if (data.operationResult) {
          Alert.success(
            i18n.t("SET_SETTING_BACKEND") +
              i18n.t("MESSAGE_ENGINE_OPERATION_SUCCESS")
          );
        }
        that.setState({ isLoading: false, isSetSettingsLoading: false });
      })
      .catch((err) => {
        that.setState({ isLoading: false, isSetSettingsLoading: false });
        Alert.error(i18n.t("MESSAGE_ENGINE_OPERATION_UNSUCCESS"));

        // let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
        debugger;
      });
  };

  openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  handleGetUrl = () => {
    const that = this;
    const { workspace, selectedService } = this.props;
    DashBoardURLSService.GET_DASHBOARD_URL(workspace.id)
      .then((data) => {
        //debugger;
        if (data.operationResult) {
          debugger;
          that.openInNewTab(data.dynamicValue.url);
        } else {
          that.handleCreateUrl();
        }
      })
      .catch((err) => {
        that.setState({ isLoading: false, isSetSettingsLoading: false });
        let data = {
          title: i18n.t("warning"),
          desc: ResponseStatusCode(err.statusCode),
        };
        debugger;
        Alert.error(data.desc);
        that.handleCreateUrl();
      });
  };

  handleReDeployEngine = () => {
    const that = this;
    const { workspace, selectedService } = this.props;
    this.setState({ isLoading: true, isReDeployEnginesLoading: true });

    WorkspaceService.RE_DEPLOY_ENGINE(workspace.id, "DEV")
      .then((data) => {
        that.setState({ isLoading: false, isReDeployEnginesLoading: false });
      })
      .catch((err) => {
        that.setState({ isLoading: false, isReDeployEnginesLoading: false });
        let data = {
          title: i18n.t("warning"),
          desc: ResponseStatusCode(err.statusCode),
        };
        debugger;
        Alert.error(data.desc);
        that.handleCreateUrl();
      });
  };

  handleGitStatus = () => {
    const that = this;
    const { workspace, selectedService, gitStatus } = this.props;

    this.setState({ isLoading: true, isGitLoading: true });
    const URL_MODE = "dev_URL";
    GITService[gitStatus ? "STOP_GIT" : "START_GIT"](
      workspace.id,
      workspace[URL_MODE]
    )
      .then((data) => {
        that.setState({ isLoading: false, isGitLoading: false });
      })
      .catch((err) => {
        that.setState({ isLoading: false, isGitLoading: false });
        let data = {
          title: i18n.t("warning"),
          desc: ResponseStatusCode(err.statusCode),
        };
        debugger;
        Alert.error(data.desc);
      });
  };

  handleGetGitUrl = () => {
    const that = this;
    const { workspace, selectedService, gitStatus } = this.props;

    this.setState({ isLoading: true });
    const URL_MODE = "dev_URL";
    GITService.GET_GIT_URL(workspace.id, workspace[URL_MODE])
      .then((data) => {
        that.setState({ isLoading: false });
        //workspace[URL_MODE]
        const terminal = that.terminal.current;
        terminal.pushToStdout(
          "https://" + workspace[URL_MODE] + data.dynamicValue
        );
        terminal.scrollToBottom();
      })
      .catch((err) => {
        that.setState({ isLoading: false });
        let data = {
          title: i18n.t("warning"),
          desc: ResponseStatusCode(err.statusCode),
        };
        debugger;
        Alert.error(data.desc);
      });
  };

  handleGetGeneratedModelsBase = async () => {
    const { workspace, selectedService, updateGeneratedModules } = this.props;
    const URL_MODE = "dev_URL";
    const that = this;

    return await ErpEngineService.GET_GENERATED_MODEL_BASE(
      workspace.relWorkspaceId
    )
      .then((data) => {
        if (data.operationResult) {
          updateGeneratedModules(data.dynamicValue);
          return data.dynamicValue;
        }
      })
      .catch((err) => {
        that.setState({ isLoading: false, isGetGeneratedModelsLoading: false });
        Alert.error(i18n.t("MESSAGE_ENGINE_OPERATION_UNSUCCESS"));
        debugger;
      });
  };

  handleGetInterfacesBase = async () => {
    const { workspace, selectedService, updateGeneratedInterfaces } =
      this.props;
    const URL_MODE = "dev_URL";
    const that = this;

    return await ErpEngineService.GET_INTERFACES(
      workspace[URL_MODE],
      workspace.relWorkspaceId
    )
      .then((data) => {
        if (data.operationResult) {
          updateGeneratedInterfaces(data.dynamicValue);
          return data.dynamicValue;
        }
      })
      .catch((err) => {
        that.setState({ isLoading: false, isGetGeneratedModelsLoading: false });
        //  Alert.error(i18n.t("MESSAGE_ENGINE_OPERATION_UNSUCCESS"));
      });
  };

  handleGenerateAllTransactionBase = async () => {
    const { workspace, selectedService, updateGeneratedTransactions } =
      this.props;
    const URL_MODE = "dev_URL";
    const that = this;

    return await ErpEngineService.GENERATE_ALL_TRANSACTION_BASE(
      workspace.relWorkspaceId
    )
      .then((data) => {
        if (data.operationResult) {
          updateGeneratedTransactions(data.dynamicValue);
          return data.dynamicValue;
        }
      })
      .catch((err) => {
        that.setState({
          isLoading: false,
          isGenerateAllTransactionLoading: false,
        });
        Alert.error(i18n.t("MESSAGE_ENGINE_OPERATION_UNSUCCESS"));

        // let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
        debugger;
      });
  };

  handleGetAllBase = (isGet) => {
    const { generatedTransactions, generatedModels, generatedInterfaces } =
      this.props;
    const that = this;
    if (generatedTransactions.length == 0 || isGet) {
      that.handleGenerateAllTransactionBase();
    }
    if (generatedModels.length == 0 || isGet) {
      that.handleGetGeneratedModelsBase();
    }
    if (generatedInterfaces.length == 0 || isGet) {
      that.handleGetInterfacesBase();
    }
  };

  render() {
    const {
      isActive,
      handleToggle,
      store,
      storeCategories,
      user,
      workspace,
      EngineStatus,
      gitStatus,
    } = this.props;
    const { isLoading, mystore, active } = this.state;
    return (
      <Translation>
        {(t) => (
          <>
            <Modal
              className="modal-dialog-centered"
              isOpen={isActive}
              size="lg"
              scrollable={true}
            >
              <div>
                <div className="modal-header store-model-header">
                  <h5 className="modal-title">
                    {t("MODAL_ENGINE_MODAL_TITLE")}
                  </h5>
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    disabled={this.state.isLoading}
                    onClick={() => handleToggle()}
                  >
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
                <div className={"store-header"}>
                  <div className={"store-header-sub"}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        marginLeft: "6px",
                      }}
                    >
                      <div className="engine-status-box">
                        <div
                          className={
                            "engine-status-color " +
                            (EngineStatus
                              ? " engine-status-on"
                              : " engine-status-off")
                          }
                        />
                        <span>{t("TITLE_ENGINE_STATUS")}</span>
                      </div>
                      <div className="engine-status-box">
                        <div className="engine-status-color engine-status-loading" />
                        <span>{t("TITLE_API_STATUS")}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-body store-model-body">
                <Container>
                  <div style={{ paddingBottom: "1em" }}>
                    <div>
                      {workspace && (
                        <Terminal
                          ref={this.terminal}
                          style={{
                            maxHeight: "200px",
                            minHeight: "200px",
                            borderRadius: "6px",
                          }}
                          autoFocus={true}
                          welcomeMessage="Wellcome to TheXApp"
                          promptLabel={
                            user.name.toLowerCase() +
                            "@" +
                            workspace.name +
                            ":~$"
                          }
                          promptLabelStyle={{ color: "#2baebf" }}
                          readOnly
                          disabled={this.state.isProgressing}
                          locked={this.state.isProgressing}
                          commands={{
                            wait: {
                              description:
                                "Waits one second and sends a message.",
                              fn: () => {
                                const terminal = this.terminal.current;
                                setTimeout(
                                  () =>
                                    terminal.pushToStdout(
                                      "Hello after 1 second!"
                                    ),
                                  1500
                                );
                                return "Running, please wait...";
                              },
                            },
                            progress: {
                              description: "Displays a progress counter.",
                              fn: () => {
                                this.setState({ isProgressing: true }, () => {
                                  const terminal = this.terminal.current;

                                  const interval = setInterval(() => {
                                    if (this.state.progress === 100) {
                                      // Stop at 100%
                                      clearInterval(interval);
                                      this.setState({
                                        isProgressing: false,
                                        progress: 0,
                                      });
                                    } else {
                                      this.setState(
                                        { progress: this.state.progress + 1 },
                                        () =>
                                          terminal.pushToStdout(
                                            `Progress: ${this.state.progress}%`
                                          )
                                      );
                                    }
                                  }, 15);
                                });

                                return "";
                              },
                            },
                          }}
                        />
                      )}
                    </div>
                    <FlexboxGrid style={{ marginTop: "1em" }}>
                      <FlexboxGrid.Item
                        colspan={4}
                        md={4}
                        sm={6}
                        xs={6}
                        className={"engine-box-flex"}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Button
                            className="engine-box"
                            block
                            appearance="ghost"
                            onClick={() => this.handleGitStatus()}
                            loading={this.state.isGitLoading}
                            disabled={this.state.isLoading}
                            style={{
                              minHeight: "45px",
                              marginBottom: "4px",
                            }}
                          >
                            {gitStatus
                              ? t("BUTTON_GIT_STOP")
                              : t("BUTTON_GIT_START")}
                          </Button>
                          <Button
                            className="engine-box"
                            block
                            appearance="ghost"
                            onClick={() => {
                              this.handleGetGitUrl();
                            }}
                            //loading={this.state.isRunLoading}
                            style={{
                              minHeight: "45px",
                            }}
                            disabled={this.state.isLoading}
                          >
                            {"Git Url"}
                          </Button>
                        </div>
                      </FlexboxGrid.Item>
                      <FlexboxGrid.Item
                        colspan={4}
                        md={4}
                        sm={6}
                        xs={6}
                        className={"engine-box-flex"}
                      >
                        <Button
                          className="engine-box"
                          block
                          appearance="ghost"
                          onClick={() => {
                            this.handlePlatformModal();
                            // this.handleGetUrl();
                          }}
                          //loading={this.state.isRunLoading}
                          disabled={this.state.isLoading}
                        >
                          {"ERP Url"}
                        </Button>
                      </FlexboxGrid.Item>
                      <FlexboxGrid.Item
                        colspan={8}
                        md={8}
                        sm={12}
                        xs={12}
                        className={"engine-box-flex"}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Button
                            className="engine-box"
                            block
                            appearance="ghost"
                            onClick={() => this.handleRunInstance()}
                            loading={this.state.isGitLoading}
                            disabled={this.state.isLoading}
                            style={{
                              minHeight: "45px",
                              marginBottom: "4px",
                            }}
                          >
                            {"Run another instance"}
                          </Button>
                          <Button
                            className="engine-box"
                            block
                            appearance="ghost"
                            onClick={() => {
                              this.handleTransferModelData();
                            }}
                            //loading={this.state.isRunLoading}
                            style={{
                              minHeight: "45px",
                            }}
                            disabled={this.state.isLoading}
                          >
                            {"Transfer data"}
                          </Button>
                        </div>
                      </FlexboxGrid.Item>

                      <FlexboxGrid.Item
                        colspan={8}
                        md={8}
                        sm={12}
                        xs={12}
                        className={"engine-box-flex"}
                      >
                        <Button
                          className="engine-box"
                          block
                          appearance="primary"
                          onClick={() => this.handleRun()}
                          loading={this.state.isRunLoading}
                          disabled={this.state.isLoading}
                        >
                          {t("SERVICES_MENU_ITEM_RUN")}
                        </Button>
                      </FlexboxGrid.Item>
                      <FlexboxGrid.Item
                        colspan={8}
                        md={8}
                        sm={12}
                        xs={12}
                        className={"engine-box-flex"}
                      >
                        <Button
                          className="engine-box"
                          block
                          appearance="default"
                          onClick={() => this.handleGetBase()}
                          loading={this.state.isGetBaseProjectLoading}
                          disabled={this.state.isLoading}
                        >
                          {t("TITLE_ENGINE_API_GETBASE")}
                        </Button>
                      </FlexboxGrid.Item>
                      <FlexboxGrid.Item
                        colspan={8}
                        md={8}
                        sm={12}
                        xs={12}
                        className={"engine-box-flex"}
                      >
                        <Button
                          className="engine-box"
                          block
                          appearance="default"
                          onClick={() => this.handleGenerateAllModels()}
                          loading={this.state.isGenerateAllModelsLoading}
                          disabled={this.state.isLoading}
                        >
                          {t("TITLE_ENGINE_API_GENERATEALLMODEL")}
                        </Button>
                      </FlexboxGrid.Item>
                      <FlexboxGrid.Item
                        colspan={8}
                        md={8}
                        sm={12}
                        xs={12}
                        className={"engine-box-flex"}
                      >
                        <Button
                          className="engine-box"
                          block
                          appearance="default"
                          onClick={() => this.handleGetGeneratedModels()}
                          loading={this.state.isGetGeneratedModelsLoading}
                          disabled={this.state.isLoading}
                        >
                          {t("TITLE_ENGINE_API_GETGENERATEDMODEL")}
                        </Button>
                      </FlexboxGrid.Item>
                      <FlexboxGrid.Item
                        colspan={8}
                        md={8}
                        sm={12}
                        xs={12}
                        className={"engine-box-flex"}
                      >
                        <Button
                          className="engine-box"
                          block
                          appearance="default"
                          onClick={() => this.handleGenerateAllTransaction()}
                          loading={this.state.isGenerateAllTransactionLoading}
                          disabled={this.state.isLoading}
                        >
                          {t("TITLE_ENGINE_API_GENERATEALLTRANS")}
                        </Button>
                      </FlexboxGrid.Item>
                      <FlexboxGrid.Item
                        colspan={8}
                        md={8}
                        sm={12}
                        xs={12}
                        className={"engine-box-flex"}
                      >
                        <Button
                          className="engine-box"
                          block
                          appearance="default"
                          onClick={() => this.handleSetSettings()}
                          loading={this.state.isSetSettingsLoading}
                          disabled={this.state.isLoading}
                        >
                          {t("TITLE_ENGINE_API_SETSETTINGS")}
                        </Button>
                      </FlexboxGrid.Item>
                      <FlexboxGrid.Item
                        colspan={8}
                        md={8}
                        sm={12}
                        xs={12}
                        className={"engine-box-flex"}
                      >
                        <Button
                          className="engine-box"
                          block
                          appearance="default"
                          onClick={() => this.handleReDeployEngine()}
                          loading={this.state.isReDeployEnginesLoading}
                          disabled={this.state.isLoading}
                        >
                          {t("TITLE_ENGINE_API_REDEPLOY_ENGINE")}
                        </Button>
                      </FlexboxGrid.Item>
                    </FlexboxGrid>
                  </div>
                  {false && (
                    <div className={"fade-in"}>
                      <Loader backdrop vertical />
                    </div>
                  )}
                </Container>
              </div>

              {this.state.alert}
            </Modal>
            <CreateURL
              isActive={this.state.isCreateUrl}
              handleToggle={this.handleCreateUrl}
            />

            {this.state.isRunInstance && (
              <RunAnotherInstanceModal
                isActive={this.state.isRunInstance}
                handleToggle={this.handleRunInstance}
              />
            )}
            {this.state.isTransferModelData && (
              <TransferModeDataModal
                isActive={this.state.isTransferModelData}
                handleToggle={this.handleTransferModelData}
              />
            )}

            <PlatformsModal
              isActive={this.state.isPlatformModal}
              handleToggle={this.handlePlatformModal}
            />
          </>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer, authentication } = state;
  const { loggedIn, LoginFailed, user } = authentication;

  const {
    workspace,
    workspaces,
    selectedService,
    store,
    storeCategories,
    EngineStatus,
    gitStatus,
    TerminalList,
    generatedTransactions,
    generatedModels,
    generatedInterfaces,
  } = workspaceReducer;

  return {
    workspace,
    workspaces,
    selectedService,
    store,
    storeCategories,
    user,
    EngineStatus,
    TerminalList,
    gitStatus,
    generatedTransactions,
    generatedModels,
    generatedInterfaces,
  };
};

const mapDispatchToProps = {
  updateStore,
  updateStoreCategories,
  updateGeneratedTransactions,
  updateGeneratedModules,
  updateGeneratedInterfaces,
};

export default connect(mapStateToProps, mapDispatchToProps)(EngineModal);
