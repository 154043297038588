import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import moment from "moment";
import Emitter from "_actions/emitter";
import i18n from "i18n";
import { ResponseStatusCode } from "_helpers";
import { updateServiceRelations } from "_actions";
import { ServiceRelationsService } from "_services";
import { Loader, Container, Alert, ButtonGroup, Button } from "rsuite";
import { Modal } from "reactstrap";

import ScriptEditor from "components/GlobalScript/ScriptEditor";
import RelationList from "components/ServiceRelations/RelationList";
import SwipeableViews from "react-swipeable-views";

 import ServiceRelationsSelect from "./ServiceRelationsSelect";

let LANG = i18n.t("LANG");
const WAIT_INTERVAL = 500;
const ENTER_KEY = 13;

class ServiceRelationsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      script: "",
      selectedRelation: null,
      step: 0,
      isCreate: false,
    };
  }

  handleCreateRelation = () => {
    this.setState({ isCreate: !this.state.isCreate });
  };

  resetContent = () => {
    const that = this;
    this.setState(
      {
        isLoading: false,
        script: "",
        selectedRelation: null,
        step: 0,
        isCreate: false,
      },
      () => {
        that.handleGetRelations();
      }
    );
  };
  componentDidUpdate(prevProps) {
    if (this.props.isActive) {
      if (prevProps.isActive != this.props.isActive && this.props.isActive) {
        this.resetContent();
      }
    }
  }

  componentWillMount() {
    this.timer = null;
  }

  handleGetRelations = () => {
    const { workspace, updateServiceRelations, transaction } = this.props;
    const that = this;
    this.setState({ loading: true });

    ServiceRelationsService.GET_RELATIONS(
      workspace.relWorkspaceId,
      transaction.relBaseModelId
    )
      .then((data) => {
        if (data.operationResult) {
          updateServiceRelations(data.dynamicValue);
        }
        that.setState({ loading: false });
      })
      .catch((err) => {
        that.setState({ loading: false });
        let data = {
          title: i18n.t("warning"),
          desc: ResponseStatusCode(err.statusCode),
        };
        Alert.error(data.desc);
        debugger;
      });
  };

  handlDeleteRelation = (item) => {
    const { workspace, updateServiceRelations, transaction } = this.props;
    const that = this;
    this.setState({ loading: true });

    ServiceRelationsService.REMOVE(
      item.id,
      workspace.id,
      item.primaryModulelId,
      item.primaryModelId,
       item.foreignModulelId,
      item.foreignModelId,
      //item
    )
      .then((data) => {
        if (data.operationResult) {
          that.handleGetRelations()
        }
        that.setState({ loading: false });
      })
      .catch((err) => {
        that.setState({ loading: false });
        let data = {
          title: i18n.t("warning"),
          desc: ResponseStatusCode(err.statusCode),
        };
        Alert.error(data.desc);
        debugger;
      });
  };


  handleToggleLocal = () => {
    const { isActive, handleToggle } = this.props;
    const { loading } = this.state;

    if (!loading) {
      handleToggle();
    }
  };

  handleShowList = () => {
    this.setState({
      isLoading: false,
      script: "",
      selectedScript: null,
      step: 0,
      isCreate: false,
    });
  };
  handleShowEditor = (_script) => {
    const that = this;
    this.setState({ step: 1, selectedScript: _script });
  };

  render() {
    const { isActive, handleToggle } = this.props;
    const { loading, step, selectedScript } = this.state;
    const that = this;

    return (
      <Translation>
        {(t) => (
          <>
            <Modal
              className="modal-dialog-centered"
              isOpen={isActive}
              size="lg"
              scrollable={true}
            >
              <div>
                <div className="modal-header store-model-header">
                  <h5 className="modal-title">
                    {selectedScript
                      ? selectedScript.scriptName + " " + t("TITLE_SCRIPT")
                      : t("SERVICES_MENU_ITEM_SERVICE_RELATIONS")}
                  </h5>
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    disabled={this.state.isLoading}
                    onClick={() => handleToggle()}
                  >
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
              </div>
              <div className="modal-body store-model-body">
                <Container>
                  <div
                    style={{
                      height: "50vh",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <SwipeableViews
                      style={{ height: "50vh" }}
                      index={step}
                      ref={(el) => (this.reactSwipeEl = el)}
                    >
                      <div className={"global-script-view"}>
                        <RelationList
                          onCreate={this.handleCreateScript}
                          onSelect={this.handleShowEditor}
                          onRemove={this.handlDeleteRelation}
                          onRefresh={this.resetContent}
                        />
                      </div>
                      <div className={"global-script-view"}>
                        {step ? (
                          <ScriptEditor
                            file={selectedScript}
                            onSubmit={this.resetContent}
                            onCancel={this.handleShowList}
                          />
                        ) : (
                          <div />
                        )}
                      </div>
                    </SwipeableViews>
                  </div>
                  {loading && (
                    <div className={"fade-in"}>
                      <Loader backdrop vertical />
                    </div>
                  )}
                </Container>
              </div>
              <div className={"custom-modal-footer"}>
                <ButtonGroup >
                  <Button onClick={this.props.handleToggle} disabled={loading}>
                    {t("BUTTON_CANCEL")}
                  </Button>
                  <Button
                    loading={loading}
                    disabled={loading}
                    appearance={"primary"}
                    onClick={this.handleCreateRelation}
                  >
                 { t("BUTTON_CREATE_NEW") }
                  </Button>
                </ButtonGroup>
              </div>
            </Modal>

            <ServiceRelationsSelect
              isActive={this.state.isCreate}
              handleToggle={this.handleCreateRelation}
              onRefresh={this.resetContent}
            />
          </>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer, authentication } = state;
  const { loggedIn, LoginFailed, user } = authentication;

  const {
    workspace,
    workspaces,
    selectedService,
    store,
    storeCategories,
    transaction,
    serviceRelations,
  } = workspaceReducer;

  return {
    workspace,
    workspaces,
    selectedService,
    store,
    storeCategories,
    user,
    transaction,
    serviceRelations,
  };
};

const mapDispatchToProps = {
  updateServiceRelations,
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceRelationsModal);
