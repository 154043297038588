import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Translation } from "react-i18next";
import { ReactSortable } from "react-sortablejs";

import i18n from 'i18n';
import {
    Modal,
    Container,
    Row,
    Col,
    Card,
    CardBody,
} from "reactstrap";


import { Toggle, Icon, Tooltip, Whisper, Loading, Button, Alert, Animation } from 'rsuite';
import { Form, FormGroup, FormControl, ControlLabel, SelectPicker, Schema, ButtonToolbar } from 'rsuite';

import { decamelize } from 'assets/globalOps/global';
import { isoLangs } from 'assets/globalOps/isoLangs';
import { contentTypesSP, typeDetailsSP } from 'assets/globalOps/CollectionTypes';

const model = Schema.Model({});

class EditSpecificRowData extends Component {
    constructor(props) {
        super(props)
        this.state = {
            list: [],
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isActive !== this.props.isActive) {

            if (this.props.isActive) {
                this.setState({
                    list: this.props.data,
                })
            }
        }
    }

    componentDidMount = () => {

    }

    handleSubmit = (e) => {
        const { list } = this.state;
        const { updatePriority } = this.props;
        updatePriority(list);
        this.handleCloseModal();
    }

    handleCloseModal = () => {
        const { handleToggle } = this.props;
        this.setState({
            formValue: {},
            formError: {},
            loading: false,
        }, () => { handleToggle(); })
    }

    handleSetList = (_newList) => {

        let _new = [];
        _newList.map((item, key) => {
            let _a = item;
            _a.priority = _newList.length - key - 1;
            _new.push(_a);
        });
        this.setState({
            list: _new,
        })
    }


    render() {
        const { isActive, handleToggle, data, dataModel, schemaModel } = this.props;
        const { formValue, formError, loading } = this.state;

        return (
            <Translation>
                {t => (
                    <Modal
                        className="modal-dialog-centered"
                        isOpen={isActive}
                        size="lg"
                        toggle={this.handleCloseModal}
                        scrollable={true}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title" id="CheckProjectModal">
                                {t("TITLE_MODAL_EDIT_ROW")}
                            </h5>
                            <button
                                aria-label="Close"
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => this.handleCloseModal()}
                            >
                                <span aria-hidden={true}>×</span>
                            </button>
                        </div>
                        <div className="modal-body" style={{ padding: 0 }}>
                            <Container>
                                <Row>
                                    <Col style={{ marginBottom: "15px", padding: "0px 1.25rem" }}>
                                        <ReactSortable
                                            className={"edit-table-priority"}
                                            list={this.state.list}
                                            setList={(newState) => { this.handleSetList(newState) }}>
                                            {this.state.list.map((item, key) => (
                                                <div className={"edit-table-priority-row"} key={key}>
                                                    <Icon icon='arrows' />
                                                    <div>
                                                        <p>{item.displayName}</p>
                                                        <p>{"( "+item.name+" )"}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </ReactSortable>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        <div className="modal-footer">
                            <FormGroup>
                                <ButtonToolbar className={"creator-toobar"}>
                                    <Button disabled={loading} loading={loading} appearance="primary" onClick={this.handleSubmit}>{t("BUTTON_UPDATE")}</Button>
                                    <Button disabled={loading} appearance="default" onClick={this.handleCloseModal}>{t("BUTTON_CANCEL")}</Button>
                                </ButtonToolbar>
                            </FormGroup>
                        </div>
                    </Modal>
                )}
            </Translation>
        )
    }
}

const mapStateToProps = (state) => {
    // const { projectsReducer } = state;
    return {

    }
}

const mapDispatchToProps = {

}
export default connect(mapStateToProps, mapDispatchToProps)(EditSpecificRowData)
