import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { ActionCreators as UndoActionCreators } from "redux-undo";
import Emitter from "_actions/emitter";
import i18n from "i18n";
import { ResponseStatusCode } from "_helpers";
import { addNewPage, removeComponent } from "_actions";
import { WorkspaceService } from "_services";
import {
  Button,
  Alert,
  MultiCascader,
  Loader,
  Checkbox,
  Toggle,
  SelectPicker,
} from "rsuite";
import {
  updateStore,
  updateStoreCategories,
  updateGeneratedTransactions,
  updateGeneratedModules,
  updateGeneratedInterfaces,
} from "_actions";
import { Input, Modal, Container, Row, Col } from "reactstrap";

class TransferModeDataModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modes: [
        {
          value: "0",
          label: i18n.t("INSTANCE_MODE_DEV"),
        },
        {
          value: "1",
          label: i18n.t("INSTANCE_MODE_QA"),
        },
        {
          value: "2",
          label: i18n.t("INSTANCE_MODE_PROD"),
        },
      ],
      isPreparing: false,
      isLoading: false,
      fromMode: "0",
      targetMode: "1",
      prepeparedModules: [],
      prepeparedModulesSelecteds: [],
    };
    this.myRef = React.createRef();
  }

  successAlert = (data) => {
    this.setState({
      alert: (
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={data.title}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText={i18n.t("BUTTON_OK")}
          btnSize=""
        >
          {data.desc}
        </ReactBSAlert>
      ),
    });
  };
  warningAlert = (data) => {
    this.setState({
      alert: (
        <div className={"modal-sweet-alert"}>
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title={data.title}
            onConfirm={() => this.hideAlert()}
            onCancel={() => this.hideAlert()}
            confirmBtnBsStyle="warning"
            confirmBtnText={i18n.t("BUTTON_OK")}
            btnSize=""
          >
            {data.desc}
          </ReactBSAlert>
        </div>
      ),
    });
  };
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  handlePrepareModules = () => {
    const { workspace } = this.props;
    const { fromMode } = this.state;
    const that = this;
    that.setState({ isPreparing: true });

    //Platform,ModuleIds,RelWorkspaceId

    WorkspaceService.PRAPARE_CLONE_WORKSPACE_WITH_TARGET(workspace.id, fromMode)
      .then((data) => {
        that.setState(
          {
            prepeparedModules: data.dynamicValue,
          },
          () => {
            that.handleSelectAllCopyData();
          }
        );
      })
      .catch((err) => {
        Alert.warning(ResponseStatusCode(err.statusCode));
        that.setState({ isPreparing: false });
        debugger;
      });
  };

  handleSelectAllCopyData = () => {
    let _list = [...this.state.prepeparedModules];

    let selectedIds = [];

    _list.forEach((e1) => {
      e1.modelsDetail.forEach((e2) => {
        selectedIds.push(e1.relModule.id + "-" + e2.relModel.id);
      });
    });
    this.setState({
      prepeparedModulesSelecteds: selectedIds,
      isPreparing: false,
    });
  };

  handleSelectUpdateCopyData = () => {
    let _list = [...this.state.prepeparedModules];
    debugger;
    try {
      _list.forEach((e1) => {
        e1.modelsDetail.forEach((e2) => {
          if (
            this.state.prepeparedModulesSelecteds.find(
              (x) => e1.relModule.id + "-" + e2.relModel.id == x
            )
          ) {
            e2.copyData = true;
          } else {
            e2.copyData = false;
          }
        });
      });
    } catch (error) {
      debugger;
    }

    debugger;
    return _list;
  };

  handleTransferData = async () => {
    const {
      clearHistory,
      onRefresh,
      handleToggle,
      removeComponent,
      AddPageToProject,
      workspace,
    } = this.props;
    const that = this;
    const { fromMode, prepeparedModules, targetMode } = this.state;

    that.setState({ isLoading: true });

    const prepareCopyData = await this.handleSelectUpdateCopyData();

    await WorkspaceService.TRANSFER_DATA_WORKSPACE(
      workspace.id,
      fromMode,
      targetMode,
      prepareCopyData
    )
      .then((data) => {
        debugger;
        Alert.success(i18n.t("success"));

        that.setState({ isLoading: false });
        handleToggle();
      })
      .catch((err) => {
        Alert.warning(ResponseStatusCode(err.statusCode));
        that.setState({ isLoading: false });
        debugger;
        //  let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
        //   this.warningAlert(data);
      });
  };

  customStylesForm = (e, stateName) => {
    let newState = this.state.customStyles;
    newState[stateName] = e.target.value;
    if (stateName === "checkbox") {
      if (e.target.value) {
        newState.checkboxState = "valid";
      } else {
        newState.checkboxState = "invalid";
      }
    } else {
      if (e.target.value === "") {
        newState[stateName + "State"] = "invalid";
      } else {
        newState[stateName + "State"] = "valid";
      }
    }
    this.setState({
      customStyles: newState,
    });
  };

  resetContent = () => {
    const { data } = this.props;
    const that = this;

    this.setState(
      {
        isPreparing: false,
        isLoading: false,
        customStyles: {
          pageName: "",
          pageNameState: null,
          alert: null,
          withRoles: true,
        },
        prepeparedModules: [],
        prepeparedModulesSelecteds: [],
      },
      () => {
        that.handlePrepareModules();
      }
    );
  };

  componentDidUpdate(prevProps) {
    if (this.props.isActive)
      if (prevProps.isActive != this.props.isActive && this.props.isActive) {
        this.resetContent();
      }
  }
  componentDidMount() {
    this.resetContent();
  }

  render() {
    const { isActive, handleToggle } = this.props;
    const { isLoading, isPreparing, prepeparedModules } = this.state;
    return (
      <Translation>
        {(t) => (
          <>
            <Modal
              className="modal-dialog-centered"
              isOpen={isActive}
              //size="lg"
              // scrollable={true}
            >
              <div className="modal-header">
                <h5 className="modal-title" id="CheckProjectModal">
                  {t("TITLE_MODAL_TRANSFER_DATA_TO_MODE")}
                </h5>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  disabled={this.state.isLoading}
                  onClick={() => handleToggle()}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div
                className="modal-body"
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                }}
              >
                <Container>
                  <Row style={{ marginTop: "10px", flexDirection: "column" }}>
                    <div>
                      <label
                        className="form-control-label"
                        htmlFor="validationCustomPagename"
                      >
                        {t("TITLE_TRANS_DATA_MODE_FROM")}
                      </label>
                    </div>
                    <SelectPicker
                      accepter={SelectPicker}
                      data={this.state.modes}
                      defaultValue={"0"}
                      disabled={isLoading}
                      block
                      cleanable={false}
                      value={this.state.fromMode}
                      onChange={(value) => {
                        const that = this;
                        this.setState({ fromMode: value }, () => {
                          that.handlePrepareModules();
                        });
                      }}
                      name="currentMode"
                    />
                  </Row>

                  {prepeparedModules.length > 0 && (
                    <Row style={{ marginTop: "10px", flexDirection: "column" }}>
                      <div>
                        <label
                          className="form-control-label"
                          htmlFor="validationCustomLang"
                        >
                          {t("TITLE_TRANSFER_WITH_DATA")}
                        </label>
                        <Button
                          onClick={this.handleSelectAllCopyData}
                          size="xs"
                          appearance="link"
                          style={{
                            fontSize: "10px",
                          }}
                        >
                          {"(Select all)"}
                        </Button>
                      </div>

                      <MultiCascader
                        onClean={() => {
                          this.setState({
                            prepeparedModulesSelecteds: [],
                          });
                        }}
                        cleanable={true}
                        placement={"autoVertical"}
                        onChange={(value, a, s, d) => {
                          const that = this;
                          this.setState({
                            prepeparedModulesSelecteds: value,
                          });
                        }}
                        placeholder={t("MODAL_CREATE_STORE_TEXTBOX_COPY_DATA")}
                        block
                        value={this.state.prepeparedModulesSelecteds}
                        data={[
                          ...prepeparedModules.map((item, key) => {
                            return {
                              value: item.relModule.id,
                              label: item.relModule.name,
                              children: [
                                ...item.modelsDetail.map((ii, kk) => ({
                                  value:
                                    item.relModule.id + "-" + ii.relModel.id,
                                  label:
                                    ii.relModel.modelName +
                                    ` - (${ii.dataCount} data)`,
                                  data: ii,
                                  main: item,
                                })),
                              ],
                            };
                          }),
                        ]}
                        uncheckableItemValues={[
                          ...prepeparedModules.map((item, key) => {
                            return item.relModule.id;
                          }),
                        ]}
                      />
                    </Row>
                  )}

                  <Row
                    style={{
                      marginTop: "10px",
                      flexDirection: "column",
                      paddingBottom: "10px",
                    }}
                  >
                    <div>
                      <label
                        className="form-control-label"
                        htmlFor="validationCustomPagename"
                      >
                        {t("TITLE_TRANS_DATA_MODE_TARGET")}
                      </label>
                    </div>
                    <SelectPicker
                      accepter={SelectPicker}
                      data={this.state.modes}
                      defaultValue={"0"}
                      disabled={isLoading}
                      block
                      cleanable={false}
                      value={this.state.targetMode}
                      onChange={(value) => {
                        const that = this;
                        this.setState({ targetMode: value });
                      }}
                      name="currentMode"
                    />
                  </Row>
                </Container>
              </div>
              <div className="modal-footer">
                <Button
                  color="white"
                  size={"sm"}
                  data-dismiss="modal"
                  disabled={this.state.isLoading}
                  type="button"
                  onClick={() => handleToggle()}
                >
                  {t("BUTTON_CANCEL")}
                </Button>

                <Button
                  loading={this.state.isLoading}
                  color="primary"
                  size={"sm"}
                  type="button"
                  onClick={() => this.handleTransferData()}
                >
                  {t("BUTTON_TRANSFER")}
                </Button>
              </div>
              {this.state.alert}
              {(isPreparing || isLoading) && <Loader backdrop vertical />}
            </Modal>
          </>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer, authentication } = state;
  const { loggedIn, LoginFailed, user } = authentication;

  const {
    workspace,
    workspaces,
    selectedService,
    store,
    storeCategories,
    EngineStatus,
    gitStatus,
    TerminalList,
    generatedTransactions,
    generatedModels,
    generatedInterfaces,
  } = workspaceReducer;

  return {
    workspace,
    workspaces,
    selectedService,
    store,
    storeCategories,
    user,
    EngineStatus,
    TerminalList,
    gitStatus,
    generatedTransactions,
    generatedModels,
    generatedInterfaces,
  };
};

const mapDispatchToProps = {
  updateStore,
  updateStoreCategories,
  updateGeneratedTransactions,
  updateGeneratedModules,
  updateGeneratedInterfaces,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransferModeDataModal);
