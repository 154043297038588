import React, { Component } from "react";
import { connect } from "react-redux";
import { Loader, Alert, Icon, IconStack } from "rsuite";
import ImageCropper from "components/Modals/ImageCropper";

class AvatarCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      file: null,
      isCropperActive: false,
      img: "",
    };
    this.myRef = React.createRef();
    this.inputFileSelecter = React.createRef();
  }

  handleClick = (e) => {
    const { isEditable } = this.props;
    const { loading } = this.state;
    if (isEditable && !loading) this.inputFileSelecter.current.click();
  };

  handleCropper = () => {
    this.setState({
      isCropperActive: !this.state.isCropperActive,
    });
  };

  handleCropSuccess = (_data) => {
    const { cmsKey } = this.props;
    this.props.handleUpdate(_data, cmsKey);
    this.handleCropper();
  };

  componentDidMount() {
    this.getMyIdealImage();
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps != this.props) {
      this.getMyIdealImage();
    }
  };

  getMyIdealImage = () => {
    const { renderedImages } = this.props;
    let current = this.myRef.current;
    this.setState({ img: null, loading: true });
    if (!renderedImages) {
      this.setState({ loading: false });
      return;
    }
    if (renderedImages == null) {
      this.setState({ img: null, loading: false });
      return;
    } else if (renderedImages.length == 0) {
      this.setState({ img: null, loading: false });
      return;
    }

    if (current != null) {
      let w = this.myRef.current.offsetWidth;
      let h = this.myRef.current.offsetHeight;
      if (renderedImages == null || renderedImages.length < 1) {
        this.setState({ loading: false });
        return;
      }

      let closest = null;
      try {
        if (w > h) {
          closest = renderedImages.reduce(function (prev, curr) {
            let _x = curr.width;
            let _y = curr.height;
            let _px = prev.width;
            let _py = prev.height;
            return Math.abs(_x - w) < Math.abs(_px - w) ? curr : prev;
          });
        } else {
          closest = renderedImages.reduce(function (prev, curr) {
            let _x = curr.width;
            let _y = curr.height;
            let _px = prev.width;
            let _py = prev.height;
            return Math.abs(_y - h) < Math.abs(_py - h) ? curr : prev;
          });
        }
      } catch (error) {
        //console.log(error);
      }
      if (closest != null) {
        this.setState({ img: closest.url, loading: false });
      }
    }
  };

  handleChange = (e) => {
    if (!e) var e = window.event;
    e.cancelBubble = true;
    if (e.stopPropagation) e.stopPropagation();
    try {
      this.setState(
        {
          file: URL.createObjectURL(e.target.files[0]),
        },
        () => {
          this.setState({
            isCropperActive: true,
          });
        }
      );
    } catch (error) {}
  };

  render() {
    const {
      isEditable,
      renderedImages,
      style,
      containerStyle,
      nullStyle,
      readOnly,
      isDeletable,
      handleDelete,
      cmsKey,
    } = this.props;
    const { loading, file, img } = this.state;
    return (
      <>
       
        <div
          style={containerStyle}
          className={
            (isEditable || isDeletable) && !loading
              ? "avatar-container-editable"
              : "avatar-container-viewer"
          }
          onClick={!readOnly ? this.handleClick : () => {

          }}
        >
          {img ? (
            <>
              <div
                className={"avatar-img "}
                ref={this.myRef}
                style={{
                  backgroundImage: "url(" + this.state.img + ")",
                  ...style,
                }}
              ></div>
              {isEditable && !readOnly && (
                <div
                  className={isEditable && !loading ? " avatar-img-hover " : ""}
                >
                  <Icon icon="edit" size="2x" />
                </div>
              )}

              {isDeletable && !readOnly && (
                <div
                  onClick={() => {
                    handleDelete(cmsKey);
                  }}
                  className={
                    isDeletable && !loading ? " avatar-img-hover " : ""
                  }
                >
                  <Icon icon="trash-o" size="2x" />
                </div>
              )}
            </>
          ) : readOnly || !isEditable ? (
            <div ref={this.myRef} className="avatar-readOnly-null">
              <IconStack size="2x">
                <Icon icon="image" stack="1x" style={{ color: "#575757" }} />
                <Icon icon="ban" stack="2x" style={{ color: "#57575785" }} />
              </IconStack>
            </div>
          ) : (
            <div
              style={nullStyle}
              ref={this.myRef}
              className={"project-null-img "}
            >
              +
            </div>
          )}

          {!readOnly && (
            <input
              className={"property-image-input"}
              accept="image/*"
              type="file"
              ref={this.inputFileSelecter}
              onChange={this.handleChange}
            />
          )}

          {loading && <Loader backdrop vertical />}
        </div>
        <ImageCropper
          uploadId={"5ee80d9f985684060595a131"}
          isSquare={this.props.isSquare}
          currentImage={this.state.file}
          isActive={this.state.isCropperActive}
          handleToggle={this.handleCropper}
          handleCropSuccess={this.handleCropSuccess}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AvatarCard);
