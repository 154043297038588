import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import Emitter from "_actions/emitter";
import i18n from "i18n";
import { ResponseStatusCode } from "_helpers";
import { WorkspaceUserService } from "_services";
import { Button, Alert, CheckPicker, MultiCascader } from "rsuite";

import { Input, Modal, Container, Row, Col } from "reactstrap";

class CreateUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      customStyles: {
        username: "",
        firstName: "",
        lastName: "",
        email: "",
      },
    };
  }

  handleCreateUser = () => {
    const { workspace, clearHistory, onRefresh, handleToggle } = this.props;
    let _state = this.state.customStyles;
    const that = this;
    that.setState({ isLoading: true });
    WorkspaceUserService.CREATE_USER(
      workspace.id,
      0,
      _state.username,
      _state.firstName,
      _state.lastName,
      _state.email
    )
      .then((data) => {
        debugger;
        onRefresh();
      })
      .catch((err) => {
        Alert.warning(ResponseStatusCode(err.statusCode));

        switch (err.statusCode) {
          case 186:
            Emitter.emit("WORKSPACE_URL_NOT_FOUND");
            break;

          default:
            break;
        }
        that.setState({ isLoading: false });
        //  let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
        //   this.warningAlert(data);
      });
  };

  customStylesForm = (e, stateName) => {
    let newState = this.state.customStyles;
    newState[stateName] = e.target.value;

    if (stateName === "checkbox") {
      if (e.target.value) {
        newState.checkboxState = "valid";
      } else {
        newState.checkboxState = "invalid";
      }
    } else {
      if (e.target.value === "") {
        newState[stateName + "State"] = "invalid";
      } else {
        newState[stateName + "State"] = "valid";
      }
    }
    this.setState({
      customStyles: newState,
    });
  };

  onKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      this.validateCustomStylesForm();
    }
  };

  validateCustomStylesForm = () => {
    let newState = this.state.customStyles;
    newState.username = newState.username.trim();
    newState.firstName = newState.firstName.trim();
    newState.lastName = newState.lastName.trim();
    newState.email = newState.email.trim();

    const regex = /^[a-zA-Z][a-zA-Z0-9_.]{2,}$/;
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (newState.username === "") {
      newState.usernameState = "invalid";
    } else if (!regex.test(newState.username)) {
      newState.usernameState = "invalid";
    } else {
      newState.usernameState = "valid";
    }

    if (newState.firstName === "") {
      newState.firstNameState = "invalid";
    } else {
      newState.firstNameState = "valid";
    }
    if (newState.lastName === "") {
      newState.lastNameState = "invalid";
    } else {
      newState.lastNameState = "valid";
    }

    if (newState.email === "") {
      newState.emailState = "invalid";
    } else if (!emailRegex.test(newState.email)) {
      newState.emailState = "invalid";
    } else {
      newState.emailState = "valid";
    }

    this.setState(
      {
        customStyles: newState,
      },
      () => {
        if (
          newState.usernameState == "valid" &&
          newState.firstNameState == "valid" &&
          newState.lastNameState == "valid" &&
          newState.emailState == "valid"
        ) {
          this.handleCreateUser();
        }
      }
    );
  };
  resetContent = () => {
    this.setState({
      isLoading: false,
      customStyles: {
        username: "",
        firstName: "",
        lastName: "",
        email: "",
      },
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.isActive)
      if (prevProps.isActive != this.props.isActive && this.props.isActive) {
        this.resetContent();
      }
  }

  render() {
    const { isActive, handleToggle } = this.props;
    const { isLoading } = this.state;
    return (
      <Translation>
        {(t) => (
          <>
            <Modal
              className="modal-dialog-centered"
              isOpen={isActive}
              //size="lg"
              // scrollable={true}
            >
              <div className="modal-header">
                <h5 className="modal-title" id="CreateRoleModal">
                  {t("TITLE_MODAL_CREATE_USER")}
                </h5>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  disabled={this.state.isLoading}
                  onClick={() => handleToggle()}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body">
                <Container>
                  <Row>
                    <label
                      className="form-control-label"
                      htmlFor="validationCustomUserName"
                    >
                      {t("TITLE_MODAL_CREATE_USER_USERNAME")}
                    </label>
                    <Input
                      //innerRef={(input) => { setTimeout(() => { try { input.focus() } catch (error) { } }, 300); }}
                      autoFocus
                      aria-describedby="inputGroupPrepend"
                      placeholder={t("TITLE_MODAL_CREATE_USER_USERNAME")}
                      type="text"
                      defaultValue={this.state.customStyles.username}
                      id="validationCustomusername"
                      onKeyDown={this.onKeyDown.bind(this)}
                      valid={this.state.customStyles.usernameState === "valid"}
                      invalid={
                        this.state.customStyles.usernameState === "invalid"
                      }
                      disabled={isLoading}
                      onChange={(e) => this.customStylesForm(e, "username")}
                    />

                    <div className="invalid-feedback">
                      {t("TITLE_MODAL_CREATE_USER_USERNAME_INVALID")}
                    </div>
                  </Row>

                  <Row style={{ marginTop: "10px" }}>
                    <label
                      className="form-control-label"
                      htmlFor="validationCustomfirstName"
                    >
                      {t("TITLE_MODAL_CREATE_USER_FIRSTNAME")}
                    </label>
                    <Input
                      //innerRef={(input) => { setTimeout(() => { try { input.focus() } catch (error) { } }, 300); }}
                      autoFocus
                      aria-describedby="inputGroupPrepend"
                      placeholder={t("TITLE_MODAL_CREATE_USER_FIRSTNAME")}
                      type="text"
                      defaultValue={this.state.customStyles.firstName}
                      id="validationCustomusername"
                      onKeyDown={this.onKeyDown.bind(this)}
                      valid={this.state.customStyles.firstNameState === "valid"}
                      invalid={
                        this.state.customStyles.firstNameState === "invalid"
                      }
                      disabled={isLoading}
                      onChange={(e) => this.customStylesForm(e, "firstName")}
                    />

                    <div className="invalid-feedback">
                      {t("TITLE_MODAL_CREATE_USER_FIRSTNAME_INVALID")}
                    </div>
                  </Row>

                  <Row style={{ marginTop: "10px" }}>
                    <label
                      className="form-control-label"
                      htmlFor="validationCustomlastName"
                    >
                      {t("TITLE_MODAL_CREATE_USER_LASTNAME")}
                    </label>
                    <Input
                      //innerRef={(input) => { setTimeout(() => { try { input.focus() } catch (error) { } }, 300); }}
                      autoFocus
                      aria-describedby="inputGroupPrepend"
                      placeholder={t("TITLE_MODAL_CREATE_USER_LASTNAME")}
                      type="text"
                      defaultValue={this.state.customStyles.lastName}
                      id="validationCustomusername"
                      onKeyDown={this.onKeyDown.bind(this)}
                      valid={this.state.customStyles.lastNameState === "valid"}
                      invalid={
                        this.state.customStyles.lastNameState === "invalid"
                      }
                      disabled={isLoading}
                      onChange={(e) => this.customStylesForm(e, "lastName")}
                    />

                    <div className="invalid-feedback">
                      {t("TITLE_MODAL_CREATE_USER_LASTNAME_INVALID")}
                    </div>
                  </Row>

                  <Row style={{ marginTop: "10px" }}>
                    <label
                      className="form-control-label"
                      htmlFor="validationCustomemail"
                    >
                      {t("TITLE_MODAL_CREATE_USER_EMAIL")}
                    </label>
                    <Input
                      //innerRef={(input) => { setTimeout(() => { try { input.focus() } catch (error) { } }, 300); }}
                      autoFocus
                      aria-describedby="inputGroupPrepend"
                      placeholder={t("TITLE_MODAL_CREATE_USER_EMAIL")}
                      type="text"
                      defaultValue={this.state.customStyles.email}
                      id="validationCustomusername"
                      onKeyDown={this.onKeyDown.bind(this)}
                      valid={this.state.customStyles.emailState === "valid"}
                      invalid={this.state.customStyles.emailState === "invalid"}
                      disabled={isLoading}
                      onChange={(e) => this.customStylesForm(e, "email")}
                    />

                    <div className="invalid-feedback">
                      {t("TITLE_MODAL_CREATE_USER_EMAIL_INVALID")}
                    </div>
                  </Row>
                </Container>
              </div>
              <div className="modal-footer">
                <Button
                  size={"sm"}
                  data-dismiss="modal"
                  disabled={this.state.isLoading}
                  type="button"
                  onClick={() => handleToggle()}
                >
                  {t("BUTTON_CANCEL")}
                </Button>

                <Button
                  loading={this.state.isLoading}
                  appearance="primary"
                  size={"sm"}
                  type="button"
                  onClick={() => this.validateCustomStylesForm()}
                >
                  {t("BUTTON_CREATE")}
                </Button>

                {/*
                                          <CButton
                                    color="primary"
                                    size={"sm"}
                                    loading={this.state.loading}
                                    disabled={(this.state.loading || (this.state.customStyles.nameState == "invalid"))}
                                    value={data ? t("BUTTON_UPDATE") : t("BUTTON_CREATE")}
                                    onClick={() => { this.validateCustomStylesForm() }}>
                                </CButton>
                                    */}
              </div>
              {this.state.alert}
            </Modal>
          </>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer } = state;
  const { workspace, workspaces, selectedService, storeCategories, modules } =
    workspaceReducer;

  return {
    workspace,
    workspaces,
    selectedService,
    storeCategories,
    modules,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateUser);
