import {
    currentstatesConstants
} from "../_constants";

export function setHistory(_data) {
    return {
        type: currentstatesConstants.SET_HISTORY,
        payload: {
            data: _data,
        }
    }
}

export function setOpenedPage(_page) {
    return {
        type: currentstatesConstants.SET_CURRENTPAGE,
        payload: {
            page: _page,
        }
    }
}

export function setImageUploadLoading(_status) {
    return {
        type: currentstatesConstants.SET_UPLOADIMAGELOADING,
        payload: {
            data: _status,
        }
    }
}
export function setMyStream(_data) {
    return {
        type: currentstatesConstants.SET_MYSTREAM,
        payload: {
            data: _data,
        }
    }
}
export function setProjectStream(_data) {
    return {
        type: currentstatesConstants.SET_PROJECTSTREAM,
        payload: {
            data: _data,
        }
    }
}
export function setSystemStream(_data) {
    return {
        type: currentstatesConstants.SET_SYSTEMSTREAM,
        payload: {
            data: _data,
        }
    }
}

export function setMyStreamLoading(_status) {
    return {
        type: currentstatesConstants.SET_MYSTREAMLOADING,
        payload: {
            data: _status,
        }
    }
}
export function setProjectStreamLoading(_status) {
    return {
        type: currentstatesConstants.SET_PROJECTSTREAMLOADING,
        payload: {
            data: _status,
        }
    }
}


export function setSystemStreamLoading(_status) {
    return {
        type: currentstatesConstants.SET_SYSTEMSTREAMLOADING,
        payload: {
            data: _status,
        }
    }
}

export function setPageStatus(_status) {
    return {
        type: currentstatesConstants.SET_PAGESTATUS,
        payload: {
            data: _status,
        }
    }
}

export function setOnMove(_data) {
    return {
        type: currentstatesConstants.SET_ONMOVE_STATUS,
        payload: {
            status: true,
            data: _data,
        }
    }
}

export function setOnAdd() {
    return {
        type: currentstatesConstants.SET_ONADD_STATUS,
        payload: {
            data: true,
        }
    }
}
export function setOnAddUpdate(_objFrom, _currentEditor, _objFromId) {
    return {
        type: currentstatesConstants.SET_ONADD_UPDATE,
        payload: {
            objFrom: _objFrom,
            objFromId: _objFromId,
            currentEditor: _currentEditor,
        }
    }
}


export function setOnRemove() {
    return {
        type: currentstatesConstants.SET_ONREMOVE_STATUS,
        payload: {
            data: true,
        }
    }
}
export function setOnEnd() {
    return {
        type: currentstatesConstants.SET_ONEND_STATUS,
        payload: {
            data: true,
        }
    }
}

export function setProjectId(_id) {
    return {
        type: currentstatesConstants.SET_PROJECT_ID,
        payload: {
            id: _id,
        }
    }
}

export function setStaticCollections(_data) {
    return {
        type: currentstatesConstants.SET_PROJECT_STATIC_COLLECTIONS,
        payload: {
            data: _data,
        }
    }
}

export function setDynamicCollections(_data) {
    return {
        type: currentstatesConstants.SET_PROJECT_DYNAMIC_COLLECTIONS,
        payload: {
            data: _data,
        }
    }
}

export function setDynamicDatabase(_data) {
    return {
        type: currentstatesConstants.SET_PROJECT_DYNAMIC_DB,
        payload: {
            data: _data,
        }
    }
}


export function setSnippetsUpload(_data) {
    return {
        type: currentstatesConstants.CODE_EDITOR_SNIPPETS_UPLOAD,
        payload: {
            data: _data,
        }
    }
}

