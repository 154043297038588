import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";

import i18n from "i18n";

import { Container, Header, Content, Footer, Sidebar } from "rsuite";
import { Icon, Sidenav } from "rsuite";

import {
  Navbar,
  Nav,
  Alert,
  Dropdown,
  ButtonToolbar,
  ButtonGroup,
  IconButton,
  Button,
} from "rsuite";
import { Tooltip, Whisper } from "rsuite";
import { WorkspaceService, ModelsService, TransactionService } from "_services";

import CodeEditor from "components/Custom/CodeEditor";
import CreateTransaction from "components/Modals/CreateTransaction";
import UpdateTransaction from "components/Modals/UpdateTransaction";

import FormGenerator from "components/Transaction/FormGenerator";
import ListGenerator from "components/Transaction/ListGenerator";
import UpdateDeleteFormGenerator from "components/Transaction/UpdateDeleteFormGenerator";


import {
  updateSelectedService,
  updateSelectedTransaction,
  updatedModels,
  updatedTransactions,
  updateSelectedModel,
} from "_actions";

const CustomButton = ({ child, tip }) => (
  <Whisper
    trigger="hover"
    placement={"autoVertical"}
    speaker={<Tooltip>{tip}</Tooltip>}
  >
    {child}
  </Whisper>
);

class ServiceViewLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      isCreateTrans: false,
      isUpdateTrans: false,
      //  active: this.props.selectedService && this.props.selectedService.children[0].label
    };
  }

  handleUpdateTrans = () => {
    this.setState({
      isUpdateTrans: !this.state.isUpdateTrans,
    });
  };

  handleCreateTrans = () => {
    this.setState({
      isCreateTrans: !this.state.isCreateTrans,
    });
  };

  handleSelect = (activeKey) => {
    this.setState({ active: activeKey });
  };

  onRefresh = () => {
    this.getModelsCollections();
  };

  getAllModels = async () => {
    const that = this;
    const {
      modules,
      module,
      updatedModels,
      updateSelectedModel,
      updatedTransactions,
    } = this.props;
    let _list = [];
    await modules.dynamicValue.forEach(async (e) => {
      await ModelsService.STEPBYLOAD(false, "-1", 9999, "", e.id)
        .then(async (data) => {
          await data.dynamicValue.dynamicValue.forEach((element) => {
            _list.push({
              moduleWModel: element.modelName + " - (" + e.name + ")",
              ...element,
            });
          });
        })
        .catch((err) => {
          Alert.error("Error code : " + err.statusCode);
          this.setState({ loading: false });
        });
    });
    updateSelectedModel(_list);
  };

  getModelsCollections = () => {
    const that = this;
    this.getAllModels();
    const {
      updateSelectedService,
      module,
      updatedModels,
      selectedService,
      updateSelectedModel,
      updatedTransactions,
    } = this.props;
    if (module) {
      this.setState({ loading: true });
      ModelsService.STEPBYLOAD_W_GROUP(false, "-1", 9999, "", module.id)
        .then((data) => {
          let _list = []; //data.dynamicValue.slice(0);
          let _data = data.dynamicValue;
          let _trans = [];

          data.dynamicValue.forEach((element, key) => {
            let _el = {
              ...element.model,
              label: element.model.modelName,
              value: element.model.id,
              children: [],
              isService: true,
            };

            element.transActions.forEach((item) => {
              let _view = {};
              _el.dataTypes.map((item) => {
                _view[item.name] = {
                  visible: true,
                  col: 12,
                };
              });
              _el.children.push({
                ...item,
                label: item.name,
                value: item.id,
                refId: _el.id,
                dataModelID: "",
                type: 0,
                model: [..._el.dataTypes],
                view: _view,
                controller: "",
                script: {
                  params: [],
                  code: "",
                },
              });
              _trans.push({
                ...item,
                label: item.name,
                value: item.id,
                refId: _el.id,
                dataModelID: "",
                type: 0,
                model: [..._el.dataTypes],
                view: _view,
                controller: "",
                script: {
                  params: [],
                  code: "",
                },
              });
            });
            if (_el.id === selectedService.id) {
              updateSelectedService(_el);
            }
            _list.push(_el);
          });

          //data.dynamicValue = _list;

          updatedTransactions({
            dynamicValue: _trans,
          });

          updatedModels({
            dynamicValue: _list,
          });
          /*
                                          that.updateSelectedService();
                                                        setTimeout(() => {
                                                            Emitter.emit("CHANGE_CONTROLLER_PAGE",fData)
                                                        }, 10);
                    */

          that.setState({ loading: false });
        })
        .catch((err) => {
          debugger;
          Alert.error("Error code : " + err.statusCode);
          this.setState({ loading: false });
        });
    }
  };

  render() {
    const { active } = this.state;
    const {selectedService}=this.props;
    return (
      <Translation>
        {(t) => (
          <>
            <Container
              className={"custom-fade-in"}
              style={{ width: "100%", height: "100%" }}
            >
              <Header className={"nav-service-header"}>
                <Navbar appearance="subtle">
                  {!this.props.sidebarActive && (
                    <Navbar.Header
                      className={"nav-service-navbar-header custom-fade-in"}
                      style={{ marginLeft: 10 }}
                    >
                      {this.props.selectedService.label}
                    </Navbar.Header>
                  )}

                  <Navbar.Body>
                    <Nav></Nav>
                    <Nav pullRight>
                      <ButtonToolbar className={"header-nav-buttons"}>
                      { /*
                        {this.props.selectedService && (
                          <>
                            <CustomButton
                              child={
                                <IconButton
                                  onClick={this.handleUpdateTrans}
                                  size="sm"
                                  circle
                                  color="green"
                                  icon={<Icon icon="edit2" />}
                                  active
                                  placement="right"
                                />
                              }
                              tip={t("BUTTON_UPDATE")}
                            />
                            <CustomButton
                              child={
                                <IconButton
                                  size="sm"
                                  circle
                                  color="red"
                                  icon={<Icon icon="close" />}
                                  active
                                  placement="right"
                                />
                              }
                              tip={t("BUTTON_CANCEL")}
                            />
                          </>
                        )}

                        <CustomButton
                          child={
                            <IconButton
                              onClick={this.handleCreateTrans}
                              size="sm"
                              circle
                              color="yellow"
                              icon={<Icon icon="plus" />}
                              active
                              placement="right"
                            />
                          }
                          tip={t("BUTTON_CREATE_TRANSACTION")}
                        />
                        */}
                      </ButtonToolbar>
                    </Nav>
                  </Navbar.Body>
                </Navbar>
              </Header>
              <Container>
                <Content className={"custom-fade-in mvc-div"}>
                  {
                    /*
                                            <CodeEditor
                                                key={active+"JS"}
                                                funcName={active + "Service"}
                                                funcParams={["param"]}
                                                value={"//default value "+active}
                                                onChangeScript={(script) => {
                                                    // console.log(script)
                                                }}
                                            />
                                        */
                    selectedService && (
                      <>
                        {selectedService.responseModelType ? (
                          <ListGenerator />
                        ) : (selectedService.functionName === "update" ||
                          selectedService.functionName === "delete") ? (
                          <UpdateDeleteFormGenerator/>
                        ) : (
                          <FormGenerator />
                        )}
                      </>
                    )
                  }
                </Content>
              </Container>
            </Container>
            <CreateTransaction
              onRefresh={this.onRefresh}
              isActive={this.state.isCreateTrans}
              handleToggle={this.handleCreateTrans}
            />
            <UpdateTransaction
              onRefresh={this.onRefresh}
             // script={this.state.script}
              isActive={this.state.isUpdateTrans}
              handleToggle={this.handleUpdateTrans}
            />
          </>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer } = state;
  const {
    models,
    workspaces,
    workspace,
    selectedService,
    services,
    modules,
    module,
  } = workspaceReducer;

  return {
    workspaces,
    workspace,
    modules,
    module,
    selectedService,
    services,
    models,
  };
};

const mapDispatchToProps = {
  updateSelectedService,
  updateSelectedTransaction,
  updatedModels,
  updateSelectedModel,
  updatedTransactions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceViewLayout);
