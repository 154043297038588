import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { ActionCreators as UndoActionCreators } from "redux-undo";
import Emitter from "_actions/emitter";
import i18n from "i18n";
import { ResponseStatusCode } from "_helpers";
import { WorkspaceService, ModelsService, TransactionService } from "_services";
import { Button, Alert, SelectPicker, Nav, Toggle } from "rsuite";
import {
  updateSelectedService,
  updatedModels,
  updatedTransactions,
  updateSelectedModel,
} from "_actions";
import Slider from "react-slick";

import { Input, Modal, Container, Row, Col } from "reactstrap";

class CreateTransaction extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      langs: [],
      activeTab: "basic",
      lastupdate: new Date(),
      customStyles: {
        transName: "",
        transNameState: null,
        funcName: "",
        funcNameState: null,
        shortName: "",
        shortNameState: null,
        desc: "",
        descState: null,
        rModelType: "",
        rModelTypeState: null,
        resModelId: "",
        resModelIdState: null,
        reqModelId: "",
        reqModelIdState: null,
        reqModelType: "",
        reqModelTypeState: null,
        allowUnauthorizedAccess: false,
        passReqAndResParamsToTransaction: false,
        hideFromDashboard: false,
        alert: null,
      },
    };
  }

  handleTabSelect = (activeKey) => {
    const that = this;
    this.setState({ activeTab: activeKey }, () => {
      that.slider.slickGoTo(activeKey == "basic" ? 0 : 1);
    });
  };

  successAlert = (data) => {
    this.setState({
      alert: (
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={data.title}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText={i18n.t("BUTTON_OK")}
          btnSize=""
        >
          {data.desc}
        </ReactBSAlert>
      ),
    });
  };
  warningAlert = (data) => {
    this.setState({
      alert: (
        <div className={"modal-sweet-alert"}>
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title={data.title}
            onConfirm={() => this.hideAlert()}
            onCancel={() => this.hideAlert()}
            confirmBtnBsStyle="warning"
            confirmBtnText={i18n.t("BUTTON_OK")}
            btnSize=""
          >
            {data.desc}
          </ReactBSAlert>
        </div>
      ),
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  /*
    [{
                "lang": "en",
                "textValue": _state.desc
            }]
    TestTransSingle
    */
  handleCreateTransModal = () => {
    const {
      selectedService,
      workspace,
      clearHistory,
      onRefresh,
      handleToggle,
      removeComponent,
      AddPageToProject,
    } = this.props;
    let _state = this.state.customStyles;
    const that = this;
    that.setState({ isLoading: true });
    TransactionService.CREATE_TRANSACTION(
      _state.transName,
      _state.shortName.toUpperCase(),
      _state.funcName,
      [
        {
          lang: "en",
          textValue: _state.desc,
        },
      ],
      selectedService.id,
      _state.reqModelId, //ReqModelId
      "",
      selectedService.relModuleId,
      workspace.id,
      _state.resModelId, //RelResponseModelId
      _state.rModelType,
      true, // Pasif - active settings
      0,
      _state.reqModelType,
      _state.allowUnauthorizedAccess,
      _state.passReqAndResParamsToTransaction,
      _state.hideFromDashboard,
      _state.editableList
    )
      .then((data) => {
        debugger;
        that.props.onRefresh();
        // onRefresh("", workspace.id, true);
        Alert.success(i18n.t("MESSAGE_CREATE_TRANS_SUCCESS"));

        handleToggle();
      })
      .catch((err) => {
        Alert.warning(ResponseStatusCode(err.statusCode));
        that.setState({ isLoading: false });
        debugger;
        //  let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
        //   this.warningAlert(data);
      });
  };

  customStylesForm = (e, stateName) => {
    let newState = this.state.customStyles;
    newState[stateName] = e.target.value;
    if (stateName === "checkbox") {
      if (e.target.value) {
        newState.checkboxState = "valid";
      } else {
        newState.checkboxState = "invalid";
      }
    } else {
      if (e.target.value === "") {
        newState[stateName + "State"] = "invalid";
      } else {
        newState[stateName + "State"] = "valid";
      }
    }
    this.setState({
      customStyles: newState,
      lastupdate: new Date(),
    });
  };

  onKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      this.validateCustomStylesForm();
    }
  };

  validateCustomStylesForm = () => {
    let newState = this.state.customStyles;
    newState.transName = newState.transName.trim();

    var regex = /^[a-zA-Z][a-zA-Z0-9_-]{1,}$/;

    if (newState.transName === "") {
      newState.transNameState = "invalid";
    } else if (!regex.test(newState.transName)) {
      newState.transNameState = "invalid";
    } else {
      newState.transNameState = "valid";
    }

    if (newState.funcName === "") {
      newState.funcNameState = "invalid";
    } else if (!regex.test(newState.funcName)) {
      newState.funcNameState = "invalid";
    } else {
      newState.funcNameState = "valid";
    }

    if (newState.shortName === "") {
      newState.shortNameState = "invalid";
    } else if (!regex.test(newState.shortName)) {
      newState.shortNameState = "invalid";
    } else {
      newState.shortNameState = "valid";
    }

    if (newState.rModelType === "") {
      newState.rModelTypeState = "invalid";
      Alert.error(i18n.t("MODAL_CREATE_TRANS_RMODELTYPE_INVALID"));
    } else {
      newState.rModelTypeState = "valid";
    }

    if (newState.resModelId === "") {
      newState.resModelIdState = "invalid";
      Alert.error(i18n.t("MODAL_CREATE_TRANS_RESMODEL_INVALID"));
    } else {
      newState.resModelIdState = "valid";
    }

    if (newState.reqModelId === "") {
      newState.reqModelIdState = "invalid";
      Alert.error(i18n.t("MODAL_CREATE_TRANS_REQMODEL_INVALID"));
    } else {
      newState.reqModelIdState = "valid";
    }

    this.setState(
      {
        customStyles: newState,
      },
      () => {
        if (
          newState.transNameState == "valid" &&
          newState.funcNameState == "valid" &&
          newState.shortNameState == "valid" &&
          newState.rModelTypeState == "valid"
        ) {
          this.handleCreateTransModal();
        }
      }
    );
  };
  resetContent = () => {
    const { selectedService } = this.props;
    this.setState({
      isLoading: false,
      activeTab: "basic",
      lastupdate: new Date(),
      customStyles: {
        transName: "",
        transNameState: null,
        funcName: "",
        funcNameState: null,
        shortName: "",
        shortNameState: null,
        desc: "",
        descState: null,
        rModelType: "0",
        rModelTypeState: null,
        resModelId: selectedService.id,
        resModelIdState: null,
        reqModelId: selectedService.id,
        reqModelIdState: null,
        reqModelType: "0",
        reqModelTypeState: null,
        editableList:true,
        allowUnauthorizedAccess: false,
        passReqAndResParamsToTransaction: false,
        hideFromDashboard: false,
        alert: null,
      },
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isActive)
      if (prevProps.isActive != this.props.isActive && this.props.isActive) {
        this.resetContent();
      }
  }

  render() {
    const { isActive, handleToggle, model } = this.props;
    const { isLoading, customStyles, activeTab } = this.state;
    const settings = {
      dots: false,
      infinite: false,
      speed: 10,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipe: false,
      adaptiveHeight: true,
    };
    return (
      <Translation>
        {(t) => (
          <>
            <Modal
              className="modal-dialog-centered"
              isOpen={isActive}
              size={activeTab == "basic" ? "md" : "lg"}
              //size="lg"
              // scrollable={true}
            >
              <div className="modal-header">
                <h5 className="modal-title" id="CheckProjectModal">
                  {t("MODAL_CREATE_TRANSACTION")}
                </h5>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  disabled={this.state.isLoading}
                  onClick={() => handleToggle()}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body" style={{ paddingTop: 0 }}>
                <Slider
                  ref={(slider) => (this.slider = slider)}
                  {...settings}
                  className="menu-carusel-trans"
                >
                  <div>
                    <Container>
                      <Row>
                        <label
                          className="form-control-label"
                          htmlFor="validationCustomtransName"
                        >
                          {t("MODAL_CREATE_TRANS_TEXTBOX_NAME")}
                        </label>
                        <Input
                          //innerRef={(input) => { setTimeout(() => { try { input.focus() } catch (error) { } }, 300); }}
                          autoFocus
                          aria-describedby="inputGroupPrepend"
                          placeholder={t("MODAL_CREATE_TRANS_TEXTBOX_NAME")}
                          type="text"
                          defaultValue={this.state.customStyles.transName}
                          id="validationCustomtransName"
                          onKeyDown={this.onKeyDown.bind(this)}
                          valid={
                            this.state.customStyles.transNameState === "valid"
                          }
                          invalid={
                            this.state.customStyles.transNameState === "invalid"
                          }
                          disabled={isLoading}
                          onChange={(e) =>
                            this.customStylesForm(e, "transName")
                          }
                        />

                        <div className="invalid-feedback">
                          {t("MODAL_CREATE_TRANS_TEXTBOX_NAME_INVALID")}
                        </div>
                      </Row>
                      <Row>
                        <label
                          className="form-control-label"
                          htmlFor="validationCustomshortName"
                        >
                          {t("MODAL_CREATE_TRANS_TEXTBOX_SNAME")}
                        </label>
                        <Input
                          //innerRef={(input) => { setTimeout(() => { try { input.focus() } catch (error) { } }, 300); }}
                          autoFocus
                          aria-describedby="inputGroupPrepend"
                          placeholder={t("MODAL_CREATE_TRANS_TEXTBOX_SNAME")}
                          type="text"
                          defaultValue={this.state.customStyles.shortName}
                          id="validationCustomshortName"
                          onKeyDown={this.onKeyDown.bind(this)}
                          valid={
                            this.state.customStyles.shortNameState === "valid"
                          }
                          invalid={
                            this.state.customStyles.shortNameState === "invalid"
                          }
                          disabled={isLoading}
                          onChange={(e) =>
                            this.customStylesForm(e, "shortName")
                          }
                        />

                        <div className="invalid-feedback">
                          {t("MODAL_CREATE_TRANS_TEXTBOX_SNAME_INVALID")}
                        </div>
                      </Row>
                      <Row>
                        <label
                          className="form-control-label"
                          htmlFor="validationCustomfuncsName"
                        >
                          {t("MODAL_CREATE_TRANS_TEXTBOX_FNAME")}
                        </label>
                        <Input
                          //innerRef={(input) => { setTimeout(() => { try { input.focus() } catch (error) { } }, 300); }}
                          autoFocus
                          aria-describedby="inputGroupPrepend"
                          placeholder={t("MODAL_CREATE_TRANS_TEXTBOX_FNAME")}
                          type="text"
                          defaultValue={this.state.customStyles.funcName}
                          id="validationCustomfuncsName"
                          onKeyDown={this.onKeyDown.bind(this)}
                          valid={
                            this.state.customStyles.funcNameState === "valid"
                          }
                          invalid={
                            this.state.customStyles.funcNameState === "invalid"
                          }
                          disabled={isLoading}
                          onChange={(e) => this.customStylesForm(e, "funcName")}
                        />

                        <div className="invalid-feedback">
                          {t("MODAL_CREATE_TRANS_TEXTBOX_FNAME_INVALID")}
                        </div>
                      </Row>
                      <Row style={{ marginTop: "10px" }}>
                        <label>{t("MODAL_CREATE_TRANS_TEXTBOX_DESC")}</label>
                        <Input
                          type="textarea"
                          placeholder={t("MODAL_CREATE_TRANS_TEXTBOX_DESC")}
                          disabled={isLoading}
                          onChange={(e) => this.customStylesForm(e, "desc")}
                        />
                      </Row>
                      <Row
                        style={{ marginTop: "10px", flexDirection: "column" }}
                      >
                        <label
                          className="form-control-label"
                          htmlFor="validationCustomLang"
                        >
                          {t("MODAL_CREATE_TRANS_RES_MODEL")}
                        </label>
                        <SelectPicker
                          placement="autoVerticalStart"
                          onSelect={(value) => {
                            let _cs = this.state.customStyles;
                            _cs.resModelId = value;
                            _cs.resModelIdState = "valid";
                            this.setState({
                              customStyles: _cs,
                              lastupdate: new Date(),
                            });
                          }}
                          defaultValue={this.state.customStyles.resModelId}
                          placeholder={t("MODAL_CREATE_TRANS_RES_MODEL")}
                          block
                          data={
                            model &&
                            model.map((x) => {
                              return {
                                label: x.moduleWModel,
                                value: x.id,
                                data: x,
                              };
                            })
                          }
                          cleanable={false}
                        />
                      </Row>
                      <Row
                        style={{ marginTop: "10px", flexDirection: "column" }}
                      >
                        <label
                          className="form-control-label"
                          htmlFor="validationCustomLang"
                        >
                          {t("MODAL_CREATE_TRANS_TEXTBOX_RESPONSETYPE")}
                        </label>
                        <SelectPicker
                          onSelect={(value) => {
                            let _cs = this.state.customStyles;
                            _cs.rModelType = value;
                            _cs.rModelTypeState = "valid";
                            this.setState({
                              customStyles: _cs,
                              lastupdate: new Date(),
                            });
                          }}
                          defaultValue={this.state.customStyles.rModelType}
                          placeholder={t(
                            "MODAL_CREATE_TRANS_TEXTBOX_RESPONSETYPE"
                          )}
                          block
                          data={[
                            {
                              label: t("ResponseModelTypeSingle"),
                              value: "0",
                            },
                            {
                              label: t("ResponseModelTypeList"),
                              value: "1",
                            },
                          ]}
                          cleanable={false}
                        />
                      </Row>
                      <Row
                        style={{ marginTop: "10px", flexDirection: "column" }}
                      >
                        <label
                          className="form-control-label"
                          htmlFor="validationCustomLang"
                        >
                          {t("MODAL_CREATE_TRANS_REQ_MODEL")}
                        </label>
                        <SelectPicker
                          placement="autoVerticalStart"
                          onSelect={(value) => {
                            let _cs = this.state.customStyles;
                            _cs.reqModelId = value;
                            _cs.reqModelIdState = "valid";
                            this.setState({
                              customStyles: _cs,
                              lastupdate: new Date(),
                            });
                          }}
                          defaultValue={this.state.customStyles.reqModelId}
                          placeholder={t("MODAL_CREATE_TRANS_REQ_MODEL")}
                          block
                          data={
                            model && [
                              ...model.map((x) => {
                                return {
                                  label: x.moduleWModel,
                                  value: x.id,
                                  data: x,
                                };
                              }),
                              {
                                value: "642f18cb7b3ea775633c2447",
                                label: "ANY",
                                data: {
                                  id: "642f18cb7b3ea775633c2447",
                                  moduleWModel: "ANY",
                                },
                              },
                            ]
                          }
                          cleanable={false}
                        />
                      </Row>
                      <Row
                        style={{ marginTop: "10px", flexDirection: "column" }}
                      >
                        <label
                          className="form-control-label"
                          htmlFor="validationCustomLang"
                        >
                          {t("MODAL_CREATE_TRANS_TEXTBOX_REQTYPE")}
                        </label>
                        <SelectPicker
                          placement="autoVerticalStart"
                          onSelect={(value) => {
                            let _cs = this.state.customStyles;
                            _cs.reqModelType = value;
                            _cs.reqModelTypeState = "valid";
                            this.setState({
                              customStyles: _cs,
                              lastupdate: new Date(),
                            });
                          }}
                          defaultValue={this.state.customStyles.reqModelType}
                          placeholder={t("MODAL_CREATE_TRANS_TEXTBOX_REQTYPE")}
                          block
                          data={[
                            {
                              label: t("ReqModelTypeSingle"),
                              value: "0",
                            },
                            {
                              label: t("ReqModelTypeList"),
                              value: "1",
                            },
                          ]}
                          cleanable={false}
                        />
                      </Row>

                      <Row
                        style={{ marginTop: "10px", flexDirection: "column" }}
                      >
                        <label
                          className="form-control-label"
                          htmlFor="validationCustomLang"
                        >
                          {t("MODAL_CREATE_TRANS_EDITABLELIST")}
                        </label>
                        <Toggle
                          style={{ width: "44px" }}
                          onChange={(value) => {
                            let _cs = this.state.customStyles;
                            _cs.editableList = value;
                            this.setState({
                              customStyles: _cs,
                              lastupdate: new Date(),
                            });
                          }}
                          defaultChecked={
                            this.state.customStyles.editableList
                          }
                        />
                      </Row>

                      <Row
                        style={{ marginTop: "10px", flexDirection: "column" }}
                      >
                        <label
                          className="form-control-label"
                          htmlFor="validationCustomLang"
                        >
                          {t("MODAL_CREATE_TRANS_TEXTBOX_ALLOWUNAUTHACCESS")}
                        </label>
                        <Toggle
                          style={{ width: "44px" }}
                          onChange={(value) => {
                            let _cs = this.state.customStyles;
                            _cs.allowUnauthorizedAccess = value;
                            this.setState({
                              customStyles: _cs,
                              lastupdate: new Date(),
                            });
                          }}
                          defaultChecked={
                            this.state.customStyles.allowUnauthorizedAccess
                          }
                        />
                      </Row>

                      <Row
                        style={{ marginTop: "10px", flexDirection: "column" }}
                      >
                        <label
                          className="form-control-label"
                          htmlFor="validationCustomLang"
                        >
                          {t("MODAL_CREATE_TRANS_TEXTBOX_PASSREQANDRESPARAMS")}
                        </label>
                        <Toggle
                          style={{ width: "44px" }}
                          onChange={(value) => {
                            let _cs = this.state.customStyles;
                            _cs.passReqAndResParamsToTransaction = value;
                            this.setState({
                              customStyles: _cs,
                              lastupdate: new Date(),
                            });
                          }}
                          defaultChecked={
                            this.state.customStyles
                              .passReqAndResParamsToTransaction
                          }
                        />
                      </Row>
                      <Row
                        style={{ marginTop: "10px", flexDirection: "column" }}
                      >
                        <label
                          className="form-control-label"
                          htmlFor="validationCustomLang"
                        >
                          {t("MODAL_CREATE_TRANS_HIDE_FROM_DASHBOARD")}
                        </label>
                        <Toggle
                          style={{ width: "44px" }}
                          onChange={(value) => {
                            let _cs = this.state.customStyles;
                            _cs.hideFromDashboard = value;
                            this.setState({
                              customStyles: _cs,
                              lastupdate: new Date(),
                            });
                          }}
                          defaultChecked={
                            this.state.customStyles.hideFromDashboard
                          }
                        />
                      </Row>
                    </Container>
                  </div>
                  <div>
                    <Container>
                      <Row>Advanced Side</Row>
                    </Container>
                  </div>
                </Slider>
              </div>
              <div className="modal-footer">
                <Button
                  size={"sm"}
                  data-dismiss="modal"
                  disabled={this.state.isLoading}
                  type="button"
                  onClick={() => handleToggle()}
                >
                  {t("BUTTON_CANCEL")}
                </Button>

                <Button
                  loading={this.state.isLoading}
                  appearance="primary"
                  size={"sm"}
                  type="button"
                  onClick={() => this.validateCustomStylesForm()}
                >
                  {t("BUTTON_CREATE")}
                </Button>

                {/*
                                          <CButton
                                    color="primary"
                                    size={"sm"}
                                    loading={this.state.loading}
                                    disabled={(this.state.loading || (this.state.customStyles.nameState == "invalid"))}
                                    value={data ? t("BUTTON_UPDATE") : t("BUTTON_CREATE")}
                                    onClick={() => { this.validateCustomStylesForm() }}>
                                </CButton>
                                    */}
              </div>
              {this.state.alert}
            </Modal>
          </>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer } = state;
  const {
    transaction,
    models,
    model,
    workspaces,
    workspace,
    selectedService,
    services,
    modules,
    module,
  } = workspaceReducer;

  return {
    workspaces,
    workspace,
    modules,
    module,
    selectedService,
    services,
    models,
    model,
    transaction,
  };
};

const mapDispatchToProps = {
  updateSelectedService,
  updatedModels,
  updateSelectedModel,
  updatedTransactions,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateTransaction);
