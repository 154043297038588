import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import Emitter from "_actions/emitter";
import i18n from "i18n";
import { ResponseStatusCode } from "_helpers";
import { erpRolesService, erpRolesAndUserRelationsService } from "_services";
import { Button, Alert, Icon, Popover, Whisper, List, Loader,Tag, TagGroup,IconButton } from "rsuite";

import { Input, Modal, Container, Row, Col } from "reactstrap";

class SelectRoleList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      roles: [],
    };
  }

  getRoles = (_search = "") => {
    const { updateModules, workspace, module } = this.props;
    const that = this;
    that.setState({ isLoading: true });
    erpRolesService
      .GET_ROLES(true, "-1", 9999, _search, workspace.id)
      .then((data) => {
        if (data.operationResult) {
          this.setState({
            roles: data.dynamicValue.dynamicValue,
          });

          //  updateStore(data.dynamicValue)
        }
        that.setState({ isLoading: false });
      })
      .catch((err) => {
        that.setState({ isLoading: false });
        Alert.error(ResponseStatusCode(err.statusCode));
        debugger;
      });
  };

  resetContent = () => {
    const { data, roles } = this.props;
    this.getRoles();
    this.setState({
      isLoading: false,
      roles: [],
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.isActive)
      if (prevProps.isActive != this.props.isActive && this.props.isActive) {
        this.resetContent();
      }
  }

  render() {
    const { isActive, handleToggle, data, handleAdd } = this.props;
    const { isLoading, roles } = this.state;
    return (
      <Translation>
        {(t) => (
          <>
            <Modal className="modal-dialog-centered" isOpen={isActive}>
              <div className="modal-header">
                <h5 className="modal-title" id="CreateRoleModal">
                  {t("TITLE_MODAL_ROLES_LIST")}
                </h5>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  disabled={this.state.isLoading}
                  onClick={() => handleToggle()}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div>
                <Container>
                  {roles.length > 0 ? (
                    <List hover={true}>
                      {roles.map((item, key) => (
                        <List.Item
                          style={{ cursor: "pointer" }}
                          key={key}
                          onClick={() => {
                            handleAdd(item);
                          }}
                        >
                          <div className={"role-list-item"}>
                            {item.roleName}
                            <Whisper
                              placement="auto"
                              trigger="hover"
                              enterable={true}
                              speaker={<Popover style={{maxWidth:"50%", padding:"1em"}} title={item.roleName}>
                              <TagGroup>
                              {item.roles.map((ii, kk) => (
                                <Tag
                                  className="role-tag"
                                  key={key + "" + item.id + "-" + kk}
                                >
                                  {ii.short}
                                </Tag>
                              ))}
                            </TagGroup>
                            </Popover>}
                            >
                               <IconButton appearance="subtle" icon={<Icon icon="info" />} circle size="xs" />
                            </Whisper>
                          </div>
                        </List.Item>
                      ))}
                    </List>
                  ) : (
                    <div className={"store-not-found"}>
                      <Icon icon={"exclamation-triangle"} />
                      {t("ROLES_NO_RESULT")}
                    </div>
                  )}
                </Container>
                {isLoading && (
                  <div className={"fade-in"}>
                    <Loader backdrop vertical />
                  </div>
                )}
              </div>
              <div className="modal-footer">
                <Button
                  size={"sm"}
                  data-dismiss="modal"
                  disabled={this.state.isLoading}
                  type="button"
                  onClick={() => handleToggle()}
                >
                  {t("BUTTON_CANCEL")}
                </Button>
              </div>
              {this.state.alert}
            </Modal>
          </>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer } = state;
  const { workspace, workspaces, selectedService, storeCategories, modules } =
    workspaceReducer;

  return {
    workspace,
    workspaces,
    selectedService,
    storeCategories,
    modules,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(SelectRoleList);
