import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import moment from "moment";
import Emitter from "_actions/emitter";
import i18n from "i18n";
import { ResponseStatusCode } from "_helpers";
import { updateGlobalScripts } from "_actions";
import { GlobalScriptService } from "_services";
import { Loader, Container, Alert } from "rsuite";
import { Modal } from "reactstrap";

import ScriptEditor from "components/GlobalScript/ScriptEditor";
import ScriptList from "components/GlobalScript/ScriptList";
import SwipeableViews from "react-swipeable-views";

import GlobalScriptCreate from "./GlobalScriptCreate";

let LANG = i18n.t("LANG");
const WAIT_INTERVAL = 500;
const ENTER_KEY = 13;

class GlobalScriptModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      script: "",
      selectedScript: null,
      step: 0,
      isCreate: false,
    };
    this.terminal = React.createRef();
  }

  handleCreateScript = () => {
    this.setState({ isCreate: !this.state.isCreate });
  };

  resetContent = () => {
    const that = this;
    this.setState(
      {
        isLoading: false,
        script: "",
        selectedScript: null,
        step: 0,
        isCreate: false,
      },
      () => {
        that.handleGetScripts();
      }
    );
  };
  componentDidUpdate(prevProps) {
    if (this.props.isActive)
      if (prevProps.isActive != this.props.isActive && this.props.isActive) {
        this.resetContent();
      }
  }

  componentWillMount() {
    this.timer = null;
  }

  handleGetScripts = () => {
    const { workspace, updateGlobalScripts } = this.props;
    const that = this;
    this.setState({ loading: true });

    GlobalScriptService.GET_GLOBALS(workspace.relWorkspaceId)
      .then((data) => {
        if (data.operationResult) {
          updateGlobalScripts(data.dynamicValue);
        }
        that.setState({ loading: false });
      })
      .catch((err) => {
        that.setState({ loading: false });
        let data = {
          title: i18n.t("warning"),
          desc: ResponseStatusCode(err.statusCode),
        };
        Alert.error(data.desc);
        debugger;
      });
  };

  handleToggleLocal = () => {
    const { isActive, handleToggle } = this.props;
    const { loading } = this.state;

    if (!loading) {
      handleToggle();
    }
  };

  handleShowList = () => {
    this.setState({
      isLoading: false,
      script: "",
      selectedScript: null,
      step: 0,
      isCreate: false,
    });
  };
  handleShowEditor = (_script) => {
    const that = this;
    this.setState({ step: 1, selectedScript: _script });
  };

  render() {
    const { isActive, handleToggle } = this.props;
    const { loading, step, selectedScript } = this.state;
    const that = this;

    return (
      <Translation>
        {(t) => (
          <>
            <Modal
              className="modal-dialog-centered"
              isOpen={isActive}
              size="lg"
              scrollable={true}
            >
              <div>
                <div className="modal-header store-model-header">
                  <h5 className="modal-title">
                    {selectedScript
                      ? selectedScript.scriptName + " " + t("TITLE_SCRIPT")
                      : t("GLOBAL_SCRIPT_TITLE")}
                  </h5>
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    disabled={this.state.isLoading}
                    onClick={() => handleToggle()}
                  >
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
              </div>
              <div className="modal-body store-model-body">
                <Container>
                  <div
                    style={{
                      height: "50vh",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <SwipeableViews
                      style={{ height: "50vh" }}
                      index={step}
                      ref={(el) => (this.reactSwipeEl = el)}
                    >
                      <div className={"global-script-view"}>
                        <ScriptList onCreate={this.handleCreateScript} onSelectScript={this.handleShowEditor} />
                      </div>
                      <div className={"global-script-view"}>
                        {step ? (
                          <ScriptEditor
                            file={selectedScript}
                            onSubmit={this.resetContent}
                            onCancel={this.handleShowList}
                          />
                        ) : (
                          <div />
                        )}
                      </div>
                    </SwipeableViews>
                  </div>
                  {loading && (
                    <div className={"fade-in"}>
                      <Loader backdrop vertical />
                    </div>
                  )}
                </Container>
              </div>
            </Modal>
            <GlobalScriptCreate
              isActive={this.state.isCreate}
              onRefresh={this.resetContent}
              handleToggle={this.handleCreateScript}
            />
          </>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer, authentication } = state;
  const { loggedIn, LoginFailed, user } = authentication;

  const { workspace, workspaces, selectedService, store, storeCategories } =
    workspaceReducer;

  return {
    workspace,
    workspaces,
    selectedService,
    store,
    storeCategories,
    user,
  };
};

const mapDispatchToProps = {
  updateGlobalScripts,
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalScriptModal);
