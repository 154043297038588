import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, Button, Loader, Alert, List } from "rsuite";
import { Container, Icon, IconButton } from "rsuite";

import { Translation } from "react-i18next";
import { erpLangService } from "_services";
import {
  updateWorkspaceLangs,
  updateSupportedLangs,
  updateWorkspaceTextAndValues,
  updateWorkspaceDupTextAndValues,
} from "_actions";
import SwipeableViews from "react-swipeable-views";
import { Cell, Column, HeaderCell, Table } from "rsuite-table";
import i18n from "i18n";

class LangOptions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  handleReset = () => {
    this.setState({
      loading: false,
    });
  };

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    const { isActive, handleToggle, workspaceAllTextAndValues } = this.props;
    const that = this;
    if (prevProps.isActive !== isActive && !isActive) {
      setTimeout(() => {
        that.handleReset();
      }, 1000);
    }
  }

  handleSortColumn(sortColumn, sortType) {
    this.setState({
      loading: true,
    });

    setTimeout(() => {
      this.setState({
        sortColumn,
        sortType,
        loading: false,
      });
    }, 500);
  }

  GET_LANGS = () => {
    const that = this;
    const { workspace, updateWorkspaceLangs } = this.props;
    that.setState({ loading: true });
    erpLangService
      .GET_LANGS()
      .then((data) => {
        if (data.operationResult) {
          updateWorkspaceLangs(data.dynamicValue);
        }
        that.setState({ loading: false });
      })
      .catch((err) => {
        that.setState({ loading: false });
        debugger;
      });
  };

  GET_SUPPORTEDLANGS = () => {
    const that = this;
    const { workspace, updateSupportedLangs } = this.props;
    that.setState({ loading: true });
    erpLangService
      .GET_SUPPORTEDLANGS(workspace.relWorkspaceId)
      .then((data) => {
        if (data.operationResult) {
          updateSupportedLangs(data.dynamicValue);
        }
        that.setState({ loading: false });
      })
      .catch((err) => {
        that.setState({ loading: false });
        debugger;
      });
  };

  GET_WORKSPACE_LANGS = () => {
    const that = this;
    const { workspace, updateWorkspaceTextAndValues } = this.props;
    that.setState({ loading: true });
    erpLangService
      .GET_WORKSPACE_LANGS(workspace.relWorkspaceId)
      .then((data) => {
        if (data.operationResult) {
          debugger;
          updateWorkspaceTextAndValues(data.dynamicValue);
        }
        that.setState({ loading: false });
      })
      .catch((err) => {
        that.setState({ loading: false });
        debugger;
      });
  };

  ADD_NEW_LANGS = (Lang_id) => {
    const that = this;
    const { workspace, updateSupportedLangs } = this.props;
    that.setState({ loading: true });
    erpLangService
      .ADD_NEW_LANGS(workspace.relWorkspaceId, Lang_id)
      .then((data) => {
        if (data.operationResult) {
          Alert.success(i18n.t("SUCCESS_UPDATE"));
          that.GET_SUPPORTEDLANGS();
        } else {
          Alert.error(i18n.t("ERROR_UPDATE"));
        }
        that.setState({ loading: false });
      })
      .catch((err) => {
        Alert.error(i18n.t("ERROR_UPDATE"));
        that.setState({ loading: false });
        debugger;
      });
  };
  REMOVE_LANGS_SUPPORT = (Lang_id) => {
    const that = this;
    const { workspace, updateSupportedLangs } = this.props;
    that.setState({ loading: true });
    erpLangService
      .REMOVE_LANGS_SUPPORT(workspace.relWorkspaceId, Lang_id)
      .then((data) => {
        if (data.operationResult) {
          Alert.success(i18n.t("SUCCESS_UPDATE"));
          that.GET_SUPPORTEDLANGS();
        } else {
          Alert.error(i18n.t("ERROR_UPDATE"));
        }
        that.setState({ loading: false });
      })
      .catch((err) => {
        Alert.error(i18n.t("ERROR_UPDATE"));
        that.setState({ loading: false });
        debugger;
      });
  };

  GET_WORKSPACE_DUPLICATED_LANGS = () => {
    const that = this;
    const { workspace, updateWorkspaceDupTextAndValues } = this.props;
    that.setState({ loading: true });
    erpLangService
      .GET_WORKSPACE_DUPLICATED_LANGS(workspace.relWorkspaceId)
      .then((data) => {
        if (data.operationResult) {
          updateWorkspaceDupTextAndValues(data.dynamicValue);
        }
        that.setState({ loading: false });
      })
      .catch((err) => {
        that.setState({ loading: false });
        debugger;
      });
  };

  UPDATE_WORKSPACE_DUPLICATED_LANGS = () => {
    const that = this;
    const { workspace } = this.props;

    const { selectedCollection, formValues } = this.state;
    let _relObject = JSON.parse(JSON.stringify(selectedCollection.ref));
    _relObject.langsValues = formValues;
    debugger;
    that.setState({ loading: true });
    erpLangService
      .UPDATE_WORKSPACE_DUPLICATED_LANGS(workspace.relWorkspaceId, _relObject)
      .then((data) => {
        if (data.operationResult) {
          debugger;
          that.handleSelected(null);
          //updateWorkspaceDupTextAndValues(data.dynamicValue);
        }
        that.setState({ loading: false });
      })
      .catch((err) => {
        that.setState({ loading: false });
        debugger;
      });
  };

  UPDATE_WORKSPACE_ONE_LANGS = () => {
    const that = this;
    const { workspace } = this.props;
    const { selectedCollection, formValues } = this.state;
    let _relObject = JSON.parse(JSON.stringify(selectedCollection.ref));
    _relObject.langsValues = formValues;
    debugger;
    that.setState({ loading: true });
    erpLangService
      .UPDATE_WORKSPACE_ONE_LANGS(workspace.relWorkspaceId, _relObject)
      .then((data) => {
        if (data.operationResult) {
          debugger;
          that.handleSelected(null);
          //updateWorkspaceDupTextAndValues(data.dynamicValue);
        }
        that.setState({ loading: false });
      })
      .catch((err) => {
        that.setState({ loading: false });
        debugger;
      });
  };

  render() {
    const { isActive, handleToggle, supportedLangs, workspaceLangs } =
      this.props;
    const { loading } = this.state;
    return (
      <Translation>
        {(t) => (
          <>
            <Modal
              className="lang-setting-modal"
              size="xs"
              overflow={true}
              show={isActive}
              onHide={handleToggle}
              backdrop={!loading}
            >
              <Modal.Header>
                <Modal.Title>
                  {t("TITLE_MODAL_LANGS_EDIT_SETTINGS")}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="modal-body store-model-body">
                  <Container>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <List bordered>
                        {supportedLangs.map((item, index) => (
                          <List.Item
                            className="langs-list-option"
                            key={index + "current"}
                            index={index}
                          >
                            {item.langLong}
                            <IconButton
                              appearance="ghost"
                              color="red"
                              size="xs"
                              onClick={() => this.REMOVE_LANGS_SUPPORT(item.id)}
                              // circle
                              icon={<Icon icon={"trash-o"} />}
                            />
                          </List.Item>
                        ))}
                        {workspaceLangs
                          .filter(
                            (x) => !supportedLangs.some((e) => e.id === x.id)
                          )
                          .map((item, index) => (
                            <List.Item
                              className="langs-list-option"
                              key={index + "other"}
                              index={index}
                            >
                              {item.langLong}
                              <IconButton
                                appearance="ghost"
                                color="green"
                                size="xs"
                                onClick={() => this.ADD_NEW_LANGS(item.id)}
                                // circle
                                icon={<Icon icon={"plus"} />}
                              />
                            </List.Item>
                          ))}
                      </List>
                    </div>
                    {loading && (
                      <div className={"fade-in"}>
                        <Loader backdrop vertical />
                      </div>
                    )}
                  </Container>
                </div>
              </Modal.Body>
            </Modal>
          </>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer } = state;
  const {
    model,
    models,
    transaction,
    transactions,
    workspaces,
    workspace,
    dataModels,
    selectedService,
    services,
    allServices,
    allTransactions,
    workspaceAllTextAndValues,
    workspaceDuplicatedTextAndValues,
    workspaceLangs,
    supportedLangs,
  } = workspaceReducer;

  return {
    workspace,
    transaction,
    transactions,
    workspaces,
    dataModels,
    models,
    model,
    selectedService,
    services,
    allServices,
    allTransactions,

    workspaceAllTextAndValues,
    workspaceDuplicatedTextAndValues,
    workspaceLangs,
    supportedLangs,
  };
};

const mapDispatchToProps = {
  updateWorkspaceLangs,
  updateSupportedLangs,
  updateWorkspaceTextAndValues,
  updateWorkspaceDupTextAndValues,
};

export default connect(mapStateToProps, mapDispatchToProps)(LangOptions);
