import i18n from 'i18n';


export const contentTypes = [
    {
        id: 1,
        name: "CONTENT_TYPE_TEXT",
        detail: "CONTENT_TYPE_TEXT_DETAIL"
    },
    {
        id: 2,
        name: "CONTENT_TYPE_RICHTEXT",
        detail: "CONTENT_TYPE_RICHTEXT_DETAIL"
    },
    {
        id: 3,
        name: "CONTENT_TYPE_NUMBER",
        detail: "CONTENT_TYPE_NUMBER_DETAIL"
    },
    {
        id: 4,
        name: "CONTENT_TYPE_DATE",
        detail: "CONTENT_TYPE_DATE_DETAIL"
    },
    {
        id: 5,
        name: "CONTENT_TYPE_RELATIONS",
        detail: "CONTENT_TYPE_RELATIONS_DETAIL"
    },
    {
        id: 10,
        name: "CONTENT_TYPE_REFMODEL",
        detail: "CONTENT_TYPE_REFMODEL_DETAIL"
    },
    {
        id: 13,
        name: i18n.t("CONTENT_TYPE_LIST"),
        detail: i18n.t("CONTENT_TYPE_LIST_DETAIL"),
    },
    {
        id: 6,
        name: "CONTENT_TYPE_ENUM",
        detail: "CONTENT_TYPE_ENUM_DETAIL"
    },
    {
        id: 7,
        name: "CONTENT_TYPE_BOOLEAN",
        detail: "CONTENT_TYPE_BOOLEAN_DETAIL"
    },
    {
        id: 8,
        name: "CONTENT_TYPE_IDENTITY",
        detail: "CONTENT_TYPE_IDENTITY_DETAIL"
    },
    {
        id: 9,
        name: "CONTENT_TYPE_IMAGE",
        detail: "CONTENT_TYPE_IMAGE_DETAIL"
    },
    {
        id: 11,
        name: i18n.t("CONTENT_TYPE_CURRENT_USER"),
        detail: i18n.t("CONTENT_TYPE_CURRENT_USER_DETAIL"),
    },
    {
        id: 12,
        name: i18n.t("CONTENT_TYPE_PROCESSING_TIME"),
        detail: i18n.t("CONTENT_TYPE_PROCESSING_TIME_DETAIL"),
    },
    {
        id: 14,
        name: i18n.t("CONTENT_TYPE_BUTTON"),
        detail: i18n.t("CONTENT_TYPE_BUTTON_DETAIL"),
    },
    {
        id: 15,
        name: i18n.t("CONTENT_TYPE_DOCUMENT_SINGLE"),
        detail: i18n.t("CONTENT_TYPE_DOCUMENT_SINGLE_DETAIL"),
    },
    {
        id: 16,
        name: i18n.t("CONTENT_TYPE_DOCUMENT_MULTI"),
        detail: i18n.t("CONTENT_TYPE_DOCUMENT_MULTI_DETAIL"),
    },
    {
        id: 17,
        name: i18n.t("CONTENT_TYPE_ANY"),
        detail: i18n.t("CONTENT_TYPE_ANY_MULTI_DETAIL"),
    },
   
   
];

export const typeDetails = [
    {
        id: 1,
        name: "TYPE_DETAIL_STANDART_TEXT",
        detail: "TYPE_DETAIL_STANDART_TEXT_DETAIL"
    },
    {
        id: 2,
        name: "TYPE_DETAIL_PASSWORD",
        detail: "TYPE_DETAIL_PASSWORD_DETAIL"
    },
    {
        id: 3,
        name: "TYPE_DETAIL_EMAIL",
        detail: "TYPE_DETAIL_EMAIL_DETAIL"
    },
    {
        id: 4,
        name: "TYPE_DETAIL_INT",
        detail: "TYPE_DETAIL_INT_DETAIL"
    },
 /*   {
        id: 5,
        name: "TYPE_DETAIL_BIGINT",
        detail: "TYPE_DETAIL_BIGINT_DETAIL"
    },
    {
        id: 6,
        name: "TYPE_DETAIL_DECIMAL",
        detail: "TYPE_DETAIL_DECIMAL_DETAIL"
    },
    {
        id: 7,
        name: "TYPE_DETAIL_FLOAT",
        detail: "TYPE_DETAIL_FLOAT_DETAIL"
    },
    */
    {
        id: 8,
        name: "TYPE_DETAIL_IMAGE_SINGLE",
        detail: "TYPE_DETAIL_IMAGE_SINGLE_DETAIL",
    },
    {
        id: 9,
        name: "TYPE_DETAIL_IMAGE_MULTIPLE",
        detail: "TYPE_DETAIL_IMAGE_MULTIPLE_DETAIL"
    },
    {
        id: 10,
        name: "TYPE_DETAIL_LIST_TEXT",
        detail: "TYPE_DETAIL_LIST_TEXT_DETAIL"
    },
    {
        id: 11,
        name: "TYPE_DETAIL_LIST_NUMBER",
        detail: "TYPE_DETAIL_LIST_NUMBER_DETAIL"
    },
    {
        id: 12,
        name: "TYPE_DETAIL_SINGLE",
        detail: "TYPE_DETAIL_SINGLE_DETAIL"
    },
    {
        id: 13,
        name: "TYPE_DETAIL_LIST",
        detail: "TYPE_DETAIL_LIST_DETAIL"
    },
];

//! Number NOT: INT TIPLERI GEÇİCİ OLARAK KALDIRILDI


export const contentTypesSP = [
    {
        label: i18n.t("CONTENT_TYPE_TEXT"),
        value: "1",
    },
    {
        label: i18n.t("CONTENT_TYPE_RICHTEXT"),
        value: "2",
    }, {
        label: i18n.t("CONTENT_TYPE_NUMBER"),
        value: "3",
    },
    {
        label: i18n.t("CONTENT_TYPE_DATE"),
        value: "4",
    },
    {
        label: i18n.t("CONTENT_TYPE_RELATIONS"),
        value: "5",
    },
    {
        label: i18n.t("CONTENT_TYPE_REFMODEL"),
        value: "10"
    },
    {
        label: i18n.t("CONTENT_TYPE_LIST"),
        value: "13",
    },
    {
        label: i18n.t("CONTENT_TYPE_ENUM"),
        value: "6",
    },
    {
        label: i18n.t("CONTENT_TYPE_BOOLEAN"),
        value: "7",
    },
    {
        label: i18n.t("CONTENT_TYPE_IDENTITY"),
        value: "8",
    },
    {
        label: i18n.t("CONTENT_TYPE_IMAGE"),
        value: "9",
    },
    {
        label: i18n.t("CONTENT_TYPE_CURRENT_USER"),
        value: "11",
    },
    {
        label: i18n.t("CONTENT_TYPE_PROCESSING_TIME"),
        value: "12",
    },
    {
        label: i18n.t("CONTENT_TYPE_BUTTON"),
        value: "14",
    },
    {
        label: i18n.t("CONTENT_TYPE_DOCUMENT_SINGLE"),
        value: "15",
    },
    {
        label: i18n.t("CONTENT_TYPE_DOCUMENT_MULTI"),
        value: "16",
    },
    {
        label: i18n.t("CONTENT_TYPE_ANY"),
        value: "17",
    },
   
]

export const typeDetailsSP = [
    {
        label: i18n.t("TYPE_DETAIL_STANDART_TEXT"),
        value: "1",
        role: "1",
    },
    {
        label: i18n.t("TYPE_DETAIL_PASSWORD"),
        value: "2",
        role: "1",

    }, {
        label: i18n.t("TYPE_DETAIL_EMAIL"),
        value: "3",
        role: "1",

    },
    {
        label: i18n.t("TYPE_DETAIL_INT"),
        value: "4",
        role: "3",

    },
   /* {
        label: i18n.t("TYPE_DETAIL_BIGINT"),
        value: "5",
        role: "3",

    },
    {
        label: i18n.t("TYPE_DETAIL_DECIMAL"),
        value: "6",
        role: "3",

    },
    {
        label: i18n.t("TYPE_DETAIL_FLOAT"),
        value: "7",
        role: "3",

    }*/
    {
        label: i18n.t("TYPE_DETAIL_IMAGE_SINGLE"),
        value: "8",
        role: "9",

    },
    {
        label: i18n.t("TYPE_DETAIL_IMAGE_MULTIPLE"),
        value: "9",
        role: "9",
    },
    {
        label: i18n.t("TYPE_DETAIL_LIST_TEXT"),
        value: "10",
        role: "13",
    },
    {
        label: i18n.t("TYPE_DETAIL_LIST_NUMBER"),
        value: "11",
        role: "13",
    },
    {
        label: i18n.t("TYPE_DETAIL_SINGLE"),
        value: "12",
        role: "17",
    },
    {
        label: i18n.t("TYPE_DETAIL_LIST"),
        value: "13",
        role: "17",
    },
]

//! Number NOT: INT TIPLERI GEÇİCİ OLARAK KALDIRILDI
