import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";

import i18n from "i18n";

import {
  Divider,
  Icon,
  Loading,
  Button,
  Alert,
  IconButton,
  Modal,
  Progress,
} from "rsuite";
const { Circle } = Progress;

class MigrationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formValue: {
        projectName: "",
      },
      formError: {},
      loading: false,
      prg: 51,
    };
  }
  handleCloseModal = () => {
    const { handleToggle } = this.props;
    this.setState(
      {
        formValue: {
          projectName: "",
        },
        formError: {},
        loading: false,
      },
      () => {
        handleToggle();
      }
    );
  };

  render() {
    const { isActive, handleToggle, status, handleRefresh, migrationId } =
      this.props;

    return (
      <Translation>
        {(t) => (
          <Modal
            className="workspace-mig-modal"
            size="sm"
            show={isActive}
            onHide={handleToggle}
          >
            <Modal.Body style={{ margin: 0, padding: 0 }}>
              <div className="workspace-mig-modal-body">
                <div className="workspace-mig-progress-div">
                  <div className="workspace-mig-progress">
                    <Circle percent={status == "1" ? 0 : 51} status="active" />
                  </div>
                </div>

                <div>
                  {status == "1" ? (
                    <h2>{t("STATUS_WORKSPACE_WAITING")}</h2>
                  ) : (
                    <h2>{t("STATUS_WORKSPACE_PROGRESS")}</h2>
                  )}
                  <div className="workspace-mig-message">
                    <label>{t("TITLE_CHECK_LAST_STATUS")}</label>
                  </div>
                  <div className="workspace-mig-buttons">
                    <Button
                      style={{ marginRight: "10px" }}
                      size="sm"
                      onClick={handleToggle}
                    >
                      {t("BUTTON_CANCEL")}
                    </Button>
                    <Button
                      size="sm"
                      appearance="primary"
                      onClick={() => handleRefresh(migrationId)}
                    >
                      {t("BUTTON_REFRESH")}
                    </Button>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(MigrationModal);

/*
 <Modal.Body style={{ margin: 0, padding: 0 }}>
              <div className="workspace-mig-modal-body">
                <div className="workspace-mig-progress">
                  <Circle percent={status == "1" ? 0 : 51} status="active" />
                </div>
                {
                    status == "1" ?
                    <h2>{t("STATUS_WORKSPACE_WAITING")}</h2>:
                    <h2>{t("STATUS_WORKSPACE_PROGRESS")}</h2>
                }
 
                <div className="workspace-mig-message">
                  <label>{t("TITLE_CHECK_LAST_STATUS")}</label>
                </div>
                <Button onClick={handleRefresh}>{t("BUTTON_REFRESH")}</Button>
              </div>
            </Modal.Body>

*/
