import React, { Component } from "react";
import { sortableContainer } from "react-sortable-hoc";
 
const SortableContainer1 = sortableContainer(({children}) => {
  return <ul className="dndscontainer">{children}</ul>;
});

export default function SortableContainer(props) {
  return <SortableContainer1 {...props} />;
}
