import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";

import i18n from "i18n";
import { Modal, Container, Row, Col, Card, CardBody } from "reactstrap";
import Slider from "react-slick";
import AvatarCard from "components/Custom/AvatarCard";
import DynamicTag from "components/Custom/DynamicTag";

import {
  Toggle,
  Icon,
  Popover,
  Tooltip,
  Whisper,
  Loading,
  Button,
  Alert,
  Animation,
} from "rsuite";
import {
  Tag,
  TagGroup,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  SelectPicker,
  InputNumber,
  Schema,
  DatePicker,
  ButtonToolbar,
  Nav,
} from "rsuite";

import { decamelize } from "assets/globalOps/global";
import { isoLangs } from "assets/globalOps/isoLangs";
import {
  contentTypesSP,
  typeDetailsSP,
} from "assets/globalOps/CollectionTypes";
const { StringType, NumberType, ArrayType, DateType, ObjectType, BooleanType } =
  Schema.Types;

const model = Schema.Model({});

class CreateRowData extends Component {
  constructor(props) {
    super(props);
    this.triggerRefRelationModel = React.createRef();
    this.triggerRefColums = React.createRef();
    this.triggerRelationType = React.createRef();

    this.state = {
      formValue: {},
      formError: {},
      advanceFormValue: {},
      advanceFormError: {},
      activeTab: "basic",
      loading: false,
    };
  }
  handleTabSelect = (activeKey) => {
    const that = this;
    this.setState({ activeTab: activeKey }, () => {
      that.slider.slickGoTo(activeKey == "basic" ? 0 : 1);
    });
  };
  componentDidUpdate(prevProps, prevState) {
    let formValue = Object.assign({}, this.state.formValue);
    let { isActive, schemaModel, schemaModel2, isDynamic } = this.props;

    if (isActive && !isDynamic) {
      if (prevState.formValue != formValue) {
        const _typeDetails = typeDetailsSP.filter(
          (x) => x.role == formValue["contentType"]
        );

        if (
          _typeDetails.length == 0 &&
          formValue.hasOwnProperty("typeDetail")
        ) {
          delete formValue.typeDetail;
          this.setState({
            formValue: formValue,
          });
        }
      }
    }

    if (prevProps.isActive !== this.props.isActive) {
      if (isActive && isDynamic) {
        let _model = Schema.Model({});
        if (this.state._model) {
          _model = this.state._model;
        } else {
          const _typeDetails = typeDetailsSP.filter(
            (x) => x.role == this.state.formValue["contenttype"]
          );
          const _dynamicSchemaModel =
            isActive && isDynamic && this.prepareGenerateSchema();
          _model = isDynamic
            ? _dynamicSchemaModel
              ? _dynamicSchemaModel
              : undefined
            : schemaModel
            ? _typeDetails && _typeDetails.length > 0
              ? schemaModel2
              : schemaModel
            : model;
        }

        _model = Schema.Model({
          ...Object.keys(_model.schema).reduce(
            (c, k) => ((c[k] = _model.schema[k]), c),
            {}
          ),
        });

        this.setState({
          _model: _model,
        });
      }

      if (isActive && formValue.hasOwnProperty("contentType")) {
        if (
          prevState.formValue &&
          prevState.formValue.hasOwnProperty("contentType")
        ) {
          // geçmişte enum yapılmış
          if (
            formValue["contentType"] != "6" &&
            formValue.hasOwnProperty("defaultData")
          ) {
            //debugger

            //defaultData formValue'dan silinmeli
            let __model = this.state._model;
            delete __model.schema.defaultData;

            let _nObj = Object.assign({}, formValue);
            delete _nObj.defaultData;
            delete schemaModel.schema.defaultData;

            this.setState({
              _model: __model,
              formValue: _nObj,
            });
          }
        } else if (
          formValue["contentType"] == "6" &&
          !formValue.hasOwnProperty("defaultData")
        ) {
          let __model = this.state._model;
          __model.schema["defaultdata"] = ArrayType()
            .isRequired(i18n.t("ERROR_DM_GLOBAL_REQUIRED_ENUM"))
            .minLength(2, i18n.t("ERROR_DM_GLOBAL_REQUIRED_ENUM"));

          this.setState({
            _model: __model,
            formValue: {
              ...this.state.formValue,
              defaultData: [],
            },
          });
        }
      }
    }
  }

  handleSubmit = (e) => {
    const that = this;
    const { formValue, formError, _model } = this.state;
    const { schemaModel, schemaModel2 } = this.props;
    debugger;
    if (!this.form.check()) {
      debugger;
      if (formValue.contentType == "5") {
        if (formValue.hasOwnProperty("RelationModel")) {
          if (
            this.triggerRefRelationModel.current &&
            !formValue.RelationModel.hasOwnProperty("RelModel")
          ) {
            this.triggerRefRelationModel.current.open();
          } else if (
            this.triggerRefColums.current &&
            !formValue.RelationModel.hasOwnProperty("ListColumn")
          ) {
            this.triggerRefColums.current.open();
          } else if (
            this.triggerRelationType.current &&
            !formValue.RelationModel.hasOwnProperty("RelationType")
          ) {
            this.triggerRelationType.current.open();
          } else {
            try {
              this.triggerRefRelationModel.current.close();
              this.triggerRefColums.current.close();
              this.triggerRelationType.current.close();
            } catch (error) {}
          }
        } else {
          this.triggerRefRelationModel.current &&
            this.triggerRefRelationModel.current.open();
          return;
        }
      }
      return;
    }
    // alert("başarılı")
    // return
    this.props.handleCreate(this.state.formValue);
    this.handleCloseModal();
  };

  handleCloseModal = () => {
    const { handleToggle } = this.props;
    this.setState(
      {
        formValue: {},
        formError: {},
        advanceFormValue: {},
        advanceFormError: {},
        activeTab: "basic",
        loading: false,
      },
      () => {
        handleToggle();
      }
    );
  };

  prepareGenerateSchema = () => {
    const { isDynamic, dataModel } = this.props;
    let model = {}; // Schema.Model({});

    dataModel.map((item, key) => {
      if (!item.privateField) {
        let _obj = {};
        switch (item.contentType) {
          case 1:
            // String
            _obj = StringType();
            break;
          case 2:
            // Rich Text
            _obj = StringType();
            break;
          case 3:
            // Number
            _obj = NumberType();
            break;
          case 4:
            // Date
            _obj = DateType();
            break;
          case 5:
            // Relations
            _obj = ObjectType();
            break;
          case 6:
            // Enum
            _obj = StringType();
            break;
          case 7:
            // Boolean
            _obj = BooleanType();
            break;
          case 8:
            // Identity
            _obj = StringType();
            break;
          case 9:
            // Image
            _obj = ObjectType();
            break;
          case 10:
            // Ref Model
            _obj = ObjectType();
            break;
          case 11:
            // Relations
            _obj = ObjectType(); // ObjectType();
            break;
          case 12:
            // Relations
            _obj = ObjectType(); // ObjectType();
            break;
          case 13:
            // Relations
            _obj = ArrayType(); // ObjectType();
            break;
            case 15:
              // Relations
              _obj = ObjectType();
              break;
            case 16:
              // Relations
              _obj = ObjectType(); // ObjectType();
              break;
              case 17:
                // Relations
                _obj = ObjectType(); // ObjectType();
                break;
          default:
            break;
        }
        if (item.reqiured || item.notNull) {
          _obj.isRequired(i18n.t("ERROR_DM_GLOBAL_REQUIRED"));
        }
        if (item.uniq) {
        }

        switch (item.typeDetail) {
          case 1:
            // STANDART
            break;
          case 2:
            // PASSWORD
            break;
          case 3:
            // EMAIL
            _obj.isEmail(i18n.t("ERROR_DM_GLOBAL_EMAIL_FORMAT"));
            break;
          case 4:
            // INT
            _obj.isInteger(i18n.t("ERROR_DM_GLOBAL_INT"));
            break;
          case 5:
            // BIGINT
            _obj.isInteger(i18n.t("ERROR_DM_GLOBAL_INT"));
            break;
          case 6:
            // DECIMAL
            break;
          case 7:
            // DOUBLE
            break;
          default:
            break;
        }

        if (
          item.contentType !== 8 &&
          item.contentType !== 12 &&
          item.contentType !== 11 &&
          item.contentType !== 14 &&
          item.contentType !== 17
        ) {
          model[item.name] = _obj;
        }
      }
    });
    // debugger
    // model

    return Schema.Model(model);
  };

  handleUpdatePP = (_data, cmsKey) => {
    let _formValue = this.state.formValue;
    _formValue[cmsKey] = _data;
    this.setState({
      formValue: _formValue,
    });
  };

  handleUpdateEnum = (_data) => {
    const { formValue } = this.state;
    formValue.defaultData = _data;
    this.setState({
      formValue: formValue,
    });
  };

  //{this.getCurrentLangDisplayName(item)}
  getCurrentLangDisplayName = (item) => {
    try {
      const _lang = this.props.currentLang;
      if (_lang) {
        let _langDNM = item.displayNameMultiLangs.find((x) => x.lang == _lang);
        if (_langDNM && _langDNM.textValue.trim().length > 0) {
          return _langDNM.textValue;
        } else {
          _langDNM = item.displayNameMultiLangs.find((x) => x.lang == "en");
          return _langDNM.textValue;
        }
      } else {
        const _langDNM = item.displayNameMultiLangs.find((x) => x.lang == "en");
        return _langDNM.textValue;
      }
    } catch (error) {
      return item.hasOwnProperty("displayName")
        ? item.displayName
        : decamelize(item.name, " ");
    }
  };

  render() {
    const {
      isActive,
      handleToggle,
      dataModel,
      schemaModel,
      schemaModel2,
      isDynamic,
      models,
      model,
      collectionName,
      allTransactions,
    } = this.props;
    const { formValue, formError, loading, advanceFormValue } = this.state;

    const _typeDetails = typeDetailsSP.filter(
      (x) => x.role == formValue["contentType"]
    );

    const _dynamicSchemaModel =
      isActive && isDynamic && this.prepareGenerateSchema();

    const _model = isDynamic
      ? _dynamicSchemaModel
        ? _dynamicSchemaModel
        : undefined
      : schemaModel
      ? _typeDetails && _typeDetails.length > 0
        ? schemaModel2
        : schemaModel
      : model;

    const Locale = {
      sunday: i18n.t("sunday"),
      monday: i18n.t("monday"),
      tuesday: i18n.t("tuesday"),
      wednesday: i18n.t("wednesday"),
      thursday: i18n.t("thursday"),
      friday: i18n.t("friday"),
      saturday: i18n.t("saturday"),
      ok: i18n.t("ok"),
      today: i18n.t("today"),
      yesterday: i18n.t("yesterday"),
      hours: i18n.t("hours"),
      minutes: i18n.t("minutes"),
      seconds: i18n.t("seconds"),
    };
    const settings = {
      dots: false,
      infinite: false,
      speed: 10,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipe: false,
      adaptiveHeight: true,
    };

    return (
      <Translation>
        {(t) => (
          <Modal
            className="modal-dialog-centered"
            isOpen={isActive}
            //size="lg"
            //toggle={this.handleCloseModal}
            scrollable={true}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="CheckProjectModal">
                {t("TITLE_MODAL_CREATE_ROW")}
                {collectionName ? " (" + collectionName + ") " : ""}
              </h5>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => this.handleCloseModal()}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body" style={{ padding: 0 }}>
              <Nav
                appearance="subtle"
                activeKey={this.state.activeTab}
                onSelect={this.handleTabSelect}
                style={{ marginBottom: "1em", padding: "0 1em" }}
              >
                <Nav.Item eventKey="basic">
                  {t("TITLE_TRANSACTION_SETTINGS_BASIC")}
                </Nav.Item>
                {false && (
                  <Nav.Item eventKey="advanced">
                    {t("TITLE_TRANSACTION_SETTINGS_ADVANCED")}
                  </Nav.Item>
                )}
              </Nav>

              <Slider
                ref={(slider) => (this.slider = slider)}
                {...settings}
                className="menu-carusel-trans"
              >
                <div>
                  <Container>
                    <Row>
                      <Col
                        style={{ marginBottom: "15px", padding: "0px 1.25rem" }}
                      >
                        <Form
                          ref={(ref) => (this.form = ref)}
                          onChange={(formValue) => {
                            const {
                              isActive,
                              handleToggle,
                              dataModel,
                              schemaModel,
                              schemaModel2,
                              isDynamic,
                              models,
                            } = this.props;
                            const that = this;

                            if (
                              formValue.contentType == "5" &&
                              !schemaModel.schema.hasOwnProperty(
                                "RelationModel"
                              )
                            ) {
                              schemaModel.schema.RelationModel = ObjectType();
                              schemaModel.schema.RelationModel.isRequired(
                                i18n.t("ERROR_DM_GLOBAL_REQUIRED")
                              );
                              schemaModel.schema.RelationModel.shape({
                                ListColumn: StringType().isRequired(
                                  i18n.t("ERROR_DM_GLOBAL_REQUIRED")
                                ),
                                RelModel: ObjectType().isRequired(
                                  i18n.t("ERROR_DM_GLOBAL_REQUIRED")
                                ),
                                RelationType: NumberType().isRequired(
                                  i18n.t("ERROR_DM_GLOBAL_REQUIRED")
                                ),
                              });
                            } else if (
                              formValue.contentType !== "5" &&
                              schemaModel.schema.hasOwnProperty("RelationModel")
                            ) {
                              delete schemaModel.schema.RelationModel;
                            } else if (
                              formValue.contentType == "10" &&
                              !schemaModel.schema.hasOwnProperty("RefModel")
                            ) {
                              schemaModel.schema.RefModel = ObjectType();
                              schemaModel.schema.RefModel.isRequired(
                                i18n.t("ERROR_DM_GLOBAL_REQUIRED")
                              );
                            } else if (
                              formValue.contentType !== "10" &&
                              schemaModel.schema.hasOwnProperty("RefModel")
                            ) {
                              delete schemaModel.schema.RefModel;
                            } else {
                            }

                            if (formValue.contentType !== "5") {
                              let _new = Object.assign({}, formValue);
                              delete _new.IsAutopopulate;
                              delete _new.RelationModel;

                              this.setState({ formValue: _new });
                            } else if (formValue.contentType !== "10") {
                              let _new = Object.assign({}, formValue);
                              delete _new.RefModel;

                              this.setState({ formValue: _new });
                            } else {
                              this.setState({ formValue });
                            }
                          }}
                          onCheck={(formError) => {
                            this.setState({ formError });
                          }}
                          className={"dynamic-create-form"}
                          formValue={formValue}
                          model={_model}
                          fluid
                        >
                          {dataModel &&
                            dataModel.map(
                              (item, key) =>
                                !item.privateField &&
                                item.contentType != 8 &&
                                (item.contentType == "typeDetails" ? (
                                  _typeDetails &&
                                  _typeDetails.length > 0 && (
                                    <FormGroup
                                      className={"custom-fade-in"}
                                      key={key}
                                    >
                                      <ControlLabel className={"cell-header"}>
                                        {this.getCurrentLangDisplayName(item)}
                                      </ControlLabel>
                                      <FormControl
                                        cleanable={false}
                                        block
                                        placement="autoVerticalStart"
                                        accepter={SelectPicker}
                                        data={_typeDetails}
                                        disabled={item.privateField || loading}
                                        name={item.name}
                                      />
                                    </FormGroup>
                                  )
                                ) : (
                                  <FormGroup key={key}>
                                    <ControlLabel className={"cell-header"}>
                                      {this.getCurrentLangDisplayName(item)}
                                    </ControlLabel>
                                    {item.contentType == "string" ||
                                    item.contentType == 1 ? (
                                      <FormControl
                                        type={
                                          item["typeDetail"] == 2
                                            ? "password"
                                            : "text"
                                        }
                                        disabled={item.privateField || loading}
                                        name={item.name}
                                        className={"custom-fade-in"}
                                      />
                                    ) : item.contentType == "boolean" ||
                                      item.contentType == 7 ? (
                                      <FormControl
                                        accepter={Toggle}
                                        disabled={item.privateField || loading}
                                        name={item.name}
                                        className={"custom-fade-in"}
                                      />
                                    ) : item.contentType == "contentTypes" ? (
                                      <>
                                        <FormControl
                                          cleanable={false}
                                          block
                                          placement="autoVerticalStart"
                                          accepter={SelectPicker}
                                          data={contentTypesSP}
                                          disabled={
                                            item.privateField || loading
                                          }
                                          name={item.name}
                                          className={"custom-fade-in"}
                                        />
                                        {
                                          //ENUM type
                                          formValue["contentType"] == "6" && (
                                            <>
                                              <div
                                                className={"dynamic-dt-side"}
                                              >
                                                <div
                                                  className={
                                                    "dynamic-enum-side"
                                                  }
                                                >
                                                  <DynamicTag
                                                    data={
                                                      formValue.defaultData
                                                        ? formValue.defaultData
                                                        : []
                                                    }
                                                    handleUpdate={
                                                      this.handleUpdateEnum
                                                    }
                                                  />
                                                </div>{" "}
                                                <div
                                                  className={
                                                    "dynamic-dt-popover"
                                                  }
                                                >
                                                  {this.state.formError.hasOwnProperty(
                                                    "defaultData"
                                                  ) && (
                                                    <Popover
                                                      visible
                                                      className={
                                                        "custom-white-tooltip"
                                                      }
                                                    >
                                                      <p>
                                                        {t(
                                                          "ERROR_DM_GLOBAL_REQUIRED_ENUM"
                                                        )}{" "}
                                                      </p>
                                                    </Popover>
                                                  )}
                                                </div>
                                              </div>
                                            </>
                                          )
                                        }
                                        {formValue["contentType"] == "5" && (
                                          <>
                                            <ControlLabel
                                              style={{ marginTop: "24px" }}
                                              className={"cell-header"}
                                            >
                                              {t(
                                                "MODAL_CREATEROWDATA_TITLE_DBMODEL"
                                              )}
                                            </ControlLabel>
                                            <Whisper
                                              trigger="none"
                                              ref={this.triggerRefRelationModel}
                                              placement={"bottomStart"}
                                              speaker={
                                                <Popover
                                                  visible
                                                  className={
                                                    "custom-white-tooltip"
                                                  }
                                                >
                                                  <p>
                                                    {t(
                                                      "ERROR_DM_GLOBAL_REQUIRED"
                                                    )}{" "}
                                                  </p>
                                                </Popover>
                                              }
                                            >
                                              <SelectPicker
                                                data={
                                                  model &&
                                                  model
                                                    .filter((x) => {
                                                      return (
                                                        x.isDatabaseObject ||
                                                        x.isDataBaseFalseObjectAllowTransaction
                                                      );
                                                    })
                                                    .map((x) => {
                                                      return {
                                                        label: x.moduleWModel,
                                                        value: x, //x.id,
                                                        data: x,
                                                      };
                                                    })
                                                }
                                                onChange={(value) => {
                                                  this.triggerRefRelationModel.current.close();

                                                  let _obj = Object.assign(
                                                    {},
                                                    this.state.formValue
                                                  ); //
                                                  let _RelationModel = {
                                                    RelModel: {
                                                      id: value.id,
                                                      ModelName:
                                                        value.modelName,
                                                      RelModuleId:
                                                        value.relModuleId,
                                                    },
                                                    //"RelationType": 0,
                                                    //"ListColumn": "CompanyName"
                                                  };
                                                  this.setState({
                                                    formValue: {
                                                      ..._obj,
                                                      RelationModel:
                                                        _RelationModel,
                                                    },
                                                  });
                                                }}
                                                disabled={
                                                  item.privateField || loading
                                                }
                                                className={"custom-fade-in"}
                                                cleanable={false}
                                                block
                                                name={"RelationModel"}
                                                placement="autoVerticalStart"
                                              />
                                            </Whisper>

                                            {formValue["RelationModel"] &&
                                              model.filter(
                                                (sModel) =>
                                                  sModel.id ===
                                                  formValue["RelationModel"]
                                                    .RelModel.id
                                              ).length > 0 && (
                                                <>
                                                  <ControlLabel
                                                    style={{
                                                      marginTop: "24px",
                                                    }}
                                                    className={"cell-header"}
                                                  >
                                                    {t(
                                                      "MODAL_CREATEROWDATA_TITLE_COLUMN"
                                                    )}
                                                  </ControlLabel>
                                                  <Whisper
                                                    trigger="none"
                                                    ref={this.triggerRefColums}
                                                    placement={"bottomStart"}
                                                    speaker={
                                                      <Popover
                                                        visible
                                                        className={
                                                          "custom-white-tooltip"
                                                        }
                                                      >
                                                        <p>
                                                          {t(
                                                            "ERROR_DM_GLOBAL_REQUIRED"
                                                          )}{" "}
                                                        </p>
                                                      </Popover>
                                                    }
                                                  >
                                                    <SelectPicker
                                                      data={
                                                        model &&
                                                        model
                                                          .filter(
                                                            (sModel) =>
                                                              sModel.id ===
                                                              formValue[
                                                                "RelationModel"
                                                              ].RelModel.id
                                                          )[0]
                                                          .dataTypes.map(
                                                            (x, key) => {
                                                              return {
                                                                label: x.name,
                                                                value: x, //key,
                                                                data: x,
                                                              };
                                                            }
                                                          )
                                                      }
                                                      onChange={(value) => {
                                                        this.triggerRefColums.current.close();
                                                        let _obj =
                                                          Object.assign(
                                                            {},
                                                            this.state.formValue
                                                          ); //
                                                        let _RelationModel = {
                                                          //"RelModel": {
                                                          //    "id": value.id,
                                                          //    "ModelName": value.modelName,
                                                          //    "RelModuleId": value.relModuleId
                                                          //},
                                                          //"RelationType": 0,
                                                          ListColumn:
                                                            value.name,
                                                        };
                                                        this.setState({
                                                          formValue: {
                                                            ..._obj,
                                                            RelationModel: {
                                                              ..._obj.RelationModel,
                                                              ..._RelationModel,
                                                            },
                                                          },
                                                        });
                                                      }}
                                                      disabled={
                                                        item.privateField ||
                                                        loading
                                                      }
                                                      className={
                                                        "custom-fade-in"
                                                      }
                                                      cleanable={false}
                                                      block
                                                      placement="autoVerticalStart"
                                                    />
                                                  </Whisper>
                                                 {
                                                  /*
                                                   <ControlLabel
                                                    style={{
                                                      marginTop: "24px",
                                                    }}
                                                    className={"cell-header"}
                                                  >
                                                    {t(
                                                      "MODAL_CREATEROWDATA_TITLE_RELATION_ANY_READ"
                                                    )}
                                                  </ControlLabel>
                                                  <Whisper
                                                    trigger="none"
                                                    ref={this.triggerRefColums}
                                                    placement={"bottomStart"}
                                                    speaker={
                                                      <Popover
                                                        visible
                                                        className={
                                                          "custom-white-tooltip"
                                                        }
                                                      >
                                                        <p>
                                                          {t(
                                                            "ERROR_DM_GLOBAL_REQUIRED"
                                                          )}{" "}
                                                        </p>
                                                      </Popover>
                                                    }
                                                  >
                                                    <SelectPicker
                                                      defaultValue={
                                                        formValue.hasOwnProperty(
                                                          camelize(
                                                            "RelationModel"
                                                          )
                                                        ) &&
                                                        formValue[
                                                          camelize(
                                                            "RelationModel"
                                                          )
                                                        ].hasOwnProperty(
                                                          camelize("RelModel")
                                                        ) &&
                                                        allTransactions &&
                                                        allTransactions
                                                          .find(
                                                            (sModel) =>
                                                              sModel.model
                                                                .id ===
                                                              formValue[
                                                                camelize(
                                                                  "RelationModel"
                                                                )
                                                              ][
                                                                camelize(
                                                                  "RelModel"
                                                                )
                                                              ].id
                                                          )
                                                          ?.transActions.find(
                                                            (x) =>
                                                              x.functionName ==
                                                              "read"
                                                          ).id
                                                      }
                                                      data={
                                                        allTransactions &&
                                                        allTransactions
                                                          .find(
                                                            (sModel) =>
                                                              sModel.model
                                                                .id ===
                                                              formValue[
                                                                camelize(
                                                                  "RelationModel"
                                                                )
                                                              ][
                                                                camelize(
                                                                  "RelModel"
                                                                )
                                                              ]?.id
                                                          )
                                                          ?.transActions.filter(
                                                            (f) =>
                                                              f.responseModelType ==
                                                              1
                                                          )
                                                          .map((x, key) => {
                                                            return {
                                                              label: x.name,
                                                              value: x.id, //key,
                                                              data: x,
                                                            };
                                                          })
                                                      }
                                                      onChange={(value) => {
                                                        this.triggerRefColums.current.close();
                                                        let _obj =
                                                          Object.assign(
                                                            {},
                                                            this.state.formValue
                                                          ); //
                                                        let _RelationModel = {
                                                          //"RelModel": {
                                                          //    "id": value.id,
                                                          //    "ModelName": value.modelName,
                                                          //    "RelModuleId": value.relModuleId
                                                          //},
                                                          //"RelationType": 0,
                                                          [camelize(
                                                            "ListAnyRead"
                                                          )]: value.name,
                                                        };
                                                        this.setState({
                                                          formValue: {
                                                            ..._obj,
                                                            [camelize(
                                                              "RelationModel"
                                                            )]: {
                                                              ..._obj[
                                                                camelize(
                                                                  "RelationModel"
                                                                )
                                                              ],
                                                              ..._RelationModel,
                                                            },
                                                          },
                                                        });
                                                      }}
                                                      disabled={
                                                        item.privateField ||
                                                        loading
                                                      }
                                                      className={
                                                        "custom-fade-in"
                                                      }
                                                      cleanable={false}
                                                      block
                                                      placement="autoVertical"
                                                    />
                                                  </Whisper>
                                                  
                                                  */
                                                 }
                                                  <ControlLabel
                                                    style={{
                                                      marginTop: "24px",
                                                    }}
                                                    className={"cell-header"}
                                                  >
                                                    {t(
                                                      "MODAL_CREATEROWDATA_TITLE_RELATIONTYPE"
                                                    )}
                                                  </ControlLabel>
                                                  <Whisper
                                                    trigger="none"
                                                    ref={
                                                      this.triggerRelationType
                                                    }
                                                    placement={"bottomStart"}
                                                    speaker={
                                                      <Popover
                                                        visible
                                                        className={
                                                          "custom-white-tooltip"
                                                        }
                                                      >
                                                        <p>
                                                          {t(
                                                            "ERROR_DM_GLOBAL_REQUIRED"
                                                          )}{" "}
                                                        </p>
                                                      </Popover>
                                                    }
                                                  >
                                                    <SelectPicker
                                                      data={[
                                                        {
                                                          label: i18n.t(
                                                            "ResponseModelTypeSingle"
                                                          ),
                                                          value: 0,
                                                        },
                                                        {
                                                          label: i18n.t(
                                                            "ResponseModelTypeList"
                                                          ),
                                                          value: 1,
                                                        },
                                                      ]}
                                                      onChange={(value) => {
                                                        this.triggerRelationType.current.close();
                                                        let _obj =
                                                          Object.assign(
                                                            {},
                                                            this.state.formValue
                                                          ); //
                                                        let _RelationModel = {
                                                          //"RelModel": {
                                                          //    "id": value.id,
                                                          //    "ModelName": value.modelName,
                                                          //    "RelModuleId": value.relModuleId
                                                          //},
                                                          RelationType: value,
                                                          //"ListColumn": value.name
                                                        };
                                                        this.setState({
                                                          formValue: {
                                                            ..._obj,
                                                            RelationModel: {
                                                              ..._obj.RelationModel,
                                                              ..._RelationModel,
                                                            },
                                                          },
                                                        });
                                                      }}
                                                      disabled={
                                                        item.privateField ||
                                                        loading
                                                      }
                                                      className={
                                                        "custom-fade-in"
                                                      }
                                                      cleanable={false}
                                                      block
                                                      placement="autoVerticalStart"
                                                    />
                                                  </Whisper>
                                                </>
                                              )}

                                            <>
                                              <ControlLabel
                                                style={{ marginTop: "24px" }}
                                                className={"cell-header"}
                                              >
                                                {t(
                                                  "MODAL_CREATEROWDATA_TITLE_ISAUTOPOPULATE"
                                                )}
                                              </ControlLabel>
                                              <FormControl
                                                accepter={Toggle}
                                                disabled={
                                                  item.privateField || loading
                                                }
                                                name={"IsAutopopulate"}
                                                className={"custom-fade-in"}
                                              />
                                            </>
                                            <>
                                              <ControlLabel
                                                style={{ marginTop: "24px" }}
                                                className={"cell-header"}
                                              >
                                                {t(
                                                  "MODAL_CREATEROWDATA_TITLE_SEND_CURRENT_MODEL"
                                                )}
                                              </ControlLabel>
                                              <FormControl
                                                accepter={Toggle}
                                                disabled={
                                                  item.privateField || loading
                                                }
                                                name={camelize(
                                                  "SendCurrentModel"
                                                )}
                                                className={"custom-fade-in"}
                                              />
                                            </>
                                          </>
                                        )}
                                        {formValue["contentType"] == "10" && (
                                          <>
                                            <ControlLabel
                                              style={{ marginTop: "24px" }}
                                              className={"cell-header"}
                                            >
                                              {t(
                                                "MODAL_CREATEROWDATA_TITLE_MODEL"
                                              )}
                                            </ControlLabel>
                                            <Whisper
                                              trigger="none"
                                              ref={this.triggerRefRelationModel}
                                              placement={"bottomStart"}
                                              speaker={
                                                <Popover
                                                  visible
                                                  className={
                                                    "custom-white-tooltip"
                                                  }
                                                >
                                                  <p>
                                                    {t(
                                                      "ERROR_DM_GLOBAL_REQUIRED"
                                                    )}{" "}
                                                  </p>
                                                </Popover>
                                              }
                                            >
                                              <SelectPicker
                                                data={
                                                  model &&
                                                  model.map((x) => {
                                                    return {
                                                      label: x.moduleWModel,
                                                      value: x, //x.id,
                                                      data: x,
                                                    };
                                                  })
                                                }
                                                onChange={(value) => {
                                                  this.triggerRefRelationModel.current.close();

                                                  let _obj = Object.assign(
                                                    {},
                                                    this.state.formValue
                                                  ); //
                                                  let _RefModel = {
                                                    ...value,
                                                    moduleWModel: undefined,
                                                    //"RelationType": 0,
                                                    //"ListColumn": "CompanyName"
                                                  };
                                                  this.setState({
                                                    formValue: {
                                                      ..._obj,
                                                      RefModel: _RefModel,
                                                    },
                                                  });
                                                }}
                                                disabled={
                                                  item.privateField || loading
                                                }
                                                className={"custom-fade-in"}
                                                cleanable={false}
                                                block
                                                name={"RefModel"}
                                                placement="autoVerticalStart"
                                              />
                                            </Whisper>

                                            {formValue["RefModel"] && (
                                              <>
                                                <ControlLabel
                                                  style={{
                                                    marginTop: "24px",
                                                  }}
                                                  className={"cell-header"}
                                                >
                                                  {t(
                                                    "MODAL_CREATEROWDATA_TITLE_COLUMN"
                                                  )}
                                                </ControlLabel>
                                                <Whisper
                                                  trigger="none"
                                                  ref={this.triggerRefColums}
                                                  placement={"bottomStart"}
                                                  speaker={
                                                    <Popover
                                                      visible
                                                      className={
                                                        "custom-white-tooltip"
                                                      }
                                                    >
                                                      <p>
                                                        {t(
                                                          "ERROR_DM_GLOBAL_REQUIRED"
                                                        )}{" "}
                                                      </p>
                                                    </Popover>
                                                  }
                                                >
                                                  <SelectPicker
                                                    defaultValue={
                                                      formValue.hasOwnProperty(
                                                        "RefModel"
                                                      ) &&
                                                      model
                                                        .filter(
                                                          (sModel) =>
                                                            sModel.id ===
                                                            formValue[
                                                              "RefModel"
                                                            ].id
                                                        )[0]
                                                        .dataTypes.find(
                                                          (x) =>
                                                            x.name ==
                                                            this.state
                                                              .formValue[
                                                              "RefModel"
                                                            ].ListColumn
                                                        )
                                                    }
                                                    data={
                                                      model &&
                                                      model
                                                        .filter(
                                                          (sModel) =>
                                                            sModel.id ===
                                                            formValue[
                                                              "RefModel"
                                                            ].id
                                                        )[0]
                                                        .dataTypes.map(
                                                          (x, key) => {
                                                            return {
                                                              label: x.name,
                                                              value: x, //key,
                                                              data: x,
                                                            };
                                                          }
                                                        )
                                                    }
                                                    onChange={(value) => {
                                                      this.triggerRefColums.current.close();
                                                      let _obj = Object.assign(
                                                        {},
                                                        this.state.formValue
                                                      ); //
                                                      let _RefModel = {
                                                        //"RelModel": {
                                                        //    "id": value.id,
                                                        //    "ModelName": value.modelName,
                                                        //    "RelModuleId": value.relModuleId
                                                        //},
                                                        //"RelationType": 0,
                                                        ["ListColumn"]:
                                                          value.name,
                                                      };
                                                      this.setState({
                                                        formValue: {
                                                          ..._obj,
                                                          ["RefModel"]: {
                                                            ..._obj["RefModel"],
                                                            ..._RefModel,
                                                          },
                                                        },
                                                      });
                                                    }}
                                                    disabled={
                                                      item.privateField ||
                                                      loading
                                                    }
                                                    className={"custom-fade-in"}
                                                    cleanable={false}
                                                    block
                                                    placement="autoVertical"
                                                  />
                                                </Whisper>
                                                <ControlLabel
                                                  style={{ marginTop: "24px" }}
                                                  className={"cell-header"}
                                                >
                                                  {t(
                                                    "MODAL_CREATEROWDATA_TITLE_RELATIONTYPE"
                                                  )}
                                                </ControlLabel>
                                                <Whisper
                                                  trigger="none"
                                                  ref={this.triggerRelationType}
                                                  placement={"bottomStart"}
                                                  speaker={
                                                    <Popover
                                                      visible
                                                      className={
                                                        "custom-white-tooltip"
                                                      }
                                                    >
                                                      <p>
                                                        {t(
                                                          "ERROR_DM_GLOBAL_REQUIRED"
                                                        )}{" "}
                                                      </p>
                                                    </Popover>
                                                  }
                                                >
                                                  <SelectPicker
                                                    data={[
                                                      {
                                                        label: i18n.t(
                                                          "ResponseModelTypeSingle"
                                                        ),
                                                        value: 0,
                                                      },
                                                      {
                                                        label: i18n.t(
                                                          "ResponseModelTypeList"
                                                        ),
                                                        value: 1,
                                                      },
                                                    ]}
                                                    onChange={(value) => {
                                                      this.triggerRelationType.current.close();
                                                      let _obj = Object.assign(
                                                        {},
                                                        this.state.formValue
                                                      ); //
                                                      let _RefModel = {
                                                        //"RelModel": {
                                                        //    "id": value.id,
                                                        //    "ModelName": value.modelName,
                                                        //    "RelModuleId": value.relModuleId
                                                        //},
                                                        RelationType: value,
                                                        //"ListColumn": value.name
                                                      };
                                                      this.setState({
                                                        formValue: {
                                                          ..._obj,
                                                          RefModel: {
                                                            ..._obj.RefModel,
                                                            ..._RefModel,
                                                          },
                                                        },
                                                      });
                                                    }}
                                                    disabled={
                                                      item.privateField ||
                                                      loading
                                                    }
                                                    className={"custom-fade-in"}
                                                    cleanable={false}
                                                    block
                                                    placement="autoVerticalStart"
                                                  />
                                                </Whisper>
                                                <>
                                                  <ControlLabel
                                                    style={{
                                                      marginTop: "24px",
                                                    }}
                                                    className={"cell-header"}
                                                  >
                                                    {t(
                                                      "MODAL_CREATEROWDATA_TITLE_SEND_CURRENT_MODEL"
                                                    )}
                                                  </ControlLabel>
                                                  <FormControl
                                                    accepter={Toggle}
                                                    disabled={
                                                      item.privateField ||
                                                      loading
                                                    }
                                                    name={camelize(
                                                      "SendCurrentModel"
                                                    )}
                                                    className={"custom-fade-in"}
                                                  />
                                                </>
                                              </>
                                            )}
                                          </>
                                        )}
                                      </>
                                    ) : item.contentType == "lang" ? (
                                      <FormControl
                                        name={item.name}
                                        accepter={SelectPicker}
                                        cleanable={false}
                                        data={isoLangs}
                                        className={"picker-full custom-fade-in"}
                                        disabled={item.privateField || loading}
                                      />
                                    ) : item.contentType == 2 ? (
                                      <FormControl
                                        rows={5}
                                        disabled={item.privateField || loading}
                                        name={item.name}
                                        className={"custom-fade-in"}
                                        componentClass="textarea"
                                      />
                                    ) : item.contentType == 3 ? (
                                      //Number NOT: INT TIPLERI GEÇİCİ OLARAK KALDIRILDI
                                      <FormControl
                                        accepter={InputNumber}
                                        defaultValue={0}
                                        step={1}
                                        /*  defaultValue={
                                        item.typeDetail === 6 || item.typeDetail === 7
                                        ? 0.01
                                        : 0
                                    }
                                    step={
                                        item.typeDetail === 6 || item.typeDetail === 7
                                        ? 0.01
                                        : 1
                                    }*/
                                        disabled={item.privateField || loading}
                                        name={item.name}
                                        className={"custom-fade-in"}
                                      />
                                    ) : item.contentType == 4 ? (
                                      //DATE
                                      <FormControl
                                        block
                                        placement="autoVerticalStart"
                                        format={"DD/MM/YYYY"}
                                        locale={Locale}
                                        accepter={DatePicker}
                                        disabled={item.privateField || loading}
                                        name={item.name}
                                        className={"custom-fade-in"}
                                      />
                                    ) : item.contentType == 6 ? (
                                      <FormControl
                                        name={item.name}
                                        accepter={SelectPicker}
                                        cleanable={false}
                                        data={item.defaultData.map(
                                          (x, key) => ({
                                            label: x,
                                            value: x,
                                          })
                                        )}
                                        className={"picker-full custom-fade-in"}
                                        disabled={item.privateField || loading}
                                      />
                                    ) : item.contentType == 9 ? (
                                      //IMAGE
                                      <>
                                        <div className={"dynamic-dt-side"}>
                                          <AvatarCard
                                            renderedImages={
                                              formValue[item.name]
                                            }
                                            isEditable={true}
                                            isSquare={false}
                                            handleUpdate={this.handleUpdatePP}
                                            cmsKey={item.name}
                                          />
                                          <div className={"dynamic-dt-popover"}>
                                            {this.state.formError.hasOwnProperty(
                                              item.name
                                            ) && (
                                              <Popover
                                                visible
                                                className={
                                                  "custom-white-tooltip"
                                                }
                                              >
                                                <p>
                                                  {t(
                                                    "ERROR_DM_GLOBAL_REQUIRED"
                                                  )}{" "}
                                                </p>
                                              </Popover>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      item.contentType +
                                      " contenttype not found"
                                    )}
                                  </FormGroup>
                                ))
                            )}
                        </Form>
                      </Col>
                    </Row>
                  </Container>
                </div>
                <div>
                  <div style={{ padding: "0 1em" }}>Advante</div>
                </div>
              </Slider>
            </div>
            <div className="modal-footer">
              <FormGroup>
                <ButtonToolbar className={"creator-toobar"}>
                  <Button
                    disabled={loading}
                    loading={loading}
                    appearance="primary"
                    onClick={this.handleSubmit}
                  >
                    {t("BUTTON_CREATE")}
                  </Button>
                  <Button
                    disabled={loading}
                    appearance="default"
                    onClick={this.handleCloseModal}
                  >
                    {t("BUTTON_CANCEL")}
                  </Button>
                </ButtonToolbar>
              </FormGroup>
            </div>
          </Modal>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer } = state;
  //const { workspaces, workspace, selectedService, services, modules, module, transactions } = workspaceReducer;
  const { models, model, currentLang, allTransactions } = workspaceReducer;
  return {
    //workspaces,
    //workspace,
    //modules,
    //module,
    //selectedService,
    //services,
    //transactions,
    models,
    model,
    currentLang,
    allTransactions,
  };
};

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(CreateRowData);

function camelize(str) {
  return str;
  /*
  .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
    return index === 0 ? word.toLowerCase() : word.toUpperCase();
  })
  .replace(/\s+/g, "");
  */
}
