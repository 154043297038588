import {
  //ServiceURLs,

  DashboardEndPoints,
  MakeReqestAndGetResponse,
} from "./index";


import { HTTPOperationType } from "./httpOperations";
import { GetBaseERPProductionOnly } from "./_serviceSettings";

export const DashboardService = {
  FEED_MENU,
  FEED_MODEL_AND_TRANSACTIONS,
};

function FEED_MENU(RelWorkspaceId,RelOAuthUserId,ErpWorkPlace) {
    let js = {
      RelWorkspaceId: RelWorkspaceId,
      RelOAuthUserId: RelOAuthUserId,
      ErpWorkPlace: ErpWorkPlace,
    };
    return MakeReqestAndGetResponse(
      GetBaseERPProductionOnly() + DashboardEndPoints.FEED_MENU,
      HTTPOperationType.post,
      js
    ).then((res) => {
     
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    }).catch(err=>{
      return Promise.reject(err);
    });
  }

  function FEED_MODEL_AND_TRANSACTIONS(RelWorkspaceId,RelOAuthUserId,ErpWorkPlace) {
    let js = {
      RelWorkspaceId: RelWorkspaceId,
      RelOAuthUserId: RelOAuthUserId,
      ErpWorkPlace: ErpWorkPlace,
    };
    return MakeReqestAndGetResponse(
      GetBaseERPProductionOnly() + DashboardEndPoints.FEED_MODEL_AND_TRANSACTIONS,
      HTTPOperationType.post,
      js
    ).then((res) => {
     
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    }).catch(err=>{
      return Promise.reject(err);
    });
  }
