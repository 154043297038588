import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import i18n from "i18n";

import { Modal, Container, Row, Col } from "reactstrap";
import { NumericFormat } from "react-number-format";

import AvatarCard from "components/Custom/AvatarCard";
import DynamicTag from "components/Custom/DynamicTag";
import { ErpEngineService, ModelsService } from "_services";

import { Toggle, Popover, Alert, Button, Icon } from "rsuite";
import {
  FormGroup,
  FormControl,
  ControlLabel,
  SelectPicker,
  InputNumber,
  DatePicker,
  Schema,
} from "rsuite";

import {
  updateServices,
  updateSelectedService,
  updatedModels,
  updateSelectedModel,
} from "_actions";

import RunTransactionModal from "components/Transaction/Modals/RunTransactionModal";

import FormGenerator from "./FormGenerator";
import FormRefElement from "./FormRefElement";
import FormRefModelElement from "./FormRefModelElement";

import FormListElement from "./FormListElement";
import { enqueueSnackbar } from "notistack";

import { decamelize } from "assets/globalOps/global";
import { isoLangs } from "assets/globalOps/isoLangs";
import {
  contentTypesSP,
  typeDetailsSP,
} from "assets/globalOps/CollectionTypes";
import EasyInvoiceViewer from "components/Modals/EasyInvoiceViewer";
import ConfirmModal from "components/Modals/ConfirmModal";
import FormElementFileUploader from "./FormElementFileUploader";

const { StringType, NumberType, ArrayType, DateType, ObjectType, BooleanType } =
  Schema.Types;
const model = Schema.Model({});

const settingsModel = Schema.Model({
  visible: StringType().isRequired("This field is required."),
  col: StringType()
    .isEmail("Please enter a valid email address.")
    .isRequired("This field is required."),
});

class FormElement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowSettings: false,

      formError: {},
      loading: false,
      isTransModal: false,
      refTransaction: null,
      refFormValue: null,
      isInvoiceViewer: false,
      isConfirmModal: false,
      confirmTitle: "",
    };
  }
  handleConfirmModal = (title = "") => {
    this.setState({
      confirmTitle: title,
      isConfirmModal: !this.state.isConfirmModal,
    });
  };
  handleInvoiceViewerModal = () => {
    this.setState({
      isInvoiceViewer: !this.state.isInvoiceViewer,
    });
  };

  handleRunTransaction = (refTransaction, _formValue, refEvent) => {
    const { workspace, item } = this.props;
    const that = this;
    let selectedService = refTransaction;
    const { formValue, formError, form } = this.state;
    const URL_MODE = "dev_URL";
    // debugger
    const _body = {
      [refEvent.modelKey]: _formValue,
    };

    this.setState({ loading: true });
    ErpEngineService.EXECUTE_TRANSACTION_DIRECTLY(
      workspace[URL_MODE],
      this.props.isListDetail
        ? this.props.itemOperation
        : selectedService.functionName,
      selectedService.executableTransactionServiceName,
      JSON.stringify(this.preTransactionFunc(_body, refTransaction, _body))
    )
      .then((data) => {
        debugger;
        if (data.operationResult) {
          that.postTransactionFunc(
            data.operationResult,
            data.dynamicValue,
            refEvent,
            refTransaction
          );
          debugger;
          that.handleTransModal(
            refTransaction,
            data.dynamicValue,
            undefined,
            refEvent
          );
          //debugger;
        }
        that.setState({ loading: false });
      })
      .catch((err) => {
        that.setState({ loading: false });
        try {
          Alert.error(
            Array.isArray(err.failText) ? err.failText.join(". ") : err.failText
          );
        } catch (error) {
          Alert.error(i18n.t("MESSAGE_TRANSACTION_SUBMIT_UNSUCCESS"));
        }
        // let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
        debugger;
      });
  };

  runBlobTransaction = (refTransaction, _formValue) => {
    const { workspace, item } = this.props;
    const that = this;
    let selectedService = refTransaction;
    const { formValue, formError, form } = this.state;
    const URL_MODE = "dev_URL";

    const _body = _formValue;
    this.setState({ loading: true });
    ErpEngineService.EXECUTE_TRANSACTION_DIRECTLY_BLOB(
      workspace[URL_MODE],
      this.props.isListDetail
        ? this.props.itemOperation
        : selectedService.functionName,
      selectedService.executableTransactionServiceName,
      JSON.stringify(this.preTransactionFunc(_body, refTransaction, _body))
    )
      .then((_data) => {
        const { data, res } = _data;
        debugger;
        that.setState({ loading: false });
        try {
          const href = URL.createObjectURL(data);

          // create "a" HTML element with href to file & click

          const link = document.createElement("a");
          link.href = href;
          debugger;
          const contentDisposition = res.headers["x-suggested-filename"];

          if (contentDisposition) {
            link.setAttribute("download", `${contentDisposition}`); //or any other extension
          } else {
            switch (res.headers["content-type"]) {
              case "application/pdf":
                link.setAttribute("download", `${new Date().toJSON()}.pdf`); //or any other extension
                break;
              default:
                link.setAttribute("download", `${new Date().toJSON()}.xlsx`); //or any other extension

                break;
            }
          }
          document.body.appendChild(link);
          link.click();

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
          debugger;
          return true;
        } catch (error) {
          return false;
        }
      })
      .catch((err) => {
        that.setState({ loading: false });
        debugger;
        try {
          Alert.error(
            Array.isArray(err.failText) ? err.failText.join(". ") : err.failText
          );
        } catch (error) {
          Alert.error(i18n.t("MESSAGE_TRANSACTION_SUBMIT_UNSUCCESS"));
        }
        // let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
        debugger;
      });
  };

  runTransaction = (refTransaction, _formValue) => {
    const { workspace, item } = this.props;
    const that = this;
    let selectedService = refTransaction;
    const { formValue, formError, form } = this.state;
    const URL_MODE = "dev_URL";

    const _body = _formValue;
    this.setState({ loading: true });
    ErpEngineService.EXECUTE_TRANSACTION_DIRECTLY(
      workspace[URL_MODE],
      this.props.isListDetail
        ? this.props.itemOperation
        : selectedService.functionName,
      selectedService.executableTransactionServiceName,
      JSON.stringify(this.preTransactionFunc(_body, refTransaction, _body))
    )
      .then((data) => {
        if (data.operationResult) {
          that.postTransactionFunc(
            data.operationResult,
            data.dynamicValue,
            "refEvent",
            refTransaction
          );

          //debugger;
        }
        that.setState({ loading: false });
      })
      .catch((err) => {
        that.setState({ loading: false });
        try {
          Alert.error(
            Array.isArray(err.failText) ? err.failText.join(". ") : err.failText
          );
        } catch (error) {
          Alert.error(i18n.t("MESSAGE_TRANSACTION_SUBMIT_UNSUCCESS"));
        }
        // let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
        debugger;
      });
  };

  handleTransModal = (
    refTransaction = null,
    formValue,
    status,
    refEvent = null
  ) => {
    this.setState({
      isTransModal: status == undefined ? !this.state.isTransModal : status,
      refTransaction: refTransaction,
      refFormValue: formValue,
      refEvent: refEvent,
    });
  };

  preTransactionFunc = (data, _selectedService, _formValue) => {
    const that = this;
    debugger;
    const formData = JSON.parse(JSON.stringify(this.props.formValue));

    const runTransaction = that.runTransaction;
    const transactions = this.props.allTransList;
    const Toast = this.ToastMessage;
    const SnackToast = this.SnackMessage;

    const easyinvoice = require("easyinvoice").default; //easyinvoice.download('myInvoice.pdf', data.InvoiceBinary);
    const showInvoiceModal = this.handleInvoiceViewerModal;
    const showConfirmModal = this.handleConfirmModal;

    const updatePreForm = this.props.isShowTransaction
      ? that.props.updateBaseForm
      : that.props.formOnChange;

    const updateData = this.props.updateData;

    const closeModal = () => {
      if (this.props.isShowTransaction) {
        this.props.showTransactionClose();
      } else {
        that.setState({
          isTransModal: false,
        });
      }
    };

    let result = null;
    if (
      _selectedService.hasOwnProperty("preScript") &&
      _selectedService.preScript
    ) {
      try {
        if (_selectedService.preScript.includes("await ")) {
          result = eval(
            "(async function() => { " + _selectedService.preScript + "})()"
          );
        } else {
          result = eval("(function() {" + _selectedService.preScript + "}())");
        }
      } catch (error) {
        debugger;
      }
    }
    /*
    preScript(pin): "alert("pre")"
postScript(pin): "alert("post")"
    */

    try {
      debugger;
      if (result) {
        return result;
      }
      return data;
    } catch (error) {
      return data;
    }
  };
  ToastMessage = (msg, type) => {
    try {
      switch (type) {
        case "success":
          Alert.success(msg);
          break;
        case "error":
          Alert.error(msg);
          break;
        case "info":
          Alert.info(msg);
          break;
        case "warning":
          Alert.warning(msg);
          break;
        default:
          Alert.info(msg);
          break;
      }
    } catch (error) {
      Alert.info(msg);
    }
  };
  SnackMessage = (msg, type) => {
    try {
      enqueueSnackbar(msg, { variant: type });
    } catch (error) {
      enqueueSnackbar(msg);
    }
  };
  postTransactionFunc = (status, response, refEvent, _selectedService) => {
    const that = this;
    const formData = JSON.parse(JSON.stringify(this.props.formValue));

    const runTransaction = that.runTransaction;
    const transactions = this.props.allTransList;
    const Toast = this.ToastMessage;
    const SnackToast = this.SnackMessage;

    const updatePreForm = this.props.isShowTransaction
      ? that.props.updateBaseForm
      : that.props.formOnChange;
    const closeModal = () => {
      if (this.props.isShowTransaction) {
        this.props.showTransactionClose();
      } else {
        that.setState({
          isTransModal: false,
        });
      }
    };

    const easyinvoice = require("easyinvoice").default; //easyinvoice.download('myInvoice.pdf', data.InvoiceBinary);
    const showInvoiceModal = this.handleInvoiceViewerModal;
    const showConfirmModal = this.handleConfirmModal;

    const runPreTransaction = () => {
      debugger;
      if (that.props.isRunTransModal && that.props.handleRunPreTransaction) {
        that.props.handleRunPreTransaction();
      }
    };
    let __result = null;
    if (
      _selectedService.hasOwnProperty("postScript") &&
      _selectedService.postScript
    ) {
      try {
        if (_selectedService.postScript.trim().length > 0 && status) {
          if (_selectedService.postScript.includes("await ")) {
            __result = eval(
              "(async function() => { " + _selectedService.postScript + "})()"
            );
          } else {
            __result = eval(
              "(function() {" + _selectedService.postScript + "}())"
            );
          }
        }
      } catch (error) {
        debugger;
      }
    }
  };

  componentDidMount() {
    const { item, _typeDetails, loading, selectedService, base } = this.props;
    const { formRef, formValue, formValueOrj, formOnChange, readOnly } =
      this.props;

    if (
      item.contentType == 7 &&
      (!formValue || !formValue.hasOwnProperty(item.name))
    ) {
      formValue[item.name] = false;
    }
  }

  setAdvancedDefaults = () => {
    const { item } = this.props;

    let _inputs = {};
    item.advanced.forEach((x) => {
      try {
        let _v = x.defaultValue;
        switch (x.currentContentType) {
          case 3:
            _v = parseFloat(x.defaultValue);
            break;

          case 7:
            _v = x.defaultValue.toLowerCase() == "true";
            break;

          default:
            break;
        }
        _inputs[x.name] = _v;
      } catch (error) {}
    });

    let _events = {};
    item.events.forEach((x) => {
      //debugger;
      if (x.defaultValue) {
        _events[x.name + x.eventName] = JSON.parse(x.defaultValue);
      }
    });
    /*
    this.setState({
      cInputs: _inputs,
      advanceFormValue: _inputs,
      cEvents: _events,
    });
    */
    return {
      advanced: _inputs,
      events: _events,
    };
  };
  //{this.getCurrentLangDisplayName(item)}
  getCurrentLangDisplayName = (item) => {
    try {
      const _lang = this.props.currentLang;
      if (_lang) {
        let _langDNM = item.displayNameMultiLangs.find((x) => x.lang == _lang);
        if (_langDNM && _langDNM.textValue.trim().length > 0) {
          return _langDNM.textValue;
        } else {
          _langDNM = item.displayNameMultiLangs.find((x) => x.lang == "en");
          return _langDNM.textValue;
        }
      } else {
        const _langDNM = item.displayNameMultiLangs.find((x) => x.lang == "en");
        return _langDNM.textValue;
      }
    } catch (error) {
      return item.hasOwnProperty("displayName")
        ? item.displayName
        : decamelize(item.name, " ");
    }
  };

  render() {
    const {
      formRef,
      formValue,
      formValueOrj,
      formOnChange,
      readOnly,
      allServices,
    } = this.props;
    const {
      item,
      _typeDetails,
      loading,
      selectedService,
      base,
      runTransaction,
      isFilter,
    } = this.props;
    const { _key, isDesigner, activeElement, handleSetActiveElement } =
      this.props;
    const Locale = {
      sunday: i18n.t("sunday"),
      monday: i18n.t("monday"),
      tuesday: i18n.t("tuesday"),
      wednesday: i18n.t("wednesday"),
      thursday: i18n.t("thursday"),
      friday: i18n.t("friday"),
      saturday: i18n.t("saturday"),
      ok: i18n.t("ok"),
      today: i18n.t("today"),
      yesterday: i18n.t("yesterday"),
      hours: i18n.t("hours"),
      minutes: i18n.t("minutes"),
      seconds: i18n.t("seconds"),
    };

    const _viewPath = base ? base.view[item.name] : null;
    const _col = _viewPath ? _viewPath.col : 12;
    const _visible = _viewPath ? _viewPath.visible : true;

    const _extra = this.setAdvancedDefaults();
    const _f = this.props.formModel.find(
      (x) => x.contentType == 1 || x.contentType == 2 || x.contentType == 3
    );

    return (
      <Translation>
        {(t) => (
          <>
            {_visible && (
              <Col deger={_col} xs={_col} style={{ marginBottom: "10px" }}>
                {item.contentType == "typeDetails" ? (
                  _typeDetails &&
                  _typeDetails.length > 0 && (
                    <FormGroup className={"custom-fade-in"}>
                      <ControlLabel className={"cell-header"}>
                        {this.getCurrentLangDisplayName(item)}
                      </ControlLabel>
                      <FormControl
                        cleanable={false}
                        block
                        placement="autoVerticalStart"
                        accepter={SelectPicker}
                        data={_typeDetails}
                        disabled={item.privateField || loading}
                        name={item.name}
                      />
                    </FormGroup>
                  )
                ) : item.contentType == 11 || item.contentType == 12 ? (
                  ""
                ) : _extra.advanced.ReadOnly ? (
                  <FormGroup>
                    <ControlLabel className={"cell-header"}>
                      {this.getCurrentLangDisplayName(item)}
                    </ControlLabel>

                    {item.contentType == 5 ? (
                      <FormRefElement
                        formRef={formRef}
                        formValue={formValue}
                        formValueOrj={formValueOrj}
                        formOnChange={formOnChange}
                        loading={loading}
                        readOnly={true}
                        data={item}
                        _extra={_extra}
                        runTransaction={runTransaction}
                        isShowTransaction={this.props.isShowTransaction}
                      />
                    ) : item.contentType == 3 ? (
                      //Number NOT: INT TIPLERI GEÇİCİ OLARAK KALDIRILDI
                      <NumericFormat
                        displayType={"text"}
                        value={
                          formValue[item.name] ? formValue[item.name] : "0"
                        }
                        allowDecimal={
                          _extra.advanced.IsInteger || isFilter ? false : true
                        }
                        decimalScale={
                          _extra.advanced.IsInteger || isFilter ? 0 : 2
                        }
                        fixedDecimalScale={
                          _extra.advanced.IsInteger || isFilter
                            ? undefined
                            : true
                        }
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        name={item.name}
                        className={"custom-fade-in "}
                        prefix={_extra.advanced.Prefix}
                        suffix={_extra.advanced.Prefix}
                      />
                    ) : item.contentType == 7 ? (
                      <div>
                        {formValue[item.name] ? (
                          <Icon icon="check" style={{ color: "#32cd32" }} />
                        ) : (
                          <Icon icon="close" style={{ color: "#ff0000" }} />
                        )}
                      </div>
                    ) : (
                      <span>
                        {formValue[item.name]
                          ? typeof formValue[item.name] == "object"
                            ? Array.isArray(formValue[item.name])
                              ? "Collection"
                              : "[Object]"
                            : formValue[item.name]
                          : "-"}
                      </span>
                    )}
                  </FormGroup>
                ) : item.contentType == 14 ? (
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      flex: 1,
                      justifyContent:
                        _extra.advanced.Position == "All"
                          ? "center"
                          : _extra.advanced.Position == "Start"
                          ? "start"
                          : _extra.advanced.Position == "Center"
                          ? "center"
                          : "end",
                    }}
                  >
                    <Button
                      style={{
                        width:
                          _extra.advanced.Position == "All" ? "100%" : "auto",
                      }}
                      onClick={() => {
                        debugger;
                        const _cEvent = _extra.events["onClick"];
                        if (_cEvent) {
                          const _cDetail = _cEvent;
                          //"{"transId":"6390bb32961f1f80d3ee9f5c","modelKey":"name","runType":"page"}"
                          const _sTrans = allServices.find(
                            (x) => x.id == _cDetail.transId
                          );
                          if (_sTrans) {
                            switch (_cDetail.runType) {
                              case "modal":
                                this.handleRunTransaction(
                                  _sTrans,
                                  formValue,
                                  _cDetail
                                );
                                break;
                              case "current":
                                runTransaction(_sTrans, formValue);
                                break;
                              case "blob":
                                this.runBlobTransaction(_sTrans, formValue);
                                break;
                              default:
                                runTransaction(_sTrans, formValue);
                                break;
                            }
                          }
                        }
                      }}
                    >
                      {this.getCurrentLangDisplayName(item)}
                    </Button>
                  </div>
                ) : (
                  <FormGroup>
                    <ControlLabel className={"cell-header"}>
                      {this.getCurrentLangDisplayName(item)}
                    </ControlLabel>
                    {item.contentType == "string" || item.contentType == 1 ? (
                      <FormControl
                        //autoFocus={_f?.name == item.name}
                        type={item["typeDetail"] == 2 ? "password" : "text"}
                        disabled={item.privateField || loading}
                        name={item.name}
                        className={"custom-fade-in"}
                        onBlur={(event) => {
                          const _cEvent = _extra.events["onBlur"];
                          if (_cEvent) {
                            debugger;
                            const _cDetail = _cEvent;
                            //"{"transId":"6390bb32961f1f80d3ee9f5c","modelKey":"name","runType":"page"}"
                            const _sTrans = allServices.find(
                              (x) => x.id == _cDetail.transId
                            );
                            if (_sTrans) {
                              switch (_cDetail.runType) {
                                case "modal":
                                  this.handleRunTransaction(
                                    _sTrans,
                                    formValue,
                                    _cDetail
                                  );
                                  break;
                                case "current":
                                  runTransaction(_sTrans, formValue);
                                  break;
                                default:
                                  runTransaction(_sTrans, formValue);
                                  break;
                              }
                            }
                          }
                        }}
                        onChange={(event) => {
                          const _cEvent = _extra.events["onChange"];
                          if (_cEvent) {
                            const _cDetail = _cEvent;
                            //"{"transId":"6390bb32961f1f80d3ee9f5c","modelKey":"name","runType":"page"}"
                            const _sTrans = allServices.find(
                              (x) => x.id == _cDetail.transId
                            );
                            if (_sTrans) {
                              switch (_cDetail.runType) {
                                case "modal":
                                  this.handleRunTransaction(
                                    _sTrans,
                                    formValue,
                                    _cDetail
                                  );
                                  break;
                                case "current":
                                  runTransaction(_sTrans, formValue);
                                  break;
                                default:
                                  runTransaction(_sTrans, formValue);
                                  break;
                              }
                            }
                          }
                        }}
                        onKeyPress={(event) => {
                          /*{...formValue , [_cDetail.modelKey]: formValue[item.name]} */
                          const _cEvent = _extra.events["onKeyPressEnter"];
                          if (event.key == "Enter" && _cEvent) {
                            // debugger;
                            const _cDetail = _cEvent;
                            //"{"transId":"6390bb32961f1f80d3ee9f5c","modelKey":"name","runType":"page"}"
                            const _sTrans = allServices.find(
                              (x) => x.id == _cDetail.transId
                            );
                            if (_sTrans) {
                              switch (_cDetail.runType) {
                                case "modal":
                                  this.handleRunTransaction(
                                    _sTrans,
                                    formValue,
                                    _cDetail
                                  );
                                  break;
                                case "current":
                                  runTransaction(_sTrans, formValue);
                                  break;
                                default:
                                  runTransaction(_sTrans, formValue);
                                  break;
                              }
                            }
                          }
                        }}
                      />
                    ) : item.contentType == "boolean" ||
                      item.contentType == 7 ? (
                      <FormControl
                        accepter={Toggle}
                        disabled={item.privateField || loading}
                        defaultChecked={formValue[item.name]}
                        name={item.name}
                        className={"custom-fade-in"}
                      />
                    ) : item.contentType == "contentTypes" ? (
                      <>
                        <FormControl
                          cleanable={false}
                          block
                          placement="autoVerticalStart"
                          accepter={SelectPicker}
                          data={contentTypesSP}
                          disabled={item.privateField || loading}
                          name={item.name}
                          className={"custom-fade-in"}
                        />
                        {
                          //ENUM type
                          formValue["contentType"] == "6" && (
                            <>
                              {!item.privateField && (
                                <div className={"dynamic-dt-side"}>
                                  <div className={"dynamic-enum-side"}>
                                    <DynamicTag
                                      data={
                                        formValue["defaultData"]
                                          ? formValue["defaultData"]
                                          : []
                                      }
                                      handleUpdate={this.handleUpdateEnum}
                                    />
                                  </div>
                                  <div className={"dynamic-dt-popover"}>
                                    {this.state.formError.hasOwnProperty(
                                      "defaultdata"
                                    ) && (
                                      <Popover
                                        visible
                                        className={"custom-white-tooltip"}
                                      >
                                        <p>
                                          {t("ERROR_DM_GLOBAL_REQUIRED_ENUM")}{" "}
                                        </p>
                                      </Popover>
                                    )}
                                  </div>
                                </div>
                              )}
                            </>
                          )
                        }
                      </>
                    ) : item.contentType == "lang" ? (
                      <FormControl
                        name={item.name}
                        accepter={SelectPicker}
                        cleanable={false}
                        data={isoLangs}
                        className={"picker-full custom-fade-in"}
                        disabled={item.privateField || loading}
                      />
                    ) : item.contentType == 2 ? (
                      <FormControl
                        rows={5}
                        autoFocus={_f?.name == item.name}
                        disabled={item.privateField || loading}
                        name={item.name}
                        className={"custom-fade-in"}
                        componentClass="textarea"
                      />
                    ) : item.contentType == 3 ? (
                      //Number NOT: INT TIPLERI GEÇİCİ OLARAK KALDIRILDI
                      <NumericFormat
                        displayType={readOnly ? "text" : "input"}
                        defaultValue={formValue[item.name]}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        allowDecimal={
                          _extra.advanced.IsInteger || isFilter ? false : true
                        }
                        decimalScale={
                          _extra.advanced.IsInteger || isFilter ? 0 : 2
                        }
                        fixedDecimalScale={
                          _extra.advanced.IsInteger || isFilter
                            ? undefined
                            : true
                        }
                        min={_extra.advanced.Min}
                        max={_extra.advanced.Max}
                        prefix={_extra.advanced.Prefix}
                        suffix={_extra.advanced.Prefix}
                        disabled={item.privateField || loading}
                        name={item.name}
                        className={"custom-fade-in rs-input"}
                        onBlur={(event) => {
                          const _cEvent = _extra.events["onBlur"];

                          if (_cEvent) {
                            const _cDetail = _cEvent;
                            //"{"transId":"6390bb32961f1f80d3ee9f5c","modelKey":"name","runType":"page"}"
                            const _sTrans = allServices.find(
                              (x) => x.id == _cDetail.transId
                            );
                            if (_sTrans) {
                              switch (_cDetail.runType) {
                                case "modal":
                                  this.handleRunTransaction(
                                    _sTrans,
                                    formValue,
                                    _cDetail
                                  );
                                  break;
                                case "current":
                                  runTransaction(_sTrans, formValue);
                                  break;
                                default:
                                  runTransaction(_sTrans, formValue);
                                  break;
                              }
                            }
                          }
                        }}
                        onValueChange={(values, sourceInfo) => {
                          const _cEvent = _extra.events["onChange"];
                          //values.value
                          debugger;
                          let _objClone = Object.assign({}, formValue);
                          _objClone[item.name] = values.floatValue;
                          formOnChange(_objClone);

                          if (_cEvent) {
                            const _cDetail = _cEvent;
                            //"{"transId":"6390bb32961f1f80d3ee9f5c","modelKey":"name","runType":"page"}"
                            const _sTrans = allServices.find(
                              (x) => x.id == _cDetail.transId
                            );
                            if (_sTrans) {
                              switch (_cDetail.runType) {
                                case "modal":
                                  this.handleRunTransaction(
                                    _sTrans,
                                    formValue,
                                    _cDetail
                                  );
                                  break;
                                case "current":
                                  runTransaction(_sTrans, formValue);
                                  break;
                                default:
                                  runTransaction(_sTrans, formValue);
                                  break;
                              }
                            }
                          }
                        }}
                        onKeyPress={(event) => {
                          const _cEvent = _extra.events["onKeyPressEnter"];

                          if (event.key == "Enter" && _cEvent) {
                            const _cDetail = _cEvent;
                            //"{"transId":"6390bb32961f1f80d3ee9f5c","modelKey":"name","runType":"page"}"
                            const _sTrans = allServices.find(
                              (x) => x.id == _cDetail.transId
                            );
                            if (_sTrans) {
                              switch (_cDetail.runType) {
                                case "modal":
                                  this.handleRunTransaction(
                                    _sTrans,
                                    formValue,
                                    _cDetail
                                  );
                                  break;
                                case "current":
                                  runTransaction(_sTrans, formValue);
                                  break;
                                default:
                                  runTransaction(_sTrans, formValue);
                                  break;
                              }
                            }
                          }
                        }}
                      />
                    ) : item.contentType == 4 ? (
                      //DATE
                      <FormControl
                        block
                        placement="autoVerticalStart"
                        format={"DD/MM/YYYY"}
                        locale={Locale}
                        accepter={DatePicker}
                        disabled={item.privateField || loading}
                        name={item.name}
                        className={"custom-fade-in"}
                      />
                    ) : item.contentType == 5 ? (
                      <FormRefElement
                        formRef={formRef}
                        formValue={formValue}
                        formValueOrj={formValueOrj}
                        formOnChange={formOnChange}
                        loading={loading}
                        readOnly={readOnly}
                        data={item}
                        _extra={_extra}
                        runTransaction={runTransaction}
                        isShowTransaction={this.props.isShowTransaction}
                      />
                    ) : item.contentType == 6 ? (
                      <FormControl
                        name={item.name}
                        accepter={SelectPicker}
                        cleanable={false}
                        data={item.defaultData.map((x, key) => ({
                          label: x,
                          value: x,
                        }))}
                        className={"picker-full custom-fade-in"}
                        disabled={item.privateField || loading}
                      />
                    ) : item.contentType == 8 ? (
                      <FormControl
                        readOnly
                        disabled
                        name={item.name}
                        className={"custom-fade-in"}
                      />
                    ) : item.contentType == 9 ? (
                      //IMAGE
                      !item.privateField && (
                        <div className={"dynamic-dt-side"}>
                          <div className="multi-avatar">
                            {item.typeDetail == 9 &&
                              formValue[item.name] &&
                              formValue[item.name].map((_i, _k) => {
                                debugger;
                                return (
                                  <div key={_k}>
                                    <AvatarCard
                                      //key={_k}
                                      readOnly={readOnly}
                                      renderedImages={_i}
                                      isEditable={false}
                                      isSquare={false}
                                      isDeletable={true}
                                      handleDelete={(fieldName) => {
                                        let _objClone = Object.assign(
                                          {},
                                          formValue
                                        );

                                        if (item.typeDetail == 9) {
                                          _objClone[fieldName].splice(_k, 1);
                                        } else {
                                          delete _objClone[fieldName];
                                        }
                                        formOnChange(_objClone);
                                      }}
                                      cmsKey={item.name}
                                    />
                                  </div>
                                );
                              })}
                            <AvatarCard
                              readOnly={readOnly}
                              renderedImages={
                                item.typeDetail == 9
                                  ? null
                                  : formValue[item.name]
                              }
                              //readOnly={readOnly}
                              isEditable={true}
                              isSquare={false}
                              handleUpdate={(result, fieldName) => {
                                let _objClone = Object.assign({}, formValue);
                                //typeDetail: 8 single
                                //typeDetail: 9 multiple

                                if (item.typeDetail == 9) {
                                  if (Array.isArray(_objClone[fieldName])) {
                                    _objClone[fieldName].push(result);
                                  } else {
                                    _objClone[fieldName] = [];
                                    _objClone[fieldName].push(result);
                                  }
                                } else {
                                  _objClone[fieldName] = result; //_cValueIsObj;
                                }

                                formOnChange(_objClone);
                                const _iii = item;
                                debugger;
                              }}
                              cmsKey={item.name}
                            />
                          </div>

                          <div className={"dynamic-dt-popover"}>
                            {this.state.formError.hasOwnProperty(item.name) && (
                              <Popover
                                visible
                                className={"custom-white-tooltip"}
                              >
                                <p>{t("ERROR_DM_GLOBAL_REQUIRED")} </p>
                              </Popover>
                            )}
                          </div>
                        </div>
                      )
                    ) : item.contentType == 10 ? (
                      <FormRefModelElement
                        baseData={this.props.baseData}
                        formRef={formRef}
                        formValue={formValue}
                        formOnChange={formOnChange}
                        isObject={true}
                        loading={loading}
                        data={item}
                        readOnly={readOnly}
                        _extra={_extra}
                        refTransaction={this.props.refTransaction}
                        preRefTransaction={this.props.preRefTransaction}
                        isShowTransaction={this.props.isShowTransaction}
                        runTransaction={(_sTrans, data) => {
                          runTransaction(_sTrans, data);
                        }}
                      />
                    ) : item.contentType == 13 ? (
                      <FormListElement
                        baseData={this.props.baseData}
                        formRef={formRef}
                        formValue={formValue}
                        formOnChange={formOnChange}
                        isObject={true}
                        loading={loading}
                        data={item}
                        readOnly={readOnly}
                        _extra={_extra}
                        isShowTransaction={this.props.isShowTransaction}
                        runTransaction={(_sTrans, data) => {
                          runTransaction(_sTrans, data);
                        }}
                      />
                    ) : item.contentType == 15 ? (
                      <FormElementFileUploader
                        item={item}
                        readOnly={readOnly}
                        loading={loading}
                        formValue={formValue}
                        formValueOrj={formValueOrj}
                        data={item}
                        handleUpdate={(result, fieldName) => {
                          let _objClone = Object.assign({}, formValue);
                          _objClone[fieldName] = result[0]; //_cValueIsObj;
                          formOnChange(_objClone);
                          const _iii = item;
                          debugger;
                        }}
                      />
                    ) : item.contentType == 16 ? (
                      <FormElementFileUploader
                        item={item}
                        readOnly={readOnly}
                        loading={loading}
                        formValue={formValue}
                        formValueOrj={formValueOrj}
                        data={item}
                        handleUpdate={(result, fieldName) => {
                          let _objClone = Object.assign({}, formValue);
                       //  _objClone[fieldName] = result; //_cValueIsObj;
                          _objClone[fieldName] = {"DocumentList":result} 
                          formOnChange(_objClone);
                          const _iii = item;
                          debugger;
                        }}
                      />
                    ) : (
                      item.contentType + " contenttype not found"
                    )}
                  </FormGroup>
                )}
              </Col>
            )}
            {this.state.isTransModal && (
              <RunTransactionModal
                baseData={this.props.formValue}
                refTransaction={this.state.refTransaction}
                preRefTransaction={this.props.refTransaction}
                isActive={this.state.isTransModal}
                handleToggle={() => {
                  this.setState({ isTransModal: false });
                }}
                refFormValue={this.state.refFormValue}
                updateBaseForm={formOnChange}
                handleRunPreTransaction={this.props.handleRunPreTransaction}
              />
            )}

            {this.state.isInvoiceViewer && (
              <EasyInvoiceViewer
                isActive={this.state.isInvoiceViewer}
                handleToggle={this.handleInvoiceViewerModal}
              />
            )}

            {this.state.isConfirmModal && (
              <ConfirmModal
                isActive={this.state.isConfirmModal}
                handleToggle={this.handleConfirmModal}
                title={this.state.confirmTitle}
              />
            )}
          </>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer } = state;
  const {
    workspace,
    selectedService,
    services,
    models,
    allServices,
    allTransList,
    allTransactions,
    currentLang,
  } = workspaceReducer;
  //this.props.currentLang
  return {
    workspace,
    selectedService,
    services,
    models,
    allServices,
    allTransList,
    allTransactions,
    currentLang,
  };
};

const mapDispatchToProps = {
  updateServices,
  updateSelectedService,
  updatedModels,
  updateSelectedModel,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormElement);
