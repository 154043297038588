import React from "react";
import ReactDOM from "react-dom";
// react library for routing
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./_helpers";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import "./i18n";

// bootstrap rtl for rtl support page
import "assets/vendor/bootstrap-rtl/bootstrap-rtl.scss";
// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
// plugins styles downloaded
import "assets/vendor/fullcalendar/dist/fullcalendar.min.css";
import "assets/vendor/sweetalert2/dist/sweetalert2.min.css";
import "assets/vendor/select2/dist/css/select2.min.css";
import "assets/vendor/quill/dist/quill.core.css";
import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
// core styles
import "assets/scss/argon-dashboard-pro-react.scss?v1.1.0";
import "assets/css/App.css";
import "assets/css/slick.css";
import "assets/css/slick-theme.css";
import "assets/css/rsTheme.css";
import Main from "./layouts/Main";
import Auth from "views/auth";
import KrnzTable from "components/Table/KrnzTable";

import { SnackbarProvider, closeSnackbar, useSnackbar } from "notistack";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

Sentry.init({
  dsn: "https://c5cc30a642274059b510fc5f7f64ceaa@o431334.ingest.sentry.io/5841036",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const action = (snackbarId) => (
  <>
    {" "}
    <IconButton
      onClick={() => {
        closeSnackbar(snackbarId);
      }}
    >
      {" "}
      <CloseIcon style={{ color: "white" }} fontSize="small" />{" "}
    </IconButton>{" "}
  </>
);

ReactDOM.render(
  <Provider store={store}>
    <SnackbarProvider
      action={action}
      hideIconVariant
      persist
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <BrowserRouter>
        <Switch>
          {
            <Route
              path="/auth/:token"
              render={(props) => <Auth {...props} />}
            />
          }
          <Route path="/" render={(props) => <Main {...props} />} />
          <Redirect from="*" to="/" />
        </Switch>
      </BrowserRouter>
    </SnackbarProvider>
  </Provider>,
  document.getElementById("root")
);
