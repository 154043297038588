import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import i18n from "i18n";
import { WorkspaceService } from "_services";
import CreateWorkspace from "components/Modals/CreateWorkspace";

import WorkspacesHeader from "components/Headers/WorkspacesHeader";
import { NavLink } from "react-router-dom";
import {
  Alert,
  ButtonGroup,
  ButtonToolbar,
  Container,
  Divider,
  Dropdown,
  Tag,
} from "rsuite";
import { Row, Col, Panel, Placeholder, Badge, Button } from "rsuite";

import { Loader, IconButton, Icon } from "rsuite";

import { updateWorkspace, updateWorkspaceList, clearWorkspace } from "_actions";
import CloneWorkspace from "components/Modals/CloneWorkspace";

import {
  workspaceUpdateTime,
  dateformat4,
} from "../../assets/globalOps/global";
import MigrationModal from "components/Modals/MigrationModal";

const { Paragraph } = Placeholder;

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      createWorkspaceModal: false,
      cloneWorkspaceModal: false,
      selectedWorkspace: null,
      isProgressWorkspace: false,
      migrationStatus: null,
      migrationId: null,
    };
  }

  handleIsProgressWorkspace = () => {
    this.setState({
      isProgressWorkspace: !this.state.isProgressWorkspace,
    });
  };

  componentDidMount() {
    this.getWorkspaces();
    this.props.clearWorkspace();
  }

  handleCreateWorkspace = () => {
    this.setState({ createWorkspaceModal: !this.state.createWorkspaceModal });
  };
  handleCloneWorkspace = (data) => {
    this.setState({
      cloneWorkspaceModal: !this.state.cloneWorkspaceModal,
      selectedWorkspace: data,
    });
  };

  getWorkspaces = (_search = "") => {
    const { updateWorkspaceList } = this.props;
    const that = this;
    that.setState({ isLoading: true });
    WorkspaceService.STEPBYLOAD(false, "-1", 9999, _search, null)
      .then((data) => {
        //debugger;
        if (data.operationResult) {
          updateWorkspaceList(data.dynamicValue);
        }
        that.setState({ isLoading: false });
      })
      .catch((err) => {
        that.setState({ isLoading: false });
        // let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
        debugger;
      });
  };

  handleCheckWorkspaceStatus = (item) => {
    const that = this;

    WorkspaceService.GET_MIGRATION_STATUS(item.id)
      .then((data) => {
        that.setState({ isLoading: false, isProgressWorkspace: false });
        if (data.operationResult) {
          that.setState({
            migrationStatus: data.dynamicValue,
            migrationId: item.id,
          });
debugger
          switch (data.dynamicValue) {
            case "0": //Done
              that.handleSelecetWorkspace(item);
              break;
            case "1": //InQuee
              that.setState({ isProgressWorkspace: true });
              Alert.warning(i18n.t("STATUS_WORKSPACE_IS_LOADING"));
              break;
            case "2": // Processing
              that.setState({ isProgressWorkspace: true });
              Alert.info(i18n.t("STATUS_WORKSPACE_IS_LOADING"));
              break;

            default:
              that.setState({ isProgressWorkspace: true });
              break;
          }
        }
        that.setState({ isLoading: false });
      })
      .catch((err) => {
        that.setState({ isLoading: false });
        // let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
        debugger;
      });
  };

  handleSelecetWorkspace = (item) => {
    const that = this;
    that.setState({ isLoading: true });
    const { history } = this.props;
    WorkspaceService.GET_CONFIGS(item.id)
      .then((data) => {
        //debugger;
        if (data.operationResult) {
          history.push("workspace/" + item.id);
          this.props.updateWorkspace({
            ...data.dynamicValue,
            id: item.id,
            name: item.name,
          });
        }
        that.setState({ isLoading: false });
      })
      .catch((err) => {
        that.setState({ isLoading: false });
        // let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
        debugger;
      });
  };

  handleSearch = (_value) => {
    this.getWorkspaces(_value);
  };

  render() {
    const { workspaces } = this.props;
    const { isLoading } = this.state;
    const getNumber = (t) => +t.replace(/:/g, "");
    return (
      <Translation>
        {(t) => (
          <div className={"global-view"}>
            {
              <Container
                key={"workspaces-list"}
                style={{ height: "100%", padding: "1em" }}
                className={"custom-fade-in"}
              >
                <WorkspacesHeader
                  handleSearch={this.handleSearch}
                  openCreateModal={this.handleCreateWorkspace}
                />

                <Row>
                  {this.props.workspaces &&
                    [
                      ...this.props.workspaces.dynamicValue.sort(function (
                        a,
                        b
                      ) {
                        return (
                          new Date(b.updatedTime) - new Date(a.updatedTime)
                        );
                      }),
                    ].map((item, key) => (
                      <Col
                        className={"custom-fade-in"}
                        key={item.id + "-" + key}
                        style={{
                          paddingTop: "5px",
                          paddingBottom: "5px",
                        }}
                        md={6}
                        sm={12}
                      >
                        <Card
                          handleSelect={() => {
                            this.handleCheckWorkspaceStatus(item);
                          }}
                          handleCloneWorkspace={() => {
                            this.handleCloneWorkspace(item);
                          }}
                          cID={item.id}
                          demoBadge={false}
                          header={item.name}
                          data={item}
                        />
                      </Col>
                    ))}
                  {this.props.workspaces &&
                    this.props.workspaces.dynamicValue.length < 1 && (
                      <div className={"no-workspaces"}>
                        <Icon icon="project" size="4x" />
                        {t("ERROR_NOT_FOUND_WORKSPACE_TITLE")}

                        <IconButton
                          onClick={this.handleCreateWorkspace}
                          className={"no-workspaces-button"}
                          appearance="ghost"
                          icon={<Icon icon="plus" />}
                        >
                          {t("BUTTON_CREATE")}
                        </IconButton>
                      </div>
                    )}
                </Row>
              </Container>
            }

            {isLoading && <Loader backdrop content={t("LOADING")} vertical />}
            <CreateWorkspace
              onRefresh={() => {
                this.getWorkspaces("");
              }}
              isActive={this.state.createWorkspaceModal}
              handleToggle={this.handleCreateWorkspace}
            />

            <CloneWorkspace
              onRefresh={() => {
                this.getWorkspaces("");
              }}
              isActive={this.state.cloneWorkspaceModal}
              handleToggle={this.handleCloneWorkspace}
              data={this.state.selectedWorkspace}
            />

            <MigrationModal
              isActive={this.state.isProgressWorkspace}
              handleToggle={this.handleIsProgressWorkspace}
              handleRefresh={this.handleCheckWorkspaceStatus}
              status={this.state.migrationStatus}
              migrationId={{ id: this.state.migrationId }}
            />
          </div>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer } = state;
  const { workspaces, workspace, selectedService } = workspaceReducer;

  return {
    workspaces,
    workspace,
    selectedService,
  };
};

const mapDispatchToProps = {
  updateWorkspace,
  updateWorkspaceList,
  clearWorkspace,
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);

const Card = (props) => {
  return (
    <div to={"workspace/" + props.cID}>
      <Badge content={props.demoBadge} style={{ width: "100%" }}>
        <Button
          onClick={props.handleSelect}
          appearance="subtle"
          className="wBoxButton"
        >
          <Panel className="wPanel" header={props.header} bordered shaded>
            <div className="workspace-panel">
              <div style={{ marginBottom: "1em", fontSize: "12px" }}>
                <Tag style={{ color: "rgb(166, 173, 174)" }}>
                  <Icon
                    icon={"clock-o"}
                    style={{
                      marginRight: "4px",
                      color: "rgba(30, 133, 146, 0.43)",
                    }}
                  />
                  {workspaceUpdateTime(props.data.updatedTime)}
                </Tag>
              </div>
              <div>
                <small className="w-title">Network</small>
                <small>:</small>
                <small>{props.data.ownNetwork}</small>
              </div>
              <div>
                <small className="w-title">Subnet</small>
                <small>:</small>
                <small>{props.data.ownSubnet}</small>
              </div>
            </div>
            <ButtonToolbar className="wButtonToolbar">
              <Button size="xs" appearance="primary">
                {i18n.t("BUTTON_ENTER")}
              </Button>
              <Divider vertical style={{ marginLeft: "12px" }} />
              <Button
                size="xs"
                appearance="ghost"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  props.handleCloneWorkspace();
                }}
              >
                {i18n.t("BUTTON_CLONE")}
              </Button>
            </ButtonToolbar>
          </Panel>
        </Button>
      </Badge>
    </div>
  );
};
