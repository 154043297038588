import React, { useEffect } from "react";
import { IconButton, Icon } from "rsuite";
import { Translation } from "react-i18next";
//DefaultCombinatorName
export default function RemoveRuleAction(props) {
  //debugger; //props.operator

  return (
    <Translation>
      {(t) => (
        <IconButton
          icon={<Icon icon={"close"} />}
          size={"xs"}
         // appearance="primary"
          onClick={props.handleOnClick}
        ></IconButton>
      )}
    </Translation>
  );
}
