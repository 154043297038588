import React, { Component } from 'react'
import { connect } from 'react-redux'
import classnames from "classnames";
import Emitter from "_actions/emitter";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";

import i18n from "i18n";
import { Translation } from "react-i18next";
import routes from "routes.js";
import { Alert,Message } from 'rsuite';
import { ResponseStatusCode } from "_helpers";
import ReactBSAlert from "react-bootstrap-sweetalert";
import {
    Container,
    Row,
    Col,
    Button
} from "reactstrap";


import MainNavbar from "components/Navbars/MainNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";

import Sidebar from "components/Sidebar/Sidebar";

import {
    GetAuthURL,
    userService,
    ProjectService,
    CMSService,
    WorkspaceService
} from "_services";

import {
    setHistory,
    LoginSuccess,
    updateWorkspaceList,
    //  setStaticCollections
} from "_actions";

import SelectProject from 'components/Modals/SelectProject';
import SweetModal from 'components/Modals/SweetModal';

class Main extends Component {
    constructor(props) {
        super(props)
        this.state = {
            rigthMenuTab: 0,
            sidenavOpen: false,
            checkModal: false,
            alert: null,
            noProject: false,
            projects: null

        }

    }
    handleSelectProject = () => {
        this.setState({
            noProject: !this.state.noProject,
        })
    }

    getProjets = () => {
        const that = this;
        ProjectService.GetPaging("-1", null, false, "0", "99999")
            .then(data => {
                //data.dynamicValue
                that.setState({
                    projects: data.dynamicValue,
                })
            }).catch(err => {
                debugger;
                Alert.error("Error code : " + err.statusCode);

            })
    }

    getRoutes = routes => {
        return routes.map((prop, key) => {
            if (prop.collapse) {
                return this.getRoutes(prop.views);
            }
            if (prop.layout === "/") {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                    />
                );
            } else {
                return null;
            }
        });
    };
    getRouteName = async () => {
        const that = this;
        let path = that.props.location.pathname;

        let result = {
            mainName: "boş",
            childName: "boş"
        }
        await routes.map(async (prop, key) => {
            if (("/" + prop.name) == path) {
                result = {
                    mainName: prop.name,
                    childName: ""
                }
                return;
            } else {
                if (prop.collapse) {
                    await prop.views.map((item, key) => {


                        if (("/" + item.path) == path) {
                            result = {
                                mainName: prop.name,
                                childName: item.name
                            }
                            return;
                        }
                    });

                }
            }
        });

        return result;

    };
    successAlert = (data) => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title={data.title}
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    confirmBtnText={i18n.t("BUTTON_OK")}
                    btnSize=""
                >
                    {data.desc}
                </ReactBSAlert>
            ),
        });
    };
    warningAlert = (data) => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title={data.title}
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText={i18n.t("BUTTON_OK")}
                    btnSize=""
                >
                    {data.desc}
                </ReactBSAlert>
            ),
        });
    };
    hideAlert = () => {
        this.setState({
            alert: null,
        });
    };

 

    sessionCheck = () => {
        const { loginSuccess, loginFailure, logout } = this.props;
        let res = localStorage.getItem("user");
        if (res === "null" || res === null || res === undefined) {
            userService.setRedirectTemp().then(data => {
                let token = data.dynamicValue;
                window.location.href = GetAuthURL() + token;
            }).catch(err => {

                //alert(err);
            });
        }
        else {

            //guncelle.
            userService.getMyInformation().then(mData => {
                let Data = mData.dynamicValue;
                let current = JSON.parse(res);
                Data.token = current.token;
                localStorage.setItem("user", JSON.stringify(Data));
                loginSuccess(Data);
                this.getWorkspaces()
               
            }).catch(err => {
                debugger
                //alert(err);

            });
        }
    }


    getWorkspaces = (_search = "") => {
        const { updateWorkspaceList } = this.props;
        const that = this;
        that.setState({ isLoading: true });
        WorkspaceService.STEPBYLOAD(false, "-1", 9999, _search, null).then(data => {
            //debugger;
            if (data.operationResult) {
                updateWorkspaceList(data.dynamicValue);
            }
            that.setState({ isLoading: false });
        }).catch(err => {
            that.setState({ isLoading: false });
            // let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
             debugger
        });
    }


    componentDidMount = (prevProps) => {
        this.sessionCheck(this);
        this.props.setHistory(this.props.history);
        //Emitter.on("UPDATE_PROJECT_ID", (newValue) => this.sessionCheck());

    }
    componentWillUnmount() {
        //Emitter.off("UPDATE_PROJECT_ID");

        Emitter.off("SHOW_WARNING_MODAL");
        Emitter.off("SHOW_SUCCESS_MODAL");

    }

    // toggles collapse between mini sidenav and normal
    toggleSidenav = e => {
        if (document.body.classList.contains("g-sidenav-pinned")) {
            document.body.classList.remove("g-sidenav-pinned");
            document.body.classList.add("g-sidenav-hidden");
        } else {
            document.body.classList.add("g-sidenav-pinned");
            document.body.classList.remove("g-sidenav-hidden");
        }
        this.setState({
            sidenavOpen: !this.state.sidenavOpen
        });
    };



    toggleNavs = (_index) => {
        let index = _index;
        if (_index == this.state.rigthMenuTab) { index = null; }
        this.setState({ rigthMenuTab: index, })
    }

    render() {
        return (
            <>

                <Sidebar {...this.props} toggleSidenav={this.toggleSidenav} sidenavOpen={this.state.sidenavOpen} />
                <div
                    className="main-content"
                    ref="mainContent"
                    onClick={this.closeSidenav}>
                    <MainNavbar
                        {...this.props}
                        theme={"light"}
                        toggleSidenav={this.toggleSidenav}
                        sidenavOpen={this.state.sidenavOpen}
                        brandText={""}
                        getNames={this.getRouteName}
                    />
                    
                    <div className={"main-layout-inside"}>
                        <Switch>
                            {this.getRoutes(routes)}
                            <Redirect from="*" to="/workspaces" />
                        </Switch>
                        {
                            /*
                                                    <AdminFooter />
                             */
                        }
                    </div>

                </div>
               
                <SweetModal />
                <SelectProject isActive={this.state.noProject} handleToggle={this.handleSelectProject} projectList={this.state.projects} />
                {this.state.alert}
                    {
                        /*
                                        <Message style={{zIndex:99999}} full showIcon type="warning" description="Warning" />

                        */
                    }

            </>

        )

    }
}

const mapStateToProps = (state, props) => {
    const { currentstatesReducer } = state;

    //  const { currentProjectId, staticCollections } = currentstatesReducer;

    return {
        //currentProjectId,
        //staticCollections,
    };
}
const mapDispatchToProps = {
    loginSuccess: LoginSuccess,
    setHistory,
    updateWorkspaceList
    //  setStaticCollections,
}

export default connect(mapStateToProps, mapDispatchToProps)(Main)
