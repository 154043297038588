import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Translation } from "react-i18next";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { ActionCreators as UndoActionCreators } from 'redux-undo'
import Emitter from "_actions/emitter";
import { pagesService,GetConsoleFrontEnd } from "_services";

import i18n from "i18n";
import { Alert } from 'rsuite';

import ProjectCard from 'components/Projects/ProjectCard';

import {
    Button,
    Input,
    Modal,
    Container,
    Row, Col
} from "reactstrap";

class SelectProject extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
        }
    }

    handleGoToConsole = () => {
        window.location.href=GetConsoleFrontEnd();
    }
    handleClose = () => {
        const { handleToggle } = this.props;
        handleToggle();
    }
    render() {
        const { isActive, handleToggle, projectList } = this.props;

        return (
            <Translation>
                {t => (
                    <>
                        <Modal
                            className="modal-dialog-centered"
                            isOpen={isActive}
                            size="lg"
                        // scrollable={true}
                        >
                            <div className="modal-header">
                                <h5 className="modal-title" id="CheckProjectModal">
                                    {t("MODAL_TITLE_SELECT_PROJECT")}
                                </h5>
                            </div>
                            <div className="modal-body">
                                <Container>
                                    <Row>
                                        {
                                            projectList &&
                                            projectList.dynamicValue.map((item, key) => (
                                                <Col xs="6" sm="3" lg="2" key={key} className={"project-col"}>
                                                    <ProjectCard isBussy={this.state.loading} data={item} handleClose={this.handleClose} />
                                                </Col>
                                            ))
                                        }
                                        {

                                            (!projectList || projectList.dynamicValue.length < 1) &&
                                            <Col>
                                                <p className={"faq-no-result-found"}>
                                                    {t("PROJECTS_NO_RESULT")}
                                                </p>
                                            </Col>

                                        }
                                    </Row>

                                </Container>
                            </div>
                            <div className="modal-footer">
                                {
                                    /*
                                      <Button
                                        color="white"
                                        size={"sm"}
                                        data-dismiss="modal"
                                        disabled={this.state.loading}
                                        type="button"
                                        onClick={() => handleToggle()}
                                    >
                                        {t("MODAL_CREATE_NEWPAGE_BUTTON_CANCEL")}
                                    </Button>
                                    */
                                }
                                <Button color="primary" size={"sm"} type="button" onClick={() => this.handleGoToConsole()}>
                                    {t("MODAL_SELECT_PROJECT_BUTTON_OPEN_CONSOLE")}
                                </Button>
                            </div>
                        </Modal>

                    </>

                )}
            </Translation>
        )
    }
}

const mapStateToProps = (state, props) => {
    const { componentReducer, editorReducer, currentstatesReducer } = state;
   // const { currentProjectId } = currentstatesReducer;

 
    return {
       
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        clearHistory: () => dispatch(UndoActionCreators.clearHistory()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SelectProject)
