import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";

import i18n from "i18n";
import { Modal, Container, Row, Col, Card, CardBody } from "reactstrap";

import AvatarCard from "components/Custom/AvatarCard";
import DynamicTag from "components/Custom/DynamicTag";
import AdvancedBox from "components/Custom/EditRow/AdvancedBox";
import { inputDefaultsService } from "_services";
import { ResponseStatusCode } from "_helpers";

import {
  Toggle,
  Icon,
  Tooltip,
  Popover,
  Whisper,
  Loading,
  Button,
  Alert,
  Animation,
  Checkbox,
  CheckPicker,
} from "rsuite";
import {
  HelpBlock,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  SelectPicker,
  InputNumber,
  DatePicker,
  Schema,
  ButtonToolbar,
  Nav,
} from "rsuite";
import Slider from "react-slick";
import { decamelize } from "assets/globalOps/global";
import { isoLangs } from "assets/globalOps/isoLangs";
import {
  contentTypesSP,
  typeDetailsSP,
} from "assets/globalOps/CollectionTypes";
const { StringType, NumberType, ArrayType, DateType, ObjectType, BooleanType } =
  Schema.Types;
const model = Schema.Model({});

class EditRowData extends Component {
  constructor(props) {
    super(props);
    this.triggerRefRelationModel = React.createRef();
    this.triggerRefColums = React.createRef();
    this.triggerRelationType = React.createRef();

    this.state = {
      formValue: {},
      formError: {},
      advanceFormValue: {},
      advanceFormError: {},
      loading: false,
      counter: 0,
      activeTab: "basic",
      selectedContentType: null,
      selectedInputs: [],
      selectedEvents: [],
      isAdvLoading: true,
    };
  }

  handleTabSelect = (activeKey) => {
    const that = this;
    this.setState({ activeTab: activeKey }, () => {
      that.slider.slickGoTo(activeKey == "basic" ? 0 : 1);
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      isActive,
      handleToggle,
      dataModel,
      schemaModel,
      schemaModel2,
      isDynamic,
      isFromMVC,
    } = this.props;
    const that = this;
    let formValue = Object.assign({}, this.state.formValue);

    if (isActive && !isDynamic) {
      if (prevState.formValue != formValue) {
        const _typeDetails = typeDetailsSP.filter(
          (x) => x.role == formValue["contenttype"]
        );
        if (
          prevState.selectedContentType !== this.state.selectedContentType ||
          this.state.selectedContentType == null
        ) {
          if (formValue["contentType"])
            this.handleGetAdvancedOptions(formValue["contentType"]);
        }

        if (
          _typeDetails.length == 0 &&
          formValue.hasOwnProperty("typedetail")
        ) {
          delete formValue.typedetail;

          this.setState({
            formValue: formValue,
          });
        } else if (
          _typeDetails.length > 0 &&
          formValue.hasOwnProperty("typedetail") &&
          prevState.formValue.contenttype !== formValue.contenttype
        ) {
          let _hasObj = _typeDetails.findIndex(
            (x) => x.value == formValue.typedetail
          );

          if (_hasObj == -1) {
            let _model = Schema.Model({});

            const _typeDetails = typeDetailsSP.filter(
              (x) => x.role == this.state.formValue["contenttype"]
            );
            const _dynamicSchemaModel =
              isActive && isDynamic && this.prepareGenerateSchema();
            _model = isDynamic
              ? _dynamicSchemaModel
                ? _dynamicSchemaModel
                : undefined
              : schemaModel
              ? _typeDetails && _typeDetails.length > 0
                ? schemaModel2
                : schemaModel
              : model;

            _model = Schema.Model({
              ...Object.keys(_model.schema).reduce(
                (c, k) => ((c[k.toLowerCase()] = _model.schema[k]), c),
                {}
              ),
            });
            let hasEnum =
              this.props.data.hasOwnProperty("contentType") &&
              this.props.data.contentType == "6";
            hasEnum = !hasEnum
              ? this.props.data.hasOwnProperty("contenttype") &&
                this.props.data.contenttype == "6"
              : hasEnum;
            if (hasEnum) {
              _model.schema["defaultdata"] = ArrayType()
                .isRequired(i18n.t("ERROR_DM_GLOBAL_REQUIRED_ENUM"))
                .minLength(2, i18n.t("ERROR_DM_GLOBAL_REQUIRED_ENUM"));
            } else {
              delete _model.schema.defaultdata;
            }

            formValue.typedetail = null;
            this.setState({
              _model: _model,
              formValue: formValue,
            });
          }
        }
      }
    }
    if (prevProps.isActive !== this.props.isActive) {
      if (this.props.isActive) {
        let _model = Schema.Model({});

        const _typeDetails = typeDetailsSP.filter(
          (x) =>
            x.role ==
            this.state.formValue[isFromMVC ? "contentType" : "contenttype"]
        );
        const _dynamicSchemaModel =
          isActive && isDynamic && this.prepareGenerateSchema();
        _model = isDynamic
          ? _dynamicSchemaModel
            ? _dynamicSchemaModel
            : undefined
          : schemaModel
          ? _typeDetails && _typeDetails.length > 0
            ? schemaModel2
            : schemaModel
          : model;

        _model = Schema.Model({
          ...Object.keys(_model.schema).reduce(
            (c, k) => (
              (c[isFromMVC ? k : k.toLowerCase()] = _model.schema[k]), c
            ),
            {}
          ),
        });
        let hasEnum =
          this.props.data.hasOwnProperty("contentType") &&
          this.props.data.contentType == "6";
        hasEnum = !hasEnum
          ? this.props.data.hasOwnProperty("contenttype") &&
            this.props.data.contenttype == "6"
          : hasEnum;
        if (hasEnum) {
          _model.schema["defaultdata"] = ArrayType()
            .isRequired(i18n.t("ERROR_DM_GLOBAL_REQUIRED_ENUM"))
            .minLength(2, i18n.t("ERROR_DM_GLOBAL_REQUIRED_ENUM"));
        } else {
          delete _model.schema.defaultdata;
        }

        this.setState(
          {
            _model: _model,
            formValue: {
              ...Object.keys(this.props.data).reduce(
                (c, k) => (
                  (c[isFromMVC ? k : k.toLowerCase()] =
                    k == "contentType" || k == "typeDetail"
                      ? this.props.data[k] + ""
                      : this.props.data[k]),
                  c
                ),
                {}
              ),
            },
          },
          () => {
            let { formValue } = this.state;
            let { isActive, schemaModel } = this.props;

            if (!this.props.isDynamic) {
              if (formValue.hasOwnProperty("contenttype")) {
                if (
                  prevState.formValue &&
                  prevState.formValue.hasOwnProperty("contenttype")
                ) {
                  // geçmişte enum yapılmış
                  if (
                    formValue["contenttype"] != "6" &&
                    formValue.hasOwnProperty("defaultdata")
                  ) {
                    //                    debugger

                    //defaultData formValue'dan silinmeli
                    let __model = this.state._model;
                    let _nObj = Object.assign({}, formValue);
                    delete _nObj.defaultdata;
                    delete __model.schema.defaultdata;
                    delete __model.schema.defaultData;

                    // __model = {...Object.keys(__model.schema).reduce((c, k) => (c[k.toLowerCase()] = __model.schema[k], c), {})}
                    this.setState({
                      _model: __model,
                      formValue: _nObj,
                    });
                  }
                } else if (
                  formValue["contenttype"] == "6" &&
                  !formValue.hasOwnProperty("defaultdata")
                ) {
                  let __model = this.state._model;
                  __model.schema["defaultdata"] = ArrayType()
                    .isRequired(i18n.t("ERROR_DM_GLOBAL_REQUIRED_ENUM"))
                    .minLength(2, i18n.t("ERROR_DM_GLOBAL_REQUIRED_ENUM"));

                  this.setState({
                    _model: __model,
                    formValue: {
                      ...this.state.formValue,
                      defaultdata: [],
                    },
                  });
                }
              }
            }
          }
        );
      }
    }
  }

  componentDidMount = () => {
    const that = this;
    debugger;
  };

  handleSubmit = (e) => {
    const that = this;
    const { formValue, formError } = this.state;
    if (!this.form.check()) {
      debugger;
      return;
    }
    debugger;
    this.props.handleUpdateRow(this.state.formValue);
    this.handleCloseModal();
  };

  handleCloseModal = () => {
    const { handleToggle } = this.props;
    this.setState(
      {
        formValue: {},
        formError: {},
        advanceFormValue: {},
        advanceFormError: {},
        loading: false,
        counter: 0,
        activeTab: "basic",
        selectedInputs: [],
        selectedEvents: [],
        selectedContentType: null,
        isAdvLoading: true,
      },
      () => {
        handleToggle();
      }
    );
  };
  prepareGenerateSchema = () => {
    const { isDynamic, dataModel } = this.props;
    let model = {}; // Schema.Model({});
    debugger;
    dataModel.map((item, key) => {
      if (!item.privateField) {
        let _obj = {};
        switch (item.contentType) {
          case 1:
            // String
            _obj = StringType();
            break;
          case 2:
            // Rich Text
            _obj = StringType();
            break;
          case 3:
            // Number
            _obj = NumberType();
            break;
          case 4:
            // Date
            _obj = DateType();
            break;
          case 5:
            // Relations
            _obj = ObjectType();
            break;
          case 6:
            // Enum
            _obj = StringType();
            break;
          case 7:
            // Boolean
            _obj = BooleanType();
            break;
          case 8:
            // Identity
            _obj = StringType();
            break;
          case 9:
            // Image
            _obj = ObjectType();
            break;
          case 10:
            // Relations
            _obj = ObjectType(); // ObjectType();
            break;
          case 11:
            // Relations
            _obj = ObjectType(); // ObjectType();
            break;
          case 12:
            // Relations
            _obj = ObjectType(); // ObjectType();
            break;
          case 13:
            // Relations
            _obj = ArrayType(); // ObjectType();
            break;
            case 15:
              // Relations
              _obj = ObjectType();
              break;
            case 16:
              // Relations
              _obj = ObjectType(); // ObjectType();
              break;
              case 17:
                // Relations
                _obj = ObjectType(); // ObjectType();
                break;
          default:
            break;
        }
        if (item.reqiured || item.notNull) {
          _obj.isRequired(i18n.t("ERROR_DM_GLOBAL_REQUIRED"));
        }
        if (item.uniq) {
        }

        switch (item.typeDetail) {
          case 1:
            // STANDART
            break;
          case 2:
            // PASSWORD
            break;
          case 3:
            // EMAIL
            _obj.isEmail(i18n.t("ERROR_DM_GLOBAL_EMAIL_FORMAT"));
            break;
          case 4:
            // INT
            _obj.isInteger(i18n.t("ERROR_DM_GLOBAL_INT"));
            break;
          case 5:
            // BIGINT
            _obj.isInteger(i18n.t("ERROR_DM_GLOBAL_INT"));
            break;
          case 6:
            // DECIMAL
            break;
          case 7:
            // DOUBLE
            break;
          default:
            break;
        }
        if (
          item.contentType !== 8 &&
          item.contentType !== 12 &&
          item.contentType !== 11 &&
          item.contentType !== 14 &&
          item.contentType !== 17
        ) {
          model[item.name.toLowerCase()] = _obj;
        }
      }
    });
    return Schema.Model(model);
  };

  handleUpdatePP = (_data, cmsKey) => {
    let _formValue = this.state.formValue;
    _formValue[cmsKey] = _data;
    this.setState({
      formValue: _formValue,
    });
  };

  handleUpdateEnum = (_data) => {
    const { formValue } = this.state;
    formValue["defaultData".toLowerCase()] = _data;
    this.setState({
      formValue: formValue,
    });
  };

  handleGetAdvancedOptions = (_id) => {
    const that = this;
    that.setState({ isAdvLoading: true, selectedContentType: _id });
    inputDefaultsService
      .GET_SELECTED_EVENTS(_id)
      .then((data) => {
        that.setState({ selectedEvents: data.dynamicValue });
        inputDefaultsService
          .GET_SELECTED_INPUTS(_id)
          .then((data) => {
            that.setState({
              selectedInputs: data.dynamicValue,
              isAdvLoading: false,
            });
          })
          .catch((err) => {
            debugger;
            Alert.warning(ResponseStatusCode(err.statusCode));
            that.setState({ isAdvLoading: false });
            debugger;
          });
      })
      .catch((err) => {
        Alert.warning(ResponseStatusCode(err.statusCode));
        that.setState({ isAdvLoading: false });
        debugger;
      });
  };

  getCurrentLangDisplayName = (item) => {
    try {
      const _lang = this.props.currentLang;
      if (_lang) {
        let _langDNM = item.displayNameMultiLangs.find((x) => x.lang == _lang);
        if (_langDNM && _langDNM.textValue.trim().length > 0) {
          return _langDNM.textValue;
        } else {
          _langDNM = item.displayNameMultiLangs.find((x) => x.lang == "en");
          return _langDNM.textValue;
        }
      } else {
        const _langDNM = item.displayNameMultiLangs.find((x) => x.lang == "en");
        return _langDNM.textValue;
      }
    } catch (error) {
      const { isFromMVC } = this.props;
      return item.hasOwnProperty("displayName")
        ? item.displayName
        : decamelize(isFromMVC ? item.name : item.name.toLowerCase(), " ");
    }
  };

  render() {
    const {
      isActive,
      handleToggle,
      dataModel,
      schemaModel,
      schemaModel2,
      isDynamic,
      selectedService,
      isFromMVC,
      model,
      models,
      allTransactions,
    } = this.props;
    const {
      formValue,
      formError,
      loading,
      _model,
      activeTab,
      advanceFormValue,
      selectedInputs,
      selectedEvents,
    } = this.state;

    const _typeDetails = typeDetailsSP.filter(
      (x) => x.role == formValue[isFromMVC ? "contentType" : "contenttype"]
    );
    const settings = {
      dots: false,
      infinite: false,
      speed: 10,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipe: false,
      adaptiveHeight: true,
    };

    const Locale = {
      sunday: i18n.t("sunday"),
      monday: i18n.t("monday"),
      tuesday: i18n.t("tuesday"),
      wednesday: i18n.t("wednesday"),
      thursday: i18n.t("thursday"),
      friday: i18n.t("friday"),
      saturday: i18n.t("saturday"),
      ok: i18n.t("ok"),
      today: i18n.t("today"),
      yesterday: i18n.t("yesterday"),
      hours: i18n.t("hours"),
      minutes: i18n.t("minutes"),
      seconds: i18n.t("seconds"),
    };

    const _hasList = dataModel.find(
      (x) =>
        (x.contentType == 5 && x.relationModel.relationType) ||
        (x.contentType == 10 && x.refModel.relationType)
    );

    if (
      formValue[isFromMVC ? "contentType" : "contentType".toLowerCase()] == "5"
    ) {
      debugger;
    }

    if (model) {
      debugger;
    }

    return (
      <Translation>
        {(t) => (
          <Modal
            className="modal-dialog-centered"
            isOpen={isActive}
            //size="lg"
            //toggle={this.handleCloseModal}
            scrollable={true}
          >
            <div className="modal-body" style={{ padding: 0 }}>
              <div className="modal-header">
                <h5 className="modal-title" id="CheckProjectModal">
                  {t("TITLE_MODAL_EDIT_ROW")}
                </h5>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.handleCloseModal()}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
            </div>

            <div className="modal-body" style={{ padding: 0 }}>
              {(selectedInputs.length > 0 || selectedEvents.length > 0) && (
                <Nav
                  appearance="subtle"
                  activeKey={this.state.activeTab}
                  onSelect={this.handleTabSelect}
                  className="fade-in"
                  style={{ marginBottom: "1em", padding: "0 1em" }}
                >
                  <Nav.Item eventKey="basic">
                    {t("TITLE_TRANSACTION_SETTINGS_BASIC")}
                  </Nav.Item>
                  <Nav.Item eventKey="advanced">
                    {t("TITLE_TRANSACTION_SETTINGS_ADVANCED")}
                  </Nav.Item>
                </Nav>
              )}

              <Slider
                ref={(slider) => (this.slider = slider)}
                {...settings}
                className="menu-carusel-trans"
              >
                <div>
                  <Container>
                    <Row>
                      <Col
                        style={{ marginBottom: "15px", padding: "0px 1.25rem" }}
                      >
                        <Form
                          ref={(ref) => (this.form = ref)}
                          onChange={(formValue) => {
                            this.setState({ formValue });
                          }}
                          onCheck={(formError) => {
                            this.setState({ formError });
                          }}
                          className={"dynamic-create-form"}
                          formValue={formValue}
                          model={_model}
                          fluid
                        >
                          {dataModel &&
                            dataModel.map((item, key) =>
                              //  !item.privateField &&
                              item.contentType == "typeDetails" ? (
                                _typeDetails &&
                                _typeDetails.length > 0 && (
                                  <FormGroup
                                    className={"custom-fade-in"}
                                    key={key}
                                  >
                                    <ControlLabel className={"cell-header"}>
                                      {this.getCurrentLangDisplayName(item)}
                                    </ControlLabel>
                                    <FormControl
                                      cleanable={false}
                                      block
                                      placement="autoVertical"
                                      accepter={SelectPicker}
                                      data={_typeDetails}
                                      disabled={item.privateField || loading}
                                      name={
                                        isFromMVC
                                          ? item.name
                                          : item.name.toLowerCase()
                                      }
                                    />
                                  </FormGroup>
                                )
                              ) : (
                                <FormGroup key={key}>
                                  <ControlLabel className={"cell-header"}>
                                    {this.getCurrentLangDisplayName(item)}
                                  </ControlLabel>
                                  {item.contentType == "string" ||
                                  item.contentType == 1 ? (
                                    <FormControl
                                      type={
                                        item["typeDetail"] == 2
                                          ? "password"
                                          : "text"
                                      }
                                      disabled={
                                        item.privateField ||
                                        loading ||
                                        (isFromMVC && item.name === "name")
                                      }
                                      name={
                                        isFromMVC
                                          ? item.name
                                          : item.name.toLowerCase()
                                      }
                                      className={"custom-fade-in"}
                                    />
                                  ) : item.contentType == "boolean" ||
                                    item.contentType == 7 ? (
                                    <FormControl
                                      accepter={Toggle}
                                      disabled={item.privateField || loading}
                                      defaultChecked={
                                        formValue[
                                          isFromMVC
                                            ? item.name
                                            : item.name.toLowerCase()
                                        ]
                                      }
                                      name={
                                        isFromMVC
                                          ? item.name
                                          : item.name.toLowerCase()
                                      }
                                      className={"custom-fade-in"}
                                    />
                                  ) : item.contentType == "contentTypes" ? (
                                    <>
                                      <FormControl
                                        cleanable={false}
                                        block
                                        placement="autoVertical"
                                        onChange={(value) => {
                                          let _fv = Object.assign(
                                            {},
                                            { ...this.state.formValue }
                                          );
                                          delete _fv["relationModel"];
                                          delete _fv["refModel"];
                                          _fv["contentType"] = value;
                                          if (value)
                                            this.handleGetAdvancedOptions(
                                              value
                                            );
                                          const that = this;
                                          // debugger;
                                          that.setState({ formValue: _fv });
                                        }}
                                        accepter={SelectPicker}
                                        data={contentTypesSP}
                                        disabled={item.privateField || loading}
                                        name={
                                          isFromMVC
                                            ? item.name
                                            : item.name.toLowerCase()
                                        }
                                        className={"custom-fade-in"}
                                      />
                                      {
                                        //ENUM type

                                        formValue[
                                          isFromMVC
                                            ? "contentType"
                                            : "contentType".toLowerCase()
                                        ] == "6" ? (
                                          <>
                                            {!item.privateField && (
                                              <div
                                                className={"dynamic-dt-side"}
                                              >
                                                <div
                                                  className={
                                                    "dynamic-enum-side"
                                                  }
                                                >
                                                  <DynamicTag
                                                    data={
                                                      formValue[
                                                        isFromMVC
                                                          ? "defaultData"
                                                          : "defaultData".toLowerCase()
                                                      ]
                                                        ? formValue[
                                                            isFromMVC
                                                              ? "defaultData"
                                                              : "defaultData".toLowerCase()
                                                          ]
                                                        : []
                                                    }
                                                    handleUpdate={
                                                      this.handleUpdateEnum
                                                    }
                                                  />
                                                </div>
                                                <div
                                                  className={
                                                    "dynamic-dt-popover"
                                                  }
                                                >
                                                  {this.state.formError.hasOwnProperty(
                                                    "defaultdata"
                                                  ) && (
                                                    <Popover
                                                      visible
                                                      className={
                                                        "custom-white-tooltip"
                                                      }
                                                    >
                                                      <p>
                                                        {t(
                                                          "ERROR_DM_GLOBAL_REQUIRED_ENUM"
                                                        )}{" "}
                                                      </p>
                                                    </Popover>
                                                  )}
                                                </div>
                                              </div>
                                            )}
                                          </>
                                        ) : formValue[
                                            isFromMVC
                                              ? "contentType"
                                              : "contentType".toLowerCase()
                                          ] == "5" ? ( // Relation sub settings
                                          <>
                                            <ControlLabel
                                              style={{ marginTop: "24px" }}
                                              className={"cell-header"}
                                            >
                                              {t(
                                                "MODAL_CREATEROWDATA_TITLE_DBMODEL"
                                              )}
                                            </ControlLabel>
                                            <Whisper
                                              key={"sp-relmodel-selector"}
                                              trigger="none"
                                              ref={this.triggerRefRelationModel}
                                              placement={"bottomStart"}
                                              speaker={
                                                <Popover
                                                  visible
                                                  className={
                                                    "custom-white-tooltip"
                                                  }
                                                >
                                                  <p>
                                                    {t(
                                                      "ERROR_DM_GLOBAL_REQUIRED"
                                                    )}{" "}
                                                  </p>
                                                </Popover>
                                              }
                                            >
                                              <SelectPicker
                                                defaultValue={
                                                  formValue.hasOwnProperty(
                                                    camelize("RelationModel")
                                                  )
                                                    ? model.filter(
                                                        (sModel) =>
                                                          sModel.id ===
                                                          formValue[
                                                            camelize(
                                                              "RelationModel"
                                                            )
                                                          ][
                                                            camelize("RelModel")
                                                          ].id
                                                      )[0]
                                                    : undefined
                                                }
                                                data={
                                                  model &&
                                                  model
                                                    .filter((x) => {
                                                      return (
                                                        (x.isDatabaseObject ||
                                                          x.isDataBaseFalseObjectAllowTransaction) &&
                                                        selectedService &&
                                                        x.id !==
                                                          selectedService.relBaseModelId
                                                      );
                                                    })
                                                    .map((x) => {
                                                      const that = this;

                                                      return {
                                                        label: x.moduleWModel,
                                                        value: x, //x.id,
                                                        data: x,
                                                      };
                                                    })
                                                }
                                                onChange={(value) => {
                                                  this.triggerRefRelationModel.current.close();

                                                  let _obj = Object.assign(
                                                    {},
                                                    this.state.formValue
                                                  ); //
                                                  let _RelationModel = {
                                                    [camelize("RelModel")]: {
                                                      id: value.id,
                                                      [camelize("ModelName")]:
                                                        value.modelName,
                                                      [camelize("RelModuleId")]:
                                                        value.relModuleId,
                                                    },
                                                    //"RelationType": 0,
                                                    //"ListColumn": "CompanyName"
                                                  };
                                                  this.setState({
                                                    formValue: {
                                                      ..._obj,
                                                      [isFromMVC
                                                        ? "relationModel"
                                                        : "RelationModel".toLowerCase()]:
                                                        _RelationModel,
                                                    },
                                                  });
                                                }}
                                                disabled={
                                                  item.privateField || loading
                                                }
                                                className={"custom-fade-in"}
                                                cleanable={false}
                                                block
                                                name={
                                                  isFromMVC
                                                    ? "relationModel"
                                                    : "RelationModel".toLowerCase()
                                                }
                                                placement="autoVertical"
                                              />
                                            </Whisper>

                                            {formValue[
                                              isFromMVC
                                                ? "relationModel"
                                                : "RelationModel".toLowerCase()
                                            ] &&
                                              model.filter(
                                                (sModel) =>
                                                  sModel.id ===
                                                  formValue[
                                                    isFromMVC
                                                      ? "relationModel"
                                                      : "RelationModel".toLowerCase()
                                                  ][camelize("RelModel")].id
                                              ).length > 0 && (
                                                <>
                                                  <ControlLabel
                                                    style={{
                                                      marginTop: "24px",
                                                    }}
                                                    className={"cell-header"}
                                                  >
                                                    {t(
                                                      "MODAL_CREATEROWDATA_TITLE_COLUMN"
                                                    )}
                                                  </ControlLabel>
                                                  <Whisper
                                                    trigger="none"
                                                    ref={this.triggerRefColums}
                                                    placement={"bottomStart"}
                                                    speaker={
                                                      <Popover
                                                        visible
                                                        className={
                                                          "custom-white-tooltip"
                                                        }
                                                      >
                                                        <p>
                                                          {t(
                                                            "ERROR_DM_GLOBAL_REQUIRED"
                                                          )}{" "}
                                                        </p>
                                                      </Popover>
                                                    }
                                                  >
                                                    <SelectPicker
                                                      defaultValue={
                                                        formValue.hasOwnProperty(
                                                          camelize(
                                                            "RelationModel"
                                                          )
                                                        ) &&
                                                        formValue[
                                                          camelize(
                                                            "RelationModel"
                                                          )
                                                        ].hasOwnProperty(
                                                          camelize("RelModel")
                                                        ) &&
                                                        model
                                                          .filter(
                                                            (sModel) =>
                                                              sModel.id ===
                                                              formValue[
                                                                camelize(
                                                                  "RelationModel"
                                                                )
                                                              ][
                                                                camelize(
                                                                  "RelModel"
                                                                )
                                                              ].id
                                                          )[0]
                                                          .dataTypes.find(
                                                            (x) =>
                                                              x.name ==
                                                              this.state
                                                                .formValue[
                                                                "relationModel"
                                                              ].listColumn
                                                          )
                                                      }
                                                      data={
                                                        model &&
                                                        model
                                                          .filter(
                                                            (sModel) =>
                                                              sModel.id ===
                                                              formValue[
                                                                camelize(
                                                                  "RelationModel"
                                                                )
                                                              ][
                                                                camelize(
                                                                  "RelModel"
                                                                )
                                                              ].id
                                                          )[0]
                                                          .dataTypes.map(
                                                            (x, key) => {
                                                              return {
                                                                label: x.name,
                                                                value: x, //key,
                                                                data: x,
                                                              };
                                                            }
                                                          )
                                                      }
                                                      onChange={(value) => {
                                                        this.triggerRefColums.current.close();
                                                        let _obj =
                                                          Object.assign(
                                                            {},
                                                            this.state.formValue
                                                          ); //
                                                        let _RelationModel = {
                                                          //"RelModel": {
                                                          //    "id": value.id,
                                                          //    "ModelName": value.modelName,
                                                          //    "RelModuleId": value.relModuleId
                                                          //},
                                                          //"RelationType": 0,
                                                          [camelize(
                                                            "ListColumn"
                                                          )]: value.name,
                                                        };
                                                        this.setState({
                                                          formValue: {
                                                            ..._obj,
                                                            [camelize(
                                                              "RelationModel"
                                                            )]: {
                                                              ..._obj[
                                                                camelize(
                                                                  "RelationModel"
                                                                )
                                                              ],
                                                              ..._RelationModel,
                                                            },
                                                          },
                                                        });
                                                      }}
                                                      disabled={
                                                        item.privateField ||
                                                        loading
                                                      }
                                                      className={
                                                        "custom-fade-in"
                                                      }
                                                      cleanable={false}
                                                      block
                                                      placement="autoVertical"
                                                    />
                                                  </Whisper>

                                                  <ControlLabel
                                                    style={{
                                                      marginTop: "24px",
                                                    }}
                                                    className={"cell-header"}
                                                  >
                                                    {t(
                                                      "MODAL_CREATEROWDATA_TITLE_RELATION_ANY_READ"
                                                    )}
                                                  </ControlLabel>
                                                  <Whisper
                                                    trigger="none"
                                                    ref={this.triggerRefColums}
                                                    placement={"bottomStart"}
                                                    speaker={
                                                      <Popover
                                                        visible
                                                        className={
                                                          "custom-white-tooltip"
                                                        }
                                                      >
                                                        <p>
                                                          {t(
                                                            "ERROR_DM_GLOBAL_REQUIRED"
                                                          )}{" "}
                                                        </p>
                                                      </Popover>
                                                    }
                                                  >
                                                    <SelectPicker
                                                      defaultValue={
                                                        formValue.hasOwnProperty(
                                                          camelize(
                                                            "RelationModel"
                                                          )
                                                        ) &&
                                                        formValue[
                                                          camelize(
                                                            "RelationModel"
                                                          )
                                                        ].hasOwnProperty(
                                                          camelize("RelModel")
                                                        ) &&
                                                        allTransactions &&
                                                        formValue[
                                                          camelize(
                                                            "RelationModel"
                                                          )
                                                        ][
                                                          camelize(
                                                            "ListAnyRead"
                                                          )
                                                        ]
                                                          ? formValue[
                                                              camelize(
                                                                "RelationModel"
                                                              )
                                                            ][
                                                              camelize(
                                                                "ListAnyRead"
                                                              )
                                                            ]
                                                          : allTransactions
                                                              .find(
                                                                (sModel) =>
                                                                  sModel.model
                                                                    .id ===
                                                                  formValue[
                                                                    camelize(
                                                                      "RelationModel"
                                                                    )
                                                                  ][
                                                                    camelize(
                                                                      "RelModel"
                                                                    )
                                                                  ].id
                                                              )
                                                              .transActions.find(
                                                                (x) =>
                                                                  x.functionName ==
                                                                  "read"
                                                              ).id
                                                      }
                                                      data={
                                                        allTransactions &&
                                                        allTransactions
                                                          .find(
                                                            (sModel) =>
                                                              sModel.model
                                                                .id ===
                                                              formValue[
                                                                camelize(
                                                                  "RelationModel"
                                                                )
                                                              ][
                                                                camelize(
                                                                  "RelModel"
                                                                )
                                                              ].id
                                                          )
                                                          .transActions.filter(
                                                            (f) =>
                                                              f.responseModelType ==
                                                              1
                                                          )
                                                          .map((x, key) => {
                                                            return {
                                                              label: x.name,
                                                              value: x.id, //key,
                                                              data: x,
                                                            };
                                                          })
                                                      }
                                                      onChange={(value) => {
                                                        this.triggerRefColums.current.close();
                                                        let _obj =
                                                          Object.assign(
                                                            {},
                                                            this.state.formValue
                                                          ); //
                                                        let _RelationModel = {
                                                          //"RelModel": {
                                                          //    "id": value.id,
                                                          //    "ModelName": value.modelName,
                                                          //    "RelModuleId": value.relModuleId
                                                          //},
                                                          //"RelationType": 0,
                                                          [camelize(
                                                            "ListAnyRead"
                                                          )]: value,
                                                        };

                                                        debugger;
                                                        this.setState({
                                                          formValue: {
                                                            ..._obj,
                                                            [camelize(
                                                              "RelationModel"
                                                            )]: {
                                                              ..._obj[
                                                                camelize(
                                                                  "RelationModel"
                                                                )
                                                              ],
                                                              ..._RelationModel,
                                                            },
                                                          },
                                                        });
                                                      }}
                                                      disabled={
                                                        item.privateField ||
                                                        loading
                                                      }
                                                      className={
                                                        "custom-fade-in"
                                                      }
                                                      cleanable={false}
                                                      block
                                                      placement="autoVertical"
                                                    />
                                                  </Whisper>

                                                  <ControlLabel
                                                    style={{
                                                      marginTop: "24px",
                                                    }}
                                                    className={"cell-header"}
                                                  >
                                                    {t(
                                                      "MODAL_CREATEROWDATA_TITLE_RELATIONTYPE"
                                                    )}
                                                  </ControlLabel>
                                                  <Whisper
                                                    key={"sp-relmodel-relType"}
                                                    trigger="none"
                                                    ref={
                                                      this.triggerRelationType
                                                    }
                                                    placement={"bottomStart"}
                                                    speaker={
                                                      <Popover
                                                        visible
                                                        className={
                                                          "custom-white-tooltip"
                                                        }
                                                      >
                                                        <p>
                                                          {t(
                                                            "ERROR_DM_GLOBAL_REQUIRED"
                                                          )}{" "}
                                                        </p>
                                                      </Popover>
                                                    }
                                                  >
                                                    <SelectPicker
                                                      defaultValue={
                                                        this.state.formValue[
                                                          "relationModel"
                                                        ].hasOwnProperty(
                                                          "relationType"
                                                        )
                                                          ? this.state
                                                              .formValue[
                                                              "relationModel"
                                                            ]["relationType"]
                                                          : undefined
                                                      }
                                                      data={[
                                                        {
                                                          label: i18n.t(
                                                            "ResponseModelTypeSingle"
                                                          ),
                                                          value: 0,
                                                        },
                                                        {
                                                          label: i18n.t(
                                                            "ResponseModelTypeList"
                                                          ),
                                                          value: 1,
                                                        },
                                                      ]}
                                                      onChange={(value) => {
                                                        this.triggerRelationType.current.close();
                                                        let _obj =
                                                          Object.assign(
                                                            {},
                                                            this.state.formValue
                                                          ); //
                                                        let _RelationModel = {
                                                          //"RelModel": {
                                                          //    "id": value.id,
                                                          //    "ModelName": value.modelName,
                                                          //    "RelModuleId": value.relModuleId
                                                          //},
                                                          [camelize(
                                                            "RelationType"
                                                          )]: value,
                                                          //"ListColumn": value.name
                                                        };
                                                        this.setState({
                                                          formValue: {
                                                            ..._obj,
                                                            [camelize(
                                                              "RelationModel"
                                                            )]: {
                                                              ..._obj[
                                                                camelize(
                                                                  "RelationModel"
                                                                )
                                                              ],
                                                              ..._RelationModel,
                                                            },
                                                          },
                                                        });
                                                      }}
                                                      disabled={
                                                        item.privateField ||
                                                        loading
                                                      }
                                                      className={
                                                        "custom-fade-in"
                                                      }
                                                      cleanable={false}
                                                      block
                                                      placement="autoVertical"
                                                    />
                                                  </Whisper>

                                                  {
                                                    this.state.formValue[
                                                      "relationModel"
                                                    ].hasOwnProperty(
                                                      "relationType"
                                                    )
                                                      && this.state
                                                          .formValue[
                                                          "relationModel"
                                                        ]["relationType"]  &&
                                                        <>
                                                        <ControlLabel
                                                          style={{ marginTop: "24px" }}
                                                          className={"cell-header"}
                                                        >
                                                          {t(
                                                            "MODAL_CREATEROWDATA_TITLE_IS_MULTIPLE_SELECT_LIST"
                                                          )}
                                                        </ControlLabel>
                                                        <FormControl
                                                          accepter={Toggle}
                                                          disabled={
                                                            item.privateField || loading
                                                          }
                                                          name={camelize(
                                                            "IsMultipleSelectList"
                                                          )}
                                                          defaultChecked={formValue[camelize(
                                                            "IsMultipleSelectList"
                                                          )]}
                                                          className={"custom-fade-in"}
                                                        />
                                                      </>
                                                  }
                                                </>
                                              )}

                                            <>
                                              <ControlLabel
                                                style={{ marginTop: "24px" }}
                                                className={"cell-header"}
                                              >
                                                {t(
                                                  "MODAL_CREATEROWDATA_TITLE_SEND_CURRENT_MODEL"
                                                )}
                                              </ControlLabel>
                                              <FormControl
                                                accepter={Toggle}
                                                disabled={
                                                  item.privateField || loading
                                                }
                                                name={camelize(
                                                  "SendCurrentModel"
                                                )}
                                                className={"custom-fade-in"}
                                              />
                                            </>

                                            <>
                                              {formValue[
                                                camelize("RelationModel")
                                              ] &&
                                                formValue[
                                                  camelize("SendCurrentModel")
                                                ] && (
                                                  <>
                                                    <ControlLabel
                                                      style={{
                                                        marginTop: "24px",
                                                      }}
                                                      className={"cell-header"}
                                                    >
                                                      {t(
                                                        "MODAL_CREATEROWDATA_TITLE_EXECUTE_CUSTOM_TRANS"
                                                      )}
                                                    </ControlLabel>
                                                    <Whisper
                                                      trigger="none"
                                                      ref={
                                                        this.triggerRefColums
                                                      }
                                                      placement={"bottomStart"}
                                                      speaker={
                                                        <Popover
                                                          visible
                                                          className={
                                                            "custom-white-tooltip"
                                                          }
                                                        >
                                                          <p>
                                                            {t(
                                                              "ERROR_DM_GLOBAL_REQUIRED"
                                                            )}{" "}
                                                          </p>
                                                        </Popover>
                                                      }
                                                    >
                                                      <SelectPicker
                                                        defaultValue={
                                                          formValue.hasOwnProperty(
                                                            camelize(
                                                              "RelationModel"
                                                            )
                                                          ) &&
                                                          formValue[
                                                            camelize(
                                                              "RelationModel"
                                                            )
                                                          ].hasOwnProperty(
                                                            camelize("RelModel")
                                                          ) &&
                                                          allTransactions &&
                                                          formValue[
                                                            camelize(
                                                              "RelationModel"
                                                            )
                                                          ][
                                                            camelize(
                                                              "ExecuteCustomTransaction"
                                                            )
                                                          ]
                                                            ? formValue[
                                                                camelize(
                                                                  "RelationModel"
                                                                )
                                                              ][
                                                                camelize(
                                                                  "ExecuteCustomTransaction"
                                                                )
                                                              ]
                                                            : allTransactions
                                                                .find(
                                                                  (sModel) =>
                                                                    sModel.model
                                                                      .id ===
                                                                    formValue[
                                                                      camelize(
                                                                        "RelationModel"
                                                                      )
                                                                    ][
                                                                      camelize(
                                                                        "RelModel"
                                                                      )
                                                                    ].id
                                                                )
                                                                .transActions.find(
                                                                  (x) =>
                                                                    x.functionName ==
                                                                    "read"
                                                                ).id
                                                        }
                                                        data={
                                                          allTransactions &&
                                                          allTransactions
                                                            .find(
                                                              (sModel) =>
                                                                sModel.model
                                                                  .id ===
                                                                formValue[
                                                                  camelize(
                                                                    "RelationModel"
                                                                  )
                                                                ][
                                                                  camelize(
                                                                    "RelModel"
                                                                  )
                                                                ].id
                                                            )
                                                            .transActions.map(
                                                              (x, key) => {
                                                                return {
                                                                  label: x.name,
                                                                  value: x.id, //key,
                                                                  data: x,
                                                                };
                                                              }
                                                            )
                                                        }
                                                        onChange={(value) => {
                                                          this.triggerRefColums.current.close();
                                                          let _obj =
                                                            Object.assign(
                                                              {},
                                                              this.state
                                                                .formValue
                                                            ); //
                                                          let _RelationModel = {
                                                            //"RelModel": {
                                                            //    "id": value.id,
                                                            //    "ModelName": value.modelName,
                                                            //    "RelModuleId": value.relModuleId
                                                            //},
                                                            //"RelationType": 0,
                                                            [camelize(
                                                              "ExecuteCustomTransaction"
                                                            )]: value,
                                                          };

                                                          debugger;
                                                          this.setState({
                                                            formValue: {
                                                              ..._obj,
                                                              [camelize(
                                                                "RelationModel"
                                                              )]: {
                                                                ..._obj[
                                                                  camelize(
                                                                    "RelationModel"
                                                                  )
                                                                ],
                                                                ..._RelationModel,
                                                              },
                                                            },
                                                          });
                                                        }}
                                                        disabled={
                                                          item.privateField ||
                                                          loading
                                                        }
                                                        className={
                                                          "custom-fade-in"
                                                        }
                                                        cleanable={false}
                                                        block
                                                        placement="autoVertical"
                                                      />
                                                    </Whisper>
                                                  </>
                                                )}
                                            </>

                                            <>
                                              <ControlLabel
                                                style={{ marginTop: "24px" }}
                                                className={"cell-header"}
                                              >
                                                {t(
                                                  "MODAL_CREATEROWDATA_TITLE_ISAUTOPOPULATE"
                                                )}
                                              </ControlLabel>
                                              <FormControl
                                                accepter={Toggle}
                                                disabled={
                                                  item.privateField || loading
                                                }
                                                name={camelize(
                                                  "IsAutopopulate"
                                                )}
                                                className={"custom-fade-in"}
                                              />
                                            </>
                                          </>
                                        ) : formValue[
                                            isFromMVC
                                              ? "contentType"
                                              : "contentType".toLowerCase()
                                          ] == "10" ? ( // RefModel sub settings
                                          <>
                                            <ControlLabel
                                              style={{ marginTop: "24px" }}
                                              className={"cell-header"}
                                            >
                                              {t(
                                                "MODAL_CREATEROWDATA_TITLE_MODEL"
                                              )}
                                            </ControlLabel>
                                            <Whisper
                                              key={"sp-refmodel-selector"}
                                              trigger="none"
                                              ref={this.triggerRefRelationModel}
                                              placement={"bottomStart"}
                                              speaker={
                                                <Popover
                                                  visible
                                                  className={
                                                    "custom-white-tooltip"
                                                  }
                                                >
                                                  <p>
                                                    {t(
                                                      "ERROR_DM_GLOBAL_REQUIRED"
                                                    )}{" "}
                                                  </p>
                                                </Popover>
                                              }
                                            >
                                              <SelectPicker
                                                defaultValue={
                                                  formValue.hasOwnProperty(
                                                    camelize("RefModel")
                                                  )
                                                    ? model.filter(
                                                        (sModel) =>
                                                          sModel.id ===
                                                          formValue[
                                                            camelize("RefModel")
                                                          ].id
                                                      )[0]
                                                    : undefined
                                                }
                                                data={
                                                  model &&
                                                  model
                                                    .filter((x) => {
                                                      return (
                                                        x.id !==
                                                        selectedService.relBaseModelId
                                                      );
                                                    })
                                                    .map((x) => {
                                                      return {
                                                        label: x.moduleWModel,
                                                        value: x, //x.id,
                                                        data: x,
                                                      };
                                                    })
                                                }
                                                onChange={(value) => {
                                                  this.triggerRefRelationModel.current.close();

                                                  let _obj = Object.assign(
                                                    {},
                                                    this.state.formValue
                                                  ); //
                                                  let _RefModel = {
                                                    ...value,
                                                    moduleWModel: undefined,
                                                    //"RelationType": 0,
                                                    //"ListColumn": "CompanyName"
                                                  };
                                                  this.setState({
                                                    formValue: {
                                                      ..._obj,
                                                      [camelize("RefModel")]:
                                                        _RefModel,
                                                    },
                                                  });
                                                }}
                                                disabled={
                                                  item.privateField || loading
                                                }
                                                className={"custom-fade-in"}
                                                cleanable={false}
                                                block
                                                name={camelize("RefModel")}
                                                placement="autoVertical"
                                              />
                                            </Whisper>

                                            {formValue[
                                              camelize("RefModel")
                                            ] && (
                                              <>
                                                <ControlLabel
                                                  style={{
                                                    marginTop: "24px",
                                                  }}
                                                  className={"cell-header"}
                                                >
                                                  {t(
                                                    "MODAL_CREATEROWDATA_TITLE_COLUMN"
                                                  )}
                                                </ControlLabel>
                                                <Whisper
                                                  trigger="none"
                                                  ref={this.triggerRefColums}
                                                  placement={"bottomStart"}
                                                  speaker={
                                                    <Popover
                                                      visible
                                                      className={
                                                        "custom-white-tooltip"
                                                      }
                                                    >
                                                      <p>
                                                        {t(
                                                          "ERROR_DM_GLOBAL_REQUIRED"
                                                        )}{" "}
                                                      </p>
                                                    </Popover>
                                                  }
                                                >
                                                  <SelectPicker
                                                    defaultValue={
                                                      formValue.hasOwnProperty(
                                                        camelize("refModel")
                                                      ) &&
                                                      model
                                                        .filter(
                                                          (sModel) =>
                                                            sModel.id ===
                                                            formValue[
                                                              camelize(
                                                                "refModel"
                                                              )
                                                            ].id
                                                        )[0]
                                                        ?.dataTypes.find(
                                                          (x) =>
                                                            x.name ==
                                                            this.state
                                                              .formValue[
                                                              "refModel"
                                                            ].listColumn
                                                        )
                                                    }
                                                    data={
                                                      model &&
                                                      model
                                                        .filter(
                                                          (sModel) =>
                                                            sModel.id ===
                                                            formValue[
                                                              camelize(
                                                                "refModel"
                                                              )
                                                            ].id
                                                        )[0]
                                                        ?.dataTypes.map(
                                                          (x, key) => {
                                                            return {
                                                              label: x.name,
                                                              value: x, //key,
                                                              data: x,
                                                            };
                                                          }
                                                        )
                                                    }
                                                    onChange={(value) => {
                                                      this.triggerRefColums.current.close();
                                                      let _obj = Object.assign(
                                                        {},
                                                        this.state.formValue
                                                      ); //
                                                      let _RelationModel = {
                                                        //"RelModel": {
                                                        //    "id": value.id,
                                                        //    "ModelName": value.modelName,
                                                        //    "RelModuleId": value.relModuleId
                                                        //},
                                                        //"RelationType": 0,
                                                        [camelize(
                                                          "ListColumn"
                                                        )]: value.name,
                                                      };
                                                      this.setState({
                                                        formValue: {
                                                          ..._obj,
                                                          [camelize(
                                                            "refModel"
                                                          )]: {
                                                            ..._obj[
                                                              camelize(
                                                                "refModel"
                                                              )
                                                            ],
                                                            ..._RelationModel,
                                                          },
                                                        },
                                                      });
                                                    }}
                                                    disabled={
                                                      item.privateField ||
                                                      loading
                                                    }
                                                    className={"custom-fade-in"}
                                                    cleanable={false}
                                                    block
                                                    placement="autoVertical"
                                                  />
                                                </Whisper>

                                                <ControlLabel
                                                  style={{
                                                    marginTop: "24px",
                                                  }}
                                                  className={"cell-header"}
                                                >
                                                  {t(
                                                    "MODAL_CREATEROWDATA_TITLE_VISIBLE_FIELDS"
                                                  )}
                                                </ControlLabel>
                                                <Whisper
                                                  trigger="none"
                                                  ref={this.triggerRefColums}
                                                  placement={"bottomStart"}
                                                  speaker={
                                                    <Popover
                                                      visible
                                                      className={
                                                        "custom-white-tooltip"
                                                      }
                                                    >
                                                      <p>
                                                        {t(
                                                          "ERROR_DM_GLOBAL_REQUIRED"
                                                        )}{" "}
                                                      </p>
                                                    </Popover>
                                                  }
                                                >
                                                  <CheckPicker
                                                    defaultValue={
                                                      formValue.hasOwnProperty(
                                                        camelize("refModel")
                                                      ) &&
                                                      formValue[
                                                        "refModel"
                                                      ].hasOwnProperty(
                                                        "visibleFields"
                                                      ) &&
                                                      formValue.refModel
                                                        .visibleFields
                                                        ? [
                                                            ...JSON.parse(
                                                              formValue.refModel
                                                                .visibleFields
                                                            ),
                                                          ]
                                                        : model
                                                            .filter(
                                                              (sModel) =>
                                                                sModel.id ===
                                                                formValue[
                                                                  camelize(
                                                                    "refModel"
                                                                  )
                                                                ].id
                                                            )[0]
                                                            ?.dataTypes.map(
                                                              (dt, k) => dt.name
                                                            )
                                                    }
                                                    data={
                                                      model &&
                                                      model
                                                        .filter(
                                                          (sModel) =>
                                                            sModel.id ===
                                                            formValue[
                                                              camelize(
                                                                "refModel"
                                                              )
                                                            ].id
                                                        )[0]
                                                        ?.dataTypes.map(
                                                          (x, key) => {
                                                            return {
                                                              label:
                                                                x.displayName,
                                                              value: x.name, //key,
                                                              data: x,
                                                            };
                                                          }
                                                        )
                                                    }
                                                    onChange={(value) => {
                                                      this.triggerRefColums.current.close();
                                                      debugger;
                                                      let _obj = Object.assign(
                                                        {},
                                                        this.state.formValue
                                                      ); //
                                                      let _RelationModel = {
                                                        //"RelModel": {
                                                        //    "id": value.id,
                                                        //    "ModelName": value.modelName,
                                                        //    "RelModuleId": value.relModuleId
                                                        //},
                                                        //"RelationType": 0,
                                                        VisibleFields:
                                                          JSON.stringify(value),
                                                      };
                                                      this.setState({
                                                        formValue: {
                                                          ..._obj,
                                                          [camelize(
                                                            "refModel"
                                                          )]: {
                                                            ..._obj[
                                                              camelize(
                                                                "refModel"
                                                              )
                                                            ],
                                                            ..._RelationModel,
                                                          },
                                                        },
                                                      });
                                                    }}
                                                    disabled={
                                                      item.privateField ||
                                                      loading
                                                    }
                                                    className={"custom-fade-in"}
                                                    cleanable={false}
                                                    block
                                                    placement="autoVertical"
                                                  />
                                                </Whisper>

                                                <ControlLabel
                                                  style={{ marginTop: "24px" }}
                                                  className={"cell-header"}
                                                >
                                                  {t(
                                                    "MODAL_CREATEROWDATA_TITLE_RELATIONTYPE"
                                                  )}
                                                </ControlLabel>
                                                <Whisper
                                                  key={"sp-refmodel-relType"}
                                                  trigger="none"
                                                  ref={this.triggerRelationType}
                                                  placement={"bottomStart"}
                                                  speaker={
                                                    <Popover
                                                      visible
                                                      className={
                                                        "custom-white-tooltip"
                                                      }
                                                    >
                                                      <p>
                                                        {t(
                                                          "ERROR_DM_GLOBAL_REQUIRED"
                                                        )}{" "}
                                                      </p>
                                                    </Popover>
                                                  }
                                                >
                                                  <SelectPicker
                                                    defaultValue={
                                                      this.state.formValue[
                                                        "refModel"
                                                      ].hasOwnProperty(
                                                        "relationType"
                                                      )
                                                        ? this.state.formValue[
                                                            "refModel"
                                                          ]["relationType"]
                                                        : undefined
                                                    }
                                                    data={[
                                                      {
                                                        label: i18n.t(
                                                          "ResponseModelTypeSingle"
                                                        ),
                                                        value: 0,
                                                      },
                                                      {
                                                        label: i18n.t(
                                                          "ResponseModelTypeList"
                                                        ),
                                                        value: 1,
                                                      },
                                                    ]}
                                                    onChange={(value) => {
                                                      this.triggerRelationType.current.close();
                                                      let _obj = Object.assign(
                                                        {},
                                                        this.state.formValue
                                                      ); //
                                                      let _RefModel = {
                                                        //"RelModel": {
                                                        //    "id": value.id,
                                                        //    "ModelName": value.modelName,
                                                        //    "RelModuleId": value.relModuleId
                                                        //},
                                                        [camelize(
                                                          "RelationType"
                                                        )]: value,
                                                        //"ListColumn": value.name
                                                      };
                                                      this.setState({
                                                        formValue: {
                                                          ..._obj,
                                                          [camelize(
                                                            "RefModel"
                                                          )]: {
                                                            ..._obj[
                                                              camelize(
                                                                "RefModel"
                                                              )
                                                            ],
                                                            ..._RefModel,
                                                          },
                                                        },
                                                      });
                                                    }}
                                                    disabled={
                                                      item.privateField ||
                                                      loading
                                                    }
                                                    className={"custom-fade-in"}
                                                    cleanable={false}
                                                    block
                                                    placement="autoVertical"
                                                  />
                                                </Whisper>

                                                {
                                                   this.state.formValue[
                                                    "refModel"
                                                  ].hasOwnProperty(
                                                    "relationType"
                                                  )
                                                      && this.state.formValue[
                                                        "refModel"
                                                      ]["relationType"] &&
                                                        <>
                                                        <ControlLabel
                                                          style={{ marginTop: "24px" }}
                                                          className={"cell-header"}
                                                        >
                                                          {t(
                                                            "MODAL_CREATEROWDATA_TITLE_IS_MULTIPLE_SELECT_LIST"
                                                          )}
                                                        </ControlLabel>
                                                        <FormControl
                                                          accepter={Toggle}
                                                          disabled={
                                                            item.privateField || loading
                                                          }
                                                          name={camelize(
                                                            "IsMultipleSelectList"
                                                          )}
                                                          defaultChecked={formValue[camelize(
                                                            "IsMultipleSelectList"
                                                          )]}
                                                          className={"custom-fade-in"}
                                                        />
                                                      </>
                                                  }


                                                <>
                                                  <ControlLabel
                                                    style={{
                                                      marginTop: "24px",
                                                    }}
                                                    className={"cell-header"}
                                                  >
                                                    {t(
                                                      "MODAL_CREATEROWDATA_TITLE_SEND_CURRENT_MODEL"
                                                    )}
                                                  </ControlLabel>
                                                  <FormControl
                                                    accepter={Toggle}
                                                    disabled={
                                                      item.privateField ||
                                                      loading
                                                    }
                                                    name={camelize(
                                                      "SendCurrentModel"
                                                    )}
                                                    className={"custom-fade-in"}
                                                  />
                                                </>

                                                <>
                                                  {formValue[
                                                    camelize("SendCurrentModel")
                                                  ] && (
                                                    <>
                                                      <ControlLabel
                                                        style={{
                                                          marginTop: "24px",
                                                        }}
                                                        className={
                                                          "cell-header"
                                                        }
                                                      >
                                                        {t(
                                                          "MODAL_CREATEROWDATA_TITLE_EXECUTE_CUSTOM_TRANS"
                                                        )}
                                                      </ControlLabel>
                                                      <Whisper
                                                        trigger="none"
                                                        ref={
                                                          this.triggerRefColums
                                                        }
                                                        placement={
                                                          "bottomStart"
                                                        }
                                                        speaker={
                                                          <Popover
                                                            visible
                                                            className={
                                                              "custom-white-tooltip"
                                                            }
                                                          >
                                                            <p>
                                                              {t(
                                                                "ERROR_DM_GLOBAL_REQUIRED"
                                                              )}{" "}
                                                            </p>
                                                          </Popover>
                                                        }
                                                      >
                                                        <SelectPicker
                                                          defaultValue={
                                                            formValue.hasOwnProperty(
                                                              camelize(
                                                                "refModel"
                                                              )
                                                            ) &&
                                                            allTransactions &&
                                                            formValue[
                                                              camelize(
                                                                "refModel"
                                                              )
                                                            ][
                                                              camelize(
                                                                "ExecuteCustomTransaction"
                                                              )
                                                            ]
                                                              ? formValue[
                                                                  camelize(
                                                                    "refModel"
                                                                  )
                                                                ][
                                                                  camelize(
                                                                    "ExecuteCustomTransaction"
                                                                  )
                                                                ]
                                                              : allTransactions
                                                                  .find(
                                                                    (sModel) =>
                                                                      sModel
                                                                        .model
                                                                        .id ===
                                                                      formValue[
                                                                        camelize(
                                                                          "refModel"
                                                                        )
                                                                      ].id
                                                                  )
                                                                  .transActions.find(
                                                                    (x) =>
                                                                      x.functionName ==
                                                                      "read"
                                                                  )?.id
                                                          }
                                                          data={
                                                            allTransactions &&
                                                            allTransactions
                                                              .find(
                                                                (sModel) =>
                                                                  sModel.model
                                                                    .id ===
                                                                  formValue[
                                                                    camelize(
                                                                      "refModel"
                                                                    )
                                                                  ].id
                                                              )
                                                              .transActions.map(
                                                                (x, key) => {
                                                                  return {
                                                                    label:
                                                                      x.name,
                                                                    value: x.id, //key,
                                                                    data: x,
                                                                  };
                                                                }
                                                              )
                                                          }
                                                          onChange={(value) => {
                                                            this.triggerRefColums.current.close();
                                                            let _obj =
                                                              Object.assign(
                                                                {},
                                                                this.state
                                                                  .formValue
                                                              ); //
                                                            let _RelationModel =
                                                              {
                                                                //"RelModel": {
                                                                //    "id": value.id,
                                                                //    "ModelName": value.modelName,
                                                                //    "RelModuleId": value.relModuleId
                                                                //},
                                                                //"RelationType": 0,
                                                                [camelize(
                                                                  "ExecuteCustomTransaction"
                                                                )]: value,
                                                              };

                                                            debugger;
                                                            this.setState({
                                                              formValue: {
                                                                ..._obj,
                                                                [camelize(
                                                                  "refModel"
                                                                )]: {
                                                                  ..._obj[
                                                                    camelize(
                                                                      "refModel"
                                                                    )
                                                                  ],
                                                                  ..._RelationModel,
                                                                },
                                                              },
                                                            });
                                                          }}
                                                          disabled={
                                                            item.privateField ||
                                                            loading
                                                          }
                                                          className={
                                                            "custom-fade-in"
                                                          }
                                                          cleanable={false}
                                                          block
                                                          placement="autoVertical"
                                                        />
                                                      </Whisper>
                                                    </>
                                                  )}
                                                </>
                                              </>
                                            )}
                                          </>
                                        ) : (
                                          ""
                                        )
                                      }
                                    </>
                                  ) : item.contentType == "lang" ? (
                                    <FormControl
                                      name={
                                        isFromMVC
                                          ? item.name
                                          : item.name.toLowerCase()
                                      }
                                      accepter={SelectPicker}
                                      cleanable={false}
                                      data={isoLangs}
                                      className={"picker-full custom-fade-in"}
                                      disabled={item.privateField || loading}
                                    />
                                  ) : item.contentType == 2 ? (
                                    <FormControl
                                      rows={5}
                                      disabled={item.privateField || loading}
                                      name={
                                        isFromMVC
                                          ? item.name
                                          : item.name.toLowerCase()
                                      }
                                      className={"custom-fade-in"}
                                      componentClass="textarea"
                                    />
                                  ) : item.contentType == 3 ? (
                                    //Number NOT: INT TIPLERI GEÇİCİ OLARAK KALDIRILDI
                                    <FormControl
                                      accepter={InputNumber}
                                      defaultValue={0}
                                      step={1}
                                      /*  defaultValue={
                        item.typeDetail === 6 || item.typeDetail === 7
                          ? 0.01
                          : 0
                      }
                      step={
                        item.typeDetail === 6 || item.typeDetail === 7
                          ? 0.01
                          : 1
                      }*/
                                      disabled={item.privateField || loading}
                                      name={
                                        isFromMVC
                                          ? item.name
                                          : item.name.toLowerCase()
                                      }
                                      className={"custom-fade-in"}
                                    />
                                  ) : item.contentType == 4 ? (
                                    //DATE
                                    <FormControl
                                      block
                                      placement="autoVertical"
                                      format={"DD/MM/YYYY"}
                                      locale={Locale}
                                      accepter={DatePicker}
                                      disabled={item.privateField || loading}
                                      name={
                                        isFromMVC
                                          ? item.name
                                          : item.name.toLowerCase()
                                      }
                                      className={"custom-fade-in"}
                                    />
                                  ) : item.contentType == 6 ? (
                                    <FormControl
                                      name={
                                        isFromMVC
                                          ? item.name
                                          : item.name.toLowerCase()
                                      }
                                      accepter={SelectPicker}
                                      cleanable={false}
                                      data={item.defaultData.map((x, key) => ({
                                        label: x,
                                        value: x,
                                      }))}
                                      className={"picker-full custom-fade-in"}
                                      disabled={item.privateField || loading}
                                    />
                                  ) : item.contentType == 8 ? (
                                    <p>
                                      {
                                        formValue[
                                          isFromMVC
                                            ? item.name
                                            : item.name.toLowerCase()
                                        ]
                                      }
                                    </p>
                                  ) : item.contentType == 9 ? (
                                    //IMAGE
                                    !item.privateField && (
                                      <div className={"dynamic-dt-side"}>
                                        <AvatarCard
                                          renderedImages={
                                            formValue[
                                              isFromMVC
                                                ? item.name
                                                : item.name.toLowerCase()
                                            ]
                                          }
                                          isEditable={true}
                                          isSquare={false}
                                          handleUpdate={this.handleUpdatePP}
                                          cmsKey={
                                            isFromMVC
                                              ? item.name
                                              : item.name.toLowerCase()
                                          }
                                        />
                                        <div className={"dynamic-dt-popover"}>
                                          {this.state.formError.hasOwnProperty(
                                            isFromMVC
                                              ? item.name
                                              : item.name.toLowerCase()
                                          ) && (
                                            <Popover
                                              visible
                                              className={"custom-white-tooltip"}
                                            >
                                              <p>
                                                {t("ERROR_DM_GLOBAL_REQUIRED")}{" "}
                                              </p>
                                            </Popover>
                                          )}
                                        </div>
                                      </div>
                                    )
                                  ) : (
                                    item.contentType + " contenttype not found"
                                  )}
                                </FormGroup>
                              )
                            )}
                        </Form>
                      </Col>
                    </Row>
                  </Container>
                </div>
                <div>
                  <Container>
                    <Row>
                      {(selectedInputs.length > 0 ||
                        selectedEvents.length > 0) && (
                        <div style={{ padding: "10px", width: "100%" }}>
                          <AdvancedBox
                            loading={this.state.isAdvLoading}
                            key={selectedService.id + "advancebox"}
                            formValue={this.state.formValue}
                            inputs={selectedInputs}
                            events={selectedEvents}
                            advancedValues={formValue["advanced"]}
                            eventsValues={formValue["events"]}
                            onAdvancedUpdate={(_data) => {
                              let _frmValues = JSON.parse(
                                JSON.stringify(formValue)
                              );
                              _frmValues["advanced"] = _data;
                              this.setState({ formValue: _frmValues });
                            }}
                            onEventsUpdate={(_data) => {
                              let _frmValues = JSON.parse(
                                JSON.stringify(formValue)
                              );
                              _frmValues["events"] = _data;
                              this.setState({ formValue: _frmValues });
                            }}
                          />
                        </div>
                      )}
                    </Row>
                  </Container>
                </div>
              </Slider>
            </div>
            <div className="modal-footer">
              <FormGroup>
                <ButtonToolbar className={"creator-toobar"}>
                  <Button
                    disabled={loading}
                    loading={loading}
                    appearance="primary"
                    onClick={this.handleSubmit}
                  >
                    {t("BUTTON_UPDATE")}
                  </Button>
                  <Button
                    disabled={loading}
                    appearance="default"
                    onClick={this.handleCloseModal}
                  >
                    {t("BUTTON_CANCEL")}
                  </Button>
                </ButtonToolbar>
              </FormGroup>
            </div>
          </Modal>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state) => {
  const { workspaceReducer } = state;
  const { models, model, selectedService, currentLang, allTransactions } =
    workspaceReducer;
  return {
    models,
    model,
    selectedService,
    currentLang,
    allTransactions,
  };
};

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(EditRowData);

function camelize(str) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
}
