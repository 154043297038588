import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import i18n from "i18n";
import { Toggle, Icon, Loading, IconButton, SelectPicker } from "rsuite";
import {
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  InputNumber,
  DatePicker,
  Panel,
} from "rsuite";

import AdvancedEventField from "./AdvancedEventField";
import { NumericFormat } from "react-number-format";

class AdvancedBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      advanceFormValue: {},
      advanceFormError: {},
      cInputs: {},
      cEvents: {},
      loading: false,
      isFirst: true,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.loading !== this.props.loading && !this.state.loading) {
      this.setDefaults();
    }
  }

  componentDidMount = () => {
    if (this.state.isFirst) {
      this.setDefaults();
    }
  };

  setDefaults = () => {
    const {
      allTransactions,
      allServices,
      inputs,
      advancedValues,
      onAdvancedUpdate,
      events,
      eventsValues,
      onEventsUpdate,
    } = this.props;
    this.setState({
      isFirst: false,
    });

    let _inputs = {};
    if (advancedValues) {
      advancedValues.forEach((x) => {
        try {
          let _v = x.defaultValue ? x.defaultValue : null;
          switch (x.currentContentType) {
            case 3:
              _v = parseFloat(x.defaultValue);
              break;

            case 7:
              _v = x.defaultValue.toLowerCase() == "true";
              break;

            default:
              break;
          }
          _inputs[x.name] = _v;
        } catch (error) {}
      });
    }

    let _events = {};
    if (eventsValues) {
      eventsValues.forEach((x) => {
        if (x.defaultValue) {
          _events[x.name + x.eventName] = JSON.parse(x.defaultValue);
        }
      });
    }

    this.setState({
      cInputs: _inputs,
      advanceFormValue: _inputs,
      cEvents: _events,
    });
  };

  getDefaultValue = (x) => {
    const { advanceFormValue } = this.state;
    const that = this;
    try {
      let _v = x.defaultValue ? x.defaultValue : null;
      switch (x.currentContentType) {
        case 3:
          _v = parseFloat(x.defaultValue);
          break;

        case 7:
          _v = x.defaultValue.toLowerCase() == "true";
          break;

        default:
          break;
      }
      return _v;
    } catch (error) {
      debugger;
      return x.defaultValue;
    }
  };

  handleUpdateInputs = (values) => {
    const {
      allTransactions,
      allServices,
      inputs,
      advancedValues,
      onAdvancedUpdate,
    } = this.props;

    let _list = [];
    inputs.forEach((element) => {
      if (values.hasOwnProperty(element.name)) {
        let _cData = element;
        _cData["defaultValue"] = values[element.name];
        _list.push(_cData);
      }
    });
    debugger;
    onAdvancedUpdate(_list);
  };

  handleUpdateEvents = (key, values, isDelete) => {
    const {
      allTransactions,
      allServices,
      events,
      eventsValues,
      onEventsUpdate,
    } = this.props;

    let _list = [...eventsValues];

    for (let index = 0; index < events.length; index++) {
      const element = events[index];

      if (element.name + element.eventName == key) {
        let _cData = element;
        if (isDelete) {
          const _cDataIndex = _list.findIndex((x) => x.id == element.id);
          if (_cDataIndex > -1) {
            _list = _list.splice(1, _cDataIndex);
          }
        } else {
          _cData["defaultValue"] = JSON.stringify(values);
          let _finded = _list.findIndex((x) => x.id == element.id);

          if (_finded > -1) {
            _list[_finded] = _cData;
          } else {
            _list.push(_cData);
          }
        }
      }
    }

    onEventsUpdate(_list);
  };

  render() {
    const { advanceFormValue } = this.state;
    const {
      allTransactions,
      allServices,
      inputs,
      advancedValues,
      onAdvancedUpdate,
      events,
      eventsValues,
      onEventsUpdate,
    } = this.props;

    const Locale = {
      sunday: i18n.t("sunday"),
      monday: i18n.t("monday"),
      tuesday: i18n.t("tuesday"),
      wednesday: i18n.t("wednesday"),
      thursday: i18n.t("thursday"),
      friday: i18n.t("friday"),
      saturday: i18n.t("saturday"),
      ok: i18n.t("ok"),
      today: i18n.t("today"),
      yesterday: i18n.t("yesterday"),
      hours: i18n.t("hours"),
      minutes: i18n.t("minutes"),
      seconds: i18n.t("seconds"),
    };

    return (
      <Translation>
        {(t) => (
          <div className="advanced-box">
            {inputs.length > 0 && (
              <Panel header={t("TITLE_ADVANCED_INPUTS")}>
                <Form
                  ref={(ref) => (this.advanceForm = ref)}
                  onChange={(formValue) => {
                    this.setState({ advanceFormValue: formValue });
                    this.handleUpdateInputs(formValue);
                  }}
                  onCheck={(formError) => {
                    this.setState({ advanceFormError: formError });
                  }}
                  className={"dynamic-create-form custom-fade-in"}
                  formValue={advanceFormValue}
                  //model={_model}
                  fluid
                >
                  {inputs.map((item, key) => {
                    return (
                      <>
                        <FormGroup
                          key={key + "key" + advanceFormValue[item.name]}
                        >
                          <ControlLabel>{item.name}</ControlLabel>

                          {item.currentContentType == 1 && (
                            <FormControl
                              block
                              type={"text"}
                              name={item.name}
                              defaultValue={item.defaultValue}
                              //accepter={Toggle}
                              //inline
                            ></FormControl>
                          )}
                          {item.currentContentType == 2 && (
                            <FormControl
                              block
                              componentClass="textarea"
                              rows={5}
                              name={item.name}
                              defaultValue={item.defaultValue}
                              //accepter={Toggle}
                              //inline
                            ></FormControl>
                          )}
                          {item.currentContentType == 3 && (
                            <NumericFormat
                              // displayType={readOnly ? "text" : "input"}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              defaultValue={item.defaultValue}
                              thousandSeparator={"."}
                              decimalSeparator={","}
                              allowDecimal={true}
                              onValueChange={(values, sourceInfo) => {
                                let _objClone = Object.assign(
                                  {},
                                  advanceFormValue
                                );
                                _objClone[item.name] = values.value;
                                this.handleUpdateInputs(_objClone);
                              
                              }}
                              name={item.name}
                              className={"custom-fade-in rs-input"}
                            />
                          )}
                          {item.currentContentType == 4 && (
                            <FormControl
                              block
                              placement="autoVerticalStart"
                              format={"DD/MM/YYYY"}
                              locale={Locale}
                              accepter={DatePicker}
                              name={item.name}
                              defaultValue={item.defaultValue}

                              //inline
                            ></FormControl>
                          )}
                          {item.currentContentType == 7 && (
                            <FormControl
                              name={item.name}
                              accepter={Toggle}
                              inline
                              defaultChecked={
                                advanceFormValue.hasOwnProperty(item.name)
                                  ? advanceFormValue[item.name]
                                  : this.getDefaultValue(item)
                              }
                            ></FormControl>
                          )}
                          {item.currentContentType == 8 && (
                            <FormControl
                              name={item.name}
                              cleanable={false}
                              accepter={SelectPicker}
                              placement="autoVertical"
                              inline
                              style={{ width: "100%" }}
                              defaultValue={
                                advanceFormValue.hasOwnProperty(item.name)
                                  ? advanceFormValue[item.name]
                                  : this.getDefaultValue(item)
                              }
                              data={item.eventName.split(",").map((x) => {
                                return {
                                  label: x,
                                  value: x,
                                };
                              })}
                            ></FormControl>
                          )}
                        </FormGroup>
                      </>
                    );
                  })}
                </Form>
              </Panel>
            )}

            {events.length > 0 && (
              <Panel header={t("TITLE_ADVANCED_EVENTS")}>
                <div>
                  {events.map((item, key) => {
                    const _ceData =
                      eventsValues && eventsValues.find((x) => x.id == item.id);
                    let _eData = null;
                    if (_ceData) {
                      _eData = JSON.parse(_ceData.defaultValue);
                    }
                    return (
                      <div key={key} className={"form-element-advanced"}>
                        <div
                          className="form-label-base-advanced"
                          key={key + "-base-" + (_ceData ? "has" : "not")}
                        >
                          <span className="form-label-advanced">
                            {item.name + item.eventName}
                          </span>
                          {
                            //handleUpdateEvents
                            _ceData && (
                              <IconButton
                                appearance="ghost"
                                color="red"
                                icon={<Icon icon={"trash-o"} />}
                                size="xs"
                                onClick={() => {
                                  this.handleUpdateEvents(
                                    item.name + item.eventName,
                                    "",
                                    true
                                  );
                                }}
                              />
                            )
                          }
                        </div>

                        <AdvancedEventField
                          refData={item}
                          defData={eventsValues}
                          cData={_eData}
                          onUpdate={this.handleUpdateEvents}
                          onDelete={() => {
                            this.handleUpdateEvents(
                              item.name + item.eventName,
                              "",
                              true
                            );
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </Panel>
            )}
          </div>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer } = state;
  const {
    workspace,
    selectedService,
    dataModels,
    modules,
    module,
    models,
    allTransactions,
    allServices,
  } = workspaceReducer;

  return {
    workspace,
    selectedService,
    dataModels,
    modules,
    module,
    models,
    allTransactions,
    allServices,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedBox);
