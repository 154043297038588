import { useState } from "react";
import { QueryBuilderDnD } from "@react-querybuilder/dnd";
import * as ReactDnD from "react-dnd";
import * as ReactDndHtml5Backend from "react-dnd-html5-backend";
import { QueryBuilder, formatQuery, parseMongoDB ,parseJsonLogic} from "react-querybuilder";
import CustomValueEditor from "./CustomValueEditor";
import AddRuleAction from "./AddRuleAction";
import AddGroupAction from "./AddGroupAction";
import RemoveRuleAction from "./RemoveRuleAction";
import i18n from "i18n";
import FilterCodeEditor from "../FilterCodeEditor";
import { Button } from "rsuite";
import "./styles.scss";

export default function CustomQueryBuilder(props) {
  const [query, setQuery] = useState(
    props.query ? props.query : { combinator: "and", rules: [] }
  );
  const [queryDirect, setQueryDirect] = useState(
    props.query ? formatQuery(props.query,"mongodb") : ""
  );

  function handleParseCode(value) {
    const _result = parseMongoDB(value);
    const _base = parseJsonLogic(value)
    debugger;
    setQueryDirect(_result);
    setQuery(_result)
  }

  return (
    <>
      <div>
        <h5 style={{ marginBottom: "1em" }}>{i18n.t("TITLE_QUERY")}</h5>
        <QueryBuilderDnD dnd={{ ...ReactDnD, ...ReactDndHtml5Backend }}>
          <QueryBuilder
            controlClassnames={{ queryBuilder: "queryBuilder-branches" }}
            fields={props.fields}
            controlElements={{
              valueEditor: CustomValueEditor,
              addRuleAction: AddRuleAction,
              addGroupAction: AddGroupAction,
              removeRuleAction: RemoveRuleAction,
              removeGroupAction: RemoveRuleAction,
            }}
            query={query}
            onQueryChange={(q) => {
              setQuery(q);
              props.updateQuery(q);
            }}
          />
        </QueryBuilderDnD>
      </div>

      <div style={{ marginTop: "1em" }}>
        <h5>{i18n.t("TITLE_QUERY_RESULT")}</h5>
        <div className="query-codo-box">
          {<code>{formatQuery(query, "mongodb")}</code>}
          {
              /*
                 <Button
            onClick={() => {
              handleParseCode(queryDirect);
            }}
          >
            convert
          </Button>
          <FilterCodeEditor
            handleUpdate={setQueryDirect}
            handleSave={handleParseCode}
            value={queryDirect}
          />
              
              */
            }
       
        </div>
      </div>
    </>
  );
}
