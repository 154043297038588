import {
  //ServiceURLs,
  DashboardUrlsEndpoints,
  GetBaseERPURL,
  MakeReqestAndGetResponse,
  GetUserLang,
} from "./index";
import { HTTPOperationType } from "./httpOperations";

export const DashBoardURLSService = {
  CREATE_DASHSBOARD_URL,
  GET_DASHBOARD_URL,
  GET_PLATFORMS,
  CHECK_URL,
  RUN_UNDASHBOARDURL,
};

function GET_PLATFORMS() {
  return MakeReqestAndGetResponse(
    GetBaseERPURL() + DashboardUrlsEndpoints.GET_PLATFORMS,
    HTTPOperationType.get
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}
function CHECK_URL(id) {
  return MakeReqestAndGetResponse(
    GetBaseERPURL() + DashboardUrlsEndpoints.CHECK_URL + id,
    HTTPOperationType.get
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

function RUN_UNDASHBOARDURL(
  id,
  RelWorkspaceId,
  RelFrontEndPlatform,
  WORK_PLACE = 0
) {
  var js = {
    id: id,
    RelWorkspaceId: RelWorkspaceId,
    RelFrontEndPlatform: RelFrontEndPlatform,
    WORK_PLACE: WORK_PLACE,
  };

  return MakeReqestAndGetResponse(
    GetBaseERPURL() + DashboardUrlsEndpoints.RUN_UNDASHBOARDURL,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

function CREATE_DASHSBOARD_URL(
  RelWorkspaceId,
  URL,
  RelFrontEndPlatform,
  WORK_PLACE = 0
) {
  var js = {
    RelWorkspaceId: RelWorkspaceId,
    URL: URL,
    RelFrontEndPlatform: RelFrontEndPlatform,
    WORK_PLACE: WORK_PLACE,
  };

  return MakeReqestAndGetResponse(
    GetBaseERPURL() + DashboardUrlsEndpoints.CREATE_DASHSBOARD_URL,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

function GET_DASHBOARD_URL(WorkspaceId) {
  return MakeReqestAndGetResponse(
    GetBaseERPURL() + DashboardUrlsEndpoints.GET_DASHBOARD_URL + WorkspaceId,
    HTTPOperationType.get
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}
