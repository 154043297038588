import React, { Component } from 'react'
import { NavLink as NavLinkRRD, Link } from "react-router-dom";

import classnames from "classnames";
import { connect } from 'react-redux'
import PerfectScrollbar from "react-perfect-scrollbar";
//import { components } from '../../assets/test/components'
import i18n from "i18n";
import Emitter from "_actions/emitter";
import { ResponseStatusCode } from "_helpers";
import routes from "routes.js";

import {
  CustomComponentService
} from "_services";

import {
  //setMyComponentList,
} from "_actions";

import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav
} from "reactstrap";

import { updateWorkspace } from '_actions'

class Sidebar extends Component {

  constructor(props) {
    super(props)

    this.state = {
      collapseOpen: true,
      ...this.getCollapseStates(routes)
    }
  }

  activeRoute = routeName => {
     return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  // makes the sidenav normal on hover (actually when mouse enters on it)
  onMouseEnterSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.add("g-sidenav-show");
    }
  };
  // makes the sidenav mini on hover (actually when mouse leaves from it)
  onMouseLeaveSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-show");
    }
  };

  // toggles collapse between opened and closed (true/false)
  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen
    });
  };
  // closes the collapse
  closeCollapse = () => {
    this.setState({
      collapseOpen: false
    });
  };
  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = routes => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }
  // this is used on mobile devices, when a user navigates
  // the sidebar will autoclose
  closeSidenav = () => {
    if (window.innerWidth < 1200) {
      this.props.toggleSidenav();
    }
  };

  createLinks = routes => {
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.hasOwnProperty("hidden")) {
        if (prop.hidden) {
          return null;
        }
      }

      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !this.state[prop.state];
        return (
          <NavItem key={key}>
            <NavLink
              href="#thexapp"
              data-toggle="collapse"
              aria-expanded={this.state[prop.state]}
              className={classnames({
                active: this.getCollapseInitialState(prop.views)
              })}
              onClick={e => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              {prop.icon ? (
                <>
                  <i className={prop.icon} />
                  <span className="nav-link-text">{prop.name}</span>
                </>
              ) : prop.miniName ? (
                <>
                  <span className="sidenav-mini-icon"> {prop.miniName} </span>
                  <span className="sidenav-normal"> {prop.name} </span>
                </>
              ) : null}
            </NavLink>
            <Collapse isOpen={this.state[prop.state]}>
              <Nav className="nav-sm flex-column">
                {this.createLinks(prop.views)}
              </Nav>
            </Collapse>
          </NavItem>
        );
      }

      return (
        <NavItem
          className={this.activeRoute(prop.layout + prop.path)}
          key={key}
        >
          <NavLink
            to={prop.layout + prop.path}
            activeClassName=""
            onClick={()=>{
              this.props.updateWorkspace(null)
              this.closeSidenav();
            }}
            tag={NavLinkRRD}
          >
            {prop.icon !== undefined ? (
              <>
                <i className={prop.icon} />
                <span className="nav-link-text">{prop.name}</span>
              </>
            ) : prop.miniName !== undefined ? (
              <>
                <span className="sidenav-mini-icon"> {prop.miniName} </span>
                <span className="sidenav-normal"> {prop.name} </span>
              </>
            ) : (
                  prop.name
                )}
          </NavLink>
        </NavItem>
      );
    });
  };


  render() {
    const { sidenavOpen } = this.state;
    const _logo = require("../../assets/img/brand/xapplogo.svg");
   
    // const { routes, logo } = this.props;
    const scrollBarInner = (
      <div className="scrollbar-inner">
        <div className="sidenav-header d-flex align-items-center">
          <NavbarBrand>
            <img
              alt={"Logo - TheXApp"}
              className="navbar-brand-img"
              src={_logo.default}
            />
          </NavbarBrand>
          <div className="ml-auto" style={{ marginRight: "auto" }}>
            <div
              className={classnames("sidenav-toggler d-none d-xl-block", {
                active: !this.props.sidenavOpen
              })}
              onClick={this.props.toggleSidenav}
            >
              <div className="sidenav-toggler-inner">
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
              </div>
            </div>
          </div>
        </div>
        <div className="navbar-inner">
          <Collapse navbar isOpen={true}>
            <Nav navbar>{this.createLinks(routes)}</Nav>

          </Collapse>
        </div>
      </div>
    );
    return (
      <>
        <Navbar
          className={
            "sidenav navbar-vertical navbar-expand-xs navbar-light bg-white " +
            (this.props.rtlActive ? "" : "fixed-left")}
          onMouseEnter={this.onMouseEnterSidenav}
          onMouseLeave={this.onMouseLeaveSidenav}>
          {navigator.platform.indexOf("Win") > -1 ? (
            <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
          ) : (
              scrollBarInner
            )}
        </Navbar>
      </>
    )
  }
}

const mapStateToProps = (state, props) => {
  
  return {
   
  };
}
const mapDispatchToProps = {
  updateWorkspace 
}
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)


