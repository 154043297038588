import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import Emitter from "_actions/emitter";
import i18n from "i18n";
import { ResponseStatusCode } from "_helpers";
import { erpRolesService, erpRolesAndUserRelationsService } from "_services";
import { Button, Alert, Icon, IconButton,Loader } from "rsuite";
import SelectRoleList from "./SelectRoleList";
import { Input, Modal, Container } from "reactstrap";

class EditUserRole extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isRoleList: false,
      config: null,
    };
  }

  handleShowRoleList = () => {
    this.setState({ isRoleList: !this.state.isRoleList });
  };

  GetUserConfig = () => {
    const { workspace, onRefresh, data } = this.props;
    let _state = this.state.customStyles;
    const that = this;
    that.setState({ isLoading: true });
    erpRolesAndUserRelationsService
      .GET_USER_ROLES(workspace.id, 0, data.id)
      .then((data) => {
        this.setState({ config: data.dynamicValue, isLoading: false });
      })
      .catch((err) => {
        Alert.warning(ResponseStatusCode(err.statusCode));
        that.setState({ isLoading: false });
        debugger;
        //  let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
        //   this.warningAlert(data);
      });
  };

  handleSetUserRole = (item) => {
    const { workspace, onRefresh, data } = this.props;
    let _state = this.state.customStyles;
    const that = this;
    that.setState({ isLoading: true });
    erpRolesAndUserRelationsService
      .ASSIGN_ROLE_TO_USER(workspace.id, 0, data.id, item.id)
      .then((data) => {
        that.GetUserConfig();
        that.handleShowRoleList();
        Alert.success(i18n.t("MESSAGE_USER_ROLE_ADD_SUCCESS"));
      })
      .catch((err) => {
        Alert.warning(ResponseStatusCode(err.statusCode));
        that.setState({ isLoading: false });
        debugger;
        //  let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
        //   this.warningAlert(data);
      });
  };

  handleRemoveRole = (item) => {
    const { workspace, onRefresh, data } = this.props;
    let _state = this.state.customStyles;
    const that = this;
    that.setState({ isLoading: true });
    erpRolesAndUserRelationsService
      .REMOVE_ROLE_FROM_USER(workspace.id, 0, data.id, item.relErpRoleId)
      .then((data) => {
        that.GetUserConfig();
        Alert.success(i18n.t("MESSAGE_USER_ROLE_REMOVE_SUCCESS"));
      })
      .catch((err) => {
        Alert.warning(ResponseStatusCode(err.statusCode));
        that.setState({ isLoading: false });
        debugger;
        //  let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
        //   this.warningAlert(data);
      });
  };

  resetContent = () => {
    const { data, roles } = this.props;
    this.GetUserConfig();
    this.setState({
      isLoading: false,
      isRoleList: false,
      config: null,
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.isActive)
      if (prevProps.isActive != this.props.isActive && this.props.isActive) {
        this.resetContent();
      }
  }

  render() {
    const { isActive, handleToggle, data } = this.props;
    const { isLoading, config } = this.state;
    return (
      <Translation>
        {(t) => (
          <>
            <Modal
              className="modal-dialog-centered"
              isOpen={isActive}
              //size="lg"
              // scrollable={true}
            >
              <div className="modal-header">
                <h5 className="modal-title" id="CreateRoleModal">
                  {t("TITLE_MODAL_UPDATE_USER_ROLE")}
                </h5>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  disabled={this.state.isLoading}
                  onClick={() => handleToggle()}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div>
                <Container>
                  <div>
                    <div className={"users-card-header"}>
                      {data && (
                        <div className={"users-card"}>
                          <div style={{ fontWeight: "600" }}>
                            {data.firstName + " " + data.lastName}
                          </div>
                          <div>{data.username}</div>
                          <div>{data.email}</div>
                        </div>
                      )}
                    </div>
                    <div>
                      <div className={"user_roles-title"}>
                        {t("TITLE_USER_CURRENT_ROLES")}

                        <IconButton
                          onClick={() => {
                            this.handleShowRoleList();
                          }}
                          size="sm"
                          appearance="subtle"
                          icon={<Icon icon="plus" />}
                        />
                      </div>
                      {config ? (
                        config.roles.length > 0 ? (
                          config.roles.map((item, key) => (
                            <div className="user_roles-card" key={key}>
                              {item.roleDetail.roleName}
                              <IconButton
                                onClick={() => {
                                  this.handleRemoveRole(item);
                                }}
                                appearance="subtle"
                                color="red"
                                icon={<Icon icon="trash" />}
                                circle
                              />
                            </div>
                          ))
                        ) : (
                          <div className={"store-not-found"}>
                            <Icon icon={"exclamation-triangle"} />
                            {t("USER_ROLE_NO_RESULT")}
                          </div>
                        )
                      ) : (
                        <div className={"store-not-found"}>
                          <Icon icon={"exclamation-triangle"} />
                          {t("USER_ROLE_NO_RESULT")}
                        </div>
                      )}
                    </div>
                  </div>
                  
                </Container>
                {isLoading && (
                    <div className={"fade-in"}>
                      <Loader backdrop vertical />
                    </div>
                  )}
              </div>
              <div className="modal-footer">
                <Button
                  size={"sm"}
                  data-dismiss="modal"
                  disabled={this.state.isLoading}
                  type="button"
                  onClick={() => handleToggle()}
                >
                  {t("BUTTON_CANCEL")}
                </Button>
              </div>
              <SelectRoleList
                onRefresh={this.resetContent}
                isActive={this.state.isRoleList}
                handleAdd={this.handleSetUserRole}
                handleToggle={this.handleShowRoleList}
                data={config}
              />
              {this.state.alert}
            </Modal>
          </>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer } = state;
  const { workspace, workspaces, selectedService, storeCategories, modules } =
    workspaceReducer;

  return {
    workspace,
    workspaces,
    selectedService,
    storeCategories,
    modules,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(EditUserRole);
