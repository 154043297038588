import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import i18n from "i18n";

import CForm from "components/Custom/CForm";

class ViewLayout extends Component {
  render() {
    const { trans } = this.props;
    return (
      <Translation>
        {(t) => (
          <div className={"custom-fade-in"}>
            <CForm trans={trans}/>
          </div>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer } = state;
  const { workspace, selectedService, dataModels } = workspaceReducer;

  return {
    workspace,
    selectedService,
    dataModels,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ViewLayout);
