import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import Emitter from "_actions/emitter";

import i18n from "i18n";
import { Tree, Input, InputGroup, Icon, Button, Alert, Loader } from "rsuite";

import CodeEditor from "components/Custom/CodeEditor";
import { ModelsService, TransactionService } from "_services";

const WAIT_INTERVAL = 500;

class ControllerLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      script: "",
      data: null,
      loading: true,
    };
  }
  // Emitter.emit("CHANGE_CONTROLLER_PAGE",null)
  getTransDetail = () => {
    const that = this;
    const {
      module,
      workspace,
      selectedService,
      updatedModels,
      setDynamicCollections,
      dynamicDatabase,
    } = this.props;
    if (module && workspace && selectedService) {
      this.setState({ loading: true });
      TransactionService.GET_TRANSACTION_BYID(
        selectedService.id,
        workspace.id,
        module.id
      )
        .then((data) => {
          that.setState({
            script: data.dynamicValue.script,
            data: data.dynamicValue,
          });
          that.setState({ loading: false });
        })
        .catch((err) => {
          debugger;
          Alert.error("Error code : " + err.statusCode);
          this.setState({ loading: false });
        });
    }
  };

  componentDidMount() {
    this.getTransDetail();
    Emitter.on("CHANGE_CONTROLLER_PAGE", (newValue) => this.getTransDetail());
  }
  componentWillUnmount = () => {
    Emitter.off("CHANGE_CONTROLLER_PAGE");
  };

  render() {
    const { selectedService } = this.props;
    return (
      <Translation>
        {(t) => (
          <>
            {!this.state.loading ? (
              <CodeEditor
                readOnly={true}
                value={this.state.script}
                executableTransactionServiceName={
                  this.props.selectedService.executableTransactionServiceName
                }
                funcName={selectedService.label + "Service"}
                funcParams={["param1,param2"]}
                onChangeScript={(script) => {
                  // console.log(script)
                  this.setState({ script });
                }}
              />
            ) : (
              <div className={"fade-in"}>
                <Loader backdrop vertical />
              </div>
            )}
          </>
        )}
      </Translation>
    );
  }
}
const mapStateToProps = (state, props) => {
  const { workspaceReducer } = state;
  const {
    workspaces,
    workspace,
    selectedService,
    services,
    modules,
    module,
    transactions,
  } = workspaceReducer;

  return {
    workspaces,
    workspace,
    modules,
    module,
    selectedService,
    services,
    transactions,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ControllerLayout);
