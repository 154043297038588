import React, { Component } from 'react'
import { Translation } from "react-i18next";
import { connect } from 'react-redux';
import { ResponseStatusCode } from "_helpers";

import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { IconButton, ButtonGroup, ButtonToolbar, Icon, Alert,Loader } from 'rsuite';
import { StorageService } from "_services";
import { setImageUploadLoading } from "_actions";
import i18n from "i18n";

import {
    Button,
    Input,
    Row
} from "reactstrap";

class CropBox extends Component {
    constructor(props) {
        super(props)
        this.cropper = React.createRef(null);
        const { currentImage } = this.props;

        this.state = {
            src: currentImage,
            cropResult: null,
            customStyles: {
                imageDesc: "",
                imageDescState: null,
            }
        };
        this.cropImage = this.cropImage.bind(this);
        this.onChange = this.onChange.bind(this);
        this.useDefaultImage = this.useDefaultImage.bind(this);
    }

    customStylesForm = (e, stateName) => {
        let newState = this.state.customStyles;
        newState[stateName] = e.target.value;
        if (stateName === "checkbox") {
            if (e.target.value) {
                newState.checkboxState = "valid";
            } else {
                newState.checkboxState = "invalid";
            }
        } else {
            if (e.target.value === "") {
                newState[stateName + "State"] = "invalid";
            } else {
                newState[stateName + "State"] = "valid";
            }
        }
        this.setState({
            customStyles: newState
        });
    };

   

    uploadStream = (_file, _name = "Test") => {
        const { handleSuccess, setImageUploadLoading,uploadId } = this.props;
       let _uploadId = uploadId ? uploadId:"5ee80d9f985684060595a131";
        setImageUploadLoading(true);
        StorageService.uploadFileBase64(
            _file,
            _uploadId,//SettingID
            "CroppedImage",
            "-1"
        ).then(data => {
         
            if(data.operationResult){
                handleSuccess(data.dynamicValue.images);
            }else{
                Alert.error(ResponseStatusCode("not"));
                debugger
            }
            setImageUploadLoading(false);
            
        }).catch(err => {
            debugger
            setImageUploadLoading(false);
            Alert.error(ResponseStatusCode(err.statusCode));
        });
    }

    onChange(e) {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            this.setState({ src: reader.result });
        };
        reader.readAsDataURL(files[0]);
    }

    cropImage = () => {
        //   const { width, height } = this.props;

        if (typeof this.cropper.getCroppedCanvas() === 'undefined') {
            return;
        }
        // getCroppedImage(this.cropper.getCroppedCanvas({ width: width, height: height }).toDataURL());
        this.uploadStream(this.cropper.getCroppedCanvas().toDataURL(), this.state.customStyles.imageDesc)
    }

    useDefaultImage() {
        const { currentImage } = this.props;

        this.setState({ src: currentImage });
    }

    state = {
        status: false,
        value: "1",
        success: false,
        loading: false,
    }

    handleButtonClick = () => {
        if (!this.state.loading) {
            this.setState({
                success: false,
                loading: true,
            })
            setTimeout(() => {
                this.setState({
                    success: true,
                    loading: false,
                })
            }, 2000);
        }
    };

    scaleX = 1;
    scaleY = 1;
    handleScale = (value) => {
        this.cropper[value](this[value] * -1);
        this[value] = this[value] * -1;
    }
    handleRotate = (value) => {
        this.cropper.rotate(value);
    }

    handleZoom = (value) => {
        this.cropper.zoom(value);
    }
    handleReset = () => {
        this.cropper.reset();
    }
    render() {

        const { classes, currentImage, width, height, isSquare,isImageUploadLoading } = this.props;
        const hasMedia = true;
        const type = 'video';
      
        return (
            <Translation>
                {t => (
                    <div
                        className={this.props.className}
                        ref={this.props.ref}
                    >
                        <div className={"cropper-container"}>
                            <div>
                            <Cropper
                                style={{
                                    height: 400, width: '100%',
                                }}
                                viewMode={1}
                                dragMode={"move"}
                                preview=".img-preview"
                                aspectRatio={isSquare && 1 / 1}
                                guides={false}
                                src={currentImage}
                                ref={cropper => { this.cropper = cropper; }}
                            />
                            {
                                this.props.isImageUploadLoading &&
                                <div className={"modal-loading-container fade-in"}>
                                     <Loader backdrop vertical />
                                </div>
                            }
                            </div>

                            

                            <ButtonToolbar className={"cropper-tools-container"}>
                                <IconButton disabled={isImageUploadLoading} size="lg" onClick={() => { this.handleZoom(-0.1); }} appearance="ghost" className={"cropper-tools-button"} icon={<i className="fas fa-search-minus"></i>} />
                                <IconButton disabled={isImageUploadLoading} size="lg" onClick={() => { this.handleZoom(0.1); }} appearance="ghost" className={"cropper-tools-button"} icon={<i className="fas fa-search-plus"></i>} />

                                <IconButton disabled={isImageUploadLoading} size="lg" onClick={() => { this.handleRotate(-45); }} appearance="ghost" className={"cropper-tools-button"} icon={<i className="fas fa-undo"></i>} />
                                <IconButton disabled={isImageUploadLoading} size="lg" onClick={() => { this.handleRotate(45); }} appearance="ghost" className={"cropper-tools-button"} icon={<i className="fas fa-redo"></i>} />

                                <IconButton disabled={isImageUploadLoading} size="lg" onClick={() => { this.handleScale("scaleX"); }} appearance="ghost" className={"cropper-tools-button"} icon={<i className="fas fa-exchange-alt"></i>} />
                                <IconButton disabled={isImageUploadLoading} size="lg" onClick={() => { this.handleScale("scaleY"); }} appearance="ghost" className={"cropper-tools-button"} icon={<i className="fas fa-exchange-alt cropper-tool-icon-rotate90"></i>} />
                            </ButtonToolbar>


                        </div>
                      
                        <div className="modal-footer modal-footer-p0">
                            <Button
                                color="white"
                                size={"sm"}
                                data-dismiss="modal"
                                disabled={isImageUploadLoading}
                                type="button"
                                onClick={() => this.props.handleToggleBack()}
                            >
                                {t("BUTTON_CANCEL")}
                            </Button>

                            <Button disabled={isImageUploadLoading} color="primary" size={"sm"} type="button" onClick={() =>  this.cropImage()}>
                                {t("BUTTON_SAVE")}
                                
                            </Button>

                        </div>
                        
                        
                    </div>
                )}
            </Translation>
        )
    }
}


const mapStateToProps = (state, props) => {
     const { currentstatesReducer } = state;
     const { isImageUploadLoading } = currentstatesReducer;
 
     return {
         isImageUploadLoading,
     };
    
}
const mapDispatchToProps = {
    setImageUploadLoading,
}
export default connect(mapStateToProps, mapDispatchToProps)(CropBox)
