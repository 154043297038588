import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import moment from "moment";
import Emitter from "_actions/emitter";
import i18n from "i18n";
import { ResponseStatusCode } from "_helpers";
import { updateServiceRelationsModels } from "_actions";
import { ServiceRelationsService } from "_services";
import { Loader, Container,Icon, Alert, Button, Input } from "rsuite";
import { Modal } from "reactstrap";

import ScriptEditor from "components/GlobalScript/ScriptEditor";
import RelationList from "components/ServiceRelations/RelationList";
import SwipeableViews from "react-swipeable-views";

import GlobalScriptCreate from "./GlobalScriptCreate";

class ServiceRelationsSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      loading: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.isActive) {
      if (prevProps.isActive != this.props.isActive && this.props.isActive) {
        this.resetContent();
      }
    }
  }
  resetContent = () => {
    const that = this;
    this.setState(
      {
        search: "",
        loading: false,
      },
      () => {
        that.GET_ALL_MODELS();
      }
    );
  };

  handleSearch = (value) => {
    this.setState({ search: value });
  };

  GET_ALL_MODELS = () => {
    const that = this;
    const { updateServiceRelationsModels, workspace } = this.props;
    this.setState({ loading: true });

    ServiceRelationsService.GET_ALL_MODELS(workspace.id)
      .then(async (data) => {
        that.setState({ loading: false });

        if (data.operationResult) {
          let _data = data.dynamicValue;
          updateServiceRelationsModels(_data);
        }
      })
      .catch((err) => {
        that.setState({ loading: false });
        let data = {
          title: i18n.t("warning"),
          desc: ResponseStatusCode(err.statusCode),
        };
        Alert.error(data.desc);
      });
  };

  CREATE_NEW = (_data) => {
    const that = this;
    const {
      serviceRelations,
      updateServiceRelationsModels,
      workspace,
      transaction,
      onRefresh,
    } = this.props;
    this.setState({ loading: true });

    ServiceRelationsService.CREATE(
      workspace.id,
      transaction.relModuleId,
      transaction.relBaseModelId,
      _data.relModuleId,
      _data.id
    )
      .then(async (data) => {
        that.setState({ loading: false });

        if (data.operationResult) {
          onRefresh();
        }
      })
      .catch((err) => {
        that.setState({ loading: false });
        let data = {
          title: i18n.t("warning"),
          desc: ResponseStatusCode(err.statusCode),
        };
        Alert.error(data.desc);
      });
  };

  searchServices = (eleman) => {
   // debugger;
    const { serviceRelations } = this.props;
    return (
      (
        eleman.relModule.name.toLowerCase() +
        " " +
        eleman.modelName.toLowerCase()
      ).includes(this.state.search.toLowerCase())
    );
  };

  render() {
    const { isActive, handleToggle, serviceRelationsModels } = this.props;
    const { formValue, formError, loading } = this.state;
    const _myList = serviceRelationsModels.filter(this.searchServices);
    
    return (
      <Translation>
        {(t) => (
          <Modal
            className="modal-dialog-centered"
            isOpen={isActive}
            //size="lg"
            //toggle={handleToggle}
            scrollable={true}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div className="modal-header" style={{ paddingBottom: 0 }}>
                <h5 className="modal-title" id="CheckProjectModal">
                  {t("TITLE_SERVICE_RELATION_MODEL_SELECT")}
                </h5>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => {
                    handleToggle();
                  }}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>

              <div>
                <div style={{ padding: "10px" }}>
                  <Input
                  disabled={loading}
                    placeholder={t("PLACEHOLDER_SEARCH")}
                    onChange={(value) => {
                      this.handleSearch(value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="modal-body" style={{ padding: 0 }}>
              {_myList.length>0 ?
              _myList.map((item, key) => (
                  <Button
                    onClick={()=>{this.CREATE_NEW(item)}}
                    disabled={loading}
                    key={key}
                    appearance="default"
                    block
                  >
                    {"[" + item.relModule.name + "] - " + item.modelName}
                  </Button>
                )):
                <div
                    className={"global-view not-match-workspace custom-fade-in"}
                  >
                    <Icon icon="exclamation-triangle" size="3x" />
                    <span style={{ fontSize: "1.3rem" }}>
                      {t("ERROR_NOT_FOUND_RELATIONS_MODEL")}
                    </span>
                  </div>
            }
              {loading && (
                    <div className={"fade-in"}>
                      <Loader backdrop vertical />
                    </div>
                  )}
            </div>
          </Modal>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workspaceReducer, authentication } = state;
  const { loggedIn, LoginFailed, user } = authentication;

  const {
    workspace,
    workspaces,
    selectedService,
    store,
    storeCategories,
    transaction,
    serviceRelations,
    serviceRelationsModels,
  } = workspaceReducer;

  return {
    workspace,
    workspaces,
    selectedService,
    store,
    storeCategories,
    user,
    transaction,
    serviceRelations,
    serviceRelationsModels,
  };
};

const mapDispatchToProps = {
  updateServiceRelationsModels,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceRelationsSelect);
