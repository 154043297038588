import {
    //ServiceURLs,
    WorkspaceUserEndpoints,
    GetBaseERPURL,
    MakeReqestAndGetResponse,
    GetUserLang,
  } from "./index";
  import { HTTPOperationType } from "./httpOperations";
  
  export const WorkspaceUserService = {
    GET_USERS,
    CREATE_USER,
    RESET_PASSWORD_FROM_ADMIN_SIDE,
    SET_ACTIVE_STATUS
 
  };
  //BAN VS ICIN.
  function SET_ACTIVE_STATUS(_RelWorkSpaceId,_RelERP_WORK_PLACE,UserId,IsActive) {
    var js = {
      RelWorkSpaceId:_RelWorkSpaceId,
      RelERP_WORK_PLACE :_RelERP_WORK_PLACE,
      UserData:{
        id:UserId,
        IsActive:IsActive
      }
    };
    return MakeReqestAndGetResponse(
      GetBaseERPURL() + WorkspaceUserEndpoints.SET_ACTIVE_STATUS,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }
  function RESET_PASSWORD_FROM_ADMIN_SIDE(_RelWorkSpaceId,_RelERP_WORK_PLACE,UserId) {
    var js = {
      RelWorkSpaceId:_RelWorkSpaceId,
      RelERP_WORK_PLACE :_RelERP_WORK_PLACE,
      UserData:{
        id:UserId,
      }}
    
    return MakeReqestAndGetResponse(
      GetBaseERPURL() + WorkspaceUserEndpoints.RESET_PASSWORD_FROM_ADMIN_SIDE,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }
  function CREATE_USER(_RelWorkSpaceId,_RelERP_WORK_PLACE,username,firstName,lastName,email) {
    var js = {
      RelWorkSpaceId:_RelWorkSpaceId,
      RelERP_WORK_PLACE :_RelERP_WORK_PLACE,
      UserData:{
        username:username,
        firstName:firstName,
        lastName:lastName,
        email:email
      }
    };

    return MakeReqestAndGetResponse(
      GetBaseERPURL() + WorkspaceUserEndpoints.CREATE_USER,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }
  function GET_USERS(_RelWorkSpaceId,_RelERP_WORK_PLACE) {
    var js = {
      RelWorkSpaceId:_RelWorkSpaceId,
      RelERP_WORK_PLACE :_RelERP_WORK_PLACE
    };

    return MakeReqestAndGetResponse(
      GetBaseERPURL() + WorkspaceUserEndpoints.GET_USERS,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }